import axios from "axios";
import "./MasterUserProfileEdit.css";
import Modal from "react-modal";
import { saveAs } from "file-saver";
import { DatePicker } from "rsuite";
import { toast } from "react-toastify";
import subDays from "date-fns/subDays";
import { ThreeDots } from "react-loader-spinner";
import { RotatingLines } from 'react-loader-spinner';
import { Link, useNavigate } from "react-router-dom";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Type from "../../../../images/icons/type.svg";
import Tele from "../../../../images/icons/tele.svg";
import { useSelector, useDispatch } from 'react-redux';
import Countries from "../../../../constants/countries";
import userTypeName from "../../../../constants/userType";
import React, { useEffect, useRef, useState, createRef } from "react";
import EmailIcon from "../../../../images/icons/email.svg";
import PadLock from "../../../../images/icons/padlock.png";
import Tick from "../../../../images/icons/tick-arrow.svg";
import location from "../../../../images/icons/location.svg";
import Currencies from "../../../../constants/currenciesList";
import PostalCode from "../../../../images/icons/postal-code.svg";
import PassportlIcon from "../../../../images/icons/passport.svg";
import birthIcon from "../../../../images/icons/dat-of-birth.svg";
import PicEdit from "../../../../images/icons/profile-pic-edit.svg";
import profile_image from "../../../../images/user/Default_pfp.png";
import UserIcon from "../../../../images/icons/single-user-icon.svg";
import EyeIcon from "../../../../images/icons/eye-icon.svg";
import EyeCrossed from "../../../../images/icons/eye-crossed.svg";
import { reset as invoiceReset } from "../../../../features/invoice/invoiceSlice.js";
import { masterLogout, reset } from '../../../../features/masterAuth/masterAuthSlice.js';
import { updateProfileImage } from "../../../../features/auth/authSlice";
import { setNotification } from "../../../../features/notification/notificationSlice.js";
import { reset as headerReset } from "../../../../features/headerText/headerTextSlice.js";
import { reset as appSettingsReset } from "../../../../features/appSettings/appSettingsSlice.js";
import { useMasterUserType, useMasterUserView, } from "./useMasterUserView";

const MasterUserProfileEdit = (props) => {
    const [showConfirmation, setShowConfirmation] = useState(false);
    const fileInputRef = useRef(null);
    const [userType, setUserType] = useState("");
    const [firstName, setFirstName] = useState("");
    const [middleName, setMiddleName] = useState("");
    const [lastName, setLastName] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [passwordConfirmation, setPasswordConfirmation] = useState("");
    const [profileImage, setProfileImage] = useState(null);
    const [profileImageName, setProfileImageName] = useState(null);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [passport, setPassport] = useState("");
    const [country, setCountry] = useState("");
    const [state, setState] = useState("");
    const [city, setCity] = useState("");
    const [mobileNo, setMobileNo] = useState("");
    const [postCode, setPostCode] = useState("");
    const [address, setAddress] = useState("");
    const [uploadedImageUrl, setUploadedImageUrl] = useState("");
    const [removeProfileImage, setRemoveProfileImage] = useState(false);
    const [isSubmitLoading, setIsSubmitLoading] = useState(false);
    const [image, setImage] = useState(null);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [fileSelectIsLoading, setFileSelectIsLoading] = useState(false);
    const [userTypeDropdownOpen, setUserTypeDropdownOpen] = useState(false);
    const [countrySearchTerm, setCountrySearchTerm] = useState('');
    const [countryDropdownOpen, setCountryDropdownOpen] = useState(false);
    const [stateSearchTerm, setStateSearchTerm] = useState('');
    const [stateDropdownOpen, setStateDropdownOpen] = useState(false);
    const cropperRef = createRef();
    const [userView, setUserView] = useState([]);
    const [countriesList, setCountriesList] = useState([]);
    const [selectedUserId, setSelectedUserId] = useState("");
    const [passwordMatchError, setPasswordMatchError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const passwordConfirmationRef = useRef(null);
    const passwordRef = useRef(null);
    const userTypeDropdownRef = useRef(null);
    const countryDropdownRef = useRef(null);
    const stateDropdownRef = useRef(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const SUPER_ADMIN = 1;
    const ADMIN = 2;
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const dropdownRef = useRef(null);
    const user = useSelector((state) => state.masterAuth.masterUser)
    let token = null;
    if (user) {
        if (user && user.token) {
            token = user.token;
        }
    }

    const { data: roleId = [], isLoading: userTypeLoading, isError: userTypeError } = useMasterUserType();
    const { data: userData = [], isLoading, isError: userViewError } = useMasterUserView(props.id, token);
    useEffect(() => {

        if (userData) {
            setUserView(userData);
        }
        const sortedCountries = Countries.slice().sort((a, b) => a.country.localeCompare(b.country));
        setCountriesList(sortedCountries);

        const handleKeyDown = (event) => {
            if (event.key === "Escape") {
                document.activeElement.blur();
            }
        };
        document.addEventListener("keydown", handleKeyDown);
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [props.id]);

    useEffect(() => {
        if (!isLoading && userView) {
            setUserType(userView?.role_id?._id);
            setFirstName(userView.first_name);
            if (userView.middle_name && userView.middle_name !== "null") {
                setMiddleName(userView.middle_name);
            }
            setLastName(userView.last_name);
            setPassport(userView.passport_no);
            // setSelectedDate(new Date(userView.dob));
            // setPassword("");
            // if (userView.country) {
            //     setCountry(userView.country);
            // }
            // if (userView.state) {
            //     setState(userView.state);
            // }
            // setCity(userView.city);
            // setMobileNo(userView.mobile_no);
            // setPostCode(userView.pincode);
            // setAddress(userView.address);
        }
    }, [isLoading, userView]);

    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownVisible(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleCountrySearchInputChange = (e) => {
        setCountrySearchTerm(e.target.value);
    };

    const filteredCountries = countriesList.filter((country) => {
        return country.country?.toLowerCase().includes(countrySearchTerm?.toLowerCase());
    });

    const handleStateSearchInputChange = (e) => {
        setStateSearchTerm(e.target.value);
    };

    const selectedCountry = countriesList.find((c) => c.country === country);
    const filteredStates = selectedCountry?.states?.filter((stateObj) =>
        stateObj.state?.toLowerCase().includes(stateSearchTerm?.toLowerCase())
    );

    const handleProfilePicChange = () => {
        setFileSelectIsLoading(true);
        fileInputRef.current.click();
    };

    const handleDropdown = () => {
        setDropdownVisible(!dropdownVisible);
    }

    const handleFileInputCancel = () => {
        setFileSelectIsLoading(false);
    };

    const handleClickOutsideDropdown = (event) => {
        const isClickInsideUserTypeDropdown = userTypeDropdownRef.current && userTypeDropdownRef.current.contains(event.target);
        const isClickInsideCountryDropdown = countryDropdownRef.current && countryDropdownRef.current.contains(event.target);
        const isClickInsideStateDropdown = stateDropdownRef.current && stateDropdownRef.current.contains(event.target);
        if (!isClickInsideUserTypeDropdown) {
            setUserTypeDropdownOpen(false);
        }
        if (!isClickInsideCountryDropdown) {
            setCountryDropdownOpen(false);
        }
        if (!isClickInsideStateDropdown) {
            setStateDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutsideDropdown);
        return () => {
            document.removeEventListener('click', handleClickOutsideDropdown);
        };
    }, []);

    useEffect(() => {
        const inputElement = fileInputRef.current;

        const handleChange = () => {
            handleFileInputChange({ target: { files: inputElement.files } });
        };

        const handleCancel = () => {
            handleFileInputCancel();
        };

        inputElement.addEventListener('change', handleChange);
        inputElement.addEventListener('cancel', handleCancel);

        return () => {
            inputElement.removeEventListener('change', handleChange);
            inputElement.removeEventListener('cancel', handleCancel);
        };
    }, []);

    const handleFileInputChange = (event) => {
        setFileSelectIsLoading(false);
        const file = event.target.files[0];
        setProfileImageName(event.target.files[0])
        if (file) {
            const fileReader = new FileReader();
            fileReader.onload = () => {
                setImage(fileReader.result);
                setModalIsOpen(true);
            };
            fileReader.readAsDataURL(file);
        }
    };

    const closeModal = () => {
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
        setFileSelectIsLoading(false);
        setModalIsOpen(false);
    };

    const handleCropClick = () => {
        if (typeof cropperRef.current?.cropper !== "undefined") {
            const croppedImage = (cropperRef.current?.cropper.getCroppedCanvas().toDataURL());
            setUploadedImageUrl(croppedImage);
            setProfileImage(croppedImage);
        }
        closeModal();
    };

    // Function to resize the image
    const resizeImage = async (base64Image, maxWidth) => {
        return new Promise((resolve) => {
            const image = new Image();
            image.src = base64Image;

            image.onload = () => {
                const canvas = document.createElement('canvas');
                const context = canvas.getContext('2d');
                const aspectRatio = image.width / image.height;
                const newHeight = maxWidth / aspectRatio;

                canvas.width = maxWidth;
                canvas.height = newHeight;

                context.drawImage(image, 0, 0, maxWidth, newHeight);

                const resizedImage = canvas.toDataURL('image/jpeg'); // Change to 'image/png' if needed
                resolve(resizedImage);
            };
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setPasswordMatchError("");
        setPasswordError("");
        // const mobileNoRegex = /^[+\d]+$/;
        // if (!mobileNoRegex.test(mobileNo)) {
        //     toast.error("It's not a valid phone number", {
        //         position: "bottom-right",
        //         autoClose: 2000,
        //         hideProgressBar: false,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         draggable: true,
        //         progress: undefined,
        //     });
        //     return;
        // }

        if (password) {
            if (passwordConfirmation === "") {
                setPasswordMatchError("You Need to confirm the Password");
                passwordConfirmationRef.current.focus();
                return;
            } else if (password !== passwordConfirmation) {
                setPasswordMatchError("Passwords do not match");
                passwordConfirmationRef.current.focus();
                return;
            }
            const passwordRegex =
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#\$%\^&\*\(\)_\+\-\.\/:;<=>?@[\\\]^_`{|}~])[A-Za-z\d!@#\$%\^&\*\(\)_\+\-\.\/:;<=>?@[\\\]^_`{|}~]{8,}$/;
            if (!passwordRegex.test(password)) {
                setPasswordError("Invalid password format");
                toast.error("Password must be at least 8 charachters long and include atleast one uppercase letter ,one lowercase letter,one digit and one special character from the set !@#$%^&*()_+,-/:;<=>?@_'[\]{|}~", {
                    position: "bottom-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                passwordRef.current.focus();
                return;
            }
        }

        const previousUserType = userView?.role_id?._id;
        const fileExtension = profileImageName
            ? profileImageName.name.split(".").pop()
            : "";
        const uniqueSuffix = Date.now() + "-" + Math.round(Math.random() * 1e9);
        const profile_img_fileName = profileImageName
            ? `${profileImageName.name
                .split(".")
                .shift()}_${uniqueSuffix}.${fileExtension}`
            : null;

        if (lastName) {
            if (lastName.length <= 1) {

                toast.error("A last name should have more than one character.", {
                    position: "bottom-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                return;

            }
        }
        const requestData = {
            first_name: firstName,
            middle_name: middleName,
            last_name: lastName,
            // mobile_no: mobileNo,
            // passport_no: passport,
            password,
            role_id: userType,
            // address,
            // pincode: postCode,
            // dob: selectedDate,
            // country,
            // state,
            // city,
        };
        if (removeProfileImage) {
            requestData.profile_image = null;
        }

        if (profileImage) {
            requestData.profile_image = profile_img_fileName;
        }

        try {
            setIsSubmitLoading(true);
            const response = await axios.put(`/api/master/user/update/${props.id}`, requestData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })
            if (profileImage) {
                const resizedImage = await resizeImage(profileImage, 500);

                const base64Image = resizedImage.split(',')[1];
                const blob = await fetch(`data:image/png;base64,${base64Image}`).then(res => res.blob());

                // Change to 'image/png' if needed

                const uploadFormData = new FormData();
                uploadFormData.append("profile_image", blob, profile_img_fileName);

                try {
                    await axios.post("/api/uploads/master/user/profileImage", uploadFormData);
                } catch (uploadError) {
                    console.log(uploadError);
                    global.config.slackMessage(uploadError.toString());
                    if (uploadError.response?.data?.status === "deleted") {
                        localStorage.removeItem("masterUser");
                        dispatch(masterLogout());
                        dispatch(reset());
                        dispatch(invoiceReset());
                        dispatch(headerReset());
                        dispatch(appSettingsReset());
                        toast.error('This user is deleted!', {
                            position: "bottom-right",
                            autoClose: 1500,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                        navigate("/master-user/login");
                        return;
                    }
                    toast.error('Something went wrong with the profile image upload!', {
                        position: "bottom-right",
                        autoClose: 1500,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            }
            if (user._id === props.id) {
                if (profileImage) {
                    dispatch(updateProfileImage(profile_img_fileName));
                }
                if (removeProfileImage) {
                    dispatch(updateProfileImage(null));
                }
            }
            if (user._id === props.id && previousUserType !== userType && (user.userType === SUPER_ADMIN || user.userType === ADMIN)) {
                localStorage.removeItem("masterUser");
                dispatch(masterLogout());
                dispatch(reset());
                dispatch(invoiceReset());
                dispatch(headerReset());
                dispatch(appSettingsReset());
                toast.success("User details updated successfully!", {
                    position: "bottom-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                // global.config.activityLog(
                //     window.location.href,
                //     "User",
                //     `User "${firstName} " Details updated successfully!`
                // );
                // navigate("/master-user/login");
                return;
            }

            const data = response.data;
            if (data.status === "error") {
                toast.error('Something went wrong while updating User Details!', {
                    position: "bottom-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } else {
                // const Name = firstName ? `${firstName} ${middleName} ${lastName}`.trim() : `${companyName}`.trim();
                // global.config.activityLog(
                //     window.location.href,
                //     "User",
                //     `User "${firstName} " Details updated successfully!`
                // );
                toast.success("User details updated successfully!!!", {
                    position: "bottom-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                dispatch(setNotification(Date.now()));
                props.onCancel();
            }
        } catch (error) {
            console.log(error);
            global.config.slackMessage(error.toString());
            if (error.response?.data?.status === "deleted") {
                localStorage.removeItem("masterUser");
                dispatch(masterLogout());
                dispatch(reset());
                dispatch(invoiceReset());
                dispatch(headerReset());
                dispatch(appSettingsReset());
                toast.error('This user is deleted!', {
                    position: "bottom-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                navigate("/master-user/login");
                return;
            }
            alert("Something went wrong");
        } finally {
            setIsSubmitLoading(false);
        }
    };

    const allowedExtensions = ["pdf", "doc", "docx", "msword", "xls", "xlsx", "csv"];

    const StatusChangeActive = async (id) => {
        try {
            setIsSubmitLoading(true);
            await axios.get(`/api/master/user/status-change/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (user._id === id) {
                localStorage.removeItem("masterUser");
                dispatch(masterLogout());
                dispatch(reset());
                dispatch(invoiceReset());
                dispatch(headerReset());
                dispatch(appSettingsReset());
                toast.success(`Master user ${firstName}'s account has been deactivated successfully!`, {
                    position: "bottom-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                global.config.activityLog(
                    window.location.href,
                    "Master user",
                    `Master user ${firstName}'s account has been deactivated successfully!`
                );
                return;
            }
            toast.success(`${firstName}'s account has been deactivated successfully!`, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            // global.config.activityLog(
            //     window.location.href,
            //     "User",
            //     `${firstName}'s account has been deactivated successfully!`
            // );
            dispatch(setNotification(Date.now()));
            setUserView((prevState) => {
                // Find the user object with the corresponding id
                if (prevState._id === id) {
                    // Update the is_active property of the user object
                    return { ...prevState, is_active: false };
                }
                return prevState;
            });
        } catch (error) {
            console.log("Something went wrong while deactivating: ", error);
            toast.error(`Something went wrong while deactivating ${firstName}'s account`, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            // global.config.activityLog(
            //     window.location.href,
            //     "User",
            //     `Something went wrong while deactivating ${firstName}'s account!`
            // );
            console.log(error);
            global.config.slackMessage(error.toString());
            if (error.response?.data?.status === "deleted") {
                localStorage.removeItem("masterUser");
                dispatch(masterLogout());
                dispatch(reset());
                dispatch(invoiceReset());
                dispatch(headerReset());
                dispatch(appSettingsReset());
                toast.error('This user is deleted!', {
                    position: "bottom-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                navigate("/master-user/login");
                return;
            }
        } finally {
            setIsSubmitLoading(false);
        }
    };

    const StatusChangeInactive = async (id) => {
        try {
            setIsSubmitLoading(true);
            await axios.get(`/api/master/user/status-change/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (user._id === id) {
                localStorage.removeItem("masterUser");
                dispatch(masterLogout());
                dispatch(reset());
                dispatch(invoiceReset());
                dispatch(headerReset());
                dispatch(appSettingsReset());
                toast.success(`Master user ${firstName}'s account has been activated successfully!`, {
                    position: "bottom-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                global.config.activityLog(
                    window.location.href,
                    "Master user",
                    `Master user ${firstName}'s account has been activated successfully!`
                );
                return;
            }
            toast.success(`Master user ${firstName}'s account has been activated successfully!`, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            dispatch(setNotification(Date.now()));
            // global.config.activityLog(
            //     window.location.href,
            //     "User",
            //     `${firstName}'s account has been activated successfully!`
            // );
            setUserView((prevState) => {
                // Find the user object with the corresponding id
                if (prevState._id === id) {
                    // Update the is_active property of the user object
                    return { ...prevState, is_active: true };
                }
                return prevState;
            });
        } catch (error) {
            console.log("Something went wrong while activating user: ", error);
            global.config.slackMessage(error.toString());
            toast.error("Something went wrong in changing user status!", {
                position: "bottom-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            // global.config.activityLog(
            //     window.location.href,
            //     "User",
            //     `Something went wrong while activating ${firstName}'s account!`
            // );
            console.log(error);
            if (error.response?.data?.status === "deleted") {
                localStorage.removeItem("masterUser");
                dispatch(masterLogout());
                dispatch(reset());
                dispatch(invoiceReset());
                dispatch(headerReset());
                dispatch(appSettingsReset());
                toast.error('This user is deleted!', {
                    position: "bottom-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                navigate("/master-user/login");
                return;
            }
        } finally {
            setIsSubmitLoading(false);
        }
    };

    const ranges = [
        {
            label: "today",
            value: new Date(), // Use a single date instead of an array
        },
        {
            label: "yesterday",
            value: subDays(new Date(), 1),
        },
    ];

    const handleConfirmationOk = async () => {
        try {
            await axios.get(`/api/delete/user/${selectedUserId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            toast.success("User removed successfully", {
                position: "bottom-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            navigate("/user/myteam/list");
        } catch (error) {
            console.log(error);
            global.config.slackMessage(error.toString());
            if (error.response?.data?.status === "deleted") {
                localStorage.removeItem("masterUser");
                dispatch(masterLogout());
                dispatch(reset());
                dispatch(invoiceReset());
                dispatch(headerReset());
                dispatch(appSettingsReset());
                toast.error('This user is deleted!', {
                    position: "bottom-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                navigate("/master-user/login");
                return;
            }
            toast.error("Something went wrong", {
                position: "bottom-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };
    // Define selectedDate and handleDateChange
    // Set an initial date

    const handleDateChange = (newDate) => {
        setSelectedDate(new Date(newDate));
    };

    const handleToggleCountryDropdown = () => {
        setCountrySearchTerm('');
        setCountryDropdownOpen(!countryDropdownOpen);
    };

    const handleCountryChange = (selectedCountry) => {
        setCountry(selectedCountry);
        setState("");
        setCountryDropdownOpen(false);
    };

    const handleStateChange = (event) => {
        setState(event.target.value);
    };

    const handleCancel = () => {
        props.onCancel();
    };
    const countryData = countriesList.find((c) => c.country === country);
    const states = countryData?.states || [];
    const numberInputOnWheelPreventChange = (e) => {
        // Prevent the input value change
        e.target.blur()

        // Prevent the page/container scrolling
        e.stopPropagation()

        setTimeout(() => {
            e.target.focus()
        }, 0)
    }

    return (
        <div className="profile-details-edit">
            <form onSubmit={handleSubmit}>
                <div className="profile-photo-outer">
                    <div className="profile-photo">
                        <div className="profile-photo-inner">
                            <div className="profile-photo-sec">
                                {(userView.profile_image !== "null" && userView.profile_image !== null || uploadedImageUrl) && !isLoading && !removeProfileImage ? (
                                    <div className="profile-photo-sec-edit-outer">
                                        <div className="pic-edit picart" onClick={!fileSelectIsLoading && handleDropdown}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" fill="none">
                                                <circle cx="14.5" cy="14.5" r="14.5" fill="#6479F8" />
                                                <path d="M22.7194 10.1524L19.2559 6.66847C19.2063 6.61849 19.1405 6.59104 19.0701 6.59104C18.9998 6.59104 18.9344 6.6185 18.8847 6.66848L16.8328 8.73279L16.8303 8.74018L16.7894 8.77644L8.86392 16.7497C8.82719 16.7866 8.80235 16.8331 8.7922 16.8838L7.91485 21.2513C7.89736 21.3382 7.9243 21.4273 7.98658 21.4903C8.03626 21.5399 8.10204 21.5673 8.17236 21.5673C8.18986 21.5673 8.207 21.5656 8.2231 21.5624L12.5654 20.6792C12.6164 20.669 12.6623 20.644 12.6987 20.6074L22.7194 10.5259C22.7691 10.4762 22.7961 10.4097 22.7961 10.339C22.7961 10.2682 22.7688 10.2021 22.7194 10.1524ZM12.3831 20.1777L8.50718 20.9662L9.29043 17.0668L10.4345 15.9158L13.5271 19.0271L12.3831 20.1777ZM13.898 18.6536L10.8053 15.5427L16.9801 9.33077L20.0724 12.4417L13.8976 18.6536L13.898 18.6536ZM20.4439 12.0682L17.3516 8.95733L19.0702 7.22844L22.1628 10.3393L20.4439 12.0682Z" fill="white" stroke="white" strokeWidth="0.4" />
                                            </svg>
                                        </div>

                                        {dropdownVisible && (
                                            <div className="dropdown-edit-profile" ref={dropdownRef}>
                                                {/* <button onClick={handleUploadPhoto}>Upload Photo</button> */}
                                                <div className="profile-pic-edit">
                                                    <Link className="pic-edit" onClick={!fileSelectIsLoading && handleProfilePicChange}>
                                                        {fileSelectIsLoading ? (
                                                            <RotatingLines
                                                                strokeColor="grey"
                                                                strokeWidth="5"
                                                                animationDuration="0.75"
                                                                width="18"
                                                                visible={true}
                                                            />
                                                        ) : (
                                                            <span>Upload Photo</span>
                                                        )}
                                                    </Link>
                                                </div>
                                                {/* <button onClick={!fileSelectIsLoading && handleProfilePicChange}>Delete Photo</button> */}
                                                <div className="remove-pic">
                                                    <label for="delete1">
                                                        <input
                                                            type="checkbox"
                                                            checked={removeProfileImage}
                                                            onChange={() => setRemoveProfileImage(!removeProfileImage)}
                                                            id="delete1"
                                                            hidden
                                                        />
                                                        Delete Photo
                                                    </label>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    <div className="profile-pic-edit">
                                        <Link className="pic-edit" onClick={!fileSelectIsLoading && handleProfilePicChange}>
                                            {fileSelectIsLoading ? (
                                                <RotatingLines
                                                    strokeColor="grey"
                                                    strokeWidth="5"
                                                    animationDuration="0.75"
                                                    width="18"
                                                    visible={true}
                                                />
                                            ) : (
                                                <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" fill="none">
                                                    <circle cx="14.5" cy="14.5" r="14.5" fill="#6479F8" />
                                                    <path d="M22.7194 10.1524L19.2559 6.66847C19.2063 6.61849 19.1405 6.59104 19.0701 6.59104C18.9998 6.59104 18.9344 6.6185 18.8847 6.66848L16.8328 8.73279L16.8303 8.74018L16.7894 8.77644L8.86392 16.7497C8.82719 16.7866 8.80235 16.8331 8.7922 16.8838L7.91485 21.2513C7.89736 21.3382 7.9243 21.4273 7.98658 21.4903C8.03626 21.5399 8.10204 21.5673 8.17236 21.5673C8.18986 21.5673 8.207 21.5656 8.2231 21.5624L12.5654 20.6792C12.6164 20.669 12.6623 20.644 12.6987 20.6074L22.7194 10.5259C22.7691 10.4762 22.7961 10.4097 22.7961 10.339C22.7961 10.2682 22.7688 10.2021 22.7194 10.1524ZM12.3831 20.1777L8.50718 20.9662L9.29043 17.0668L10.4345 15.9158L13.5271 19.0271L12.3831 20.1777ZM13.898 18.6536L10.8053 15.5427L16.9801 9.33077L20.0724 12.4417L13.8976 18.6536L13.898 18.6536ZM20.4439 12.0682L17.3516 8.95733L19.0702 7.22844L22.1628 10.3393L20.4439 12.0682Z" fill="white" stroke="white" strokeWidth="0.4" />
                                                </svg>
                                            )}
                                        </Link>
                                    </div>
                                )}

                                <div className="profile-pic-edit" style={{ display: "none" }}>
                                    <Link className="pic-edit" onClick={!fileSelectIsLoading && handleProfilePicChange}>
                                        {fileSelectIsLoading ? (
                                            <RotatingLines
                                                strokeColor="grey"
                                                strokeWidth="5"
                                                animationDuration="0.75"
                                                width="18"
                                                visible={true}
                                            />
                                        ) : (
                                            <img src={PicEdit} alt="edit" />
                                        )}
                                    </Link>
                                </div>


                                {isLoading ? (
                                    <div>
                                        <ThreeDots
                                            height="18"
                                            width="20"
                                            radius="9"
                                            color="#6479f9"
                                            ariaLabel="three-dots-loading"
                                            wrapperStyle={{}}
                                            wrapperClassName=""
                                            visible={true}
                                        />
                                    </div>
                                ) : (
                                    removeProfileImage ? (
                                        <div className="profile-image">
                                            {/* Display a default placeholder image */}
                                            <img src={profile_image} alt="Default Profile" />
                                        </div>
                                    ) : uploadedImageUrl ? (
                                        <div className="profile-image">
                                            <img src={uploadedImageUrl} alt="Uploaded Profile " />
                                        </div>
                                    ) : (
                                        <div className="profile-image">
                                            {userView.profile_image !== "null" && userView.profile_image !== null ? (
                                                <img
                                                    src={`/uploads/orgiProfileImage/${userView.profile_image}`}
                                                    alt="User Profile "
                                                    onError={(e) => {
                                                        e.target.onerror = null;
                                                        e.target.src = '/uploads/lowProfileImage/user_default.png';
                                                    }}
                                                />
                                            ) : (
                                                <img src={profile_image} alt="User Profile " />
                                            )}
                                        </div>
                                    )
                                )}
                                {isLoading ? (
                                    <ThreeDots
                                        height="18"
                                        width="20"
                                        radius="9"
                                        color="#6479f9"
                                        ariaLabel="three-dots-loading"
                                        wrapperStyle={{}}
                                        wrapperClassName=""
                                        visible={true}
                                    />
                                ) : (
                                    <div className="profile-pic-edit" style={{ display: "none" }}>
                                        <Link className="pic-edit" onClick={!fileSelectIsLoading && handleProfilePicChange}>
                                            {fileSelectIsLoading ? (
                                                <RotatingLines
                                                    strokeColor="grey"
                                                    strokeWidth="5"
                                                    animationDuration="0.75"
                                                    width="18"
                                                    visible={true}
                                                />
                                            ) : (
                                                <img src={PicEdit} alt="edit" />
                                            )}
                                        </Link>
                                    </div>
                                )}
                            </div>
                            <div className="profile-name">
                                {isLoading ? (
                                    <div>
                                        <ThreeDots
                                            height="18"
                                            width="20"
                                            radius="9"
                                            color="#6479f9"
                                            ariaLabel="three-dots-loading"
                                            wrapperStyle={{}}
                                            wrapperClassName=""
                                            visible={true}
                                        />
                                    </div>
                                ) : (
                                    <div className="profile-name-sec">
                                        {firstName ? firstName : userView.first_name}{" "}
                                        {middleName ? middleName : (userView.middle_name && userView.middle_name !== "null" ? userView.middle_name + " " : "")}
                                        {lastName ? lastName : userView.last_name}{" "}
                                    </div>
                                )}
                                {isLoading ? (
                                    <div>
                                        <ThreeDots
                                            height="18"
                                            width="20"
                                            radius="9"
                                            color="#6479f9"
                                            ariaLabel="three-dots-loading"
                                            wrapperStyle={{}}
                                            wrapperClassName=""
                                            visible={true}
                                        />
                                    </div>
                                ) : (
                                    <>
                                        {userType ? (
                                            userType === userTypeName.Super_Admin ? (
                                                <div className="profile-type">
                                                    Super Admin
                                                </div>
                                            ) : userType === userTypeName.Admin ? (
                                                <div className="profile-type">
                                                    Admin
                                                </div>
                                            ) : userType === userTypeName.User ? (
                                                <div className="profile-type">
                                                    User
                                                </div>
                                            ) : null
                                        ) : (
                                            <>
                                                {userView.role_id && (
                                                    <div className="profile-type">
                                                        {userView.role_id.user_type}
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </>
                                )}
                                <div className="profile-status">
                                    {isLoading ? (
                                        <div>
                                            <ThreeDots
                                                height="18"
                                                width="20"
                                                radius="9"
                                                color="#6479f9"
                                                ariaLabel="three-dots-loading"
                                                wrapperStyle={{}}
                                                wrapperClassName=""
                                                visible={true}
                                            />
                                        </div>
                                    ) : (
                                        <>
                                            {(user) ? userView.is_active === false ? (
                                                <div className="inactive" onClick={() => !isSubmitLoading && StatusChangeInactive(userView._id)} style={{ cursor: "pointer" }}>
                                                    {isSubmitLoading ?
                                                        <div className="outter-load-table">
                                                            <ThreeDots
                                                                height="18"
                                                                width="18"
                                                                radius="9"
                                                                color="#405BFF"
                                                                ariaLabel="three-dots-loading"
                                                                wrapperStyle={{}}
                                                                wrapperClassName=""
                                                                visible={true}
                                                            />
                                                        </div> : "Inactive"
                                                    }
                                                </div>
                                            ) : userView.is_active === true ? (
                                                <div className="active" onClick={() => !isSubmitLoading && StatusChangeActive(userView._id)} style={{ cursor: "pointer" }}>
                                                    {isSubmitLoading ?
                                                        <div className="outter-load-table">
                                                            <ThreeDots
                                                                height="18"
                                                                width="18"
                                                                radius="9"
                                                                color="#405BFF"
                                                                ariaLabel="three-dots-loading"
                                                                wrapperStyle={{}}
                                                                wrapperClassName=""
                                                                visible={true}
                                                            />
                                                        </div> : "Active"
                                                    }
                                                </div>
                                            ) : (
                                                userView.is_active
                                            ) : (userView.is_active === false) ? (
                                                <div className="inactive" >
                                                    {isSubmitLoading ?
                                                        <div className="outter-load-table">
                                                            <ThreeDots
                                                                height="18"
                                                                width="18"
                                                                radius="9"
                                                                color="#405BFF"
                                                                ariaLabel="three-dots-loading"
                                                                wrapperStyle={{}}
                                                                wrapperClassName=""
                                                                visible={true}
                                                            />
                                                        </div> : "Inactive"
                                                    }
                                                </div>
                                            ) : (
                                                <div className="active" >
                                                    {isSubmitLoading ?
                                                        <div className="outter-load-table">
                                                            <ThreeDots
                                                                height="18"
                                                                width="18"
                                                                radius="9"
                                                                color="#405BFF"
                                                                ariaLabel="three-dots-loading"
                                                                wrapperStyle={{}}
                                                                wrapperClassName=""
                                                                visible={true}
                                                            />
                                                        </div> : "Active"
                                                    }
                                                </div>
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <input
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    ref={fileInputRef}
                />

                <Modal
                    isOpen={modalIsOpen}
                    contentLabel="Image Crop Modal"
                    style={{
                        content: {
                            height: "500%",
                            width: "1000%",
                            position: "fixed",
                            top: "15%",
                            left: "50%",
                            transform: "translate(-17.5%, 0%)",
                            parent: document.querySelector(".admin-outer.time.tracker"),
                        },
                    }}
                >
                    <div>
                        {image && (
                            <Cropper
                                ref={cropperRef}
                                style={{ height: 400, width: "100%" }}
                                zoomTo={0}
                                initialAspectRatio={1}
                                preview=".img-preview"
                                src={image}
                                viewMode={1}
                                minCropBoxHeight={10}
                                minCropBoxWidth={10}
                                background={false}
                                responsive={true}
                                autoCropArea={100}
                                checkOrientation={false}
                                guides={true}
                            />
                        )}
                    </div>
                    <div>
                        <div className="crop-btn">
                            <button onClick={handleCropClick}>Crop and Upload</button>
                            <button className="delete-client" onClick={closeModal}>Cancel</button>
                        </div>
                    </div>
                </Modal>

                {showConfirmation && (
                    <Modal
                        isOpen={showConfirmation}
                        onRequestClose={() => setShowConfirmation(false)}
                        contentLabel="Confirmation Modal"
                        style={{
                            content: {
                                top: "50%",
                                left: "50%",
                                right: "auto",
                                bottom: "auto",
                                transform: "translate(-50%, -50%)",
                                maxHeight: "80vh",
                                overflow: "auto",
                            },
                        }}
                    >
                        <p>Are you sure you want to delete?</p>
                        <div>
                            <button onClick={handleConfirmationOk}>OK</button>
                            <button onClick={() => setShowConfirmation(false)}>Cancel</button>
                        </div>
                    </Modal>
                )}

                <div className="information-outer-section">
                    <div className="information-inner">
                        <div className="information">
                            <div className="information-title">
                                Personal Information
                            </div>
                            <div className="information-outer">
                                <div className="information-left">
                                    <div className="inner-detail-outer">
                                        <div className="inner-detail">
                                            <span class="icon"><img src={UserIcon} alt="user icon" /></span>
                                            <div className="inner-detail-inner">
                                                <label for="fname">First Name *</label>
                                            </div>
                                        </div>
                                        {isLoading ? (
                                            <ThreeDots
                                                height="18"
                                                width="20"
                                                radius="9"
                                                color="#6479f9"
                                                ariaLabel="three-dots-loading"
                                                wrapperStyle={{}}
                                                wrapperClassName=""
                                                visible={true}
                                            />
                                        ) : (
                                            <div className="inner-detail">
                                                <span class="icon"></span>
                                                <div className="inner-detail-inner">
                                                    <input type="text" id="fname" value={firstName} onChange={(e) => setFirstName(e.target.value)} required />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className="inner-detail-outer">
                                        <div className="inner-detail">
                                            <span class="icon"><img src={UserIcon} alt="user icon" /></span>
                                            <div className="inner-detail-inner">
                                                <label for="mname">Middle Name</label>
                                            </div>
                                        </div>
                                        {isLoading ? (
                                            <ThreeDots
                                                height="18"
                                                width="20"
                                                radius="9"
                                                color="#6479f9"
                                                ariaLabel="three-dots-loading"
                                                wrapperStyle={{}}
                                                wrapperClassName=""
                                                visible={true}
                                            />
                                        ) : (
                                            <div className="inner-detail">
                                                <span class="icon"></span>
                                                <div className="inner-detail-inner">
                                                    <input type="text" id="mname" value={middleName} onChange={(e) => setMiddleName(e.target.value)} />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className="inner-detail-outer">
                                        <div className="inner-detail">
                                            <span class="icon"><img src={UserIcon} alt="user icon" /></span>
                                            <div className="inner-detail-inner">
                                                <label for="lname">Last Name *</label>
                                            </div>
                                        </div>
                                        {isLoading ? (
                                            <ThreeDots
                                                height="18"
                                                width="20"
                                                radius="9"
                                                color="#6479f9"
                                                ariaLabel="three-dots-loading"
                                                wrapperStyle={{}}
                                                wrapperClassName=""
                                                visible={true}
                                            />
                                        ) : (
                                            <div className="inner-detail">
                                                <span class="icon"></span>
                                                <div className="inner-detail-inner">
                                                    <input type="text" id="lname" value={lastName} onChange={(e) => setLastName(e.target.value)} required />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className="inner-detail-outer">
                                        <div className="inner-detail">
                                            <span class="icon"><img src={Type} alt="type" /></span>
                                            <div className="inner-detail-inner">
                                                <label>Type of Account *</label>
                                            </div>
                                        </div>
                                        <div className="inner-detail">
                                            <span class="icon"></span>
                                            {isLoading ? (
                                                <ThreeDots
                                                    height="18"
                                                    width="20"
                                                    radius="9"
                                                    color="#6479f9"
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClassName=""
                                                    visible={true}
                                                />
                                            ) : (
                                                <div className="inner-detail-inner">
                                                    <div className="tooltip-container">

                                                        <div className={`custom-dropdown-user-new-outer custom-dropdown-select ${userTypeDropdownOpen ? 'open' : ''}`} ref={userTypeDropdownRef}>
                                                            <div className="selected-option custom-dropdown-user-new" onClick={() => setUserTypeDropdownOpen(!userTypeDropdownOpen)} style={{ cursor: 'pointer' }}>
                                                                {userType ? roleId.find((role) => role._id === userType)?.user_type : 'Select Role'}
                                                            </div>
                                                            {userTypeDropdownOpen && (
                                                                <div className="custom-dropdown-new">
                                                                    <ul className="role-list">
                                                                        {roleId
                                                                            .filter((role) => !(user.userType === ADMIN && role._id === userTypeName.Super_Admin))
                                                                            .map((role) => (
                                                                                <li
                                                                                    key={role._id}
                                                                                    className={userType === role._id ? 'selected-item' : ''}
                                                                                    onClick={() => {
                                                                                        setUserType(role._id);
                                                                                        setUserTypeDropdownOpen(false);
                                                                                    }}
                                                                                >
                                                                                    {role.user_type}
                                                                                </li>
                                                                            ))}
                                                                    </ul>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="information-right">
                                    <div className="inner-detail-outer">
                                        <div className="inner-detail">
                                            <span class="icon"><img src={EmailIcon} alt="email icon" /></span>
                                            <div className="inner-detail-inner">
                                                <label for="email">Email</label>
                                            </div>
                                        </div>
                                        {isLoading ? (
                                            <ThreeDots
                                                height="18"
                                                width="20"
                                                radius="9"
                                                color="#6479f9"
                                                ariaLabel="three-dots-loading"
                                                wrapperStyle={{}}
                                                wrapperClassName=""
                                                visible={true}
                                            />
                                        ) : (
                                            <div className="inner-detail">
                                                <span class="icon"></span>
                                                <div className="inner-detail-inner">
                                                    <input type="email" className="read-only-input" id="email" value={userView.email} required readOnly />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className="inner-detail-outer">
                                        <div className="inner-detail">
                                            <span class="icon"><img width={'20px'} src={PadLock} alt="email icon" /></span>
                                            <div className="inner-detail-inner">
                                                <label for="password">Password</label>
                                            </div>
                                        </div>
                                        {isLoading ? (
                                            <ThreeDots
                                                height="18"
                                                width="20"
                                                radius="9"
                                                color="#6479f9"
                                                ariaLabel="three-dots-loading"
                                                wrapperStyle={{}}
                                                wrapperClassName=""
                                                visible={true}
                                            />
                                        ) : (
                                            <div className="inner-detail">
                                                <span class="icon"></span>
                                                <div className="inner-detail-inner pass-eye">
                                                    <input type={showPassword ? "text" : "password"} id="password" value={password} onChange={(e) => setPassword(e.target.value)} ref={passwordRef} autoComplete="new-password" />
                                                    <img
                                                        src={showPassword ? EyeCrossed : EyeIcon}
                                                        className="password-toggle-icon"
                                                        alt="Toggle Password"
                                                        onClick={() => setShowPassword(!showPassword)}
                                                    />
                                                </div>
                                                {passwordError && (
                                                    <span className="text-red-500 text-xs italic">
                                                        {passwordError}
                                                    </span>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                    {password && (
                                        <div className="inner-detail-outer">
                                            <div className="inner-detail">
                                                <span class="icon"><img src={EmailIcon} alt="email icon" /></span>
                                                <div className="inner-detail-inner">
                                                    <label for="confpassword">Confirm Password</label>
                                                </div>
                                            </div>
                                            {isLoading ? (
                                                <ThreeDots
                                                    height="18"
                                                    width="20"
                                                    radius="9"
                                                    color="#6479f9"
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClassName=""
                                                    visible={true}
                                                />
                                            ) : (
                                                <div className="inner-detail">
                                                    <span class="icon"></span>
                                                    <div className="inner-detail-inner">
                                                        <input type="password" id="confpassword" value={passwordConfirmation} onChange={(e) => setPasswordConfirmation(e.target.value)} ref={passwordConfirmationRef} />
                                                    </div>
                                                    {passwordMatchError && (
                                                        <span className="text-red-500 text-xs italic">
                                                            {passwordMatchError}
                                                        </span>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    )}
                                    {/* <div className="inner-detail-outer">
                                        <div className="inner-detail">
                                            <span class="icon"><img src={birthIcon} alt="birth icon" /></span>
                                            <div className="inner-detail-inner">
                                                <label>Date of birth</label>
                                            </div>
                                        </div>
                                        {isLoading ? (
                                            <ThreeDots
                                                height="18"
                                                width="20"
                                                radius="9"
                                                color="#6479f9"
                                                ariaLabel="three-dots-loading"
                                                wrapperStyle={{}}
                                                wrapperClassName=""
                                                visible={true}
                                            />
                                        ) : (
                                            <div className="inner-detail">
                                                <div className="date-picker-outer date-picker-outer-all">
                                                    <div className="custom-picker-icon custom-picker-icon-all">
                                                        <svg
                                                            width="1em"
                                                            height="1em"
                                                            viewBox="0 0 14 14"
                                                            fill="currentColor"
                                                            aria-hidden="true"
                                                            focusable="false"
                                                            class="rs-picker-toggle-caret rs-icon"
                                                            aria-label="calendar"
                                                            data-category="legacy"
                                                        >
                                                            <path d="M1 4v8.5a.5.5 0 00.5.5H8c0-.128.049-.256.146-.354.555-.555.854-1.6.854-3.146a.5.5 0 01.621-.485l.119.03A2.623 2.623 0 0012.999 6.5V4h-12zm3-2h6V0h1v2h1.5A1.5 1.5 0 0114 3.5v3a3.623 3.623 0 01-4.015 3.603c-.064 1.245-.335 2.212-.831 2.898H12.5a.5.5 0 00.5-.5v-2a.5.5 0 011 0v2a1.5 1.5 0 01-1.5 1.5h-11a1.5 1.5 0 01-1.5-1.5v-9a1.5 1.5 0 011.5-1.5H3v-2h1v2zm5 4V5h1v1h2v1h-2v1H9V7H7v2h1v1H7v2H6v-2H4v2H3v-2H1V9h2V7H1V6h2V5h1v1h2V5h1v1h2zM6 9V7H4v2h2z"></path>
                                                        </svg>
                                                    </div>
                                                    <div className="custoom-icon-calender custoom-icon-calender-all">
                                                        {/* <DatePicker
                                                            oneTap
                                                            showOneCalendar
                                                            ranges={ranges}
                                                            format="dd/MM/yyyy"
                                                            value={selectedDate || new Date()}
                                                            onChange={handleDateChange}
                                                            placement="bottomEnd"
                                                            isoWeek='Monday'
                                                            timeZone={global.config.getTimeZone()}
                                                        />
                                                    </div>
                                                </div>
                                                <span class="icon"></span>
                                            </div>
                                        )}
                                    </div>
                                    <div className="inner-detail-outer">
                                        <div className="inner-detail">
                                            <span class="icon"><img src={PassportlIcon} alt="passport icon" /></span>
                                            <div className="inner-detail-inner">
                                                <label for="id_number">Passport or ID Number *</label>
                                            </div>
                                        </div>
                                        {isLoading ? (
                                            <ThreeDots
                                                height="18"
                                                width="20"
                                                radius="9"
                                                color="#6479f9"
                                                ariaLabel="three-dots-loading"
                                                wrapperStyle={{}}
                                                wrapperClassName=""
                                                visible={true}
                                            />
                                        ) : (
                                            <div className="inner-detail">
                                                <span class="icon"></span>
                                                <div className="inner-detail-inner">
                                                    <input type="text" id="id_number" value={passport} onChange={(e) => setPassport(e.target.value)} required />
                                                </div>
                                            </div>
                                        )}
                                    </div> */}
                                    {/* )} */}
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className="information-inner">
                        <div className="information">
                            <div className="information-title">
                                Address
                            </div>
                            <div className="information-outer">
                                <div className="information-left">
                                    <div className="inner-detail-outer">
                                        <div className="inner-detail">
                                            <span className="icon">
                                                <img src={location} alt="Location Icon" />
                                            </span>
                                            <div className="inner-detail-inner">
                                                <label>Country *</label>
                                            </div>
                                        </div>
                                        <div className="inner-detail">
                                            <span className="icon"></span>
                                            {isLoading ? (
                                                <ThreeDots
                                                    height="18"
                                                    width="20"
                                                    radius="9"
                                                    color="#6479f9"
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClassName=""
                                                    visible={true}
                                                />
                                            ) : (
                                                <div className="inner-detail-inner">

                                                    <div className={`custom-dropdown-user-new-outer custom-dropdown-select ${countryDropdownOpen ? 'open' : ''}`} ref={countryDropdownRef}>
                                                        <div className="selected-option custom-dropdown-user-new" onClick={handleToggleCountryDropdown} style={{ cursor: 'pointer' }}>
                                                            {country ? countriesList.find((c) => c.country === country)?.country : 'Select Country'}
                                                        </div>
                                                        {countryDropdownOpen && (
                                                            <div className="custom-dropdown-new">
                                                                <input
                                                                    type="text"
                                                                    className="search-bar"
                                                                    placeholder="Search..."
                                                                    value={countrySearchTerm}
                                                                    onChange={handleCountrySearchInputChange}
                                                                />
                                                                <ul className="country-list">
                                                                    {filteredCountries.length > 0 &&
                                                                        filteredCountries.map((c) => (
                                                                            <li
                                                                                key={c.country}
                                                                                className={country === c.country ? 'selected-item' : ''}
                                                                                onClick={() => handleCountryChange(c.country)}
                                                                            >
                                                                                {c.country}
                                                                            </li>
                                                                        ))}
                                                                </ul>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    {states.length > 0 && (
                                        <div className="inner-detail-outer">
                                            <div className="inner-detail">
                                                <span className="icon">
                                                    <img src={location} alt="Location Icon" />
                                                </span>
                                                <div className="inner-detail-inner">
                                                    <label>State *</label>
                                                </div>
                                            </div>
                                            <div className="inner-detail">
                                                <span className="icon"></span>
                                                {isLoading ? (
                                                    <ThreeDots
                                                        height="18"
                                                        width="20"
                                                        radius="9"
                                                        color="#6479f9"
                                                        ariaLabel="three-dots-loading"
                                                        wrapperStyle={{}}
                                                        wrapperClassName=""
                                                        visible={true}
                                                    />
                                                ) : (
                                                    <div className="inner-detail-inner">

                                                        <div className={`custom-dropdown-user-new-outer custom-dropdown-select ${stateDropdownOpen ? 'open' : ''}`} ref={stateDropdownRef}>
                                                            <div className="selected-option custom-dropdown-user-new"
                                                                onClick={
                                                                    () => {
                                                                        setStateDropdownOpen(!stateDropdownOpen);
                                                                        setStateSearchTerm('');
                                                                    }
                                                                }
                                                                style={{ cursor: 'pointer' }}
                                                            >
                                                                {state ? states.find((s) => s.state === state)?.state : 'Select State'}
                                                            </div>
                                                            {stateDropdownOpen && (
                                                                <div className="custom-dropdown-new">
                                                                    <input
                                                                        type="text"
                                                                        className="search-bar"
                                                                        placeholder="Search..."
                                                                        value={stateSearchTerm}
                                                                        onChange={handleStateSearchInputChange}
                                                                    />
                                                                    <ul className="state-list">
                                                                        {filteredStates.length > 0 &&
                                                                            filteredStates.map((s) => (
                                                                                <li
                                                                                    key={s.state}
                                                                                    className={state === s.state ? 'selected-item' : ''}
                                                                                    onClick={() => {
                                                                                        setState(s.state);
                                                                                        setStateDropdownOpen(false);
                                                                                    }}
                                                                                >
                                                                                    {s.state}
                                                                                </li>
                                                                            ))}
                                                                    </ul>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}

                                    <div className="inner-detail-outer">
                                        <div className="inner-detail">
                                            <span className="icon">
                                                <img src={location} alt="Location Icon" />
                                            </span>
                                            <div className="inner-detail-inner">
                                                <label>City *</label>
                                            </div>
                                        </div>
                                        <div className="inner-detail">
                                            <span className="icon"></span>
                                            {isLoading ? (
                                                <ThreeDots
                                                    height="18"
                                                    width="20"
                                                    radius="9"
                                                    color="#6479f9"
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClassName=""
                                                    visible={true}
                                                />
                                            ) : (
                                                <div className="inner-detail-inner">
                                                    <input type="text" id="city" value={city} onChange={(e) => setCity(e.target.value)} required />
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className="inner-detail-outer">
                                        <div className="inner-detail">
                                            <span class="icon"><img src={location} alt="type" /></span>
                                            <div className="inner-detail-inner">
                                                <label for="address">Address *</label>
                                            </div>
                                        </div>
                                        <div className="inner-detail">
                                            <span class="icon"></span>
                                            {isLoading ? (
                                                <ThreeDots
                                                    height="18"
                                                    width="20"
                                                    radius="9"
                                                    color="#6479f9"
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClassName=""
                                                    visible={true}
                                                />
                                            ) : (
                                                <div className="inner-detail-inner">
                                                    <input type="text" id="address" value={address} onChange={(e) => setAddress(e.target.value)} rows="4" required />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="information-right">
                                    <div className="inner-detail-outer">
                                        <div className="inner-detail">
                                            <span class="icon"><img src={PostalCode} alt="type" /></span>
                                            <div className="inner-detail-inner">
                                                <label for="postal_code">Postal Code </label>
                                            </div>
                                        </div>
                                        <div className="inner-detail">
                                            <span class="icon"></span>
                                            {isLoading ? (
                                                <ThreeDots
                                                    height="18"
                                                    width="20"
                                                    radius="9"
                                                    color="#6479f9"
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClassName=""
                                                    visible={true}
                                                />
                                            ) : (
                                                <div className="inner-detail-inner">
                                                    <input type="text" id="postal_code" value={postCode} onChange={(e) => setPostCode(e.target.value)} />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="inner-detail-outer">
                                        <div className="inner-detail">
                                            <span class="icon"><img src={Tele} alt="type" /></span>
                                            <div className="inner-detail-inner">
                                                <label for="number">Mobile No. *</label>
                                            </div>
                                        </div>
                                        <div className="inner-detail">
                                            <span class="icon"></span>
                                            {isLoading ? (
                                                <ThreeDots
                                                    height="18"
                                                    width="20"
                                                    radius="9"
                                                    color="#6479f9"
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClassName=""
                                                    visible={true}
                                                />
                                            ) : (
                                                <div className="inner-detail-inner">
                                                    <PhoneInput
                                                        placeholder="Enter phone number"
                                                        value={mobileNo}
                                                        onChange={setMobileNo}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
                <div className="save-button-sec">
                    <a href="#"
                        className="cancel-btn"
                        onClick={(e) => {
                            e.preventDefault();
                            if (!isSubmitLoading) {
                                handleCancel();
                            }
                        }} >Cancel
                    </a>
                    <button type="submit" className="save-btn" disabled={isSubmitLoading}>
                        {isSubmitLoading ?
                            <div className="outter-load-table">
                                <ThreeDots
                                    height="38"
                                    width="40"
                                    radius="9"
                                    color="white"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                />
                            </div> :
                            <>
                                <img src={Tick} alt="arrow" /> Save changes
                            </>
                        }
                    </button>
                </div>
            </form>
        </div>
    );
};

export default MasterUserProfileEdit;