import React, { useRef, useState, useEffect } from "react";
import Modal from "react-modal";
import "../ReportingTimeOffRequest/ReportingTimeOffRequest.css";
import DatePicker from "react-datepicker";
import { useSelector, useDispatch } from 'react-redux';
import { toast } from "react-toastify";
import * as XLSX from "xlsx";
import moment from "moment";
import Calendar from "../../../../helpers/dateComponents/Calendar";
import DownloadImage from "../../../../images/icons/download-icon.svg";
import TruncateUserName from "../../../helpers/truncateUserName";
import invStatusConsts from "../../../../constants/invStatusConsts";
import paymntConsts from "../../../../constants/invPaymetConsts";
import { setReportingReimbursmentDateRange, setReportingReimbursmentInvPaymentStatus, setReportingReimbursmentInvStatus, setReportingReimbursmentUser } from "../../../../features/reporting/reportingPersonnel/reportingReimbursmentSlice";

function ReportReimbursmentFilter({ reimbursementInv, users }) {
  const dispatch = useDispatch();
  const userDropdownRef = useRef(null);
  const invStatusDropdownRef = useRef(null);
  const invPaymentStatusDropdownRef = useRef(null);
  const userModalDropdownRef = useRef(null);
  const invStatusModalDropdownRef = useRef(null);
  const invPaymentStatusModalDropdownRef = useRef(null);

  const [isUserDropdownOpen, setIsUserDropdownOpen] = useState(false);
  const [isInvStatusDropdownOpen, setIsInvStatusDropdownOpen] = useState(false);
  const [isInvPaymentStatusDropdownOpen, setIsInvPaymentStatusDropdownOpen] = useState(false);
  const [isUserModalDropdownOpen, setIsUserModalDropdownOpen] =
    useState(false);
  const [isInvStatusModalDropdownOpen, setIsInvStatusModalDropdownOpen] =
    useState(false);
  const [isInvPaymentStatusModalDropdownOpen, setIsInvPaymentStatusModalDropdownOpen] =
    useState(false);
  const [userDropdownSearchTerm, setUserDropdownSearchTerm] = useState('');
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectAllUser, setSelectAllUser] = useState(true);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectAllInvStatus, setSelectAllInvStatus] = useState(true);
  const [sortedInvStatus, setSortedInvStatus] = useState([]);
  const [selectedInvStatus, setSelectedInvStatus] = useState([]);
  const [selectAllInvPaymentStatus, setSelectAllInvPaymentStatus] = useState(true);
  const [sortedInvPaymentStatus, setSortedInvPaymentStatus] = useState([]);
  const [selectedInvPaymentStatus, setSelectedInvPaymentStatus] = useState([]);
  const firstDayOfYear = moment.tz(global.config.getTimeZone()).startOf("year").toDate();
  const [showCalendar, setShowCalendar] = useState(false);
  const [showModalCalendar, setShowModalCalendar] = useState(false);
  const [dateRange, setDateRange] = useState([firstDayOfYear, new Date()]);
  const reportingReimbursment = useSelector((state) => state.reportingReimbursment);
  const currentYear = new Date().getFullYear();
  // const [year, setYear] = useState(new Date(currentYear, 0, 1));
  const calendarRef = useRef(null);
  const calendarModalRef = useRef(null);

  const dummyUsers = [
    { _id: 1, user_name: "User A" },
    { _id: 2, user_name: "User B" },
    { _id: 3, user_name: "User C" },
  ];

  const dummyInvStatuses = [
    { _id: 1, invStatus: "Braintrust" },
    { _id: 2, invStatus: "TalenOn" },
    { _id: 3, invStatus: "Gruporesidencial" },
    { _id: 4, invStatus: "Mudhouse" },
  ];

  const dummyInvPaymentStatuses = [
    { _id: 1, invPaymentStatus: "INR" },
    { _id: 2, invPaymentStatus: "DOllar" },
    { _id: 3, invPaymentStatus: "EURO" },
    { _id: 4, invPaymentStatus: "MYR" },
  ];

  const dummyRanges = [
    { label: "Today", value: new Date() },
    {
      label: "Yesterday",
      value: new Date(new Date().setDate(new Date().getDate() - 1)),
    },
    {
      label: "Last 7 Days",
      value: new Date(new Date().setDate(new Date().getDate() - 7)),
    },
  ];

  const invStatusMap = Object.keys(invStatusConsts).reduce((acc, key) => {
    acc[invStatusConsts[key]] = key.replace('_', ' ');
    return acc;
  }, {});

  const invPaymentStatusMap = Object.keys(paymntConsts).reduce((acc, key) => {
    acc[paymntConsts[key]] = key.replace('_', ' ');
    return acc;
  }, {});

  const toggleUserDropdown = () => {
    setIsUserDropdownOpen(!isUserDropdownOpen);
    setUserDropdownSearchTerm('');
  };

  const toggleInvStatusDropdown = () => {
    setIsInvStatusDropdownOpen(!isInvStatusDropdownOpen);
  };

  const toggleInvPaymentStatusDropdown = () => {
    setIsInvPaymentStatusDropdownOpen(!isInvPaymentStatusDropdownOpen);
  };

  const toggleUserModalDropdown = () => {
    setIsUserModalDropdownOpen(!isUserModalDropdownOpen);
    setUserDropdownSearchTerm('');
  };

  const toggleInvStatusModalDropdown = () => {
    setIsInvStatusModalDropdownOpen(!isInvStatusModalDropdownOpen);
  };

  const toggleInvPaymentStatusModalDropdown = () => {
    setIsInvPaymentStatusModalDropdownOpen(!isInvPaymentStatusModalDropdownOpen);
  };

  const toggleFilterModal = () => {
    setShowFilterModal(!showFilterModal);
  };

  const applyFilters = () => {
    setShowFilterModal(false);
  };

  const handleApplyConfirm = () => {
    setShowConfirmationModal(false);
    setShowFilterModal(false);
  };

  const clearFilters = () => {
    const uniqueStatuses = Array.from(new Set(reimbursementInv.map(inv => inv.inv_status)));
    const uniquePaymentStatuses = Array.from(new Set(reimbursementInv.map(inv => inv.inv_payment_status)));
    setSelectedUsers(users.map((user) => user._id));
    setSelectedInvStatus(uniqueStatuses);
    setSelectedInvPaymentStatus(uniquePaymentStatuses);
    setDateRange([firstDayOfYear, new Date()]);
    setShowConfirmationModal(false);
    setShowFilterModal(false);
  };

  const handleSelectAllUserChange = () => {
    if (!selectAllUser) {
      setSelectedUsers(users.map((user) => user._id));
    } else {
      setSelectedUsers([]);
    }
    setSelectAllUser(!selectAllUser);
  };

  const filteredDropdownUsers = users.filter((user) => {
    const fullName = user.first_name
      ? `${user.first_name} ${user.middle_name || ''} ${user.last_name}`
      : `${user.company_first_name ? user.company_first_name + (user.company_last_name && user.company_last_name) : user.company_name}`;
    return fullName?.toLowerCase().includes(userDropdownSearchTerm?.toLowerCase());
  });

  const handleUserDropdownSearchChange = (e) => {
    setUserDropdownSearchTerm(e.target.value);
  };

  const handleCheckboxUserChange = (userId) => {
    const updatedSelection = [...selectedUsers];
    const index = updatedSelection.indexOf(userId);

    if (index === -1) {
      updatedSelection.push(userId);
    } else {
      updatedSelection.splice(index, 1);
    }
    setSelectedUsers(updatedSelection);
  };

  useEffect(() => {
    const processData = () => {
      setSelectAllUser(selectedUsers.length === users.length);
      if (selectedUsers.length === users.length) {
        // setUsersFilter("All");
        selectUser(selectedUsers);
      } else {
        // setusersFilter(selectedUsers);
        selectUser(selectedUsers);
      }
    };

    // Defer the execution to the next event loop for non-blocking behavior
    const timeoutId = setTimeout(() => {
      processData();
    }, 0);

    return () => clearTimeout(timeoutId); // Cleanup timeout on dependency change
  }, [selectedUsers, users]);

  const handleSelectAllInvStatusChange = () => {
    if (!selectAllInvStatus) {
      // setSelectedInvStatus(['Pending', 'Approved', 'Rejected', 'Cancelled']);
      setSelectedInvStatus(sortedInvStatus);
    } else {
      setSelectedInvStatus([]);
    }
    setSelectAllInvStatus(!selectAllInvStatus);
  };

  const handleCheckboxInvStatusChange = (invStatusId) => {
    const updatedStatusSelection = [...selectedInvStatus];
    const index = updatedStatusSelection.indexOf(invStatusId);

    if (index === -1) {
      updatedStatusSelection.push(invStatusId);
    } else {
      updatedStatusSelection.splice(index, 1);
    }
    setSelectedInvStatus(updatedStatusSelection);
  };

  useEffect(() => {
    const processData = () => {
      setSelectAllInvStatus(selectedInvStatus.length === sortedInvStatus.length);
      if (selectedInvStatus.length === sortedInvStatus.length) {
        // setStatusesFilter("All");
        selectInvStatus(selectedInvStatus);
      } else {
        // setStatusesFilter(selectedInvStatus);
        selectInvStatus(selectedInvStatus);
      }
    };

    // Defer the execution to the next event loop for non-blocking behavior
    const timeoutId = setTimeout(() => {
      processData();
    }, 0);

    return () => clearTimeout(timeoutId); // Cleanup timeout on dependency change
  }, [selectedInvStatus, reimbursementInv]);

  const handleSelectAllInvPaymentStatusChange = () => {
    if (!selectAllInvPaymentStatus) {
      setSelectedInvPaymentStatus(sortedInvPaymentStatus);
    } else {
      setSelectedInvPaymentStatus([]);
    }
    setSelectAllInvPaymentStatus(!selectAllInvPaymentStatus);
  };

  const handleCheckboxInvPaymentStatusChange = (invPaymentStatusId) => {
    const updatedSelection = [...selectedInvPaymentStatus];
    const index = updatedSelection.indexOf(invPaymentStatusId);

    if (index === -1) {
      updatedSelection.push(invPaymentStatusId);
    } else {
      updatedSelection.splice(index, 1);
    }

    setSelectedInvPaymentStatus(updatedSelection);
  };

  useEffect(() => {
    const processData = () => {
      setSelectAllInvPaymentStatus(selectedInvPaymentStatus.length === sortedInvPaymentStatus.length);
      if (selectedInvPaymentStatus.length === sortedInvPaymentStatus.length) {
        selectInvPaymentStatus(selectedInvPaymentStatus);
      } else {
        selectInvPaymentStatus(selectedInvPaymentStatus);
      }
    };

    // Defer the execution to the next event loop for non-blocking behavior
    const timeoutId = setTimeout(() => {
      processData();
    }, 0);

    return () => clearTimeout(timeoutId); // Cleanup timeout on dependency change
  }, [selectedInvPaymentStatus, reimbursementInv]);

  const onDateChange = (ranges) => {
    // moment.tz(item.date,'YYYY-MM-DD HH:mm:ss',global.config.getTimeZone()).format()
    const dateRangeArray = [ranges.startDate, ranges.endDate];
    setDateRange(dateRangeArray);
    selectDateRange(dateRangeArray);
    if (ranges === null) {
      const firstDayOfYear = new Date(new Date().getFullYear(), 0, 1);
      setDateRange([firstDayOfYear, new Date()]);
      selectDateRange([firstDayOfYear, new Date()]);
    }
    setShowCalendar(false);
    setShowModalCalendar(false);
  };

  const toggleCalendar = () => {
    setShowCalendar(!showCalendar);
  };

  const cancelCalendar = () => {
    setShowCalendar(false);
  };

  const toggleModalCalendar = () => {
    setShowModalCalendar(!showModalCalendar);
  };

  const cancelModalCalendar = () => {
    setShowModalCalendar(false);
  };

  const selectUser = async (user) => {
    await dispatch(setReportingReimbursmentUser(user));
  }

  const selectInvStatus = async (invStatus) => {
    await dispatch(setReportingReimbursmentInvStatus(invStatus));
  }

  const selectInvPaymentStatus = async (InvPaymentStatus) => {
    await dispatch(setReportingReimbursmentInvPaymentStatus(InvPaymentStatus));
  }

  const selectDateRange = async (date) => {
    await dispatch(setReportingReimbursmentDateRange(date));
  };

  const checkAndShowConfirmationModal = () => {
    if (selectedUsers.length || selectedInvStatus.length || dateRange) {
      setShowConfirmationModal(true);
    } else {
      setShowFilterModal(false);
    }
  };

  useEffect(() => {
    const processData = () => {
      const currentYear = new Date().getFullYear();
      setSelectedUsers(users.map((user) => user._id));

      const firstDayOfYear = new Date(currentYear, 0, 1);
      setDateRange([firstDayOfYear, new Date()]);
      selectDateRange([firstDayOfYear, new Date()]);

      const uniquePaymentStatuses = Array.from(new Set(reimbursementInv.map(inv => inv.inv_payment_status)));
      setSortedInvPaymentStatus(uniquePaymentStatuses);
      setSelectedInvPaymentStatus(uniquePaymentStatuses);

      const uniqueStatuses = Array.from(new Set(reimbursementInv.map(inv => inv.inv_status)));
      setSortedInvStatus(uniqueStatuses);
      setSelectedInvStatus(uniqueStatuses);
    };

    // Defer execution to the next event loop
    const timeoutId = setTimeout(() => {
      processData();
    }, 0);

    return () => clearTimeout(timeoutId); // Cleanup timeout on dependency change
  }, [users, reimbursementInv]);

  const sortedStatusByLabel = [...sortedInvStatus].sort((a, b) => {
    if (invStatusMap[a] < invStatusMap[b]) return -1;
    if (invStatusMap[a] > invStatusMap[b]) return 1;
    return 0;
  });

  const sortedPaymentStatusByLabel = [...sortedInvPaymentStatus].sort((a, b) => {
    if (invPaymentStatusMap[a] < invPaymentStatusMap[b]) return -1;
    if (invPaymentStatusMap[a] > invPaymentStatusMap[b]) return 1;
    return 0;
  });

  // const calculateNumberOfDays = (startDate, startTime, endDate, endTime) => {
  //   if (!startTime || !endTime || startDate !== endDate) {
  //     const startDateTime = moment(startDate).tz(global.config.getTimeZone()).startOf('day');
  //     const endDateTime = moment(endDate).tz(global.config.getTimeZone()).startOf('day');
  //     const duration = moment.duration(endDateTime.diff(startDateTime));
  //     const numberOfDays = Math.ceil(duration.asDays());
  //     return `${numberOfDays + 1} days`;
  //   } else {
  //     const startDateTime = moment(startDate).tz(global.config.getTimeZone()).startOf('day').add(startTime, 'hours');
  //     const endDateTime = moment(endDate).tz(global.config.getTimeZone()).startOf('day').add(endTime, 'hours');
  //     const duration = moment.duration(endDateTime.diff(startDateTime));
  //     const numberOfDays = Math.ceil(duration.asDays());
  //     if (numberOfDays === 1) {
  //       const numberOfHours = (endTime - startTime) * numberOfDays;
  //       const numberOfDaysDecimal = (numberOfHours / 8).toFixed(2);
  //       const cappedNumberOfDaysDecimal = Math.min(numberOfDaysDecimal, 1);
  //       return `${cappedNumberOfDaysDecimal} days`;
  //     } else {
  //       return `${numberOfDays} days`;
  //     }
  //   }
  // };

  const handleClickOutsideDropdown = (event) => {
    const isClickInsideUserDropdown = userDropdownRef.current && userDropdownRef.current.contains(event.target);
    const isClickInsideUserModalDropdown = userModalDropdownRef.current && userModalDropdownRef.current.contains(event.target);
    const isClickInsideInvStatusDropdown = invStatusDropdownRef.current && invStatusDropdownRef.current.contains(event.target);
    const isClickInsideInvStatusModalDropdown = invStatusModalDropdownRef.current && invStatusModalDropdownRef.current.contains(event.target);
    const isClickInsideInvPaymentStatusDropdown = invPaymentStatusDropdownRef.current && invPaymentStatusDropdownRef.current.contains(event.target);
    const isClickInsideInvPaymentStatusModalDropdown = invPaymentStatusModalDropdownRef.current && invPaymentStatusModalDropdownRef.current.contains(event.target);
    const isClickInsideCalendarDropdown = calendarRef.current && calendarRef.current.contains(event.target);
    const isClickInsideModalCalendarDropdown = calendarModalRef.current && calendarModalRef.current.contains(event.target);

    if (!isClickInsideUserDropdown) {
      setIsUserDropdownOpen(false);
    }
    if (!isClickInsideUserModalDropdown) {
      setIsUserModalDropdownOpen(false);
    }
    if (!isClickInsideInvStatusDropdown) {
      setIsInvStatusDropdownOpen(false);
    }
    if (!isClickInsideInvStatusModalDropdown) {
      setIsInvStatusModalDropdownOpen(false);
    }
    if (!isClickInsideInvPaymentStatusDropdown) {
      setIsInvPaymentStatusDropdownOpen(false);
    }
    if (!isClickInsideInvPaymentStatusModalDropdown) {
      setIsInvPaymentStatusModalDropdownOpen(false);
    }
    if (!isClickInsideCalendarDropdown) {
      setShowCalendar(false);
    }
    if (!isClickInsideModalCalendarDropdown) {
      setShowModalCalendar(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutsideDropdown);

    return () => {
      document.removeEventListener("click", handleClickOutsideDropdown);
    };
  }, []);

  useEffect(() => {
    const processData = () => {
      if (reimbursementInv && reportingReimbursment) {
        const firstDayOfYear = moment.tz(global.config.getTimeZone()).startOf("year").toDate();
        const dateRange = reportingReimbursment.reimbursmentDateRange || [firstDayOfYear, new Date()];
        const startDate = moment(dateRange[0]);
        const endDate = moment(dateRange[1]);

        const filtered = reimbursementInv.filter((invoice) => {
          const invoiceEndDate = moment(invoice?.inv_end_date);
          return (
            invoice &&
            !invoice.is_delete &&
            (reportingReimbursment.reimbursmentUser && reportingReimbursment.reimbursmentUser.includes(invoice.inv_for._id)) &&
            (reportingReimbursment.reimbursmentInvStatus && reportingReimbursment.reimbursmentInvStatus.includes(invoice?.inv_status || 0)) &&
            (reportingReimbursment.reimbursmentInvPaymentStatus && reportingReimbursment.reimbursmentInvPaymentStatus.includes(invoice?.inv_payment_status || 0)) &&
            invoiceEndDate.isBetween(startDate, endDate, null, '[]')
          );
        });

        const sortedData = filtered.sort((a, b) => new Date(b.inv_created_at) - new Date(a.inv_created_at));
        setFilteredData(sortedData);
      }
    };

    // Defer execution to the next event loop
    const timeoutId = setTimeout(() => {
      processData();
    }, 0);

    return () => clearTimeout(timeoutId); // Cleanup timeout on dependency change
  }, [reimbursementInv, reportingReimbursment]);

  // const excelTimeConverter = (time) => {
  //   let hour = parseInt(time);
  //   let hours = parseInt(time);
  //   let minute = Math.round((time - hour) * 60);

  //   if (hour > 12) {
  //     hour -= 12;
  //   }

  //   if (minute === 0) {
  //     minute = "0";
  //   }

  //   return `${hour < 10 ? "0" + hour : hour}:${minute < 10 ? "0" + minute : minute} ${hours < 12 ? "am" : "pm"}`;
  // };

  const exportToExcel = () => {
    if (filteredData.length === 0) {
      toast.error("No data to export.", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    try {
      const now = new Date();
      const formattedDate =
        now.getFullYear() +
        "-" +
        (now.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        now.getDate().toString().padStart(2, "0");
      const formattedTime =
        now.getHours().toString().padStart(2, "0") +
        ":" +
        now.getMinutes().toString().padStart(2, "0") +
        ":" +
        now.getSeconds().toString().padStart(2, "0");

      const modifiedLog = [];
      filteredData.forEach((item, index) => {
        // Assuming these items in an array
        const descriptions = item.inv_description;
        const category = item.inv_category
        const costs = item.inv_cost;
        const taxs = item.inv_tax;

        if (descriptions && descriptions.length > 0) {
          descriptions.forEach((description, i) => {
            const cost = parseFloat(costs[i]);
            const tax = parseFloat(taxs[i]);
            const vat = (cost * (tax / 100)).toFixed(2);
            const amount = (cost + parseFloat(vat)).toFixed(2);
            modifiedLog.push({
              User: item.inv_for
                ? item.inv_for.first_name
                  ? `${item.inv_for.first_name} ${item.inv_for.middle_name ? item.inv_for.middle_name : ""
                  } ${item.inv_for.last_name ? item.inv_for.last_name : ""}`
                  : item.inv_for.company_first_name
                    ? `${item.inv_for.company_first_name} ${item.inv_for.company_last_name
                      ? item.inv_for.company_last_name
                      : ""
                    }`
                    : item.inv_for.company_name
                : "",
              "Category": category[i],
              "Creation Date": moment(item.inv_created_at)
                .tz(global.config.getTimeZone())
                .format("DD/MM/YYYY"),
              "Due Date": moment(item.inv_due_date)
                .tz(global.config.getTimeZone())
                .format("DD/MM/YYYY"),
              "Invoice Due Date": moment(item.inv_due_date)
                .tz(global.config.getTimeZone())
                .format("DD/MM/YYYY"),
              "Payment Status": item.inv_payment_details
                ? moment(item.inv_payment_details.created_at)
                  .tz(global.config.getTimeZone())
                  .format("DD/MM/YYYY, hh:mm:ss A")
                : "Not Updated",
              "Invoice Currency": item.inv_currency,
              Amount: amount,
            });
          });
        } else {
          modifiedLog.push({
            User: item.inv_for
              ? item.inv_for.first_name +
              " " +
              (item.inv_for.middle_name && item.inv_for.middle_name !== "null"
                ? " " + item.inv_for.middle_name
                : "") +
              " " +
              item.inv_for.last_name
              : "",
            "Category": item.inv_category,
            "Creation Date": moment(item.inv_created_at)
              .tz(global.config.getTimeZone())
              .format("DD/MM/YYYY"),
            "Due Date": moment(item.inv_due_date)
              .tz(global.config.getTimeZone())
              .format("DD/MM/YYYY"),
            "Payment Status": item.inv_payment_details
              ? moment(item.inv_payment_details.created_at)
                .tz(global.config.getTimeZone())
                .format("DD/MM/YYYY, hh:mm:ss A")
              : "Not Updated",
            "Invoice Currency": item.inv_currency,
            Amount: "",
          });
        }
      });

      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(modifiedLog);
      const colWidths = [
        { wpx: 170 },
        { wpx: 100 },
        { wpx: 100 },
        { wpx: 100 },
        { wpx: 125 },
        { wpx: 125 },
        { wpx: 100 }
      ];
      worksheet["!cols"] = colWidths;

      // Add alignment to center align the first row and increase font size
      const firstRow = XLSX.utils.decode_range(worksheet["!ref"]).s.r;
      for (let i = 0; i < colWidths.length; i++) {
        const cell = XLSX.utils.encode_cell({ r: firstRow, c: i });
        const style = worksheet[cell]?.s || {};
        style.alignment = style.alignment || {};
        style.alignment.horizontal = "center !important";
        style.alignment.vertical = "center !important";
        style.font = { size: 14, bold: true };
        worksheet[cell] = { ...worksheet[cell], s: style };
      }

      // Set border for active area and increase font size
      const range = XLSX.utils.decode_range(worksheet["!ref"]);
      for (let row = range.s.r + 1; row <= range.e.r; row++) {
        for (let col = range.s.c; col <= range.e.c; col++) {
          const cell = XLSX.utils.encode_cell({ r: row, c: col });
          const style = worksheet[cell]?.s || {};
          style.font = { size: 12 };
          worksheet[cell] = { ...worksheet[cell], s: style };
        }
      }
      global.config.activityLog(
        window.location.href,
        "Reimbursement Invoice",
        "Reimbursement Invoices List exported to Excel"
      );
      const fileName = `Reimbursement_Invoice_Report_${formattedDate}_${formattedTime}.xlsx`;
      worksheet["!autofilter"] = { ref: range };
      XLSX.utils.book_append_sheet(workbook, worksheet, "Reimbursement Invoice Report");
      XLSX.writeFile(workbook, fileName);
    } catch (error) {
      console.log(error);
      global.config.slackMessage(error.toString());
      global.config.activityLog(
        window.location.href,
        "Invoice",
        "Error occurred while exporting reimbursement INVOICES to Excel"
      );
      alert("Error occurred while exporting to Excel.");
    }
  };

  return (
    <>
      <div className="report-top-button">
        <div className="button-inner-report">
          <a onClick={exportToExcel}>
            <span className="icon-export">
              <img src={DownloadImage} alt="Export" />
            </span>
            Export Data
          </a>
        </div>
      </div>
      <div className="invoice-filter invoice-filter-report">
        <div className="invoice-filter-inner-section no-flter-all">
          <div className="filter-inner users">
            <label htmlFor="fname">User</label>
            <div className="custom-dropdown1" ref={userDropdownRef}>
              <div className="drop-down-inner">
                <div className="selected-options1" onClick={toggleUserDropdown} >
                  {selectedUsers.length === 0 ? (
                    <div className="placeholder">Select</div>
                  ) : (
                    <div className="selected-option1">
                      {selectedUsers.length === users.length
                        ? "All"
                        : (
                          <>
                            <TruncateUserName
                              textContent={users && Array.isArray(users) && users.find((item) => item._id === selectedUsers[0])?.first_name
                                ? `${users.find((item) => item._id === selectedUsers[0]).first_name} ${users.find((item) => item._id === selectedUsers[0]).middle_name
                                  ? users.find((item) => item._id === selectedUsers[0]).middle_name
                                  : ''
                                } ${users.find((item) => item._id === selectedUsers[0]).last_name
                                  ? users.find((item) => item._id === selectedUsers[0]).last_name
                                  : ''
                                }`
                                : (
                                  users.find((item) => item._id === selectedUsers[0])?.company_first_name
                                    ? `${users.find((item) => item._id === selectedUsers[0]).company_first_name} ${users.find((item) => item._id === selectedUsers[0]).company_last_name
                                      ? users.find((item) => item._id === selectedUsers[0]).company_last_name
                                      : ''
                                    }`
                                    : `${users.find((item) => item._id === selectedUsers[0])?.company_name} (Company)`
                                )} />
                            {selectedUsers.length > 1 && selectedUsers.length !== users.length && (
                              <div className="selected-option-count">
                                {selectedUsers.length - 1}+
                              </div>
                            )}
                          </>
                        )}
                    </div>
                  )}
                </div>
                {isUserDropdownOpen && (
                  <div className="dropdown-list1">
                    <input
                      type="text"
                      placeholder="Search teams"
                      value={userDropdownSearchTerm}
                      onChange={handleUserDropdownSearchChange}
                      className="search-bar"
                    />
                    {filteredDropdownUsers.length > 0 && (
                      <>
                        {userDropdownSearchTerm === "" && (
                          <label className="checkbox-label1" key="selectAll">
                            <input
                              type="checkbox"
                              checked={
                                selectedUsers.length === users.length
                              }
                              onChange={handleSelectAllUserChange}
                            />
                            All
                          </label>
                        )}
                        {filteredDropdownUsers
                          .slice()
                          .sort((a, b) => {
                            const nameA = a.first_name
                              ? `${a.first_name} ${a.middle_name || ''} ${a.last_name}`
                              : `${a.company_first_name ? a.company_first_name + (a.company_last_name && a.company_last_name) : a.company_name}`;
                            const nameB = b.first_name
                              ? `${b.first_name} ${b.middle_name || ''} ${b.last_name}`
                              : `${b.company_first_name ? b.company_first_name + (b.company_last_name && b.company_last_name) : b.company_name}`;
                            return nameA.localeCompare(nameB);
                          })
                          .map((user) => (
                            <label className="checkbox-label1" key={user._id}>
                              <input
                                type="checkbox"
                                checked={selectedUsers.includes(user._id)}
                                onChange={() => handleCheckboxUserChange(user._id)}
                              />
                              {user.first_name ? (
                                <>
                                  {user.first_name}{' '}
                                  {user.middle_name && user.middle_name !== 'null' ? user.middle_name + ' ' : ''}
                                  {user.last_name}{' '}
                                </>
                              ) : (
                                <>
                                  {user.company_first_name
                                    ? `${user.company_first_name} ${user.company_last_name ? user.company_last_name : ''}`
                                    : `${user.company_name || ''}(Company)`}
                                </>
                              )}
                            </label>
                          ))}
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="filter-inner invoice-status">
            <label htmlFor="invoiceproject">Invoice Status</label>
            <div className="custom-dropdown1" ref={invStatusDropdownRef}>
              <div className="drop-down-inner">
                <div
                  className="selected-options1"
                  onClick={toggleInvStatusDropdown}
                >
                  {selectedInvStatus.length === 0 ? (
                    <div className="placeholder">Select</div>
                  ) : (
                    <>
                      <div className="selected-option1">
                        {selectedInvStatus.length === sortedInvStatus.length
                          ? "All" : invStatusMap[selectedInvStatus[0]]}
                      </div>
                      {selectedInvStatus.length > 1 &&
                        selectedInvStatus.length !== sortedInvStatus.length && (
                          <div className="selected-option-count">
                            {selectedInvStatus.length - 1}+
                          </div>
                        )}
                    </>
                  )}
                </div>
                {isInvStatusDropdownOpen && (
                  <div className="dropdown-list1">
                    {sortedInvStatus.length > 0 && (
                      <>
                        <label className="checkbox-label1" key="selectAll">
                          <input
                            type="checkbox"
                            checked={selectedInvStatus.length === sortedInvStatus.length}
                            onChange={handleSelectAllInvStatusChange}
                          />
                          All
                        </label>
                        {sortedStatusByLabel.map((invStatus, index) => (
                          <label className="checkbox-label1" key={index}>
                            <input
                              type="checkbox"
                              checked={selectedInvStatus.includes(invStatus)}
                              onChange={() =>
                                handleCheckboxInvStatusChange(invStatus)
                              }
                            />
                            {invStatusMap[invStatus]}
                          </label>
                        ))}
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="filter-inner invoice-status">
            <label htmlFor="invoiceproject">Payment Status</label>
            <div className="custom-dropdown1" ref={invPaymentStatusDropdownRef}>
              <div className="drop-down-inner">
                <div
                  className="selected-options1"
                  onClick={toggleInvPaymentStatusDropdown}
                >
                  {selectedInvPaymentStatus.length === 0 ? (
                    <div className="placeholder">Select</div>
                  ) : (
                    <>
                      <div className="selected-option1">
                        {selectedInvPaymentStatus.length === sortedInvPaymentStatus.length
                          ? "All" : invPaymentStatusMap[selectedInvPaymentStatus[0]]}
                      </div>
                      {selectedInvPaymentStatus.length > 1 &&
                        selectedInvPaymentStatus.length !== sortedInvPaymentStatus.length && (
                          <div className="selected-option-count">
                            {selectedInvPaymentStatus.length - 1}+
                          </div>
                        )}
                    </>
                  )}
                </div>
                {isInvPaymentStatusDropdownOpen && (
                  <div className="dropdown-list1">
                    {sortedInvPaymentStatus.length > 0 && (
                      <>
                        <label className="checkbox-label1" key="selectAll">
                          <input
                            type="checkbox"
                            checked={selectedInvPaymentStatus.length === sortedInvPaymentStatus.length}
                            onChange={handleSelectAllInvPaymentStatusChange}
                          />
                          All
                        </label>
                        {sortedPaymentStatusByLabel.map((invPaymentStatus, index) => (
                          <label className="checkbox-label1" key={index}>
                            <input
                              type="checkbox"
                              checked={selectedInvPaymentStatus.includes(invPaymentStatus)}
                              onChange={() =>
                                handleCheckboxInvPaymentStatusChange(invPaymentStatus)
                              }
                            />
                            {invPaymentStatusMap[invPaymentStatus]}
                          </label>
                        ))}
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="filter-inner due-date">
            <label htmlFor="dueDate" className="calendar-icon">
              Timeframe
            </label>
            <div
              className="date-picker-outer date-picker-outer-all"
              ref={calendarRef}
            >
              <div className="custom-picker-icon custom-picker-icon-all">
                <svg
                  width="1em"
                  height="1em"
                  viewBox="0 0 14 14"
                  fill="currentColor"
                  aria-hidden="true"
                  focusable="false"
                  class="rs-picker-toggle-caret rs-icon"
                  aria-label="calendar"
                  data-category="legacy"
                >
                  <path d="M1 4v8.5a.5.5 0 00.5.5H8c0-.128.049-.256.146-.354.555-.555.854-1.6.854-3.146a.5.5 0 01.621-.485l.119.03A2.623 2.623 0 0012.999 6.5V4h-12zm3-2h6V0h1v2h1.5A1.5 1.5 0 0114 3.5v3a3.623 3.623 0 01-4.015 3.603c-.064 1.245-.335 2.212-.831 2.898H12.5a.5.5 0 00.5-.5v-2a.5.5 0 011 0v2a1.5 1.5 0 01-1.5 1.5h-11a1.5 1.5 0 01-1.5-1.5v-9a1.5 1.5 0 011.5-1.5H3v-2h1v2zm5 4V5h1v1h2v1h-2v1H9V7H7v2h1v1H7v2H6v-2H4v2H3v-2H1V9h2V7H1V6h2V5h1v1h2V5h1v1h2zM6 9V7H4v2h2z"></path>
                </svg>
              </div>
              <div className="custoom-icon-calender custoom-icon-calender-all">
                <div className="custoom-icon-calender custoom-icon-calender-all custom-date-range ">
                  <input
                    type="text"
                    value={
                      dateRange
                        ? `${new Date(dateRange[0]).toLocaleDateString(
                          "en-GB",
                          {
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric",
                          }
                        )} to ${new Date(dateRange[1]).toLocaleDateString(
                          "en-GB",
                          {
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric",
                          }
                        )}`
                        : null
                    }
                    id="invoiceId"
                    name="invoiceId"
                    placeholder="All Time"
                    onClick={toggleCalendar}
                    autocomplete="off"
                    readOnly
                  />
                </div>
                <div className="date-range-new right-align">
                  {showCalendar && (
                    <Calendar
                      onCancel={cancelCalendar}
                      onChange={onDateChange}
                      initialRange={{
                        startDate: dateRange ? dateRange[0] : null,
                        endDate: dateRange ? dateRange[1] : null,
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="filter-invoice-right apply-fliter-right">
          <button onClick={toggleFilterModal}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="13"
              height="17"
              viewBox="0 0 13 17"
              fill="none"
            >
              <path
                d="M11.7363 8.81108C11.4276 9.81451 10.4925 10.5444 9.38889 10.5444C8.28471 10.5444 7.35016 9.8141 7.04147 8.81108L0.722221 8.81108C0.323349 8.81108 -1.07845e-06 8.48773 -1.06101e-06 8.08886C-1.04357e-06 7.68998 0.323349 7.36663 0.722221 7.36663L7.04144 7.36663C7.35022 6.3632 8.28529 5.6333 9.38889 5.6333C10.4931 5.6333 11.4276 6.36362 11.7363 7.36663L12.2778 7.36663C12.6767 7.36663 13 7.68998 13 8.08886C13 8.48773 12.6766 8.81108 12.2778 8.81108L11.7363 8.81108ZM9.38889 7.07775C8.83162 7.07775 8.37778 7.53123 8.37778 8.08886C8.37778 8.64613 8.83127 9.09997 9.38889 9.09997C9.94616 9.09997 10.4 8.64648 10.4 8.08886C10.4 7.53158 9.94651 7.07775 9.38889 7.07775Z"
                fill="#6479F8"
              />
              <path
                d="M5.38078 3.17778C5.072 4.18121 4.13693 4.91111 3.03333 4.91111C1.92882 4.91111 0.994035 4.18035 0.68563 3.17687C0.303759 3.15781 -1.07791e-06 2.84216 -1.06101e-06 2.45556C-1.04411e-06 2.06896 0.303748 1.75331 0.685606 1.73425C0.994098 0.730343 1.9294 -4.83911e-07 3.03333 -4.35657e-07C4.13752 -3.87392e-07 5.07206 0.730315 5.38076 1.73333L12.2778 1.73333C12.6767 1.73333 13 2.05668 13 2.45556C13 2.85443 12.6766 3.17778 12.2778 3.17778L5.38078 3.17778ZM3.03333 1.44444C2.47606 1.44444 2.02222 1.89793 2.02222 2.45556C2.02222 3.01283 2.47571 3.46667 3.03333 3.46667C3.5906 3.46667 4.04444 3.01318 4.04444 2.45556C4.04444 1.89828 3.59096 1.44444 3.03333 1.44444Z"
                fill="#6479F8"
              />
              <path
                d="M7.403 14.4444C7.09422 15.4478 6.15916 16.1777 5.05556 16.1777C3.95137 16.1777 3.01682 15.4474 2.70813 14.4444L0.722221 14.4444C0.323349 14.4444 -1.07845e-06 14.121 -1.06101e-06 13.7222C-1.04357e-06 13.3233 0.323349 12.9999 0.722221 12.9999L2.70811 12.9999C3.01689 11.9965 3.95195 11.2666 5.05556 11.2666C6.15974 11.2666 7.09429 11.9969 7.40298 12.9999L12.2778 12.9999C12.6767 12.9999 13 13.3233 13 13.7222C13 14.121 12.6766 14.4444 12.2778 14.4444L7.403 14.4444ZM5.05556 12.711C4.49828 12.711 4.04444 13.1645 4.04444 13.7222C4.04444 14.2794 4.49793 14.7333 5.05556 14.7333C5.61283 14.7333 6.06667 14.2798 6.06667 13.7222C6.06667 13.1649 5.61318 12.711 5.05556 12.711Z"
                fill="#6479F8"
              />
            </svg>
            All Filters
          </button>
        </div>

        <Modal
          isOpen={showFilterModal}
          onRequestClose={toggleFilterModal}
          contentLabel="Filter Modal"
          className="new-filter-modal"
          style={{
            content: {
              height: "50%",
              width: "50%",
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-25%, -25%)",
              borderRadius: "10px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              backgroundColor: "#fff",
              padding: "20px",
              // overflow: "auto",
            },
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            },
          }}
        >
          <div className="new-nodal-invoice-outer-sec">
            <div className="new-modal-top-sec">
              <div className="new-modal-heading">All filters</div>
              <a
                className="close-image-clr1"
                onClick={checkAndShowConfirmationModal}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                >
                  <path d="M1 13L13 1" stroke="black" />
                  <path d="M1 0.999999L13 13" stroke="black" />
                </svg>{" "}
              </a>
            </div>

            <div className="invoice-filter-inner-section">
              <div className="filter-inner users">
                <label htmlFor="fname">User</label>
                <div className="custom-dropdown1" ref={userModalDropdownRef}>
                  <div className="drop-down-inner">
                    <div
                      className="selected-options1"
                      onClick={toggleUserModalDropdown}
                    >
                      {selectedUsers.length === 0 ? (
                        <div className="placeholder">Select</div>
                      ) : (
                        <div className="selected-option1">
                          {selectedUsers.length === users.length
                            ? "All"
                            : (
                              <>
                                <TruncateUserName
                                  textContent={users && Array.isArray(users) && users.find((item) => item._id === selectedUsers[0])?.first_name
                                    ? `${users.find((item) => item._id === selectedUsers[0]).first_name} ${users.find((item) => item._id === selectedUsers[0]).middle_name
                                      ? users.find((item) => item._id === selectedUsers[0]).middle_name
                                      : ''
                                    } ${users.find((item) => item._id === selectedUsers[0]).last_name
                                      ? users.find((item) => item._id === selectedUsers[0]).last_name
                                      : ''
                                    }`
                                    : (
                                      users.find((item) => item._id === selectedUsers[0])?.company_first_name
                                        ? `${users.find((item) => item._id === selectedUsers[0]).company_first_name} ${users.find((item) => item._id === selectedUsers[0]).company_last_name
                                          ? users.find((item) => item._id === selectedUsers[0]).company_last_name
                                          : ''
                                        }`
                                        : `${users.find((item) => item._id === selectedUsers[0])?.company_name} (Company)`
                                    )} />
                                {selectedUsers.length > 1 && selectedUsers.length !== users.length && (
                                  <div className="selected-option-count">
                                    {selectedUsers.length - 1}+
                                  </div>
                                )}
                              </>
                            )}
                        </div>
                      )}
                    </div>
                    {isUserModalDropdownOpen && (
                      <div className="dropdown-list1">
                        <input
                          type="text"
                          placeholder="Search teams"
                          value={userDropdownSearchTerm}
                          onChange={handleUserDropdownSearchChange}
                          className="search-bar"
                        />
                        {filteredDropdownUsers.length > 0 && (
                          <>
                            {userDropdownSearchTerm === "" && (
                              <label className="checkbox-label1" key="selectAll">
                                <input
                                  type="checkbox"
                                  checked={selectedUsers.length === users.length}
                                  onChange={handleSelectAllUserChange}
                                />
                                All
                              </label>
                            )}
                            {filteredDropdownUsers
                              .slice()
                              .sort((a, b) => {
                                const nameA = a.first_name
                                  ? `${a.first_name} ${a.middle_name || ''} ${a.last_name}`
                                  : `${a.company_first_name ? a.company_first_name + (a.company_last_name && a.company_last_name) : a.company_name}`;
                                const nameB = b.first_name
                                  ? `${b.first_name} ${b.middle_name || ''} ${b.last_name}`
                                  : `${b.company_first_name ? b.company_first_name + (b.company_last_name && b.company_last_name) : b.company_name}`;
                                return nameA.localeCompare(nameB);
                              })
                              .map((user) => (
                                <label className="checkbox-label1" key={user._id}>
                                  <input
                                    type="checkbox"
                                    checked={selectedUsers.includes(user._id)}
                                    onChange={() => handleCheckboxUserChange(user._id)}
                                  />
                                  {user.first_name ? (
                                    <>
                                      {user.first_name}{' '}
                                      {user.middle_name && user.middle_name !== 'null' ? user.middle_name + ' ' : ''}
                                      {user.last_name}{' '}
                                    </>
                                  ) : (
                                    <>
                                      {user.company_first_name
                                        ? `${user.company_first_name} ${user.company_last_name ? user.company_last_name : ''}`
                                        : `${user.company_name || ''}(Company)`}
                                    </>
                                  )}
                                </label>
                              ))}
                          </>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="filter-inner invoice-project">
                <label htmlFor="invoiceproject">Invoice Status</label>
                <div className="custom-dropdown1" ref={invStatusModalDropdownRef}>
                  <div className="drop-down-inner">
                    <div
                      className="selected-options1"
                      onClick={toggleInvStatusModalDropdown}
                    >
                      {selectedInvStatus.length === 0 ? (
                        <div className="placeholder">Select</div>
                      ) : (
                        <>
                          <div className="selected-option1">
                            {selectedInvStatus.length === sortedInvStatus.length
                              ? "All" : invStatusMap[selectedInvStatus[0]]}
                          </div>
                          {selectedInvStatus.length > 1 &&
                            selectedInvStatus.length !== sortedInvStatus.length && (
                              <div className="selected-option-count">
                                {selectedInvStatus.length - 1}+
                              </div>
                            )}
                        </>
                      )}
                    </div>
                    {isInvStatusModalDropdownOpen && (
                      <div className="dropdown-list1">
                        {sortedInvStatus.length > 0 && (
                          <>
                            <label className="checkbox-label1" key="selectAll">
                              <input
                                type="checkbox"
                                checked={selectedInvStatus.length === sortedInvStatus.length}
                                onChange={handleSelectAllInvStatusChange}
                              />
                              All
                            </label>
                            {sortedStatusByLabel.map((invStatus, index) => (
                              <label className="checkbox-label1" key={index}>
                                <input
                                  type="checkbox"
                                  checked={selectedInvStatus.includes(invStatus)}
                                  onChange={() =>
                                    handleCheckboxInvStatusChange(invStatus)
                                  }
                                />
                                {invStatusMap[invStatus]}
                              </label>
                            ))}
                          </>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="filter-inner invoice-project">
                <label htmlFor="invoiceproject">Payment Status</label>
                <div className="custom-dropdown1" ref={invPaymentStatusModalDropdownRef}>
                  <div className="drop-down-inner">
                    <div
                      className="selected-options1"
                      onClick={toggleInvPaymentStatusModalDropdown}
                    >
                      {selectedInvPaymentStatus.length === 0 ? (
                        <div className="placeholder">Select</div>
                      ) : (
                        <>
                          <div className="selected-option1">
                            {selectedInvPaymentStatus.length === sortedInvPaymentStatus.length
                              ? "All" : invPaymentStatusMap[selectedInvPaymentStatus[0]]}
                          </div>
                          {selectedInvPaymentStatus.length > 1 &&
                            selectedInvPaymentStatus.length !== sortedInvPaymentStatus.length && (
                              <div className="selected-option-count">
                                {selectedInvPaymentStatus.length - 1}+
                              </div>
                            )}
                        </>
                      )}
                    </div>
                    {isInvPaymentStatusModalDropdownOpen && (
                      <div className="dropdown-list1">
                        {sortedInvPaymentStatus.length > 0 && (
                          <>
                            <label className="checkbox-label1" key="selectAll">
                              <input
                                type="checkbox"
                                checked={selectedInvPaymentStatus.length === sortedInvPaymentStatus.length}
                                onChange={handleSelectAllInvPaymentStatusChange}
                              />
                              All
                            </label>
                            {sortedPaymentStatusByLabel.map((invPaymentStatus, index) => (
                              <label className="checkbox-label1" key={index}>
                                <input
                                  type="checkbox"
                                  checked={selectedInvPaymentStatus.includes(invPaymentStatus)}
                                  onChange={() =>
                                    handleCheckboxInvPaymentStatusChange(invPaymentStatus)
                                  }
                                />
                                {invPaymentStatusMap[invPaymentStatus]}
                              </label>
                            ))}
                          </>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="filter-inner due-date">
                <label htmlFor="dueDate" className="calendar-icon">
                  Timeframe
                </label>
                <div
                  className="date-picker-outer date-picker-outer-all"
                  ref={calendarModalRef}
                >
                  <div className="custom-picker-icon custom-picker-icon-all">
                    <svg
                      width="1em"
                      height="1em"
                      viewBox="0 0 14 14"
                      fill="currentColor"
                      aria-hidden="true"
                      focusable="false"
                      class="rs-picker-toggle-caret rs-icon"
                      aria-label="calendar"
                      data-category="legacy"
                    >
                      <path d="M1 4v8.5a.5.5 0 00.5.5H8c0-.128.049-.256.146-.354.555-.555.854-1.6.854-3.146a.5.5 0 01.621-.485l.119.03A2.623 2.623 0 0012.999 6.5V4h-12zm3-2h6V0h1v2h1.5A1.5 1.5 0 0114 3.5v3a3.623 3.623 0 01-4.015 3.603c-.064 1.245-.335 2.212-.831 2.898H12.5a.5.5 0 00.5-.5v-2a.5.5 0 011 0v2a1.5 1.5 0 01-1.5 1.5h-11a1.5 1.5 0 01-1.5-1.5v-9a1.5 1.5 0 011.5-1.5H3v-2h1v2zm5 4V5h1v1h2v1h-2v1H9V7H7v2h1v1H7v2H6v-2H4v2H3v-2H1V9h2V7H1V6h2V5h1v1h2V5h1v1h2zM6 9V7H4v2h2z"></path>
                    </svg>
                  </div>
                  <div className="custoom-icon-calender custoom-icon-calender-all">
                    <div className="custoom-icon-calender custoom-icon-calender-all custom-date-range">
                      <input
                        type="text"
                        // onChange={}
                        value={
                          dateRange
                            ? `${new Date(dateRange[0]).toLocaleDateString(
                              "en-GB",
                              {
                                day: "2-digit",
                                month: "2-digit",
                                year: "numeric",
                              }
                            )} to ${new Date(
                              dateRange[1]
                            ).toLocaleDateString("en-GB", {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                            })}`
                            : null
                        }
                        id="invoiceId"
                        name="invoiceId"
                        placeholder="All Time"
                        onClick={toggleModalCalendar}
                        autocomplete="off"
                      />
                    </div>

                    {/* Date range selector */}
                    <div className="date-range-new">
                      {showModalCalendar && (
                        <Calendar
                          onCancel={cancelModalCalendar}
                          onChange={onDateChange}
                          initialRange={{
                            startDate: dateRange ? dateRange[0] : null,
                            endDate: dateRange ? dateRange[1] : null,
                          }}
                        />
                      )}
                    </div>
                    {/* Date range selector */}
                  </div>
                </div>
              </div>
            </div>

            <div className="filter-buttons">
              <button className="clear-all" onClick={clearFilters}>
                Clear All
              </button>
              <button className="apply-filter" onClick={applyFilters}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="13"
                  height="17"
                  viewBox="0 0 13 17"
                  fill="none"
                >
                  <path
                    d="M11.7363 8.81108C11.4276 9.81451 10.4925 10.5444 9.38889 10.5444C8.28471 10.5444 7.35016 9.8141 7.04147 8.81108L0.722221 8.81108C0.323349 8.81108 -1.07845e-06 8.48773 -1.06101e-06 8.08886C-1.04357e-06 7.68998 0.323349 7.36663 0.722221 7.36663L7.04144 7.36663C7.35022 6.3632 8.28529 5.6333 9.38889 5.6333C10.4931 5.6333 11.4276 6.36362 11.7363 7.36663L12.2778 7.36663C12.6767 7.36663 13 7.68998 13 8.08886C13 8.48773 12.6766 8.81108 12.2778 8.81108L11.7363 8.81108ZM9.38889 7.07775C8.83162 7.07775 8.37778 7.53123 8.37778 8.08886C8.37778 8.64613 8.83127 9.09997 9.38889 9.09997C9.94616 9.09997 10.4 8.64648 10.4 8.08886C10.4 7.53158 9.94651 7.07775 9.38889 7.07775Z"
                    fill="white"
                  />
                  <path
                    d="M5.38078 3.17778C5.072 4.18121 4.13693 4.91111 3.03333 4.91111C1.92882 4.91111 0.994035 4.18035 0.68563 3.17687C0.303759 3.15781 -1.07791e-06 2.84216 -1.06101e-06 2.45556C-1.04411e-06 2.06896 0.303748 1.75331 0.685606 1.73425C0.994098 0.730343 1.9294 -4.83911e-07 3.03333 -4.35657e-07C4.13752 -3.87392e-07 5.07206 0.730315 5.38076 1.73333L12.2778 1.73333C12.6767 1.73333 13 2.05668 13 2.45556C13 2.85443 12.6766 3.17778 12.2778 3.17778L5.38078 3.17778ZM3.03333 1.44444C2.47606 1.44444 2.02222 1.89793 2.02222 2.45556C2.02222 3.01283 2.47571 3.46667 3.03333 3.46667C3.5906 3.46667 4.04444 3.01318 4.04444 2.45556C4.04444 1.89828 3.59096 1.44444 3.03333 1.44444Z"
                    fill="white"
                  />
                  <path
                    d="M7.403 14.4444C7.09422 15.4478 6.15916 16.1777 5.05556 16.1777C3.95137 16.1777 3.01682 15.4474 2.70813 14.4444L0.722221 14.4444C0.323349 14.4444 -1.07845e-06 14.121 -1.06101e-06 13.7222C-1.04357e-06 13.3233 0.323349 12.9999 0.722221 12.9999L2.70811 12.9999C3.01689 11.9965 3.95195 11.2666 5.05556 11.2666C6.15974 11.2666 7.09429 11.9969 7.40298 12.9999L12.2778 12.9999C12.6767 12.9999 13 13.3233 13 13.7222C13 14.121 12.6766 14.4444 12.2778 14.4444L7.403 14.4444ZM5.05556 12.711C4.49828 12.711 4.04444 13.1645 4.04444 13.7222C4.04444 14.2794 4.49793 14.7333 5.05556 14.7333C5.61283 14.7333 6.06667 14.2798 6.06667 13.7222C6.06667 13.1649 5.61318 12.711 5.05556 12.711Z"
                    fill="white"
                  />
                </svg>
                Apply Filters
              </button>
            </div>
          </div>
        </Modal>

        <Modal
          isOpen={showConfirmationModal}
          onRequestClose={() => setShowConfirmationModal(false)}
          contentLabel="Confirmation Modal"
          ariaHideApp={false}
          className="confirmation-modal"
          overlayClassName="confirmation-modal-overlay"
        >
          <div className="modal-content">
            {/* <h2>Are you sure?</h2> */}
            <p>Are you sure you want to clear the filters?</p>
            <div className="modal-footer">
              <button
                className="cancel-btn"
                onClick={() => setShowConfirmationModal(false)}
              >
                Cancel
              </button>
              <button className="confirm-btn" onClick={handleApplyConfirm}>
                Yes, Clear
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
}

export default ReportReimbursmentFilter;
