import "./userDetail.css";
import { AES, enc } from "crypto-js";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Back from "../../../images/icons/lf-arw.svg";
import { useDispatch, useSelector } from "react-redux";
import userTypeName from "../../../constants/userType.js";
import { setHeaderText } from "../../../features/headerText/headerTextSlice.js";
import UserProfile from "../../../components/User/UserDetails/UserProfile/UserProfile.jsx";
import UserAccount from "../../../components/User/UserDetails/UserAccount/UserAccount.jsx";
import { resetAccountDetails } from "../../../features/accountDetails/accountDetailsSlice.js";
import UserNotification from "../../../components/User/UserDetails/UserNotification/UserNotification.jsx";
import UserTeam from "../../../components/User/UserDetails/UserNotification/UserTeam.jsx";

function UserDeatils() {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(0);
  const { encryptedId } = useParams();
  const decodedId = decodeURIComponent(encryptedId.replace(/%2F/g, "/"));
  const decryptedBytes = AES.decrypt(decodedId, "encryptionKey");
  const id = decryptedBytes.toString(enc.Utf8);
  const accountDetails = useSelector(
    (state) => state.accountDetails.accountDetails
  );
  const userRole = useSelector((state) => state.user);

  const handleTabClick = (index) => {
    setActiveTab(index);
    dispatch(resetAccountDetails());
    localStorage.setItem("activeTab", String(index));
  };

  const allAccount = () => {
    dispatch(resetAccountDetails());
  };

  useEffect(() => {
    const storedActiveTab = localStorage.getItem("activeTab");
    if (storedActiveTab) {
      setActiveTab(Number(storedActiveTab));
    }
    dispatch(setHeaderText("My Team"));
  }, [id, dispatch]);

  return (
    <div className="content-inner-inner">
      {accountDetails === false ? (
        <div className="back-to">
          <Link to="/user/myteam/list">
            <span className="back-icon">
              {/* <img src={Back} alt="Back" /> */}
            </span>
            Back to All Users
          </Link>
        </div>
      ) : (
        <div
          className="back-to"
          onClick={allAccount}
          style={{ cursor: "pointer" }}
        >
          <span className="back-icon">
            <img src={Back} alt="Back" />
          </span>
          Back to All Accounts
        </div>
      )}

      <div className="tab-outer">
        <ul className="tabs">
          <li
            className={activeTab === 0 ? "active-tab" : ""}
            onClick={() => handleTabClick(0)}
          >
            Profile
          </li>

          <li
            className={activeTab === 1 ? "active-tab" : ""}
            onClick={() => handleTabClick(1)}
          >
            <>Bank Accounts</>
          </li>

          {/* <li
            className={activeTab === 2 ? "active-tab" : ""}
            onClick={() => handleTabClick(2)}
          >
            Notifications
          </li> */}
          {userRole.userRolename === userTypeName.Team_Manager && (
            <li
              className={activeTab === 3 ? "active-tab" : ""}
              onClick={() => handleTabClick(3)}
            >
              Team
            </li>
          )}
        </ul>

        <div className="tabs-content">
          <div style={{ display: activeTab === 0 ? "block" : "none" }}>
            <UserProfile id={id} />
          </div>
          <div style={{ display: activeTab === 1 ? "block" : "none" }}>
            <UserAccount id={id} />
          </div>
          {/* <div style={{ display: activeTab === 2 ? "block" : "none" }}>
            <UserNotification id={id} />
          </div> */}
          {userRole.userRolename === userTypeName.Team_Manager && (
            <div style={{ display: activeTab === 3 ? "block" : "none" }}>
              <UserTeam id={id} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default UserDeatils;
