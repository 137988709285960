import axios from "axios";
import moment from "moment";
import "./TimeTrackerOverviewGraph.css";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { logout, reset } from "../../../features/auth/authSlice.js";
import { reset as invoiceReset } from "../../../features/invoice/invoiceSlice.js";
import { reset as headerReset } from "../../../features/headerText/headerTextSlice.js";
import { reset as appSettingsReset } from "../../../features/appSettings/appSettingsSlice.js";
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
} from "recharts";
import { useGetTimeTrackerGraphData } from "./useTimeTracker.jsx";

export default function ClickabeChart(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [last14Days, setLast14Days] = useState([]);

  
  const token = useSelector((state) => state.auth.token);
  const tz = useSelector((state) => state.appSettings.appSettings.timeZone);
  const timerDataa = useSelector((state) => state.tracker);
  const notification = useSelector(
    (state) => state.notification.notificationState
  );

  const { data: data = [], isLoading: isLoading, refetch: refetchTimeTrackerGraphData, } = useGetTimeTrackerGraphData(
    token,
    props.team,
    timerDataa,
    new Date(props.graphDate),
    tz
  );

  // useEffect(() => {
  //   refetchTimeTrackerGraphData();
  //   const currentDate = tz && moment().tz(tz).startOf('day');
  //   const dates = [];
  //   for (let i = 0; i < 14; i++) {
  //     const date = currentDate?.clone().subtract(i, 'days').format('YYYY-MM-DD');
  //     dates.push(date);
  //   }
  //   setLast14Days(dates);

  // }, [props, timerDataa, notification]);


  useEffect(() => {
    const fetchDataAndProcessDates = async () => {
      try {
        // Refetch data asynchronously
        await refetchTimeTrackerGraphData();
  
        // Calculate dates in chunks to avoid blocking the UI
        const currentDate = tz && moment().tz(tz).startOf('day');
        const dates = [];
        if (currentDate) {
          for (let i = 0; i < 14; i++) {
            const date = currentDate.clone().subtract(i, 'days').format('YYYY-MM-DD');
            dates.push(date);
          }
        }
  
        // Use a timeout to allow UI updates between iterations
        setTimeout(() => {
          setLast14Days(dates);
        }, 0); // 0ms to release the main thread
      } catch (error) {
        console.error("Error fetching data or processing dates:", error);
      }
    };
  
    fetchDataAndProcessDates();
  }, [props, timerDataa, notification]);

  const maxValue = Math.max(...data.map((item) => item.hours));
  return (
    <div className="graph-main-outer-time">
      <div className="top-graph">
        <div className="top-inner-graph">
          <div className="graph-title">Hours per day</div>
          <div className="icon-graph">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="49"
              height="49"
              viewBox="0 0 49 49"
              fill="none"
            >
              <g filter="url(#filter0_d_3604_56)">
                <circle cx="24.5" cy="24.5" r="20.5" fill="white" />
              </g>
              <path
                d="M35.7781 33.8157L30.6962 28.2439C32.3931 26.4006 33.4322 23.9674 33.4322 21.2566C33.4307 15.5887 28.8602 11 23.2161 11C17.5721 11 13 15.5887 13 21.2566C13 26.9244 17.605 31.5131 23.2161 31.5131C25.6756 31.5131 27.9257 30.644 29.6915 29.1835L34.8077 34.7914C34.9468 34.931 35.1187 35 35.2922 35C35.4656 35 35.639 34.931 35.7766 34.7914C36.0532 34.5137 36.0532 34.0964 35.7766 33.8172L35.7781 33.8157ZM23.2161 30.1201C18.3331 30.1201 14.386 26.1574 14.386 21.2551C14.386 16.3527 18.3675 12.39 23.2146 12.39C28.0618 12.39 32.0448 16.3527 32.0448 21.2551C32.0448 26.1574 28.0977 30.1201 23.2146 30.1201H23.2161Z"
                fill="#6A7988"
              />
              <path
                d="M27.1411 16.5936L27.1411 24.4686C27.1411 24.7853 26.8725 25.0622 26.5659 25.0622C26.2593 25.0622 25.9906 24.7853 25.9906 24.4686L25.9906 16.5936C25.9906 16.2772 26.259 16 26.5659 16C26.8725 16 27.1411 16.2769 27.1411 16.5936Z"
                fill="#6A7988"
                stroke="#6A7988"
                strokeWidth="0.2"
              />
              <path
                d="M23.1438 18.5936L23.1438 24.4686C23.1438 24.7853 22.8752 25.0622 22.5686 25.0622C22.2619 25.0622 21.9933 24.7853 21.9933 24.4686L21.9933 18.5936C21.9933 18.2772 22.2617 18 22.5686 18C22.8752 18 23.1438 18.2769 23.1438 18.5936Z"
                fill="#6A7988"
                stroke="#6A7988"
                strokeWidth="0.2"
              />
              <path
                d="M19.1465 20.5936L19.1465 24.4686C19.1465 24.7853 18.8779 25.0622 18.5712 25.0622C18.2646 25.0622 17.996 24.7853 17.996 24.4686L17.996 20.5936C17.996 20.2772 18.2644 20 18.5712 20C18.8779 20 19.1465 20.2769 19.1465 20.5936Z"
                fill="#6A7988"
                stroke="#6A7988"
                strokeWidth="0.2"
              />
              <defs>
                <filter
                  id="filter0_d_3604_56"
                  x="0"
                  y="0"
                  width="49"
                  height="49"
                  filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB"
                >
                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset />
                  <feGaussianBlur stdDeviation="2" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.468733 0 0 0 0 0.51145 0 0 0 0 0.554167 0 0 0 0.39 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_3604_56"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_3604_56"
                    result="shape"
                  />
                </filter>
              </defs>
            </svg>
          </div>
        </div>
      </div>

      <ResponsiveContainer width="100%" height={235}>
        <BarChart
          data={data}
          margin={{ top: 10, right: 5, left: 0, bottom: 40 }}
        >
          <XAxis
            dataKey="day"
            angle={-55}
            textAnchor="end"
            interval={0}
            dy={10}
          />
          <YAxis
            type="number"
            dataKey="exps"
            domain={[0, maxValue]}
            allowDataOverflow={true}
          />
          <Tooltip cursor={false} />
          <CartesianGrid
            horizontal={true}
            stroke="#D6D9DB"
            strokeDasharray="3 3"
          />
          <Bar dataKey="hours" fill="#96A8BA" cursor={"pointer"} barSize={13} />
        </BarChart>
      </ResponsiveContainer>

    </div>
  );
}
