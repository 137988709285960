import "./TimeTrackerOverviewPiechart.css";
import ReactApexChart from "react-apexcharts";
import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";
import DownloadImage from "../../../images/icons/download-icon.svg";

const TimeTrackerOverviewPieChart = (props) => {

const [projects, setProjects] = useState([]);
const [totalHours, setTotalHours] = useState([]);

function decimalToTime(decimalTime) {
// Calculate hours, minutes, and seconds
const hours = Math.floor(decimalTime);
const remainingMinutes = (decimalTime - hours) * 60;
const minutes = Math.floor(remainingMinutes);
const seconds = Math.round((remainingMinutes - minutes) * 60);

// Format the result
const formattedHours = String(hours).padStart(2, '0');
const formattedMinutes = String(minutes).padStart(2, '0');
const formattedSeconds = String(seconds).padStart(2, '0');

return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}

const series = projects.map((project) => project.hoursLogged);
const projectNames = projects.map((project) => project.name);
const options = {
chart: {
  width: 188,
  height: 188,
  type: "donut",
},
plotOptions: {
  pie: {
    startAngle: -45,
    endAngle: 315,
    expandOnClick: false,
  },
},
dataLabels: {
  enabled: false,
},
fill: {
  type: "gradient",
},
legend: {
  show: false,
},
labels: projectNames, // Set the project names as labels
tooltip: {
  enabled: true,
  y: {
    formatter: function (val) {
      return val + "%";
    },
  },
  custom: function ({ series, seriesIndex, w }) {
    const project = projectNames[seriesIndex];
    const hoursLogged = projects[seriesIndex]?.hoursLogged || 0;
    const totalHours = projects.reduce(
      (total, project) => total + project.hoursLogged,
      0
    );
    const percentage = ((hoursLogged / totalHours) * 100).toFixed(2);
    const timeFormatted = decimalToTime(hoursLogged);


    return `<div class="apexcharts-tooltip-custom" style="background: #FFFFFF; box-shadow: 0px 1px 8px rgba(110, 110, 110, 0.1); border-radius: 0px;height: 44pxxpx;padding: 5px 10px;">
              <span class="apexcharts-tooltip-marker"></span>
              <span class="apexcharts-tooltip-text" style="font-weight: 300; font-size: 11px; line-height: 13.42px; letter-spacing: 0.5px; color: #282828;">${project}</span>
              <span class="apexcharts-tooltip-value" style="font-weight: 300; font-size: 11px; line-height: 13.42px; letter-spacing: 0.5px; color: #282828;">${timeFormatted} - ${percentage}%</span>
            </div>`;
  },
},
responsive: [
  {
    breakpoint: 480,
    options: {
      chart: {
        width: 200,
      },
      legend: {
        position: "bottom",
      },
    },
  },
],
colors: ["#8AA9D2", "#234266", "#6D98CF", "#4263A5", "#6BA9E2", "#9ACBFB", "#75AECF", "#3f4b57", "#6A9DCB", "#A4C4E6", "#9AC9F3", "#000", "#89A7C4", "#8EA9C6", "#BBCFE7", "#B7D4F0", "#A6C0D6", "#4B6F98", "#A2B5CB", "#86A0BC"], // Set the bar colors
states: {
  active: {
    filter: {
      type: "none", // No visual change for active state
    },
  },
  normal: {
    filter: {
      type: "none", // No visual change for normal state
    },
  },
},
};

const exportToExcel = () => {
if (projects.length === 0) {
  toast.error("No data to export.", {
    position: "bottom-right",
    autoClose: 1500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  }); 
  return;
}
try {
  const now = new Date();
  const formattedDate =
    now.getFullYear() +
    "-" +
    (now.getMonth() + 1).toString().padStart(2, "0") +
    "-" +
    now.getDate().toString().padStart(2, "0");
  const formattedTime =
    now.getHours().toString().padStart(2, "0") +
    ":" +
    now.getMinutes().toString().padStart(2, "0") +
    ":" +
    now.getSeconds().toString().padStart(2, "0");

  const modifiedLog = projects.map((project, index) => ({
    "Project Name": project.name,
    "Logged Hours": project.hoursLogged ? decimalToTime(project.hoursLogged) : decimalToTime(0),
  }));
  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.json_to_sheet(modifiedLog);
  const colWidths = [
    { wpx: 175 },
    { wpx: 70 },
  ];
  worksheet["!cols"] = colWidths;
  // Add alignment to center align the first row and increase font size
  const firstRow = XLSX.utils.decode_range(worksheet["!ref"]).s.r;
  for (let i = 0; i < colWidths.length; i++) {
    const cell = XLSX.utils.encode_cell({ r: firstRow, c: i });
    const style = worksheet[cell]?.s || {};
    style.alignment = style.alignment || {};
    style.alignment.horizontal = "center !important";
    style.alignment.vertical = "center !important";
    style.font = { size: 14, bold: true };
    worksheet[cell] = { ...worksheet[cell], s: style };
  }
  // Set border for active area and increase font size
  const range = XLSX.utils.decode_range(worksheet["!ref"]);
  for (let row = range.s.r + 1; row <= range.e.r; row++) {
    for (let col = range.s.c; col <= range.e.c; col++) {
      const cell = XLSX.utils.encode_cell({ r: row, c: col });
      const style = worksheet[cell]?.s || {};
      style.font = { size: 12 };
      worksheet[cell] = { ...worksheet[cell], s: style };
    }
  }
  // global.config.activityLog(
  //   window.location.href,
  //   "TimeTracker Page",
  //   "TimeTracker category exported to Excel"
  // );
  const fileName = `TimeTracker_Data_${formattedDate}_${formattedTime}.xlsx`;
  worksheet["!autofilter"] = { ref: range };
  XLSX.utils.book_append_sheet(workbook, worksheet, "TimeTracker");
  XLSX.writeFile(workbook, fileName);
} catch (error) {
  console.log(error);
  global.config.slackMessage(error.toString());
  // global.config.activityLog(
  //   window.location.href,
  //   "TimeTracker Page",
  //   "Error occurred while exporting to Excel"
  // );
  alert("Error occurred while exporting TimeTracker Data to Excel.");
}
};

// useEffect(() => {
// const piChart = () => {
//   let hours = props.timerData;
//   let result = [];
//   let sum = 0;

//   hours.forEach((entry) => {
//     sum += entry.duration;
//     const existingEntry = result.find((item) => item.name === entry?.project?.project_name);
//     if (existingEntry) {

//       existingEntry.hoursLogged += entry.duration;

//     } else {
//       result.push({ name: entry?.project?.project_name, hoursLogged: entry.duration });
//     }
//   });


//   const updatedData = result.map(item => {
//     return { name: item.name, hoursLogged: (item.hoursLogged / 3600) };
//   });

//   setProjects(updatedData);
//   let second = sum;
//   const hourss = Math.floor(second / 3600);
//   const minutes = Math.floor((second % 3600) / 60);
//   const seconds = Math.trunc(second % 60);
//   const formattedDuration = `${hourss.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
//   setTotalHours(formattedDuration);
// }
// piChart();

// }, [props.timerData]);

useEffect(() => {
  const processPiChartData = async () => {
    try {
      const hours = props.timerData;
      const result = [];
      let sum = 0;

      // Process data in chunks to avoid blocking the UI
      for (let i = 0; i < hours.length; i++) {
        const entry = hours[i];
        sum += entry.duration;

        const existingEntry = result.find((item) => item.name === entry?.project?.project_name);
        if (existingEntry) {
          existingEntry.hoursLogged += entry.duration;
        } else {
          result.push({ name: entry?.project?.project_name, hoursLogged: entry.duration });
        }

        // Yield to the main thread after every 100 iterations
        if (i % 100 === 0) {
          await new Promise((resolve) => setTimeout(resolve, 0)); // Non-blocking pause
        }
      }

      // Map and calculate the updated data
      const updatedData = result.map((item) => ({
        name: item.name,
        hoursLogged: item.hoursLogged / 3600, // Convert to hours
      }));

      // Update state with processed data
      setProjects(updatedData);

      // Format and set total hours
      const hourss = Math.floor(sum / 3600);
      const minutes = Math.floor((sum % 3600) / 60);
      const seconds = Math.trunc(sum % 60);
      const formattedDuration = `${hourss.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
      setTotalHours(formattedDuration);
    } catch (error) {
      console.error("Error processing pi chart data:", error);
    }
  };

  processPiChartData();
}, [props.timerData]);



const allNegative = series.every(value => value <= 0);
const [showMessage, setShowMessage] = useState(false);


// useEffect(() => {
//   if (allNegative) {
//     // Set a delay of 2 seconds before showing the message
//     const timer = setTimeout(() => {
//       setShowMessage(true);
//     }, 2000);

//     // Clear the timer if component unmounts
//     return () => clearTimeout(timer);
//   }
// }, [allNegative]);

useEffect(() => {
  if (allNegative) {
    let timer;

    const scheduleMessage = async () => {
      // Introduce a delay of 2 seconds without blocking the main thread
      timer = setTimeout(() => {
        setShowMessage(true);
      }, 2000);
    };

    scheduleMessage();

    // Cleanup the timer if the component unmounts or `allNegative` changes
    return () => clearTimeout(timer);
  }
}, [allNegative]);


return (
<div className="pie-outer">
  <div className="pie-chart-top">
    <div className="top-graph">
      <div className="top-inner-graph">
        <div className="graph-title">Summary</div>
        <div className="icon-graph">{totalHours}</div>
      </div>
    </div>
  </div>
  <div className="pie-chart-inner">
  {allNegative && showMessage ? (
          <p className='no-data-text'>No data to display</p>

        ) : (
    <ReactApexChart
      options={options}
      series={series}
      type="donut"
      width={290}
    />
    )}
  </div>
  <div className="export-icon pie-export">
    <a onClick={exportToExcel}>
      <span className="icon-export">
        {/* <img src={DownloadImage} alt="Export" /> */}
          <svg width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.52625 15.8539C6.55157 15.8771 6.57923 15.8979 6.60884 15.9161L6.65014 15.9361L6.70235 15.9621L6.75689 15.9782L6.80482 15.9904C6.87651 16.0043 6.95092 16.0043 7.023 15.9904L7.07092 15.9782L7.12547 15.9621L7.17767 15.9361L7.21897 15.9161C7.24897 15.8979 7.27664 15.8771 7.30196 15.8539L12.6977 10.9C12.8773 10.7014 12.8625 10.41 12.6634 10.2275C12.4643 10.045 12.1464 10.0314 11.9298 10.1961L7.45702 14.3V1.5C7.45702 1.22393 7.21274 1 6.91157 1C6.6104 1 6.36612 1.22393 6.36612 1.5V14.3L1.89339 10.1961C1.67677 10.0314 1.35884 10.045 1.15975 10.2275C0.960661 10.41 0.945856 10.7014 1.12547 10.9L6.52547 15.8539H6.52625Z" fill="white" stroke="white" stroke-width="0.5"/>
          </svg>
      </span>
      <div className="pie-export-title">Export Data</div> 
    </a>
  </div>
</div>
);
};

export default TimeTrackerOverviewPieChart;