
import React, { useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { AES } from 'crypto-js';
import "./ClientOverviewListingLatestProject.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ThreeDots } from 'react-loader-spinner';
import { Link } from 'react-router-dom';
import { useFetchClientProjectDetails } from '../ClientInvoice/useClientInvoice';
import DateConverter from '../../../helpers/dateFormatChange';
import { TrackedLatestProjectHours, TrackedProjectHours } from '../../../helpers/ProjectHelpers';
import TruncateFieldView from '../../helpers/truncateFieldView';
// import { dateConvert } from "../../helpers/dateFormatChange";
export function detailCTA(rowData) {
  const encryptedCliId = AES.encrypt(rowData.client_name._id, 'encryptionKey').toString();
  const encodedId = encodeURIComponent(encryptedCliId).replace(/\//g, '%2F');

  return (
    <Link to={`/client/projects/details/${rowData._id}/${encodedId}`} className="cta-link">
      Details
    </Link>
  );
}

const LatestProject = (props) => {

  let token = null;
  const { user } = useSelector((state) => state.auth);
  if (user) {
    if (user && user.token) {
      token = user.token;
    }
  }

  const { data: project = [], isLoading, refetch: refetchProjects } = useFetchClientProjectDetails(props.id, token);

  const getStatusColor = (status) => {
    switch (status) {
      case 'Paid':
        return 'status-paid'; // CSS class for paid status
      case 'Pending':
        return 'status-pending'; // CSS class for pending status
      default:
        return ''; // default CSS class
    }
  };

  // const renderClient = (rowData) => {
  //   var clientName = rowData.client_name.client_name || rowData.client_name.legal_entity_name;
  //   return <span> <TruncateFieldView textContent={clientName} /></span>;
  // };

  const renderProjectStatus = (rowData) => {
    // Determine the class based on the payment_status value
    let statusClass;
    let statusName;
    if (rowData.is_billable === true) {
      statusClass = "billable";
      statusName = "Yes";
    } else if (rowData.is_billable === false) {
      statusClass = "non-billable";
      statusName = "No";
    }
    return (
      <span className={`payment-status ${statusClass}`}>{statusName}</span>
    );
  };

  return (
    <div className="latest-project-outer">
      <div className="table-head-outer">
        <div className="table-head">
          Latest Projects
        </div>
        <div className="view-button">
          <Link to={`/client/projects/${encodeURIComponent(AES.encrypt(props.id, 'encryptionKey').toString().replace(/\//g, '%2F'))}`} className={window.location.pathname.startsWith('/client/projects') ? 'active' : ''}>
            View All
          </Link>
        </div>
      </div>
      {isLoading ? (
        <ThreeDots
          height="80"
          width="80"
          radius="9"
          color="#405BFF"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClassName=""
          visible={true}
        />
      ) : (
        <DataTable
          value={project.slice(0, 5)}
          removableSort
          tableStyle={{ minWidth: "50rem", width: "100%", textAlign: "left" }}
          paginator={false}
          paginatorClassName="recent-invoices-pagination"
          rows={5}
          emptyMessage="No Projects found under this client"
        >
          <Column
            field="project_name"
            header="Project"
            sortable
            style={{ width: "18.7%" }}
          ></Column>
          {/* <Column
            field="fullName"
            body={"project name"}
            header="Sprint"
            sortable
            style={{ width: "17.2%" }}
          ></Column> */}
          {/* <Column
            field="fullName"
            header="Client"
            body={renderClient}
            // sortable
            sortField="fullName"
            style={{ width: "16.8%" }}
          ></Column> */}
          <Column
            field="created_at"
            body={(rowData) => (
              <DateConverter
                date={rowData.created_at}
              />
            )}
            header="Date Created"
            sortable
            style={{ width: "15.3%" }}
          ></Column>

          <Column
            field="tracked_hours"
            header="Tracked Hours"
            body={TrackedLatestProjectHours}
            sortable
            style={{ width: "14.7%" }}
          ></Column>
          <Column
            field="is_billable"
            header="Billable"
            body={(rowData) => renderProjectStatus(rowData)}
            sortable
            style={{ width: "10.4%" }}
          ></Column>
          <Column
            field="_id"
            body={detailCTA}
            style={{ width: "15%" }}
          ></Column>
        </DataTable>
      )}
    </div>
  );

};
export default LatestProject;