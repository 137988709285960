import React, { useState, useRef } from 'react';
import DatePicker from "react-datepicker";
import { reportingFilterComparisonConst } from '../../constants/reportingFilterComparisonConst';
import { reportingFilterDateRangesConst } from '../../constants/reportingFilterDateRangesConst';

const Filter = ({
        showFrequency,
        dateRangeDropdownRef,
        toggleDateRangeDropdown,
        selectedDateRange,
        dateRanges,
        isDateRangeDropdownOpen,
        handleDateRangeSelect,
        selectedYearRange,
        setSelectedYearRange,
        selectedMonthRange,
        setSelectedMonthRange,
        selectedComparisonYear,
        setSelectedComparisonYear,
        selectedComparisonMonth,
        setSelectedComparisonMonth,
        comparisonDropdownRef,
        toggleComparisonDropdown,
        selectedComparison,
        comparisonOptions,
        isComparisonDropdownOpen,
        frequencyDropdownRef,
        handleComparisonSelect,
        toggleFrequencyDropdown,
        selectedFrequency,
        frequencyOptions,
        isFrequencyDropdownOpen,
        handleFrequencySelect
}) => {

        const filteredFrequencyOptions = frequencyOptions.filter(option => {
                const shouldShowAllOptions =
                        selectedComparison === reportingFilterComparisonConst.Last_Month ||
                        selectedComparison === reportingFilterComparisonConst.Month_Select ||
                        selectedDateRange === reportingFilterDateRangesConst.This_Month ||
                        selectedDateRange === reportingFilterDateRangesConst.Month_Selected;

                if (shouldShowAllOptions) {
                        return true;
                }

                return option.option_name === 'Monthly';
        });
        return (
                <>
                        <div className='modal-inner-filter'>
                                <div className='modal-inner-filter-inner'>
                                        <div className='modal-inner-filter-tile'>
                                                Filters
                                        </div>
                                        <div className="filter-inner-outer">
                                                <div className='filter-inner users'>
                                                        <label htmlFor="date-range">Date range</label>
                                                        <div className="custom-dropdown1" ref={dateRangeDropdownRef}>
                                                                <div className="drop-down-inner">
                                                                        <div className="selected-options1" onClick={toggleDateRangeDropdown}>
                                                                                {selectedDateRange === null ? (
                                                                                        <div className="placeholder">Select</div>
                                                                                ) : (
                                                                                        <div className="selected-option1">
                                                                                                {dateRanges.find(range => range._id === selectedDateRange)?.range_name}
                                                                                        </div>
                                                                                )}
                                                                        </div>
                                                                        {isDateRangeDropdownOpen && (
                                                                                <div className="dropdown-list1">
                                                                                        {dateRanges.length > 0 && (
                                                                                                <>
                                                                                                        {dateRanges
                                                                                                                .slice()
                                                                                                                .sort((a, b) =>
                                                                                                                        a._id?.toLowerCase().localeCompare(b._id?.toLowerCase())
                                                                                                                )
                                                                                                                .map((range) => (
                                                                                                                        <div
                                                                                                                                className="dropdown-item"
                                                                                                                                key={range._id}
                                                                                                                                onClick={() => handleDateRangeSelect(range._id)}
                                                                                                                        >
                                                                                                                                {range.range_name}
                                                                                                                        </div>
                                                                                                                ))}
                                                                                                </>
                                                                                        )}
                                                                                </div>
                                                                        )}
                                                                </div>
                                                        </div>
                                                </div>

                                                {selectedDateRange === reportingFilterDateRangesConst.Year_Selected && (
                                                        <div className="filter-inner users">
                                                                <label htmlFor="year-select">Select Year</label>
                                                                <div className="date-picker-outer date-picker-outer-all filter-report-date">
                                                                        <div className="custom-picker-icon custom-picker-icon-all">
                                                                                <svg
                                                                                        width="1em"
                                                                                        height="1em"
                                                                                        viewBox="0 0 14 14"
                                                                                        fill="currentColor"
                                                                                        aria-hidden="true"
                                                                                        focusable="false"
                                                                                        className="rs-picker-toggle-caret rs-icon"
                                                                                        aria-label="calendar"
                                                                                        data-category="legacy"
                                                                                >
                                                                                        <path d="M1 4v8.5a.5.5 0 00.5.5H8c0-.128.049-.256.146-.354.555-.555.854-1.6.854-3.146a.5.5 0 01.621-.485l.119.03A2.623 2.623 0 0012.999 6.5V4h-12zm3-2h6V0h1v2h1.5A1.5 1.5 0 0114 3.5v3a3.623 3.623 0 01-4.015 3.603c-.064 1.245-.335 2.212-.831 2.898H12.5a.5.5 0 00.5-.5v-2a.5.5 0 011 0v2a1.5 1.5 0 01-1.5 1.5h-11a1.5 1.5 0 01-1.5-1.5v-9a1.5 1.5 0 011.5-1.5H3v-2h1v2zm5 4V5h1v1h2v1h-2v1H9V7H7v2h1v1H7v2H6v-2H4v2H3v-2H1V9h2V7H1V6h2V5h1v1h2V5h1v1h2zM6 9V7H4v2h2z"></path>
                                                                                </svg>
                                                                        </div>
                                                                        <div className="custoom-icon-calender custoom-icon-calender-all">
                                                                                <div className="custoom-icon-calender custoom-icon-calender-all custom-date-range">
                                                                                        <DatePicker
                                                                                                selected={selectedYearRange}
                                                                                                onChange={(date) => {
                                                                                                        const selectedYear = date.getFullYear();
                                                                                                        setSelectedYearRange(new Date(selectedYear, 0, 1));
                                                                                                }}
                                                                                                showYearPicker
                                                                                                dateFormat="yyyy"
                                                                                        />
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                        </div>
                                                )}

                                                {selectedDateRange === reportingFilterDateRangesConst.Month_Selected && (
                                                        <div className="filter-inner users">
                                                                <label htmlFor="month-select">Select Month</label>
                                                                <div className="date-picker-outer date-picker-outer-all filter-report-date">
                                                                        <div className="custom-picker-icon custom-picker-icon-all">
                                                                                <svg
                                                                                        width="1em"
                                                                                        height="1em"
                                                                                        viewBox="0 0 14 14"
                                                                                        fill="currentColor"
                                                                                        aria-hidden="true"
                                                                                        focusable="false"
                                                                                        className="rs-picker-toggle-caret rs-icon"
                                                                                        aria-label="calendar"
                                                                                        data-category="legacy"
                                                                                >
                                                                                        <path d="M1 4v8.5a.5.5 0 00.5.5H8c0-.128.049-.256.146-.354.555-.555.854-1.6.854-3.146a.5.5 0 01.621-.485l.119.03A2.623 2.623 0 0012.999 6.5V4h-12zm3-2h6V0h1v2h1.5A1.5 1.5 0 0114 3.5v3a3.623 3.623 0 01-4.015 3.603c-.064 1.245-.335 2.212-.831 2.898H12.5a.5.5 0 00.5-.5v-2a.5.5 0 011 0v2a1.5 1.5 0 01-1.5 1.5h-11a1.5 1.5 0 01-1.5-1.5v-9a1.5 1.5 0 011.5-1.5H3v-2h1v2zm5 4V5h1v1h2v1h-2v1H9V7H7v2h1v1H7v2H6v-2H4v2H3v-2H1V9h2V7H1V6h2V5h1v1h2V5h1v1h2zM6 9V7H4v2h2z"></path>
                                                                                </svg>
                                                                        </div>
                                                                        <div className="custoom-icon-calender custoom-icon-calender-all">
                                                                                <div className="custoom-icon-calender custoom-icon-calender-all custom-date-range">
                                                                                        <DatePicker
                                                                                                selected={selectedMonthRange}
                                                                                                onChange={(date) => {
                                                                                                        const selectedMonth = date;
                                                                                                        setSelectedMonthRange(selectedMonth);
                                                                                                }}
                                                                                                dateFormat="MM/yyyy"
                                                                                                showMonthYearPicker
                                                                                        />
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                        </div>
                                                )}

                                                <div className='filter-inner users'>
                                                        <label htmlFor="comparison">Compared to</label>
                                                        <div className="custom-dropdown1" ref={comparisonDropdownRef}>
                                                                <div className="drop-down-inner">
                                                                        <div className="selected-options1" onClick={toggleComparisonDropdown}>
                                                                                {selectedComparison === null ? (
                                                                                        <div className="placeholder">Select</div>
                                                                                ) : (
                                                                                        <div className="selected-option1">
                                                                                                {comparisonOptions.find(option => option._id === selectedComparison)?.option_name}
                                                                                        </div>
                                                                                )}
                                                                        </div>
                                                                        {isComparisonDropdownOpen && (
                                                                                <div className="dropdown-list1">
                                                                                        {comparisonOptions.length > 0 && (
                                                                                                <>
                                                                                                        {comparisonOptions
                                                                                                                .slice()
                                                                                                                .sort((a, b) =>
                                                                                                                        a._id?.toLowerCase().localeCompare(b._id?.toLowerCase())
                                                                                                                )
                                                                                                                .map((option) => (
                                                                                                                        <div
                                                                                                                                className="dropdown-item"
                                                                                                                                key={option._id}
                                                                                                                                onClick={() => handleComparisonSelect(option._id)}
                                                                                                                        >
                                                                                                                                {option.option_name}
                                                                                                                        </div>
                                                                                                                ))}
                                                                                                </>
                                                                                        )}
                                                                                </div>
                                                                        )}
                                                                </div>
                                                        </div>
                                                </div>

                                                {selectedComparison === '5' && (
                                                        <div className="filter-inner users">
                                                                <label htmlFor="year-select-comparison">Select Year</label>
                                                                <div className="date-picker-outer date-picker-outer-all filter-report-date">
                                                                        <div className="custom-picker-icon custom-picker-icon-all">
                                                                                <svg
                                                                                        width="1em"
                                                                                        height="1em"
                                                                                        viewBox="0 0 14 14"
                                                                                        fill="currentColor"
                                                                                        aria-hidden="true"
                                                                                        focusable="false"
                                                                                        className="rs-picker-toggle-caret rs-icon"
                                                                                        aria-label="calendar"
                                                                                        data-category="legacy"
                                                                                >
                                                                                        <path d="M1 4v8.5a.5.5 0 00.5.5H8c0-.128.049-.256.146-.354.555-.555.854-1.6.854-3.146a.5.5 0 01.621-.485l.119.03A2.623 2.623 0 0012.999 6.5V4h-12zm3-2h6V0h1v2h1.5A1.5 1.5 0 0114 3.5v3a3.623 3.623 0 01-4.015 3.603c-.064 1.245-.335 2.212-.831 2.898H12.5a.5.5 0 00.5-.5v-2a.5.5 0 011 0v2a1.5 1.5 0 01-1.5 1.5h-11a1.5 1.5 0 01-1.5-1.5v-9a1.5 1.5 0 011.5-1.5H3v-2h1v2zm5 4V5h1v1h2v1h-2v1H9V7H7v2h1v1H7v2H6v-2H4v2H3v-2H1V9h2V7H1V6h2V5h1v1h2V5h1v1h2zM6 9V7H4v2h2z"></path>
                                                                                </svg>
                                                                        </div>
                                                                        <div className="custoom-icon-calender custoom-icon-calender-all">
                                                                                <div className="custoom-icon-calender custoom-icon-calender-all custom-date-range">
                                                                                        <DatePicker
                                                                                                selected={selectedComparisonYear}
                                                                                                onChange={(date) => {
                                                                                                        const selectedYear = date.getFullYear();
                                                                                                        setSelectedComparisonYear(new Date(selectedYear, 0, 1));
                                                                                                }}
                                                                                                showYearPicker
                                                                                                dateFormat="yyyy"
                                                                                        />
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                        </div>
                                                )}

                                                {selectedComparison === '6' && (
                                                        <div className="filter-inner users">
                                                                <label htmlFor="month-select-comparison">Select Month</label>
                                                                <div className="date-picker-outer date-picker-outer-all filter-report-date">
                                                                        <div className="custom-picker-icon custom-picker-icon-all">
                                                                                <svg
                                                                                        width="1em"
                                                                                        height="1em"
                                                                                        viewBox="0 0 14 14"
                                                                                        fill="currentColor"
                                                                                        aria-hidden="true"
                                                                                        focusable="false"
                                                                                        className="rs-picker-toggle-caret rs-icon"
                                                                                        aria-label="calendar"
                                                                                        data-category="legacy"
                                                                                >
                                                                                        <path d="M1 4v8.5a.5.5 0 00.5.5H8c0-.128.049-.256.146-.354.555-.555.854-1.6.854-3.146a.5.5 0 01.621-.485l.119.03A2.623 2.623 0 0012.999 6.5V4h-12zm3-2h6V0h1v2h1.5A1.5 1.5 0 0114 3.5v3a3.623 3.623 0 01-4.015 3.603c-.064 1.245-.335 2.212-.831 2.898H12.5a.5.5 0 00.5-.5v-2a.5.5 0 011 0v2a1.5 1.5 0 01-1.5 1.5h-11a1.5 1.5 0 01-1.5-1.5v-9a1.5 1.5 0 011.5-1.5H3v-2h1v2zm5 4V5h1v1h2v1h-2v1H9V7H7v2h1v1H7v2H6v-2H4v2H3v-2H1V9h2V7H1V6h2V5h1v1h2V5h1v1h2zM6 9V7H4v2h2z"></path>
                                                                                </svg>
                                                                        </div>
                                                                        <div className="custoom-icon-calender custoom-icon-calender-all">
                                                                                <div className="custoom-icon-calender custoom-icon-calender-all custom-date-range">
                                                                                        <DatePicker
                                                                                                selected={selectedComparisonMonth}
                                                                                                onChange={(date) => {
                                                                                                        const selectedMonth = date;
                                                                                                        setSelectedComparisonMonth(selectedMonth);
                                                                                                }}
                                                                                                dateFormat="MM/yyyy"
                                                                                                showMonthYearPicker
                                                                                        />
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                        </div>
                                                )}
                                                {showFrequency && (
                                                        <div className='filter-inner users'>
                                                                <label htmlFor="frequency">Frequency</label>
                                                                <div className="custom-dropdown1" ref={frequencyDropdownRef}>
                                                                        <div className="drop-down-inner">
                                                                                <div className="selected-options1" onClick={toggleFrequencyDropdown}>
                                                                                        {selectedFrequency === null ? (
                                                                                                <div className="placeholder">Select</div>
                                                                                        ) : (
                                                                                                <div className="selected-option1">
                                                                                                        {frequencyOptions.find(option => option._id === selectedFrequency)?.option_name}
                                                                                                </div>
                                                                                        )}
                                                                                </div>
                                                                                {isFrequencyDropdownOpen && (
                                                                                        <div className="dropdown-list1">
                                                                                                {frequencyOptions.length > 0 && (
                                                                                                        <>
                                                                                                                {frequencyOptions
                                                                                                                        .slice()
                                                                                                                        .sort((a, b) =>
                                                                                                                                a._id?.toLowerCase().localeCompare(b._id?.toLowerCase())
                                                                                                                        )
                                                                                                                        .map((option) => (
                                                                                                                                <div
                                                                                                                                        className="dropdown-item"
                                                                                                                                        key={option._id}
                                                                                                                                        onClick={() => handleFrequencySelect(option._id)}
                                                                                                                                >
                                                                                                                                        {option.option_name}
                                                                                                                                </div>
                                                                                                                        ))}
                                                                                                        </>
                                                                                                )}
                                                                                        </div>
                                                                                )}
                                                                        </div>
                                                                </div>
                                                        </div>
                                                )}
                                        </div>
                                </div>
                        </div>
                </>
        );
};

export default Filter;