import moment from "moment";
import { useParams } from "react-router-dom";
import { AES, enc } from 'crypto-js';
import { ThreeDots } from "react-loader-spinner";
import { ResizeObserver } from 'resize-observer';
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import DateConverter from "../../helpers/dateFormatChange.js";
import LeftArrow from "../../images/icons/blue-left-arrow.svg";
import { setHeaderText } from "../../features/headerText/headerTextSlice.js";
import { useProjectList } from "../../features/projects/ProjectList.jsx";
import { ProjectBudget, ProjectCost, Roi, TrackedProjectHours } from "../../helpers/ProjectHelpers.jsx";
import NewProjectModal from "./helpers/NewProjectModal.jsx";
import { renderClientProjectCTA } from "../../helpers/invoiceHelpers.jsx";
import { useFetchClientProjectDetails } from "../../components/Clients/ClientInvoice/useClientInvoice.jsx";


const Project = () => {

    const { encryptedId } = useParams();
    const decodedId = decodeURIComponent(encryptedId.replace(/%2F/g, '/'));
    const decryptedBytes = AES.decrypt(decodedId, 'encryptionKey');
    const id = decryptedBytes.toString(enc.Utf8);
    const settings = JSON.parse(localStorage.getItem("settings"));
    const { forex } = useSelector((state) => state.forex);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [updatedProject, setUpdatedProject] = useState([]);
    const [filter, setFilter] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [selectAllByPage, setSelectAllByPage] = useState({});
    const [showingDropdownOpen, setShowingDropdownOpen] = useState(false);
    const [userId, setUserId] = useState("");
    const targetRef = useRef(null);
    const showingDropdownRef = useRef(null);
    const { appSettings } = useSelector((state) => state.appSettings);
    const { projectAdded } = useSelector((state) => state.project);

    const handleFilterChange = (e) => {
        setFilter(e.target.value);
        setCurrentPage(0); // Reset page to the first page
    };

    let token = null;
    const { user } = useSelector((state) => state.auth);
    if (user) {
        if (user && user.token) {
            token = user.token;
        }
    }
    const renderProjectBillableStatus = (rowData) => {
        // Determine the class based on the payment_status value
        let statusClass;
        let statusName;
        if (rowData.is_billable === true) {
            statusClass = "active";
            statusName = "Yes";
        } else if (rowData.is_billable === false) {
            statusClass = "inactive";
            statusName = "No";
        }
        return (
            <span className={`payment-status ${statusClass}`}>{statusName}</span>
        );
    };

    const renderProjectStatus = (rowData) => {
        let statusClass;
        let statusName;
        if (rowData.is_active === true) {
            statusClass = "active";
            statusName = "Active";
        } else if (rowData.is_active === false) {
            statusClass = "inactive";
            statusName = "Inactive";
        }
        return (
            <span className={`payment-status ${statusClass}`}>{statusName}</span>
        );
    };

    // react Query for project
    const { data: project = [], isLoading, refetch: refetchProjects } = useFetchClientProjectDetails(id, token);
    //

    // useEffect(() => {
    //     const resizeObserver = new ResizeObserver((entries) => {
    //         // Your resize observer logic here
    //     });

    //     if (targetRef.current) {
    //         resizeObserver.observe(targetRef.current);
    //     }
    //     // Clean up the observer on component unmount
    //     return () => {
    //         if (targetRef.current) {
    //             resizeObserver.unobserve(targetRef.current);
    //         }
    //     };
    // }, []);

    useEffect(() => {
        const initializeObserver = () => {
            const resizeObserver = new ResizeObserver((entries) => {
                // Your resize observer logic here
                setTimeout(() => {
                    entries.forEach((entry) => {
                        console.log("Element resized:", entry.target);
                        // Perform non-blocking operations as necessary
                    });
                }, 0);
            });
    
            if (targetRef.current) {
                resizeObserver.observe(targetRef.current);
            }
    
            // Clean up the observer on component unmount
            return () => {
                setTimeout(() => {
                    if (targetRef.current) {
                        resizeObserver.unobserve(targetRef.current);
                    }
                }, 0);
            };
        };
    
        // Defer the initialization
        setTimeout(initializeObserver, 0);
    }, []);

    // useEffect(() => {
    //     setUpdatedProject(project);
    //     // dispatch(setHeaderText("Client Overview"));

    //     if ((user.userType !== 1 || user.userType !== 2)) {
    //         setUserId(user._id);
    //     }
    //     refetchProjects();


    // }, [dispatch, project, token, user._id, user.userType, projectAdded]);

    useEffect(() => {
        const initialize = async () => {
            // Set the updated project
            setUpdatedProject(project);
    
            // Check user type and set user ID
            if (user.userType !== 1 && user.userType !== 2) {
                setUserId(user._id);
            }
    
            // Refetch projects in a non-blocking manner
            try {
                await Promise.resolve(refetchProjects());
            } catch (error) {
                console.error("Error refetching projects:", error);
            }
        };
    
        // Defer initialization to ensure non-blocking
        setTimeout(initialize, 0);
    }, [dispatch, project, token, user._id, user.userType, projectAdded]);

    const handleClickOutsideDropdown = (event) => {
        const isClickInsideShowingDropdown = showingDropdownRef.current && showingDropdownRef.current.contains(event.target);
        if (!isClickInsideShowingDropdown) {
            setShowingDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutsideDropdown);
        return () => {
            document.removeEventListener('click', handleClickOutsideDropdown);
        };
    }, []);

    const filteredData = updatedProject.filter((item) => {
        const searchTerm = filter.trim().toLowerCase();
        const hasMatch = item.project_name.toLowerCase().includes(searchTerm);
        return hasMatch;
    });

    const totalResults = filteredData.length;


    const handleRowsPerPageChange = (e) => {
        const newRowsPerPage = parseInt(e.target.value);
        setRowsPerPage(newRowsPerPage);
        setCurrentPage(0); // Set the current page to 0 when changing rows per page
    };

    const onPageChange = (e) => {
        const newPage = e.page;
        // Store the current "Select All" state for the current page
        setSelectAllByPage((prevSelectAllByPage) => ({
            ...prevSelectAllByPage,
        }));
        setCurrentPage(e.page);
    };


    function ConvertHelper(currencyFrom, currencyTo, amount, forex) {
        const currencyFromRate = forex[currencyFrom];
        const currencyToRate = forex[currencyTo];
        const amountConvertedUSD = amount / currencyFromRate;
        const amountConvertTo = amountConvertedUSD * currencyToRate;
        return amountConvertTo;
    }

    return (
        <div className="outer-project-client-section">
            <div className="text-arrow-outer">
                <div className="text-arrow-inner">
                    <Link to={`/client/details/${encodeURIComponent(AES.encrypt(id, 'encryptionKey').toString().replace(/\//g, '%2F'))}`}>
                        <div className="text-arrow">
                            <div className="left-arrow">
                                <img src={LeftArrow} alt="left-arrow" />
                            </div>
                            <div className="top-text">
                                Back to Overview
                            </div>
                        </div>
                    </Link>
                </div>
            </div>

            <div className="user-outer-timeoff"  >
                <div className="invoice-top">
                    <NewProjectModal clientId={id} />
                    <div className="search-sec">
                        <div class="search-container">
                            <input
                                type="text"
                                onChange={handleFilterChange}
                                value={filter}
                                class="search-input"
                                placeholder="Search by Project name"
                            />
                        </div>
                    </div>
                </div>
                <div className="overview-top-right" ref={targetRef} >

                    <div className="showing-count showing-count-timeoff">
                        <div className="filter-inner showing-result show-result-team-fill">
                            <label>Showing</label>

                            <div ref={showingDropdownRef} className="select-modal-mainM custom-dropdown-user-new-outer">
                                <div className="selected-option custom-dropdown-user-new" onClick={() => setShowingDropdownOpen(!showingDropdownOpen)}>
                                    {totalResults < 10 ? totalResults.toString() : rowsPerPage.toString()}
                                </div>
                                {showingDropdownOpen && (
                                    <div className="custom-dropdown-new">
                                        <ul className="showing-options-list">
                                            {totalResults >= 10 && <li className={rowsPerPage === 10 ? 'selected-item' : ''} onClick={() => { handleRowsPerPageChange({ target: { value: 10 } }); setShowingDropdownOpen(false); }}>10</li>}
                                            {totalResults >= 20 && <li className={rowsPerPage === 20 ? 'selected-item' : ''} onClick={() => { handleRowsPerPageChange({ target: { value: 20 } }); setShowingDropdownOpen(false); }}>20</li>}
                                            {totalResults >= 30 && <li className={rowsPerPage === 30 ? 'selected-item' : ''} onClick={() => { handleRowsPerPageChange({ target: { value: 30 } }); setShowingDropdownOpen(false); }}>30</li>}
                                            {totalResults >= 40 && <li className={rowsPerPage === 40 ? 'selected-item' : ''} onClick={() => { handleRowsPerPageChange({ target: { value: 40 } }); setShowingDropdownOpen(false); }}>40</li>}
                                            {totalResults >= 10 && <li className={totalResults >= 10 && rowsPerPage === totalResults ? 'selected-item' : ''} onClick={() => { handleRowsPerPageChange({ target: { value: totalResults }, }); setShowingDropdownOpen(false); }}>ALL</li>}
                                            {/* Add more conditions as needed */}
                                            {totalResults < 10 ? (
                                                <li className={totalResults < 10 ? 'selected-item' : ''} onClick={() => { handleRowsPerPageChange({ target: { value: totalResults }, }); setShowingDropdownOpen(false); }}>{totalResults}</li>
                                            ) : null}
                                        </ul>
                                    </div>
                                )}
                            </div>
                            <label>of {totalResults} Results</label>
                        </div>
                    </div>
                </div>
                <div className="datatable-team-maintb">


                    {isLoading ? (

                        <div className="outter-load-table">
                            <ThreeDots
                                height="80"
                                width="80"
                                radius="9"
                                color="#6479f9"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                            />
                        </div>

                    ) : (
                        <DataTable
                            value={filteredData}
                            removableSort
                            tableStyle={{
                                minWidth: "50rem",
                                width: "100%",
                                textAlign: "left",
                            }}
                            paginator={filteredData.length > 10}
                            paginatorClassName="recent-invoices-pagination"
                            rows={rowsPerPage} // Set the number of rows per page
                            first={currentPage * rowsPerPage} // Set the current page using currentPage
                            onPage={onPageChange} // Update currentPage when changing pages
                        >
                            <Column
                                field="project_name"
                                header="Project"
                                className=""
                                sortable
                                style={{ paddingRight: "44px" }}
                            ></Column>
                            <Column
                                field="created_at"
                                body={(rowData) => (
                                    <DateConverter
                                        date={rowData.created_at}
                                    />
                                )}
                                header="Date Created"
                                className=""
                                sortable
                                style={{ paddingRight: "44px" }}
                            ></Column>
                            <Column
                                field="tracked_hours"
                                header="Tracked Hours"
                                className=""
                                body={TrackedProjectHours}
                                sortable
                                style={{ paddingRight: "44px" }}
                            ></Column>
                            <Column
                                field="budget"
                                header="Budget"
                                // body={(rowData) => {
                                //     const { client_name, budget } = rowData;
                                //     if (client_name && client_name.preferred_currency) {
                                //         return new Intl.NumberFormat("en-US", {
                                //             style: "currency",
                                //             currency: settings.currency,
                                //         }).format(ConvertHelper(client_name.preferred_currency, settings.currency, budget, forex));
                                //     } else {
                                //         return new Intl.NumberFormat("en-US", {
                                //             style: "currency",
                                //             currency: settings.currency,
                                //         }).format(ConvertHelper("USD", settings.currency, budget, forex));
                                //     }
                                // }}
                                body={ProjectBudget}
                                sortable
                                style={{ paddingRight: "44px" }}
                            ></Column>
                            <Column
                                field="cost"
                                header="Cost"
                                body={ProjectCost}
                                className=""
                                sortable
                                style={{ paddingRight: "44px" }}
                            ></Column>
                            <Column
                                field="roi"
                                header="ROI"
                                body={Roi}
                                className=""
                                sortable
                                style={{ paddingRight: "44px" }}
                            ></Column>
                            <Column
                                field="is_billable"
                                header="Billable"
                                body={(rowData) => renderProjectBillableStatus(rowData)}
                                className="amount-bold"
                                sortable
                                style={{ paddingRight: "44px" }}
                            ></Column>
                            <Column
                                field="is_active"
                                header="Status"
                                body={(rowData) => renderProjectStatus(rowData)}
                                className="amount-bold"
                                sortable
                                style={{ paddingRight: "44px" }}
                            ></Column>
                            <Column
                                field="_id"
                                body={renderClientProjectCTA}
                                className=""
                                style={{
                                    paddingRight: "45px",
                                    minWidth: "121.73px",
                                }}
                            ></Column>
                        </DataTable>
                    )}
                </div>
            </div>
        </div>
    );
};
export default Project;