import React, { useEffect, useRef } from "react";

const InactivityHandler = () => {
  const timeoutRef = useRef(null); // To store the inactivity timeout
  const lastActivityRef = useRef(Date.now()); // To track the last activity timestamp

  const refreshApp = () => {
    window.location.reload(); // Refresh the page
  };

  const resetInactivityTimeout = () => {
    lastActivityRef.current = Date.now();

    // Clear any existing timeout
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    // Set a new timeout for 1 hour (3600000 milliseconds)
    timeoutRef.current = setTimeout(() => {
      //console.log("1 hour of inactivity. Waiting for activity...");
    }, 
    3600000
);
  };

  const handleActivity = () => {
    const now = Date.now();
    const timeElapsed = now - lastActivityRef.current;

    if (timeElapsed > 3600000) {
      // If more than 1 hour of inactivity, refresh the app
      refreshApp();
    } else {
      // Reset the inactivity timeout
      resetInactivityTimeout();
    }
  };

  useEffect(() => {
    // Add event listeners for activity
    window.addEventListener("mousemove", handleActivity);
    window.addEventListener("keydown", handleActivity);

    // Set the initial inactivity timeout
    resetInactivityTimeout();

    // Cleanup on component unmount
    return () => {
      window.removeEventListener("mousemove", handleActivity);
      window.removeEventListener("keydown", handleActivity);
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return null; // No UI for this component
};

export default InactivityHandler;