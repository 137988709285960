import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import AES from "crypto-js/aes";
import axios from "axios";
import Modal from "react-modal";
import PhoneInput from "react-phone-number-input";
import "./ClientOverviewListingSingleDetails.css";
import Countries from "../../../constants/countries";
import AccProfileImg from "../../../images/icons/manager-alice.svg";
import ContactProfileImg from "../../../images/icons/contact-icon.svg";
import MailIcon from "../../../images/icons/email.svg";
import PhoneIcon from "../../../images/icons/phone-icon.svg";
import NewClientIcon from "../../../images/icons/plus-icon.svg";
import profile_image from "../../../images/user/Default_pfp.png";
import crssrImage from "../../../images/singleinvoice/cross-red.svg";
import Plus from "../../../images/icons/plus-black.svg";
import Delete from "../../../images/icons/delete.svg";
import {
  useClientView,
  useDeleteClientDetails,
} from "../../TimeTracker/TimeTrackerClient/useClientDetails.jsx";
import { setHeaderText } from "../../../features/headerText/headerTextSlice";
import { logout, reset } from "../../../features/auth/authSlice";
import { reset as invoiceReset } from "../../../features/invoice/invoiceSlice.js";
import { reset as headerReset } from "../../../features/headerText/headerTextSlice.js";
import { reset as appSettingsReset } from "../../../features/appSettings/appSettingsSlice.js";
import { useUserById } from "../../TimeOff/timeOffApi.jsx";
import { useGetClients } from "../../TimeTracker/TimeTrackerOverview/useTimeTracker.jsx";
import TruncateFilterFieldView from "../../helpers/truncateFilterFieldView.js";
import TruncateUserName from "../../helpers/truncateUserName.js";
import TruncateFieldView from "../../helpers/truncateFieldView.js";
import { useFetchClientProjectManager } from "../../TimeTracker/TimeTrackerProject/useProjectDetails.jsx";

const ClientDropdown = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [clientName, setClientName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [accManager, setAccManager] = useState("");
  const [accManagerDropdownOpen, setAccManagerDropdownOpen] = useState(false);
  const [userSearchTerm, setUserSearchTerm] = useState("");
  const [accManagerIsValid, setAccManagerIsValid] = useState(false);
  const [contactPoint, setContactPoint] = useState("");
  const [contactPointDropdownOpen, setContactPointDropdownOpen] =
    useState(false);
  // const [contactPointSearchTerm, setContactPointSearchTerm] = useState('');
  const [contactPointIsValid, setContactPointIsValid] = useState(false);
  const [companyWebsite, setCompanyWebsite] = useState("");
  const [isValidUrl, setIsValidUrl] = useState(false);
  const [companyLogo, setCompanyLogo] = useState(null);
  const [companyLogoName, setCompanyLogoName] = useState("");
  const [country, setCountry] = useState("");
  const [address, setAddress] = useState("");
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [updateClientList, setUpdateClientList] = useState(false);
  const [countrySearchTerm, setCountrySearchTerm] = useState("");
  const [countryDropdownOpen, setCountryDropdownOpen] = useState(false);
  const [countriesList, setCountriesList] = useState([]);
  const [ClientDropdownOpen, setClientDropdownOpen] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const clientDropdownRef = useRef(null);
  const countryDropdownRef = useRef(null);
  const accManagerRef = useRef(null);
  const accManagerSelectDropdownRef = useRef(null);
  const contactPointRef = useRef(null);
  const contactPointSelectDropdownRef = useRef(null);

  const user = useSelector((state) => state.auth.user);
  const { appSettings } = useSelector((state) => state.appSettings);
  let token = null;
  if (user) {
    if (user && user.token) {
      token = user.token;
    }
  }

  const { data: userIds = [], isLoading: isLoadingUser } = useUserById(token);
  const {
    data: clients = [],
    // isLoading: clientLoading,
    refetch: refetchClientProfiles,
    // isError: clientError,
  } = useGetClients(token);
  const {
    data: clientView = [],
    isLoading,
    isError: clientViewError,
  } = useClientView(selectedClient, token);
  
  const {
    data: clientProjectManagers = [],
    // isLoading: isClientProjectManagersLoading,
    // refetch: refetchProjectManager,
  } = useFetchClientProjectManager(token, selectedClient);
  const deleteClientMutation = useDeleteClientDetails(token);

  // useEffect(() => {
  //   setSelectedClient(props.id);
  //   const sortedCountries = Countries.slice().sort((a, b) =>
  //     a.country.localeCompare(b.country)
  //   );
  //   setCountriesList(sortedCountries);
  //   if (clientViewError) {
  //     if (clientViewError.response?.data?.status === "deleted") {
  //       // Handle the case where the user is deleted
  //       localStorage.removeItem("user");
  //       dispatch(logout());
  //       dispatch(reset());
  //       dispatch(invoiceReset());
  //       dispatch(headerReset());
  //       dispatch(appSettingsReset());
  //       toast.error("This client is deleted!", {
  //         position: "bottom-right",
  //         autoClose: 1500,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //         theme: "light",
  //       });
  //       navigate("/");
  //       return;
  //     }
  //     // Handle other errors
  //     alert(`Error fetching users`);
  //   }
  // }, []);

  useEffect(() => {
    const initializeClient = () => {
      setSelectedClient(props.id);

      // Sort countries and set them asynchronously
      setTimeout(() => {
        const sortedCountries = Countries.slice().sort((a, b) =>
          a.country.localeCompare(b.country)
        );
        setCountriesList(sortedCountries);
      }, 0); // Non-blocking sort and state update
    };

    const handleClientError = () => {
      if (clientViewError) {
        if (clientViewError.response?.data?.status === "deleted") {
          // Handle the case where the user is deleted
          localStorage.removeItem("user");
          dispatch(logout());
          dispatch(reset());
          dispatch(invoiceReset());
          dispatch(headerReset());
          dispatch(appSettingsReset());
          toast.error("This client is deleted!", {
            position: "bottom-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          navigate("/");
        } else {
          // Handle other errors asynchronously
          setTimeout(() => {
            alert(`Error fetching users`);
          }, 0);
        }
      }
    };

    // Initialize client and handle errors
    initializeClient();
    handleClientError();
  }, []); // Dependencies are empty since the effect only runs on mount

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setClientName("");
    setEmail("");
    setMobileNo("");
    setAccManager("");
    setUserSearchTerm("");
    setAccManagerDropdownOpen(false);
    setAccManagerIsValid(false);
    setContactPoint("");
    setContactPointDropdownOpen(false);
    setContactPointIsValid(false);
    setCompanyWebsite("");
    setCompanyLogo(null);
    setCompanyLogoName("");
    setCountry("");
    setAddress("");
    setAdditionalInfo("");
  };

  const handleLogoUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setCompanyLogo(e.target.files[0]);
      setCompanyLogoName(file.name);
    } else {
      setCompanyLogo(null);
      setCompanyLogoName("");
    }
  };

  const handleToggleAccManagerDropdown = () => {
    setUserSearchTerm("");
    setAccManagerDropdownOpen((prevOpen) => !prevOpen);
  };

  const handleSelectAccManager = (selectedAccManager) => {
    setAccManager(selectedAccManager);
    setUserSearchTerm("");
    setAccManagerDropdownOpen(false);
  };

  const handleAccManagerSearchInputChange = (e) => {
    setUserSearchTerm(e.target.value);
  };

  const handleToggleContactPointDropdown = () => {
    setUserSearchTerm("");
    setContactPointDropdownOpen((prevOpen) => !prevOpen);
  };

  const handleSelectContactPoint = (selectedContactPoint) => {
    setContactPoint(selectedContactPoint);
    setUserSearchTerm("");
    setContactPointDropdownOpen(false);
  };

  const handleContactPointSearchInputChange = (e) => {
    setUserSearchTerm(e.target.value);
  };

  const filteredUsers = userIds.filter((user) => {
    const fullName = user.first_name
      ? `${user.first_name} ${user.middle_name || ""} ${user.last_name}`
      : `${user.company_first_name
        ? user.company_first_name +
        (user.company_last_name && user.company_last_name)
        : user.company_name
      }`;
    return fullName?.toLowerCase().includes(userSearchTerm?.toLowerCase());
  });

  const handleToggleCountryDropdown = () => {
    setCountrySearchTerm("");
    setCountryDropdownOpen((prevOpen) => !prevOpen);
  };

  const handleCountrySearchInputChange = (e) => {
    setCountrySearchTerm(e.target.value);
  };

  const filteredCountries = countriesList.filter((country) => {
    return country.country
      ?.toLowerCase()
      .includes(countrySearchTerm?.toLowerCase());
  });

  const handleClickOutsideDropdown = (event) => {
    const isClickInsideCountryDropdown =
      countryDropdownRef.current &&
      countryDropdownRef.current.contains(event.target);
    const isClickInsideClientDropdown =
      clientDropdownRef.current &&
      clientDropdownRef.current.contains(event.target);
    const isClickInsideAccManagerSelectDropdown =
      accManagerSelectDropdownRef.current &&
      accManagerSelectDropdownRef.current.contains(event.target);
    const isClickInsideContactPointSelectDropdown =
      contactPointSelectDropdownRef.current &&
      contactPointSelectDropdownRef.current.contains(event.target);

    if (!isClickInsideCountryDropdown) {
      setCountryDropdownOpen(false);
    }
    if (!isClickInsideClientDropdown) {
      setClientDropdownOpen(false);
    }
    if (!isClickInsideAccManagerSelectDropdown) {
      setAccManagerDropdownOpen(false);
    }
    if (!isClickInsideContactPointSelectDropdown) {
      setContactPointDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutsideDropdown);
    return () => {
      document.removeEventListener("click", handleClickOutsideDropdown);
    };
  }, []);

  const handleDelete = () => {
    setIsDeleteModalOpen(true);
  };

  const handleConfirmDelete = async () => {
    try {
      setIsSubmitLoading(true);
      await deleteClientMutation.mutateAsync(selectedClient);
      toast.success("Client removed successfully", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      global.config.activityLog(
        window.location.href,
        "Client",
        `Client (${selectedClient}) deleted successfully`
      );
      // handleCloseModal();
      refetchClientProfiles();
      navigate("../overview");
    } catch (error) {
      console.error(error);
      global.config.slackMessage(error.toString());
      if (error.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        toast.error("This user is deleted!", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
        return;
      }
      toast.error("Something went wrong", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      setIsSubmitLoading(false);
    }
    setIsDeleteModalOpen(false);
  };

  const handleCancelDelete = () => {
    setIsDeleteModalOpen(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setAccManagerIsValid(false);
    setContactPointIsValid(false);
    setIsValidUrl(false);
    const pattern = /^(ftp|http|https):\/\/[^ "]+$/;
    if (accManager === "" || contactPoint === "" || clientName === "") {
      if (accManager === "") {
        setAccManagerIsValid(true);
        accManagerRef && accManagerRef.current.focus();
        return;
      } else if (contactPoint === "") {
        setContactPointIsValid(true);
        contactPointRef && contactPointRef.current.focus();
        return;
      }

      toast.error("Please fill all required fields!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    if (!pattern.test(companyWebsite)) {
      toast.error("It's not a valid URL.", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setIsValidUrl(true);
      setIsSubmitLoading(false);
      return;
    }
    const requestData = {
      client_name: clientName,
      email,
      mobile_no: mobileNo,
      address,
      country,
      acc_manager: accManager,
      contact_point: contactPoint,
      company_website: companyWebsite,
      additional_info: additionalInfo,
    };
    // Create a new FormData object
    const formData = new FormData();
    formData.append("company_logo", companyLogo);

    // Append the other request data to the form data
    for (let key in requestData) {
      formData.append(key, requestData[key]);
    }
    try {
      setIsSubmitLoading(true);
      const response = await axios.post(
        "/api/client-details/create",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = response.data;
      if (data.status === "exists") {
        toast.error("Client already exists", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (data.status === "error") {
        toast.error("Something went wrong", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.success("Client registered successfully", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        handleCloseModal();
        global.config.activityLog(
          window.location.href,
          "Client",
          `Client (${clientName}) Registered Successfully`
        );
      }
    } catch (error) {
      console.log(error);
      global.config.slackMessage(error.toString());
      if (error.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        toast.error("This user is deleted!", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
        return;
      }
      alert("Something went wrong");
    } finally {
      setIsSubmitLoading(false);
      if (updateClientList === false) {
        setUpdateClientList(true);
      } else {
        setUpdateClientList(false);
      }
    }
  };

  const toggleClientDropdown = () => {
    setClientDropdownOpen(!ClientDropdownOpen);
  };
  const selectClientOption = (selectedClient) => {
    setSelectedClient(selectedClient);
    setClientDropdownOpen(false);
    navigate(
      `/client/details/${encodeURIComponent(
        AES.encrypt(selectedClient, "encryptionKey")
          .toString()
          .replace(/\//g, "%2F")
      )}`
    );
  };
  //   const handleOpenModal = () => {
  //     setIsModalOpen(true);
  //   };

  return (
    <div className="client-details-overview-outer">
      <div className="clint-title">Client</div>
      <div className="client-with-invitation">
        <div className="custom-dropdown">
          <div
            ref={clientDropdownRef}
            className={`custom-dropdown-user-new-outer custom-dropdown-select ${ClientDropdownOpen ? "open" : ""
              }`}
          >
            <div
              className="selected-option custom-dropdown-user-new"
              onClick={toggleClientDropdown}
            >
              {selectedClient
                ? clients.find((item) => item._id === selectedClient)
                  ?.client_name
                : "Select Client"}
            </div>
            {ClientDropdownOpen && (
              <div className="custom-dropdown-new">
                {clients.length > 0 && (
                  <ul className="type-list">
                    {clients
                      .slice()
                      .sort((a, b) =>
                        a.client_name
                          ?.toLowerCase()
                          .localeCompare(b.client_name?.toLowerCase())
                      )
                      .map((client) => (
                        <li onClick={() => selectClientOption(client._id)}>
                          {client.client_name && `${client.client_name}`}
                        </li>
                      ))}
                    {/* Add more Client options as needed */}
                  </ul>
                )}
              </div>
            )}
          </div>
          <span className="custom-arrow"></span>
        </div>
        <div className="invite-and-addclient">
          <div className="invite-client-outer">
            <div className="open-client-profile">
              <Link
                to={`/client/profile/${encodeURIComponent(
                  AES.encrypt(props.id, "encryptionKey")
                    .toString()
                    .replace(/\//g, "%2F")
                )}`}
              >
                Open client profile
              </Link>
            </div>
          </div>

          {/* <div className="new-client-outer">
                        <a className="btn-new-client" onClick={handleOpenModal}>
                            <span className="icon-new-client">
                                <img src={NewClientIcon} alt="invite" />
                            </span>
                            {global.config.locate(appSettings && appSettings.language, 'New Client')}
                        </a>
                    </div>
                    <div className="modal-invite-main-outter">
                        <Modal
                            className="client-modal"
                            isOpen={isModalOpen}
                            onRequestClose={handleCloseModal}
                            contentLabel="New Client Account"
                            style={{
                                content: {
                                    height: "41%",
                                    width: "30%",
                                    position: "fixed",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                    parent: document.querySelector(".admin-outer.time.tracker"),
                                },
                            }}
                        >
                            <div className="modal">
                                <div className="modal-close-invite outter-modal-main">
                                    <a className="" onClick={handleCloseModal}>
                                        <img src={crssrImage} alt="Edit Icon" />{" "}
                                    </a>
                                </div>
                                <div className="modal-client-head">
                                    <h2 className="">{global.config.locate(appSettings && appSettings.language, 'New Client Account')}</h2>
                                </div>
                                <div className="form-field-client-outer profile-details-edit">
                                    <form onSubmit={handleSubmit}>
                                        <div className="form-field-client">
                                            <label htmlFor="clientName" className="form-label">
                                                {global.config.locate(appSettings && appSettings.language, 'Client Name')}
                                            </label>
                                            <input
                                                type="text"
                                                id="clientName"
                                                name="clientName"
                                                className="form-input"
                                                value={clientName}
                                                onChange={(e) => setClientName(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div className="form-field-client">
                                            <label htmlFor="emailAddress" className="form-label">
                                                {global.config.locate(appSettings && appSettings.language, 'Email Address')}
                                            </label>
                                            <input
                                                type="email"
                                                id="emailAddress"
                                                name="emailAddress"
                                                className="form-input"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div className="inner-detail-inner form-field-client">
                                            <label htmlFor="mobileNo" className="form-label">
                                                {global.config.locate(appSettings && appSettings.language, 'Mobile_No')}
                                            </label>
                                            <PhoneInput
                                                placeholder="Enter phone number"
                                                value={mobileNo}
                                                onChange={setMobileNo}
                                            />
                                        </div>
                                        <div className="form-field-client">
                                            <label htmlFor="companyWebsite" className="form-label">
                                                {global.config.locate(appSettings && appSettings.language, 'Company Website')}
                                            </label>
                                            <input
                                                type="text"
                                                id="companyWebsite"
                                                name="companyWebsite"
                                                className="form-input"
                                                value={companyWebsite}
                                                onChange={(e) => setCompanyWebsite(e.target.value)}
                                            />
                                            {isValidUrl && (
                                                <span className="text-red-500 text-xs italic">
                                                    Please enter a valid URL!!
                                                </span>
                                            )}
                                        </div>
                                        <div className="form-field-client">
                                            <label htmlFor="companyLogo" className="form-label">
                                                {global.config.locate(appSettings && appSettings.language, 'Company Logo')}
                                            </label>
                                            <div className="file-input-wrapper">
                                                <input
                                                    type="text"
                                                    className="form-input form-input-file"
                                                    value={companyLogoName}
                                                    readOnly
                                                />
                                                <input
                                                    type="file"
                                                    id="companyLogo"
                                                    name="companyLogo"
                                                    className="form-input hidden-input"
                                                    accept="image/*"
                                                    onChange={handleLogoUpload}
                                                    required
                                                />
                                                <label
                                                    htmlFor="companyLogo"
                                                    className="file-input-label"
                                                >
                                                    <img src={Plus} alt="Browse" />Browse
                                                </label>
                                            </div>
                                        </div>
                                        <div className="form-field-client">
                                            <label htmlFor="client" className="form-label">
                                                {global.config.locate(appSettings && appSettings.language, 'Account Manager')}
                                            </label>

                                            <div ref={accManagerSelectDropdownRef} className={`cursor ${accManagerDropdownOpen ? 'open' : ''}`} >
                                                <span
                                                    id="user-select"
                                                    onClick={handleToggleAccManagerDropdown}
                                                    className={`custom-dropdown-user-new custom-dropdown-user ${accManagerIsValid ? 'input-validation-error' : ''}`}
                                                    ref={accManagerRef}
                                                >
                                                    {accManager ? (
                                                        userIds?.find((user) => user._id === accManager)?.first_name ? (
                                                            <>
                                                                {userIds?.find((user) => user._id === accManager)?.first_name}{' '}
                                                                {userIds?.find((user) => user._id === accManager)?.middle_name &&
                                                                    userIds?.find((user) => user._id === accManager)?.middle_name !== 'null'
                                                                    ? userIds?.find((user) => user._id === accManager)?.middle_name + ' '
                                                                    : ''}
                                                                {userIds?.find((user) => user._id === accManager)?.last_name}{' '}
                                                            </>
                                                        ) : (
                                                            <>
                                                                {userIds.find((user) => user._id === accManager)?.company_first_name
                                                                    ? `${userIds.find((user) => user._id === accManager)?.company_first_name} ${userIds.find((user) => user._id === accManager)?.company_last_name
                                                                        ? userIds.find((user) => user._id === accManager)?.company_last_name
                                                                        : ''
                                                                    }`
                                                                    : `${userIds.find((user) => user._id === accManager)?.company_name || ''}(Company)`}
                                                            </>
                                                        )
                                                    ) : (
                                                        global.config.locate(appSettings && appSettings.language, 'Select Account Manager')
                                                    )}
                                                </span>
                                                {accManagerDropdownOpen && (
                                                    <div className="custom-dropdown-new">
                                                        <input
                                                            type="text"
                                                            className="search-bar"
                                                            placeholder={global.config.locate(appSettings && appSettings.language, 'Search')}
                                                            value={userSearchTerm}
                                                            onChange={handleAccManagerSearchInputChange}
                                                        />
                                                        <ul className="client-list">
                                                            {filteredUsers.length > 0 &&
                                                                filteredUsers
                                                                    .slice()
                                                                    .sort((a, b) => {
                                                                        const nameA = a.first_name
                                                                            ? `${a.first_name} ${a.middle_name || ''} ${a.last_name}`
                                                                            : `${a.company_first_name ? a.company_first_name + (a.company_last_name && a.company_last_name) : a.company_name}`;
                                                                        const nameB = b.first_name
                                                                            ? `${b.first_name} ${b.middle_name || ''} ${b.last_name}`
                                                                            : `${b.company_first_name ? b.company_first_name + (b.company_last_name && b.company_last_name) : b.company_name}`;
                                                                        return nameA.localeCompare(nameB);
                                                                    })
                                                                    .map((item) => (
                                                                        <li key={item._id} className={accManager === item._id ? 'selected-item' : ''} onClick={() => handleSelectAccManager(item._id)}>
                                                                            {item.first_name ? (
                                                                                <>
                                                                                    {item.first_name}{' '}
                                                                                    {item.middle_name && item.middle_name !== 'null' ? item.middle_name + ' ' : ''}
                                                                                    {item.last_name}{' '}
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    {item.company_first_name
                                                                                        ? `${item.company_first_name} ${item.company_last_name ? item.company_last_name : ''}`
                                                                                        : `${item.company_name || ''}(Company)`}
                                                                                </>
                                                                            )}
                                                                        </li>
                                                                    ))
                                                            }
                                                        </ul>
                                                    </div>
                                                )}
                                            </div>

                                            {accManagerIsValid && (
                                                <span className="text-red-500 text-xs italic">
                                                    Account Manager needs to be selected
                                                </span>
                                            )}
                                        </div>
                                        <div className="form-field-client">
                                            <label htmlFor="client" className="form-label">
                                                Contact Point
                                            </label>
                                            <div ref={contactPointSelectDropdownRef} className={`cursor ${contactPointDropdownOpen ? 'open' : ''}`} >
                                                <span
                                                    id="user-select"
                                                    onClick={handleToggleContactPointDropdown}
                                                    className={`custom-dropdown-user-new custom-dropdown-user ${contactPointIsValid ? 'input-validation-error' : ''}`}
                                                    ref={contactPointRef}
                                                >
                                                    {contactPoint ? (
                                                        userIds?.find((user) => user._id === contactPoint)?.first_name ? (
                                                            <>
                                                                {userIds?.find((user) => user._id === contactPoint)?.first_name}{' '}
                                                                {userIds?.find((user) => user._id === contactPoint)?.middle_name &&
                                                                    userIds?.find((user) => user._id === contactPoint)?.middle_name !== 'null'
                                                                    ? userIds?.find((user) => user._id === contactPoint)?.middle_name + ' '
                                                                    : ''}
                                                                {userIds?.find((user) => user._id === contactPoint)?.last_name}{' '}
                                                            </>
                                                        ) : (
                                                            <>
                                                                {userIds.find((user) => user._id === contactPoint)?.company_first_name
                                                                    ? `${userIds.find((user) => user._id === contactPoint)?.company_first_name} ${userIds.find((user) => user._id === contactPoint)?.company_last_name
                                                                        ? userIds.find((user) => user._id === contactPoint)?.company_last_name
                                                                        : ''
                                                                    }`
                                                                    : `${userIds.find((user) => user._id === contactPoint)?.company_name || ''}(Company)`}
                                                            </>
                                                        )
                                                    ) : (
                                                        'Select Contact Point Person'
                                                    )}
                                                </span>
                                                {contactPointDropdownOpen && (
                                                    <div className="custom-dropdown-new">
                                                        <input
                                                            type="text"
                                                            className="search-bar"
                                                            placeholder="Search..."
                                                            value={userSearchTerm}
                                                            onChange={handleContactPointSearchInputChange}
                                                        />
                                                        <ul className="client-list">
                                                            {filteredUsers.length > 0 &&
                                                                filteredUsers
                                                                    .slice()
                                                                    .sort((a, b) => {
                                                                        const nameA = a.first_name
                                                                            ? `${a.first_name} ${a.middle_name || ''} ${a.last_name}`
                                                                            : `${a.company_first_name ? a.company_first_name + (a.company_last_name && a.company_last_name) : a.company_name}`;
                                                                        const nameB = b.first_name
                                                                            ? `${b.first_name} ${b.middle_name || ''} ${b.last_name}`
                                                                            : `${b.company_first_name ? b.company_first_name + (b.company_last_name && b.company_last_name) : b.company_name}`;
                                                                        return nameA.localeCompare(nameB);
                                                                    })
                                                                    .map((item) => (
                                                                        <li key={item._id} className={contactPoint === item._id ? 'selected-item' : ''} onClick={() => handleSelectContactPoint(item._id)}>
                                                                            {item.first_name ? (
                                                                                <>
                                                                                    {item.first_name}{' '}
                                                                                    {item.middle_name && item.middle_name !== 'null' ? item.middle_name + ' ' : ''}
                                                                                    {item.last_name}{' '}
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    {item.company_first_name
                                                                                        ? `${item.company_first_name} ${item.company_last_name ? item.company_last_name : ''}`
                                                                                        : `${item.company_name || ''}(Company)`}
                                                                                </>
                                                                            )}
                                                                        </li>
                                                                    ))
                                                            }
                                                        </ul>
                                                    </div>
                                                )}
                                            </div>

                                            {contactPointIsValid && (
                                                <span className="text-red-500 text-xs italic">
                                                    Contact Point needs to be selected
                                                </span>
                                            )}
                                        </div>
                                        <div className="form-field-client">
                                            <label htmlFor="address" className="form-label">
                                                {global.config.locate(appSettings && appSettings.language, 'Address')}
                                            </label>
                                            <input
                                                type="text"
                                                id="address"
                                                name="address"
                                                className="form-input"
                                                value={address}
                                                onChange={(e) => setAddress(e.target.value)}
                                            />
                                        </div>
                                        <div className="form-field-client">
                                            <label htmlFor="country" className="form-label">
                                                {global.config.locate(appSettings && appSettings.language, 'Country')}
                                            </label>
                                            <div ref={countryDropdownRef} className="select-modal-mainM custom-dropdown-user-new-outer">
                                                <span
                                                    id="country"
                                                    onClick={handleToggleCountryDropdown}
                                                    className="custom-dropdown-user-new"
                                                >
                                                    {country ? country : (appSettings && appSettings.language ? global.config.locate(appSettings.language, 'Select Country') : null)}
                                                </span>
                                                {countryDropdownOpen && (
                                                    <div className="custom-dropdown-new">
                                                        <input
                                                            type="text"
                                                            className="search-bar"
                                                            placeholder={global.config.locate(appSettings && appSettings.language, 'Search')}
                                                            value={countrySearchTerm}
                                                            onChange={handleCountrySearchInputChange}
                                                        />
                                                        <ul className="country-list">
                                                            {filteredCountries.length > 0 &&
                                                                filteredCountries.map((c) => (
                                                                    <li
                                                                        key={c.country}
                                                                        className={country === c.country ? 'selected-item' : ''}
                                                                        onClick={() => {
                                                                            setCountry(c.country);
                                                                            setCountryDropdownOpen(false);
                                                                        }}
                                                                    >
                                                                        {c.country}
                                                                    </li>
                                                                ))}
                                                        </ul>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="form-field-client">
                                            <label
                                                htmlFor="additionalInformation"
                                                className="form-label"
                                            >
                                                {global.config.locate(appSettings && appSettings.language, 'Additional Information')}
                                            </label>
                                            <textarea
                                                id="additionalInformation"
                                                name="additionalInformation"
                                                className="form-textarea"
                                                rows="4"
                                                value={additionalInfo}
                                                onChange={(e) => setAdditionalInfo(e.target.value)}
                                            ></textarea>
                                        </div>
                                        <button type="submit" className="submit-client" disabled={isSubmitLoading}>
                                            {isSubmitLoading ?
                                                <div className="outter-load-table">
                                                    <ThreeDots
                                                        height="18"
                                                        width="20"
                                                        radius="9"
                                                        color="white"
                                                        ariaLabel="three-dots-loading"
                                                        wrapperStyle={{}}
                                                        wrapperClassName=""
                                                        visible={true}
                                                    />
                                                </div> : global.config.locate(appSettings && appSettings.language, 'Create Client')}
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </Modal>
                    </div> */}
        </div>
      </div>
      <div className="client-professional-details-outer">
        <div className="client-detail-row-one">
          <div className="client-status">
            <div className="client-status-text">Client Status</div>
            {isLoading ? (
              <div>
                <ThreeDots
                  height="18"
                  width="20"
                  radius="9"
                  color="#6479f9"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              </div>
            ) : (
              <>
                {clientView.is_active === false ? (
                  <div className="inactive">Inactive</div>
                ) : clientView.is_active === true ? (
                  <div className="client-status-bar">Active</div>
                ) : (
                  clientView.is_active
                )}
              </>
            )}
          </div>
          <div className="account-manager">
            <div className="account-manager-text">Account Manager</div>
            {isLoading ? (
              <div>
                <ThreeDots
                  height="18"
                  width="20"
                  radius="9"
                  color="#6479f9"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              </div>
            ) : clientView.acc_manager ? (
              <div className="account-manager-image-with-name">
                <span className="manager-profile-icon">
                  {clientView.acc_manager?.profile_image ? (
                    <img
                      src={`/uploads/lowProfileImage/${clientView.acc_manager.profile_image}`}
                      alt={clientView.acc_manager.first_name}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src =
                          "/uploads/lowProfileImage/user_default.png";
                      }}
                    />
                  ) : (
                    <img
                      src={profile_image}
                      alt={clientView.acc_manager.first_name}
                    />
                  )}
                </span>
                <div className="account-manager-name">
                  <TruncateUserName
                    textContent={
                      clientView.acc_manager?.first_name ? (
                        <>
                          {clientView.acc_manager.first_name}{" "}
                          {clientView.acc_manager.middle_name &&
                            clientView.acc_manager.middle_name !== "null"
                            ? clientView.acc_manager.middle_name + " "
                            : ""}
                          {clientView.acc_manager.last_name}{" "}
                        </>
                      ) : (
                        <>
                          {clientView.acc_manager?.company_first_name
                            ? `${clientView.acc_manager.company_first_name} ${clientView.acc_manager.company_last_name
                              ? clientView.acc_manager.company_last_name
                              : ""
                            }`
                            : `${clientView.acc_manager.company_name || ""
                            }(Company)`}
                        </>
                      )
                    }
                  />
                </div>
              </div>
            ) : (
              <div>Account manager is not assigned</div>
            )}
          </div>
          <div className="customer-since">
            <div className="customer-since-text">Customer Since</div>
            {isLoading ? (
              <div>
                <ThreeDots
                  height="18"
                  width="20"
                  radius="9"
                  color="#6479f9"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              </div>
            ) : (
              <div className="customer-since-date">
                {new Date(clientView.created_at).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })}
              </div>
            )}
          </div>
        </div>
        <div
          className={`client-details-border ${clientProjectManagers && clientProjectManagers.length > 0
            ? ""
            : "no-border"
            }`}
        >
          <div className="client-detail-row-two">
            <div className="point-of-contact">
              <div className="point-of-contact-text">Point of Contact</div>
              {isLoading ? (
                <div>
                  <ThreeDots
                    height="18"
                    width="20"
                    radius="9"
                    color="#6479f9"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                </div>
              ) : clientView.poc_user_name ? (
                <div className="client-manager-image-with-name">
                  <span className="contact-profile-icon">
                    {/* {clientView.contact_point?.profile_image ? (
                                        <img
                                            src={`/uploads/lowProfileImage/${clientView.contact_point.profile_image}`}
                                            alt={clientView.contact_point.first_name}
                                            onError={(e) => {
                                                e.target.onerror = null;
                                                e.target.src = "/uploads/lowProfileImage/user_default.png";
                                            }}
                                        />
                                    ) : (
                                        <img
                                            src={profile_image}
                                            alt={clientView.contact_point.first_name}
                                        />
                                    )} */}
                    <img src={ContactProfileImg} alt="profile" />
                  </span>
                  <div className="point-of-contact-name">
                    {/* <TruncateUserName
                                            textContent={clientView.contact_point?.first_name ? (
                                                <>
                                                    {clientView.contact_point.first_name}{' '}
                                                    {clientView.contact_point.middle_name && clientView.contact_point.middle_name !== 'null' ? clientView.contact_point.middle_name + ' ' : ''}
                                                    {clientView.contact_point.last_name}{' '}
                                                </>
                                            ) : (
                                                <>
                                                    {clientView.contact_point?.company_first_name
                                                        ? `${clientView.contact_point.company_first_name} ${clientView.contact_point.company_last_name ? clientView.contact_point.company_last_name : ''}`
                                                        : `${clientView.contact_point.company_name || ''}(Company)`}
                                                </>
                                            )}
                                        /> */}
                    <TruncateFieldView textContent={clientView.poc_user_name} />
                  </div>
                </div>
              ) : (
                <div>Point of Contact is not assigned</div>
              )}
            </div>
            <div className="email-with-icon">
              <span className="email-icon">
                <img src={MailIcon} alt="mail-icon" />
              </span>
              {isLoading ? (
                <div>
                  <ThreeDots
                    height="18"
                    width="20"
                    radius="9"
                    color="#6479f9"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                </div>
              ) : (
                <div className="mail-addrss-text">
                  <a href={`mailto:${clientView.email}`}>
                    {clientView?.email && (
                      <TruncateFieldView textContent={clientView?.email} />
                    )}
                  </a>
                </div>
              )}
            </div>
            <div className="contact-with-icon">
              <span className="contact-icon">
                <img src={PhoneIcon} alt="contact-icon" />
              </span>
              {isLoading ? (
                <div>
                  <ThreeDots
                    height="18"
                    width="20"
                    radius="9"
                    color="#6479f9"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                </div>
              ) : (
                <div className="contact-number-text">
                  <a href={`tel:${clientView.mobile_no}`}>
                    {clientView.mobile_no}
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
        {clientProjectManagers && clientProjectManagers.length > 0 && (
          <div className="account-manager project-manager account-manager-client-overview">
            <div className="account-manager-text">Assigned Team</div>
            <div className="account-manager-image-with-name-outer">
              {isLoading || props.isLoadingProject ? (
                <div>
                  <ThreeDots
                    height="18"
                    width="20"
                    radius="9"
                    color="#6479f9"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                </div>
              ) : (
                clientProjectManagers &&
                clientProjectManagers.map((manager, index) => (
                  <div className="account-manager-image-with-name">
                    <span className="manager-profile-icon">
                      {manager.profile_image ? (
                        <img
                          src={`/uploads/lowProfileImage/${manager.profile_image}`}
                          alt={manager.first_name || manager.company_name}
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src =
                              "/uploads/lowProfileImage/user_default.png";
                          }}
                        />
                      ) : (
                        <img
                          src={profile_image}
                          alt={manager.first_name || manager.company_name}
                        />
                      )}
                    </span>
                    <div className="account-manager-name">
                      <TruncateUserName
                        textContent={
                          manager.first_name ? (
                            <>
                              {manager.first_name}{" "}
                              {manager.middle_name &&
                                manager.middle_name !== "null"
                                ? manager.middle_name + " "
                                : ""}
                              {manager.last_name}{" "}
                            </>
                          ) : (
                            <>
                              {manager.company_first_name
                                ? `${manager.company_first_name} ${manager.company_last_name
                                  ? manager.company_last_name
                                  : ""
                                }`
                                : `${manager.company_name || ""}(Company)`}
                            </>
                          )
                        }
                      />
                      {/* {index < props.project.project_manager.length - 1 && ', '} */}
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default ClientDropdown;
