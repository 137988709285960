import React, { useState, useEffect } from "react";
import "./MasterTenantDetails.css";
import axios from "axios";
import { AES, enc } from "crypto-js";
import { ThreeDots } from "react-loader-spinner";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { toast } from "react-toastify";
import Modal from "react-modal";
import Delete from "../../../images/icons/delete.svg";
import Edit from "../../../images/icons/edit.svg";
import profile_image from "../../../images/user/Default_pfp.png";
import UserIcon from "../../../images/icons/single-user-icon.svg";
import EmailIcon from "../../../images/icons/email.svg";
import birthIcon from "../../../images/icons/dat-of-birth.svg";
import PassportlIcon from "../../../images/icons/passport.svg";
import Company from "../../../images/icons/company.svg";
import location from "../../../images/icons/location.svg";
import PostalCode from "../../../images/icons/postal-code.svg";
import Type from "../../../images/icons/type.svg";
import Tele from "../../../images/icons/tele.svg";
import { logout, reset } from "../../../features/auth/authSlice";
import { reset as invoiceReset } from "../../../features/invoice/invoiceSlice.js";
import { reset as headerReset, setHeaderText } from "../../../features/headerText/headerTextSlice.js";
import { reset as appSettingsReset } from "../../../features/appSettings/appSettingsSlice.js";
import MasterTenantDetailsEdit from "./MasterTenantDetailsEdit";
import TruncateFieldView from "../../helpers/truncateFieldView.js";
import { useMasterTenantView } from "./useMasterTenantView.jsx";
import moment from "moment";

const MasterTenantDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [ownerEdit, setOwnerEdit] = useState(false);
  const { encodedSubDomain } = useParams();
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);

  const user = useSelector(
    (state) => state.masterAuth.masterUser
  );
  const { appSettings } = useSelector((state) => state.appSettings);
  let token = null;

  if (user) {
    if (user && user.token) {
      token = user.token;
    }
  }
  const { data: ownerDetailView = [], isLoading, isError: ownerDetailViewError } = useMasterTenantView(encodedSubDomain, token, ownerEdit);


  console.log('ownerDetailView', ownerDetailView);
  const handleDeleteClick = () => {
    setShowConfirmation(true);
  };

  const handleConfirmationOk = async () => {
    try {
      setIsSubmitLoading(true);
      await axios.post(`/api/delete/tenant/by/email`,
        {
          encodedSubDomain: encodedSubDomain,
          email: ownerDetailView.email,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      toast.success("Tenant removed successfully", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      navigate("/master-user/tenants/list");
    } catch (error) {
      if (error.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        toast.error('This tenant is deleted!', {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
        return;
      }
      toast.error("Something went wrong while user removal", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      global.config.slackMessage(error.toString());
    } finally {
      setIsSubmitLoading(false);
    }
  };

  useEffect(() => {
    dispatch(setHeaderText("Tenant Management"));
  }, []);

  if (ownerDetailViewError) {
    if (ownerDetailViewError.response?.data?.status === "deleted") {
      // Handle the case where the user is deleted
      localStorage.removeItem("user");
      dispatch(logout());
      dispatch(reset());
      dispatch(invoiceReset());
      dispatch(headerReset());
      dispatch(appSettingsReset());
      toast.error('This user is deleted!', {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      navigate("/");
      return;
    }
    // Handle other errors
    alert(`Error fetching users`);
  }

  const goToEditProfile = (e) => {
    setOwnerEdit(true);
  };

  const handleCancelEdit = () => {
    setOwnerEdit(false);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${day}/${month}/${year}`;
  };

  return (
    <>
      {ownerEdit ? (
        <MasterTenantDetailsEdit encodedSubDomain={encodedSubDomain} onCancel={handleCancelEdit} />
      ) : (
        <div className="profile-details">
          <div className="profile-photo-outer">
            <div className="profile-photo">
              <div className="profile-photo-inner">
                <div className="profile-photo-sec">
                  {isLoading ? (
                    <div>
                      <ThreeDots
                        height="18"
                        width="20"
                        radius="9"
                        color="#6479f9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                    </div>
                  ) : (
                    <div className="profile-image">
                      {ownerDetailView && ownerDetailView.company_logo !== "null" &&
                        ownerDetailView.company_logo !== null ? (
                        <img
                          src={`/uploads/orgiOwnerCompyLog/${ownerDetailView.company_logo}`}
                          alt="Company Logo"
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = '/uploads/orgiOwnerCompyLog/user_default.png';
                          }}
                        />
                      ) : (
                        <img
                          src={profile_image}
                          alt="Company Logo"
                        />
                      )}
                    </div>
                  )}
                </div>
                <div className="profile-name">
                  <div className="profile-type">Admin Name</div>
                  {isLoading ? (
                    <div>
                      <ThreeDots
                        height="18"
                        width="20"
                        radius="9"
                        color="#6479f9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                    </div>
                  ) : (
                    <div className="profile-name-sec">
                      {ownerDetailView?.usersDetail?.first_name
                        ? `${ownerDetailView?.usersDetail?.first_name}${ownerDetailView?.usersDetail?.middle_name ? ` ${ownerDetailView?.usersDetail?.middle_name}` : ""}${ownerDetailView?.usersDetail?.last_name ? ` ${ownerDetailView?.usersDetail?.last_name}` : ""}`
                        : `${ownerDetailView?.usersDetail?.company_first_name
                          ? ownerDetailView?.usersDetail?.company_last_name
                            ? `${ownerDetailView?.usersDetail?.company_first_name} ${ownerDetailView?.usersDetail?.company_last_name}`
                            : ownerDetailView?.usersDetail?.company_first_name
                          : ownerDetailView?.usersDetail?.company_name || ownerDetailView?.commercial_name
                        }`}{" "}
                    </div>
                  )}

                  {/* {isLoading ? (
                    <div>
                      <ThreeDots
                        height="18"
                        width="20"
                        radius="9"
                        color="#6479f9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                    </div>
                  ) : (
                    <>
                      {ownerDetailView && ownerDetailView.role && (
                        <div className="profile-type">
                          {ownerDetailView.role}
                        </div>
                      )}
                    </>
                  )} */}


                  <div className="satus-collabe">
                    <div className="profile-status">
                      {isLoading ? (
                        <div>
                          <ThreeDots
                            height="18"
                            width="20"
                            radius="9"
                            color="#6479f9"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                          />
                        </div>
                      ) : (
                        <>
                          <div className="profile-status-text">Account Status</div>
                          {ownerDetailView.ownerMasterDetail.status === false ? (
                            <div className="inactive">
                              Inactive
                            </div>
                          ) : ownerDetailView.ownerMasterDetail.status === true ? (
                            <div className="active">
                              Active
                            </div>
                          ) : (
                            ownerDetailView.is_active
                          )}

                        </>
                      )}
                    </div>
                    <div className="subscription-status">
                      {isLoading ? (
                        <div>
                          <ThreeDots
                            height="18"
                            width="20"
                            radius="9"
                            color="#6479f9"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                          />
                        </div>
                      ) : (
                        <>
                          <div className="subscription-status-text">Subscription Status</div>
                          <div className="active">
                            Free Trial
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {isLoading ? (
              <div>
                <ThreeDots
                  height="18"
                  width="20"
                  radius="9"
                  color="#6479f9"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              </div>
            ) : (
              <div className="profile-button">
                <a onClick={goToEditProfile} className="edit-profile">
                  <span class="bt-ico">
                    <img src={Edit} alt="edit" />
                  </span>
                  Edit Profile
                </a>{" "}
                {/* {userView._id !== user._id && ( */}
                <a
                  className="delete-profile"
                  onClick={() => handleDeleteClick()}
                >
                  <span class="bt-ico">
                    <img src={Delete} alt="delete" />
                  </span>
                  Delete
                </a>
                <Modal
                  className="delete-modal"
                  isOpen={showConfirmation}
                  onRequestClose={() => setShowConfirmation(false)}
                  contentLabel="Confirmation Delete"
                  style={{
                    content: {
                      height: "41%",
                      width: "30%",
                      position: "fixed",
                      top: "36%",
                      left: "50%",
                      transform: "translate(-19.75%, 0%)",
                      parent: document.querySelector(".admin-outer.time.tracker"),
                    },
                  }}
                >
                  <div className="delete-modal-content">
                    <h2>Confirm Delete</h2>
                    <p>
                      Are you sure you want to delete this Tenant?
                    </p>
                    <div className="delete-modal-buttons">
                      <button className="buttons-rejected" onClick={handleConfirmationOk} disabled={isSubmitLoading}>
                        {isSubmitLoading ?
                          <div className="outter-load-table">
                            <ThreeDots
                              height="18"
                              width="20"
                              radius="9"
                              color="white"
                              ariaLabel="three-dots-loading"
                              wrapperStyle={{}}
                              wrapperClassName=""
                              visible={true}
                            />
                          </div> : "Delete"}
                      </button>
                      <button onClick={() => setShowConfirmation(false)} disabled={isSubmitLoading}>
                        Cancel
                      </button>
                    </div>
                  </div>
                </Modal>
                {/* )} */}
              </div>
            )}
          </div>

          <div className="information-outer-section">

            {console.log("ownerDetailView", ownerDetailView)}
            <div className="information-inner">
              <div className="information">
                <div className="information-title">Admin Information</div>
                <div className="information-outer">
                  <div className="information-left">
                    {ownerDetailView?.usersDetail?.first_name && (
                      <div className="inner-detail-outer">
                        <div className="inner-detail">
                          <span class="icon">
                            <img alt="" src={UserIcon} />
                          </span>
                          <div className="inner-detail-inner">
                            <label>First name</label>
                          </div>
                        </div>
                        <div className="inner-detail">
                          <span class="icon"></span>
                          <div className="inner-detail-inner">
                            {isLoading ? (
                              <ThreeDots
                                height="18"
                                width="20"
                                radius="9"
                                color="#6479f9"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                              />
                            ) : (
                              <div className="value-field">{ownerDetailView?.usersDetail?.first_name}</div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}

                    {ownerDetailView?.usersDetail?.middle_name && (
                      <div className="inner-detail-outer">
                        <div className="inner-detail">
                          <span class="icon">
                            <img alt="" src={UserIcon} />
                          </span>
                          <div className="inner-detail-inner">
                            <label>Middle name</label>
                          </div>
                        </div>
                        <div className="inner-detail">
                          <span class="icon"></span>
                          <div className="inner-detail-inner">
                            {isLoading ? (
                              <ThreeDots
                                height="18"
                                width="20"
                                radius="9"
                                color="#6479f9"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                              />
                            ) : (
                              <div className="value-field">{ownerDetailView?.usersDetail?.middle_name}</div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}

                    {ownerDetailView?.usersDetail?.last_name && (
                      <div className="inner-detail-outer">
                        <div className="inner-detail">
                          <span class="icon">
                            <img alt="" src={UserIcon} />
                          </span>
                          <div className="inner-detail-inner">
                            <label>Last name</label>
                          </div>
                        </div>
                        <div className="inner-detail">
                          <span class="icon"></span>
                          <div className="inner-detail-inner">
                            {isLoading ? (
                              <ThreeDots
                                height="18"
                                width="20"
                                radius="9"
                                color="#6479f9"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                              />
                            ) : (
                              <div className="value-field">{ownerDetailView?.usersDetail?.last_name}</div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>


                  <div className="information-right">
                    {ownerDetailView?.usersDetail?.email && (
                      <div className="inner-detail-outer">
                        <div className="inner-detail">
                          <span class="icon">
                            <img alt="" src={EmailIcon} />
                          </span>
                          <div className="inner-detail-inner">
                            <label>{global.config.locate(appSettings && appSettings.language, 'Email')}</label>
                          </div>
                        </div>
                        <div className="inner-detail">
                          <span class="icon"></span>
                          <div className="inner-detail-inner">
                            {isLoading ? (
                              <ThreeDots
                                height="18"
                                width="20"
                                radius="9"
                                color="#6479f9"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                              />
                            ) : (
                              <div className="value-field">{ownerDetailView?.usersDetail?.email}</div>)}
                          </div>
                        </div>
                      </div>
                    )}

                    {ownerDetailView?.usersDetail?.dob && (
                      <div className="inner-detail-outer">
                        <div className="inner-detail">
                          <span class="icon">
                            <img alt="" src={birthIcon} />
                          </span>
                          <div className="inner-detail-inner">
                            <label>Date of birth</label>
                          </div>
                        </div>
                        <div className="inner-detail">
                          <span class="icon"></span>
                          <div className="inner-detail-inner">
                            {isLoading ? (
                              <ThreeDots
                                height="18"
                                width="20"
                                radius="9"
                                color="#6479f9"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                              />
                            ) : (
                              <div className="value-field">{formatDate(ownerDetailView?.usersDetail?.dob)}</div>)}

                          </div>
                        </div>
                      </div>
                    )}

                    {ownerDetailView?.usersDetail?.passport_no && (
                      <div className="inner-detail-outer">
                        <div className="inner-detail">
                          <span class="icon">
                            <img alt="" src={PassportlIcon} />
                          </span>
                          <div className="inner-detail-inner">
                            <label>Passport of ID Number</label>
                          </div>
                        </div>
                        <div className="inner-detail">
                          <span class="icon"></span>
                          <div className="inner-detail-inner">
                            {isLoading ? (
                              <ThreeDots
                                height="18"
                                width="20"
                                radius="9"
                                color="#6479f9"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                              />
                            ) : (
                              <div className="value-field">{ownerDetailView?.usersDetail?.passport_no}</div>)}
                          </div>
                        </div>
                      </div>
                    )}



                  </div>
                </div>
              </div>
            </div>


            <div className="information-inner">
              <div className="information">
                <div className="information-title">{global.config.locate(appSettings && appSettings.language, 'Company_Information')}</div>
                <div className="information-outer">
                  <div className="information-left">


                    {/* {ownerDetailView.commercial_name && ( */}
                    <div className="inner-detail-outer">
                      <div className="inner-detail">
                        <span class="icon">
                          <img alt="" src={Type} />
                        </span>
                        <div className="inner-detail-inner">
                          <label>Type of Account</label>
                        </div>
                      </div>
                      <div className="inner-detail">
                        <span class="icon"></span>
                        <div className="inner-detail-inner">
                          {isLoading ? (
                            <ThreeDots
                              height="18"
                              width="20"
                              radius="9"
                              color="#6479f9"
                              ariaLabel="three-dots-loading"
                              wrapperStyle={{}}
                              wrapperClassName=""
                              visible={true}
                            />
                          ) : (
                            <div className="value-field">Enterprise</div>
                          )}
                        </div>
                      </div>
                    </div>
                    {/* )} */}

                    {ownerDetailView.commercial_name && (
                      <div className="inner-detail-outer">
                        <div className="inner-detail">
                          <span class="icon">
                            <img alt="" src={Company} />
                          </span>
                          <div className="inner-detail-inner">
                            <label>Company</label>
                          </div>
                        </div>
                        <div className="inner-detail">
                          <span class="icon"></span>
                          <div className="inner-detail-inner">
                            {isLoading ? (
                              <ThreeDots
                                height="18"
                                width="20"
                                radius="9"
                                color="#6479f9"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                              />
                            ) : (
                              <div className="value-field">{ownerDetailView.commercial_name}</div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}



                  </div>
                  <div className="information-right">
                    {ownerDetailView.email && (
                      <div className="inner-detail-outer">
                        <div className="inner-detail">
                          <span class="icon">
                            <img alt="" src={Company} />
                          </span>
                          <div className="inner-detail-inner">
                            <label>Sub-domain</label>
                          </div>
                        </div>
                        <div className="inner-detail">
                          <span class="icon"></span>
                          <div className="inner-detail-inner">
                            {isLoading ? (
                              <ThreeDots
                                height="18"
                                width="20"
                                radius="9"
                                color="#6479f9"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                              />
                            ) : (
                              <div className="value-field">{ownerDetailView.ownerMasterDetail.subDomain}</div>)}
                          </div>
                        </div>
                      </div>
                    )}
                    { console.log('zzzzcccc',ownerDetailView) }
                    {ownerDetailView.company_id && (
                      <div className="inner-detail-outer">
                        <div className="inner-detail">
                          <span class="icon">
                            <img alt="" src={PassportlIcon} />
                          </span>
                          <div className="inner-detail-inner">
                            <label>Date Added</label>
                          </div>
                        </div>
                        <div className="inner-detail">
                          <span class="icon"></span>
                          <div className="inner-detail-inner">
                            {isLoading ? (
                              <ThreeDots
                                height="18"
                                width="20"
                                radius="9"
                                color="#6479f9"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                              />
                            ) : (
                              <div className="value-field">{moment(ownerDetailView.ownerMasterDetail.created_at).format("MMMM DD, YYYY")}</div>)}
                          </div>
                        </div>
                      </div>
                    )}


                  </div>
                </div>
              </div>
            </div>

            {ownerDetailView.address && (
              <div className="information-inner">
                <div className="information">
                  <div className="information-title">{global.config.locate(appSettings && appSettings.language, 'Address')}</div>
                  <div className="information-outer">
                    <div className="information-left">
                      {ownerDetailView.city && (
                        <div className="inner-detail-outer">
                          <div className="inner-detail">
                            <span class="icon">
                              <img alt="" src={location} />
                            </span>
                            <div className="inner-detail-inner">
                              <label>{global.config.locate(appSettings && appSettings.language, 'City')}</label>
                            </div>
                          </div>
                          <div className="inner-detail">
                            <span class="icon"></span>
                            <div className="inner-detail-inner">
                              {isLoading ? (
                                <ThreeDots
                                  height="18"
                                  width="20"
                                  radius="9"
                                  color="#6479f9"
                                  ariaLabel="three-dots-loading"
                                  wrapperStyle={{}}
                                  wrapperClassName=""
                                  visible={true}
                                />
                              ) : (
                                <div className="value-field">{ownerDetailView.city}</div>)}
                            </div>
                          </div>
                        </div>
                      )}
                      {ownerDetailView.country && (
                        <div className="inner-detail-outer">
                          <div className="inner-detail">
                            <span class="icon">
                              <img alt="" src={location} />
                            </span>
                            <div className="inner-detail-inner">
                              <label>{global.config.locate(appSettings && appSettings.language, 'Country')}</label>
                            </div>
                          </div>
                          <div className="inner-detail">
                            <span class="icon"></span>
                            <div className="inner-detail-inner">
                              {isLoading ? (
                                <ThreeDots
                                  height="18"
                                  width="20"
                                  radius="9"
                                  color="#6479f9"
                                  ariaLabel="three-dots-loading"
                                  wrapperStyle={{}}
                                  wrapperClassName=""
                                  visible={true}
                                />
                              ) : (
                                <div className="value-field">{ownerDetailView.country}</div>)}
                            </div>
                          </div>
                        </div>
                      )}
                      {ownerDetailView.address && (
                        <div className="inner-detail-outer">
                          <div className="inner-detail">
                            <span class="icon">
                              <img alt="" src={location} />
                            </span>
                            <div className="inner-detail-inner">
                              <label>{global.config.locate(appSettings && appSettings.language, 'Address')}</label>
                            </div>
                          </div>
                          <div className="inner-detail">
                            <span class="icon"></span>
                            <div className="inner-detail-inner">
                              {isLoading ? (
                                <ThreeDots
                                  height="18"
                                  width="20"
                                  radius="9"
                                  color="#6479f9"
                                  ariaLabel="three-dots-loading"
                                  wrapperStyle={{}}
                                  wrapperClassName=""
                                  visible={true}
                                />
                              ) : (
                                <div className="value-field"><TruncateFieldView textContent={ownerDetailView.address} /></div>)}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="information-right">
                      {ownerDetailView.pincode && (
                        <div className="inner-detail-outer">
                          <div className="inner-detail">
                            <span class="icon">
                              <img alt="" src={PostalCode} />
                            </span>
                            <div className="inner-detail-inner">
                              <label>{global.config.locate(appSettings && appSettings.language, 'Postal_Code')}</label>
                            </div>
                          </div>
                          <div className="inner-detail">
                            <span class="icon"></span>
                            <div className="inner-detail-inner">
                              {isLoading ? (
                                <ThreeDots
                                  height="18"
                                  width="20"
                                  radius="9"
                                  color="#6479f9"
                                  ariaLabel="three-dots-loading"
                                  wrapperStyle={{}}
                                  wrapperClassName=""
                                  visible={true}
                                />
                              ) : (
                                <div className="value-field">{ownerDetailView.pincode}</div>)}
                            </div>
                          </div>
                        </div>
                      )}
                      {ownerDetailView.mobile_no && (
                        <div className="inner-detail-outer">
                          <div className="inner-detail">
                            <span class="icon">
                              <img alt="" src={Tele} />
                            </span>
                            <div className="inner-detail-inner">
                              <label>{global.config.locate(appSettings && appSettings.language, 'Mobile_No')}</label>
                            </div>
                          </div>
                          <div className="inner-detail">
                            <span class="icon"></span>
                            <div className="inner-detail-inner">
                              {isLoading ? (
                                <ThreeDots
                                  height="18"
                                  width="20"
                                  radius="9"
                                  color="#6479f9"
                                  ariaLabel="three-dots-loading"
                                  wrapperStyle={{}}
                                  wrapperClassName=""
                                  visible={true}
                                />
                              ) : (
                                <div className="value-field">{ownerDetailView.mobile_no}</div>)}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>)}
          </div>
        </div>
      )}
    </>
  );
};

export default MasterTenantDetails;
