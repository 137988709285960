import React from "react";

function DateConverter({ date }) {
  const formatDate = (dateString) => {
    const [isoDate, desiredFormat] = dateString.split("/");
    const dateObj = new Date(isoDate);
    const day = dateObj.getUTCDate().toString().padStart(2, "0");
    const month = (dateObj.getUTCMonth() + 1).toString().padStart(2, "0");
    const year = dateObj.getUTCFullYear();
    return "dd/mm/yyyy" ? `${day}/${month}/${year}` : null;
  };
  return <>{formatDate(date)}</>;
}

export default DateConverter;