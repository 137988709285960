import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ThreeDots } from "react-loader-spinner";
import TruncateFieldView from '../../../helpers/truncateFieldView';
import { ProjectColumn, ProjectLoggedHours, ProjectRoi, ProjectTotalCost, ProjectTotalBudget } from '../../Services/reportingOverview';
import { convertCurrencyHelper } from '../../../../helpers/forexConvertor';
import { useTotalCostByProject } from '../../../../features/projects/ProjectList';

const CostPerProjectOverview = ({ projects, projectLoading }) => {
    const reportingFinancialOverview = useSelector((state) => state.reportingFinancialOverview);
    const [filteredData, setFilteredData] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);

    useEffect(() => {
        if (projects && reportingFinancialOverview) {
            const filtered = projects.filter((project) =>
                project &&
                !project.is_delete &&
                (reportingFinancialOverview.financialOverviewProject && reportingFinancialOverview.financialOverviewProject.includes(project._id)) &&
                new Date(project.created_at).getFullYear() === reportingFinancialOverview.financialOverviewYear &&
                (reportingFinancialOverview.financialOverviewCurrency && reportingFinancialOverview.financialOverviewCurrency.includes(project?.client_name?.preferred_currency || 'USD'))
            );
            const sortedData = filtered.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
            setFilteredData(sortedData);
        }
    }, [projects, reportingFinancialOverview]);

    const onPageChange = (e) => {
        setCurrentPage(e.first / rowsPerPage);
    };

    return (
        <div className="datatable-team-maintb">
            <DataTable
                value={filteredData}
                removableSort
                tableStyle={{ minWidth: '50rem', width: '100%', textAlign: 'left' }}
                paginator={filteredData.length > rowsPerPage}
                paginatorClassName="recent-invoices-pagination"
                rows={rowsPerPage}
                first={currentPage * rowsPerPage}
                onPage={onPageChange}
                emptyMessage={projectLoading ? (
                    <div className="outter-load-table">
                        <ThreeDots
                            height="80"
                            width="80"
                            radius="9"
                            color="#405BFF"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                        />
                    </div>
                ) : "No projects data found"}
            >
                <Column
                    field="project_name"
                    header="Project"
                    // body={(rowData) => <TruncateFieldView textContent={rowData?.project_name} />}
                    body={(rowData) => <ProjectColumn rowData={rowData} />}
                    className="fixed-sec"
                    sortable
                    style={{ paddingRight: '44px', minWidth: '280px' }}
                />
                <Column
                    field="client_name"
                    body={(rowData) => {
                        const clientName = rowData?.client_name?.client_name || rowData?.client_name?.legal_entity_name || 'Client Name';
                        return <TruncateFieldView textContent={clientName} />;
                    }}
                    header="Client Name"
                    sortField="client_name.client_name"
                    sortable
                    style={{ paddingRight: '44px', minWidth: '280px' }}
                />
                <Column
                    field="totalRevenue"
                    header="Total Budget"
                    body={ProjectTotalBudget}
                    sortable
                    style={{ paddingRight: '44px' }}
                />
                <Column
                    field="tracked_hours"
                    header="Time Logged"
                    className="fixed-sec4"
                    body={ProjectLoggedHours}
                    style={{ paddingRight: '44px' }}
                />
                <Column
                    field="cost"
                    header="Cost"
                    body={ProjectTotalCost}
                    style={{ paddingRight: '44px' }}
                />
                <Column
                    field="roi"
                    header="ROI"
                    body={ProjectRoi}
                    style={{ paddingRight: '44px' }}
                />
            </DataTable>
        </div>
    );
};

export default CostPerProjectOverview;
