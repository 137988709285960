import React, { useState, useEffect } from 'react';
import { ThreeDots } from "react-loader-spinner";
import { useSelector } from 'react-redux';
// import { useTotalBudgetByClient, useTotalCostByClient, useTotalCostByProject, useTrackedClientHours, useTrackedProjectHours } from "../../../features/projects/ProjectList";
import { convertCurrencyHelper } from '../../../../../helpers/forexConvertor';
import { CLIENT_INVOICE_STATUS } from '../../../../../constants/cliInvStatus';
import { reportingFilterDateRangesConst } from '../../../../../constants/reportingFilterDateRangesConst';
import { reportingFilterComparisonConst } from '../../../../../constants/reportingFilterComparisonConst';


export function clientRevenueTotal(trackReport, projectCost, invoices, reportingRevenue, preferredCurrency, forex, typeOfContract, expenses) {
    const currentYear = reportingRevenue.revenueYear || new Date().getFullYear();
    let totalBilledAmount = 0;
    let totalCost = 0;
    let totalExpense = 0;

    invoices.forEach(invoice => {
        const year = reportingRevenue.revenueYear || new Date().getFullYear();
        const invoiceYear = new Date(invoice?.cli_inv_end_date).getFullYear();
        const preferredClientCurrency = invoice?.cli_inv_currency || 'USD';
        const clientId = invoice.cli_inv_for?._id;
        if (invoiceYear === year && !invoice.is_delete && clientId && (reportingRevenue.revenueClient && reportingRevenue.revenueClient.includes(clientId)) && reportingRevenue.revenueCurrency.includes(preferredClientCurrency)) {
            const invoiceCurrency = invoice.cli_inv_currency || 'USD';
            totalBilledAmount += convertCurrencyHelper(invoiceCurrency, preferredCurrency, invoice.cli_inv_total_amount, forex);
        }
    });

    trackReport.forEach(entry => {
        const { project, clientId, user, startTime, duration } = entry;
        const projectYear = new Date(startTime).getFullYear();
        const projectId = project && project._id;

        if (projectYear === currentYear && project && !project.is_delete && (reportingRevenue.revenueProject && reportingRevenue.revenueProject.includes(projectId)) && (reportingRevenue.revenueCurrency && reportingRevenue.revenueCurrency.includes(clientId?.preferred_currency || 'USD'))) {

            // const userCost = user.cost || 0;
            const projectSpecificCost = projectCost.find(cost => cost.userId === user?._id && cost.projectId === projectId && !cost.is_delete);
            // const userCost = projectSpecificCost && projectSpecificCost.amount > 0 ? projectSpecificCost.amount : (user.cost || 0);
            let userCost;
            if (projectSpecificCost && projectSpecificCost.amount > 0) {
                userCost = convertCurrencyHelper(
                    clientId?.preferred_currency || 'USD',
                    user.payment_currency,
                    projectSpecificCost.amount,
                    forex
                );
            } else {
                userCost = user.cost || 0;
            }
            const userOverheadCost = user.overhead_cost || 0;
            const userTypeOfContract = user.typeOfContract || typeOfContract.Hourly_Rate;
            const paymentCurrency = user.payment_currency || 'USD';

            let finalCost;
            if (userTypeOfContract === typeOfContract.Hourly_Rate) {
                finalCost = userCost;
            } else {
                finalCost = userCost / parseInt(user?.monthly_hours ? user?.monthly_hours : 168); // Assuming a different type of contract with monthly rate divided by 168 hours
            }

            const cost = (finalCost * (duration / 3600)) + (userOverheadCost * (duration / 3600));
            totalCost += convertCurrencyHelper(paymentCurrency, preferredCurrency, cost, forex);
        }
    });

    // Calculate total expenses per client
    expenses.forEach(expense => {
        const clientId = expense?.exp_client?._id;
        const expenseYear = new Date(expense.exp_date).getFullYear();

        if (expenseYear === currentYear && !expense.is_delete && clientId && (reportingRevenue.revenueClient ? reportingRevenue.revenueClient.includes(clientId) : true)) {
            const convertedAmount = convertCurrencyHelper(expense.exp_currency, preferredCurrency, expense.exp_amount, forex);
            totalExpense += convertedAmount;
        }
    });

    // Calculate total revenue
    const totalRevenue = totalBilledAmount - (totalCost + totalExpense);

    return parseFloat(totalRevenue).toFixed(2);
}

export function clientHighestRevenue(trackReport, projectCost, invoices, reportingRevenue, preferredCurrency, forex, typeOfContract, expenses) {
    const currentYear = reportingRevenue.revenueYear || new Date().getFullYear();
    const clientData = {};

    // Calculate total billed amount per client
    const totalBilledAmountPerClient = {};
    invoices.forEach(invoice => {
        const invoiceYear = new Date(invoice?.cli_inv_end_date).getFullYear();
        const clientId = invoice.cli_inv_for?._id;
        const preferredClientCurrency = invoice?.cli_inv_currency || 'USD';

        if (invoiceYear === currentYear && !invoice.is_delete && clientId && (reportingRevenue.revenueClient ? reportingRevenue.revenueClient.includes(clientId) : true) && (reportingRevenue.revenueCurrency && reportingRevenue.revenueCurrency.includes(preferredClientCurrency))) {
            const invoiceCurrency = invoice.cli_inv_currency || 'USD';
            const convertedAmount = convertCurrencyHelper(invoiceCurrency, preferredCurrency, invoice.cli_inv_total_amount, forex);

            if (!totalBilledAmountPerClient[clientId]) {
                totalBilledAmountPerClient[clientId] = 0;
            }
            totalBilledAmountPerClient[clientId] += convertedAmount;
        }
    });

    // Calculate project costs per client
    trackReport.forEach(entry => {
        const { project, clientId, user, startTime, duration } = entry;
        const projectYear = new Date(startTime).getFullYear();
        const projectId = project && project._id;

        if (projectYear === currentYear && project && !project.is_delete && (reportingRevenue.revenueProject && reportingRevenue.revenueProject.includes(projectId)) && (reportingRevenue.revenueCurrency && reportingRevenue.revenueCurrency.includes(clientId?.preferred_currency || 'USD'))) {
            const preferredClientCurrency = clientId?.preferred_currency || 'USD';
            if (!clientData[clientId?._id]) {
                clientData[clientId?._id] = {
                    budget: 0,
                    cost: 0,
                    totalExpense: 0,
                    details: clientId
                };
            }

            clientData[clientId?._id].budget += convertCurrencyHelper(preferredClientCurrency, preferredCurrency, project.budget, forex);
            // const userCost = user.cost || 0;
            const projectSpecificCost = projectCost.find(cost => cost.userId === user?._id && cost.projectId === projectId && !cost.is_delete);
            // const userCost = projectSpecificCost && projectSpecificCost.amount > 0 ? projectSpecificCost.amount : (user.cost || 0);
            let userCost;
            if (projectSpecificCost && projectSpecificCost.amount > 0) {
                userCost = convertCurrencyHelper(
                    clientId?.preferred_currency || 'USD',
                    user.payment_currency,
                    projectSpecificCost.amount,
                    forex
                );
            } else {
                userCost = user.cost || 0;
            }
            const userOverheadCost = user.overhead_cost || 0;
            const userTypeOfContract = user.typeOfContract || typeOfContract.Hourly_Rate;
            const paymentCurrency = user.payment_currency || 'USD';

            let finalCost;
            if (userTypeOfContract === typeOfContract.Hourly_Rate) {
                finalCost = userCost;
            } else {
                finalCost = userCost / parseInt(user?.monthly_hours ? user?.monthly_hours : 168); // Assuming a different type of contract with monthly rate divided by 168 hours
            }

            const cost = (finalCost * (duration / 3600)) + (userOverheadCost * (duration / 3600));
            clientData[clientId?._id].cost += convertCurrencyHelper(paymentCurrency, preferredCurrency, cost, forex);
        }
    });

    // Calculate total expenses per client
    expenses.forEach(expense => {
        const clientId = expense?.exp_client?._id;
        const expenseYear = new Date(expense.exp_date).getFullYear();

        if (expenseYear === currentYear && !expense.is_delete && clientId && (reportingRevenue.revenueClient ? reportingRevenue.revenueClient.includes(clientId) : true)) {
            const convertedAmount = convertCurrencyHelper(expense.exp_currency, preferredCurrency, expense.exp_amount, forex);

            if (!clientData[clientId]) {
                clientData[clientId] = {
                    budget: 0,
                    cost: 0,
                    totalExpense: 0,
                    details: expense.exp_client
                };
            }

            clientData[clientId].totalExpense += convertedAmount;
        }
    });

    // Calculate Revenue and find the client with the highest Revenue
    let highestRevenueClient = null;
    for (const clientId in clientData) {
        const client = clientData[clientId];
        const totalBilled = totalBilledAmountPerClient[clientId] || 0;
        const totalCostAndExpense = client.cost + client.totalExpense;
        const revenue = (totalBilled - totalCostAndExpense);

        client.revenue = revenue;

        if (!highestRevenueClient || revenue > highestRevenueClient.revenue) {
            highestRevenueClient = client;
        }
    }

    return highestRevenueClient;
}

export function clientTotalRevenueHistory(trackReport, projectCost, invoices, reportingRevenue, preferredCurrency, forex, typeOfContract, expenses) {
    const year = reportingRevenue.revenueYear || new Date().getFullYear();

    const initializeMonthlyData = () => {
        return Array.from({ length: 12 }, (_, i) => ({
            name: new Date(year, i).toLocaleString('default', { month: 'long' }),
            totalBilled: 0,
            totalExpense: 0,
            cost: 0
        }));
    };

    const calculateMonthlyData = () => {
        const monthlyData = initializeMonthlyData();

        const totalBilledAmountPerClient = {};

        invoices && invoices.forEach(invoice => {
            const invoiceYear = new Date(invoice?.cli_inv_end_date).getFullYear();
            const preferredClientCurrency = invoice?.cli_inv_currency || 'USD';
            const clientId = invoice?.cli_inv_for?._id;

            if (invoiceYear === year && !invoice.is_delete && clientId && (reportingRevenue.revenueClient ? reportingRevenue.revenueClient.includes(clientId) : true) && (reportingRevenue.revenueCurrency && reportingRevenue.revenueCurrency.includes(preferredClientCurrency))) {
                const invoiceCurrency = invoice?.cli_inv_currency || 'USD';
                const convertedAmount = convertCurrencyHelper(invoiceCurrency, preferredCurrency, invoice.cli_inv_total_amount, forex);

                if (!totalBilledAmountPerClient[clientId]) {
                    totalBilledAmountPerClient[clientId] = 0;
                }
                totalBilledAmountPerClient[clientId] += convertedAmount;

                const month = new Date(invoice.cli_inv_end_date).getMonth();
                monthlyData[month].totalBilled += convertedAmount;
            }
        });

        const clientData = {};

        trackReport.forEach(project => {
            const projectYear = new Date(project.startTime).getFullYear();
            const preferredClientCurrency = project?.clientId?.preferred_currency || 'USD';

            if (projectYear === year && (project?.project && !project?.project?.is_delete) && (reportingRevenue.revenueProject && reportingRevenue.revenueProject.includes(project?.project?._id)) && (reportingRevenue.revenueCurrency && reportingRevenue.revenueCurrency.includes(preferredClientCurrency))) {
                const clientId = project?.clientId?._id;
                // const userCost = project.user.cost || 0;
                const projectSpecificCost = projectCost.find(cost => cost.userId === project.user?._id && cost.projectId === project?.project?._id && !cost.is_delete);
                // const userCost = projectSpecificCost && projectSpecificCost.amount > 0 ? projectSpecificCost.amount : (project.user.cost || 0);
                let userCost;
                if (projectSpecificCost && projectSpecificCost.amount > 0) {
                    userCost = convertCurrencyHelper(
                        preferredClientCurrency,
                        project.user.payment_currency,
                        projectSpecificCost.amount,
                        forex
                    );
                } else {
                    userCost = project?.user?.cost || 0;
                }
                const userTypeOfContract = project?.user?.typeOfContract || typeOfContract?.Hourly_Rate;
                const userOverheadCost = project?.user?.overhead_cost || 0;
                const paymentCurrency = project?.user?.payment_currency || 'USD';

                let finalCost;
                if (userTypeOfContract === typeOfContract.Hourly_Rate) {
                    finalCost = userCost;
                } else {
                    finalCost = userCost / parseInt(project?.user?.monthly_hours ? project?.user?.monthly_hours : 168); // Adjust as needed for different contract types
                }
                const cost = (finalCost * (project.duration / 3600)) + (userOverheadCost * (project.duration / 3600));
                const costConverted = convertCurrencyHelper(paymentCurrency, preferredCurrency, cost, forex);

                if (!clientData[clientId]) {
                    clientData[clientId] = {
                        cost: 0,
                        totalBilled: totalBilledAmountPerClient[clientId] || 0,
                        totalExpense: 0
                    };
                }

                clientData[clientId].cost += costConverted;

                const month = new Date(project.startTime).getMonth();
                monthlyData[month].cost += costConverted;
            }
        });

        expenses.forEach(expense => {
            const clientId = expense?.exp_client?._id;
            const expenseYear = new Date(expense.exp_date).getFullYear();

            if (expenseYear === year && !expense.is_delete && clientId && (reportingRevenue.revenueClient ? reportingRevenue.revenueClient.includes(clientId) : true)) {
                const convertedAmount = convertCurrencyHelper(expense.exp_currency, preferredCurrency, expense.exp_amount, forex);

                if (!clientData[clientId]) {
                    clientData[clientId] = {
                        cost: 0,
                        totalBilled: totalBilledAmountPerClient[clientId] || 0,
                        totalExpense: 0
                    };
                }

                clientData[clientId].totalExpense += convertedAmount;

                const month = new Date(expense.exp_date).getMonth();
                monthlyData[month].totalExpense += convertedAmount;
            }
        });

        return monthlyData.map(month => ({
            name: month.name,
            revenue: (month.totalBilled - (month.cost + month.totalExpense)).toFixed(2)
        }));
        // return monthlyData.map(month => {
        //     console.log("Month name", month.name);
        //     console.log("totalBilled out", month.totalBilled);
        //     console.log("cost out", month.cost);
        //     console.log("totalExpense out", month.totalExpense);
        //     return {
        //         name: month.name,
        //         revenue: (month.totalBilled - (month.cost + month.totalExpense)).toFixed(2)
        //     };
        // });
    };

    return calculateMonthlyData();
}

// export function clientTotalRevenueHistoryFilter(
//     trackReport,
//     projectCost,
//     invoices,
//     reportingRevenue,
//     preferredCurrency,
//     forex,
//     typeOfContract,
//     expenses,
//     selectedDateRange,
//     selectedComparison,
//     selectedFrequency,
//     selectedYearRange,
//     selectedMonthRange,
//     selectedComparisonYear,
//     selectedComparisonMonth,
// ) {
//     const getLastDayOfMonth = (year, month) => {
//         return new Date(year, month, 0).getDate();
//     };

//     const getStartAndEndDates = (rangeId, year = new Date().getFullYear()) => {
//         switch (rangeId) {
//             case reportingFilterDateRangesConst.This_Year:
//                 return [new Date(`${year}-01-01`), new Date(`${year}-12-31`)];

//             case reportingFilterDateRangesConst.This_Quarter: {
//                 const quarter = Math.floor((new Date().getMonth() + 3) / 3);
//                 const startMonth = (quarter - 1) * 3 + 1;
//                 const endMonth = startMonth + 2;
//                 const endDay = getLastDayOfMonth(year, endMonth);
//                 return [new Date(`${year}-${startMonth}-01`), new Date(`${year}-${endMonth}-${endDay}`)];
//             }

//             case reportingFilterDateRangesConst.First_Half:
//                 return [new Date(`${year}-01-01`), new Date(`${year}-06-30`)];

//             case reportingFilterDateRangesConst.This_Month: {
//                 const month = new Date().getMonth() + 1;
//                 const endDay = getLastDayOfMonth(year, month);
//                 return [new Date(`${year}-${month}-01`), new Date(`${year}-${month}-${endDay}`)];
//             }

//             case reportingFilterDateRangesConst.Year_Selected:
//                 if (selectedYearRange) {
//                     const selectedYear = selectedYearRange.getFullYear();
//                     return [new Date(`${selectedYear}-01-01`), new Date(`${selectedYear}-12-31`)];
//                 } else {
//                     return [new Date(`${year}-01-01`), new Date(`${year}-12-31`)];
//                 }
//             case reportingFilterDateRangesConst.Month_Selected:
//                 if (selectedMonthRange) {
//                     const selectedMonth = selectedMonthRange.getMonth() + 1;
//                     const selectedYearForMonth = selectedMonthRange.getFullYear();
//                     const endDay = getLastDayOfMonth(selectedYearForMonth, selectedMonth);
//                     return [new Date(`${selectedYearForMonth}-${selectedMonth}-01`), new Date(`${selectedYearForMonth}-${selectedMonth}-${endDay}`)];
//                 } else {
//                     const currentMonth = new Date().getMonth() + 1;
//                     const endDay = getLastDayOfMonth(year, currentMonth);
//                     return [new Date(`${year}-${currentMonth}-01`), new Date(`${year}-${currentMonth}-${endDay}`)];
//                 }
//             default:
//                 return [new Date(`${year}-01-01`), new Date(`${year}-12-31`)];
//         }
//     };

//     const getComparisonStartAndEndDates = (comparisonId, year = new Date().getFullYear()) => {
//         switch (comparisonId) {
//             case reportingFilterComparisonConst.Last_Year:
//                 return [new Date(`${year - 1}-01-01`), new Date(`${year - 1}-12-31`)];

//             case reportingFilterComparisonConst.Last_Quarter: {
//                 const quarter = Math.floor((new Date().getMonth() + 3) / 3);
//                 const lastQuarter = quarter === 1 ? 4 : quarter - 1;
//                 const startMonth = (lastQuarter - 1) * 3 + 1;
//                 const endMonth = startMonth + 2;
//                 const lastYear = quarter === 1 ? year - 1 : year;
//                 const endDay = getLastDayOfMonth(lastYear, endMonth);
//                 return [new Date(`${lastYear}-${startMonth}-01`), new Date(`${lastYear}-${endMonth}-${endDay}`)];
//             }

//             case reportingFilterComparisonConst.Second_Half:
//                 return [new Date(`${year}-07-01`), new Date(`${year}-12-31`)];

//             case reportingFilterComparisonConst.Last_Month: {
//                 const lastMonth = new Date().getMonth();
//                 const lastYear = lastMonth === 0 ? year - 1 : year;
//                 const lastMonthFormatted = lastMonth === 0 ? 12 : lastMonth;
//                 const endDay = getLastDayOfMonth(lastYear, lastMonthFormatted);
//                 return [new Date(`${lastYear}-${lastMonthFormatted}-01`), new Date(`${lastYear}-${lastMonthFormatted}-${endDay}`)];
//             }
//             case reportingFilterComparisonConst.Year_Select:
//                 if (selectedComparisonYear) {
//                     const comparisonYear = selectedComparisonYear.getFullYear();
//                     return [new Date(`${comparisonYear}-01-01`), new Date(`${comparisonYear}-12-31`)];
//                 } else {
//                     return [new Date(`${year - 1}-01-01`), new Date(`${year - 1}-12-31`)];
//                 }

//             case reportingFilterComparisonConst.Month_Select:
//                 if (selectedComparisonMonth) {
//                     const comparisonMonth = selectedComparisonMonth.getMonth() + 1;
//                     const comparisonYearForMonth = selectedComparisonMonth.getFullYear();
//                     const endDay = getLastDayOfMonth(comparisonYearForMonth, comparisonMonth);
//                     return [new Date(`${comparisonYearForMonth}-${comparisonMonth}-01`), new Date(`${comparisonYearForMonth}-${comparisonMonth}-${endDay}`)];
//                 } else {
//                     const lastMonth = new Date().getMonth() || 12;
//                     const fallbackYear = lastMonth === 12 ? year - 1 : year;
//                     const endDay = getLastDayOfMonth(fallbackYear, lastMonth);
//                     return [new Date(`${fallbackYear}-${lastMonth}-01`), new Date(`${fallbackYear}-${lastMonth}-${endDay}`)];
//                 }
//             default:
//                 return [new Date(`${year - 1}-01-01`), new Date(`${year - 1}-12-31`)];
//         }
//     };

//     const [startCurrentRange, endCurrentRange] = getStartAndEndDates(selectedDateRange);
//     const [startComparisonRange, endComparisonRange] = getComparisonStartAndEndDates(selectedComparison);

//     const calculateRevenueData = (invoices, trackReport, projectCost, expenses, startRange, endRange, frequency, reportingRevenue, preferredCurrency, forex, typeOfContract) => {
//         const data = {};
//         const processInvoices = () => {
//             invoices.forEach(invoice => {
//                 const invoiceDate = new Date(invoice?.cli_inv_end_date);
//                 const invoiceCurrency = invoice?.cli_inv_currency || 'USD';
//                 const clientId = invoice.cli_inv_for._id;

//                 if (invoiceDate >= startRange && invoiceDate <= endRange && !invoice.is_delete &&
//                     clientId &&
//                     (reportingRevenue.revenueClient ? reportingRevenue.revenueClient.includes(clientId) : true) &&
//                     (reportingRevenue.revenueCurrency && reportingRevenue.revenueCurrency.includes(invoiceCurrency))) {

//                     const convertedAmount = convertCurrencyHelper(invoiceCurrency, preferredCurrency, invoice.cli_inv_total_amount, forex);
//                     const key = frequency === 'Monthly' ? invoiceDate.toLocaleString('default', { month: 'long' }) :
//                         frequency === 'Daily' ? invoiceDate.toLocaleDateString() : '';

//                     if (!data[key]) {
//                         data[key] = { name: key, totalBilled: 0, cost: 0, totalExpense: 0 };
//                     }
//                     data[key].totalBilled += convertedAmount;
//                 }
//             });
//         };

//         const processProjects = () => {
//             trackReport.forEach(project => {
//                 const projectDate = new Date(project.startTime);
//                 const projectYear = projectDate.getFullYear();
//                 const preferredClientCurrency = project?.clientId?.preferred_currency || 'USD';

//                 if (projectDate >= startRange && projectDate <= endRange && projectYear === new Date(startRange).getFullYear() &&
//                     (project?.project && !project?.project?.is_delete) &&
//                     (reportingRevenue.revenueProject && reportingRevenue.revenueProject.includes(project?.project?._id)) &&
//                     (reportingRevenue.revenueCurrency && reportingRevenue.revenueCurrency.includes(preferredClientCurrency))) {

//                     const clientId = project?.clientId?._id;
//                     const projectSpecificCost = projectCost.find(cost => cost.userId === project.user?._id && cost.projectId === project?.project?._id && !cost.is_delete);
//                     // const userCost = projectSpecificCost && projectSpecificCost.amount > 0 ? projectSpecificCost.amount : (project.user.cost || 0);
//                      let userCost;
//                      if (projectSpecificCost && projectSpecificCost.amount > 0) {
//                          userCost = convertCurrencyHelper(
//                              preferredClientCurrency,
//                              project.user.payment_currency,
//                              projectSpecificCost.amount,
//                              forex
//                          );
//                       } else {
//                          userCost = project.user.cost || 0;
//                       }
//                     const userTypeOfContract = project.user.typeOfContract || typeOfContract.Hourly_Rate;
//                     const userOverheadCost = project.user.overhead_cost || 0;
//                     const paymentCurrency = project.user.payment_currency || 'USD';

//                     let finalCost;
//                     if (userTypeOfContract === typeOfContract.Hourly_Rate) {
//                         finalCost = userCost;
//                     } else {
//                         finalCost = userCost / parseInt(project?.user?.monthly_hours ? project?.user?.monthly_hours : 168); // Adjust as needed for different contract types
//                     }
//                     const cost = (finalCost * (project.duration / 3600)) + (userOverheadCost * (project.duration / 3600));
//                     const costConverted = convertCurrencyHelper(paymentCurrency, preferredCurrency, cost, forex);

//                     const key = frequency === 'Monthly' ? projectDate.toLocaleString('default', { month: 'long' }) :
//                         frequency === 'Daily' ? projectDate.toLocaleDateString() : '';

//                     if (!data[key]) {
//                         data[key] = { name: key, totalBilled: 0, cost: 0, totalExpense: 0 };
//                     }
//                     data[key].cost += costConverted;
//                 }
//             });
//         };

//         const processExpenses = () => {
//             expenses.forEach(expense => {
//                 const expenseDate = new Date(expense?.exp_date);
//                 const expenseCurrency = expense?.exp_currency || 'USD';
//                 const clientId = expense?.exp_client?._id;

//                 if (expenseDate >= startRange && expenseDate <= endRange && !expense.is_delete &&
//                     clientId &&
//                     (reportingRevenue.revenueClient ? reportingRevenue.revenueClient.includes(clientId) : true)) {

//                     const convertedAmount = convertCurrencyHelper(expenseCurrency, preferredCurrency, expense.exp_amount, forex);
//                     const key = frequency === 'Monthly' ? expenseDate.toLocaleString('default', { month: 'long' }) :
//                         frequency === 'Daily' ? expenseDate.toLocaleDateString() : '';

//                     if (!data[key]) {
//                         data[key] = { name: key, totalBilled: 0, cost: 0, totalExpense: 0 };
//                     }
//                     data[key].totalExpense += convertedAmount;
//                 }
//             });
//         };

//         // Process all data
//         processInvoices();
//         processProjects();
//         processExpenses();

//         // Format the data for output
//         return Object.values(data).map(item => ({
//             name: item.name,
//             revenue: (item.totalBilled - (item.cost + item.totalExpense)).toFixed(2)
//         }));
//         // return Object.values(data).map(item => {
//         //     console.log("Month name", item.name);
//         //     console.log("totalBilled", item.totalBilled);
//         //     console.log("cost", item.cost);
//         //     console.log("totalExpense", item.totalExpense);
//         //     return {
//         //         name: item.name,
//         //         revenue: (item.totalBilled - (item.cost + item.totalExpense)).toFixed(2)
//         //     };
//         // });
//     };

//     const currentData = calculateRevenueData(invoices, trackReport, projectCost, expenses, startCurrentRange, endCurrentRange, selectedFrequency === '1' ? 'Monthly' : 'Daily', reportingRevenue, preferredCurrency, forex, typeOfContract);
//     const comparisonData = calculateRevenueData(invoices, trackReport, projectCost, expenses, startComparisonRange, endComparisonRange, selectedFrequency === '1' ? 'Monthly' : 'Daily', reportingRevenue, preferredCurrency, forex, typeOfContract);

//     // Ensure that `currentData` and `comparisonData` have consistent `name` fields
//     const allKeys = new Set([...currentData.map(item => item.name), ...comparisonData.map(item => item.name)]);

//     let mergedData = Array.from(allKeys).map(name => ({
//         name: name,
//         currentYear: parseFloat(currentData.find(item => item.name === name)?.revenue || 0),
//         lastYear: parseFloat(comparisonData.find(item => item.name === name)?.revenue || 0),
//     }));

//     if (selectedFrequency === '1') {
//         const monthOrder = [
//             'January', 'February', 'March', 'April', 'May', 'June',
//             'July', 'August', 'September', 'October', 'November', 'December'
//         ];
//         mergedData = mergedData.sort((a, b) => monthOrder.indexOf(a.name) - monthOrder.indexOf(b.name));
//     } else {
//         const parseDate = (dateString) => {
//             const [day, month, year] = dateString.split('/').map(Number);
//             return new Date(year, month - 1, day); // JavaScript months are 0-based
//         };
//         mergedData = mergedData.sort((a, b) => {
//             const dateA = parseDate(a.name);
//             const dateB = parseDate(b.name);
//             return dateA - dateB;
//         });
//     }

//     return mergedData;
// }

export function clientTotalRevenueHistoryFilter(
    trackReport,
    projectCost,
    invoices,
    reportingRevenue,
    preferredCurrency,
    forex,
    typeOfContract,
    expenses,
    selectedDateRange,
    selectedComparison,
    selectedFrequency,
    selectedYearRange,
    selectedMonthRange,
    selectedComparisonYear,
    selectedComparisonMonth,
) {
    const getLastDayOfMonth = (year, month) => {
        return new Date(year, month, 0).getDate();
    };

    const getStartAndEndDates = (rangeId, year = new Date().getFullYear()) => {
        switch (rangeId) {
            case reportingFilterDateRangesConst.This_Year:
                return [new Date(`${year}-01-01`), new Date(`${year}-12-31`)];

            case reportingFilterDateRangesConst.This_Quarter: {
                const quarter = Math.floor((new Date().getMonth() + 3) / 3);
                const startMonth = (quarter - 1) * 3 + 1;
                const endMonth = startMonth + 2;
                const endDay = getLastDayOfMonth(year, endMonth);
                return [new Date(`${year}-${startMonth}-01`), new Date(`${year}-${endMonth}-${endDay}`)];
            }

            case reportingFilterDateRangesConst.First_Half:
                return [new Date(`${year}-01-01`), new Date(`${year}-06-30`)];

            case reportingFilterDateRangesConst.This_Month: {
                const month = new Date().getMonth() + 1;
                const endDay = getLastDayOfMonth(year, month);
                return [new Date(`${year}-${month}-01`), new Date(`${year}-${month}-${endDay}`)];
            }

            case reportingFilterDateRangesConst.Year_Selected:
                if (selectedYearRange) {
                    const selectedYear = selectedYearRange.getFullYear();
                    return [new Date(`${selectedYear}-01-01`), new Date(`${selectedYear}-12-31`)];
                } else {
                    return [new Date(`${year}-01-01`), new Date(`${year}-12-31`)];
                }
            case reportingFilterDateRangesConst.Month_Selected:
                if (selectedMonthRange) {
                    const selectedMonth = selectedMonthRange.getMonth() + 1;
                    const selectedYearForMonth = selectedMonthRange.getFullYear();
                    const endDay = getLastDayOfMonth(selectedYearForMonth, selectedMonth);
                    return [new Date(`${selectedYearForMonth}-${selectedMonth}-01`), new Date(`${selectedYearForMonth}-${selectedMonth}-${endDay}`)];
                } else {
                    const currentMonth = new Date().getMonth() + 1;
                    const endDay = getLastDayOfMonth(year, currentMonth);
                    return [new Date(`${year}-${currentMonth}-01`), new Date(`${year}-${currentMonth}-${endDay}`)];
                }
            default:
                return [new Date(`${year}-01-01`), new Date(`${year}-12-31`)];
        }
    };

    const getComparisonStartAndEndDates = (comparisonId, year = new Date().getFullYear()) => {
        switch (comparisonId) {
            case reportingFilterComparisonConst.Last_Year:
                return [new Date(`${year - 1}-01-01`), new Date(`${year - 1}-12-31`)];

            case reportingFilterComparisonConst.Last_Quarter: {
                const quarter = Math.floor((new Date().getMonth() + 3) / 3);
                const lastQuarter = quarter === 1 ? 4 : quarter - 1;
                const startMonth = (lastQuarter - 1) * 3 + 1;
                const endMonth = startMonth + 2;
                const lastYear = quarter === 1 ? year - 1 : year;
                const endDay = getLastDayOfMonth(lastYear, endMonth);
                return [new Date(`${lastYear}-${startMonth}-01`), new Date(`${lastYear}-${endMonth}-${endDay}`)];
            }

            case reportingFilterComparisonConst.Second_Half:
                return [new Date(`${year}-07-01`), new Date(`${year}-12-31`)];

            case reportingFilterComparisonConst.Last_Month: {
                const lastMonth = new Date().getMonth();
                const lastYear = lastMonth === 0 ? year - 1 : year;
                const lastMonthFormatted = lastMonth === 0 ? 12 : lastMonth;
                const endDay = getLastDayOfMonth(lastYear, lastMonthFormatted);
                return [new Date(`${lastYear}-${lastMonthFormatted}-01`), new Date(`${lastYear}-${lastMonthFormatted}-${endDay}`)];
            }
            case reportingFilterComparisonConst.Year_Select:
                if (selectedComparisonYear) {
                    const comparisonYear = selectedComparisonYear.getFullYear();
                    return [new Date(`${comparisonYear}-01-01`), new Date(`${comparisonYear}-12-31`)];
                } else {
                    return [new Date(`${year - 1}-01-01`), new Date(`${year - 1}-12-31`)];
                }

            case reportingFilterComparisonConst.Month_Select:
                if (selectedComparisonMonth) {
                    const comparisonMonth = selectedComparisonMonth.getMonth() + 1;
                    const comparisonYearForMonth = selectedComparisonMonth.getFullYear();
                    const endDay = getLastDayOfMonth(comparisonYearForMonth, comparisonMonth);
                    return [new Date(`${comparisonYearForMonth}-${comparisonMonth}-01`), new Date(`${comparisonYearForMonth}-${comparisonMonth}-${endDay}`)];
                } else {
                    const lastMonth = new Date()?.getMonth() || 12;
                    const fallbackYear = lastMonth === 12 ? year - 1 : year;
                    const endDay = getLastDayOfMonth(fallbackYear, lastMonth);
                    return [new Date(`${fallbackYear}-${lastMonth}-01`), new Date(`${fallbackYear}-${lastMonth}-${endDay}`)];
                }
            default:
                return [new Date(`${year - 1}-01-01`), new Date(`${year - 1}-12-31`)];
        }
    };

    const [startCurrentRange, endCurrentRange] = getStartAndEndDates(selectedDateRange);
    const [startComparisonRange, endComparisonRange] = getComparisonStartAndEndDates(selectedComparison);

    const calculateTotalRevenue = (invoices, trackReport, projectCost, expenses, startRange, endRange, reportingRevenue, preferredCurrency, forex, typeOfContract) => {
        let totalRevenue = 0;

        invoices.forEach(invoice => {
            const invoiceDate = new Date(invoice?.cli_inv_end_date);
            const invoiceCurrency = invoice?.cli_inv_currency || 'USD';
            const clientId = invoice.cli_inv_for?._id;

            if (invoiceDate >= startRange && invoiceDate <= endRange && !invoice.is_delete &&
                clientId &&
                (reportingRevenue.revenueClient ? reportingRevenue.revenueClient.includes(clientId) : true) &&
                (reportingRevenue.revenueCurrency && reportingRevenue.revenueCurrency.includes(invoiceCurrency))) {

                const convertedAmount = convertCurrencyHelper(invoiceCurrency, preferredCurrency, invoice.cli_inv_total_amount, forex);
                totalRevenue += convertedAmount;
            }
        });

        trackReport.forEach(project => {
            const projectDate = new Date(project.startTime);
            const projectYear = projectDate.getFullYear();
            const preferredClientCurrency = project?.clientId?.preferred_currency || 'USD';

            if (projectDate >= startRange && projectDate <= endRange && projectYear === new Date(startRange).getFullYear() &&
                (project?.project && !project?.project?.is_delete) &&
                (reportingRevenue.revenueProject && reportingRevenue.revenueProject.includes(project?.project?._id)) &&
                (reportingRevenue.revenueCurrency && reportingRevenue.revenueCurrency.includes(preferredClientCurrency))) {

                const clientId = project?.clientId?._id;
                const projectSpecificCost = projectCost.find(cost => cost.userId === project.user?._id && cost.projectId === project?.project?._id && !cost.is_delete);
                // const userCost = projectSpecificCost && projectSpecificCost.amount > 0 ? projectSpecificCost.amount : (project.user.cost || 0);
                let userCost;
                if (projectSpecificCost && projectSpecificCost.amount > 0) {
                    userCost = convertCurrencyHelper(
                        preferredClientCurrency,
                        project.user.payment_currency,
                        projectSpecificCost.amount,
                        forex
                    );
                } else {
                    userCost = project?.user?.cost || 0;
                }
                const userTypeOfContract = project?.user?.typeOfContract || typeOfContract?.Hourly_Rate;
                const userOverheadCost = project?.user?.overhead_cost || 0;
                const paymentCurrency = project?.user?.payment_currency || 'USD';

                let finalCost = userTypeOfContract === typeOfContract.Hourly_Rate ? userCost : userCost / parseInt(project?.user?.monthly_hours ? project?.user?.monthly_hours : 168);
                const cost = (finalCost * (project.duration / 3600)) + (userOverheadCost * (project.duration / 3600));
                const costConverted = convertCurrencyHelper(paymentCurrency, preferredCurrency, cost, forex);
                totalRevenue -= costConverted;
            }
        });

        expenses.forEach(expense => {
            const expenseDate = new Date(expense?.exp_date);
            const expenseCurrency = expense?.exp_currency || 'USD';
            const clientId = expense?.exp_client?._id;

            if (expenseDate >= startRange && expenseDate <= endRange && !expense.is_delete &&
                clientId &&
                (reportingRevenue.revenueClient ? reportingRevenue.revenueClient.includes(clientId) : true)) {

                const convertedAmount = convertCurrencyHelper(expenseCurrency, preferredCurrency, expense.exp_amount, forex);
                totalRevenue -= convertedAmount;
            }
        });

        return totalRevenue.toFixed(2);
    };

    const currentTotalRevenue = calculateTotalRevenue(invoices, trackReport, projectCost, expenses, startCurrentRange, endCurrentRange, reportingRevenue, preferredCurrency, forex, typeOfContract);
    const comparisonTotalRevenue = calculateTotalRevenue(invoices, trackReport, projectCost, expenses, startComparisonRange, endComparisonRange, reportingRevenue, preferredCurrency, forex, typeOfContract);

    return [{
        name: 'Total Revenue',
        currentYear: currentTotalRevenue,
        lastYear: comparisonTotalRevenue,
    }];
}

export function clientRevenueOverviewPerClient(trackReport, projectCost, invoices, reportingRevenue, preferredCurrency, forex, typeOfContract, expenses) {
    const currentYear = reportingRevenue?.revenueYear || new Date().getFullYear();

    const calculateClientRevenue = (projects) => {
        const clientData = {};

        // Calculate total billed amount per client
        const totalBilledAmountPerClient = {};
        invoices && invoices.forEach(invoice => {
            const invoiceYear = new Date(invoice?.cli_inv_end_date).getFullYear();
            const preferredClientCurrency = invoice?.cli_inv_currency || 'USD';
            const clientId = invoice?.cli_inv_for?._id;

            if (invoiceYear === currentYear && !invoice.is_delete && clientId && (reportingRevenue.revenueClient ? reportingRevenue.revenueClient.includes(clientId) : true) && (reportingRevenue.revenueCurrency && reportingRevenue.revenueCurrency.includes(preferredClientCurrency))) {
                const invoiceCurrency = invoice?.cli_inv_currency || 'USD';
                const convertedAmount = convertCurrencyHelper(invoiceCurrency, preferredCurrency, invoice.cli_inv_total_amount, forex);

                if (!totalBilledAmountPerClient[clientId]) {
                    totalBilledAmountPerClient[clientId] = 0;
                }
                totalBilledAmountPerClient[clientId] += convertedAmount;
            }
        });

        // Process each project
        projects.forEach(project => {
            const projectYear = new Date(project.startTime).getFullYear();
            const preferredClientCurrency = project?.clientId?.preferred_currency || 'USD';

            if (projectYear === currentYear && (project?.project && !project?.project?.is_delete) && (reportingRevenue.revenueProject && reportingRevenue.revenueProject.includes(project?.project?._id)) && (reportingRevenue.revenueCurrency && reportingRevenue.revenueCurrency.includes(preferredClientCurrency))) {
                const clientId = project?.clientId?._id;
                // const userCost = project.user.cost || 0;
                const projectSpecificCost = projectCost.find(cost => cost.userId === project.user?._id && cost.projectId === project?.project?._id && !cost.is_delete);
                // const userCost = projectSpecificCost && projectSpecificCost.amount > 0 ? projectSpecificCost.amount : (project.user.cost || 0);
                let userCost;
                if (projectSpecificCost && projectSpecificCost.amount > 0) {
                    userCost = convertCurrencyHelper(
                        preferredClientCurrency,
                        project.user.payment_currency || 'USD',
                        projectSpecificCost.amount,
                        forex
                    );
                } else {
                    userCost = project?.user?.cost || 0;
                }
                const userTypeOfContract = project?.user?.typeOfContract || typeOfContract?.Hourly_Rate;
                const userOverheadCost = project?.user?.overhead_cost || 0;
                const paymentCurrency = project?.user?.payment_currency || 'USD';

                let finalCost;
                if (userTypeOfContract === typeOfContract.Hourly_Rate) {
                    finalCost = userCost;
                } else {
                    finalCost = userCost / parseInt(project?.user?.monthly_hours ? project?.user?.monthly_hours : 168); // Assuming a different type of contract with monthly rate divided by 168 hours
                }
                const cost = (finalCost * (project.duration / 3600)) + (userOverheadCost * (project.duration / 3600));
                const costConverted = convertCurrencyHelper(paymentCurrency, preferredCurrency, cost, forex);

                if (!clientData[clientId]) {
                    clientData[clientId] = {
                        name: project.clientId?.client_name,
                        cost: 0,
                        totalBilled: totalBilledAmountPerClient[clientId] || 0,
                        totalExpense: 0
                    };
                }

                clientData[clientId].cost += costConverted;
            }
        });

        // Calculate total expenses per client
        expenses.forEach(expense => {
            const clientId = expense?.exp_client?._id;
            const expenseYear = new Date(expense.exp_date).getFullYear();

            if (expenseYear === currentYear && !expense.is_delete && clientId && (reportingRevenue.revenueClient ? reportingRevenue.revenueClient.includes(clientId) : true)) {
                const convertedAmount = convertCurrencyHelper(expense.exp_currency, preferredCurrency, expense.exp_amount, forex);

                if (!clientData[clientId]) {
                    clientData[clientId] = {
                        name: expense.exp_client.client_name,
                        cost: 0,
                        totalBilled: totalBilledAmountPerClient[clientId] || 0,
                        totalExpense: 0
                    };
                }

                clientData[clientId].totalExpense += convertedAmount;
            }
        });

        // Calculate Revenue for each client
        return Object.values(clientData).map(client => {
            const totalCostAndExpense = client.cost + client.totalExpense;
            const revenue = (client.totalBilled - totalCostAndExpense).toFixed(2);

            return {
                name: client.name,
                revenue: parseFloat(revenue) // Ensuring the Revenue is a number
            };
        });
    };

    return calculateClientRevenue(trackReport);
};

export function projectRevenueOverviewPerProject(trackReport, projectCost, sprints, reportingRevenue, preferredCurrency, forex, typeOfContract) {
    const currentYear = reportingRevenue?.revenueYear || new Date().getFullYear();

    const sprintsByProject = sprints.reduce((acc, sprint) => {
        const projectId = sprint.project?._id;
        if (projectId) {
            if (!acc[projectId]) {
                acc[projectId] = [];
            }
            acc[projectId].push(sprint);
        }
        return acc;
    }, {});

    const calculateProjectRevenue = (projects) => {
        const projectData = {};

        projects.forEach(project => {
            const projectYear = new Date(project.startTime).getFullYear();
            const preferredClientCurrency = project?.clientId?.preferred_currency || 'USD';
            const projectId = project?.project?._id;

            if (projectYear === currentYear && !project?.project?.is_delete && (reportingRevenue.revenueProject && reportingRevenue.revenueProject.includes(projectId)) && (reportingRevenue.revenueCurrency && reportingRevenue.revenueCurrency.includes(preferredClientCurrency))) {
                // const budget = convertCurrencyHelper(preferredClientCurrency, preferredCurrency, project.project.budget, forex);
                if (!projectData[projectId]) {
                    const totalSprintBudget = (sprintsByProject[projectId] || []).reduce((sum, sprint) => {
                        return sum + convertCurrencyHelper(preferredClientCurrency, preferredCurrency, sprint.budget, forex);
                    }, 0);

                    projectData[projectId] = {
                        name: project?.project.project_name,
                        budget: totalSprintBudget,
                        cost: 0
                    };
                }
                // const userCost = project.user.cost || 0;
                const projectSpecificCost = projectCost.find(cost => cost.userId === project.user?._id && cost.projectId === projectId && !cost.is_delete);
                // const userCost = projectSpecificCost && projectSpecificCost.amount > 0 ? projectSpecificCost.amount : (project.user.cost || 0);
                let userCost;
                if (projectSpecificCost && projectSpecificCost.amount > 0) {
                    userCost = convertCurrencyHelper(
                        preferredClientCurrency,
                        project?.user?.payment_currency || 'USD',
                        projectSpecificCost.amount,
                        forex
                    );
                } else {
                    userCost = project?.user?.cost || 0;
                }
                const userTypeOfContract = project?.user?.typeOfContract || typeOfContract?.Hourly_Rate;
                const userOverheadCost = project?.user?.overhead_cost || 0;
                const paymentCurrency = project?.user?.payment_currency || 'USD';
                let finalCost;
                if (userTypeOfContract === typeOfContract.Hourly_Rate) {
                    finalCost = userCost;
                } else {
                    finalCost = userCost / parseInt(project?.user?.monthly_hours ? project?.user?.monthly_hours : 168); // Assuming a different type of contract with monthly rate divided by 168 hours
                }
                const cost = (finalCost * (project.duration / 3600)) + (userOverheadCost * (project.duration / 3600));
                // const cost = (userCost + userOverheadCost) * (project.duration / 3600);
                const costConverted = convertCurrencyHelper(paymentCurrency, preferredCurrency, cost, forex);

                // projectData[projectId].budget += budget;
                projectData[projectId].cost += costConverted;
            }
        });

        return Object.values(projectData).map(project => ({
            name: project.name,
            revenue: (project.budget - project.cost).toFixed(2)
        }));
    };

    return calculateProjectRevenue(trackReport);
};

export function processedRevenueByService(trackReport, projectCost, sprints, reportingRevenue, preferredCurrency, forex, typeOfContract) {
    const currentYear = reportingRevenue?.revenueYear || new Date().getFullYear();

    const sprintsByProject = sprints.reduce((acc, sprint) => {
        const projectId = sprint.project?._id;
        if (projectId) {
            if (!acc[projectId]) {
                acc[projectId] = [];
            }
            acc[projectId].push(sprint);
        }
        return acc;
    }, {});

    const calculateClientRevenueByService = () => {
        const revenueByService = {};
        const processedProjects = new Set();

        trackReport.forEach(project => {
            const projectYear = new Date(project.startTime).getFullYear();
            const preferredClientCurrency = project?.clientId?.preferred_currency || 'USD';
            const clientId = project?.clientId?._id;
            const projectId = project?.project?._id;
            const serviceName = project?.tag?.tag_name;

            if (projectYear === currentYear
                && project?.project && !project?.project?.is_delete && serviceName
                && reportingRevenue.revenueProject && reportingRevenue.revenueProject.includes(projectId)
                && reportingRevenue.revenueClient && reportingRevenue.revenueClient.includes(clientId)
                && reportingRevenue.revenueCurrency && reportingRevenue.revenueCurrency.includes(preferredClientCurrency)
            ) {
                if (!revenueByService[serviceName]) {
                    revenueByService[serviceName] = {
                        name: serviceName,
                        budget: 0,
                        cost: 0
                    };
                }

                if (!processedProjects.has(projectId)) {
                    const totalSprintBudget = (sprintsByProject[projectId] || []).reduce((sum, sprint) => {
                        return sum + convertCurrencyHelper(preferredClientCurrency, preferredCurrency, sprint.budget, forex);
                    }, 0);
                    revenueByService[serviceName].budget += totalSprintBudget;
                    processedProjects.add(projectId);
                }

                const projectSpecificCost = projectCost.find(cost => cost.userId === project.user?._id && cost.projectId === projectId && !cost.is_delete);
                // const userCost = projectSpecificCost && projectSpecificCost.amount > 0 ? projectSpecificCost.amount : (project.user.cost || 0);
                let userCost;
                if (projectSpecificCost && projectSpecificCost.amount > 0) {
                    userCost = convertCurrencyHelper(
                        preferredClientCurrency,
                        project?.user?.payment_currency || 'USD',
                        projectSpecificCost.amount,
                        forex
                    );
                } else {
                    userCost = project?.user?.cost || 0;
                }
                const userTypeOfContract = project?.user?.typeOfContract || typeOfContract?.Hourly_Rate;
                const userOverheadCost = project?.user?.overhead_cost || 0;
                const paymentCurrency = project?.user?.payment_currency || 'USD';

                let finalCost;
                if (userTypeOfContract === typeOfContract.Hourly_Rate) {
                    finalCost = userCost;
                } else {
                    finalCost = userCost / parseInt(project?.user?.monthly_hours ? project?.user?.monthly_hours : 168);
                }
                const cost = (finalCost * (project.duration / 3600)) + (userOverheadCost * (project.duration / 3600));
                const costConverted = convertCurrencyHelper(paymentCurrency, preferredCurrency, cost, forex);

                revenueByService[serviceName].cost += costConverted;
            }
        });

        return Object.values(revenueByService).map(service => ({
            name: service.name,
            revenue: (service.budget - service.cost).toFixed(2)
        }));
    };

    return calculateClientRevenueByService();
}

export function processedRevenueByServiceFilter(
    trackReport,
    projectCost,
    sprints,
    reportingRevenue,
    preferredCurrency,
    forex,
    typeOfContract,
    selectedDateRange,
    selectedComparison,
    selectedYearRange,
    selectedMonthRange,
    selectedComparisonYear,
    selectedComparisonMonth,
) {

    if (!trackReport || !reportingRevenue || !reportingRevenue?.revenueProject || !reportingRevenue?.revenueClient || !reportingRevenue?.revenueCurrency) {
        return [];
    }

    const getLastDayOfMonth = (year, month) => {
        return new Date(year, month, 0).getDate();
    };

    const getStartAndEndDates = (rangeId, year = new Date().getFullYear()) => {
        switch (rangeId) {
            case reportingFilterDateRangesConst.This_Year:
                return [
                    new Date(`${year}-01-01`),
                    new Date(`${year}-12-31`)
                ];

            case reportingFilterDateRangesConst.This_Quarter: {
                const quarter = Math.floor((new Date().getMonth() + 3) / 3);
                const startMonth = (quarter - 1) * 3 + 1;
                const endMonth = startMonth + 2;
                const endDay = getLastDayOfMonth(year, endMonth);
                return [
                    new Date(`${year}-${startMonth}-01`),
                    new Date(`${year}-${endMonth}-${endDay}`)
                ];
            }

            case reportingFilterDateRangesConst.First_Half:
                return [
                    new Date(`${year}-01-01`),
                    new Date(`${year}-06-30`)
                ];

            case reportingFilterDateRangesConst.This_Month: {
                const month = new Date().getMonth() + 1;
                const endDay = getLastDayOfMonth(year, month);
                return [
                    new Date(`${year}-${month}-01`),
                    new Date(`${year}-${month}-${endDay}`)
                ];
            }

            case reportingFilterDateRangesConst.Year_Selected:
                if (selectedYearRange) {
                    const selectedYear = selectedYearRange.getFullYear();
                    return [
                        new Date(`${selectedYear}-01-01`),
                        new Date(`${selectedYear}-12-31`)
                    ];
                } else {
                    return [
                        new Date(`${year}-01-01`),
                        new Date(`${year}-12-31`)
                    ];
                }

            case reportingFilterDateRangesConst.Month_Selected:
                if (selectedMonthRange) {
                    const selectedMonth = selectedMonthRange.getMonth() + 1;
                    const selectedYearForMonth = selectedMonthRange.getFullYear();
                    const endDay = getLastDayOfMonth(selectedYearForMonth, selectedMonth);
                    return [
                        new Date(`${selectedYearForMonth}-${selectedMonth}-01`),
                        new Date(`${selectedYearForMonth}-${selectedMonth}-${endDay}`)
                    ];
                } else {
                    // Fallback to the current month if selectedMonthRange is not provided
                    const currentMonth = new Date().getMonth() + 1;
                    const endDay = getLastDayOfMonth(year, currentMonth);
                    return [
                        new Date(`${year}-${currentMonth}-01`),
                        new Date(`${year}-${currentMonth}-${endDay}`)
                    ];
                }

            default:
                return [
                    new Date(`${year}-01-01`),
                    new Date(`${year}-12-31`)
                ];
        }
    };

    const getComparisonStartAndEndDates = (comparisonId, year = new Date().getFullYear()) => {
        switch (comparisonId) {
            case reportingFilterComparisonConst.Last_Year:
                return [
                    new Date(`${year - 1}-01-01`),
                    new Date(`${year - 1}-12-31`)
                ];

            case reportingFilterComparisonConst.Last_Quarter: {
                const quarter = Math.floor((new Date().getMonth() + 3) / 3);
                const lastQuarter = quarter === 1 ? 4 : quarter - 1;
                const startMonth = (lastQuarter - 1) * 3 + 1;
                const endMonth = startMonth + 2;
                const lastYear = quarter === 1 ? year - 1 : year;
                const endDay = getLastDayOfMonth(lastYear, endMonth);
                return [
                    new Date(`${lastYear}-${startMonth}-01`),
                    new Date(`${lastYear}-${endMonth}-${endDay}`)
                ];
            }

            case reportingFilterComparisonConst.Second_Half:
                return [
                    new Date(`${year}-07-01`),
                    new Date(`${year}-12-31`)
                ];

            case reportingFilterComparisonConst.Last_Month: {
                const lastMonth = new Date().getMonth(); // 0-indexed for previous month
                const lastYear = lastMonth === 0 ? year - 1 : year;
                const lastMonthFormatted = lastMonth === 0 ? 12 : lastMonth;
                const endDay = getLastDayOfMonth(lastYear, lastMonthFormatted);
                return [
                    new Date(`${lastYear}-${lastMonthFormatted}-01`),
                    new Date(`${lastYear}-${lastMonthFormatted}-${endDay}`)
                ];
            }

            case reportingFilterComparisonConst.Year_Select:
                if (selectedComparisonYear) {
                    const comparisonYear = selectedComparisonYear.getFullYear();
                    return [
                        new Date(`${comparisonYear}-01-01`),
                        new Date(`${comparisonYear}-12-31`)
                    ];
                } else {
                    return [
                        new Date(`${year - 1}-01-01`),
                        new Date(`${year - 1}-12-31`)
                    ];
                }

            case reportingFilterComparisonConst.Month_Select:
                if (selectedComparisonMonth) {
                    const comparisonMonth = selectedComparisonMonth.getMonth() + 1;
                    const comparisonYearForMonth = selectedComparisonMonth.getFullYear();
                    const endDay = getLastDayOfMonth(comparisonYearForMonth, comparisonMonth);
                    return [
                        new Date(`${comparisonYearForMonth}-${comparisonMonth}-01`),
                        new Date(`${comparisonYearForMonth}-${comparisonMonth}-${endDay}`)
                    ];
                } else {
                    const lastMonth = new Date()?.getMonth() || 12;
                    const fallbackYear = lastMonth === 12 ? year - 1 : year;
                    const endDay = getLastDayOfMonth(fallbackYear, lastMonth);
                    return [
                        new Date(`${fallbackYear}-${lastMonth}-01`),
                        new Date(`${fallbackYear}-${lastMonth}-${endDay}`)
                    ];
                }

            default:
                return [
                    new Date(`${year - 1}-01-01`),
                    new Date(`${year - 1}-12-31`)
                ];
        }
    };

    const [startCurrentRange, endCurrentRange] = getStartAndEndDates(selectedDateRange);
    const [startComparisonRange, endComparisonRange] = getComparisonStartAndEndDates(selectedComparison);

    const filterRevenue = (reports, startDate, endDate) => {
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(23, 59, 59, 999);
        return reports.filter(project =>
            new Date(project.startTime) >= startDate &&
            new Date(project.startTime) <= endDate &&
            project?.project && !project?.project?.is_delete &&
            project?.tag?.tag_name &&
            reportingRevenue.revenueProject.includes(project.project?._id) &&
            reportingRevenue.revenueClient.includes(project.clientId?._id) &&
            reportingRevenue.revenueCurrency.includes(project?.clientId?.preferred_currency || 'USD')
        );
    };

    const sprintsByProject = sprints.reduce((acc, sprint) => {
        const projectId = sprint.project?._id;
        if (projectId) {
            if (!acc[projectId]) {
                acc[projectId] = [];
            }
            acc[projectId].push(sprint);
        }
        return acc;
    }, {});

    const calculateRevenueByService = (filteredReports) => {
        const revenueByService = {};
        const processedProjects = new Set();

        filteredReports.forEach(project => {
            const serviceName = project?.tag?.tag_name;
            const projectId = project?.project?._id;
            const preferredClientCurrency = project?.clientId?.preferred_currency || 'USD';

            if (serviceName && projectId) {
                if (!revenueByService[serviceName]) {
                    revenueByService[serviceName] = {
                        name: serviceName,
                        budget: 0,
                        cost: 0
                    };
                }

                if (!processedProjects.has(projectId)) {
                    // Calculate Budget
                    const totalSprintBudget = (sprintsByProject[projectId] || []).reduce((sum, sprint) => {
                        return sum + convertCurrencyHelper(preferredClientCurrency, preferredCurrency, sprint.budget, forex);
                    }, 0);
                    revenueByService[serviceName].budget += totalSprintBudget;
                    processedProjects.add(projectId);
                }

                // Calculate Cost
                const projectSpecificCost = projectCost.find(cost => cost.userId === project.user?._id && cost.projectId === projectId && !cost.is_delete);
                // const userCost = projectSpecificCost && projectSpecificCost.amount > 0 ? projectSpecificCost.amount : (project.user.cost || 0);
                let userCost;
                if (projectSpecificCost && projectSpecificCost.amount > 0) {
                    userCost = convertCurrencyHelper(
                        preferredClientCurrency,
                        project.user.payment_currency || 'USD',
                        projectSpecificCost.amount,
                        forex
                    );
                } else {
                    userCost = project?.user?.cost || 0;
                }
                const userTypeOfContract = project?.user?.typeOfContract || typeOfContract?.Hourly_Rate;
                const userOverheadCost = project?.user?.overhead_cost || 0;
                const paymentCurrency = project?.user?.payment_currency || 'USD';

                let finalCost;
                if (userTypeOfContract === typeOfContract.Hourly_Rate) {
                    finalCost = userCost;
                } else {
                    finalCost = userCost / parseInt(project?.user?.monthly_hours ? project?.user?.monthly_hours : 168);
                }
                const costWithOverhead = (finalCost * (project.duration / 3600)) + (userOverheadCost * (project.duration / 3600));
                revenueByService[serviceName].cost += convertCurrencyHelper(paymentCurrency, preferredCurrency, costWithOverhead, forex);
            }
        });

        return Object.values(revenueByService).map(service => ({
            name: service.name,
            revenue: parseFloat((service.budget - service.cost).toFixed(2)) || 0 // Ensure revenue is a number
        }));
    };

    const filteredCurrentPeriod = filterRevenue(trackReport, startCurrentRange, endCurrentRange);
    const filteredComparisonPeriod = filterRevenue(trackReport, startComparisonRange, endComparisonRange);

    const currentPeriodRevenue = calculateRevenueByService(filteredCurrentPeriod);
    const comparisonPeriodRevenue = calculateRevenueByService(filteredComparisonPeriod);

    const combinedRevenue = currentPeriodRevenue.map(current => {
        const lastYearData = comparisonPeriodRevenue.find(last => last.name === current.name) || { revenue: 0 };
        return {
            name: current.name,
            currentYear: parseFloat(current.revenue) || 0,
            lastYear: parseFloat(lastYearData.revenue) || 0,
        };
    });

    return combinedRevenue;
};

export function clientTotalRevenueComparison(trackReport, projectCost, invoices, reportingRevenue, preferredCurrency, forex, typeOfContract, expenses) {
    const currentYear = reportingRevenue?.revenueYear || new Date().getFullYear();
    const previousYear = currentYear - 1;

    const initializeMonthlyData = (year) => {
        return Array.from({ length: 12 }, (_, i) => ({
            name: new Date(year, i).toLocaleString('default', { month: 'long' }),
            budget: 0,
            cost: 0,
            totalBilled: 0,
            totalExpense: 0
        }));
    };

    const calculateClientRevenue = (projects, year) => {
        const monthlyData = initializeMonthlyData(year);

        const totalBilledAmountPerClient = {};
        invoices.forEach(invoice => {
            const invoiceYear = new Date(invoice?.cli_inv_end_date).getFullYear();
            const preferredClientCurrency = invoice?.cli_inv_currency || 'USD';
            const clientId = invoice.cli_inv_for?._id;

            if (invoiceYear === year && !invoice.is_delete && clientId && (reportingRevenue.revenueClient ? reportingRevenue.revenueClient.includes(clientId) : true) && (reportingRevenue.revenueCurrency && reportingRevenue.revenueCurrency.includes(preferredClientCurrency))) {
                const invoiceCurrency = invoice?.cli_inv_currency || 'USD';
                const convertedAmount = convertCurrencyHelper(invoiceCurrency, preferredCurrency, invoice.cli_inv_total_amount, forex);

                if (!totalBilledAmountPerClient[clientId]) {
                    totalBilledAmountPerClient[clientId] = 0;
                }
                totalBilledAmountPerClient[clientId] += convertedAmount;

                const month = new Date(invoice.cli_inv_end_date).getMonth();
                monthlyData[month].totalBilled += convertedAmount;
            }
        });

        const clientData = {};

        projects.forEach(project => {
            const projectYear = new Date(project.startTime).getFullYear();
            const preferredClientCurrency = project?.clientId?.preferred_currency || 'USD';

            if (projectYear === year && (project?.project && !project?.project?.is_delete) && (reportingRevenue.revenueProject && reportingRevenue.revenueProject.includes(project?.project?._id)) && (reportingRevenue.revenueCurrency && reportingRevenue.revenueCurrency.includes(preferredClientCurrency))) {
                const clientId = project?.clientId?._id;
                // const userCost = project.user.cost || 0;
                const projectSpecificCost = projectCost.find(cost => cost.userId === project.user?._id && cost.projectId === project?.project?._id && !cost.is_delete);
                // const userCost = projectSpecificCost && projectSpecificCost.amount > 0 ? projectSpecificCost.amount : (project.user.cost || 0);
                let userCost;
                if (projectSpecificCost && projectSpecificCost.amount > 0) {
                    userCost = convertCurrencyHelper(
                        preferredClientCurrency,
                        project?.user?.payment_currency || 'USD',
                        projectSpecificCost.amount,
                        forex
                    );
                } else {
                    userCost = project?.user?.cost || 0;
                }
                const userTypeOfContract = project?.user?.typeOfContract || typeOfContract?.Hourly_Rate;
                const userOverheadCost = project?.user?.overhead_cost || 0;
                const paymentCurrency = project?.user?.payment_currency || 'USD';

                let finalCost;
                if (userTypeOfContract === typeOfContract.Hourly_Rate) {
                    finalCost = userCost;
                } else {
                    finalCost = userCost / parseInt(project?.user?.monthly_hours ? project?.user?.monthly_hours : 168); // Assuming a different type of contract with monthly rate divided by 168 hours
                }
                const cost = (finalCost * (project.duration / 3600)) + (userOverheadCost * (project.duration / 3600));
                const costConverted = convertCurrencyHelper(paymentCurrency, preferredCurrency, cost, forex);

                if (!clientData[clientId]) {
                    clientData[clientId] = {
                        name: project.clientId?.client_name,
                        cost: 0,
                        totalBilled: totalBilledAmountPerClient[clientId] || 0,
                        totalExpense: 0
                    };
                }

                clientData[clientId].cost += costConverted;

                const month = new Date(project.startTime).getMonth();
                monthlyData[month].cost += costConverted;
            }
        });

        expenses.forEach(expense => {
            const clientId = expense?.exp_client?._id;
            const expenseYear = new Date(expense.exp_date).getFullYear();

            if (expenseYear === year && !expense.is_delete && clientId && (reportingRevenue.revenueClient ? reportingRevenue.revenueClient.includes(clientId) : true)) {
                const convertedAmount = convertCurrencyHelper(expense.exp_currency, preferredCurrency, expense.exp_amount, forex);

                if (!clientData[clientId]) {
                    clientData[clientId] = {
                        name: expense.exp_client.client_name,
                        cost: 0,
                        totalBilled: totalBilledAmountPerClient[clientId] || 0,
                        totalExpense: 0
                    };
                }

                clientData[clientId].totalExpense += convertedAmount;

                const month = new Date(expense.exp_date).getMonth();
                monthlyData[month].totalExpense += convertedAmount;
            }
        });

        return monthlyData.map(month => ({
            name: month.name,
            revenue: (month.totalBilled - (month.cost + month.totalExpense)).toFixed(2)
        }));
    };

    const currentYearData = calculateClientRevenue(trackReport, currentYear);
    const previousYearData = calculateClientRevenue(trackReport, previousYear);

    return currentYearData.map((data, index) => ({
        name: data.name,
        currentYear: parseFloat(data.revenue),
        lastYear: parseFloat(previousYearData[index].revenue)
    }));
}

// export function clientTotalRevenueComparisonFilter(
//     trackReport,
//     projectCost,
//     invoices,
//     reportingRevenue,
//     preferredCurrency,
//     forex,
//     typeOfContract,
//     expenses,
//     selectedDateRange,
//     selectedComparison,
//     selectedFrequency,
//     selectedYearRange,
//     selectedMonthRange,
//     selectedComparisonYear,
//     selectedComparisonMonth,
// ) {
//     const getLastDayOfMonth = (year, month) => {
//         return new Date(year, month, 0).getDate();
//     };

//     const getStartAndEndDates = (rangeId, year = new Date().getFullYear()) => {
//         switch (rangeId) {
//             case reportingFilterDateRangesConst.This_Year:
//                 return [new Date(`${year}-01-01`), new Date(`${year}-12-31`)];

//             case reportingFilterDateRangesConst.This_Quarter: {
//                 const quarter = Math.floor((new Date().getMonth() + 3) / 3);
//                 const startMonth = (quarter - 1) * 3 + 1;
//                 const endMonth = startMonth + 2;
//                 const endDay = getLastDayOfMonth(year, endMonth);
//                 return [new Date(`${year}-${startMonth}-01`), new Date(`${year}-${endMonth}-${endDay}`)];
//             }

//             case reportingFilterDateRangesConst.First_Half:
//                 return [new Date(`${year}-01-01`), new Date(`${year}-06-30`)];

//             case reportingFilterDateRangesConst.This_Month: {
//                 const month = new Date().getMonth() + 1;
//                 const endDay = getLastDayOfMonth(year, month);
//                 return [new Date(`${year}-${month}-01`), new Date(`${year}-${month}-${endDay}`)];
//             }

//             case reportingFilterDateRangesConst.Year_Selected:
//                 if (selectedYearRange) {
//                     const selectedYear = selectedYearRange.getFullYear();
//                     return [new Date(`${selectedYear}-01-01`), new Date(`${selectedYear}-12-31`)];
//                 } else {
//                     return [new Date(`${year}-01-01`), new Date(`${year}-12-31`)];
//                 }
//             case reportingFilterDateRangesConst.Month_Selected:
//                 if (selectedMonthRange) {
//                     const selectedMonth = selectedMonthRange.getMonth() + 1;
//                     const selectedYearForMonth = selectedMonthRange.getFullYear();
//                     const endDay = getLastDayOfMonth(selectedYearForMonth, selectedMonth);
//                     return [new Date(`${selectedYearForMonth}-${selectedMonth}-01`), new Date(`${selectedYearForMonth}-${selectedMonth}-${endDay}`)];
//                 } else {
//                     const currentMonth = new Date().getMonth() + 1;
//                     const endDay = getLastDayOfMonth(year, currentMonth);
//                     return [new Date(`${year}-${currentMonth}-01`), new Date(`${year}-${currentMonth}-${endDay}`)];
//                 }
//             default:
//                 return [new Date(`${year}-01-01`), new Date(`${year}-12-31`)];
//         }
//     };

//     const getComparisonStartAndEndDates = (comparisonId, year = new Date().getFullYear()) => {
//         switch (comparisonId) {
//             case reportingFilterComparisonConst.Last_Year:
//                 return [new Date(`${year - 1}-01-01`), new Date(`${year - 1}-12-31`)];

//             case reportingFilterComparisonConst.Last_Quarter: {
//                 const quarter = Math.floor((new Date().getMonth() + 3) / 3);
//                 const lastQuarter = quarter === 1 ? 4 : quarter - 1;
//                 const startMonth = (lastQuarter - 1) * 3 + 1;
//                 const endMonth = startMonth + 2;
//                 const lastYear = quarter === 1 ? year - 1 : year;
//                 const endDay = getLastDayOfMonth(lastYear, endMonth);
//                 return [new Date(`${lastYear}-${startMonth}-01`), new Date(`${lastYear}-${endMonth}-${endDay}`)];
//             }

//             case reportingFilterComparisonConst.Second_Half:
//                 return [new Date(`${year}-07-01`), new Date(`${year}-12-31`)];

//             case reportingFilterComparisonConst.Last_Month: {
//                 const lastMonth = new Date().getMonth();
//                 const lastYear = lastMonth === 0 ? year - 1 : year;
//                 const lastMonthFormatted = lastMonth === 0 ? 12 : lastMonth;
//                 const endDay = getLastDayOfMonth(lastYear, lastMonthFormatted);
//                 return [new Date(`${lastYear}-${lastMonthFormatted}-01`), new Date(`${lastYear}-${lastMonthFormatted}-${endDay}`)];
//             }
//             case reportingFilterComparisonConst.Year_Select:
//                 if (selectedComparisonYear) {
//                     const comparisonYear = selectedComparisonYear.getFullYear();
//                     return [new Date(`${comparisonYear}-01-01`), new Date(`${comparisonYear}-12-31`)];
//                 } else {
//                     return [new Date(`${year - 1}-01-01`), new Date(`${year - 1}-12-31`)];
//                 }

//             case reportingFilterComparisonConst.Month_Select:
//                 if (selectedComparisonMonth) {
//                     const comparisonMonth = selectedComparisonMonth.getMonth() + 1;
//                     const comparisonYearForMonth = selectedComparisonMonth.getFullYear();
//                     const endDay = getLastDayOfMonth(comparisonYearForMonth, comparisonMonth);
//                     return [new Date(`${comparisonYearForMonth}-${comparisonMonth}-01`), new Date(`${comparisonYearForMonth}-${comparisonMonth}-${endDay}`)];
//                 } else {
//                     const lastMonth = new Date().getMonth() || 12;
//                     const fallbackYear = lastMonth === 12 ? year - 1 : year;
//                     const endDay = getLastDayOfMonth(fallbackYear, lastMonth);
//                     return [new Date(`${fallbackYear}-${lastMonth}-01`), new Date(`${fallbackYear}-${lastMonth}-${endDay}`)];
//                 }
//             default:
//                 return [new Date(`${year - 1}-01-01`), new Date(`${year - 1}-12-31`)];
//         }
//     };

//     const [startCurrentRange, endCurrentRange] = getStartAndEndDates(selectedDateRange);
//     const [startComparisonRange, endComparisonRange] = getComparisonStartAndEndDates(selectedComparison);

//     const calculateRevenueData = (invoices, trackReport, projectCost, expenses, startRange, endRange, frequency, reportingRevenue, preferredCurrency, forex, typeOfContract) => {
//         const data = {};
//         const processInvoices = () => {
//             invoices.forEach(invoice => {
//                 const invoiceDate = new Date(invoice?.cli_inv_end_date);
//                 const invoiceCurrency = invoice?.cli_inv_currency || 'USD';
//                 const clientId = invoice.cli_inv_for._id;

//                 if (invoiceDate >= startRange && invoiceDate <= endRange && !invoice.is_delete &&
//                     clientId &&
//                     (reportingRevenue.revenueClient ? reportingRevenue.revenueClient.includes(clientId) : true) &&
//                     (reportingRevenue.revenueCurrency && reportingRevenue.revenueCurrency.includes(invoiceCurrency))) {

//                     const convertedAmount = convertCurrencyHelper(invoiceCurrency, preferredCurrency, invoice.cli_inv_total_amount, forex);
//                     const key = frequency === 'Monthly' ? invoiceDate.toLocaleString('default', { month: 'long' }) :
//                         frequency === 'Daily' ? invoiceDate.toLocaleDateString() : '';

//                     if (!data[key]) {
//                         data[key] = { name: key, totalBilled: 0, cost: 0, totalExpense: 0 };
//                     }
//                     data[key].totalBilled += convertedAmount;
//                 }
//             });
//         };

//         const processProjects = () => {
//             trackReport.forEach(project => {
//                 const projectDate = new Date(project.startTime);
//                 const projectYear = projectDate.getFullYear();
//                 const preferredClientCurrency = project?.clientId?.preferred_currency || 'USD';

//                 if (projectDate >= startRange && projectDate <= endRange && projectYear === new Date(startRange).getFullYear() &&
//                     (project?.project && !project?.project?.is_delete) &&
//                     (reportingRevenue.revenueProject && reportingRevenue.revenueProject.includes(project?.project?._id)) &&
//                     (reportingRevenue.revenueCurrency && reportingRevenue.revenueCurrency.includes(preferredClientCurrency))) {

//                     const clientId = project?.clientId?._id;
//                     const projectSpecificCost = projectCost.find(cost => cost.userId === project.user?._id && cost.projectId === project?.project?._id && !cost.is_delete);
//                     const userCost = projectSpecificCost && projectSpecificCost.amount > 0 ? projectSpecificCost.amount : (project.user.cost || 0);
//                     let userCost;
// if (projectSpecificCost && projectSpecificCost.amount > 0) {
//     userCost = convertCurrencyHelper(
//         preferredClientCurrency,
//         project.user.payment_currency || 'USD',
//         projectSpecificCost.amount,
//         forex
//     );
// } else {
//     userCost = project.user.cost || 0;
// }
//                     const userTypeOfContract = project.user.typeOfContract || typeOfContract.Hourly_Rate;
//                     const userOverheadCost = project.user.overhead_cost || 0;
//                     const paymentCurrency = project.user.payment_currency || 'USD';

//                     let finalCost;
//                     if (userTypeOfContract === typeOfContract.Hourly_Rate) {
//                         finalCost = userCost;
//                     } else {
//                         finalCost = userCost / parseInt(project?.user?.monthly_hours ? project?.user?.monthly_hours : 168);
//                     }
//                     const cost = (finalCost * (project.duration / 3600)) + (userOverheadCost * (project.duration / 3600));
//                     const costConverted = convertCurrencyHelper(paymentCurrency, preferredCurrency, cost, forex);

//                     const key = frequency === 'Monthly' ? projectDate.toLocaleString('default', { month: 'long' }) :
//                         frequency === 'Daily' ? projectDate.toLocaleDateString() : '';

//                     if (!data[key]) {
//                         data[key] = { name: key, totalBilled: 0, cost: 0, totalExpense: 0 };
//                     }
//                     data[key].cost += costConverted;
//                 }
//             });
//         };

//         const processExpenses = () => {
//             expenses.forEach(expense => {
//                 const expenseDate = new Date(expense?.exp_date);
//                 const expenseCurrency = expense?.exp_currency || 'USD';
//                 const clientId = expense?.exp_client?._id;

//                 if (expenseDate >= startRange && expenseDate <= endRange && !expense.is_delete &&
//                     clientId &&
//                     (reportingRevenue.revenueClient ? reportingRevenue.revenueClient.includes(clientId) : true)) {

//                     const convertedAmount = convertCurrencyHelper(expenseCurrency, preferredCurrency, expense.exp_amount, forex);
//                     const key = frequency === 'Monthly' ? expenseDate.toLocaleString('default', { month: 'long' }) :
//                         frequency === 'Daily' ? expenseDate.toLocaleDateString() : '';

//                     if (!data[key]) {
//                         data[key] = { name: key, totalBilled: 0, cost: 0, totalExpense: 0 };
//                     }
//                     data[key].totalExpense += convertedAmount;
//                 }
//             });
//         };

//         processInvoices();
//         processProjects();
//         processExpenses();

//         return Object.values(data).map(item => ({
//             name: item.name,
//             revenue: (item.totalBilled - (item.cost + item.totalExpense)).toFixed(2)
//         }));
//     };

//     const currentData = calculateRevenueData(invoices, trackReport, projectCost, expenses, startCurrentRange, endCurrentRange, selectedFrequency === '1' ? 'Monthly' : 'Daily', reportingRevenue, preferredCurrency, forex, typeOfContract);
//     const comparisonData = calculateRevenueData(invoices, trackReport, projectCost, expenses, startComparisonRange, endComparisonRange, selectedFrequency === '1' ? 'Monthly' : 'Daily', reportingRevenue, preferredCurrency, forex, typeOfContract);

//     const allKeys = new Set([...currentData.map(item => item.name), ...comparisonData.map(item => item.name)]);

//     let mergedData = Array.from(allKeys).map(name => ({
//         name: name,
//         currentYear: parseFloat(currentData.find(item => item.name === name)?.revenue || 0),
//         lastYear: parseFloat(comparisonData.find(item => item.name === name)?.revenue || 0),
//     }));

//     if (selectedFrequency === '1') {
//         const monthOrder = [
//             'January', 'February', 'March', 'April', 'May', 'June',
//             'July', 'August', 'September', 'October', 'November', 'December'
//         ];
//         mergedData = mergedData.sort((a, b) => monthOrder.indexOf(a.name) - monthOrder.indexOf(b.name));
//     } else {
//         const parseDate = (dateString) => {
//             const [day, month, year] = dateString.split('/').map(Number);
//             return new Date(year, month - 1, day);
//         };
//         mergedData = mergedData.sort((a, b) => {
//             const dateA = parseDate(a.name);
//             const dateB = parseDate(b.name);
//             return dateA - dateB;
//         });
//     }

//     return mergedData;
// }

export function clientTotalRevenueComparisonFilter(
    trackReport,
    projectCost,
    invoices,
    reportingRevenue,
    preferredCurrency,
    forex,
    typeOfContract,
    expenses,
    selectedDateRange,
    selectedComparison,
    selectedFrequency,
    selectedYearRange,
    selectedMonthRange,
    selectedComparisonYear,
    selectedComparisonMonth,
) {
    const getLastDayOfMonth = (year, month) => {
        return new Date(year, month, 0).getDate();
    };

    const getStartAndEndDates = (rangeId, year = new Date().getFullYear()) => {
        switch (rangeId) {
            case reportingFilterDateRangesConst.This_Year:
                return [new Date(`${year}-01-01`), new Date(`${year}-12-31`)];

            case reportingFilterDateRangesConst.This_Quarter: {
                const quarter = Math.floor((new Date().getMonth() + 3) / 3);
                const startMonth = (quarter - 1) * 3 + 1;
                const endMonth = startMonth + 2;
                const endDay = getLastDayOfMonth(year, endMonth);
                return [new Date(`${year}-${startMonth}-01`), new Date(`${year}-${endMonth}-${endDay}`)];
            }

            case reportingFilterDateRangesConst.First_Half:
                return [new Date(`${year}-01-01`), new Date(`${year}-06-30`)];

            case reportingFilterDateRangesConst.This_Month: {
                const month = new Date().getMonth() + 1;
                const endDay = getLastDayOfMonth(year, month);
                return [new Date(`${year}-${month}-01`), new Date(`${year}-${month}-${endDay}`)];
            }

            case reportingFilterDateRangesConst.Year_Selected:
                if (selectedYearRange) {
                    const selectedYear = selectedYearRange.getFullYear();
                    return [new Date(`${selectedYear}-01-01`), new Date(`${selectedYear}-12-31`)];
                } else {
                    return [new Date(`${year}-01-01`), new Date(`${year}-12-31`)];
                }
            case reportingFilterDateRangesConst.Month_Selected:
                if (selectedMonthRange) {
                    const selectedMonth = selectedMonthRange.getMonth() + 1;
                    const selectedYearForMonth = selectedMonthRange.getFullYear();
                    const endDay = getLastDayOfMonth(selectedYearForMonth, selectedMonth);
                    return [new Date(`${selectedYearForMonth}-${selectedMonth}-01`), new Date(`${selectedYearForMonth}-${selectedMonth}-${endDay}`)];
                } else {
                    const currentMonth = new Date().getMonth() + 1;
                    const endDay = getLastDayOfMonth(year, currentMonth);
                    return [new Date(`${year}-${currentMonth}-01`), new Date(`${year}-${currentMonth}-${endDay}`)];
                }
            default:
                return [new Date(`${year}-01-01`), new Date(`${year}-12-31`)];
        }
    };

    const getComparisonStartAndEndDates = (comparisonId, year = new Date().getFullYear()) => {
        switch (comparisonId) {
            case reportingFilterComparisonConst.Last_Year:
                return [new Date(`${year - 1}-01-01`), new Date(`${year - 1}-12-31`)];

            case reportingFilterComparisonConst.Last_Quarter: {
                const quarter = Math.floor((new Date().getMonth() + 3) / 3);
                const lastQuarter = quarter === 1 ? 4 : quarter - 1;
                const startMonth = (lastQuarter - 1) * 3 + 1;
                const endMonth = startMonth + 2;
                const lastYear = quarter === 1 ? year - 1 : year;
                const endDay = getLastDayOfMonth(lastYear, endMonth);
                return [new Date(`${lastYear}-${startMonth}-01`), new Date(`${lastYear}-${endMonth}-${endDay}`)];
            }

            case reportingFilterComparisonConst.Second_Half:
                return [new Date(`${year}-07-01`), new Date(`${year}-12-31`)];

            case reportingFilterComparisonConst.Last_Month: {
                const lastMonth = new Date().getMonth();
                const lastYear = lastMonth === 0 ? year - 1 : year;
                const lastMonthFormatted = lastMonth === 0 ? 12 : lastMonth;
                const endDay = getLastDayOfMonth(lastYear, lastMonthFormatted);
                return [new Date(`${lastYear}-${lastMonthFormatted}-01`), new Date(`${lastYear}-${lastMonthFormatted}-${endDay}`)];
            }
            case reportingFilterComparisonConst.Year_Select:
                if (selectedComparisonYear) {
                    const comparisonYear = selectedComparisonYear.getFullYear();
                    return [new Date(`${comparisonYear}-01-01`), new Date(`${comparisonYear}-12-31`)];
                } else {
                    return [new Date(`${year - 1}-01-01`), new Date(`${year - 1}-12-31`)];
                }

            case reportingFilterComparisonConst.Month_Select:
                if (selectedComparisonMonth) {
                    const comparisonMonth = selectedComparisonMonth.getMonth() + 1;
                    const comparisonYearForMonth = selectedComparisonMonth.getFullYear();
                    const endDay = getLastDayOfMonth(comparisonYearForMonth, comparisonMonth);
                    return [new Date(`${comparisonYearForMonth}-${comparisonMonth}-01`), new Date(`${comparisonYearForMonth}-${comparisonMonth}-${endDay}`)];
                } else {
                    const lastMonth = new Date()?.getMonth() || 12;
                    const fallbackYear = lastMonth === 12 ? year - 1 : year;
                    const endDay = getLastDayOfMonth(fallbackYear, lastMonth);
                    return [new Date(`${fallbackYear}-${lastMonth}-01`), new Date(`${fallbackYear}-${lastMonth}-${endDay}`)];
                }
            default:
                return [new Date(`${year - 1}-01-01`), new Date(`${year - 1}-12-31`)];
        }
    };

    const [startCurrentRange, endCurrentRange] = getStartAndEndDates(selectedDateRange);
    const [startComparisonRange, endComparisonRange] = getComparisonStartAndEndDates(selectedComparison);

    const calculateRevenueData = (invoices, trackReport, projectCost, expenses, startRange, endRange, reportingRevenue, preferredCurrency, forex, typeOfContract) => {
        let totalRevenue = 0;

        invoices.forEach(invoice => {
            const invoiceDate = new Date(invoice?.cli_inv_end_date);
            const invoiceCurrency = invoice?.cli_inv_currency || 'USD';
            const clientId = invoice.cli_inv_for?._id;

            if (invoiceDate >= startRange && invoiceDate <= endRange && !invoice.is_delete &&
                clientId &&
                (reportingRevenue.revenueClient ? reportingRevenue.revenueClient.includes(clientId) : true) &&
                (reportingRevenue.revenueCurrency && reportingRevenue.revenueCurrency.includes(invoiceCurrency))) {

                const convertedAmount = convertCurrencyHelper(invoiceCurrency, preferredCurrency, invoice.cli_inv_total_amount, forex);
                totalRevenue += convertedAmount;
            }
        });

        trackReport.forEach(project => {
            const projectDate = new Date(project.startTime);
            const projectYear = projectDate.getFullYear();
            const preferredClientCurrency = project?.clientId?.preferred_currency || 'USD';

            if (projectDate >= startRange && projectDate <= endRange && projectYear === new Date(startRange).getFullYear() &&
                (project?.project && !project?.project?.is_delete) &&
                (reportingRevenue.revenueProject && reportingRevenue.revenueProject.includes(project?.project?._id)) &&
                (reportingRevenue.revenueCurrency && reportingRevenue.revenueCurrency.includes(preferredClientCurrency))) {

                const clientId = project?.clientId?._id;
                const projectSpecificCost = projectCost.find(cost => cost.userId === project.user?._id && cost.projectId === project?.project?._id && !cost.is_delete);
                // const userCost = projectSpecificCost && projectSpecificCost.amount > 0 ? projectSpecificCost.amount : (project.user.cost || 0);
                let userCost;
                if (projectSpecificCost && projectSpecificCost.amount > 0) {
                    userCost = convertCurrencyHelper(
                        preferredClientCurrency,
                        project?.user?.payment_currency || 'USD',
                        projectSpecificCost.amount,
                        forex
                    );
                } else {
                    userCost = project?.user?.cost || 0;
                }
                const userTypeOfContract = project?.user?.typeOfContract || typeOfContract?.Hourly_Rate;
                const userOverheadCost = project?.user?.overhead_cost || 0;
                const paymentCurrency = project?.user?.payment_currency || 'USD';

                let finalCost = userTypeOfContract === typeOfContract.Hourly_Rate ? userCost : userCost / parseInt(project?.user?.monthly_hours ? project?.user?.monthly_hours : 168);
                const cost = (finalCost * (project.duration / 3600)) + (userOverheadCost * (project.duration / 3600));
                const costConverted = convertCurrencyHelper(paymentCurrency, preferredCurrency, cost, forex);
                totalRevenue -= costConverted;
            }
        });

        expenses.forEach(expense => {
            const expenseDate = new Date(expense?.exp_date);
            const expenseCurrency = expense?.exp_currency || 'USD';
            const clientId = expense?.exp_client?._id;

            if (expenseDate >= startRange && expenseDate <= endRange && !expense.is_delete &&
                clientId &&
                (reportingRevenue.revenueClient ? reportingRevenue.revenueClient.includes(clientId) : true)) {

                const convertedAmount = convertCurrencyHelper(expenseCurrency, preferredCurrency, expense.exp_amount, forex);
                totalRevenue -= convertedAmount;
            }
        });

        return totalRevenue.toFixed(2);
    };

    const currentData = calculateRevenueData(invoices, trackReport, projectCost, expenses, startCurrentRange, endCurrentRange, reportingRevenue, preferredCurrency, forex, typeOfContract);
    const comparisonData = calculateRevenueData(invoices, trackReport, projectCost, expenses, startComparisonRange, endComparisonRange, reportingRevenue, preferredCurrency, forex, typeOfContract);

    return [{
        name: 'Total Revenue',
        currentYear: currentData,
        lastYear: comparisonData,
    }];
}

export function processedRevenueByLocation(trackReport, projectCost, invoices, reportingRevenue, preferredCurrency, forex, typeOfContract, expenses) {
    const currentYear = reportingRevenue?.revenueYear || new Date().getFullYear();

    const calculateClientRevenueByCountry = () => {
        const revenueByCountry = {};

        invoices.forEach(invoice => {
            const invoiceYear = new Date(invoice?.cli_inv_end_date).getFullYear();
            const preferredClientCurrency = invoice?.cli_inv_currency || 'USD';
            const clientId = invoice.cli_inv_for?._id;
            const clientCountry = invoice.cli_inv_for?.country;

            if (invoiceYear === currentYear && !invoice.is_delete && clientId && clientCountry && (reportingRevenue.revenueClient ? reportingRevenue.revenueClient.includes(clientId) : true) && (reportingRevenue.revenueCurrency && reportingRevenue.revenueCurrency.includes(preferredClientCurrency))) {
                const invoiceCurrency = invoice?.cli_inv_currency || 'USD';
                const convertedAmount = convertCurrencyHelper(invoiceCurrency, preferredCurrency, invoice.cli_inv_total_amount, forex);

                if (!revenueByCountry[clientCountry]) {
                    revenueByCountry[clientCountry] = {
                        name: clientCountry,
                        closedAmount: 0
                    };
                }
                revenueByCountry[clientCountry].closedAmount += convertedAmount;
            }
        });

        trackReport.forEach(project => {
            const projectYear = new Date(project.startTime).getFullYear();
            const preferredClientCurrency = project?.clientId?.preferred_currency || 'USD';
            const clientId = project?.clientId?._id;
            const clientCountry = project.clientId?.country;

            if (projectYear === currentYear && clientCountry && (project?.project && !project?.project?.is_delete) && (reportingRevenue.revenueProject && reportingRevenue.revenueProject.includes(project?.project?._id)) && (reportingRevenue.revenueCurrency && reportingRevenue.revenueCurrency.includes(preferredClientCurrency))) {
                // const userCost = project.user.cost || 0;
                const projectSpecificCost = projectCost.find(cost => cost.userId === project.user?._id && cost.projectId === project?.project?._id && !cost.is_delete);
                // const userCost = projectSpecificCost && projectSpecificCost.amount > 0 ? projectSpecificCost.amount : (project.user.cost || 0);
                let userCost;
                if (projectSpecificCost && projectSpecificCost.amount > 0) {
                    userCost = convertCurrencyHelper(
                        preferredClientCurrency,
                        project?.user?.payment_currency || 'USD',
                        projectSpecificCost.amount,
                        forex
                    );
                } else {
                    userCost = project?.user?.cost || 0;
                }
                const userTypeOfContract = project?.user?.typeOfContract || typeOfContract?.Hourly_Rate;
                const userOverheadCost = project?.user?.overhead_cost || 0;
                const paymentCurrency = project?.user?.payment_currency || 'USD';

                let finalCost;
                if (userTypeOfContract === typeOfContract.Hourly_Rate) {
                    finalCost = userCost;
                } else {
                    finalCost = userCost / parseInt(project?.user?.monthly_hours ? project?.user?.monthly_hours : 168); // Assuming a different type of contract with monthly rate divided by 168 hours
                }
                const cost = (finalCost * (project.duration / 3600)) + (userOverheadCost * (project.duration / 3600));
                const costConverted = convertCurrencyHelper(paymentCurrency, preferredCurrency, cost, forex);

                if (!revenueByCountry[clientCountry]) {
                    revenueByCountry[clientCountry] = {
                        name: clientCountry,
                        closedAmount: 0
                    };
                }
                revenueByCountry[clientCountry].closedAmount -= costConverted; // Subtracting the cost from revenue
            }
        });

        expenses.forEach(expense => {
            const clientId = expense?.exp_client?._id;
            const expenseYear = new Date(expense.exp_date).getFullYear();
            const clientCountry = expense?.exp_client?.country;

            if (expenseYear === currentYear && !expense.is_delete && clientId && clientCountry && (reportingRevenue.revenueClient ? reportingRevenue.revenueClient.includes(clientId) : true)) {
                const convertedAmount = convertCurrencyHelper(expense.exp_currency, preferredCurrency, expense.exp_amount, forex);

                if (!revenueByCountry[clientCountry]) {
                    revenueByCountry[clientCountry] = {
                        name: clientCountry,
                        closedAmount: 0
                    };
                }
                revenueByCountry[clientCountry].closedAmount -= convertedAmount; // Subtracting the expenses from revenue
            }
        });

        return Object.values(revenueByCountry);
    };

    return calculateClientRevenueByCountry();
}

export function processedRevenueByLocationFilter(
    trackReport,
    projectCost,
    invoices,
    expenses,
    reportingRevenue,
    preferredCurrency,
    forex,
    typeOfContract,
    selectedDateRange,
    selectedComparison,
    selectedYearRange,
    selectedMonthRange,
    selectedComparisonYear,
    selectedComparisonMonth,
) {
    const getLastDayOfMonth = (year, month) => {
        return new Date(year, month, 0).getDate();
    };

    const getStartAndEndDates = (rangeId, year = new Date().getFullYear()) => {
        switch (rangeId) {
            case reportingFilterDateRangesConst.This_Year:
                return [new Date(`${year}-01-01`), new Date(`${year}-12-31`)];

            case reportingFilterDateRangesConst.This_Quarter: {
                const quarter = Math.floor((new Date().getMonth() + 3) / 3);
                const startMonth = (quarter - 1) * 3 + 1;
                const endMonth = startMonth + 2;
                const endDay = getLastDayOfMonth(year, endMonth);
                return [new Date(`${year}-${startMonth}-01`), new Date(`${year}-${endMonth}-${endDay}`)];
            }

            case reportingFilterDateRangesConst.First_Half:
                return [new Date(`${year}-01-01`), new Date(`${year}-06-30`)];

            case reportingFilterDateRangesConst.This_Month: {
                const month = new Date().getMonth() + 1;
                const endDay = getLastDayOfMonth(year, month);
                return [new Date(`${year}-${month}-01`), new Date(`${year}-${month}-${endDay}`)];
            }

            case reportingFilterDateRangesConst.Year_Selected:
                if (selectedYearRange) {
                    const selectedYear = selectedYearRange.getFullYear();
                    return [new Date(`${selectedYear}-01-01`), new Date(`${selectedYear}-12-31`)];
                } else {
                    return [new Date(`${year}-01-01`), new Date(`${year}-12-31`)];
                }
            case reportingFilterDateRangesConst.Month_Selected:
                if (selectedMonthRange) {
                    const selectedMonth = selectedMonthRange.getMonth() + 1;
                    const selectedYearForMonth = selectedMonthRange.getFullYear();
                    const endDay = getLastDayOfMonth(selectedYearForMonth, selectedMonth);
                    return [new Date(`${selectedYearForMonth}-${selectedMonth}-01`), new Date(`${selectedYearForMonth}-${selectedMonth}-${endDay}`)];
                } else {
                    const currentMonth = new Date().getMonth() + 1;
                    const endDay = getLastDayOfMonth(year, currentMonth);
                    return [new Date(`${year}-${currentMonth}-01`), new Date(`${year}-${currentMonth}-${endDay}`)];
                }
            default:
                return [new Date(`${year}-01-01`), new Date(`${year}-12-31`)];
        }
    };

    const getComparisonStartAndEndDates = (comparisonId, year = new Date().getFullYear()) => {
        switch (comparisonId) {
            case reportingFilterComparisonConst.Last_Year:
                return [new Date(`${year - 1}-01-01`), new Date(`${year - 1}-12-31`)];

            case reportingFilterComparisonConst.Last_Quarter: {
                const quarter = Math.floor((new Date().getMonth() + 3) / 3);
                const lastQuarter = quarter === 1 ? 4 : quarter - 1;
                const startMonth = (lastQuarter - 1) * 3 + 1;
                const endMonth = startMonth + 2;
                const lastYear = quarter === 1 ? year - 1 : year;
                const endDay = getLastDayOfMonth(lastYear, endMonth);
                return [new Date(`${lastYear}-${startMonth}-01`), new Date(`${lastYear}-${endMonth}-${endDay}`)];
            }

            case reportingFilterComparisonConst.Second_Half:
                return [new Date(`${year}-07-01`), new Date(`${year}-12-31`)];

            case reportingFilterComparisonConst.Last_Month: {
                const lastMonth = new Date().getMonth();
                const lastYear = lastMonth === 0 ? year - 1 : year;
                const lastMonthFormatted = lastMonth === 0 ? 12 : lastMonth;
                const endDay = getLastDayOfMonth(lastYear, lastMonthFormatted);
                return [new Date(`${lastYear}-${lastMonthFormatted}-01`), new Date(`${lastYear}-${lastMonthFormatted}-${endDay}`)];
            }
            case reportingFilterComparisonConst.Year_Select:
                if (selectedComparisonYear) {
                    const comparisonYear = selectedComparisonYear.getFullYear();
                    return [new Date(`${comparisonYear}-01-01`), new Date(`${comparisonYear}-12-31`)];
                } else {
                    return [new Date(`${year - 1}-01-01`), new Date(`${year - 1}-12-31`)];
                }

            case reportingFilterComparisonConst.Month_Select:
                if (selectedComparisonMonth) {
                    const comparisonMonth = selectedComparisonMonth.getMonth() + 1;
                    const comparisonYearForMonth = selectedComparisonMonth.getFullYear();
                    const endDay = getLastDayOfMonth(comparisonYearForMonth, comparisonMonth);
                    return [new Date(`${comparisonYearForMonth}-${comparisonMonth}-01`), new Date(`${comparisonYearForMonth}-${comparisonMonth}-${endDay}`)];
                } else {
                    const lastMonth = new Date()?.getMonth() || 12;
                    const fallbackYear = lastMonth === 12 ? year - 1 : year;
                    const endDay = getLastDayOfMonth(fallbackYear, lastMonth);
                    return [new Date(`${fallbackYear}-${lastMonth}-01`), new Date(`${fallbackYear}-${lastMonth}-${endDay}`)];
                }
            default:
                return [new Date(`${year - 1}-01-01`), new Date(`${year - 1}-12-31`)];
        }
    };

    const [startCurrentRange, endCurrentRange] = getStartAndEndDates(selectedDateRange);
    const [startComparisonRange, endComparisonRange] = getComparisonStartAndEndDates(selectedComparison);

    const filterRevenueByDate = (reports, startDate, endDate) => {
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(23, 59, 59, 999);
        const clientCountry = reports.clientId?.country;
        return reports.filter(report =>
            (report?.project && !report?.project?.is_delete) &&
            (reportingRevenue.revenueProject ? reportingRevenue.revenueProject.includes(report?.project?._id) : true) &&
            (reportingRevenue.revenueCurrency ? reportingRevenue.revenueCurrency.includes(report?.clientId?.preferred_currency || 'USD') : true) &&
            new Date(report.startTime) >= startDate &&
            new Date(report.startTime) <= endDate
        );
    };

    const calculateRevenueByLocation = (filteredReports, filteredInvoices, filteredExpenses) => {
        const revenueByLocation = {};

        filteredInvoices.forEach(invoice => {
            const clientCountry = invoice.cli_inv_for?.country;
            const invoiceCurrency = invoice?.cli_inv_currency || 'USD';
            const convertedAmount = convertCurrencyHelper(invoiceCurrency, preferredCurrency, invoice.cli_inv_total_amount, forex);

            if (!revenueByLocation[clientCountry]) {
                revenueByLocation[clientCountry] = {
                    name: clientCountry,
                    revenue: 0
                };
            }
            revenueByLocation[clientCountry].revenue += convertedAmount;
        });

        filteredReports.forEach(project => {
            const clientCountry = project?.clientId?.country;
            const preferredClientCurrency = project?.clientId?.preferred_currency || 'USD';
            const projectSpecificCost = projectCost.find(cost => cost.userId === project.user?._id && cost.projectId === project?.project?._id && !cost.is_delete);
            // const userCost = projectSpecificCost && projectSpecificCost.amount > 0 ? projectSpecificCost.amount : (project.user.cost || 0);
            let userCost;
            if (projectSpecificCost && projectSpecificCost.amount > 0) {
                userCost = convertCurrencyHelper(
                    preferredClientCurrency,
                    project?.user?.payment_currency || 'USD',
                    projectSpecificCost.amount,
                    forex
                );
            } else {
                userCost = project?.user?.cost || 0;
            }
            const userTypeOfContract = project?.user?.typeOfContract || typeOfContract?.Hourly_Rate;
            const userOverheadCost = project?.user?.overhead_cost || 0;
            const paymentCurrency = project?.user?.payment_currency || 'USD';
            const finalCost = userTypeOfContract === typeOfContract.Hourly_Rate
                ? userCost
                : userCost / (project?.user?.monthly_hours || 168);
            const cost = (finalCost * (project.duration / 3600)) + (userOverheadCost * (project.duration / 3600));
            const costConverted = convertCurrencyHelper(paymentCurrency, preferredCurrency, cost, forex);

            if (!revenueByLocation[clientCountry]) {
                revenueByLocation[clientCountry] = {
                    name: clientCountry,
                    revenue: 0
                };
            }
            revenueByLocation[clientCountry].revenue -= costConverted;
        });

        filteredExpenses.forEach(expense => {
            const clientCountry = expense?.exp_client?.country;
            const convertedAmount = convertCurrencyHelper(expense.exp_currency, preferredCurrency, expense.exp_amount, forex);

            if (!revenueByLocation[clientCountry]) {
                revenueByLocation[clientCountry] = {
                    name: clientCountry,
                    revenue: 0
                };
            }
            revenueByLocation[clientCountry].revenue -= convertedAmount;
        });

        return Object.values(revenueByLocation);
    };

    const filteredCurrentReports = filterRevenueByDate(trackReport, startCurrentRange, endCurrentRange);
    const filteredComparisonReports = filterRevenueByDate(trackReport, startComparisonRange, endComparisonRange);

    const filteredCurrentInvoices = invoices.filter(invoice => {
        const clientId = invoice.cli_inv_for?._id;
        const clientCountry = invoice.cli_inv_for?.country;
        const preferredClientCurrency = invoice?.cli_inv_currency || 'USD';
        startCurrentRange.setHours(0, 0, 0, 0);
        endCurrentRange.setHours(23, 59, 59, 999);

        return (
            !invoice.is_delete &&
            clientId &&
            clientCountry &&
            (reportingRevenue.revenueClient ? reportingRevenue.revenueClient.includes(clientId) : true) &&
            (reportingRevenue.revenueCurrency && reportingRevenue.revenueCurrency.includes(preferredClientCurrency)) &&
            new Date(invoice.cli_inv_end_date) >= startCurrentRange &&
            new Date(invoice.cli_inv_end_date) <= endCurrentRange
        );
    });

    const filteredComparisonInvoices = invoices.filter(invoice => {
        const clientId = invoice.cli_inv_for?._id;
        const preferredClientCurrency = invoice?.cli_inv_currency || 'USD';
        const clientCountry = invoice.cli_inv_for?.country;
        startComparisonRange.setHours(0, 0, 0, 0);
        endComparisonRange.setHours(23, 59, 59, 999);

        return (
            !invoice.is_delete &&
            clientId &&
            clientCountry &&
            (reportingRevenue.revenueClient ? reportingRevenue.revenueClient.includes(clientId) : true) &&
            (reportingRevenue.revenueCurrency && reportingRevenue.revenueCurrency.includes(preferredClientCurrency)) &&
            new Date(invoice.cli_inv_end_date) >= startComparisonRange &&
            new Date(invoice.cli_inv_end_date) <= endComparisonRange
        );
    });

    const filteredCurrentExpenses = expenses.filter(expense => {
        const clientId = expense?.exp_client?._id;
        const clientCountry = expense?.exp_client?.country;
        startCurrentRange.setHours(0, 0, 0, 0);
        endCurrentRange.setHours(23, 59, 59, 999);
        return (
            !expense.is_delete &&
            clientId &&
            clientCountry &&
            (reportingRevenue.revenueClient ? reportingRevenue.revenueClient.includes(clientId) : true) &&
            new Date(expense.exp_date) >= startCurrentRange &&
            new Date(expense.exp_date) <= endCurrentRange
        );
    });

    const filteredComparisonExpenses = expenses.filter(expense => {
        const clientId = expense?.exp_client?._id;
        const clientCountry = expense?.exp_client?.country;
        startComparisonRange.setHours(0, 0, 0, 0);
        endComparisonRange.setHours(23, 59, 59, 999);
        return (
            !expense.is_delete &&
            clientId &&
            clientCountry &&
            (reportingRevenue.revenueClient ? reportingRevenue.revenueClient.includes(clientId) : true) &&
            new Date(expense.exp_date) >= startComparisonRange &&
            new Date(expense.exp_date) <= endComparisonRange
        );
    });

    const currentPeriodRevenueByLocation = calculateRevenueByLocation(filteredCurrentReports, filteredCurrentInvoices, filteredCurrentExpenses);
    const comparisonPeriodRevenueByLocation = calculateRevenueByLocation(filteredComparisonReports, filteredComparisonInvoices, filteredComparisonExpenses);

    const combinedRevenue = currentPeriodRevenueByLocation.map(current => {
        const lastYearData = comparisonPeriodRevenueByLocation.find(last => last.name === current.name) || { revenue: 0 };
        return {
            name: current.name,
            currentYear: parseFloat(current.revenue) || 0,
            lastYear: parseFloat(lastYearData.revenue) || 0,
        };
    });

    return combinedRevenue;
}

export function processedRevenueByClient(trackReport, projectCost, invoices, reportingRevenue, preferredCurrency, forex, typeOfContract, expenses) {
    const currentYear = reportingRevenue?.revenueYear || new Date().getFullYear();

    const calculateClientRevenue = () => {
        const revenueByClient = {};

        invoices && invoices.forEach(invoice => {
            const invoiceYear = new Date(invoice?.cli_inv_end_date).getFullYear();
            const preferredClientCurrency = invoice?.cli_inv_currency || 'USD';
            const clientId = invoice?.cli_inv_for?._id;
            const clientName = invoice?.cli_inv_for?.client_name;

            if (invoiceYear === currentYear && !invoice.is_delete && clientId && clientName && (reportingRevenue.revenueClient ? reportingRevenue.revenueClient.includes(clientId) : true) && (reportingRevenue.revenueCurrency && reportingRevenue.revenueCurrency.includes(preferredClientCurrency))) {
                const invoiceCurrency = invoice?.cli_inv_currency || 'USD';
                const convertedAmount = convertCurrencyHelper(invoiceCurrency, preferredCurrency, invoice.cli_inv_total_amount, forex);

                if (!revenueByClient[clientId]) {
                    revenueByClient[clientId] = {
                        name: clientName,
                        closedAmount: 0
                    };
                }
                revenueByClient[clientId].closedAmount += convertedAmount;
            }
        });

        trackReport && trackReport.forEach(project => {
            const projectYear = new Date(project.startTime).getFullYear();
            const preferredClientCurrency = project?.clientId?.preferred_currency || 'USD';
            const clientId = project?.clientId?._id;
            const clientName = project.clientId?.client_name;

            if (projectYear === currentYear && (project?.project && !project?.project?.is_delete) && (reportingRevenue.revenueProject && reportingRevenue.revenueProject.includes(project?.project?._id)) && (reportingRevenue.revenueCurrency && reportingRevenue.revenueCurrency.includes(preferredClientCurrency))) {
                // const userCost = project.user.cost || 0;
                const projectSpecificCost = projectCost.find(cost => cost.userId === project.user?._id && cost.projectId === project?.project?._id && !cost.is_delete);
                // const userCost = projectSpecificCost && projectSpecificCost.amount > 0 ? projectSpecificCost.amount : (project.user.cost || 0);
                let userCost;
                if (projectSpecificCost && projectSpecificCost.amount > 0) {
                    userCost = convertCurrencyHelper(
                        preferredClientCurrency,
                        project?.user?.payment_currency || 'USD',
                        projectSpecificCost.amount,
                        forex
                    );
                } else {
                    userCost = project?.user?.cost || 0;
                }
                const userTypeOfContract = project?.user?.typeOfContract || typeOfContract?.Hourly_Rate;
                const userOverheadCost = project?.user?.overhead_cost || 0;
                const paymentCurrency = project?.user?.payment_currency || 'USD';

                let finalCost;
                if (userTypeOfContract === typeOfContract.Hourly_Rate) {
                    finalCost = userCost;
                } else {
                    finalCost = userCost / parseInt(project?.user?.monthly_hours ? project?.user?.monthly_hours : 168); // Assuming a different type of contract with monthly rate divided by 168 hours
                }
                const cost = (finalCost * (project.duration / 3600)) + (userOverheadCost * (project.duration / 3600));
                const costConverted = convertCurrencyHelper(paymentCurrency, preferredCurrency, cost, forex);

                if (!revenueByClient[clientId]) {
                    revenueByClient[clientId] = {
                        name: clientName,
                        closedAmount: 0
                    };
                }
                revenueByClient[clientId].closedAmount -= costConverted; // Subtracting the cost from revenue
            }
        });

        expenses.forEach(expense => {
            const clientId = expense?.exp_client?._id;
            const expenseYear = new Date(expense.exp_date).getFullYear();
            const clientName = expense?.exp_client?.client_name;

            if (expenseYear === currentYear && !expense.is_delete && clientId && clientName && (reportingRevenue.revenueClient ? reportingRevenue.revenueClient.includes(clientId) : true)) {
                const convertedAmount = convertCurrencyHelper(expense.exp_currency, preferredCurrency, expense.exp_amount, forex);

                if (!revenueByClient[clientId]) {
                    revenueByClient[clientId] = {
                        name: clientName,
                        closedAmount: 0
                    };
                }
                revenueByClient[clientId].closedAmount -= convertedAmount; // Subtracting the expenses from revenue
            }
        });

        return Object.values(revenueByClient);
    };

    return calculateClientRevenue();
}

export function processedRevenueByClientFilter(
    trackReport,
    projectCost,
    invoices,
    expenses,
    reportingRevenue,
    preferredCurrency,
    forex,
    typeOfContract,
    selectedDateRange,
    selectedComparison,
    selectedYearRange,
    selectedMonthRange,
    selectedComparisonYear,
    selectedComparisonMonth,
) {
    const getLastDayOfMonth = (year, month) => {
        return new Date(year, month, 0).getDate();
    };

    const getStartAndEndDates = (rangeId, year = new Date().getFullYear()) => {
        switch (rangeId) {
            case reportingFilterDateRangesConst.This_Year:
                return [new Date(`${year}-01-01`), new Date(`${year}-12-31`)];

            case reportingFilterDateRangesConst.This_Quarter: {
                const quarter = Math.floor((new Date().getMonth() + 3) / 3);
                const startMonth = (quarter - 1) * 3 + 1;
                const endMonth = startMonth + 2;
                const endDay = getLastDayOfMonth(year, endMonth);
                return [new Date(`${year}-${startMonth}-01`), new Date(`${year}-${endMonth}-${endDay}`)];
            }

            case reportingFilterDateRangesConst.First_Half:
                return [new Date(`${year}-01-01`), new Date(`${year}-06-30`)];

            case reportingFilterDateRangesConst.This_Month: {
                const month = new Date().getMonth() + 1;
                const endDay = getLastDayOfMonth(year, month);
                return [new Date(`${year}-${month}-01`), new Date(`${year}-${month}-${endDay}`)];
            }

            case reportingFilterDateRangesConst.Year_Selected:
                if (selectedYearRange) {
                    const selectedYear = selectedYearRange.getFullYear();
                    return [new Date(`${selectedYear}-01-01`), new Date(`${selectedYear}-12-31`)];
                } else {
                    return [new Date(`${year}-01-01`), new Date(`${year}-12-31`)];
                }
            case reportingFilterDateRangesConst.Month_Selected:
                if (selectedMonthRange) {
                    const selectedMonth = selectedMonthRange.getMonth() + 1;
                    const selectedYearForMonth = selectedMonthRange.getFullYear();
                    const endDay = getLastDayOfMonth(selectedYearForMonth, selectedMonth);
                    return [new Date(`${selectedYearForMonth}-${selectedMonth}-01`), new Date(`${selectedYearForMonth}-${selectedMonth}-${endDay}`)];
                } else {
                    const currentMonth = new Date().getMonth() + 1;
                    const endDay = getLastDayOfMonth(year, currentMonth);
                    return [new Date(`${year}-${currentMonth}-01`), new Date(`${year}-${currentMonth}-${endDay}`)];
                }
            default:
                return [new Date(`${year}-01-01`), new Date(`${year}-12-31`)];
        }
    };

    const getComparisonStartAndEndDates = (comparisonId, year = new Date().getFullYear()) => {
        switch (comparisonId) {
            case reportingFilterComparisonConst.Last_Year:
                return [new Date(`${year - 1}-01-01`), new Date(`${year - 1}-12-31`)];

            case reportingFilterComparisonConst.Last_Quarter: {
                const quarter = Math.floor((new Date().getMonth() + 3) / 3);
                const lastQuarter = quarter === 1 ? 4 : quarter - 1;
                const startMonth = (lastQuarter - 1) * 3 + 1;
                const endMonth = startMonth + 2;
                const lastYear = quarter === 1 ? year - 1 : year;
                const endDay = getLastDayOfMonth(lastYear, endMonth);
                return [new Date(`${lastYear}-${startMonth}-01`), new Date(`${lastYear}-${endMonth}-${endDay}`)];
            }

            case reportingFilterComparisonConst.Second_Half:
                return [new Date(`${year}-07-01`), new Date(`${year}-12-31`)];

            case reportingFilterComparisonConst.Last_Month: {
                const lastMonth = new Date().getMonth();
                const lastYear = lastMonth === 0 ? year - 1 : year;
                const lastMonthFormatted = lastMonth === 0 ? 12 : lastMonth;
                const endDay = getLastDayOfMonth(lastYear, lastMonthFormatted);
                return [new Date(`${lastYear}-${lastMonthFormatted}-01`), new Date(`${lastYear}-${lastMonthFormatted}-${endDay}`)];
            }
            case reportingFilterComparisonConst.Year_Select:
                if (selectedComparisonYear) {
                    const comparisonYear = selectedComparisonYear.getFullYear();
                    return [new Date(`${comparisonYear}-01-01`), new Date(`${comparisonYear}-12-31`)];
                } else {
                    return [new Date(`${year - 1}-01-01`), new Date(`${year - 1}-12-31`)];
                }

            case reportingFilterComparisonConst.Month_Select:
                if (selectedComparisonMonth) {
                    const comparisonMonth = selectedComparisonMonth.getMonth() + 1;
                    const comparisonYearForMonth = selectedComparisonMonth.getFullYear();
                    const endDay = getLastDayOfMonth(comparisonYearForMonth, comparisonMonth);
                    return [new Date(`${comparisonYearForMonth}-${comparisonMonth}-01`), new Date(`${comparisonYearForMonth}-${comparisonMonth}-${endDay}`)];
                } else {
                    const lastMonth = new Date()?.getMonth() || 12;
                    const fallbackYear = lastMonth === 12 ? year - 1 : year;
                    const endDay = getLastDayOfMonth(fallbackYear, lastMonth);
                    return [new Date(`${fallbackYear}-${lastMonth}-01`), new Date(`${fallbackYear}-${lastMonth}-${endDay}`)];
                }
            default:
                return [new Date(`${year - 1}-01-01`), new Date(`${year - 1}-12-31`)];
        }
    };

    const [startCurrentRange, endCurrentRange] = getStartAndEndDates(selectedDateRange);
    const [startComparisonRange, endComparisonRange] = getComparisonStartAndEndDates(selectedComparison);

    const filterRevenueByDate = (reports, startDate, endDate) => {
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(23, 59, 59, 999);
        return reports.filter(report =>
            (report?.project && !report?.project?.is_delete) &&
            (reportingRevenue.revenueProject && reportingRevenue.revenueProject.includes(report?.project?._id)) &&
            (reportingRevenue.revenueCurrency && reportingRevenue.revenueCurrency.includes(report?.clientId?.preferred_currency || 'USD')) &&
            new Date(report.startTime) >= startDate &&
            new Date(report.startTime) <= endDate
        );
    };

    const calculateRevenueByClient = (filteredReports, filteredInvoices, filteredExpenses) => {
        const revenueByClient = {};

        filteredInvoices.forEach(invoice => {
            const clientId = invoice.cli_inv_for?._id;
            const clientName = invoice.cli_inv_for?.client_name;
            const invoiceCurrency = invoice?.cli_inv_currency || 'USD';
            const convertedAmount = convertCurrencyHelper(invoiceCurrency, preferredCurrency, invoice.cli_inv_total_amount, forex);

            if (!revenueByClient[clientId]) {
                revenueByClient[clientId] = {
                    name: clientName,
                    revenue: 0
                };
            }
            revenueByClient[clientId].revenue += convertedAmount;
        });

        filteredReports.forEach(project => {
            const clientId = project?.clientId?._id;
            const clientName = project?.clientId?.client_name;
            const preferredClientCurrency = project?.clientId?.preferred_currency || 'USD';
            const projectSpecificCost = projectCost.find(cost => cost.userId === project.user?._id && cost.projectId === project?.project?._id && !cost.is_delete);
            // const userCost = projectSpecificCost ? projectSpecificCost.amount : (project.user.cost || 0);
            let userCost;
            if (projectSpecificCost && projectSpecificCost.amount > 0) {
                userCost = convertCurrencyHelper(
                    preferredClientCurrency,
                    project?.user?.payment_currency || 'USD',
                    projectSpecificCost.amount,
                    forex
                );
            } else {
                userCost = project?.user?.cost || 0;
            }
            const userTypeOfContract = project?.user?.typeOfContract || typeOfContract?.Hourly_Rate;
            const userOverheadCost = project?.user?.overhead_cost || 0;
            const paymentCurrency = project?.user?.payment_currency || 'USD';
            const finalCost = userTypeOfContract === typeOfContract?.Hourly_Rate
                ? userCost
                : userCost / (project?.user?.monthly_hours || 168);
            const cost = (finalCost * (project.duration / 3600)) + (userOverheadCost * (project.duration / 3600));
            const costConverted = convertCurrencyHelper(paymentCurrency, preferredCurrency, cost, forex);

            if (!revenueByClient[clientId]) {
                revenueByClient[clientId] = {
                    name: clientName,
                    revenue: 0
                };
            }
            revenueByClient[clientId].revenue -= costConverted;
        });

        filteredExpenses.forEach(expense => {
            const clientId = expense?.exp_client?._id;
            const clientName = expense?.exp_client?.client_name;
            const convertedAmount = convertCurrencyHelper(expense.exp_currency, preferredCurrency, expense.exp_amount, forex);

            if (!revenueByClient[clientId]) {
                revenueByClient[clientId] = {
                    name: clientName,
                    revenue: 0
                };
            }
            revenueByClient[clientId].revenue -= convertedAmount;
        });

        return Object.values(revenueByClient);
    };

    // Filter data for current and comparison periods
    const filteredCurrentReports = filterRevenueByDate(trackReport, startCurrentRange, endCurrentRange);
    const filteredComparisonReports = filterRevenueByDate(trackReport, startComparisonRange, endComparisonRange);

    const filteredCurrentInvoices = invoices.filter(invoice => {
        const clientId = invoice.cli_inv_for?._id;
        const clientName = invoice.cli_inv_for?.client_name;
        const preferredClientCurrency = invoice?.cli_inv_currency || 'USD';
        startCurrentRange.setHours(0, 0, 0, 0);
        endCurrentRange.setHours(23, 59, 59, 999);

        return (
            !invoice.is_delete &&
            clientId &&
            clientName &&
            (reportingRevenue.revenueClient ? reportingRevenue.revenueClient.includes(clientId) : true) &&
            (reportingRevenue.revenueCurrency && reportingRevenue.revenueCurrency.includes(preferredClientCurrency)) &&
            new Date(invoice.cli_inv_end_date) >= startCurrentRange &&
            new Date(invoice.cli_inv_end_date) <= endCurrentRange
        );
    });

    const filteredComparisonInvoices = invoices.filter(invoice => {
        const clientId = invoice.cli_inv_for?._id;
        const clientName = invoice.cli_inv_for?.client_name;
        const preferredClientCurrency = invoice?.cli_inv_currency || 'USD';
        startComparisonRange.setHours(0, 0, 0, 0);
        endComparisonRange.setHours(23, 59, 59, 999);

        return (
            !invoice.is_delete &&
            clientId &&
            clientName &&
            (reportingRevenue.revenueClient ? reportingRevenue.revenueClient.includes(clientId) : true) &&
            (reportingRevenue.revenueCurrency && reportingRevenue.revenueCurrency.includes(preferredClientCurrency)) &&
            new Date(invoice.cli_inv_end_date) >= startComparisonRange &&
            new Date(invoice.cli_inv_end_date) <= endComparisonRange
        );
    });

    const filteredCurrentExpenses = expenses.filter(expense => {
        const clientId = expense?.exp_client?._id;
        const clientName = expense?.exp_client?.client_name;
        startCurrentRange.setHours(0, 0, 0, 0);
        endCurrentRange.setHours(23, 59, 59, 999);
        return (
            !expense.is_delete &&
            clientId &&
            clientName &&
            (reportingRevenue.revenueClient ? reportingRevenue.revenueClient.includes(clientId) : true) &&
            new Date(expense.exp_date) >= startCurrentRange &&
            new Date(expense.exp_date) <= endCurrentRange
        );
    });

    const filteredComparisonExpenses = expenses.filter(expense => {
        const clientId = expense?.exp_client?._id;
        const clientName = expense?.exp_client?.client_name;
        startComparisonRange.setHours(0, 0, 0, 0);
        endComparisonRange.setHours(23, 59, 59, 999);
        return (
            !expense.is_delete &&
            clientId &&
            clientName &&
            (reportingRevenue.revenueClient ? reportingRevenue.revenueClient.includes(clientId) : true) &&
            new Date(expense.exp_date) >= startComparisonRange &&
            new Date(expense.exp_date) <= endComparisonRange
        );
    });

    // Calculate revenue for current and comparison periods
    const currentPeriodRevenue = calculateRevenueByClient(filteredCurrentReports, filteredCurrentInvoices, filteredCurrentExpenses);
    const comparisonPeriodRevenue = calculateRevenueByClient(filteredComparisonReports, filteredComparisonInvoices, filteredComparisonExpenses);

    const combinedRevenue = currentPeriodRevenue.map(current => {
        const lastYearData = comparisonPeriodRevenue.find(last => last.name === current.name) || { revenue: 0 };
        return {
            name: current.name,
            currentYear: parseFloat(current?.revenue) || 0,
            lastYear: parseFloat(lastYearData?.revenue) || 0,
        };
    });

    return combinedRevenue;
}

export function processedInvoicedOutstandingByClient(invoices, reportingRevenue, preferredCurrency, forex) {
    const currentYear = reportingRevenue?.revenueYear || new Date().getFullYear();
    const clientData = {};

    invoices.forEach(invoice => {
        const invoiceYear = new Date(invoice?.cli_inv_end_date).getFullYear();
        const preferredClientCurrency = invoice?.cli_inv_currency || 'USD';
        const clientId = invoice.cli_inv_for?._id;
        const clientName = invoice.cli_inv_for?.client_name;

        if (invoiceYear === currentYear && !invoice.is_delete && clientId && clientName && (reportingRevenue.revenueClient ? reportingRevenue.revenueClient.includes(clientId) : true) && (reportingRevenue.revenueCurrency && reportingRevenue.revenueCurrency.includes(preferredClientCurrency))) {
            const invoiceCurrency = invoice?.cli_inv_currency || 'USD';
            const convertedAmount = convertCurrencyHelper(invoiceCurrency, preferredCurrency, invoice.cli_inv_total_amount, forex);

            if (!clientData[clientName]) {
                clientData[clientName] = {
                    name: clientName,
                    invoiced: 0,
                    outstanding: 0
                };
            }

            // Add the total amount to the invoiced sum
            clientData[clientName].invoiced += parseFloat(convertedAmount);

            // Add to the outstanding amount if the invoice status is not INVOICE_PAYMENT_PROCESSED
            if (invoice.cli_inv_status !== CLIENT_INVOICE_STATUS.INVOICE_PAYMENT_PROCESSED) {
                clientData[clientName].outstanding += parseFloat(convertedAmount);
            }
        }
    });

    // Round the invoiced and outstanding amounts to two decimal places
    Object.keys(clientData).forEach(clientName => {
        clientData[clientName].invoiced = clientData[clientName].invoiced.toFixed(2);
        clientData[clientName].outstanding = clientData[clientName].outstanding.toFixed(2);
    });

    return Object.values(clientData);
}

export function processedInvoicedOutstandingByClientFilter(
    invoices,
    reportingRevenue,
    preferredCurrency,
    forex,
    selectedDateRange,
    selectedComparison,
    selectedYearRange,
    selectedMonthRange,
    selectedComparisonYear,
    selectedComparisonMonth
) {
    if (!invoices || !reportingRevenue || !reportingRevenue?.revenueClient || !reportingRevenue?.revenueCurrency) {
        return [];
    }

    const getLastDayOfMonth = (year, month) => {
        return new Date(year, month, 0).getDate();
    };

    const getStartAndEndDates = (rangeId, year = new Date().getFullYear()) => {
        switch (rangeId) {
            case reportingFilterDateRangesConst.This_Year:
                return [
                    new Date(`${year}-01-01`),
                    new Date(`${year}-12-31`)
                ];

            case reportingFilterDateRangesConst.This_Quarter: {
                const quarter = Math.floor((new Date().getMonth() + 3) / 3);
                const startMonth = (quarter - 1) * 3 + 1;
                const endMonth = startMonth + 2;
                const endDay = getLastDayOfMonth(year, endMonth);
                return [
                    new Date(`${year}-${startMonth}-01`),
                    new Date(`${year}-${endMonth}-${endDay}`)
                ];
            }

            case reportingFilterDateRangesConst.First_Half:
                return [
                    new Date(`${year}-01-01`),
                    new Date(`${year}-06-30`)
                ];

            case reportingFilterDateRangesConst.This_Month: {
                const month = new Date().getMonth() + 1;
                const endDay = getLastDayOfMonth(year, month);
                return [
                    new Date(`${year}-${month}-01`),
                    new Date(`${year}-${month}-${endDay}`)
                ];
            }

            case reportingFilterDateRangesConst.Year_Selected:
                if (selectedYearRange) {
                    const selectedYear = selectedYearRange.getFullYear();
                    return [
                        new Date(`${selectedYear}-01-01`),
                        new Date(`${selectedYear}-12-31`)
                    ];
                } else {
                    return [
                        new Date(`${year}-01-01`),
                        new Date(`${year}-12-31`)
                    ];
                }

            case reportingFilterDateRangesConst.Month_Selected:
                if (selectedMonthRange) {
                    const selectedMonth = selectedMonthRange.getMonth() + 1;
                    const selectedYearForMonth = selectedMonthRange.getFullYear();
                    const endDay = getLastDayOfMonth(selectedYearForMonth, selectedMonth);
                    return [
                        new Date(`${selectedYearForMonth}-${selectedMonth}-01`),
                        new Date(`${selectedYearForMonth}-${selectedMonth}-${endDay}`)
                    ];
                } else {
                    const currentMonth = new Date().getMonth() + 1;
                    const endDay = getLastDayOfMonth(year, currentMonth);
                    return [
                        new Date(`${year}-${currentMonth}-01`),
                        new Date(`${year}-${currentMonth}-${endDay}`)
                    ];
                }

            default:
                return [
                    new Date(`${year}-01-01`),
                    new Date(`${year}-12-31`)
                ];
        }
    };

    const getComparisonStartAndEndDates = (comparisonId, year = new Date().getFullYear()) => {
        switch (comparisonId) {
            case reportingFilterComparisonConst.Last_Year:
                return [
                    new Date(`${year - 1}-01-01`),
                    new Date(`${year - 1}-12-31`)
                ];

            case reportingFilterComparisonConst.Last_Quarter: {
                const quarter = Math.floor((new Date().getMonth() + 3) / 3);
                const lastQuarter = quarter === 1 ? 4 : quarter - 1;
                const startMonth = (lastQuarter - 1) * 3 + 1;
                const endMonth = startMonth + 2;
                const lastYear = quarter === 1 ? year - 1 : year;
                const endDay = getLastDayOfMonth(lastYear, endMonth);
                return [
                    new Date(`${lastYear}-${startMonth}-01`),
                    new Date(`${lastYear}-${endMonth}-${endDay}`)
                ];
            }

            case reportingFilterComparisonConst.Second_Half:
                return [
                    new Date(`${year}-07-01`),
                    new Date(`${year}-12-31`)
                ];

            case reportingFilterComparisonConst.Last_Month: {
                const lastMonth = new Date().getMonth(); // 0-indexed for previous month
                const lastYear = lastMonth === 0 ? year - 1 : year;
                const lastMonthFormatted = lastMonth === 0 ? 12 : lastMonth;
                const endDay = getLastDayOfMonth(lastYear, lastMonthFormatted);
                return [
                    new Date(`${lastYear}-${lastMonthFormatted}-01`),
                    new Date(`${lastYear}-${lastMonthFormatted}-${endDay}`)
                ];
            }

            case reportingFilterComparisonConst.Year_Select:
                if (selectedComparisonYear) {
                    const comparisonYear = selectedComparisonYear.getFullYear();
                    return [
                        new Date(`${comparisonYear}-01-01`),
                        new Date(`${comparisonYear}-12-31`)
                    ];
                } else {
                    return [
                        new Date(`${year - 1}-01-01`),
                        new Date(`${year - 1}-12-31`)
                    ];
                }

            case reportingFilterComparisonConst.Month_Select:
                if (selectedComparisonMonth) {
                    const comparisonMonth = selectedComparisonMonth.getMonth() + 1;
                    const comparisonYearForMonth = selectedComparisonMonth.getFullYear();
                    const endDay = getLastDayOfMonth(comparisonYearForMonth, comparisonMonth);
                    return [
                        new Date(`${comparisonYearForMonth}-${comparisonMonth}-01`),
                        new Date(`${comparisonYearForMonth}-${comparisonMonth}-${endDay}`)
                    ];
                } else {
                    const lastMonth = new Date()?.getMonth() || 12;
                    const fallbackYear = lastMonth === 12 ? year - 1 : year;
                    const endDay = getLastDayOfMonth(fallbackYear, lastMonth);
                    return [
                        new Date(`${fallbackYear}-${lastMonth}-01`),
                        new Date(`${fallbackYear}-${lastMonth}-${endDay}`)
                    ];
                }
            default:
                return [
                    new Date(`${year - 1}-01-01`),
                    new Date(`${year - 1}-12-31`)
                ];
        }
    };

    const filterInvoicesByDateRange = (invoices, startDate, endDate) => {
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(23, 59, 59, 999);
        return invoices.filter(invoice =>
            !invoice.is_delete &&
            reportingRevenue.revenueClient.includes(invoice.cli_inv_for?._id) &&
            new Date(invoice.cli_inv_end_date) >= startDate &&
            new Date(invoice.cli_inv_end_date) <= endDate &&
            reportingRevenue.revenueCurrency.includes(invoice?.cli_inv_currency || 'USD')
        );
    };

    const calculateInvoicedOutstandingByClient = (invoices) => {
        const clientData = {};

        invoices.forEach(invoice => {
            const clientId = invoice.cli_inv_for?._id;
            const clientName = invoice.cli_inv_for?.client_name;
            const invoiceCurrency = invoice?.cli_inv_currency || 'USD';
            const convertedAmount = convertCurrencyHelper(invoiceCurrency, preferredCurrency, invoice.cli_inv_total_amount, forex);

            if (clientId && clientName) {
                if (!clientData[clientName]) {
                    clientData[clientName] = {
                        name: clientName,
                        invoiced: 0,
                        outstanding: 0
                    };
                }

                clientData[clientName].invoiced += parseFloat(convertedAmount);

                if (invoice.cli_inv_status !== CLIENT_INVOICE_STATUS.INVOICE_PAYMENT_PROCESSED) {
                    clientData[clientName].outstanding += parseFloat(convertedAmount);
                }
            }
        });

        Object.keys(clientData).forEach(clientName => {
            clientData[clientName].invoiced = clientData[clientName].invoiced.toFixed(2);
            clientData[clientName].outstanding = clientData[clientName].outstanding.toFixed(2);
        });

        return clientData;
    };

    const [startCurrentRange, endCurrentRange] = getStartAndEndDates(selectedDateRange, selectedYearRange ? selectedYearRange.getFullYear() : undefined);
    const [startComparisonRange, endComparisonRange] = getComparisonStartAndEndDates(selectedComparison, selectedComparisonYear ? selectedComparisonYear.getFullYear() : undefined);

    const currentPeriodInvoices = filterInvoicesByDateRange(invoices, startCurrentRange, endCurrentRange);
    const comparisonPeriodInvoices = filterInvoicesByDateRange(invoices, startComparisonRange, endComparisonRange);

    const currentPeriodClientData = calculateInvoicedOutstandingByClient(currentPeriodInvoices);
    const comparisonPeriodClientData = calculateInvoicedOutstandingByClient(comparisonPeriodInvoices);

    const combinedClientData = Object.keys(currentPeriodClientData).map(clientName => {
        const current = currentPeriodClientData[clientName];
        const comparison = comparisonPeriodClientData[clientName] || { invoiced: 0, outstanding: 0 };

        return {
            name: clientName,
            currentPeriodInvoiced: parseFloat(current.invoiced),
            currentPeriodOutstanding: parseFloat(current.outstanding),
            comparisonPeriodInvoiced: parseFloat(comparison.invoiced),
            comparisonPeriodOutstanding: parseFloat(comparison.outstanding),
        };
    });

    return combinedClientData;
}

export function totalInvoicedOverviewPerInvStatus(invoices, reportingRevenue, preferredCurrency, forex) {
    const currentYear = reportingRevenue?.revenueYear || new Date().getFullYear();
    const statusData = {
        pending: 0,
        send: 0,
        reject: 0,
        cancel: 0,
        processed: 0
    };

    invoices.forEach(invoice => {
        const invoiceYear = new Date(invoice?.cli_inv_end_date).getFullYear();
        const clientId = invoice?.cli_inv_for?._id;
        const invoiceCurrency = invoice?.cli_inv_currency || 'USD';

        if (invoiceYear === currentYear && !invoice.is_delete && clientId && (reportingRevenue.revenueClient && reportingRevenue.revenueClient.includes(clientId)) && (reportingRevenue.revenueCurrency && reportingRevenue.revenueCurrency.includes(invoiceCurrency))) {
            const convertedAmount = convertCurrencyHelper(invoiceCurrency, preferredCurrency, invoice.cli_inv_total_amount, forex);

            switch (invoice.cli_inv_status) {
                case CLIENT_INVOICE_STATUS.INVOICE_PENDING:
                    statusData.pending += parseFloat(convertedAmount);
                    break;
                case CLIENT_INVOICE_STATUS.INVOICE_SEND:
                    statusData.send += parseFloat(convertedAmount);
                    break;
                case CLIENT_INVOICE_STATUS.INVOICE_REJECT:
                    statusData.reject += parseFloat(convertedAmount);
                    break;
                case CLIENT_INVOICE_STATUS.INVOICE_CANCEL:
                    statusData.cancel += parseFloat(convertedAmount);
                    break;
                case CLIENT_INVOICE_STATUS.INVOICE_PAYMENT_PROCESSED:
                    statusData.processed += parseFloat(convertedAmount);
                    break;
                default:
                    break;
            }
        }
    });

    // Convert the status data into an array format suitable for the chart
    return [
        { name: 'Pending', revenue: statusData.pending.toFixed(2) },
        { name: 'Sent', revenue: statusData.send.toFixed(2) },
        { name: 'Reject', revenue: statusData.reject.toFixed(2) },
        { name: 'Cancel', revenue: statusData.cancel.toFixed(2) },
        { name: 'Processed', revenue: statusData.processed.toFixed(2) }
    ];
}