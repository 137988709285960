import { AES, enc } from 'crypto-js';
import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// import userTypeConsts from '../../../constants/userTypeConsts';
import { Link, useNavigate, Outlet, useParams } from 'react-router-dom';
import { setHeaderText } from "../../../features/headerText/headerTextSlice";
import { setSideBarText } from '../../../features/sideBarText/sideBarTextSlice';
import { useVendorView } from './useVendorDetails';
import userCategory from '../../../constants/userCategory';


function VendorDetailsLayout() {
  const { encryptedId } = useParams();
  const decodedId = decodeURIComponent(encryptedId.replace(/%2F/g, '/'));
  const decryptedBytes = AES.decrypt(decodedId, 'encryptionKey');
  const id = decryptedBytes.toString(enc.Utf8);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const navigate = useNavigate();
  const optionDropdownRef = useRef(null);
  const [selectedOption, setSelectedOption] = useState("Profile");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const handleOptionClick = (option, url) => {
    setSelectedOption(option);
    navigate(url + encodeURIComponent(AES.encrypt(id, 'encryptionKey').toString().replace(/\//g, '%2F')));
    setDropdownOpen(false); // Close the dropdown after selecting an option
  };
  // const handleSelectChange = (event) => {
  //   const selectedPage = event.target.value;
  //   navigate(selectedPage + encodeURIComponent(AES.encrypt(id, 'encryptionKey').toString().replace(/\//g, '%2F')));
  // };
  let token = null;
  if (user) {
    if (user && user.token) {
      token = user.token;
    }
  }

  const { data: vendor = [], isLoading, refetch: refetchVendorProfiles, isError: vendorViewError } = useVendorView(id, token);

  const getOptionText = (path) => {
    const basePathRegex = /\/vendor\/(profile|invoices|banks)(\/[^/]+)?/;
    const match = path.match(basePathRegex);
    if (!match) {
      return "";
    }
    const base = match[1];
    const encryptedIdFromPath = match[2]?.slice(1);

    switch (base) {
      case "profile":
        return "Profile";
      case "invoices":
        if (path.startsWith("/vendor/invoices/view/")) {
          return "Invoices";
        }
        return encryptedIdFromPath ? "Invoices" : "";
      case "banks":
        return "Bank Accounts";
      default:
        return "";
    }
  };

  useEffect(() => {
    // Set the initial value of the select to match the current URL path
    const currentPath = window.location.pathname;
    console.log("getOptionText", currentPath);
    setSelectedOption(getOptionText(currentPath));
    const selectElement = document.querySelector('.mobile-tab-select');
    if (selectElement) {
      selectElement.value = currentPath;
    }
  }, []);

  useEffect(() => {
    refetchVendorProfiles();
    if (!isLoading) {
      if (user && (user?.userCategory === userCategory.Vendor)) {
        dispatch(setHeaderText("Invoices"));
      } else {
        dispatch(setHeaderText(vendor?.vendor_name || vendor?.vendor_legalentity_name || "Vendor Overview"));
      }
      dispatch(setSideBarText("Vendor Overview"));
    }
  }, [id, isLoading]);

  // const originalUrl = window.location.pathname;
  // const lastIndex = originalUrl.lastIndexOf('/');
  // const newUrl = originalUrl.substring(0, lastIndex);

  return (
    <div className='tab-outer-time'>

      {(user && (user.userCategory !== userCategory.Vendor)) && (
        <>
          <div className='tab-mobile-select'>
            <h4>Select an option</h4>
            {/* <select className="mobile-tab-select" onChange={handleSelectChange} value={`${newUrl}/`}>
          <option value={`/vendor/profile/`} >Profile</option>
          <option value={`/vendor/invoices/`} >Invoices</option>
        </select> */}
            <div className="custom-dropdown-user-new-outer">
              <div className="selected-option custom-dropdown-user-new" onClick={() => setDropdownOpen(!dropdownOpen)} ref={optionDropdownRef} >
                {selectedOption}
              </div>
              {dropdownOpen && (
                <div className="custom-dropdown-new">
                  <ul className="showing-options-list">
                    <li onClick={() => handleOptionClick("Profile", "/vendor/profile/")}>Profile</li>
                    <li onClick={() => handleOptionClick("Invoices", "/vendor/invoices/")}>Invoices</li>
                    <li onClick={() => handleOptionClick("Bank Accounts", "/vendor/banks/")}>Bank Accounts</li>
                  </ul>
                </div>
              )}
            </div>

          </div>
          <ul className="tabs">
            <li>
              <Link to={`/vendor/profile/${encodeURIComponent(AES.encrypt(id, 'encryptionKey').toString().replace(/\//g, '%2F'))}`} className={window.location.pathname.startsWith('/vendor/profile') ? 'active' : ''}>
                Profile
              </Link>
            </li>
            <li>
              <Link to={`/vendor/invoices/${encodeURIComponent(AES.encrypt(id, 'encryptionKey').toString().replace(/\//g, '%2F'))}`} className={window.location.pathname.startsWith('/vendor/invoices') ? 'active' : ''}>
                Invoices
              </Link>
            </li>
            <li>
              <Link to={`/vendor/banks/${encodeURIComponent(AES.encrypt(id, 'encryptionKey').toString().replace(/\//g, '%2F'))}`} className={window.location.pathname.startsWith('/vendor/banks') ? 'active' : ''}>
                Bank Accounts
              </Link>
            </li>
          </ul>
        </>
      )}
      <div className="tabs-content">
        <div className='tab-inner-time'>
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default VendorDetailsLayout;
