import moment from "moment";
import { convertCurrencyHelper } from "../../../../helpers/forexConvertor";
import { reportingFilterDateRangesConst } from "../../../../constants/reportingFilterDateRangesConst";
import { reportingFilterComparisonConst } from "../../../../constants/reportingFilterComparisonConst";

const changeDurationFormat = (sec) => {
    const hours = Math.floor(sec / 3600);
    const minutes = Math.floor((sec % 3600) / 60);
    const seconds = sec % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
};

export function calculateServicesTotalHours(trackReport, reportingServices) {
    const timeZone = global.config.getTimeZone();
    const firstDayOfYear = moment.tz(timeZone).startOf("year").toDate();
    let totalTrackedHours = 0;

    trackReport.forEach(entry => {
        const dateRange = reportingServices.servicesDateRange || [firstDayOfYear, new Date()];
        const trackEndDate = moment.tz(entry?.endTime, timeZone);
        const tagId = entry?.tag?._id;
        const clientId = entry?.clientId?._id;
        const clientPreferredCurrency = entry?.clientId?.preferred_currency || 'USD';
        const startDate = moment.tz(dateRange[0], timeZone);
        const endDate = moment.tz(dateRange[1], timeZone);
        if (
            // !entry.is_delete &&
            tagId &&
            (reportingServices.servicesTag && reportingServices.servicesTag.includes(tagId)) &&
            clientId &&
            (reportingServices.servicesClient && reportingServices.servicesClient.includes(clientId)) &&
            trackEndDate.isBetween(startDate, endDate, null, '[]') &&
            (reportingServices.servicesCurrency && reportingServices.servicesCurrency.includes(clientPreferredCurrency))
        ) {
            totalTrackedHours += entry.duration;
        }
    });

    return totalTrackedHours;
};

export function calculateServicesTotalCost(trackReport, projectCost, reportingServices, preferredCurrency, forex, typeOfContract) {
    const timeZone = global.config.getTimeZone();
    const firstDayOfYear = moment.tz(timeZone).startOf("year").toDate();
    let totalCost = 0;

    // Calculate project costs per client
    trackReport.forEach(entry => {
        const { project, clientId, user, tag, endTime, duration } = entry;
        const dateRange = reportingServices.servicesDateRange || [firstDayOfYear, new Date()];
        const trackEndDate = moment.tz(endTime, timeZone);
        const tagId = tag && tag._id;
        const startDate = moment.tz(dateRange[0], timeZone);
        const endDate = moment.tz(dateRange[1], timeZone);

        if (
            project &&
            !project.is_delete &&
            tagId
            && (reportingServices.servicesTag && reportingServices.servicesTag.includes(tagId))
            && (reportingServices.servicesClient && reportingServices.servicesClient.includes(clientId?._id))
            && (reportingServices.servicesCurrency && reportingServices.servicesCurrency.includes(clientId?.preferred_currency || 'USD'))
            && trackEndDate.isBetween(startDate, endDate, null, '[]')
        ) {
            // const userCost = user.cost || 0;
            const paymentCurrency = user.payment_currency || 'USD';
            const preferredClientCurrency = clientId?.preferred_currency || 'USD';
            const projectSpecificCost = projectCost.find(cost => cost.userId === user?._id && cost.projectId === project?._id && !cost.is_delete);
            // const userCost = projectSpecificCost && projectSpecificCost.amount > 0 ? projectSpecificCost.amount : (user.cost || 0);
            let userCost;
            if (projectSpecificCost && projectSpecificCost.amount > 0) {
                userCost = convertCurrencyHelper(
                    preferredClientCurrency,
                    paymentCurrency,
                    projectSpecificCost.amount,
                    forex
                );
            } else {
                userCost = user.cost || 0;
            }
            const userOverheadCost = user.overhead_cost || 0;
            const userTypeOfContract = user.typeOfContract || typeOfContract.Hourly_Rate;

            let finalCost;
            if (userTypeOfContract === typeOfContract.Hourly_Rate) {
                finalCost = userCost;
            } else {
                finalCost = userCost / parseInt(user?.monthly_hours ? user?.monthly_hours : 168); // Assuming a different type of contract with monthly rate divided by 168 hours
            }

            const cost = (finalCost * (duration / 3600)) + (userOverheadCost * (duration / 3600));
            totalCost += convertCurrencyHelper(paymentCurrency, preferredCurrency, cost, forex);
        }
    });

    return parseFloat(totalCost).toFixed(2);
}

export function calculateServicesTotalInvoiced(invoices, reportingServices, preferredCurrency, forex) {
    const timeZone = global.config.getTimeZone();
    const firstDayOfYear = moment.tz(timeZone).startOf("year").toDate();
    let totalBilledAmount = 0;

    invoices && invoices.forEach(invoice => {
        const dateRange = reportingServices?.servicesDateRange || [firstDayOfYear, new Date()];
        const invEndDate = moment.tz(invoice?.cli_inv_end_date, timeZone);
        const startDate = moment.tz(dateRange[0], timeZone);
        const endDate = moment.tz(dateRange[1], timeZone);
        const preferredClientCurrency = invoice?.cli_inv_currency || 'USD';
        const clientId = invoice?.cli_inv_for?._id;
        if (invEndDate.isBetween(startDate, endDate, null, '[]') && !invoice.is_delete && clientId
            && (reportingServices.servicesClient && reportingServices.servicesClient.includes(clientId))
            && (reportingServices.servicesCurrency && reportingServices.servicesCurrency.includes(preferredClientCurrency))
        ) {
            const invoiceCurrency = invoice?.cli_inv_currency || 'USD';
            totalBilledAmount += convertCurrencyHelper(invoiceCurrency, preferredCurrency, invoice.cli_inv_total_amount, forex);
        }
    });

    return parseFloat(totalBilledAmount).toFixed(2);
}

export function calculateServicesTotalRoi(trackReport, projectCost, reportingServices, preferredCurrency, forex, typeOfContract, invoices) {
    const timeZone = global.config.getTimeZone();
    const firstDayOfYear = moment.tz(timeZone).startOf("year").toDate();
    let totalBilledAmount = 0;
    let totalBudget = 0;
    let totalCost = 0;
    const processedProjects = new Set();

    invoices.forEach(invoice => {
        const dateRange = reportingServices?.servicesDateRange || [firstDayOfYear, new Date()];
        const invEndDate = moment.tz(invoice?.cli_inv_end_date, timeZone);
        const startDate = moment.tz(dateRange[0], timeZone);
        const endDate = moment.tz(dateRange[1], timeZone);
        const preferredClientCurrency = invoice?.cli_inv_currency || 'USD';
        const clientId = invoice?.cli_inv_for?._id;
        if (invEndDate.isBetween(startDate, endDate, null, '[]') && !invoice.is_delete && clientId
            && (reportingServices.servicesClient && reportingServices.servicesClient.includes(clientId))
            && (reportingServices.servicesCurrency && reportingServices.servicesCurrency.includes(preferredClientCurrency))
        ) {
            const invoiceCurrency = invoice?.cli_inv_currency || 'USD';
            totalBilledAmount += convertCurrencyHelper(invoiceCurrency, preferredCurrency, invoice.cli_inv_total_amount, forex);
        }
    });

    // Calculate project costs per client
    trackReport.forEach(entry => {
        const { project, clientId, user, tag, endTime, duration } = entry;
        const dateRange = reportingServices?.servicesDateRange || [firstDayOfYear, new Date()];
        const trackEndDate = moment.tz(endTime, timeZone);
        const tagId = tag && tag._id;
        const startDate = moment.tz(dateRange[0], timeZone);
        const endDate = moment.tz(dateRange[1], timeZone);
        const projectId = project && project._id;

        if (project &&
            !project.is_delete &&
            tagId
            && (reportingServices.servicesTag && reportingServices.servicesTag.includes(tagId))
            && (reportingServices.servicesClient && reportingServices.servicesClient.includes(clientId?._id))
            && (reportingServices.servicesCurrency && reportingServices.servicesCurrency.includes(clientId?.preferred_currency || 'USD'))
            && trackEndDate.isBetween(startDate, endDate, null, '[]')
        ) {
            const preferredClientCurrency = clientId?.preferred_currency || 'USD';
            if (!processedProjects.has(projectId)) {
                totalBudget += convertCurrencyHelper(preferredClientCurrency, preferredCurrency, project.budget, forex);
                processedProjects.add(projectId);
            }
            // totalBudget += convertCurrencyHelper(preferredClientCurrency, preferredCurrency, project.budget, forex);

            // const userCost = user.cost || 0;
            const paymentCurrency = user?.payment_currency || 'USD';
            const projectSpecificCost = projectCost.find(cost => cost.userId === user?._id && cost.projectId === projectId && !cost.is_delete);
            // const userCost = projectSpecificCost && projectSpecificCost.amount > 0 ? projectSpecificCost.amount : (user.cost || 0);
            let userCost;
            if (projectSpecificCost && projectSpecificCost.amount > 0) {
                userCost = convertCurrencyHelper(
                    preferredClientCurrency,
                    paymentCurrency,
                    projectSpecificCost.amount,
                    forex
                );
            } else {
                userCost = user?.cost || 0;
            }
            const userOverheadCost = user?.overhead_cost || 0;
            const userTypeOfContract = user?.typeOfContract || typeOfContract?.Hourly_Rate;


            let finalCost;
            if (userTypeOfContract === typeOfContract.Hourly_Rate) {
                finalCost = userCost;
            } else {
                finalCost = userCost / parseInt(user?.monthly_hours ? user?.monthly_hours : 168); // Assuming a different type of contract with monthly rate divided by 168 hours
            }

            const cost = (finalCost * (duration / 3600)) + (userOverheadCost * (duration / 3600));
            totalCost += convertCurrencyHelper(paymentCurrency, preferredCurrency, cost, forex);
        }
    });

    const totalRoi = totalCost !== 0 ? (((totalBilledAmount - totalCost) / totalCost) * 100) : 0;

    return totalRoi.toFixed(2);
}

export function processedTotalHoursByService(trackReport, reportingServices) {
    const timeZone = global.config.getTimeZone();
    const firstDayOfYear = moment.tz(timeZone).startOf("year").toDate();
    const dateRange = reportingServices?.servicesDateRange || [firstDayOfYear, new Date()];
    const startDate = moment.tz(dateRange[0], timeZone);
    const endDate = moment.tz(dateRange[1], timeZone);

    const hoursByService = {};

    trackReport.forEach(entry => {
        const trackEndDate = moment.tz(entry?.endTime, timeZone);
        const tagId = entry?.tag?._id;
        const clientId = entry.clientId?._id;
        const clientPreferredCurrency = entry.clientId?.preferred_currency || 'USD';
        const serviceName = entry?.tag?.tag_name;

        if (
            tagId &&
            (reportingServices.servicesTag && reportingServices.servicesTag.includes(tagId)) &&
            clientId &&
            (reportingServices.servicesClient && reportingServices.servicesClient.includes(clientId)) &&
            trackEndDate.isBetween(startDate, endDate, null, '[]') &&
            (reportingServices.servicesCurrency && reportingServices.servicesCurrency.includes(clientPreferredCurrency))
        ) {
            if (!hoursByService[serviceName]) {
                hoursByService[serviceName] = {
                    name: serviceName,
                    closedAmount: 0 // Keep this as a numeric value
                };
            }
            hoursByService[serviceName].closedAmount += entry.duration;
        }
    });

    return Object.values(hoursByService);
}

export function processedTotalHoursByServiceFilter(
    trackReport,
    reportingServices,
    selectedDateRange,
    selectedComparison,
    selectedYearRange,
    selectedMonthRange,
    selectedComparisonYear,
    selectedComparisonMonth
) {
    const timeZone = global.config.getTimeZone();
    const getLastDayOfMonth = (year, month) => {
        return new Date(year, month, 0).getDate();
    };

    const getStartAndEndDates = (rangeId, year = new Date().getFullYear()) => {
        switch (rangeId) {
            case reportingFilterDateRangesConst.This_Year:
                return [
                    new Date(`${year}-01-01`),
                    new Date(`${year}-12-31`)
                ];

            case reportingFilterDateRangesConst.This_Quarter: {
                const quarter = Math.floor((new Date().getMonth() + 3) / 3);
                const startMonth = (quarter - 1) * 3 + 1;
                const endMonth = startMonth + 2;
                const endDay = getLastDayOfMonth(year, endMonth);
                return [
                    new Date(`${year}-${startMonth}-01`),
                    new Date(`${year}-${endMonth}-${endDay}`)
                ];
            }

            case reportingFilterDateRangesConst.First_Half:
                return [
                    new Date(`${year}-01-01`),
                    new Date(`${year}-06-30`)
                ];

            case reportingFilterDateRangesConst.This_Month: {
                const month = new Date().getMonth() + 1;
                const endDay = getLastDayOfMonth(year, month);
                return [
                    new Date(`${year}-${month}-01`),
                    new Date(`${year}-${month}-${endDay}`)
                ];
            }

            case reportingFilterDateRangesConst.Year_Selected:
                if (selectedYearRange) {
                    const selectedYear = selectedYearRange.getFullYear();
                    return [
                        new Date(`${selectedYear}-01-01`),
                        new Date(`${selectedYear}-12-31`)
                    ];
                } else {
                    return [
                        new Date(`${year}-01-01`),
                        new Date(`${year}-12-31`)
                    ];
                }

            case reportingFilterDateRangesConst.Month_Selected:
                if (selectedMonthRange) {
                    const selectedMonth = selectedMonthRange.getMonth() + 1;
                    const selectedYearForMonth = selectedMonthRange.getFullYear();
                    const endDay = getLastDayOfMonth(selectedYearForMonth, selectedMonth);
                    return [
                        new Date(`${selectedYearForMonth}-${selectedMonth}-01`),
                        new Date(`${selectedYearForMonth}-${selectedMonth}-${endDay}`)
                    ];
                } else {
                    // Fallback to the current month if selectedMonthRange is not provided
                    const currentMonth = new Date().getMonth() + 1;
                    const endDay = getLastDayOfMonth(year, currentMonth);
                    return [
                        new Date(`${year}-${currentMonth}-01`),
                        new Date(`${year}-${currentMonth}-${endDay}`)
                    ];
                }

            default:
                return [
                    new Date(`${year}-01-01`),
                    new Date(`${year}-12-31`)
                ];
        }
    };

    const getComparisonStartAndEndDates = (comparisonId, year = new Date().getFullYear()) => {
        switch (comparisonId) {
            case reportingFilterComparisonConst.Last_Year:
                return [
                    new Date(`${year - 1}-01-01`),
                    new Date(`${year - 1}-12-31`)
                ];

            case reportingFilterComparisonConst.Last_Quarter: {
                const quarter = Math.floor((new Date().getMonth() + 3) / 3);
                const lastQuarter = quarter === 1 ? 4 : quarter - 1;
                const startMonth = (lastQuarter - 1) * 3 + 1;
                const endMonth = startMonth + 2;
                const lastYear = quarter === 1 ? year - 1 : year;
                const endDay = getLastDayOfMonth(lastYear, endMonth);
                return [
                    new Date(`${lastYear}-${startMonth}-01`),
                    new Date(`${lastYear}-${endMonth}-${endDay}`)
                ];
            }

            case reportingFilterComparisonConst.Second_Half:
                return [
                    new Date(`${year}-07-01`),
                    new Date(`${year}-12-31`)
                ];

            case reportingFilterComparisonConst.Last_Month: {
                const lastMonth = new Date().getMonth(); // 0-indexed for previous month
                const lastYear = lastMonth === 0 ? year - 1 : year;
                const lastMonthFormatted = lastMonth === 0 ? 12 : lastMonth;
                const endDay = getLastDayOfMonth(lastYear, lastMonthFormatted);
                return [
                    new Date(`${lastYear}-${lastMonthFormatted}-01`),
                    new Date(`${lastYear}-${lastMonthFormatted}-${endDay}`)
                ];
            }

            case reportingFilterComparisonConst.Year_Select:
                if (selectedComparisonYear) {
                    const comparisonYear = selectedComparisonYear.getFullYear();
                    return [
                        new Date(`${comparisonYear}-01-01`),
                        new Date(`${comparisonYear}-12-31`)
                    ];
                } else {
                    return [
                        new Date(`${year - 1}-01-01`),
                        new Date(`${year - 1}-12-31`)
                    ];
                }

            case reportingFilterComparisonConst.Month_Select:
                if (selectedComparisonMonth) {
                    const comparisonMonth = selectedComparisonMonth.getMonth() + 1;
                    const comparisonYearForMonth = selectedComparisonMonth.getFullYear();
                    const endDay = getLastDayOfMonth(comparisonYearForMonth, comparisonMonth);
                    return [
                        new Date(`${comparisonYearForMonth}-${comparisonMonth}-01`),
                        new Date(`${comparisonYearForMonth}-${comparisonMonth}-${endDay}`)
                    ];
                } else {
                    const lastMonth = new Date()?.getMonth() || 12;
                    const fallbackYear = lastMonth === 12 ? year - 1 : year;
                    const endDay = getLastDayOfMonth(fallbackYear, lastMonth);
                    return [
                        new Date(`${fallbackYear}-${lastMonth}-01`),
                        new Date(`${fallbackYear}-${lastMonth}-${endDay}`)
                    ];
                }

            default:
                return [
                    new Date(`${year - 1}-01-01`),
                    new Date(`${year - 1}-12-31`)
                ];
        }
    };

    const filterByDateRange = (reports, startDate, endDate) => {
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(23, 59, 59, 999);
        return reports.filter(entry => {
            // const entryEndTime = new Date(entry.endTime);
            // entryEndTime >= startDate && entryEndTime <= endDate && 
            const trackEndDate = moment.tz(entry?.endTime, timeZone);
            const tagId = entry?.tag?._id;
            const clientId = entry.clientId?._id;
            const clientPreferredCurrency = entry.clientId?.preferred_currency || 'USD';
            return tagId &&
                (reportingServices.servicesTag && reportingServices.servicesTag.includes(tagId)) &&
                clientId &&
                (reportingServices.servicesClient && reportingServices.servicesClient.includes(clientId)) &&
                trackEndDate.isBetween(startDate, endDate, null, '[]') &&
                (reportingServices.servicesCurrency && reportingServices.servicesCurrency.includes(clientPreferredCurrency));
        });
    };

    const calculateTotalHoursByService = (reports) => {
        const hoursByService = {};
        reports.forEach(entry => {
            const serviceName = entry?.tag?.tag_name;
            if (serviceName) {
                if (!hoursByService[serviceName]) {
                    hoursByService[serviceName] = {
                        name: serviceName,
                        totalHours: 0
                    };
                }
                hoursByService[serviceName].totalHours += entry.duration;
            }
        });
        return Object.values(hoursByService);
    };

    const [startCurrentRange, endCurrentRange] = getStartAndEndDates(selectedDateRange);
    const [startComparisonRange, endComparisonRange] = getComparisonStartAndEndDates(selectedComparison);

    const filteredCurrentPeriod = filterByDateRange(trackReport, startCurrentRange, endCurrentRange);
    const filteredComparisonPeriod = filterByDateRange(trackReport, startComparisonRange, endComparisonRange);

    const currentPeriodHours = calculateTotalHoursByService(filteredCurrentPeriod);
    const comparisonPeriodHours = calculateTotalHoursByService(filteredComparisonPeriod);

    // const combinedHours = currentPeriodHours.map(current => {
    //     const comparisonData = comparisonPeriodHours.find(comp => comp.name === current.name) || { totalHours: 0 };
    //     return {
    //         name: current.name,
    //         currentPeriodHours: parseFloat(current.totalHours) || 0,
    //         comparisonPeriodHours: parseFloat(comparisonData.totalHours) || 0
    //     };
    // });

    // return combinedHours;
    const calculateCombinedHours = (currentPeriodHours, comparisonPeriodHours) => {
        const combinedHours = {};

        currentPeriodHours.forEach(current => {
            const serviceName = current.name;
            combinedHours[serviceName] = {
                name: serviceName,
                currentPeriodHours: parseFloat(current.totalHours) || 0,
                comparisonPeriodHours: 0 // Default to 0 until filled by comparison data
            };
        });

        comparisonPeriodHours.forEach(comparison => {
            const serviceName = comparison.name;
            if (!combinedHours[serviceName]) {
                combinedHours[serviceName] = {
                    name: serviceName,
                    currentPeriodHours: 0,
                    comparisonPeriodHours: parseFloat(comparison.totalHours) || 0
                };
            } else {
                combinedHours[serviceName].comparisonPeriodHours = parseFloat(comparison.totalHours) || 0;
            }
        });

        return Object.values(combinedHours);
    };

    const combinedHours = calculateCombinedHours(currentPeriodHours, comparisonPeriodHours);

    return combinedHours;
}

export function processedCostByService(trackReport, projectCost, reportingServices, preferredCurrency, forex, typeOfContract) {
    const timeZone = global.config.getTimeZone();
    const firstDayOfYear = moment.tz(timeZone).startOf("year").toDate();
    const dateRange = reportingServices?.servicesDateRange || [firstDayOfYear, new Date()];
    const startDate = moment.tz(dateRange[0], timeZone);
    const endDate = moment.tz(dateRange[1], timeZone);

    const costByService = {};

    trackReport.forEach(entry => {
        const { project, clientId, user, tag, endTime, duration } = entry;
        const trackEndDate = moment.tz(endTime, timeZone);
        const tagId = tag && tag._id;
        const serviceName = tag?.tag_name;
        const clientPreferredCurrency = clientId?.preferred_currency || 'USD';

        if (
            project &&
            !project.is_delete &&
            tagId &&
            (reportingServices.servicesTag && reportingServices.servicesTag.includes(tagId)) &&
            clientId &&
            (reportingServices.servicesClient && reportingServices.servicesClient.includes(clientId?._id)) &&
            trackEndDate.isBetween(startDate, endDate, null, '[]') &&
            (reportingServices.servicesCurrency && reportingServices.servicesCurrency.includes(clientPreferredCurrency))
        ) {
            if (!costByService[serviceName]) {
                costByService[serviceName] = {
                    name: serviceName,
                    totalCost: 0
                };
            }

            const paymentCurrency = user?.payment_currency || 'USD';
            const preferredClientCurrency = clientId?.preferred_currency || 'USD';
            const projectSpecificCost = projectCost.find(cost => cost.userId === user?._id && cost.projectId === project?._id && !cost.is_delete);
            // const userCost = projectSpecificCost && projectSpecificCost.amount > 0 ? projectSpecificCost.amount : (user.cost || 0);
            let userCost;
            if (projectSpecificCost && projectSpecificCost.amount > 0) {
                userCost = convertCurrencyHelper(
                    preferredClientCurrency,
                    paymentCurrency,
                    projectSpecificCost.amount,
                    forex
                );
            } else {
                userCost = user?.cost || 0;
            }
            const userOverheadCost = user?.overhead_cost || 0;
            const userTypeOfContract = user?.typeOfContract || typeOfContract.Hourly_Rate;

            let finalCost;
            if (userTypeOfContract === typeOfContract.Hourly_Rate) {
                finalCost = userCost;
            } else {
                finalCost = userCost / parseInt(user?.monthly_hours ? user?.monthly_hours : 168); // Assuming a different type of contract with a monthly rate divided by 168 hours
            }

            const cost = (finalCost * (duration / 3600)) + (userOverheadCost * (duration / 3600));
            const convertedCost = convertCurrencyHelper(paymentCurrency, preferredCurrency, cost, forex);
            costByService[serviceName].totalCost += convertedCost;
        }
    });

    return Object.values(costByService).map(service => ({
        ...service,
        totalCost: parseFloat(service.totalCost).toFixed(2)
    }));
}

export function processedCostByServiceFilter(
    trackReport,
    projectCost,
    reportingServices,
    preferredCurrency,
    forex,
    typeOfContract,
    selectedDateRange,
    selectedComparison,
    selectedYearRange,
    selectedMonthRange,
    selectedComparisonYear,
    selectedComparisonMonth
) {
    const timeZone = global.config.getTimeZone();
    const getLastDayOfMonth = (year, month) => {
        return new Date(year, month, 0).getDate();
    };

    const getStartAndEndDates = (rangeId, year = new Date().getFullYear()) => {
        switch (rangeId) {
            case reportingFilterDateRangesConst.This_Year:
                return [
                    new Date(`${year}-01-01`),
                    new Date(`${year}-12-31`)
                ];

            case reportingFilterDateRangesConst.This_Quarter: {
                const quarter = Math.floor((new Date().getMonth() + 3) / 3);
                const startMonth = (quarter - 1) * 3 + 1;
                const endMonth = startMonth + 2;
                const endDay = getLastDayOfMonth(year, endMonth);
                return [
                    new Date(`${year}-${startMonth}-01`),
                    new Date(`${year}-${endMonth}-${endDay}`)
                ];
            }

            case reportingFilterDateRangesConst.First_Half:
                return [
                    new Date(`${year}-01-01`),
                    new Date(`${year}-06-30`)
                ];

            case reportingFilterDateRangesConst.This_Month: {
                const month = new Date().getMonth() + 1;
                const endDay = getLastDayOfMonth(year, month);
                return [
                    new Date(`${year}-${month}-01`),
                    new Date(`${year}-${month}-${endDay}`)
                ];
            }

            case reportingFilterDateRangesConst.Year_Selected:
                if (selectedYearRange) {
                    const selectedYear = selectedYearRange.getFullYear();
                    return [
                        new Date(`${selectedYear}-01-01`),
                        new Date(`${selectedYear}-12-31`)
                    ];
                } else {
                    return [
                        new Date(`${year}-01-01`),
                        new Date(`${year}-12-31`)
                    ];
                }

            case reportingFilterDateRangesConst.Month_Selected:
                if (selectedMonthRange) {
                    const selectedMonth = selectedMonthRange.getMonth() + 1;
                    const selectedYearForMonth = selectedMonthRange.getFullYear();
                    const endDay = getLastDayOfMonth(selectedYearForMonth, selectedMonth);
                    return [
                        new Date(`${selectedYearForMonth}-${selectedMonth}-01`),
                        new Date(`${selectedYearForMonth}-${selectedMonth}-${endDay}`)
                    ];
                } else {
                    // Fallback to the current month if selectedMonthRange is not provided
                    const currentMonth = new Date().getMonth() + 1;
                    const endDay = getLastDayOfMonth(year, currentMonth);
                    return [
                        new Date(`${year}-${currentMonth}-01`),
                        new Date(`${year}-${currentMonth}-${endDay}`)
                    ];
                }

            default:
                return [
                    new Date(`${year}-01-01`),
                    new Date(`${year}-12-31`)
                ];
        }
    };

    const getComparisonStartAndEndDates = (comparisonId, year = new Date().getFullYear()) => {
        switch (comparisonId) {
            case reportingFilterComparisonConst.Last_Year:
                return [
                    new Date(`${year - 1}-01-01`),
                    new Date(`${year - 1}-12-31`)
                ];

            case reportingFilterComparisonConst.Last_Quarter: {
                const quarter = Math.floor((new Date().getMonth() + 3) / 3);
                const lastQuarter = quarter === 1 ? 4 : quarter - 1;
                const startMonth = (lastQuarter - 1) * 3 + 1;
                const endMonth = startMonth + 2;
                const lastYear = quarter === 1 ? year - 1 : year;
                const endDay = getLastDayOfMonth(lastYear, endMonth);
                return [
                    new Date(`${lastYear}-${startMonth}-01`),
                    new Date(`${lastYear}-${endMonth}-${endDay}`)
                ];
            }

            case reportingFilterComparisonConst.Second_Half:
                return [
                    new Date(`${year}-07-01`),
                    new Date(`${year}-12-31`)
                ];

            case reportingFilterComparisonConst.Last_Month: {
                const lastMonth = new Date().getMonth(); // 0-indexed for previous month
                const lastYear = lastMonth === 0 ? year - 1 : year;
                const lastMonthFormatted = lastMonth === 0 ? 12 : lastMonth;
                const endDay = getLastDayOfMonth(lastYear, lastMonthFormatted);
                return [
                    new Date(`${lastYear}-${lastMonthFormatted}-01`),
                    new Date(`${lastYear}-${lastMonthFormatted}-${endDay}`)
                ];
            }

            case reportingFilterComparisonConst.Year_Select:
                if (selectedComparisonYear) {
                    const comparisonYear = selectedComparisonYear.getFullYear();
                    return [
                        new Date(`${comparisonYear}-01-01`),
                        new Date(`${comparisonYear}-12-31`)
                    ];
                } else {
                    return [
                        new Date(`${year - 1}-01-01`),
                        new Date(`${year - 1}-12-31`)
                    ];
                }

            case reportingFilterComparisonConst.Month_Select:
                if (selectedComparisonMonth) {
                    const comparisonMonth = selectedComparisonMonth.getMonth() + 1;
                    const comparisonYearForMonth = selectedComparisonMonth.getFullYear();
                    const endDay = getLastDayOfMonth(comparisonYearForMonth, comparisonMonth);
                    return [
                        new Date(`${comparisonYearForMonth}-${comparisonMonth}-01`),
                        new Date(`${comparisonYearForMonth}-${comparisonMonth}-${endDay}`)
                    ];
                } else {
                    const lastMonth = new Date().getMonth() || 12;
                    const fallbackYear = lastMonth === 12 ? year - 1 : year;
                    const endDay = getLastDayOfMonth(fallbackYear, lastMonth);
                    return [
                        new Date(`${fallbackYear}-${lastMonth}-01`),
                        new Date(`${fallbackYear}-${lastMonth}-${endDay}`)
                    ];
                }

            default:
                return [
                    new Date(`${year - 1}-01-01`),
                    new Date(`${year - 1}-12-31`)
                ];
        }
    };

    const filterByDateRange = (reports, startDate, endDate) => {
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(23, 59, 59, 999);
        return reports.filter(entry => {
            const trackEndDate = moment.tz(entry?.endTime, timeZone);
            const tagId = entry?.tag?._id;
            const clientId = entry.clientId?._id;
            const clientPreferredCurrency = entry.clientId?.preferred_currency || 'USD';
            return entry?.project && !entry?.project.is_delete &&
                tagId &&
                (reportingServices.servicesTag && reportingServices.servicesTag.includes(tagId)) &&
                clientId &&
                (reportingServices.servicesClient && reportingServices.servicesClient.includes(clientId)) &&
                trackEndDate.isBetween(startDate, endDate, null, '[]') &&
                (reportingServices.servicesCurrency && reportingServices.servicesCurrency.includes(clientPreferredCurrency));
        });
    };

    const calculateCostByService = (reports) => {
        const costByService = {};
        reports.forEach(entry => {
            const serviceName = entry?.tag?.tag_name;
            const paymentCurrency = entry.user?.payment_currency || 'USD';
            const preferredClientCurrency = entry.clientId.preferred_currency || 'USD';
            const projectSpecificCost = projectCost.find(cost => cost.userId === entry.user?._id && cost.projectId === entry.project?._id && !cost.is_delete);
            // const userCost = projectSpecificCost && projectSpecificCost.amount > 0 ? projectSpecificCost.amount : (entry.user?.cost || 0);
            let userCost;
            if (projectSpecificCost && projectSpecificCost.amount > 0) {
                userCost = convertCurrencyHelper(
                    preferredClientCurrency,
                    paymentCurrency,
                    projectSpecificCost.amount,
                    forex
                );
            } else {
                userCost = entry.user?.cost || 0;
            }
            const userOverheadCost = entry.user?.overhead_cost || 0;
            const userTypeOfContract = entry.user?.typeOfContract || typeOfContract.Hourly_Rate;

            let finalCost;
            if (userTypeOfContract === typeOfContract.Hourly_Rate) {
                finalCost = userCost;
            } else {
                finalCost = userCost / parseInt(entry.user?.monthly_hours || 168);
            }

            const cost = (finalCost * (entry.duration / 3600)) + (userOverheadCost * (entry.duration / 3600));
            const convertedCost = convertCurrencyHelper(paymentCurrency, preferredCurrency, cost, forex);

            if (!costByService[serviceName]) {
                costByService[serviceName] = {
                    name: serviceName,
                    totalCost: 0
                };
            }

            costByService[serviceName].totalCost += convertedCost;
        });

        return Object.values(costByService).map(service => ({
            ...service,
            totalCost: parseFloat(service.totalCost).toFixed(2)
        }));
    };

    const [startCurrentRange, endCurrentRange] = getStartAndEndDates(selectedDateRange);
    const [startComparisonRange, endComparisonRange] = getComparisonStartAndEndDates(selectedComparison);

    const filteredCurrentPeriod = filterByDateRange(trackReport, startCurrentRange, endCurrentRange);
    const filteredComparisonPeriod = filterByDateRange(trackReport, startComparisonRange, endComparisonRange);

    const currentPeriodCost = calculateCostByService(filteredCurrentPeriod);
    const comparisonPeriodCost = calculateCostByService(filteredComparisonPeriod);

    const calculateCombinedCosts = (currentPeriodCost, comparisonPeriodCost) => {
        const combinedCost = {};

        currentPeriodCost.forEach(current => {
            combinedCost[current.name] = {
                name: current.name,
                currentPeriodCost: parseFloat(current?.totalCost) || 0,
                comparisonPeriodCost: 0
            };
        });

        comparisonPeriodCost.forEach(comparison => {
            if (!combinedCost[comparison.name]) {
                combinedCost[comparison.name] = {
                    name: comparison.name,
                    currentPeriodCost: 0,
                    comparisonPeriodCost: parseFloat(comparison?.totalCost) || 0
                };
            } else {
                combinedCost[comparison.name].comparisonPeriodCost = parseFloat(comparison.totalCost) || 0;
            }
        });

        return Object.values(combinedCost);
    };

    const combinedCosts = calculateCombinedCosts(currentPeriodCost, comparisonPeriodCost);

    return combinedCosts;
}