import moment from 'moment-timezone';
import { useSelector } from "react-redux";
import React, { useEffect, useState } from 'react';
import payStatusConsts from "../../../constants/invPaymetConsts";
import invStatusConsts from '../../../constants/invStatusConsts';

const PaymentStatus = ({ inv_payment_details, invoiceId, invData , paymentStatus  }) => {
    const [paymentDate, setPaymentDate] = useState(null);
    const [paymentData, setPaymentData] = useState(null);
    const [statusClass, setStatusClass] = useState(null);
    const [statusName, setStatusName] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    let token = null;
    const { user } = useSelector((state) => state.auth);
    const { appSettings } = useSelector((state) => state.appSettings);
    if (user) {

        if (user && user.token) {
            token = user.token;
        }
    }

    // useEffect(() => {

    //     const fetchPaymentDetails = async () => {
    //         try {
    //             setIsLoading(true);
    //             const response = await fetch('/api/invoices/paymentDetails', {
    //                 method: 'POST',
    //                 body: JSON.stringify({ _id: invData._id }),
    //                 headers: {
    //                     'Content-Type': 'application/json',
    //                     Authorization: `Bearer ${token}`,
    //                 },
    //             });

    //             if (response.ok) {
    //                 const data = await response.json();
    //                 if (data) {
    //                     setPaymentData(data);
    //                     const createdDate = data.created_at;
    //                     setPaymentDate(createdDate);
    //                 }
    //             } else {
    //                 // Handle error case
    //                 console.error('Failed to fetch payment details');
    //             }
    //         } catch (error) {
    //             global.config.slackMessage(error.toString());
    //             console.error('Error fetching payment details:', error);
    //         } finally {
    //             setIsLoading(false); // Hide loading indicator
    //         }
    //     };

    //     if (invData._id && paymentStatus===1) {
    //         fetchPaymentDetails();
    //     }
    // }, [inv_payment_details]);

    useEffect(() => {
        const fetchPaymentDetails = async () => {
          try {
            setIsLoading(true);
      
            // Wrap fetch call in a Promise.resolve().then() to ensure non-blocking behavior
            Promise.resolve().then(async () => {
              const response = await fetch('/api/invoices/paymentDetails', {
                method: 'POST',
                body: JSON.stringify({ _id: invData._id }),
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${token}`,
                },
              });
      
              if (response.ok) {
                const data = await response.json();
                if (data) {
                  setPaymentData(data);
                  const createdDate = data.created_at;
                  setPaymentDate(createdDate);
                }
              } else {
                // Handle error case
                console.error('Failed to fetch payment details');
              }
            });
          } catch (error) {
            global.config.slackMessage(error.toString());
            console.error('Error fetching payment details:', error);
          } finally {
            setIsLoading(false); // Hide loading indicator
          }
        };
      
        if (invData._id && paymentStatus === 1) {
          // Call the asynchronous fetchPaymentDetails function in a non-blocking way
          Promise.resolve().then(() => fetchPaymentDetails());
        }
      }, [inv_payment_details]);

    // useEffect(() => {

    //     const fetchData = async () => {
    //         try {
    //             setIsLoading(true);
    //             const response = await fetch('/api/wise/payment/status-update', {
    //                 method: 'POST',
    //                 body: JSON.stringify(
    //                     {
    //                         profileId: paymentData.wise_profile_id,
    //                         accountId: paymentData.bank_details,
    //                         invoiceId: paymentData.inv_number,
    //                         date: paymentData.created_at
    //                     }
    //                 ),
    //                 headers: {
    //                     'Content-Type': 'application/json',
    //                     Authorization: `Bearer ${token}`,
    //                 },
    //             });

    //             if (response.ok) {
    //                 const data = await response.json();
    //                 if (data.status === "outgoing_payment_sent") {
    //                     try {
    //                         const response = await fetch(`/api/update/invoice/paymentStatus/${paymentData.inv_number}`, {
    //                             method: 'GET',
    //                             headers: {
    //                                 'Content-Type': 'application/json',
    //                                 Authorization: `Bearer ${token}`,
    //                             },
    //                         });

    //                         if (response.ok) {
    //                             setStatusClass("sucessfully-processed");
    //                             setStatusName("Payment Processed");
    //                         } else {
    //                             // Handle error case
    //                             console.error('Failed to update invoice payment status');
    //                         }
    //                     } catch (error) {
    //                         global.config.slackMessage(error.toString());
    //                         console.error('Error updating invoice payment status:', error);
    //                     }
    //                 }
    //                 else if (data.status === "funds_refunded") {
    //                     try {
    //                         // Call the API to cancel the invoice payment status
    //                         const response = await fetch(`/api/refund/invoice/paymentStatus/${paymentData.inv_number}`, {
    //                             method: 'GET',
    //                             headers: {
    //                                 'Content-Type': 'application/json',
    //                                 Authorization: `Bearer ${token}`,
    //                             },
    //                         });

    //                         if (response.ok) {
    //                             setStatusClass("sucessfully-processed");
    //                             setStatusName("Refunded");
    //                         } else {
    //                             // Handle error case
    //                             console.error('Failed to cancel invoice payment status');
    //                         }
    //                     } catch (error) {
    //                         global.config.slackMessage(error.toString());
    //                         console.error('Error canceling invoice payment status:', error);
    //                     }
    //                 }
    //                 else if (data.status === "cancelled") {
    //                     try {
    //                         // Call the API to cancel the invoice payment status
    //                         const response = await fetch(`/api/cancel/invoice/paymentStatus/${paymentData.inv_number}`, {
    //                             method: 'GET',
    //                             headers: {
    //                                 'Content-Type': 'application/json',
    //                                 Authorization: `Bearer ${token}`,
    //                             },
    //                         });

    //                         if (response.ok) {
    //                             // Update your component state or perform any actions as needed
    //                             // For example, you can update the status to "Cancelled" here
    //                             setStatusClass("overdue");
    //                             setStatusName("Cancelled");
    //                         } else {
    //                             // Handle error case
    //                             console.error('Failed to cancel invoice payment status');
    //                         }
    //                     } catch (error) {
    //                         global.config.slackMessage(error.toString());
    //                         console.error('Error canceling invoice payment status:', error);
    //                     }
    //                 }
    //             } else {
    //                 // Handle error case
    //                 console.error('Failed to fetch payment details');
    //             }
    //         } catch (error) {
    //             console.error('Error fetching payment details:', error);
    //             global.config.slackMessage(error.toString());
    //         } finally {
    //             setIsLoading(false); // Hide loading indicator
    //         }
    //     }
    //     if(paymentStatus===1){
            
    //         if (paymentData && typeof paymentData.in_wise !== 'undefined') {
    //             fetchData();
    //         }

    //     }
       

    // }, [paymentData]);

    useEffect(() => {
        const fetchData = async () => {
          try {
            setIsLoading(true);
      
            // Use Promise.resolve().then() to make fetchData non-blocking
            Promise.resolve().then(async () => {
              const response = await fetch('/api/wise/payment/status-update', {
                method: 'POST',
                body: JSON.stringify({
                  profileId: paymentData.wise_profile_id,
                  accountId: paymentData.bank_details,
                  invoiceId: paymentData.inv_number,
                  date: paymentData.created_at,
                }),
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${token}`,
                },
              });
      
              if (response.ok) {
                const data = await response.json();
      
                if (data.status === "outgoing_payment_sent") {
                  await handlePaymentProcessed();
                } else if (data.status === "funds_refunded") {
                  await handleRefunded();
                } else if (data.status === "cancelled") {
                  await handleCancelled();
                }
              } else {
                console.error('Failed to fetch payment details');
              }
            });
          } catch (error) {
            console.error('Error fetching payment details:', error);
            global.config.slackMessage(error.toString());
          } finally {
            setIsLoading(false);
          }
        };
      
        const handlePaymentProcessed = async () => {
          try {
            const response = await fetch(`/api/update/invoice/paymentStatus/${paymentData.inv_number}`, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
              },
            });
      
            if (response.ok) {
              setStatusClass("sucessfully-processed");
              setStatusName("Payment Processed");
            } else {
              console.error('Failed to update invoice payment status');
            }
          } catch (error) {
            console.error('Error updating invoice payment status:', error);
            global.config.slackMessage(error.toString());
          }
        };
      
        const handleRefunded = async () => {
          try {
            const response = await fetch(`/api/refund/invoice/paymentStatus/${paymentData.inv_number}`, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
              },
            });
      
            if (response.ok) {
              setStatusClass("sucessfully-processed");
              setStatusName("Refunded");
            } else {
              console.error('Failed to cancel invoice payment status');
            }
          } catch (error) {
            console.error('Error canceling invoice payment status:', error);
            global.config.slackMessage(error.toString());
          }
        };
      
        const handleCancelled = async () => {
          try {
            const response = await fetch(`/api/cancel/invoice/paymentStatus/${paymentData.inv_number}`, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
              },
            });
      
            if (response.ok) {
              setStatusClass("overdue");
              setStatusName("Cancelled");
            } else {
              console.error('Failed to cancel invoice payment status');
            }
          } catch (error) {
            console.error('Error canceling invoice payment status:', error);
            global.config.slackMessage(error.toString());
          }
        };
      
        if (paymentStatus === 1) {
          if (paymentData && typeof paymentData.in_wise !== 'undefined') {
            // Call fetchData in a non-blocking manner
            Promise.resolve().then(() => fetchData());
          }
        }
      }, [paymentData]);


    // useEffect(() => {
    //     setIsLoading(true);
    //     if (invData.inv_payment_status === 2) {
    //         setStatusClass("manual");
    //         setStatusName("Manually processed");
    //     }
    //     else if (invData.inv_status === invStatusConsts.Rejected) {
    //         setStatusClass("overdue");
    //         setStatusName("Rejected");
    //     } else if (invData.inv_payment_status === payStatusConsts.Payment_Processing) {
    //         setStatusClass("payment-processeing");
    //         setStatusName("Payment Processing");
    //     } else if (invData.inv_payment_status === payStatusConsts.Pending) {
    //         const startDate = moment().tz(global.config.getTimeZone());
    //         const endDate = moment(invData.inv_due_date).tz(global.config.getTimeZone());
    //         const diffInDays = endDate.diff(startDate, "days");
    //         if (diffInDays < 0) {
    //             setStatusClass("overdue");
    //             setStatusName("Overdue");
    //         } else {
    //             setStatusClass("pending");
    //             setStatusName("Pending");
    //         }
    //     } else if (invData.inv_payment_status === payStatusConsts.Manually_Processed) {
    //         setStatusClass("manual");
    //         setStatusName("Manually processed");
    //     } else if (invData.inv_payment_status === payStatusConsts.Sucessfully_Processed) {
    //         setStatusClass("sucessfully-processed");
    //         setStatusName("Payment Processed");
    //     } else if (invData.inv_payment_status === payStatusConsts.Cancelled) {
    //         setStatusClass("overdue");
    //         setStatusName("Cancelled");;
    //     } else if (invData.inv_payment_status === payStatusConsts.Refunded) {
    //         setStatusClass("payment-processeing");
    //         setStatusName("Refunded");;
    //     }
    //     setIsLoading(false);
    // }, [invData, paymentData]);


    useEffect(() => {
        const updateStatus = () => {
          setIsLoading(true);
      
          Promise.resolve().then(() => {
            if (invData.inv_payment_status === 2) {
              setStatusClass("manual");
              setStatusName("Manually processed");
            } else if (invData.inv_status === invStatusConsts.Rejected) {
              setStatusClass("overdue");
              setStatusName("Rejected");
            } else if (invData.inv_payment_status === payStatusConsts.Payment_Processing) {
              setStatusClass("payment-processeing");
              setStatusName("Payment Processing");
            } else if (invData.inv_payment_status === payStatusConsts.Pending) {
              const startDate = moment().tz(global.config.getTimeZone());
              const endDate = moment(invData.inv_due_date).tz(global.config.getTimeZone());
              const diffInDays = endDate.diff(startDate, "days");
      
              if (diffInDays < 0) {
                setStatusClass("overdue");
                setStatusName("Overdue");
              } else {
                setStatusClass("pending");
                setStatusName("Pending");
              }
            } else if (invData.inv_payment_status === payStatusConsts.Manually_Processed) {
              setStatusClass("manual");
              setStatusName("Manually processed");
            } else if (invData.inv_payment_status === payStatusConsts.Sucessfully_Processed) {
              setStatusClass("sucessfully-processed");
              setStatusName("Payment Processed");
            } else if (invData.inv_payment_status === payStatusConsts.Cancelled) {
              setStatusClass("overdue");
              setStatusName("Cancelled");
            } else if (invData.inv_payment_status === payStatusConsts.Refunded) {
              setStatusClass("payment-processeing");
              setStatusName("Refunded");
            }
      
            setIsLoading(false);
          });
        };
      
        updateStatus();
      }, [invData, paymentData]);



    return <span className={`payment-status ${statusClass}`}> {isLoading ? "Loading..." : global.config.locate(appSettings && appSettings.language, statusName)}</span>;
};

export default PaymentStatus;