const expenseCreateData = async (autoData) => {
    var expense = autoData;
    localStorage.setItem('expenseCreate', JSON.stringify(autoData))
    return expense;
}

const expenseService = {
    expenseCreateData: expenseCreateData,
}

export default expenseService;