import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ThreeDots } from "react-loader-spinner";
import UserIcon from "../../../../images/user/rey.svg";
import profileImage from "../../../../images/user/Default_pfp.png";
import TruncateUserName from '../../../helpers/truncateUserName';

// import 'primereact/resources/themes/saga-blue/theme.css';
// import 'primereact/resources/primereact.min.css';
// import 'primeicons/primeicons.css';


const ClientDataTable = ({ clients, clientLoading }) => {
    const reportingClient = useSelector((state) => state.reportingClient);
    const [filteredData, setFilteredData] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);

    useEffect(() => {
        const filterClients = () => {
          const filteredClients = clients.filter((client) => {
            const isClientDetailMatch =
              !reportingClient.clientDetails || reportingClient.clientDetails.includes(client._id);
            const isAccountManagerMatch =
              !reportingClient.clientAccountManager ||
              (client.acc_manager && reportingClient.clientAccountManager.includes(client.acc_manager._id));
            const isStatusMatch =
              !reportingClient.clientStatus || reportingClient.clientStatus.includes(client.is_active);
            return isClientDetailMatch && isAccountManagerMatch && isStatusMatch;
          });
      
          setFilteredData(filteredClients);
        };
      
        // Use setTimeout for non-blocking execution
        const timeoutId = setTimeout(() => {
          filterClients();
        }, 0);
      
        return () => clearTimeout(timeoutId); // Cleanup timeout
      }, [clients, reportingClient]);

    const onPageChange = (e) => {
        setCurrentPage(e.first / rowsPerPage);
    };

    const renderAccountManager = (rowData) => {
        const accountManager = rowData.acc_manager;
        const fullName = accountManager.first_name ? accountManager.first_name +
            (accountManager.middle_name && accountManager.middle_name !== "null" ? " " + accountManager.middle_name : "") +
            " " +
            accountManager.last_name
            : accountManager.company_name;
        return (
            <div className='member-name' style={{ display: 'flex', alignItems: 'center' }}>
                {/* <img src={UserIcon} alt={accountManager.first_name} style={{ width: '30px', height: '30px', borderRadius: '50%', marginRight: '10px' }} /> */}
                {accountManager.profile_image !== null && accountManager.profile_image !== "null" ? (
                    <img
                        src={`/uploads/lowProfileImage/${accountManager.profile_image}`}
                        alt={fullName}
                        className="member-image"
                        onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = '/uploads/lowProfileImage/user_default.png';
                        }}
                    />
                ) : (
                    <img src={profileImage} alt={fullName} className="member-image" />
                )}
                <TruncateUserName
                    textContent={accountManager.first_name ? (
                        <>
                            {accountManager.first_name}{' '}
                            {accountManager.middle_name && accountManager.middle_name !== 'null' ? accountManager.middle_name + ' ' : ''}
                            {accountManager.last_name}{' '}
                        </>
                    ) : (
                        <>
                            {accountManager.company_first_name
                                ? `${accountManager.company_first_name} ${accountManager.company_last_name ? accountManager.company_last_name : ''}`
                                : `${accountManager.company_name || ''}(Company)`}
                        </>
                    )} />
            </div>
        );
    };

    const renderStatus = (rowData) => {
        const status = rowData.is_active ? 'Active' : 'Inactive';
        const statusClass = status === 'Active' ? 'payment-status manual' : 'payment-status inactive';
        return <span className={`client-status ${statusClass}`}>{status}</span>;
    };

    return (
        <>
            <div className="datatable-team-maintb">
                <DataTable
                    value={filteredData.slice(0, 10)}
                    sort
                    tableStyle={{ minWidth: '50rem', width: '100%', textAlign: 'left' }}
                    paginator={false}
                    // paginator={filteredData.length > 10}
                    paginatorClassName="recent-invoices-pagination"
                    rows={rowsPerPage}
                    // first={currentPage * rowsPerPage}
                    // onPage={onPageChange}
                    emptyMessage={clientLoading ? (
                        <div className="outter-load-table">
                          <ThreeDots
                            height="80"
                            width="80"
                            radius="9"
                            color="#405BFF"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                          />
                        </div>
                      ) : "No client invoice data found"}
                >
                    <Column
                        field="cli_inv_for.client_name"
                        header="Client Name"
                        body={(rowData) => rowData.client_name || rowData.legal_entity_name}
                        sortable
                        style={{ width: '30%', paddingRight: '20px' }}
                    ></Column>
                    <Column
                        field="cli_inv_for.acc_manager"
                        body={renderAccountManager}
                        header="Account Manager"
                        sortable
                        style={{ width: '30%', paddingRight: '20px' }}
                    ></Column>
                    <Column
                        field="cli_inv_for.is_active"
                        body={renderStatus}
                        header="Client Status"
                        sortable
                        style={{ width: '10%', paddingRight: '20px' }}
                    ></Column>
                </DataTable>
            </div>
        </>
    );
};

export default ClientDataTable;
