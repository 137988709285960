import React, { PureComponent, useMemo } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { ThreeDots } from "react-loader-spinner";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ReferenceLine, ResponsiveContainer, LabelList } from 'recharts';
import typeOfContract from '../../../../constants/typeOfContract';
import { processedRevenueByClient } from './Services/reportRevenue';

const data = [
  { name: 'United States', closedAmount: 44 },
  { name: 'Belgium', closedAmount: 55 },
  { name: 'Panama', closedAmount: 13 },
  { name: 'Germany', closedAmount: 43 },
  { name: 'Netherlands', closedAmount: 46 },
  { name: 'Mexico', closedAmount: 55 },
  { name: 'Country G', closedAmount: 32 },
];

const RevenuePerClientGraph = ({ clientInvoices, clientInvoicesLoading, clientInvoicesError, trackReport, trackReportLoading, trackReportError, projectCost, expenses }) => {
  const reportingRevenue = useSelector((state) => state.reportingRevenue);
  const { forex } = useSelector((state) => state.forex);
  const settings = JSON.parse(localStorage.getItem("settings"));

  const setReportingCurrency = useSelector((state) => state.reportingRevenue.revenueCurrency);
  var currencies = "USD";
  if (setReportingCurrency && setReportingCurrency.length === 1) {
    currencies = setReportingCurrency[0];
  } else {
    currencies = settings.currency;
  }

  const processedData = useMemo(() => {
    return processedRevenueByClient(
      trackReport,
      projectCost,
      clientInvoices,
      reportingRevenue,
      currencies,
      forex,
      typeOfContract,
      expenses
    );
  }, [trackReport, projectCost, clientInvoices, reportingRevenue, currencies, forex, typeOfContract, expenses]);

  if (clientInvoicesLoading || trackReportLoading) return (
    <div className="outter-load-table">
      <ThreeDots
        height="80"
        width="80"
        radius="9"
        color="#6479f9"
        ariaLabel="three-dots-loading"
        visible={true}
      />
    </div>
  );

  if (clientInvoicesError || trackReportError) return <div>Error loading data.</div>;

  const calculateXTicks = () => {
    const dataValues = processedData.map(item => item.closedAmount);
    const maxDataValue = Math.max(...dataValues);
    const minDataValue = Math.min(...dataValues);

    // Determine if there are negative values
    const hasNegativeValues = minDataValue < 0;

    // Determine tick interval
    const tickInterval = Math.ceil(Math.max(Math.abs(maxDataValue), Math.abs(minDataValue)) / 10);
    const numOfTicks = Math.min(Math.ceil(Math.max(Math.abs(maxDataValue), Math.abs(minDataValue)) / tickInterval), 10);
    const ticks = [];

    // Generate ticks based on whether there are negative values
    if (hasNegativeValues) {
      const extraNegativeStep = tickInterval;
      for (let i = -numOfTicks - 1; i <= numOfTicks; i++) {
        ticks.push(i * tickInterval);
      }
      ticks.unshift(ticks[0] - extraNegativeStep);
    } else {
      for (let i = 0; i <= numOfTicks; i++) {
        ticks.push(i * tickInterval);
      }
    }

    return ticks;
  }

  const xAxisTicks = calculateXTicks();

  const calculateChartHeight = (dataLength) => {
    const rowHeight = 50;
    const baseHeight = 100;
    return dataLength * rowHeight + baseHeight;
  };

  const calculateLeftMargin = (data) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.font = '12px Arial';

    const longestWordLength = data.reduce((maxLength, item) => {
      const firstWord = item?.name?.split(' ')[0];
      const wordWidth = context.measureText(firstWord).width;
      return Math.max(maxLength, wordWidth);
    }, 0);

    return Math.max(10, longestWordLength - 150);
  };

  const leftMargin = calculateLeftMargin(processedData);

  return (
    <div className='graph-project-per-hour' style={{ width: '100%', height: Math.max(542, calculateChartHeight(processedData.length)), }}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          layout="vertical"
          data={processedData}
          barSize={13} // Set the height of each bar
          margin={{
            top: 20,
            right: 42,
            left: leftMargin, // Adjust the left margin to remove white space
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" horizontal={false} vertical={true} />
          <XAxis
            type="number"
            domain={['auto', 'auto']} // Allow automatic domain calculation
            tickFormatter={(tick) => `${new Intl.NumberFormat("en-US", { style: "currency", currency: currencies || 'USD' }).format(tick)}`}
            ticks={xAxisTicks}
          />
          <YAxis
            type="category"
            dataKey="name"
            // width={150} // Ensure enough width for client names
            tickMargin={10}
          />
          <Tooltip formatter={(value) => `${new Intl.NumberFormat("en-US", { style: "currency", currency: currencies }).format(value)}`} />
          <ReferenceLine x={0} stroke="#7393B3" />
          <Bar dataKey="closedAmount" name="Closed amount in default currency" fill="#C6DDFF">
            <LabelList dataKey="closedAmount" position="right" fill="#282828" formatter={(value) => `${new Intl.NumberFormat("en-US", { style: "currency", currency: currencies }).format(value)}`} />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

export default RevenuePerClientGraph;