import React, { useState, useEffect } from 'react';
import { ThreeDots } from "react-loader-spinner";
import { useSelector } from 'react-redux';
import axios from 'axios';
import { useTotalBudgetByProject, useTotalCostByProject, useTotalCostBySprint, useTrackedProjectHours } from '../features/projects/ProjectList';

export function GetBudget(rowData) {

    const { forex } = useSelector((state) => state.forex);
    const settings = JSON.parse(localStorage.getItem("settings"));

}
export function ProjectCost(rowData) {

    const { forex } = useSelector((state) => state.forex);
    const settings = JSON.parse(localStorage.getItem("settings"));
    const clientPreferredCurrency = rowData?.client_name?.preferred_currency || 'USD';
    const token = useSelector(
        (state) => state.auth.token
    )

    const { data: totalCost = [], isLoading: loading, refetch: refetchTotalCost, isError } = useTotalCostByProject(rowData._id, token, forex, clientPreferredCurrency);

    useEffect(() => {

        if (rowData._id) {
            refetchTotalCost();
        }
    }, [rowData]);

    return (
        <div>
            {loading ? (
                <div className="outter-load-table">
                    <ThreeDots
                        height="18"
                        width="20"
                        radius="9"
                        color="#6479f9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                    />
                </div>
            ) : (
                totalCost ? new Intl.NumberFormat('en-US', { style: 'currency', currency: clientPreferredCurrency }).format(totalCost) : new Intl.NumberFormat('en-US', { style: 'currency', currency: clientPreferredCurrency }).format(0.00)
            )}
        </div>
    );
}

export function ClientProjectCost(rowData) {

    const { forex } = useSelector((state) => state.forex);
    const settings = JSON.parse(localStorage.getItem("settings"));
    const clientPreferredCurrency = rowData?.client_name?.preferred_currency || 'USD';
    const token = useSelector(
        (state) => state.auth.token
    )

    const { data: totalCost = [], isLoading: loading, refetch: refetchTotalCost, isError } = useTotalCostByProject(rowData._id, token, forex, settings.currency);

    useEffect(() => {

        if (rowData._id) {
            refetchTotalCost();
        }
    }, [rowData]);

    return (
        <div>
            {loading ? (
                <div className="outter-load-table">
                    <ThreeDots
                        height="18"
                        width="20"
                        radius="9"
                        color="#6479f9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                    />
                </div>
            ) : (
                totalCost ? new Intl.NumberFormat('en-US', { style: 'currency', currency: settings.currency }).format(totalCost) : new Intl.NumberFormat('en-US', { style: 'currency', currency: settings.currency }).format(0.00)
            )}
        </div>
    );
}

export function ProjectBudget(rowData) {

    const { forex } = useSelector((state) => state.forex);
    const settings = JSON.parse(localStorage.getItem("settings"));
    const clientPreferredCurrency = rowData?.client_name?.preferred_currency || 'USD';
    const token = useSelector((state) => state.auth.token);
    const [convertedBudget, setConvertedBudget] = useState(0);

    function ConvertHelper(currencyFrom, currencyTo, amount, forex) {
        const currencyFromRate = forex[currencyFrom];
        const currencyToRate = forex[currencyTo];
        const amountConvertedUSD = amount / currencyFromRate;
        const amountConvertTo = amountConvertedUSD * currencyToRate;
        return amountConvertTo;
    }

    const { data: totalBudget = [], isLoading: loading, refetch: refetchTotalBudget, isError } = useTotalBudgetByProject(rowData._id, token);

    useEffect(() => {
        if (rowData._id) {
            refetchTotalBudget();
        }
    }, [rowData, refetchTotalBudget,]);

    useEffect(() => {
        if (rowData.client_name) {
            const budget = ConvertHelper(clientPreferredCurrency, clientPreferredCurrency, totalBudget, forex);
            setConvertedBudget(budget);
        }
    }, [totalBudget, forex, clientPreferredCurrency, rowData.client_name]);

    return (
        <div>
            {loading ? (
                <div className="outter-load-table">
                    <ThreeDots
                        height="18"
                        width="20"
                        radius="9"
                        color="#6479f9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                    />
                </div>
            ) : (
                convertedBudget ? new Intl.NumberFormat('en-US', { style: 'currency', currency: clientPreferredCurrency }).format(convertedBudget) : new Intl.NumberFormat('en-US', { style: 'currency', currency: clientPreferredCurrency }).format(0.00)
            )}
        </div>
    );
}

export function ClientProjectBudget(rowData) {

    const { forex } = useSelector((state) => state.forex);
    const settings = JSON.parse(localStorage.getItem("settings"));
    const token = useSelector((state) => state.auth.token);
    const [convertedBudget, setConvertedBudget] = useState(0);

    function ConvertHelper(currencyFrom, currencyTo, amount, forex) {
        const currencyFromRate = forex[currencyFrom];
        const currencyToRate = forex[currencyTo];
        const amountConvertedUSD = amount / currencyFromRate;
        const amountConvertTo = amountConvertedUSD * currencyToRate;
        return amountConvertTo;
    }

    const { data: totalBudget = [], isLoading: loading, refetch: refetchTotalBudget, isError } = useTotalBudgetByProject(rowData._id, token);

    useEffect(() => {
        if (rowData._id) {
            refetchTotalBudget();
        }
    }, [rowData, refetchTotalBudget,]);

    useEffect(() => {
        if (rowData.client_name) {
            const budget = ConvertHelper(rowData.client_name.preferred_currency || "USD", settings.currency, totalBudget, forex);
            setConvertedBudget(budget);
        }
    }, [totalBudget, forex, settings.currency, rowData.client_name]);

    return (
        <div>
            {loading ? (
                <div className="outter-load-table">
                    <ThreeDots
                        height="18"
                        width="20"
                        radius="9"
                        color="#6479f9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                    />
                </div>
            ) : (
                convertedBudget ? new Intl.NumberFormat('en-US', { style: 'currency', currency: settings.currency }).format(convertedBudget) : new Intl.NumberFormat('en-US', { style: 'currency', currency: settings.currency }).format(0.00)
            )}
        </div>
    );
}

export function SprintCost(rowData) {
    const { forex } = useSelector((state) => state.forex);
    const settings = JSON.parse(localStorage.getItem("settings"));
    const clientPreferredCurrency = rowData?.project?.client_name?.preferred_currency || 'USD';
    const token = useSelector(
        (state) => state.auth.token
    )
    const { projectAdded } = useSelector((state) => state.project);
    const notifyUpdate = useSelector((state) => state.notification.notificationState)

    const { data: totalCost = [], isLoading: loading, refetch: refetchTotalCost, isError } = useTotalCostBySprint(rowData._id, token, forex, clientPreferredCurrency, rowData.project._id);
    useEffect(() => {
        if (rowData._id) {
            refetchTotalCost();
        }
    }, [rowData, projectAdded, notifyUpdate]);

    return (
        <div>
            {loading ? (
                <div className="outter-load-table">
                    <ThreeDots
                        height="18"
                        width="20"
                        radius="9"
                        color="#6479f9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                    />
                </div>
            ) : (
                totalCost ? new Intl.NumberFormat('en-US', { style: 'currency', currency: clientPreferredCurrency }).format(totalCost) : new Intl.NumberFormat('en-US', { style: 'currency', currency: clientPreferredCurrency }).format(0.00)
            )}
        </div>
    );
}

export function Roi(rowData) {
    const { forex } = useSelector((state) => state.forex);
    const settings = JSON.parse(localStorage.getItem("settings"));
    const clientPreferredCurrency = rowData?.project?.client_name?.preferred_currency || 'USD';
    const [convertedBudget, setConvertedBudget] = useState(0);

    function ConvertHelper(currencyFrom, currencyTo, amount, forex) {
        const currencyFromRate = forex[currencyFrom];
        const currencyToRate = forex[currencyTo];
        const amountConvertedUSD = amount / currencyFromRate;
        const amountConvertTo = amountConvertedUSD * currencyToRate;
        return amountConvertTo;
    }
    const token = useSelector(
        (state) => state.auth.token
    )
    const { data: totalBudget = [], isLoading: budgetLoading, refetch: refetchTotalBudget, isError: budgetIsError } = useTotalBudgetByProject(rowData._id, token);
    const { data: totalCost = [], isLoading: loading, refetch: refetchTotalCost, isError } = useTotalCostByProject(rowData._id, token, forex, clientPreferredCurrency);

    useEffect(() => {
        if (rowData._id) {
            refetchTotalCost();
            refetchTotalBudget();
        }
    }, [rowData, refetchTotalBudget, refetchTotalCost]);

    useEffect(() => {
        if (rowData.client_name) {
            const budget = ConvertHelper(rowData.client_name.preferred_currency || "USD", clientPreferredCurrency, totalBudget, forex);
            setConvertedBudget(budget);
        }
    }, [totalBudget, forex, clientPreferredCurrency, rowData.client_name]);


    let Rvalue;
    if (totalCost === 0 && convertedBudget === 0) {
        Rvalue = "0%";
    } else if (totalCost === 0 && convertedBudget !== 0) {
        Rvalue = "N/A";
    } else {
        const rev = convertedBudget - totalCost;
        Rvalue = (rev / totalCost) * 100;
    }

    return (
        <div>
            {loading ? (
                <div className="outter-load-table">
                    <ThreeDots
                        height="18"
                        width="20"
                        radius="9"
                        color="#6479f9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                    />
                </div>
            ) : (
                <>{typeof Rvalue === "number" ? Rvalue.toFixed(2) + "%" : Rvalue}</>
            )}
        </div>
    );
}

export function ClientRoi(rowData) {
    const { forex } = useSelector((state) => state.forex);
    const settings = JSON.parse(localStorage.getItem("settings"));
    const [convertedBudget, setConvertedBudget] = useState(0);

    function ConvertHelper(currencyFrom, currencyTo, amount, forex) {
        const currencyFromRate = forex[currencyFrom];
        const currencyToRate = forex[currencyTo];
        const amountConvertedUSD = amount / currencyFromRate;
        const amountConvertTo = amountConvertedUSD * currencyToRate;
        return amountConvertTo;
    }
    const token = useSelector(
        (state) => state.auth.token
    )
    const { data: totalBudget = [], isLoading: budgetLoading, refetch: refetchTotalBudget, isError: budgetIsError } = useTotalBudgetByProject(rowData._id, token);
    const { data: totalCost = [], isLoading: loading, refetch: refetchTotalCost, isError } = useTotalCostByProject(rowData._id, token, forex, settings.currency);

    useEffect(() => {
        if (rowData._id) {
            refetchTotalCost();
            refetchTotalBudget();
        }
    }, [rowData, refetchTotalBudget, refetchTotalCost]);

    useEffect(() => {
        if (rowData.client_name) {
            const budget = ConvertHelper(rowData.client_name.preferred_currency || "USD", settings.currency, totalBudget, forex);
            setConvertedBudget(budget);
        }
    }, [totalBudget, forex, settings.currency, rowData.client_name]);


    let Rvalue;
    if (totalCost === 0 && convertedBudget === 0) {
        Rvalue = "0%";
    } else if (totalCost === 0 && convertedBudget !== 0) {
        Rvalue = "N/A";
    } else {
        const rev = convertedBudget - totalCost;
        Rvalue = (rev / totalCost) * 100;
    }

    return (
        <div>
            {loading ? (
                <div className="outter-load-table">
                    <ThreeDots
                        height="18"
                        width="20"
                        radius="9"
                        color="#6479f9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                    />
                </div>
            ) : (
                <>{typeof Rvalue === "number" ? Rvalue.toFixed(2) + "%" : Rvalue}</>
            )}
        </div>
    );
}

export function TrackedProjectHours({ timers }) {

    const [loading, setLoading] = useState(false);
    console.log("timers", timers);

    const totalDurationInSeconds = timers && timers.reduce((total, timer) => total + timer.duration, 0);

    function formatSecondsToHHMMSS(totalSeconds) {
        const hours = Math.floor(totalSeconds / 3600); // Calculate hours
        const minutes = Math.floor((totalSeconds % 3600) / 60); // Calculate minutes
        const seconds = totalSeconds % 60; // Calculate remaining seconds

        // Pad with leading zeros if necessary
        const formattedHours = String(hours).padStart(2, '0');
        const formattedMinutes = String(minutes).padStart(2, '0');
        const formattedSeconds = String(seconds).padStart(2, '0');

        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    }
    return (
        <div>
            {loading ? (
                <div className="outter-load-table">
                    <ThreeDots
                        height="18"
                        width="20"
                        radius="9"
                        color="#6479f9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                    />
                </div>
            ) : (
                <span>{totalDurationInSeconds ? formatSecondsToHHMMSS(totalDurationInSeconds) : '00:00:00'}</span>
            )}
        </div>
    );
};

export function TrackedLatestProjectHours(rowData) {
    const token = useSelector(
        (state) => state.auth.token
    )

    const { data: totalHours = [], isLoading: loading, refetch: refetchTotalHours, isError: totalHoursFetchError } = useTrackedProjectHours(rowData._id, token);

    useEffect(() => {
        if (rowData._id) {
            refetchTotalHours();
        }
    }, [rowData]);

    const changeDurationFormat = (sec) => {
        let second = sec;
        const hours = Math.floor(second / 3600);
        const minutes = Math.floor((second % 3600) / 60);
        const seconds = second % 60;
        const formattedDuration = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
        return formattedDuration;
    }
    return (
        <div>
            {loading ? (
                <div className="outter-load-table">
                    <ThreeDots
                        height="18"
                        width="20"
                        radius="9"
                        color="#6479f9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                    />
                </div>
            ) : (
                <span>{totalHours ? changeDurationFormat(totalHours) : '00:00:00'}</span>
            )}
        </div>
    );
};


export function TotalCost({ data, timers }) {
    const [loading, setLoading] = useState(false);
    let totalSprintsTotal = 0;
    const preferredCurrency = data?.client_name?.preferred_currency || "USD";
    const totalCostInClientCurrency = data.timers.reduce((sum, timer) => {
        return sum + (timer.totalCost || 0);
    }, 0);

    // const totalSprintsTotal = sprints && sprints.reduce((total, budget) => total + budget.budget, 0);

    return (
        <div>
            {loading ? (
                <div className="outter-load-table">
                    <ThreeDots
                        height="18"
                        width="20"
                        radius="9"
                        color="#6479f9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                    />
                </div>
            ) : (
                <span>
                    {totalCostInClientCurrency ?
                        new Intl.NumberFormat('en-US', { style: 'currency', currency: preferredCurrency }).format(totalCostInClientCurrency)
                        :
                        totalCostInClientCurrency
                    }
                </span>
            )}
        </div>
    );
};

export function TotalBudget({ data }) {

    const [loading, setLoading] = useState(false);
    let totalSprintsTotal = 0;

    const preferredCurrency = data?.client_name?.preferred_currency || "USD";

    const totalCostInClientCurrency = data.sprints.reduce((sum, sprint) => {
        return sum + (sprint.budget || 0); // Add totalCost if it exists, otherwise add 0
    }, 0);

    // const totalSprintsTotal = sprints && sprints.reduce((total, budget) => total + budget.budget, 0);

    return (
        <div>

            {loading ? (
                <div className="outter-load-table">
                    <ThreeDots
                        height="18"
                        width="20"
                        radius="9"
                        color="#6479f9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                    />
                </div>
            ) : (
                <span>{totalCostInClientCurrency ? new Intl.NumberFormat('en-US', { style: 'currency', currency: preferredCurrency }).format(totalCostInClientCurrency) : "0.00"}</span>
            )}
        </div>
    );
};

export function GetRoi({ data }) {

    const [loading, setLoading] = useState(false);
    const preferredCurrency = data?.client_name?.preferred_currency || "USD";

    const totalCostInClientCurrencyBudget = data.sprints.reduce((sum, sprint) => {
        return sum + (sprint.budget || 0);
    }, 0);

    const totalCostInClientCurrencyCost = data.timers.reduce((sum, timer) => {
        return sum + (timer.totalCost || 0);
    }, 0);

    // const rev = totalCostInClientCurrencyBudget - totalCostInClientCurrencyCost;
    // Avoid NaN and infinite by checking if totalCostInClientCurrencyCost is greater than zero
    let Rvalue = 0;
    if (totalCostInClientCurrencyCost > 0) {
        const rev = totalCostInClientCurrencyBudget - totalCostInClientCurrencyCost;
        Rvalue = (rev / totalCostInClientCurrencyCost) * 100;
    } else {
        // If totalCostInClientCurrencyCost is zero or undefined, set Rvalue to a default value
        // e.g., 0 or any value that makes sense for your use case
        Rvalue = 0;
    }

    return (
        <div>
            {loading ? (
                <div className="outter-load-table">
                    <ThreeDots
                        height="18"
                        width="20"
                        radius="9"
                        color="#6479f9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                    />
                </div>
            ) : (
                <span> <>{typeof Rvalue === "number" ? Rvalue.toFixed(2) + "%" : Rvalue}</></span>
            )}
        </div>
    );

}

export function TrackedSprintHours(rowData) {
    const [totalHours, setTotalHours] = useState(null);
    const [loading, setLoading] = useState(false);
    const token = useSelector(
        (state) => state.auth.token
    )

    useEffect(() => {
        const fetchTotalHours = async () => {
            setLoading(true);
            try {
                const response = await axios.post('/api/time-track/total-duration-by-sprint', { sprintId: rowData._id }, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setTotalHours(response.data.totalDuration);
            } catch (error) {
                console.log('Error:', error);
                console.error('Error fetching total hours:', error);
                global.config.slackMessage(error.toString());
            } finally {
                setLoading(false);
            }
        };

        if (rowData._id) {
            fetchTotalHours();
        }
    }, [rowData, token]);

    const changeDurationFormat = (sec) => {
        let second = sec;
        const hours = Math.floor(second / 3600);
        const minutes = Math.floor((second % 3600) / 60);
        const seconds = second % 60;
        const formattedDuration = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
        return formattedDuration;
    }

    return (
        <div>
            {loading ? (
                <div className="outter-load-table">
                    <ThreeDots
                        height="18"
                        width="20"
                        radius="9"
                        color="#6479f9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                    />
                </div>
            ) : (
                <span>{totalHours ? changeDurationFormat(totalHours) : '00:00:00'}</span>
            )}
        </div>
    );
};

export const ExportTrackedProjectHours = async (projectId, token) => {
    const { data: totalHours } = await useTrackedProjectHours(projectId, token);
    const changeDurationFormat = (sec) => {
        let second = sec;
        const hours = Math.floor(second / 3600);
        const minutes = Math.floor((second % 3600) / 60);
        const seconds = second % 60;
        const formattedDuration = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
        return formattedDuration;
    };
    return totalHours ? changeDurationFormat(totalHours) : "00:00:00";
};

export const ExportClientProjectBudget = async (rowData, token, forex, settings) => {
    const { data: totalBudget } = await useTotalBudgetByProject(rowData._id, token);
    function ConvertHelper(currencyFrom, currencyTo, amount, forex) {
        const currencyFromRate = forex[currencyFrom];
        const currencyToRate = forex[currencyTo];
        const amountConvertedUSD = amount / currencyFromRate;
        const amountConvertTo = amountConvertedUSD * currencyToRate;
        return amountConvertTo;
    }
    const budget = ConvertHelper(
        rowData.client_name?.preferred_currency || "USD",
        settings.currency,
        totalBudget,
        forex
    );
    return budget ? new Intl.NumberFormat('en-US', { style: 'currency', currency: settings.currency }).format(budget) : "0.00";
};

export const ExportClientProjectCost = async (rowData, token, forex, settings) => {
    const { data: totalCost } = await useTotalCostByProject(rowData._id, token, forex, settings.currency);
    return totalCost ? new Intl.NumberFormat('en-US', { style: 'currency', currency: settings.currency }).format(totalCost) : "0.00";
};

export const ExportClientRoi = async (rowData, token, forex, settings) => {
    const budget = await ExportClientProjectBudget(rowData, token, forex, settings);
    const cost = await ExportClientProjectCost(rowData, token, forex, settings);

    let Rvalue;
    if (cost === 0 && budget === 0) {
        Rvalue = "0%";
    } else if (cost === 0 && budget !== 0) {
        Rvalue = "N/A";
    } else {
        const rev = budget - cost;
        Rvalue = (rev / cost) * 100;
    }
    return typeof Rvalue === "number" ? Rvalue.toFixed(2) + "%" : Rvalue;
};
