import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Modal from 'react-modal';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import * as XLSX from "xlsx";
import { toast } from "react-toastify";
import 'react-datepicker/dist/react-datepicker.css';
import TruncateFilterFieldView from "../../../helpers/truncateFilterFieldView.js";
import typeOfContract from '../../../../constants/typeOfContract';
import { convertCurrencyHelper } from "../../../../helpers/forexConvertor";
import DownloadImage from "../../../../images/icons/download-icon.svg";
import {
  setReportingCliTimeWorkedClient,
  setReportingCliTimeWorkedProject,
  // setReportingCliTimeWorkedYear
} from '../../../../features/reporting/reportingClient/reportingClientTimeWorkedSlice.js';

function ReportingClientTimeWorkedFilter(props) {
  const dispatch = useDispatch();
  const clientDropdownRef = useRef(null);
  const projectDropdownRef = useRef(null);
  const clientModalDropdownRef = useRef(null);
  const projectModalDropdownRef = useRef(null);

  const [isClientDropdownOpen, setIsClientDropdownOpen] = useState(false);
  const [isProjectDropdownOpen, setIsProjectDropdownOpen] = useState(false);
  const [isClientModalDropdownOpen, setIsClientModalDropdownOpen] = useState(false);
  const [isProjectModalDropdownOpen, setIsProjectModalDropdownOpen] = useState(false);
  const [clientDropdownSearchTerm, setClientDropdownSearchTerm] = useState('');
  const [projectDropdownSearchTerm, setProjectDropdownSearchTerm] = useState('');
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [clientsFilter, setClientsFilter] = useState("All");
  const [selectAllClient, setSelectAllClient] = useState(true);
  const [selectedClients, setSelectedClients] = useState([]);
  const [projectsFilter, setProjectsFilter] = useState("All");
  const [selectAllProject, setSelectAllProject] = useState(true);
  const [selectedProject, setSelectedProject] = useState([]);
  const [sortedProjects, setSortedProjects] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  // const currentYear = new Date().getFullYear();
  // const [year, setYear] = useState(new Date(currentYear, 0, 1));
  // const [selectedDate, setSelectedDate] = useState(null);
  const reportingTimeWorked = useSelector((state) => state.reportingTimeWorked);
  const settings = JSON.parse(localStorage.getItem("settings"));
  const { forex } = useSelector((state) => state.forex);
  const { appSettings } = useSelector((state) => state.appSettings);
  const user = useSelector((state) => state.auth.user);


  // const dummyClients = [
  //   { _id: 1, client_name: 'Client A' },
  //   { _id: 2, client_name: 'Client B' },
  //   { _id: 3, client_name: 'Client C' }
  // ];

  // const dummyProjectes = [
  //   { _id: 1, project: 'Braintrust' },
  //   { _id: 2, project: 'TalenOn' },
  //   { _id: 3, project: 'Gruporesidencial' },
  //   { _id: 4, project: 'Mudhouse' }
  // ];

  // const dummyRanges = [
  //   { label: 'Today', value: new Date() },
  //   { label: 'Yesterday', value: new Date(new Date().setDate(new Date().getDate() - 1)) },
  //   { label: 'Last 7 Days', value: new Date(new Date().setDate(new Date().getDate() - 7)) }
  // ];


  const handleCheckboxProjectChange = (projectId) => {
    const updatedSelection = [...selectedProject];
    const index = updatedSelection.indexOf(projectId);

    if (index === -1) {
      updatedSelection.push(projectId);
    } else {
      updatedSelection.splice(index, 1);
    }
    // console.log(updatedSelection);

    setSelectedProject(updatedSelection);
    // Update selected clients based on the selected projects
    const updatedClients = [...new Set(
      props.projects
        .filter(project => updatedSelection.includes(project._id))
        .map(project => project.client_name._id)
    )];

    setSelectedClients(updatedClients);
  };

  const handleSelectAllProjectChange = () => {
    if (!selectAllProject) {
      // setSelectedProject(sortedProjects.map((project) => project._id));
      const allProjectIds = sortedProjects.map((project) => project._id);
      setSelectedProject(allProjectIds);

      // Update selected clients based on all projects
      const allClientIds = [...new Set(sortedProjects.map((project) => project.client_name._id))];
      setSelectedClients(allClientIds);
    } else {
      setSelectedProject([]);
      setSelectedClients([]);
    }
    setSelectAllProject(!selectAllProject);
  };

  const filteredDropdownProjects = sortedProjects.filter((project) =>
    project?.project_name?.toLowerCase().includes(projectDropdownSearchTerm?.toLowerCase())
  );

  const handleProjectDropdownSearchChange = (e) => {
    setProjectDropdownSearchTerm(e.target.value);
  };

  useEffect(() => {
    setSelectAllProject(selectedProject.length === sortedProjects.length);
    if (selectedProject.length === sortedProjects.length) {
      setProjectsFilter("All");
      selectProject(selectedProject);
    } else {
      setProjectsFilter(selectedProject);
      selectProject(selectedProject);
    }
  }, [selectedProject, props.projects]);

  const handleCheckboxClientChange = (clientId) => {
    const updatedSelection = [...selectedClients];
    const index = updatedSelection.indexOf(clientId);
    let filteredProjects;

    if (index === -1) {
      // Add to selection
      updatedSelection.push(clientId);
    } else {
      // Remove from selection
      updatedSelection.splice(index, 1);
    }

    if (Array.isArray(props.projects)) {
      filteredProjects = props.projects.filter(project => updatedSelection.includes(project.client_name._id));
    }

    setSelectedProject(filteredProjects.map(project => project._id));
    setSortedProjects(filteredProjects);
    selectProject(filteredProjects.map(project => project._id));
    // Update sorted currencies based on selected clients
    const selectedClientObjects = props.clients.filter(client => updatedSelection.includes(client._id));
    setSelectedClients(updatedSelection);
  };

  const handleSelectAllClientChange = () => {
    if (!selectAllClient) {
      const allClientIds = props.clients.map(client => client._id);
      let filteredProjects;
      setSelectedClients(allClientIds);

      // Filter projects based on all selected clients
      if (Array.isArray(props.projects)) {
        filteredProjects = props.projects.filter(project => allClientIds.includes(project.client_name._id));
      }
      setSelectedProject(filteredProjects.map(project => project._id));
      setSortedProjects(filteredProjects);
      selectProject(filteredProjects.map(project => project._id));
    } else {
      setSelectedClients([]);
      setSelectedProject([]);
      setSortedProjects([]);
    }
    setSelectAllClient(!selectAllClient);
  };

  const filteredDropdownClients = props.clients.filter((client) => {
    const clientName = client.client_name || client.legal_entity_name;
    return clientName?.toLowerCase().includes(clientDropdownSearchTerm?.toLowerCase());
  });

  const handleClientDropdownSearchChange = (e) => {
    setClientDropdownSearchTerm(e.target.value);
  };

  useEffect(() => {
    setSelectAllClient(selectedClients.length === props.clients.length);
    if (selectedClients.length === props.clients.length) {
      setClientsFilter("All");
      selectClient(selectedClients);
    } else {
      setClientsFilter(selectedClients);
      selectClient(selectedClients);
    }
  }, [selectedClients, props.clients]);

  const toggleClientDropdown = () => {
    setIsClientDropdownOpen(!isClientDropdownOpen);
    setClientDropdownSearchTerm('');
  };

  const toggleProjectDropdown = () => {
    setIsProjectDropdownOpen(!isProjectDropdownOpen);
    setProjectDropdownSearchTerm('');
  };

  const toggleClientModalDropdown = () => {
    setIsClientModalDropdownOpen(!isClientModalDropdownOpen);
    setClientDropdownSearchTerm('');
  };

  const toggleProjectModalDropdown = () => {
    setIsProjectModalDropdownOpen(!isProjectModalDropdownOpen);
    setProjectDropdownSearchTerm('');
  };

  const toggleFilterModal = () => {
    setShowFilterModal(!showFilterModal);
  };

  const applyFilters = () => {
    setShowFilterModal(false);
  };

  const handleApplyConfirm = () => {
    setShowConfirmationModal(false);
    setShowFilterModal(false);
  };

  const clearFilters = () => {
    setSelectedClients(props.clients.map((client) => client._id));
    setSelectedProject(props.projects.map((project) => project._id));
    // setSelectedDate(null);
    setShowConfirmationModal(false);
    setShowFilterModal(false);
  };

  // const handleDateChange = (date) => {
  //   setSelectedDate(date);
  // };

  const checkAndShowConfirmationModal = () => {
    // if (selectedClients.length || selectedProject.length || selectedDate) {
    if (selectedClients.length || selectedProject.length) {
      setShowConfirmationModal(true);
    } else {
      setShowFilterModal(false);
    }
  };

  const selectClient = async (client) => {
    await dispatch(setReportingCliTimeWorkedClient(client));
  }

  const selectProject = async (project) => {
    await dispatch(setReportingCliTimeWorkedProject(project));
  }

  // const selectYear = async (year) => {
  //   await dispatch(setReportingCliTimeWorkedYear(year));
  // }

  useEffect(() => {
    // const currentYear = new Date().getFullYear();
    setSelectedClients(props.clients.map((client) => client._id));
    if (Array.isArray(props.projects)) {
      setSelectedProject(props.projects.map((project) => project._id));
    }
    setSortedProjects(props.projects);
    // selectYear(currentYear);
  }, [props.clients, props.projects]);

  const handleClickOutsideDropdown = (event) => {
    const isClickInsideProjectDropdown = projectDropdownRef.current && projectDropdownRef.current.contains(event.target);
    const isClickInsideProjectModalDropdown = projectModalDropdownRef.current && projectModalDropdownRef.current.contains(event.target);
    const isClickInsideClientDropdown = clientDropdownRef.current && clientDropdownRef.current.contains(event.target);
    const isClickInsideClientModalDropdown = clientModalDropdownRef.current && clientModalDropdownRef.current.contains(event.target);

    if (!isClickInsideProjectDropdown) {
      setIsProjectDropdownOpen(false);
    }
    if (!isClickInsideProjectModalDropdown) {
      setIsProjectModalDropdownOpen(false);
    }
    if (!isClickInsideClientDropdown) {
      setIsClientDropdownOpen(false);
    }
    if (!isClickInsideClientModalDropdown) {
      setIsClientModalDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutsideDropdown);

    return () => {
      document.removeEventListener("click", handleClickOutsideDropdown);
    };
  }, []);

  useEffect(() => {
    if (props.projects && reportingTimeWorked) {
      const filtered = props.projects?.filter((project) =>
        project &&
        !project.is_delete &&
        (reportingTimeWorked.timeWorkedProject && reportingTimeWorked.timeWorkedProject.includes(project._id))
        // new Date(project.created_at).getFullYear() === reportingTimeWorked.timeWorkedYear
      );
      const sortedData = filtered.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      setFilteredData(sortedData);
    }
  }, [props.projects, reportingTimeWorked]);

  const exportToExcel = () => {
    if (filteredData.length === 0) {
      toast.error("No data to export.", {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    try {
      const now = new Date();
      const formattedDate =
        now.getFullYear() +
        "-" +
        (now.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        now.getDate().toString().padStart(2, "0");
      const formattedTime =
        now.getHours().toString().padStart(2, "0") +
        ":" +
        now.getMinutes().toString().padStart(2, "0") +
        ":" +
        now.getSeconds().toString().padStart(2, "0");

      // Group by client and calculate necessary values
      const groupedData = filteredData.reduce((acc, item) => {
        const clientName = item.client_name?.client_name || item.client_name?.legal_entity_name || 'Unknown Client';
        const projectName = item.project_name || 'Unknown Project';
        const projectId = item._id;

        if (!acc[clientName]) {
          acc[clientName] = {};
        }

        if (!acc[clientName][projectName]) {
          acc[clientName][projectName] = {
            clientName,
            projectName,
            budget: 0,
            totalRevenue: 0,
            cost: 0,
          };
        }

        // Calculate budget from sprints
        const projectSprints = props.sprints.filter(sprint => sprint.project && sprint.project._id === projectId);
        const budget = projectSprints.reduce((sum, sprint) => {
          const sprintBudget = sprint.budget || 0;
          const clientCurrency = item.client_name?.preferred_currency || 'USD';
          return sum + convertCurrencyHelper(clientCurrency, settings.currency, sprintBudget, forex);
        }, 0);

        // Calculate total revenue using the provided reference function
        // const totalRevenue = clientTotalRevenue(filteredData, props.projectCost, projects, props.sprints, reportingTimeWorked, preferredCurrency, forex, typeOfContract, expenses);

        // Calculate cost based on the tracked hours from trackReport
        const projectTrackReports = props.trackReport.filter(report => report.project._id === projectId);
        const cost = projectTrackReports.reduce((total, report) => {
          // const userCost = report.user.cost || 0;
          const projectSpecificCost = props.projectCost.find(cost => cost.userId === report?.user?._id && cost.projectId === projectId && !cost.is_delete);
          // const userCost = projectSpecificCost && projectSpecificCost.amount > 0 ? projectSpecificCost.amount : (report?.user.cost || 0);
          let userCost;
          if (projectSpecificCost && projectSpecificCost.amount > 0) {
            userCost = convertCurrencyHelper(
              report.clientId?.preferred_currency || 'USD',
              report.user.payment_currency,
              projectSpecificCost.amount,
              forex
            );
          } else {
            userCost = report?.user.cost || 0;
          }
          const userOverheadCost = report.user.overhead_cost || 0;
          const userTypeOfContract = report.user.typeOfContract || typeOfContract.Hourly_Rate;
          const paymentCurrency = report.user.payment_currency || 'USD';

          let finalCost;
          if (userTypeOfContract === typeOfContract.Hourly_Rate) {
            finalCost = userCost;
          } else {
            finalCost = userCost / parseInt(report.user.monthly_hours ? report.user.monthly_hours : 168); // Assuming a different type of contract with a monthly rate divided by 168 hours
          }

          const durationInHours = report.duration / 3600;
          const reportCost = (finalCost * durationInHours) + (userOverheadCost * durationInHours);

          return total + convertCurrencyHelper(paymentCurrency, settings.currency, reportCost, forex);
        }, 0);
        const totalRevenue = budget - cost;

        acc[clientName][projectName].budget = budget;
        acc[clientName][projectName].totalRevenue = totalRevenue;
        acc[clientName][projectName].cost = cost;

        return acc;
      }, {});

      const final = [];
      Object.values(groupedData).forEach(projects => {
        Object.values(projects).forEach(project => {
          final.push({
            Client: project.clientName,
            Project: project.projectName,
            Budget: project.budget.toFixed(2),
            'Total Revenue': project.totalRevenue.toFixed(2),
            Cost: project.cost.toFixed(2),
          });
        });
      });

      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(final);
      const colWidths = [
        { wpx: 200 }, // Client
        { wpx: 200 }, // Project
        { wpx: 100 }, // Budget
        { wpx: 150 }, // Total Revenue
        { wpx: 100 }, // Cost
      ];
      worksheet["!cols"] = colWidths;

      // Set alignment and font size for header row
      const firstRow = XLSX.utils.decode_range(worksheet["!ref"]).s.r;
      for (let i = 0; i < colWidths.length; i++) {
        const cell = XLSX.utils.encode_cell({ r: firstRow, c: i });
        const style = worksheet[cell]?.s || {};
        style.alignment = style.alignment || {};
        style.alignment.horizontal = "center !important";
        style.alignment.vertical = "center !important";
        style.font = { size: 14, bold: true };
        worksheet[cell] = { ...worksheet[cell], s: style };
      }

      // Set font size for data rows
      const range = XLSX.utils.decode_range(worksheet["!ref"]);
      for (let row = range.s.r + 1; row <= range.e.r; row++) {
        for (let col = range.s.c; col <= range.e.c; col++) {
          const cell = XLSX.utils.encode_cell({ r: row, c: col });
          const style = worksheet[cell]?.s || {};
          style.font = { size: 12 };
          worksheet[cell] = { ...worksheet[cell], s: style };
        }
      }

      global.config.activityLog(
        window.location.href,
        "TimeWorked",
        "Financial Overview Data exported to Excel"
      );

      const fileName = `TimeWorked_Report_${formattedDate}_${formattedTime}.xlsx`;
      worksheet["!autofilter"] = { ref: range };
      XLSX.utils.book_append_sheet(workbook, worksheet, "TimeWorked Report");
      XLSX.writeFile(workbook, fileName);
    } catch (error) {
      console.log(error);
      global.config.slackMessage(error.toString());
      global.config.activityLog(
        window.location.href,
        "Invoice",
        "Error occurred while exporting to Excel"
      );
      alert("Error occurred while exporting to Excel.");
    }
  };

  return (
    <>
      <div className='report-top-button'>
        <div className="button-inner-report button-inner-report-all-currency">
          <a onClick={exportToExcel}>
            <span className="icon-export">
              <img src={DownloadImage} alt="Export" />
            </span>
            Export Data
          </a>

        </div>
      </div>
      <div className="invoice-filter invoice-filter-report">
        <div className="invoice-filter-inner-section no-flter-all">
          <div className="filter-inner users">
            <label htmlFor="fname">Client</label>
            <div className="custom-dropdown1" ref={clientDropdownRef}>
              <div className="drop-down-inner">
                <div className="selected-options1" onClick={toggleClientDropdown}>
                  {selectedClients.length === 0 ? (
                    <div className="placeholder">Select</div>
                  ) : (
                    <div className="selected-option1" key={selectedClients[0]}>
                      {selectedClients.length === props.clients.length
                        ? 'All'
                        : (
                          <>
                            <TruncateFilterFieldView textContent={props.clients.find((item) => item._id === selectedClients[0])?.client_name} />
                            {selectedClients.length > 1 && selectedClients.length !== props.clients.length && (
                              <div className="selected-option-count">
                                {selectedClients.length - 1}+
                              </div>
                            )}
                          </>
                        )}
                    </div>
                  )}
                </div>
                {isClientDropdownOpen && (
                  <div className="dropdown-list1">
                    <input
                      type="text"
                      placeholder="Search clients"
                      value={clientDropdownSearchTerm}
                      onChange={handleClientDropdownSearchChange}
                      className="search-bar"
                    />
                    {filteredDropdownClients.length > 0 && (
                      <>
                        {clientDropdownSearchTerm === "" && (
                          <label className="checkbox-label1" key="selectAll">
                            <input
                              type="checkbox"
                              checked={selectAllClient}
                              onChange={handleSelectAllClientChange}
                            />
                            All
                          </label>
                        )}
                        {filteredDropdownClients
                          .slice()
                          .sort((a, b) =>
                            a.client_name?.toLowerCase().localeCompare(b.client_name?.toLowerCase())
                          )
                          .map((client) => (
                            <label className="checkbox-label1" key={client._id}>
                              <input
                                type="checkbox"
                                checked={selectedClients.includes(client._id)}
                                onChange={() => handleCheckboxClientChange(client._id)}
                              />
                              {client.client_name && `${client.client_name}`}
                            </label>
                          ))}
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="filter-inner invoice-status">
            <label htmlFor="invoiceproject">Project</label>
            <div className="custom-dropdown1" ref={projectDropdownRef}>
              <div className="drop-down-inner">
                <div className="selected-options1" onClick={toggleProjectDropdown}>
                  {selectedProject && selectedProject.length === 0 ? (
                    <div className="placeholder">Select</div>
                  ) : (
                    <div className="selected-option1" key={selectedProject[0]}>
                      {selectedProject && selectedProject.length === sortedProjects.length
                        ? 'All'
                        : (
                          <>
                            <TruncateFilterFieldView textContent={sortedProjects && Array.isArray(sortedProjects) && sortedProjects.find((item) => item._id === selectedProject[0])?.project_name} />
                            {selectedProject.length > 1 && selectedProject.length !== sortedProjects.length && (
                              <div className="selected-option-count">
                                {selectedProject.length - 1}+
                              </div>
                            )}
                          </>
                        )}
                    </div>
                  )}
                </div>
                {isProjectDropdownOpen && (
                  <div className="dropdown-list1">
                    <input
                      type="text"
                      placeholder="Search projects"
                      value={projectDropdownSearchTerm}
                      onChange={handleProjectDropdownSearchChange}
                      className="search-bar"
                    />
                    {filteredDropdownProjects.length > 0 ? (
                      <>
                        {projectDropdownSearchTerm === "" && (
                          <label className="checkbox-label1" key="selectAll">
                            <input
                              type="checkbox"
                              checked={selectAllProject}
                              onChange={handleSelectAllProjectChange}
                            />
                            All
                          </label>
                        )}
                        {filteredDropdownProjects
                          .slice()
                          .sort((a, b) =>
                            a.project_name?.toLowerCase().localeCompare(b.project_name?.toLowerCase())
                          )
                          .map((project) => (
                            <label className="checkbox-label1" key={project._id}>
                              <input
                                type="checkbox"
                                checked={selectedProject.includes(project._id)}
                                onChange={() => handleCheckboxProjectChange(project._id)}
                              />
                              {project.project_name && `${project.project_name}`}
                            </label>
                          ))}
                      </>
                    ) : (
                      <label className="checkbox-label1">
                        No projects found
                      </label>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* <div className="filter-inner due-date">
            <label htmlFor="dueDate" className="calendar-icon">
              Timeframe
            </label>
            <div className="date-picker-outer date-picker-outer-all">
              <div className="custom-picker-icon custom-picker-icon-all">
                <svg
                  width="1em"
                  height="1em"
                  viewBox="0 0 14 14"
                  fill="currentColor"
                  aria-hidden="true"
                  focusable="false"
                  className="rs-picker-toggle-caret rs-icon"
                  aria-label="calendar"
                  data-category="legacy"
                >
                  <path d="M1 4v8.5a.5.5 0 00.5.5H8c0-.128.049-.256.146-.354.555-.555.854-1.6.854-3.146a.5.5 0 01.621-.485l.119.03A2.623 2.623 0 0012.999 6.5V4h-12zm3-2h6V0h1v2h1.5A1.5 1.5 0 0114 3.5v3a3.623 3.623 0 01-4.015 3.603c-.064 1.245-.335 2.212-.831 2.898H12.5a.5.5 0 00.5-.5v-2a.5.5 0 011 0v2a1.5 1.5 0 01-1.5 1.5h-11a1.5 1.5 0 01-1.5-1.5v-9a1.5 1.5 0 011.5-1.5H3v-2h1v2zm5 4V5h1v1h2v1h-2v1H9V7H7v2h1v1H7v2H6v-2H4v2H3v-2H1V9h2V7H1V6h2V5h1v1h2V5h1v1h2zM6 9V7H4v2h2z"></path>
                </svg>
              </div>
              <div className="custoom-icon-calender custoom-icon-calender-all">

                <div className="custoom-icon-calender custoom-icon-calender-all custom-date-range">
                  <DatePicker
                    selected={year}
                    // onChange={(date) => setYear(new Date(date.getFullYear(), 0, 1))}
                    onChange={(date) => {
                      const selectedYear = date.getFullYear();
                      const selectedDateYear = new Date(date.getFullYear(), 0, 1);
                      setYear(selectedDateYear);
                      selectYear(selectedYear);
                    }}
                    showYearPicker
                    dateFormat="yyyy"
                  />
                </div>
              </div>
            </div>
          </div> */}
        </div>
        {/* <div className="truncate-filters-outer">
          <TruncateFilterFieldView
            clients={dummyClients}
            selectedClients={selectedClients}
            selectedProject={selectedProject}
            invProjectList={dummyProjectes}
            handleClientFilterRemoval={(clientId) => handleCheckboxClientChange(clientId)}
            handleProjectFilterRemoval={(projectId) => handleCheckboxProjectChange(projectId)}
            clearFilters={clearFilters}
          />
        </div> */}

        <div className="filter-invoice-right apply-fliter-right">
          <button onClick={toggleFilterModal}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="13"
              height="17"
              viewBox="0 0 13 17"
              fill="none"
            >
              <path
                d="M11.7363 8.81108C11.4276 9.81451 10.4925 10.5444 9.38889 10.5444C8.28471 10.5444 7.35016 9.8141 7.04147 8.81108L0.722221 8.81108C0.323349 8.81108 -1.07845e-06 8.48773 -1.06101e-06 8.08886C-1.04357e-06 7.68998 0.323349 7.36663 0.722221 7.36663L7.04144 7.36663C7.35022 6.3632 8.28529 5.6333 9.38889 5.6333C10.4931 5.6333 11.4276 6.36362 11.7363 7.36663L12.2778 7.36663C12.6767 7.36663 13 7.68998 13 8.08886C13 8.48773 12.6766 8.81108 12.2778 8.81108L11.7363 8.81108ZM9.38889 7.07775C8.83162 7.07775 8.37778 7.53123 8.37778 8.08886C8.37778 8.64613 8.83127 9.09997 9.38889 9.09997C9.94616 9.09997 10.4 8.64648 10.4 8.08886C10.4 7.53158 9.94651 7.07775 9.38889 7.07775Z"
                fill="#6479F8"
              />
              <path
                d="M5.38078 3.17778C5.072 4.18121 4.13693 4.91111 3.03333 4.91111C1.92882 4.91111 0.994035 4.18035 0.68563 3.17687C0.303759 3.15781 -1.07791e-06 2.84216 -1.06101e-06 2.45556C-1.04411e-06 2.06896 0.303748 1.75331 0.685606 1.73425C0.994098 0.730343 1.9294 -4.83911e-07 3.03333 -4.35657e-07C4.13752 -3.87392e-07 5.07206 0.730315 5.38076 1.73333L12.2778 1.73333C12.6767 1.73333 13 2.05668 13 2.45556C13 2.85443 12.6766 3.17778 12.2778 3.17778L5.38078 3.17778ZM3.03333 1.44444C2.47606 1.44444 2.02222 1.89793 2.02222 2.45556C2.02222 3.01283 2.47571 3.46667 3.03333 3.46667C3.5906 3.46667 4.04444 3.01318 4.04444 2.45556C4.04444 1.89828 3.59096 1.44444 3.03333 1.44444Z"
                fill="#6479F8"
              />
              <path
                d="M7.403 14.4444C7.09422 15.4478 6.15916 16.1777 5.05556 16.1777C3.95137 16.1777 3.01682 15.4474 2.70813 14.4444L0.722221 14.4444C0.323349 14.4444 -1.07845e-06 14.121 -1.06101e-06 13.7222C-1.04357e-06 13.3233 0.323349 12.9999 0.722221 12.9999L2.70811 12.9999C3.01689 11.9965 3.95195 11.2666 5.05556 11.2666C6.15974 11.2666 7.09429 11.9969 7.40298 12.9999L12.2778 12.9999C12.6767 12.9999 13 13.3233 13 13.7222C13 14.121 12.6766 14.4444 12.2778 14.4444L7.403 14.4444ZM5.05556 12.711C4.49828 12.711 4.04444 13.1645 4.04444 13.7222C4.04444 14.2794 4.49793 14.7333 5.05556 14.7333C5.61283 14.7333 6.06667 14.2798 6.06667 13.7222C6.06667 13.1649 5.61318 12.711 5.05556 12.711Z"
                fill="#6479F8"
              />
            </svg>
            All Filters
          </button>
        </div>

        <Modal
          isOpen={showFilterModal}
          onRequestClose={toggleFilterModal}
          contentLabel="Filter Modal"
          className="new-filter-modal"
          style={{
            content: {
              height: "50%",
              width: "50%",
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-25%, -25%)",
              borderRadius: "10px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              backgroundColor: "#fff",
              padding: "20px",
              // overflow: "auto",
            },
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            },
          }}
        >
          <div className="new-nodal-invoice-outer-sec">
            <div className="new-modal-top-sec">
              <div className="new-modal-heading">
                All filters
              </div>
              <a className="close-image-clr1" onClick={checkAndShowConfirmationModal}>
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                  <path d="M1 13L13 1" stroke="black" />
                  <path d="M1 0.999999L13 13" stroke="black" />
                </svg>{" "}
              </a>
            </div>

            <div className="invoice-filter-inner-section">
              <div className="filter-inner users">
                <label htmlFor="fname">Client</label>
                <div className="custom-dropdown1" ref={clientModalDropdownRef}>
                  <div className="drop-down-inner">
                    <div className="selected-options1" onClick={toggleClientModalDropdown}>
                      {selectedClients.length === 0 ? (
                        <div className="placeholder">Select</div>
                      ) : (
                        <>
                          <div className="selected-option1" key={selectedClients[0]}>
                            {selectedClients.length === props.clients.length
                              ? 'All'
                              : (
                                <>
                                  <TruncateFilterFieldView textContent={props.clients.find((item) => item._id === selectedClients[0])?.client_name} />
                                  {selectedClients.length > 1 && selectedClients.length !== props.clients.length && (
                                    <div className="selected-option-count">
                                      {selectedClients.length - 1}+
                                    </div>
                                  )}
                                </>
                              )}
                          </div>
                        </>
                      )}
                    </div>
                    {isClientModalDropdownOpen && (
                      <div className="dropdown-list1">
                        <input
                          type="text"
                          placeholder="Search clients"
                          value={clientDropdownSearchTerm}
                          onChange={handleClientDropdownSearchChange}
                          className="search-bar"
                        />
                        {filteredDropdownClients.length > 0 && (
                          <>
                            {clientDropdownSearchTerm === "" && (
                              <label className="checkbox-label1" key="selectAll">
                                <input
                                  type="checkbox"
                                  checked={selectAllClient}
                                  onChange={handleSelectAllClientChange}
                                />
                                All
                              </label>
                            )}
                            {filteredDropdownClients
                              .slice()
                              .sort((a, b) =>
                                a.client_name?.toLowerCase().localeCompare(b.client_name?.toLowerCase())
                              )
                              .map((client) => (
                                <label className="checkbox-label1" key={client._id}>
                                  <input
                                    type="checkbox"
                                    checked={selectedClients.includes(client._id)}
                                    onChange={() => handleCheckboxClientChange(client._id)}
                                  />
                                  {client.client_name && `${client.client_name}`}
                                </label>
                              ))}
                          </>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="filter-inner invoice-project">
                <label htmlFor="invoiceproject">project</label>
                <div className="custom-dropdown1" ref={projectModalDropdownRef}>
                  <div className="drop-down-inner">
                    <div className="selected-options1" onClick={toggleProjectModalDropdown}>
                      {selectedProject.length === 0 ? (
                        <div className="placeholder">Select</div>
                      ) : (
                        <>
                          <div className="selected-option1" key={selectedProject[0]}>
                            {selectedProject.length === sortedProjects.length
                              ? 'All'
                              : (
                                <>
                                  <TruncateFilterFieldView textContent={sortedProjects && Array.isArray(sortedProjects) && sortedProjects.find((item) => item._id === selectedProject[0])?.project_name} />
                                  {selectedProject.length > 1 && selectedProject.length !== sortedProjects.length && (
                                    <div className="selected-option-count">
                                      {selectedProject.length - 1}+
                                    </div>
                                  )}
                                </>
                              )}
                          </div>
                        </>
                      )}
                    </div>
                    {isProjectModalDropdownOpen && (
                      <div className="dropdown-list1">
                        <input
                          type="text"
                          placeholder="Search projects"
                          value={projectDropdownSearchTerm}
                          onChange={handleProjectDropdownSearchChange}
                          className="search-bar"
                        />
                        {filteredDropdownProjects.length > 0 ? (
                          <>
                            {projectDropdownSearchTerm === "" && (
                              <label className="checkbox-label1" key="selectAll">
                                <input
                                  type="checkbox"
                                  checked={selectAllProject}
                                  onChange={handleSelectAllProjectChange}
                                />
                                All
                              </label>
                            )}
                            {filteredDropdownProjects
                              .slice()
                              .sort((a, b) =>
                                a.project_name?.toLowerCase().localeCompare(b.project_name?.toLowerCase())
                              )
                              .map((project) => (
                                <label className="checkbox-label1" key={project._id}>
                                  <input
                                    type="checkbox"
                                    checked={selectedProject.includes(project._id)}
                                    onChange={() => handleCheckboxProjectChange(project._id)}
                                  />
                                  {project.project_name && `${project.project_name}`}
                                </label>
                              ))}
                          </>
                        ) : (
                          <label className="checkbox-label1">
                            No projects found
                          </label>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* <div className="filter-inner due-date">
                <label htmlFor="dueDate" className="calendar-icon">
                  Timeframe
                </label>
                <div className="date-picker-outer date-picker-outer-all">
                  <div className="custom-picker-icon custom-picker-icon-all">
                    <svg
                      width="1em"
                      height="1em"
                      viewBox="0 0 14 14"
                      fill="currentColor"
                      aria-hidden="true"
                      focusable="false"
                      className="rs-picker-toggle-caret rs-icon"
                      aria-label="calendar"
                      data-category="legacy"
                    >
                      <path d="M1 4v8.5a.5.5 0 00.5.5H8c0-.128.049-.256.146-.354.555-.555.854-1.6.854-3.146a.5.5 0 01.621-.485l.119.03A2.623 2.623 0 0012.999 6.5V4h-12zm3-2h6V0h1v2h1.5A1.5 1.5 0 0114 3.5v3a3.623 3.623 0 01-4.015 3.603c-.064 1.245-.335 2.212-.831 2.898H12.5a.5.5 0 00.5-.5v-2a.5.5 0 011 0v2a1.5 1.5 0 01-1.5 1.5h-11a1.5 1.5 0 01-1.5-1.5v-9a1.5 1.5 0 011.5-1.5H3v-2h1v2zm5 4V5h1v1h2v1h-2v1H9V7H7v2h1v1H7v2H6v-2H4v2H3v-2H1V9h2V7H1V6h2V5h1v1h2V5h1v1h2zM6 9V7H4v2h2z"></path>
                    </svg>
                  </div>
                  <div className="custoom-icon-calender custoom-icon-calender-all">

                    <div className="custoom-icon-calender custoom-icon-calender-all custom-date-range">
                      <DatePicker
                        selected={year}
                        // onChange={(date) => setYear(new Date(date.getFullYear(), 0, 1))}
                        onChange={(date) => {
                          const selectedYear = date.getFullYear();
                          const selectedDateYear = new Date(date.getFullYear(), 0, 1);
                          setYear(selectedDateYear);
                          selectYear(selectedYear);
                        }}
                        showYearPicker
                        dateFormat="yyyy"
                      />
                    </div>

                  </div>
                </div>
              </div> */}
            </div>

            <div className="filter-buttons">
              <button className="clear-all" onClick={clearFilters}>Clear All</button>
              <button className="apply-filter" onClick={applyFilters}>
                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="17" viewBox="0 0 13 17" fill="none">
                  <path d="M11.7363 8.81108C11.4276 9.81451 10.4925 10.5444 9.38889 10.5444C8.28471 10.5444 7.35016 9.8141 7.04147 8.81108L0.722221 8.81108C0.323349 8.81108 -1.07845e-06 8.48773 -1.06101e-06 8.08886C-1.04357e-06 7.68998 0.323349 7.36663 0.722221 7.36663L7.04144 7.36663C7.35022 6.3632 8.28529 5.6333 9.38889 5.6333C10.4931 5.6333 11.4276 6.36362 11.7363 7.36663L12.2778 7.36663C12.6767 7.36663 13 7.68998 13 8.08886C13 8.48773 12.6766 8.81108 12.2778 8.81108L11.7363 8.81108ZM9.38889 7.07775C8.83162 7.07775 8.37778 7.53123 8.37778 8.08886C8.37778 8.64613 8.83127 9.09997 9.38889 9.09997C9.94616 9.09997 10.4 8.64648 10.4 8.08886C10.4 7.53158 9.94651 7.07775 9.38889 7.07775Z" fill="white" />
                  <path d="M5.38078 3.17778C5.072 4.18121 4.13693 4.91111 3.03333 4.91111C1.92882 4.91111 0.994035 4.18035 0.68563 3.17687C0.303759 3.15781 -1.07791e-06 2.84216 -1.06101e-06 2.45556C-1.04411e-06 2.06896 0.303748 1.75331 0.685606 1.73425C0.994098 0.730343 1.9294 -4.83911e-07 3.03333 -4.35657e-07C4.13752 -3.87392e-07 5.07206 0.730315 5.38076 1.73333L12.2778 1.73333C12.6767 1.73333 13 2.05668 13 2.45556C13 2.85443 12.6766 3.17778 12.2778 3.17778L5.38078 3.17778ZM3.03333 1.44444C2.47606 1.44444 2.02222 1.89793 2.02222 2.45556C2.02222 3.01283 2.47571 3.46667 3.03333 3.46667C3.5906 3.46667 4.04444 3.01318 4.04444 2.45556C4.04444 1.89828 3.59096 1.44444 3.03333 1.44444Z" fill="white" />
                  <path d="M7.403 14.4444C7.09422 15.4478 6.15916 16.1777 5.05556 16.1777C3.95137 16.1777 3.01682 15.4474 2.70813 14.4444L0.722221 14.4444C0.323349 14.4444 -1.07845e-06 14.121 -1.06101e-06 13.7222C-1.04357e-06 13.3233 0.323349 12.9999 0.722221 12.9999L2.70811 12.9999C3.01689 11.9965 3.95195 11.2666 5.05556 11.2666C6.15974 11.2666 7.09429 11.9969 7.40298 12.9999L12.2778 12.9999C12.6767 12.9999 13 13.3233 13 13.7222C13 14.121 12.6766 14.4444 12.2778 14.4444L7.403 14.4444ZM5.05556 12.711C4.49828 12.711 4.04444 13.1645 4.04444 13.7222C4.04444 14.2794 4.49793 14.7333 5.05556 14.7333C5.61283 14.7333 6.06667 14.2798 6.06667 13.7222C6.06667 13.1649 5.61318 12.711 5.05556 12.711Z" fill="white" />
                </svg>
                Apply Filters
              </button>
            </div>
          </div>
        </Modal>

        <Modal
          isOpen={showConfirmationModal}
          onRequestClose={() => setShowConfirmationModal(false)}
          contentLabel="Confirmation Modal"
          ariaHideApp={false}
          className="confirmation-modal"
          overlayClassName="confirmation-modal-overlay"
        >
          <div className="modal-content">
            {/* <h2>Are you sure?</h2> */}
            <p>Are you sure you want to clear the filters?</p>
            <div className="modal-footer">
              <button className="cancel-btn" onClick={() => setShowConfirmationModal(false)}>
                Cancel
              </button>
              <button className="confirm-btn" onClick={handleApplyConfirm}>
                Yes, Clear
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
}

export default ReportingClientTimeWorkedFilter;
