import axios from "axios";
import "./Security.css";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import userCategoryName from "../../../constants/userCategory";
import { logout, reset } from "../../../features/auth/authSlice";
import { toggleNotification } from '../../../features/auth/authSlice';
import { reset as invoiceReset } from "../../../features/invoice/invoiceSlice.js";
import { setNotification } from "../../../features/notification/notificationSlice.js";
import { reset as headerReset } from "../../../features/headerText/headerTextSlice.js";
import { reset as appSettingsReset } from "../../../features/appSettings/appSettingsSlice.js";

const SecuritySettings = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [isNotificationLoading, setIsNotificationLoading] = useState(false);
    const [userView, setUserView] = useState([]);
    const user = useSelector((state) => state.auth.user);
    const { appSettings } = useSelector((state) => state.appSettings);


    let token = null;
    if (user) {
        if (user && user.token) {
            token = user.token;
        }
    }

    // useEffect(() => {

    //     async function fetchUser() {
    //         setIsLoading(true);
    //         try {

    //             const response = await axios.get(`/api/get/app-settings/${appSettings && appSettings.appId}`, {
    //                 headers: {
    //                     Authorization: `Bearer ${token}`,
    //                 },
    //             });
    //             setUserView(response.data);
    //             setIsLoading(false);

    //         } catch (error) {
    //             console.log(error);
    //             if (error.response?.data?.status === "deleted") {
    //                 localStorage.removeItem("user");
    //                 dispatch(logout());
    //                 dispatch(reset());
    //                 dispatch(invoiceReset());
    //                 dispatch(headerReset());
    //                 dispatch(appSettingsReset());
    //                 toast.error('This user is deleted!', {
    //                     position: "bottom-right",
    //                     autoClose: 1500,
    //                     hideProgressBar: false,
    //                     closeOnClick: true,
    //                     pauseOnHover: true,
    //                     draggable: true,
    //                     progress: undefined,
    //                     theme: "light",
    //                 });
    //                 navigate("/");
    //                 return;
    //             }
    //             global.config.slackMessage(error.toString());
    //             alert("Something went wrong in fetching users");
    //         }
    //     }

    //     fetchUser().then(() => { });
    // }, [props.id]);

    useEffect(() => {
        const fetchUser = async () => {
          setIsLoading(true);
          try {
            // Using setTimeout to defer execution and avoid blocking
            setTimeout(async () => {
              const response = await axios.get(`/api/get/app-settings/${appSettings?.appId}`, {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              });
              setUserView(response.data);
              setIsLoading(false);
            }, 0);
          } catch (error) {
            console.log(error);
            if (error.response?.data?.status === "deleted") {
              localStorage.removeItem("user");
              dispatch(logout());
              dispatch(reset());
              dispatch(invoiceReset());
              dispatch(headerReset());
              dispatch(appSettingsReset());
              toast.error('This user is deleted!', {
                position: "bottom-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
              navigate("/");
              return;
            }
            global.config.slackMessage(error.toString());
            alert("Something went wrong in fetching users");
          }
        };
      
        fetchUser(); // Call the async function
      
      }, [props.id]); // This effect runs when props.id changes



    const handleEnableNotificationChange = async (id) => {
        try {
            setIsNotificationLoading(true);
            await axios.get(`/api/app-settings/twofa/update/${appSettings && appSettings.appId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            toast.success(`Force 2-Factor authentication updated`, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            global.config.activityLog(
                window.location.href,
                "App Settings",
                `App settings updated successfully`
            );


            // if (user._id === id) {
            //     dispatch(toggleNotification());
            // }
            // dispatch(setNotification(Date.now()));
            setUserView((prevState) => {
                if (prevState.appId === `${appSettings && appSettings.appId}`) {
                    return { ...prevState, twoFa: !prevState.twoFa };
                }
                return prevState;
            });


        } catch (error) {
            if (error.response?.data?.status === "deleted") {
                localStorage.removeItem("user");
                dispatch(logout());
                dispatch(reset());
                dispatch(invoiceReset());
                dispatch(headerReset());
                dispatch(appSettingsReset());
                toast.error('This user is deleted!', {
                    position: "bottom-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                navigate("/");
                return;
            }
            toast.error("Something went wrong in changing user notification status!", {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            global.config.slackMessage(error.toString());
            global.config.activityLog(
                window.location.href,
                "User",
                `Something went wrong while changing ${userView && userView.user_category && [userCategoryName.Business, userCategoryName.Vendor, userCategoryName.Client].includes(userView.user_category) ? userView.company_name : userView.first_name}'s Notification Status`
            );
            console.log(error);
        } finally {
            setIsNotificationLoading(false);
        }
    };

    return (
        <div>
            <div>
                <div>
                    <div className="security-outer noti-outer security-2fa">
                        <div className="security-inner noti-enable noti-head-noti">
                            <span className="custom-switch-description">
                                {global.config.locate(appSettings && appSettings.language, 'Force_2-Factor_Authentication_for_all_users')}
                            </span>
                            <div className="toggle-spinner">
                                {isLoading === true && (
                                    <ThreeDots
                                        height="18"
                                        width="20"
                                        radius="9"
                                        color="#6479f9"
                                        ariaLabel="three-dots-loading"
                                        wrapperStyle={{}}
                                        wrapperClassName=""
                                        visible={true}
                                    />
                                )}

                                {isNotificationLoading === true && (
                                    <ThreeDots
                                        height="18"
                                        width="20"
                                        radius="9"
                                        color="#6479f9"
                                        ariaLabel="three-dots-loading"
                                        wrapperStyle={{}}
                                        wrapperClassName=""
                                        visible={true}
                                    />)}

                                {isLoading === false && (
                                    <label className="custom-switch">
                                        <input
                                            type="checkbox"
                                            className="custom-switch-input"
                                            id="emailnotification"
                                            name="enable_emailnotification"
                                            value="1"
                                            style={{ cursor: "pointer" }}
                                            checked={userView.twoFa}
                                            onChange={() => handleEnableNotificationChange(userView.twoFa)}
                                        />
                                        <span style={{ cursor: "pointer" }} className="custom-switch-slider"></span>
                                    </label>)}
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    );
};

export default SecuritySettings;