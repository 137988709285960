import React from 'react'
import { ThreeDots } from 'react-loader-spinner'
import { Parser } from 'html-to-react';
import userCategoryName from "../../../../../constants/userCategory";

const ClientInvoiceAddress = (invoiceData) => {
    return (
        <>
            <div className="invo-from-to">
                {invoiceData.invoiceData && invoiceData.invoiceData.cli_inv_vendor && invoiceData.invoiceData.cli_inv_vendor.wx_company ?
                    (
                        <div className="invo-from-sing">
                            <div className="from-sing-head">From</div>
                            <div className="name-sing-head">{invoiceData.invoiceData ? (`${String(invoiceData?.invoiceData?.cli_inv_vendor?.legal_entity_company_name) === "null" ? "" : invoiceData?.invoiceData?.cli_inv_vendor?.legal_entity_company_name}` || `${String(invoiceData?.invoiceData?.cli_inv_vendor?.wx_company) === "null" ? "" : invoiceData?.invoiceData?.cli_inv_vendor?.wx_company}`) : (<ThreeDots height="18" width="20" radius="9" color="#6479f9" ariaLabel="three-dots-loading" wrapperStyle={{}} wrapperClassName="" visible={true} />)}</div>
                            <div className="addres-sing-head styll2">{Parser().parse(invoiceData.invoiceData ? (invoiceData?.invoiceData?.cli_inv_vendor?.address) : (<ThreeDots height="18" width="20" radius="9" color="#6479f9" ariaLabel="three-dots-loading" wrapperStyle={{}} wrapperClassName="" visible={true} />))}</div>
                            <div className="tele-sing-head styll2"> {invoiceData.invoiceData ? (`Tel: ${invoiceData?.invoiceData?.cli_inv_vendor?.mobile_no}`) : (<ThreeDots height="18" width="20" radius="9" color="#6479f9" ariaLabel="three-dots-loading" wrapperStyle={{}} wrapperClassName="" visible={true} />)}</div>
                            <div className="mail-sing-head styll2"> {invoiceData.invoiceData ? (`Email: ${invoiceData?.invoiceData?.cli_inv_vendor?.email}`) : (<ThreeDots height="18" width="20" radius="9" color="#6479f9" ariaLabel="three-dots-loading" wrapperStyle={{}} wrapperClassName="" visible={true} />)}</div>
                        </div>
                    ) : (
                        <div className="invo-from-sing">
                            <div className="from-sing-head">From</div>
                            <div className="name-sing-head">{invoiceData.invoiceData ? (`${String(invoiceData?.invoiceData?.cli_inv_from?.commercial_name) === "null" ? "" : invoiceData?.invoiceData?.cli_inv_from?.commercial_name}` || `${String(invoiceData?.invoiceData?.cli_inv_from?.legal_entity_name) === "null" ? "" : invoiceData?.invoiceData?.cli_inv_from?.legal_entity_name}`) : (<ThreeDots height="18" width="20" radius="9" color="#6479f9" ariaLabel="three-dots-loading" wrapperStyle={{}} wrapperClassName="" visible={true} />)}</div>
                            <div className="addres-sing-head styll2">{invoiceData.invoiceData ? (invoiceData?.invoiceData?.cli_inv_from?.address) : (<ThreeDots height="18" width="20" radius="9" color="#6479f9" ariaLabel="three-dots-loading" wrapperStyle={{}} wrapperClassName="" visible={true} />)}</div>
                            <div className="tele-sing-head styll2"> {invoiceData.invoiceData ? (`Tel: ${invoiceData?.invoiceData?.cli_inv_from?.mobile_no}`) : (<ThreeDots height="18" width="20" radius="9" color="#6479f9" ariaLabel="three-dots-loading" wrapperStyle={{}} wrapperClassName="" visible={true} />)}</div>
                            <div className="mail-sing-head styll2"> {invoiceData.invoiceData ? (`Email: ${invoiceData?.invoiceData?.cli_inv_from?.email}`) : (<ThreeDots height="18" width="20" radius="9" color="#6479f9" ariaLabel="three-dots-loading" wrapperStyle={{}} wrapperClassName="" visible={true} />)}</div>
                        </div>
                    )}
                <div className="invo-to-sing">
                    <div className="to-sing-head">To</div>
                    <div className="toname-sing-head">
                        {invoiceData.invoiceData ? (
                            `${String(invoiceData?.invoiceData?.cli_inv_for?.legal_entity_name) !== "null" && invoiceData?.invoiceData?.cli_inv_for?.legal_entity_name.trim() !== ""
                                ? invoiceData?.invoiceData?.cli_inv_for?.legal_entity_name
                                : invoiceData?.invoiceData?.cli_inv_for?.client_name}`
                        ) : (
                            <ThreeDots
                                height="18"
                                width="20"
                                radius="9"
                                color="#6479f9"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                            />
                        )}
                    </div>
                    <div className="toaddres-sing-head styll2">{invoiceData.invoiceData ? (invoiceData?.invoiceData?.cli_inv_for?.address) : (<ThreeDots height="18" width="20" radius="9" color="#6479f9" ariaLabel="three-dots-loading" wrapperStyle={{}} wrapperClassName="" visible={true} />)}</div>
                    <div className="totele-sing-head styll2"> {invoiceData.invoiceData ? (`Tel: ${invoiceData?.invoiceData?.cli_inv_for?.mobile_no}`) : (<ThreeDots height="18" width="20" radius="9" color="#6479f9" ariaLabel="three-dots-loading" wrapperStyle={{}} wrapperClassName="" visible={true} />)}</div>
                    <div className="tomail-sing-head styll2"> {invoiceData.invoiceData ? (`Email: ${invoiceData?.invoiceData?.cli_inv_for?.email}`) : (<ThreeDots height="18" width="20" radius="9" color="#6479f9" ariaLabel="three-dots-loading" wrapperStyle={{}} wrapperClassName="" visible={true} />)}</div>
                </div>
            </div>
        </>
    )
}

export default ClientInvoiceAddress