// import axios from "axios";
import * as XLSX from "xlsx";
import "./TimeTrackerOverview.css";
// import { DatePicker } from "rsuite";
import Modal from "react-modal";
// import { DateRangePicker } from "rsuite";
import { toast } from "react-toastify";
// import { useNavigate } from "react-router-dom";
import isEqual from "lodash/isEqual";
import { useReactToPrint } from "react-to-print";
import TimeTable from "./TimeTrackerOverviewTable.jsx";
import { useSelector, useDispatch } from "react-redux";
import OverviewTotal from "./TimeTrackerOverviewTotal.jsx";
import React, { useState, useRef, useEffect } from "react";
import { useQueryClient } from "react-query";
import PrintIcon from "../../../images/icons/printwhite.svg";
import userTypeConsts from "../../../constants/userTypeConsts.js";
import TimeTrackerOverviewGraph from "./TimeTrackerOverviewGraph.jsx";
import DownloadImage from "../../../images/icons/download-icon.svg";
import TrackerOverviewPieChart from "./TimeTrackerOverviewPiechart.jsx";
import TimeTrackerOverviewPrintTable from "./TimeTrackerOverviewPrintTable.jsx";
import TruncateFilterFieldView from "../../helpers/truncateFilterFieldView.js";
import Calendar from "../../../helpers/dateComponents/Calendar.jsx";
import { motion } from 'framer-motion';
import {
  useGetAllClients,
  useGetAllProjects,
  useGetClients,
  useGetDepartments,
  useGetProjects,
  useGetSprints,
  useGetTags,
  useGetTeams,
  useGetTimerData,
} from "./useTimeTracker.jsx";
import {
  setTeam,
  setProject,
  setClient,
  setTag,
  setSprint,
  setDepartment,
  setPerPage,
  setTask,
} from "../../../features/timeTracker/timeTrackerSlice.js";
import moment from "moment";
import TimestampConverter from "../Timer/tracker/helpers/TimestampConverter.jsx";

const TimeTrackerOverview = () => {
  const dispatch = useDispatch();
  const [dates, setDates] = useState(new Date());
  const firstDayOfMonth = moment
    .tz(global.config.getTimeZone())
    .startOf("month")
    .toDate(); //new Date(new Date().getFullYear(), new Date().getMonth(), 1);

  const now = new Date();
  const startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate());

  const [showCalendar, setShowCalendar] = useState(false);
  const [showModalCalendar, setShowModalCalendar] = useState(false);
  const [dateRange, setDateRange] = useState([firstDayOfMonth, startOfDay]);
  const [perPage, setPerPages] = useState(10);
  const [showingDropdownOpen, setShowingDropdownOpen] = useState(false);
  const tracker = useSelector((state) => state.tracker);
  const timerData = useSelector((state) => state.timerData.timerDataState);
  const [selClient, setSelClient] = useState(null);
  const componentRef = useRef();
  const [taskName, setTaskName] = useState('');
  const [selectAllTeam, setSelectAllTeam] = useState(true);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [isTeamDropdownOpen, setIsTeamDropdownOpen] = useState(false);
  const [isTeamModalDropdownOpen, setIsTeamModalDropdownOpen] = useState(false);
  const [isUserStatusDropdownOpen, setIsUserStatusDropdownOpen] = useState(false);
  const [isUserStatusModalDropdownOpen, setIsUserStatusModalDropdownOpen] = useState(false);
  const [userStatusFilter, setUserStatusFilter] = useState("Active");
  const [userSearchTerm, setUserSearchTerm] = useState('');
  const [selectAllClient, setSelectAllClient] = useState(true);
  const [selectedClients, setSelectedClients] = useState([]);
  const [isClientDropdownOpen, setIsClientDropdownOpen] = useState(false);
  const [isClientModalDropdownOpen, setIsClientModalDropdownOpen] = useState(false);
  const [clientSearchTerm, setClientSearchTerm] = useState('');
  const [selectAllProject, setSelectAllProject] = useState(true);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [isProjectDropdownOpen, setIsProjectDropdownOpen] = useState(false);
  const [isProjectModalDropdownOpen, setIsProjectModalDropdownOpen] = useState(false);
  const [isProjectStatusDropdownOpen, setIsProjectStatusDropdownOpen] = useState(false);
  const [isProjectStatusModalDropdownOpen, setIsProjectStatusModalDropdownOpen] = useState(false);
  const [projectStatusFilter, setProjectStatusFilter] = useState("All");
  const [projectSearchTerm, setProjectSearchTerm] = useState('');
  const [selectAllDept, setSelectAllDept] = useState(true);
  const [selectedDepts, setSelectedDepts] = useState([]);
  const [isDeptDropdownOpen, setIsDeptDropdownOpen] = useState(false);
  const [isDeptModalDropdownOpen, setIsDeptModalDropdownOpen] = useState(false);
  const [deptSearchTerm, setDeptSearchTerm] = useState('');
  const [selectAllTag, setSelectAllTag] = useState(true);
  const [selectedTags, setSelectedTags] = useState([]);
  const [isTagDropdownOpen, setIsTagDropdownOpen] = useState(false);
  const [isTagModalDropdownOpen, setIsTagModalDropdownOpen] = useState(false);
  const [tagSearchTerm, setTagSearchTerm] = useState('');
  const [selectAllSprint, setSelectAllSprint] = useState(true);
  const [selectedSprints, setSelectedSprints] = useState([]);
  const [isSprintDropdownOpen, setIsSprintDropdownOpen] = useState(false);
  const [isSprintModalDropdownOpen, setIsSprintModalDropdownOpen] = useState(false);
  const [sprintSearchTerm, setSprintSearchTerm] = useState('');
  const teamDropdownRef = useRef(null);
  const teamModalDropdownRef = useRef(null);
  const userStatusDropdownRef = useRef(null);
  const userStatusModalDropdownRef = useRef(null);
  const projectDropdownRef = useRef(null);
  const projectModalDropdownRef = useRef(null);
  const projectStatusDropdownRef = useRef(null);
  const projectStatusModalDropdownRef = useRef(null);
  const clientDropdownRef = useRef(null);
  const clientModalDropdownRef = useRef(null);
  const deptDropdownRef = useRef(null);
  const deptModalDropdownRef = useRef(null);
  const tagDropdownRef = useRef(null);
  const tagModalDropdownRef = useRef(null);
  const sprintDropdownRef = useRef(null);
  const sprintModalDropdownRef = useRef(null);
  const showingDropdownRef = useRef(null);
  const calendarRef = useRef(null);
  const calendarModalRef = useRef(null);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const { appSettings } = useSelector((state) => state.appSettings);
  const token = useSelector((state) => state.auth.token);

  const { data: teams = [], isLoading: teamLoading, isError: teamError, } = useGetTeams(token);
  const { data: clients = [], isLoading: clientLoading, isError: clientError, } = useGetAllClients(token);
  const { data: projects = [], isLoading: projectLoading, isError: projectError, } = useGetAllProjects(token);
  const { data: departments = [], isLoading: departmentLoading, isError: departmentError, } = useGetDepartments(token);
  const { data: tags = [], isLoading: tagsLoading, isError: tagsError, } = useGetTags(token);
  const { data: sprints = [], isLoading: sprintsLoading, isError: sprintsError, } = useGetSprints(token);
  
  // const { data: timerDatas = [], isLoading: timerLoading, refetch: refetchAll } = useGetTimerData(
  //   token,
  //   tracker,
  //   // dates // Use the updated date
  //   dateRange[0] || null,
  //   dateRange[1] || null
  // );

  // useEffect(() => {
  //   // console.log('timerData',timerData);
  //   refetchAll();
  // }, [timerData]);

  const { data: timerDatas = [], isLoading: timerLoading, refetch: refetchAll } = useGetTimerData(
    token,
    tracker,
    dateRange[0] || null,
    dateRange[1] || null
  );
  
  // Debounce effect for fetching timer data
  useEffect(() => {
    const fetchWithDebounce = setTimeout(() => {
      refetchAll(); // Fetch timer data
    }, 300); // 300ms debounce delay
  
    return () => clearTimeout(fetchWithDebounce); // Cleanup to prevent unnecessary calls
  }, [tracker, dateRange]); // Dependencies that trigger the refetch
  

  const handleTaskNameChange = async (e) => {
    const value = e.target.value;
    setTaskName(value);
    await dispatch(setTask(value));
  };

  const filteredUsers = teams.filter((user) => {
    const fullName = user.first_name
      ? `${user.first_name} ${user.middle_name || ''} ${user.last_name}`
      : `${user.company_first_name ? user.company_first_name + (user.company_last_name && user.company_last_name) : user.company_name}`;
    return fullName.toLowerCase().includes(userSearchTerm.toLowerCase());
  });

  const handleUserSearchChange = (e) => {
    setUserSearchTerm(e.target.value);
  };

  const toggleUserStatusDropdown = () => {
    setIsUserStatusDropdownOpen(!isUserStatusDropdownOpen);
  };

  const toggleUserStatusModalDropdown = () => {
    setIsUserStatusModalDropdownOpen(!isUserStatusModalDropdownOpen);
  };

  const handleUserStatusChange = (status) => {
    setUserStatusFilter(status);
    const filteredTeamIds = teams && teams
      .filter((team) =>
        status === "All" ? true : status === "Active" ? team.is_active === true : team.is_active === false
      )
      .map((team) => team._id);

    const updatedSelectedTeams = selectedTeams.filter((teamId) =>
      filteredTeamIds.includes(teamId)
    );
    setSelectedTeams([]);
    setSelectAllTeam(false);
  };


  const timerDataa = useSelector((state) => state.timerData.timerDataState);
  const handleCheckboxTeamChange = (teamId) => {
    const updatedSelection = [...selectedTeams];
    const index = updatedSelection.indexOf(teamId);

    if (index === -1) {
      // Add to selection
      updatedSelection.push(teamId);
    } else {
      // Remove from selection
      updatedSelection.splice(index, 1);
    }
    setSelectedTeams(updatedSelection);
  };

  const handleSelectAllTeamChange = () => {
    const filteredTeamIds = teams && teams
      .filter((team) => userStatusFilter === "All" || (userStatusFilter === "Active" ? team.is_active === true : team.is_active === false))
      .map((team) => team._id);

    if (!selectAllTeam) {
      setSelectedTeams(filteredTeamIds);
    } else {
      setSelectedTeams([]);
    }

    setSelectAllTeam(!selectAllTeam);
  };

  useEffect(() => {
    const filteredTeamIds = teams && teams
      .filter((team) => userStatusFilter === "All" || (userStatusFilter === "Active" ? team.is_active === true : team.is_active === false))
      .map((team) => team._id);

    setSelectAllTeam(
      filteredTeamIds.length > 0 && selectedTeams.length === filteredTeamIds.length
    );
    if (selectedTeams.length === filteredTeamIds.length) {
      selectTeam(filteredTeamIds.length > 0 ? filteredTeamIds : selectedTeams);
    } else {
      selectTeam(selectedTeams);
    }
  }, [selectedTeams, teams]);

  const toggleTeamDropdown = () => {
    setIsTeamDropdownOpen(!isTeamDropdownOpen);
    setUserSearchTerm('');
  };

  const toggleTeamModalDropdown = () => {
    setIsTeamModalDropdownOpen(!isTeamModalDropdownOpen);
    setUserSearchTerm('');
  };

  const filteredClients = clients && clients
    .filter((client) =>
      client && client?.client_name?.toLowerCase().includes(clientSearchTerm && clientSearchTerm?.toLowerCase())
    )
    .sort((a, b) =>
      a.client_name?.toLowerCase().localeCompare(b.client_name?.toLowerCase())
    );

  const handleClientSearchChange = (e) => {
    setClientSearchTerm(e.target.value);
  };

  const handleCheckboxClientChange = (clientId) => {
    const updatedSelection = [...selectedClients];
    const index = updatedSelection.indexOf(clientId);

    if (index === -1) {
      // Add to selection
      updatedSelection.push(clientId);
    } else {
      // Remove from selection
      updatedSelection.splice(index, 1);
    }

    setSelectedClients(updatedSelection);
  };

  const handleSelectAllClientChange = () => {
    if (!selectAllClient) {
      setSelectedClients(clients.map((client) => client._id));
    } else {
      setSelectedClients([]);
    }
    setSelectAllClient(!selectAllClient);
  };

  useEffect(() => {
    setSelectAllClient(selectedClients.length === clients.length);
    if (selectedClients.length === clients.length) {
      selectClient(null);
    } else {
      selectClient(selectedClients);
    }
  }, [selectedClients, clients]);

  const toggleClientDropdown = () => {
    setIsClientDropdownOpen(!isClientDropdownOpen);
    setClientSearchTerm('');
  };

  const toggleClientModalDropdown = () => {
    setIsClientModalDropdownOpen(!isClientModalDropdownOpen);
    setClientSearchTerm('');
  };

  const filteredProjects = projects && projects.filter((project) =>
    project && project.project_name
      ?.toLowerCase()
      .includes(projectSearchTerm?.toLowerCase())
  )
    .sort((a, b) =>
      a.project_name?.toLowerCase().localeCompare(b.project_name?.toLowerCase())
    );

  const handleProjectSearchChange = (e) => {
    setProjectSearchTerm(e.target.value);
  };

  const toggleProjectStatusDropdown = () => {
    setIsProjectStatusDropdownOpen(!isProjectStatusDropdownOpen);
  };

  const toggleProjectStatusModalDropdown = () => {
    setIsProjectStatusModalDropdownOpen(!isProjectStatusModalDropdownOpen);
  };

  const handleProjectStatusChange = (status) => {
    setProjectStatusFilter(status);
    const filteredProjectIds = projects && projects
      .filter((project) =>
        status === "All" ? true : status === "Active" ? project.is_active === true : project.is_active === false
      )
      .map((project) => project._id);

    const updatedSelectedProjects = selectedProjects.filter((projectId) =>
      filteredProjectIds.includes(projectId)
    );


    setSelectedProjects([]);
    setSelectAllProject(false);
    // setIsUserStatusDropdownOpen(false);
  };

  const handleCheckboxProjectChange = (projectId) => {
    const updatedSelection = [...selectedProjects];
    const index = updatedSelection.indexOf(projectId);

    if (index === -1) {
      // Add to selection
      updatedSelection.push(projectId);
    } else {
      // Remove from selection
      updatedSelection.splice(index, 1);
    }

    setSelectedProjects(updatedSelection);
  };

  const handleSelectAllProjectChange = () => {
    const filteredProjectIds = projects && projects
      .filter((project) => projectStatusFilter === "All" || (projectStatusFilter === "Active" ? project.is_active === true : project.is_active === false))
      .map((project) => project._id);
    if (!selectAllProject) {
      setSelectedProjects(filteredProjectIds);
    } else {
      setSelectedProjects([]);
    }
    setSelectAllProject(!selectAllProject);
  };

  // useEffect(() => {
  //   const filteredProjectIds = projects && projects
  //     .filter((project) => projectStatusFilter === "All" || (projectStatusFilter === "Active" ? project.is_active === true : project.is_active === false))
  //     .map((project) => project._id);
  //   setSelectAllProject(filteredProjectIds.length > 0 && selectedProjects.length === filteredProjectIds.length);
  //   if (selectedProjects.length === filteredProjectIds.length) {
  //     selectProject(filteredProjectIds.length > 0 ? filteredProjectIds : selectedProjects);
  //   } else {
  //     selectProject(selectedProjects);
  //   }
  // }, [selectedProjects, projects]);


  useEffect(() => {
    const fetchAndSetSelections = async () => {
      try {
        // Asynchronously filter project IDs based on the selected filter
        const filteredProjectIds = await new Promise((resolve) => {
          resolve(
            projects
              ? projects
                .filter(
                  (project) =>
                    projectStatusFilter === "All" ||
                    (projectStatusFilter === "Active"
                      ? project.is_active === true
                      : project.is_active === false)
                )
                .map((project) => project._id)
              : []
          );
        });

        // Check if all projects are selected based on filtered IDs
        setSelectAllProject(
          filteredProjectIds.length > 0 &&
          selectedProjects.length === filteredProjectIds.length
        );

        // If selected projects match filtered projects, select them
        if (selectedProjects.length === filteredProjectIds.length) {
          selectProject(
            filteredProjectIds.length > 0 ? filteredProjectIds : selectedProjects
          );
        } else {
          selectProject(selectedProjects);
        }
      } catch (error) {
        console.error("Error during selection:", error);
      }
    };

    // Call async function for filtering and selecting projects
    fetchAndSetSelections();
  }, [selectedProjects, projects, projectStatusFilter]);



  const toggleProjectDropdown = () => {
    setIsProjectDropdownOpen(!isProjectDropdownOpen);
    setProjectSearchTerm('');
  };

  const toggleProjectModalDropdown = () => {
    setIsProjectModalDropdownOpen(!isProjectModalDropdownOpen);
    setProjectSearchTerm('');
  };

  const filteredDepts = departments && departments
    .filter((dept) =>
      dept && dept.user_department?.toLowerCase().includes(deptSearchTerm && deptSearchTerm?.toLowerCase()))
    .sort((a, b) =>
      a.user_department?.toLowerCase().localeCompare(b.user_department?.toLowerCase())
    );

  const handleDeptSearchChange = (e) => {
    setDeptSearchTerm(e.target.value);
  };

  const handleCheckboxDeptChange = (deptId) => {
    const updatedSelection = [...selectedDepts];
    const index = updatedSelection.indexOf(deptId);

    if (index === -1) {
      // Add to selection
      updatedSelection.push(deptId);
    } else {
      // Remove from selection
      updatedSelection.splice(index, 1);
    }

    setSelectedDepts(updatedSelection);
  };

  const handleSelectAllDeptChange = () => {
    if (!selectAllDept) {
      setSelectedDepts(departments.map((dept) => dept._id));
    } else {
      setSelectedDepts([]);
    }
    setSelectAllDept(!selectAllDept);
  };

  // useEffect(() => {
  //   setSelectAllDept(selectedDepts.length === departments.length);
  //   if (selectedDepts.length === departments.length) {
  //     selectDepartment(null);
  //   } else {
  //     selectDepartment(selectedDepts);
  //   }
  // }, [selectedDepts, departments]);

  useEffect(() => {
    const updateSelection = async () => {
      try {
        // Check if all departments are selected
        const areAllDepartmentsSelected = selectedDepts.length === departments.length;

        // Update the select-all status
        setSelectAllDept(areAllDepartmentsSelected);

        // Conditionally select all or the specific departments
        if (areAllDepartmentsSelected) {
          selectDepartment(null); // Deselect all departments if they are fully selected
        } else {
          selectDepartment(selectedDepts); // Select the chosen departments
        }
      } catch (error) {
        console.error("Error during department selection:", error);
      }
    };

    // Call async function for selecting departments
    updateSelection();
  }, [selectedDepts, departments]); // Re-run when selectedDepts or departments change


  const toggleDeptDropdown = () => {
    setIsDeptDropdownOpen(!isDeptDropdownOpen);
    setDeptSearchTerm('');
  };

  const toggleDeptModalDropdown = () => {
    setIsDeptModalDropdownOpen(!isDeptModalDropdownOpen);
    setDeptSearchTerm('');
  };

  const filteredTags = tags && tags
    .filter((item) => item && item.tag_name?.toLowerCase().includes(tagSearchTerm && tagSearchTerm?.toLowerCase()))
    .sort((a, b) => a.tag_name.localeCompare(b.tag_name));

  const handleTagSearchChange = (e) => {
    setTagSearchTerm(e.target.value);
  };

  const handleCheckboxTagChange = (tagId) => {
    const updatedSelection = [...selectedTags];
    const index = updatedSelection.indexOf(tagId);

    if (index === -1) {
      // Add to selection
      updatedSelection.push(tagId);
    } else {
      // Remove from selection
      updatedSelection.splice(index, 1);
    }

    setSelectedTags(updatedSelection);
  };

  const handleSelectAllTagChange = () => {
    if (!selectAllTag) {
      setSelectedTags(tags.map((tag) => tag._id));
    } else {
      setSelectedTags([]);
    }
    setSelectAllTag(!selectAllTag);
  };

  // useEffect(() => {
  //   setSelectAllTag(selectedTags.length === tags.length);
  //   if (selectedTags.length === tags.length) {
  //     selectTag(null);
  //   } else {
  //     selectTag(selectedTags);
  //   }
  // }, [selectedTags, tags]);

  useEffect(() => {
    const updateTagSelection = async () => {
      try {
        // Check if all tags are selected
        const areAllTagsSelected = selectedTags.length === tags.length;

        // Update the select-all status
        setSelectAllTag(areAllTagsSelected);

        // Conditionally select all or the specific tags
        if (areAllTagsSelected) {
          selectTag(null); // Deselect all tags if they are fully selected
        } else {
          selectTag(selectedTags); // Select the chosen tags
        }
      } catch (error) {
        console.error("Error during tag selection:", error);
      }
    };

    // Call the async function to handle the tag selection
    updateTagSelection();
  }, [selectedTags, tags]); // Re-run when selectedTags or tags change

  const toggleTagDropdown = () => {
    setIsTagDropdownOpen(!isTagDropdownOpen);
    setTagSearchTerm('');
  };

  const toggleTagModalDropdown = () => {
    setIsTagModalDropdownOpen(!isTagModalDropdownOpen);
    setTagSearchTerm('');
  };

  const filteredSprints = sprints && sprints
    .filter((item) => item && item.sprint_name?.toLowerCase().includes(sprintSearchTerm && sprintSearchTerm?.toLowerCase()))
    .sort((a, b) => a.sprint_name.localeCompare(b.sprint_name));

  const handleSprintSearchChange = (e) => {
    setSprintSearchTerm(e.target.value);
  };

  const handleCheckboxSprintChange = (sprintId) => {
    const updatedSelection = [...selectedSprints];
    const index = updatedSelection.indexOf(sprintId);

    if (index === -1) {
      // Add to selection
      updatedSelection.push(sprintId);
    } else {
      // Remove from selection
      updatedSelection.splice(index, 1);
    }

    setSelectedSprints(updatedSelection);
  };

  const handleSelectAllSprintChange = () => {
    if (!selectAllSprint) {
      setSelectedSprints(sprints.map((sprint) => sprint._id));
    } else {
      setSelectedSprints([]);
    }
    setSelectAllSprint(!selectAllSprint);
  };

  useEffect(() => {
    setSelectAllSprint(selectedSprints.length === sprints.length);
    if (selectedSprints.length === sprints.length) {
      selectSprint(null);
    } else {
      selectSprint(selectedSprints);
    }
  }, [selectedSprints, sprints]);

  const toggleSprintDropdown = () => {
    setIsSprintDropdownOpen(!isSprintDropdownOpen);
    setSprintSearchTerm('');
  };

  const toggleSprintModalDropdown = () => {
    setIsSprintModalDropdownOpen(!isSprintModalDropdownOpen);
    setSprintSearchTerm('');
  };

  // const handleClickOutsideDropdown = (event) => {
  //   const isClickInsideTeamDropdown = teamDropdownRef.current && teamDropdownRef.current.contains(event.target);
  //   const isClickInsideTeamModalDropdown = teamModalDropdownRef.current &&
  //     teamModalDropdownRef.current.contains(event.target);
  //   const isClickInsideProjectDropdown =
  //     projectDropdownRef.current &&
  //     projectDropdownRef.current.contains(event.target);
  //   const isClickInsideProjectModalDropdown =
  //     projectModalDropdownRef.current &&
  //     projectModalDropdownRef.current.contains(event.target);
  //   const isClickInsideClientDropdown =
  //     clientDropdownRef.current &&
  //     clientDropdownRef.current.contains(event.target);
  //   const isClickInsideClientModalDropdown =
  //     clientModalDropdownRef.current &&
  //     clientModalDropdownRef.current.contains(event.target);
  //   const isClickInsideDeptDropdown =
  //     deptDropdownRef.current && deptDropdownRef.current.contains(event.target);
  //   const isClickInsideDeptModalDropdown =
  //     deptModalDropdownRef.current &&
  //     deptModalDropdownRef.current.contains(event.target);
  //   const isClickInsideTagDropdown =
  //     tagDropdownRef.current && tagDropdownRef.current.contains(event.target);
  //   const isClickInsideTagModalDropdown =
  //     tagModalDropdownRef.current &&
  //     tagModalDropdownRef.current.contains(event.target);
  //   const isClickInsideSprintDropdown =
  //     sprintDropdownRef.current && sprintDropdownRef.current.contains(event.target);
  //   const isClickInsideSprintModalDropdown =
  //     sprintModalDropdownRef.current &&
  //     sprintModalDropdownRef.current.contains(event.target);

  //   const isClickInsideShowingDropdown =
  //     showingDropdownRef.current &&
  //     showingDropdownRef.current.contains(event.target);
  //   const isClickInsideCalendarDropdown =
  //     calendarRef.current && calendarRef.current.contains(event.target);
  //   const isClickInsideModalCalendarDropdown =
  //     calendarModalRef.current &&
  //     calendarModalRef.current.contains(event.target);

  //   if (
  //     !isClickInsideTeamDropdown &&
  //     !isClickInsideTeamModalDropdown &&
  //     !isClickInsideProjectDropdown &&
  //     !isClickInsideProjectModalDropdown &&
  //     !isClickInsideClientDropdown &&
  //     !isClickInsideClientModalDropdown &&
  //     !isClickInsideDeptDropdown &&
  //     !isClickInsideDeptModalDropdown &&
  //     !isClickInsideTagDropdown &&
  //     !isClickInsideTagModalDropdown &&
  //     !isClickInsideSprintDropdown &&
  //     !isClickInsideSprintModalDropdown
  //   ) {
  //     // Clicked outside both project and client dropdowns
  //     setIsTeamDropdownOpen(false);
  //     setIsTeamModalDropdownOpen(false);
  //     setIsClientDropdownOpen(false);
  //     setIsClientModalDropdownOpen(false);
  //     setIsProjectDropdownOpen(false);
  //     setIsProjectModalDropdownOpen(false);
  //     setIsDeptDropdownOpen(false);
  //     setIsDeptModalDropdownOpen(false);
  //     setIsTagDropdownOpen(false);
  //     setIsTagModalDropdownOpen(false);
  //     setIsSprintDropdownOpen(false);
  //     setIsSprintModalDropdownOpen(false);
  //   } else if (
  //     !isClickInsideTeamModalDropdown &&
  //     !isClickInsideClientDropdown &&
  //     !isClickInsideClientModalDropdown &&
  //     !isClickInsideProjectDropdown &&
  //     !isClickInsideProjectModalDropdown &&
  //     !isClickInsideDeptDropdown &&
  //     !isClickInsideDeptModalDropdown &&
  //     !isClickInsideTagDropdown &&
  //     !isClickInsideTagModalDropdown &&
  //     !isClickInsideSprintDropdown &&
  //     !isClickInsideSprintModalDropdown &&
  //     isClickInsideTeamDropdown
  //   ) {
  //     // Clicked outside project dropdown, close project dropdown and filter
  //     setIsTeamModalDropdownOpen(false);
  //     setIsClientDropdownOpen(false);
  //     setIsClientModalDropdownOpen(false);
  //     setIsProjectDropdownOpen(false);
  //     setIsProjectModalDropdownOpen(false);
  //     setIsDeptDropdownOpen(false);
  //     setIsDeptModalDropdownOpen(false);
  //     setIsTagDropdownOpen(false);
  //     setIsTagModalDropdownOpen(false);
  //     setIsSprintDropdownOpen(false);
  //     setIsSprintModalDropdownOpen(false);
  //     if (isTeamDropdownOpen) {
  //       setIsTeamDropdownOpen(!isTeamDropdownOpen);
  //     }
  //   } else if (
  //     !isClickInsideTeamDropdown &&
  //     !isClickInsideClientDropdown &&
  //     !isClickInsideClientModalDropdown &&
  //     !isClickInsideProjectDropdown &&
  //     !isClickInsideProjectModalDropdown &&
  //     !isClickInsideDeptDropdown &&
  //     !isClickInsideDeptModalDropdown &&
  //     !isClickInsideTagDropdown &&
  //     !isClickInsideTagModalDropdown &&
  //     !isClickInsideSprintDropdown &&
  //     !isClickInsideSprintModalDropdown &&
  //     isClickInsideTeamModalDropdown
  //   ) {
  //     // Clicked outside project dropdown, close project dropdown and filter
  //     setIsTeamDropdownOpen(false);
  //     setIsClientDropdownOpen(false);
  //     setIsClientModalDropdownOpen(false);
  //     setIsProjectDropdownOpen(false);
  //     setIsProjectModalDropdownOpen(false);
  //     setIsDeptDropdownOpen(false);
  //     setIsDeptModalDropdownOpen(false);
  //     setIsTagDropdownOpen(false);
  //     setIsTagModalDropdownOpen(false);
  //     setIsSprintDropdownOpen(false);
  //     setIsSprintModalDropdownOpen(false);
  //     if (isTeamModalDropdownOpen) {
  //       setIsTeamModalDropdownOpen(!isTeamModalDropdownOpen);
  //     }
  //   } else if (
  //     !isClickInsideTeamDropdown &&
  //     !isClickInsideTeamModalDropdown &&
  //     !isClickInsideClientModalDropdown &&
  //     !isClickInsideProjectDropdown &&
  //     !isClickInsideProjectModalDropdown &&
  //     !isClickInsideDeptDropdown &&
  //     !isClickInsideDeptModalDropdown &&
  //     !isClickInsideTagDropdown &&
  //     !isClickInsideTagModalDropdown &&
  //     !isClickInsideSprintDropdown &&
  //     !isClickInsideSprintModalDropdown &&
  //     isClickInsideClientDropdown
  //   ) {
  //     // Clicked outside project dropdown, close project dropdown and filter
  //     setIsTeamDropdownOpen(false);
  //     setIsTeamModalDropdownOpen(false);
  //     setIsClientModalDropdownOpen(false);
  //     setIsProjectDropdownOpen(false);
  //     setIsProjectModalDropdownOpen(false);
  //     setIsDeptDropdownOpen(false);
  //     setIsDeptModalDropdownOpen(false);
  //     setIsTagDropdownOpen(false);
  //     setIsTagModalDropdownOpen(false);
  //     setIsSprintDropdownOpen(false);
  //     setIsSprintModalDropdownOpen(false);
  //     if (isClientDropdownOpen) {
  //       setIsClientDropdownOpen(!isClientDropdownOpen);
  //     }
  //   } else if (
  //     !isClickInsideTeamDropdown &&
  //     !isClickInsideTeamModalDropdown &&
  //     !isClickInsideClientDropdown &&
  //     !isClickInsideProjectDropdown &&
  //     !isClickInsideProjectModalDropdown &&
  //     !isClickInsideDeptDropdown &&
  //     !isClickInsideDeptModalDropdown &&
  //     !isClickInsideTagDropdown &&
  //     !isClickInsideTagModalDropdown &&
  //     !isClickInsideSprintDropdown &&
  //     !isClickInsideSprintModalDropdown &&
  //     isClickInsideClientModalDropdown
  //   ) {
  //     // Clicked outside project dropdown, close project dropdown and filter
  //     setIsTeamDropdownOpen(false);
  //     setIsTeamModalDropdownOpen(false);
  //     setIsClientDropdownOpen(false);
  //     setIsProjectDropdownOpen(false);
  //     setIsProjectModalDropdownOpen(false);
  //     setIsDeptDropdownOpen(false);
  //     setIsDeptModalDropdownOpen(false);
  //     setIsTagDropdownOpen(false);
  //     setIsTagModalDropdownOpen(false);
  //     setIsSprintDropdownOpen(false);
  //     setIsSprintModalDropdownOpen(false);
  //     // setIsClientDropdownOpen(true);
  //     if (isClientModalDropdownOpen) {
  //       setIsClientModalDropdownOpen(!isClientModalDropdownOpen);
  //     }
  //   } else if (
  //     !isClickInsideTeamDropdown &&
  //     !isClickInsideTeamModalDropdown &&
  //     !isClickInsideClientDropdown &&
  //     !isClickInsideClientModalDropdown &&
  //     !isClickInsideProjectModalDropdown &&
  //     !isClickInsideDeptDropdown &&
  //     !isClickInsideDeptModalDropdown &&
  //     !isClickInsideTagDropdown &&
  //     !isClickInsideTagModalDropdown &&
  //     !isClickInsideSprintDropdown &&
  //     !isClickInsideSprintModalDropdown &&
  //     isClickInsideProjectDropdown
  //   ) {
  //     // Clicked outside client dropdown, close client dropdown and filter
  //     setIsTeamDropdownOpen(false);
  //     setIsTeamModalDropdownOpen(false);
  //     setIsClientDropdownOpen(false);
  //     setIsClientModalDropdownOpen(false);
  //     setIsProjectModalDropdownOpen(false);
  //     setIsDeptDropdownOpen(false);
  //     setIsDeptModalDropdownOpen(false);
  //     setIsTagDropdownOpen(false);
  //     setIsTagModalDropdownOpen(false);
  //     setIsSprintDropdownOpen(false);
  //     setIsSprintModalDropdownOpen(false);
  //     if (isProjectDropdownOpen) {
  //       setIsProjectDropdownOpen(!isProjectDropdownOpen);
  //     }
  //   } else if (
  //     !isClickInsideTeamDropdown &&
  //     !isClickInsideTeamModalDropdown &&
  //     !isClickInsideClientDropdown &&
  //     !isClickInsideClientModalDropdown &&
  //     !isClickInsideProjectDropdown &&
  //     !isClickInsideDeptDropdown &&
  //     !isClickInsideDeptModalDropdown &&
  //     !isClickInsideTagDropdown &&
  //     !isClickInsideTagModalDropdown &&
  //     !isClickInsideSprintDropdown &&
  //     !isClickInsideSprintModalDropdown &&
  //     isClickInsideProjectModalDropdown
  //   ) {
  //     // Clicked outside client dropdown, close client dropdown and filter
  //     setIsTeamDropdownOpen(false);
  //     setIsTeamModalDropdownOpen(false);
  //     setIsClientDropdownOpen(false);
  //     setIsClientModalDropdownOpen(false);
  //     setIsProjectDropdownOpen(false);
  //     setIsDeptDropdownOpen(false);
  //     setIsDeptModalDropdownOpen(false);
  //     setIsTagDropdownOpen(false);
  //     setIsTagModalDropdownOpen(false);
  //     setIsSprintDropdownOpen(false);
  //     setIsSprintModalDropdownOpen(false);
  //     if (isProjectModalDropdownOpen) {
  //       setIsProjectModalDropdownOpen(!isProjectModalDropdownOpen);
  //     }
  //   } else if (
  //     !isClickInsideTeamDropdown &&
  //     !isClickInsideTeamModalDropdown &&
  //     !isClickInsideClientDropdown &&
  //     !isClickInsideClientModalDropdown &&
  //     !isClickInsideProjectDropdown &&
  //     !isClickInsideProjectModalDropdown &&
  //     !isClickInsideDeptModalDropdown &&
  //     !isClickInsideTagDropdown &&
  //     !isClickInsideTagModalDropdown &&
  //     !isClickInsideSprintDropdown &&
  //     !isClickInsideSprintModalDropdown &&
  //     isClickInsideDeptDropdown
  //   ) {
  //     // Clicked outside client dropdown, close client dropdown and filter
  //     setIsTeamDropdownOpen(false);
  //     setIsTeamModalDropdownOpen(false);
  //     setIsClientDropdownOpen(false);
  //     setIsClientModalDropdownOpen(false);
  //     setIsProjectDropdownOpen(false);
  //     setIsProjectModalDropdownOpen(false);
  //     setIsDeptModalDropdownOpen(false);
  //     setIsTagDropdownOpen(false);
  //     setIsTagModalDropdownOpen(false);
  //     setIsSprintDropdownOpen(false);
  //     setIsSprintModalDropdownOpen(false);
  //     if (isDeptDropdownOpen) {
  //       setIsDeptDropdownOpen(!isDeptDropdownOpen);
  //     }
  //   } else if (
  //     !isClickInsideTeamDropdown &&
  //     !isClickInsideTeamModalDropdown &&
  //     !isClickInsideClientDropdown &&
  //     !isClickInsideClientModalDropdown &&
  //     !isClickInsideProjectDropdown &&
  //     !isClickInsideProjectModalDropdown &&
  //     !isClickInsideDeptDropdown &&
  //     !isClickInsideTagDropdown &&
  //     !isClickInsideTagModalDropdown &&
  //     !isClickInsideSprintDropdown &&
  //     !isClickInsideSprintModalDropdown &&
  //     isClickInsideDeptModalDropdown
  //   ) {
  //     // Clicked outside client dropdown, close client dropdown and filter
  //     setIsTeamDropdownOpen(false);
  //     setIsTeamModalDropdownOpen(false);
  //     setIsClientDropdownOpen(false);
  //     setIsClientModalDropdownOpen(false);
  //     setIsProjectDropdownOpen(false);
  //     setIsProjectModalDropdownOpen(false);
  //     setIsDeptDropdownOpen(false);
  //     setIsTagDropdownOpen(false);
  //     setIsTagModalDropdownOpen(false);
  //     setIsSprintDropdownOpen(false);
  //     setIsSprintModalDropdownOpen(false);
  //     if (isDeptModalDropdownOpen) {
  //       setIsDeptModalDropdownOpen(!isDeptModalDropdownOpen);
  //     }
  //   } else if (
  //     !isClickInsideTeamDropdown &&
  //     !isClickInsideTeamModalDropdown &&
  //     !isClickInsideClientDropdown &&
  //     !isClickInsideClientModalDropdown &&
  //     !isClickInsideProjectDropdown &&
  //     !isClickInsideProjectModalDropdown &&
  //     !isClickInsideDeptDropdown &&
  //     !isClickInsideDeptModalDropdown &&
  //     !isClickInsideTagModalDropdown &&
  //     !isClickInsideSprintDropdown &&
  //     !isClickInsideSprintModalDropdown &&
  //     isClickInsideTagDropdown
  //   ) {
  //     // Clicked outside client dropdown, close client dropdown and filter
  //     setIsTeamDropdownOpen(false);
  //     setIsTeamModalDropdownOpen(false);
  //     setIsClientDropdownOpen(false);
  //     setIsClientModalDropdownOpen(false);
  //     setIsProjectDropdownOpen(false);
  //     setIsProjectModalDropdownOpen(false);
  //     setIsDeptDropdownOpen(false);
  //     setIsDeptModalDropdownOpen(false);
  //     setIsTagModalDropdownOpen(false);
  //     setIsSprintDropdownOpen(false);
  //     setIsSprintModalDropdownOpen(false);
  //     if (isTagDropdownOpen) {
  //       setIsTagDropdownOpen(!isTagDropdownOpen);
  //     }
  //   } else if (
  //     !isClickInsideTeamDropdown &&
  //     !isClickInsideTeamModalDropdown &&
  //     !isClickInsideClientDropdown &&
  //     !isClickInsideClientModalDropdown &&
  //     !isClickInsideProjectDropdown &&
  //     !isClickInsideProjectModalDropdown &&
  //     !isClickInsideDeptDropdown &&
  //     !isClickInsideDeptModalDropdown &&
  //     !isClickInsideTagDropdown &&
  //     !isClickInsideSprintDropdown &&
  //     !isClickInsideSprintModalDropdown &&
  //     isClickInsideTagModalDropdown
  //   ) {
  //     // Clicked outside client dropdown, close client dropdown and filter
  //     setIsTeamDropdownOpen(false);
  //     setIsTeamModalDropdownOpen(false);
  //     setIsClientDropdownOpen(false);
  //     setIsClientModalDropdownOpen(false);
  //     setIsProjectDropdownOpen(false);
  //     setIsProjectModalDropdownOpen(false);
  //     setIsDeptDropdownOpen(false);
  //     setIsDeptModalDropdownOpen(false);
  //     setIsTagDropdownOpen(false);
  //     setIsSprintDropdownOpen(false);
  //     setIsSprintModalDropdownOpen(false);
  //     if (isTagModalDropdownOpen) {
  //       setIsTagModalDropdownOpen(!isTagModalDropdownOpen);
  //     }
  //   } else if (
  //     !isClickInsideTeamDropdown &&
  //     !isClickInsideTeamModalDropdown &&
  //     !isClickInsideClientDropdown &&
  //     !isClickInsideClientModalDropdown &&
  //     !isClickInsideProjectDropdown &&
  //     !isClickInsideProjectModalDropdown &&
  //     !isClickInsideDeptDropdown &&
  //     !isClickInsideDeptModalDropdown &&
  //     !isClickInsideTagDropdown &&
  //     !isClickInsideTagModalDropdown &&
  //     !isClickInsideSprintModalDropdown &&
  //     isClickInsideSprintDropdown
  //   ) {
  //     // Clicked outside client dropdown, close client dropdown and filter
  //     setIsTeamDropdownOpen(false);
  //     setIsTeamModalDropdownOpen(false);
  //     setIsClientDropdownOpen(false);
  //     setIsClientModalDropdownOpen(false);
  //     setIsProjectDropdownOpen(false);
  //     setIsProjectModalDropdownOpen(false);
  //     setIsDeptDropdownOpen(false);
  //     setIsDeptModalDropdownOpen(false);
  //     setIsTagDropdownOpen(false);
  //     setIsTagModalDropdownOpen(false);
  //     setIsSprintModalDropdownOpen(false);
  //     if (isSprintDropdownOpen) {
  //       setIsSprintDropdownOpen(!isSprintDropdownOpen);
  //     }
  //   } else if (
  //     !isClickInsideTeamDropdown &&
  //     !isClickInsideTeamModalDropdown &&
  //     !isClickInsideClientDropdown &&
  //     !isClickInsideClientModalDropdown &&
  //     !isClickInsideProjectDropdown &&
  //     !isClickInsideProjectModalDropdown &&
  //     !isClickInsideDeptDropdown &&
  //     !isClickInsideDeptModalDropdown &&
  //     !isClickInsideTagDropdown &&
  //     !isClickInsideTagModalDropdown &&
  //     !isClickInsideSprintDropdown &&
  //     isClickInsideSprintModalDropdown
  //   ) {
  //     // Clicked outside client dropdown, close client dropdown and filter
  //     setIsTeamDropdownOpen(false);
  //     setIsTeamModalDropdownOpen(false);
  //     setIsClientDropdownOpen(false);
  //     setIsClientModalDropdownOpen(false);
  //     setIsProjectDropdownOpen(false);
  //     setIsProjectModalDropdownOpen(false);
  //     setIsDeptDropdownOpen(false);
  //     setIsDeptModalDropdownOpen(false);
  //     setIsTagDropdownOpen(false);
  //     setIsTagModalDropdownOpen(false);
  //     setIsSprintDropdownOpen(false);
  //     if (isSprintModalDropdownOpen) {
  //       setIsSprintModalDropdownOpen(!isSprintModalDropdownOpen);
  //     }
  //   }
  //   if (!isClickInsideShowingDropdown) {
  //     setShowingDropdownOpen(false);
  //   }
  //   if (!isClickInsideCalendarDropdown) {
  //     setShowCalendar(false);
  //   }
  //   if (!isClickInsideModalCalendarDropdown) {
  //     setShowModalCalendar(false);
  //   }
  // };

  const handleClickOutsideDropdown = (event) => {
    const isClickInsideTeamDropdown = teamDropdownRef.current && teamDropdownRef.current.contains(event.target);
    const isClickInsideUserStatusDropdown = userStatusDropdownRef.current && userStatusDropdownRef.current.contains(event.target);
    const isClickInsideTeamModalDropdown = teamModalDropdownRef.current && teamModalDropdownRef.current.contains(event.target);
    const isClickInsideUserStatusModaDropdown = userStatusModalDropdownRef.current && userStatusModalDropdownRef.current.contains(event.target);
    const isClickInsideProjectDropdown = projectDropdownRef.current && projectDropdownRef.current.contains(event.target);
    const isClickInsideProjectStatusDropdown = projectStatusDropdownRef.current && projectStatusDropdownRef.current.contains(event.target);
    const isClickInsideProjectModalDropdown = projectModalDropdownRef.current && projectModalDropdownRef.current.contains(event.target);
    const isClickInsideProjectStatusModaDropdown = projectStatusModalDropdownRef.current && projectStatusModalDropdownRef.current.contains(event.target);
    const isClickInsideClientDropdown = clientDropdownRef.current && clientDropdownRef.current.contains(event.target);
    const isClickInsideClientModalDropdown = clientModalDropdownRef.current && clientModalDropdownRef.current.contains(event.target);
    const isClickInsideDeptDropdown = deptDropdownRef.current && deptDropdownRef.current.contains(event.target);
    const isClickInsideDeptModalDropdown = deptModalDropdownRef.current && deptModalDropdownRef.current.contains(event.target);
    const isClickInsideTagDropdown = tagDropdownRef.current && tagDropdownRef.current.contains(event.target);
    const isClickInsideTagModalDropdown = tagModalDropdownRef.current && tagModalDropdownRef.current.contains(event.target);
    const isClickInsideSprintDropdown = sprintDropdownRef.current && sprintDropdownRef.current.contains(event.target);
    const isClickInsideSprintModalDropdown = sprintModalDropdownRef.current && sprintModalDropdownRef.current.contains(event.target);
    const isClickInsideShowingDropdown = showingDropdownRef.current && showingDropdownRef.current.contains(event.target);
    const isClickInsideCalendarDropdown = calendarRef.current && calendarRef.current.contains(event.target);
    const isClickInsideModalCalendarDropdown = calendarModalRef.current && calendarModalRef.current.contains(event.target);

    if (!isClickInsideTeamDropdown) {
      setIsTeamDropdownOpen(false);
    }
    if (!isClickInsideUserStatusDropdown) {
      setIsUserStatusDropdownOpen(false);
    }
    if (!isClickInsideUserStatusModaDropdown) {
      setIsUserStatusModalDropdownOpen(false);
    }
    if (!isClickInsideTeamModalDropdown) {
      setIsTeamModalDropdownOpen(false);
    }
    if (!isClickInsideProjectDropdown) {
      setIsProjectDropdownOpen(false);
    }
    if (!isClickInsideProjectStatusDropdown) {
      setIsProjectStatusDropdownOpen(false);
    }
    if (!isClickInsideProjectStatusModaDropdown) {
      setIsProjectStatusModalDropdownOpen(false);
    }
    if (!isClickInsideProjectModalDropdown) {
      setIsProjectModalDropdownOpen(false);
    }
    if (!isClickInsideClientDropdown) {
      setIsClientDropdownOpen(false);
    }
    if (!isClickInsideClientModalDropdown) {
      setIsClientModalDropdownOpen(false);
    }
    if (!isClickInsideDeptDropdown) {
      setIsDeptDropdownOpen(false);
    }
    if (!isClickInsideDeptModalDropdown) {
      setIsDeptModalDropdownOpen(false);
    }
    if (!isClickInsideTagDropdown) {
      setIsTagDropdownOpen(false);
    }
    if (!isClickInsideTagModalDropdown) {
      setIsTagModalDropdownOpen(false);
    }
    if (!isClickInsideSprintDropdown) {
      setIsSprintDropdownOpen(false);
    }
    if (!isClickInsideSprintModalDropdown) {
      setIsSprintModalDropdownOpen(false);
    }
    if (!isClickInsideShowingDropdown) {
      setShowingDropdownOpen(false);
    }
    if (!isClickInsideCalendarDropdown) {
      setShowCalendar(false);
    }
    if (!isClickInsideModalCalendarDropdown) {
      setShowModalCalendar(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutsideDropdown);

    return () => {
      document.removeEventListener("click", handleClickOutsideDropdown);
    };
  }, []);

  const queryClient = useQueryClient();

  const user = useSelector((state) => state.auth.user);

  const selectInitialTeam = () => {
    if (
      user &&
      user._id &&
      (user.userType === userTypeConsts.PROJECT_MANAGER ||
        user.userType === userTypeConsts.TEAM_MANAGER)
    ) {
      const projectManagerTeamId = user._id;
      selectTeam([projectManagerTeamId]);
    }
  };

  // useEffect(() => {
  //   const filteredProjectIds = projects && projects
  //     .filter((project) => project.is_active === true)
  //     .map((project) => project._id);
  //   setSelectedClients(clients.map((client) => client._id));
  //   setSelectedProjects(filteredProjectIds);
  //   setSelectedDepts(departments.map((dept) => dept._id));
  //   setSelectedTags(tags.map((tag) => tag._id));
  //   setSelectedSprints(sprints.map((sprint) => sprint._id));
  // }, [projects, tags, sprints]);

  useEffect(() => {
    const updateSelections = async () => {
      try {
        // Filtered project ids (Active projects only)
        // const filteredProjectIds = projects?.filter((project) => project.is_active === true)
        //   .map((project) => project._id) || [];
        const filteredProjectIds = projects?.map((project) => project._id) || [];

        // Update selected clients, projects, departments, tags, and sprints asynchronously
        const selectedClients = clients?.map((client) => client._id) || [];
        const selectedDepts = departments?.map((dept) => dept._id) || [];
        const selectedTags = tags?.map((tag) => tag._id) || [];
        const selectedSprints = sprints?.map((sprint) => sprint._id) || [];

        // Update state asynchronously
        setSelectedClients(selectedClients);
        setSelectedProjects(filteredProjectIds);
        setSelectedDepts(selectedDepts);
        setSelectedTags(selectedTags);
        setSelectedSprints(selectedSprints);
      } catch (error) {
        console.error("Error during selections update:", error);
      }
    };

    // Run the updateSelections function asynchronously
    updateSelections();
  }, [projects, tags, sprints, clients, departments]); // Dependency array to run the effect when these values change

  useEffect(() => {
    if (teams.length > 0) {
      setSelectedTeams([user._id]);
    }
  }, [user, teams]);

  const selectTeam = async (team) => {
    await dispatch(setTeam(team));
  };
  const selectProject = async (project) => {
    await dispatch(setProject(project));
  };
  const selectClient = async (client) => {
    setSelClient(client);
    await dispatch(setClient(client));
  };

  const selectDepartment = async (department) => {
    await dispatch(setDepartment(department));
  };

  const selectTag = async (tag) => {
    await dispatch(setTag(tag));
  };

  const selectSprint = async (sprint) => {
    await dispatch(setSprint(sprint));
  };

  const selectDate = (date) => {
    if (date) {
      setDates(new Date(date));
    } else {
      setDates(null);
    }
  };

  // const handleSelect = (ranges) => {
  //   setDateRange(ranges);
  //   if (ranges === null) {
  //     const firstDayOfMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
  //     setDateRange([firstDayOfMonth, new Date()]);
  //   }
  // };

  const onDateChange = (ranges) => {
    // moment.tz(item.date,'YYYY-MM-DD HH:mm:ss',global.config.getTimeZone()).format()


    const start = new Date(ranges.startDate);
    const startOfDay = new Date(start.getFullYear(), start.getMonth(), start.getDate());
    const end = new Date(ranges.endDate);
    const endOfDay = new Date(end.getFullYear(), end.getMonth(), end.getDate());
    const dateRangeArray = [startOfDay, endOfDay];

    

    setDateRange(dateRangeArray);
    if (ranges === null) {
      const firstDayOfMonth = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        1
      );
      setDateRange([firstDayOfMonth, new Date()]);
    }
    setShowCalendar(false);
    setShowModalCalendar(false);
  };

  const toggleCalendar = () => {
    setShowCalendar(!showCalendar);
  };

  const cancelCalendar = () => {
    setShowCalendar(false);
  };

  const toggleModalCalendar = () => {
    setShowModalCalendar(!showModalCalendar);
  };

  const cancelModalCalendar = () => {
    setShowModalCalendar(false);
  };

  const applyFilters = () => {
    setShowFilterModal(false);
  };

  const toggleFilterModal = () => {
    setShowConfirmationModal(false);
    setShowFilterModal(!showFilterModal);
  };

  const clearFilters = () => {
    setSelectedTeams([user._id]);
    setUserStatusFilter("Active");
    setProjectStatusFilter("Active");
    const filteredProjectIds = projects && projects
      .filter((project) => project.is_active === true)
      .map((project) => project._id);
    setSelectedClients(clients.map((client) => client._id));
    setSelectedProjects(filteredProjectIds);
    setSelectedDepts(departments.map((dept) => dept._id));
    setSelectedTags(tags.map((tag) => tag._id));
    setSelectedSprints(sprints.map((sprint) => sprint._id));
    setDateRange([firstDayOfMonth, new Date()]);
    setShowFilterModal(false);
  };

  const checkAndShowConfirmationModal = () => {
    const isTeamsModified = !isEqual(selectedTeams, [user._id]);
    const isClientsModified = !isEqual(
      selectedClients,
      clients.map((client) => client._id)
    );
    const isProjectsModified = !isEqual(
      selectedProjects,
      projects.map((project) => project._id)
    );
    const isDeptsModified = !isEqual(
      selectedDepts,
      departments.map((dept) => dept._id)
    );
    const isTagsModified = !isEqual(
      selectedTags,
      tags.map((tag) => tag._id)
    );
    const isSprintsModified = !isEqual(selectedSprints, sprints.map((sprint) => sprint._id));
    const isDateRangeModified = !isEqual(
      [dateRange[0], dateRange[1].setHours(0, 0, 0, 0)],
      [firstDayOfMonth, new Date().setHours(0, 0, 0, 0)]
    );

    if (
      isTeamsModified ||
      isClientsModified ||
      isProjectsModified ||
      isDeptsModified ||
      isTagsModified ||
      isSprintsModified ||
      isDateRangeModified
    ) {
      setShowConfirmationModal(true);
    } else {
      setShowFilterModal(false);
    }
  };

  const handleCancel = () => {
    setShowConfirmationModal(false);
  };

  const handleApplyConfirm = () => {
    setShowConfirmationModal(false);
    setShowFilterModal(false);
  };

  const disableInput = (event) => {
    event.preventDefault();
  };

  const groupAndSumDurations = (data) => {
    const groupedData = [];
    let currentGroup = null;
    let totalDuration = 0;
    const formatDate = (dateString) => {
      if (!dateString) {
        return null;
      }
      const dateObject = new Date(dateString);
      const formattedDate = dateObject.toISOString().split("T")[0];
      return formattedDate;
    };

    data.forEach((row) => {
      if (
        currentGroup &&
        currentGroup.user._id === row.user._id &&
        currentGroup.task === row.task &&
        currentGroup.project._id === row.project._id &&
        formatDate(currentGroup.date) === formatDate(row.date)
      ) {
        // If the current row belongs to the same group, update the duration
        totalDuration += row.duration;
        currentGroup.entries.push(row);
      } else {
        // If the row starts a new group, push the previous group (if any) and start a new one
        if (currentGroup) {
          currentGroup.totalDuration = totalDuration;
          groupedData.push(currentGroup);
        }
        // Start a new group
        currentGroup = {
          _id: row._id,
          user: row.user,
          task: row.task,
          project: row.project,
          sprint: row.sprint,
          tag: row.tag,
          date: row.date,
          startTime: row.startTime,
          endTime: row.endTime,
          totalDuration: row.duration,
          entries: [row],
        };
        totalDuration = row.duration;
      }
    });

    // Push the last group (if any)
    if (currentGroup) {
      currentGroup.totalDuration = totalDuration;
      groupedData.push(currentGroup);
    }

    return groupedData;
  };

  const sortedTimerData = [...timerDatas].sort((a, b) => {
    if (a?.project?._id !== b?.project?._id) {
      return a.project._id.localeCompare(b.project._id);
    }
    return 0;
  });

  // Group and sum durations, then sort by date
  const updatedGroupedData = groupAndSumDurations(sortedTimerData).sort((a, b) => {
    return new Date(b.date) - new Date(a.date);
  });
  const totalResults = updatedGroupedData.length;
  const changeDurationFormat = (sec) => {
    let second = sec;
    const hours = Math.floor(second / 3600);
    const minutes = Math.floor((second % 3600) / 60);
    const seconds = second % 60;
    const formattedDuration = `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
    return formattedDuration;
  };

  const changeDurationHourFormat = (sec) => {
    const hours = sec / 3600;
    const formattedDuration = hours.toFixed(2);
    return formattedDuration;
  };

  const exportToExcel = () => {
    if (timerDatas.length === 0) {
      toast.error("No data to export.", {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    try {
      const now = new Date();
      const formattedDate =
        now.getFullYear() +
        "-" +
        (now.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        now.getDate().toString().padStart(2, "0");
      const formattedTime =
        now.getHours().toString().padStart(2, "0") +
        ":" +
        now.getMinutes().toString().padStart(2, "0") +
        ":" +
        now.getSeconds().toString().padStart(2, "0");
      const modifiedLog = timerDatas;

      const final = [];
      modifiedLog.map((item) => {
        final.push({
          User: item.user.first_name
            ? `${item.user.first_name} ${item.user.middle_name ? item.user.middle_name : ""
            } ${item.user.last_name}`
            : item.user.company_first_name
              ? `${item.user.company_first_name} ${item.user.company_last_name ? item.user.company_last_name : ""
              }`
              : `${item.user.company_name} (Company)`,
          Email: item.user.email ? item.user.email : "",
          Task: item.task ? item.task : "",
          "Client Name":
            item.project.client_name != null
              ? item.project.client_name.client_name
              : "",
          Project: item.project.project_name,
          "Sprint": item?.sprint?.sprint_name ? item.sprint.sprint_name : "",
          "Tag": item?.tag?.tag_name ? item.tag.tag_name : "",
          "Start Time": item.startTime ? TimestampConverter(item.startTime) : "",
          "End Time": item.endTime ? TimestampConverter(item.endTime) : "",
          Duration: changeDurationHourFormat(item.duration),
          "Actual Hours": changeDurationFormat(item.duration),
          Date: new Date(item.date).toLocaleDateString("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric",
          }),
          Billable: item.project.is_billable === true ? "Yes" : "No",
        });
      });
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(final);
      const colWidths = [
        { wpx: 80 },
        { wpx: 120 },
        { wpx: 120 },
        { wpx: 100 },
        { wpx: 135 },
        { wpx: 80 },
        { wpx: 100 },
        { wpx: 100 },
        { wpx: 100 },
        { wpx: 60 },
        { wpx: 100 },
        { wpx: 100 },
        { wpx: 60 },
      ];
      worksheet["!cols"] = colWidths;
      // Add alignment to center align the first row and increase font size
      const firstRow = XLSX.utils.decode_range(worksheet["!ref"]).s.r;
      for (let i = 0; i < colWidths.length; i++) {
        const cell = XLSX.utils.encode_cell({ r: firstRow, c: i });
        const style = worksheet[cell]?.s || {};
        style.alignment = style.alignment || {};
        style.alignment.horizontal = "center !important";
        style.alignment.vertical = "center !important";
        style.font = { size: 14, bold: true };
        worksheet[cell] = { ...worksheet[cell], s: style };
      }

      // Set border for active area and increase font size
      const range = XLSX.utils.decode_range(worksheet["!ref"]);
      for (let row = range.s.r + 1; row <= range.e.r; row++) {
        for (let col = range.s.c; col <= range.e.c; col++) {
          const cell = XLSX.utils.encode_cell({ r: row, c: col });
          const style = worksheet[cell]?.s || {};
          style.font = { size: 12 };
          worksheet[cell] = { ...worksheet[cell], s: style };
        }
      }
      global.config.activityLog(
        window.location.href,
        "Timer",
        "Timer Data exported to Excel"
      );
      const fileName = `TimeTracker_Report_${formattedDate}_${formattedTime}.xlsx`;
      worksheet["!autofilter"] = { ref: range };
      XLSX.utils.book_append_sheet(workbook, worksheet, "TimeTracker Report");
      XLSX.writeFile(workbook, fileName);
    } catch (error) {
      console.log(error);
      global.config.slackMessage(error.toString());
      global.config.activityLog(
        window.location.href,
        "Invoice",
        "Error occurred while exporting to Excel"
      );
      alert("Error occurred while exporting to Excel.");
    }
  };
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "invoice-data",
  });

  const [showPrintSection, setShowPrintSection] = useState(false);
  const handlePrintButtonClick = () => {
    setShowPrintSection(true);

    if (timerDatas.length === 0) {
      toast.error("No data to print.", {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setShowPrintSection(false);
      return;
    }

    const htmlc = `<h1>TEST</h1>`;

    // Introduce a 2-second delay for the print action
    setTimeout(() => {
      handlePrint(timerDatas);
      setShowPrintSection(false);
    }, 2000);
  };



  const fadeIn = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 }
  };

  return (

    <motion.div
      ref={componentRef}
      initial="hidden"
      animate="visible"
      variants={fadeIn}
      transition={{ duration: 0.5 }}
    >
      <div className="overview-outer-time overview-outer-time-outer">
        <div className="overview-top">
          <div className="overview-top-inner">
            <div className="overview-top-left">
              <a onClick={exportToExcel}>
                <span className="icon-export">
                  <img src={DownloadImage} alt="Export" />
                </span>
                Export Data
              </a>
              <a onClick={handlePrintButtonClick}>
                <span className="icon-print">
                  <img src={PrintIcon} alt="Export" />
                </span>
                Print
              </a>
            </div>
            <div className="overview-top-right">
              <div className="showing-count">
                <div className="filter-inner showing-result">
                  <label>Showing</label>

                  <div
                    ref={showingDropdownRef}
                    className="select-modal-mainM custom-dropdown-user-new-outer"
                  >
                    <div
                      className="selected-option custom-dropdown-user-new"
                      onClick={() => setShowingDropdownOpen(!showingDropdownOpen)}
                    >
                      {totalResults < 10
                        ? totalResults.toString()
                        : perPage.toString()}
                    </div>
                    {showingDropdownOpen && (
                      <div className="custom-dropdown-new">
                        <ul className="showing-options-list">
                          {totalResults >= 10 && (
                            <li
                              className={perPage === 10 ? "selected-item" : ""}
                              onClick={() => {
                                setPerPages(10);
                                setShowingDropdownOpen(false);
                              }}
                            >
                              10
                            </li>
                          )}
                          {totalResults >= 20 && (
                            <li
                              className={perPage === 20 ? "selected-item" : ""}
                              onClick={() => {
                                setPerPages(20);
                                setShowingDropdownOpen(false);
                              }}
                            >
                              20
                            </li>
                          )}
                          {totalResults >= 30 && (
                            <li
                              className={perPage === 30 ? "selected-item" : ""}
                              onClick={() => {
                                setPerPages(30);
                                setShowingDropdownOpen(false);
                              }}
                            >
                              30
                            </li>
                          )}
                          {totalResults >= 40 && (
                            <li
                              className={perPage === 40 ? "selected-item" : ""}
                              onClick={() => {
                                setPerPages(40);
                                setShowingDropdownOpen(false);
                              }}
                            >
                              40
                            </li>
                          )}
                          {/* {totalResults >= 10 && (
                            <li
                              className={
                                totalResults >= 10 && perPage === totalResults
                                  ? "selected-item"
                                  : ""
                              }
                              onClick={() => {
                                setPerPages(totalResults);
                                setShowingDropdownOpen(false);
                              }}
                            >
                              ALL
                            </li>
                          )} */}
                          {totalResults <= 200 && totalResults >= 10 && (
                            <li
                              className={perPage === totalResults ? "selected-item" : ""}
                              onClick={() => {
                                setPerPages(totalResults);
                                setShowingDropdownOpen(false);
                              }}
                            >
                              ALL
                            </li>
                          )}
                          {totalResults > 200 && (
                            <li
                              className={perPage === 200 ? "selected-item" : ""}
                              onClick={() => {
                                setPerPages(200);
                                setShowingDropdownOpen(false);
                              }}
                            >
                              200
                            </li>
                          )}
                          {/* Add more conditions as needed */}
                          {totalResults < 10 ? (
                            <li
                              className={totalResults < 10 ? "selected-item" : ""}
                              onClick={() => {
                                setPerPages(totalResults);
                                setShowingDropdownOpen(false);
                              }}
                            >
                              {totalResults}
                            </li>
                          ) : null}
                        </ul>
                      </div>
                    )}
                  </div>
                  <label>of {updatedGroupedData.length} Results</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="overview-inner-section">
          <div className={`filter-sec-overview active`}>
            <div className="invoice-filter-inner">
              <div className="invoice-filter-inner-section no-flter-all">
                <div className="filter-inner invoice-id">
                  <label for="fname">Task Name</label>
                  <input
                    type="text"
                    id="taskname"
                    name="taskname"
                    placeholder="Task name..."
                    value={taskName}
                    onChange={handleTaskNameChange}
                  />
                </div>
                {user &&
                  (user.userType === userTypeConsts.SUPER_ADMIN ||
                    user.userType === userTypeConsts.ADMIN ||
                    user.userType === userTypeConsts.PROJECT_MANAGER
                    //  || user.userType === userTypeConsts.TEAM_MANAGER
                  ) && (
                    <div className="filter-inner invoice-id">
                      <label>Teams</label>
                      <div className="custom-dropdown1" ref={teamDropdownRef}>
                        <div className="drop-down-inner">
                          <div
                            className="selected-options1"
                            onClick={toggleTeamDropdown}
                          >
                            {selectedTeams.length === 0 ? (
                              <div className="placeholder">Select</div>
                            ) : (
                              <>
                                <div
                                  className="selected-option1"
                                  key={selectedTeams[0]}
                                >
                                  {selectedTeams.length === teams.filter((team) => userStatusFilter === "All" ? true : userStatusFilter === "Active" ? team.is_active === true : team.is_active === false).length ? (
                                    "All Teams"
                                  ) : (
                                    <>
                                      <TruncateFilterFieldView
                                        textContent={
                                          teams.find((item) => item._id === selectedTeams[0])?.first_name
                                            ? `${teams.find((item) => item._id === selectedTeams[0]).first_name} 
                                          ${teams.find((item) => item._id === selectedTeams[0]).middle_name ?
                                              teams.find((item) => item._id === selectedTeams[0]).middle_name
                                              : ""
                                            } ${teams.find(
                                              (item) => item._id === selectedTeams[0]
                                            ).last_name ? teams.find((item) => item._id === selectedTeams[0]).last_name : ""}`
                                            : teams.find((item) => item._id === selectedTeams[0])?.company_first_name
                                              ? `${teams.find((item) => item._id === selectedTeams[0]).company_first_name
                                              } ${teams.find((item) => item._id === selectedTeams[0]).company_last_name ? teams.find(
                                                (item) => item._id === selectedTeams[0]).company_last_name : ""}`
                                              : `${teams.find((item) => item._id === selectedTeams[0])?.company_name
                                              } (Company)`
                                        }
                                      />
                                      {selectedTeams.length > 1 &&
                                        selectedTeams.length !== teams.length && (
                                          <div className="selected-option-count">
                                            {selectedTeams.length - 1}+
                                          </div>
                                        )}
                                    </>
                                  )}
                                </div>
                              </>
                            )}
                          </div>
                          {isTeamDropdownOpen && (
                            <div className="dropdown-list1">
                              {(user.userType === userTypeConsts.SUPER_ADMIN || user.userType === userTypeConsts.ADMIN) && (
                                <>
                                  <div className="user-status-dropdown">
                                    <div className="user-status-dropdown-label" htmlFor="userStatusFilter">Filter by Status</div>
                                    <div className="custom-dropdown-user" ref={userStatusDropdownRef}>
                                      <div className="showing-name" onClick={toggleUserStatusDropdown}>
                                        {userStatusFilter ? userStatusFilter : "Active"}
                                      </div>
                                    </div>
                                  </div>
                                  {isUserStatusDropdownOpen && (
                                    <ul className="status-list">
                                      <li
                                        onClick={() => handleUserStatusChange("All")}
                                        className={userStatusFilter === "All" ? "selected-item" : ""}
                                      >
                                        All
                                      </li>
                                      <li
                                        onClick={() => handleUserStatusChange("Active")}
                                        className={userStatusFilter === "Active" ? "selected-item" : ""}
                                      >
                                        Active
                                      </li>
                                      <li
                                        onClick={() => handleUserStatusChange("Inactive")}
                                        className={userStatusFilter === "Inactive" ? "selected-item" : ""}
                                      >
                                        Inactive
                                      </li>
                                    </ul>
                                  )}
                                </>
                              )}
                              <input
                                type="text"
                                placeholder="Search teams"
                                value={userSearchTerm}
                                onChange={handleUserSearchChange}
                                className="search-bar"
                              />
                              {filteredUsers.filter(team =>
                                userStatusFilter === "All" ? true :
                                  userStatusFilter === "Active" ? team.is_active === true :
                                    team.is_active === false
                              ).length > 0 ? (
                                <>
                                  {userSearchTerm === "" && (
                                    <label
                                      className="checkbox-label1"
                                      key="selectAll"
                                    >
                                      <input
                                        type="checkbox"
                                        checked={selectAllTeam}
                                        onChange={handleSelectAllTeamChange}
                                      />
                                      All Teams
                                    </label>
                                  )}
                                  {filteredUsers && filteredUsers
                                    // .filter((team) =>
                                    //   userStatusFilter === "Active"
                                    //     ? team.is_active === true
                                    //     : team.is_active === false
                                    // )
                                    .filter((team) =>
                                      userStatusFilter === "All" ? true : userStatusFilter === "Active" ? team.is_active === true : team.is_active === false
                                    )
                                    .map((team) => (
                                      <label
                                        className="checkbox-label1"
                                        key={team._id}
                                      >
                                        <input
                                          type="checkbox"
                                          checked={selectedTeams.includes(team._id)}
                                          onChange={() =>
                                            handleCheckboxTeamChange(team._id)
                                          }
                                        />
                                        {team.first_name
                                          ? `${team.first_name} ${team.middle_name
                                            ? team.middle_name
                                            : ""
                                          } ${team.last_name}`
                                          : team.company_first_name
                                            ? `${team.company_first_name} ${team.company_last_name
                                              ? team.company_last_name
                                              : ""
                                            }`
                                            : `${team.company_name} (Company)`}
                                      </label>
                                    ))}
                                </>
                              ) : (
                                <div>No teams found</div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                <div className="filter-inner user">
                  <label for="user">Client</label>
                  <div className="custom-dropdown1" ref={clientDropdownRef}>
                    <div className="drop-down-inner">
                      <div className="selected-options1" onClick={toggleClientDropdown} >
                        {selectedClients.length === 0 ? (
                          <div className="placeholder">Select</div>
                        ) : (
                          <>
                            <div
                              className="selected-option1"
                              key={selectedClients[0]}
                            >
                              {selectedClients.length === clients.length ? (
                                "All Clients"
                              ) : (
                                <>
                                  <TruncateFilterFieldView
                                    textContent={
                                      clients.find(
                                        (item) => item._id === selectedClients[0]
                                      )?.client_name
                                    }
                                  />
                                  {selectedClients.length > 1 &&
                                    selectedClients.length !== clients.length && (
                                      <div className="selected-option-count">
                                        {selectedClients.length - 1}+
                                      </div>
                                    )}
                                </>
                              )}
                            </div>
                          </>
                        )}
                      </div>
                      {isClientDropdownOpen && (
                        <div className="dropdown-list1">
                          <input
                            type="text"
                            placeholder="Search clients"
                            value={clientSearchTerm}
                            onChange={handleClientSearchChange}
                            className="search-bar"
                          />
                          {filteredClients.length > 0 ? (
                            <>
                              {clientSearchTerm === "" && (
                                <label className="checkbox-label1" key="selectAll">
                                  <input
                                    type="checkbox"
                                    checked={selectAllClient}
                                    onChange={handleSelectAllClientChange}
                                  />
                                  All Clients
                                </label>
                              )}
                              {filteredClients
                                .slice()
                                .sort((a, b) =>
                                  a.client_name
                                    ?.toLowerCase()
                                    .localeCompare(b.client_name?.toLowerCase())
                                )
                                .map((client) => (
                                  <label
                                    className="checkbox-label1"
                                    key={client._id}
                                  >
                                    <input
                                      type="checkbox"
                                      checked={selectedClients.includes(client._id)}
                                      onChange={() =>
                                        handleCheckboxClientChange(client._id)
                                      }
                                    />
                                    {client.client_name && `${client.client_name}`}
                                  </label>
                                ))}
                            </>
                          ) : (
                            <div>No clients found</div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="filter-inner invoice-status">
                  <label>Project</label>
                  <div className="custom-dropdown1" ref={projectDropdownRef}>
                    <div className="drop-down-inner">
                      <div
                        className="selected-options1"
                        onClick={toggleProjectDropdown}
                      >
                        {selectedProjects.length === 0 ? (
                          <div className="placeholder">Select</div>
                        ) : (
                          <>
                            <div
                              className="selected-option1"
                              key={selectedProjects[0]}
                            >
                              {selectedProjects.length === projects.filter((project) => projectStatusFilter === "All" ? true : projectStatusFilter === "Active" ? project.is_active === true : project.is_active === false).length ? (
                                "All Projects"
                              ) : (
                                <>
                                  <TruncateFilterFieldView
                                    textContent={
                                      projects.find(
                                        (item) => item._id === selectedProjects[0]
                                      )?.project_name
                                    }
                                  />
                                  {selectedProjects.length > 1 &&
                                    selectedProjects.length !==
                                    projects.filter((project) => projectStatusFilter === "All" ? true : projectStatusFilter === "Active" ? project.is_active === true : project.is_active === false).length && (
                                      <div className="selected-option-count">
                                        {selectedProjects.length - 1}+
                                      </div>
                                    )}
                                </>
                              )}
                            </div>
                          </>
                        )}
                      </div>
                      {isProjectDropdownOpen && (
                        <div className="dropdown-list1">
                          {(user.userType === userTypeConsts.SUPER_ADMIN || user.userType === userTypeConsts.ADMIN) && (
                            <>
                              <div className="user-status-dropdown">
                                <div className="user-status-dropdown-label" htmlFor="projectStatusFilter">Filter by Status</div>
                                <div className="custom-dropdown-user" ref={projectStatusDropdownRef}>
                                  <div className="showing-name" onClick={toggleProjectStatusDropdown}>
                                    {projectStatusFilter ? projectStatusFilter : "Active"}
                                  </div>
                                </div>
                              </div>
                              {isProjectStatusDropdownOpen && (
                                <ul className="status-list">
                                  <li
                                    onClick={() => handleProjectStatusChange("All")}
                                    className={projectStatusFilter === "All" ? "selected-item" : ""}
                                  >
                                    All
                                  </li>
                                  <li
                                    onClick={() => handleProjectStatusChange("Active")}
                                    className={projectStatusFilter === "Active" ? "selected-item" : ""}
                                  >
                                    Active
                                  </li>
                                  <li
                                    onClick={() => handleProjectStatusChange("Inactive")}
                                    className={projectStatusFilter === "Inactive" ? "selected-item" : ""}
                                  >
                                    Inactive
                                  </li>
                                </ul>
                              )}
                            </>
                          )}
                          <input
                            type="text"
                            placeholder="Search projects"
                            value={projectSearchTerm}
                            onChange={handleProjectSearchChange}
                            className="search-bar"
                          />
                          {filteredProjects.filter(project =>
                            projectStatusFilter === "All" ? true :
                              projectStatusFilter === "Active" ? project.is_active === true :
                                project.is_active === false
                          ).length > 0 ? (
                            <>
                              {projectSearchTerm === "" && (
                                <label className="checkbox-label1" key="selectAll">
                                  <input
                                    type="checkbox"
                                    checked={selectAllProject}
                                    onChange={handleSelectAllProjectChange}
                                  />
                                  All Projects
                                </label>
                              )}
                              <div className="map-outer">
                                {filteredProjects
                                  .filter(project =>
                                    projectStatusFilter === "All" ? true :
                                      projectStatusFilter === "Active" ? project.is_active === true :
                                        project.is_active === false
                                  )
                                  .slice()
                                  .sort((a, b) =>
                                    a.project_name
                                      ?.toLowerCase()
                                      .localeCompare(b.project_name?.toLowerCase())
                                  )
                                  .map((project) => (
                                    <label
                                      className="checkbox-label1"
                                      key={project._id}
                                    >
                                      <input
                                        type="checkbox"
                                        checked={selectedProjects.includes(
                                          project._id
                                        )}
                                        onChange={() =>
                                          handleCheckboxProjectChange(project._id)
                                        }
                                      />
                                      {project.project_name &&
                                        `${project.project_name}`}
                                    </label>
                                  ))}
                              </div>
                            </>
                          ) : (
                            <div>No projects found</div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {user &&
                  (user.userType === userTypeConsts.SUPER_ADMIN ||
                    user.userType === userTypeConsts.ADMIN ||
                    user.userType === userTypeConsts.PROJECT_MANAGER ||
                    user.userType === userTypeConsts.TEAM_MANAGER) && (
                    <div className="filter-inner payment-status">
                      <label for="paymentStatus">Department</label>
                      <div className="custom-dropdown1" ref={deptDropdownRef}>
                        <div className="drop-down-inner">
                          <div
                            className="selected-options1"
                            onClick={toggleDeptDropdown}
                          >
                            {selectedDepts.length === 0 ? (
                              <div className="placeholder">Select</div>
                            ) : (
                              <>
                                <div
                                  className="selected-option1"
                                  key={selectedDepts[0]}
                                >
                                  {selectedDepts.length === departments.length ? (
                                    "All Departments"
                                  ) : (
                                    <>
                                      <TruncateFilterFieldView
                                        textContent={
                                          departments.find(
                                            (item) =>
                                              item._id === selectedDepts[0]
                                          )?.user_department
                                        }
                                      />
                                      {selectedDepts.length > 1 &&
                                        selectedDepts.length !==
                                        departments.length && (
                                          <div className="selected-option-count">
                                            {selectedDepts.length - 1}+
                                          </div>
                                        )}
                                    </>
                                  )}
                                </div>
                              </>
                            )}
                          </div>
                          {isDeptDropdownOpen && (
                            <div className="dropdown-list1">
                              <input
                                type="text"
                                placeholder="Search departments"
                                value={deptSearchTerm}
                                onChange={handleDeptSearchChange}
                                className="search-bar"
                              />
                              {filteredDepts.length > 0 ? (
                                <>
                                  {deptSearchTerm === "" && (
                                    <label
                                      className="checkbox-label1"
                                      key="selectAll"
                                    >
                                      <input
                                        type="checkbox"
                                        checked={selectAllDept}
                                        onChange={handleSelectAllDeptChange}
                                      />
                                      All Departments
                                    </label>
                                  )}
                                  {filteredDepts
                                    .slice()
                                    .sort((a, b) =>
                                      a.user_department
                                        ?.toLowerCase()
                                        .localeCompare(
                                          b.user_department?.toLowerCase()
                                        )
                                    )
                                    .map((dept) => (
                                      <label
                                        className="checkbox-label1"
                                        key={dept._id}
                                      >
                                        <input
                                          type="checkbox"
                                          checked={selectedDepts.includes(
                                            dept._id
                                          )}
                                          onChange={() =>
                                            handleCheckboxDeptChange(dept._id)
                                          }
                                        />
                                        {dept.user_department &&
                                          `${dept.user_department}`}
                                      </label>
                                    ))}
                                </>
                              ) : (
                                <div>No departments found</div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                <div className="filter-inner invoice-type">
                  <label for="invoice-type">Tag</label>
                  <div className="custom-dropdown1" ref={tagDropdownRef}>
                    <div className="drop-down-inner">
                      <div
                        className="selected-options1"
                        onClick={toggleTagDropdown}
                      >
                        {selectedTags.length === 0 ? (
                          <div className="placeholder">Select</div>
                        ) : (
                          <>
                            <div
                              className="selected-option1"
                              key={selectedTags[0]}
                            >
                              {selectedTags.length === tags.length ? (
                                "All Tags"
                              ) : (
                                <>
                                  <TruncateFilterFieldView
                                    textContent={
                                      tags.find(
                                        (item) => item._id === selectedTags[0]
                                      )?.tag_name
                                    }
                                  />
                                  {selectedTags.length > 1 &&
                                    selectedTags.length !== tags.length && (
                                      <div className="selected-option-count">
                                        {selectedTags.length - 1}+
                                      </div>
                                    )}
                                </>
                              )}
                            </div>
                          </>
                        )}
                      </div>
                      {isTagDropdownOpen && (
                        <div className="dropdown-list1">
                          <input
                            type="text"
                            placeholder="Search tags"
                            value={tagSearchTerm}
                            onChange={handleTagSearchChange}
                            className="search-bar"
                          />
                          {filteredTags.length > 0 ? (
                            <>
                              {tagSearchTerm === "" && (
                                <label className="checkbox-label1" key="selectAll">
                                  <input
                                    type="checkbox"
                                    checked={selectAllTag}
                                    onChange={handleSelectAllTagChange}
                                  />
                                  All Tags
                                </label>
                              )}
                              {filteredTags
                                .slice()
                                .sort((a, b) =>
                                  a.tag_name
                                    ?.toLowerCase()
                                    .localeCompare(b.tag_name?.toLowerCase())
                                )
                                .map((tag) => (
                                  <label
                                    className="checkbox-label1"
                                    key={tag._id}
                                  >
                                    <input
                                      type="checkbox"
                                      checked={selectedTags.includes(tag._id)}
                                      onChange={() =>
                                        handleCheckboxTagChange(tag._id)
                                      }
                                    />
                                    {tag.tag_name && `${tag.tag_name}`}
                                  </label>
                                ))}
                            </>
                          ) : (
                            <div>No tags found</div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="filter-inner invoice-type">
                  <label for="invoice-type">Sprint</label>
                  <div className="custom-dropdown1" ref={sprintDropdownRef}>
                    <div className="drop-down-inner">
                      <div
                        className="selected-options1"
                        onClick={toggleSprintDropdown}
                      >
                        {selectedSprints.length === 0 ? (
                          <div className="placeholder">Select</div>
                        ) : (
                          <>
                            <div
                              className="selected-option1"
                              key={selectedSprints[0]}
                            >
                              {selectedSprints.length === sprints.length ? (
                                "All Sprints"
                              ) : (
                                <>
                                  <TruncateFilterFieldView
                                    textContent={
                                      sprints.find(
                                        (item) => item._id === selectedSprints[0]
                                      )?.sprint_name
                                    }
                                  />
                                  {selectedSprints.length > 1 &&
                                    selectedSprints.length !== sprints.length && (
                                      <div className="selected-option-count">
                                        {selectedSprints.length - 1}+
                                      </div>
                                    )}
                                </>
                              )}
                            </div>
                          </>
                        )}
                      </div>
                      {isSprintDropdownOpen && (
                        <div className="dropdown-list1">
                          <input
                            type="text"
                            placeholder="Search sprints"
                            value={sprintSearchTerm}
                            onChange={handleSprintSearchChange}
                            className="search-bar"
                          />
                          {filteredSprints.length > 0 ? (
                            <>
                              {sprintSearchTerm === "" && (
                                <label className="checkbox-label1" key="selectAll">
                                  <input
                                    type="checkbox"
                                    checked={selectAllSprint}
                                    onChange={handleSelectAllSprintChange}
                                  />
                                  All Sprints
                                </label>
                              )}
                              {filteredSprints
                                .slice()
                                .sort((a, b) =>
                                  a.sprint_name
                                    ?.toLowerCase()
                                    .localeCompare(b.sprint_name?.toLowerCase())
                                )
                                .map((sprint) => (
                                  <label
                                    className="checkbox-label1"
                                    key={sprint._id}
                                  >
                                    <input
                                      type="checkbox"
                                      checked={selectedSprints.includes(sprint._id)}
                                      onChange={() =>
                                        handleCheckboxSprintChange(sprint._id)
                                      }
                                    />
                                    {sprint.sprint_name && `${sprint.sprint_name}`}
                                  </label>
                                ))}
                            </>
                          ) : (
                            <div>No sprints found</div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="filter-inner due-date">
                  <label htmlFor="dueDate" className="calendar-icon">
                    Date Range
                  </label>
                  <div
                    className="date-picker-outer date-picker-outer-all"
                    ref={calendarRef}
                  >
                    <div className="custom-picker-icon custom-picker-icon-all">
                      <svg
                        width="1em"
                        height="1em"
                        viewBox="0 0 14 14"
                        fill="currentColor"
                        aria-hidden="true"
                        focusable="false"
                        class="rs-picker-toggle-caret rs-icon"
                        aria-label="calendar"
                        data-category="legacy"
                      >
                        <path d="M1 4v8.5a.5.5 0 00.5.5H8c0-.128.049-.256.146-.354.555-.555.854-1.6.854-3.146a.5.5 0 01.621-.485l.119.03A2.623 2.623 0 0012.999 6.5V4h-12zm3-2h6V0h1v2h1.5A1.5 1.5 0 0114 3.5v3a3.623 3.623 0 01-4.015 3.603c-.064 1.245-.335 2.212-.831 2.898H12.5a.5.5 0 00.5-.5v-2a.5.5 0 011 0v2a1.5 1.5 0 01-1.5 1.5h-11a1.5 1.5 0 01-1.5-1.5v-9a1.5 1.5 0 011.5-1.5H3v-2h1v2zm5 4V5h1v1h2v1h-2v1H9V7H7v2h1v1H7v2H6v-2H4v2H3v-2H1V9h2V7H1V6h2V5h1v1h2V5h1v1h2zM6 9V7H4v2h2z"></path>
                      </svg>
                    </div>
                    <div className="custoom-icon-calender custoom-icon-calender-all">
                      <div className="custoom-icon-calender custoom-icon-calender-all custom-date-range ">

                        <input
                          type="text"
                          value={
                            dateRange
                              ? `${new Date(dateRange[0]).toLocaleDateString(
                                "en-GB",
                                {
                                  day: "2-digit",
                                  month: "2-digit",
                                  year: "numeric",
                                }
                              )} to ${new Date(dateRange[1]).toLocaleDateString(
                                "en-GB",
                                {
                                  day: "2-digit",
                                  month: "2-digit",
                                  year: "numeric",
                                }
                              )}`
                              : null
                          }
                          id="invoiceId"
                          name="invoiceId"
                          placeholder="All Time"
                          onClick={toggleCalendar}
                          autocomplete="off"
                          readOnly
                        />
                      </div>
                      <div className="date-range-new right-align">
                        {showCalendar && (
                          <Calendar
                            onCancel={cancelCalendar}
                            onChange={onDateChange}
                            initialRange={{
                              startDate: dateRange ? dateRange[0] : null,
                              endDate: dateRange ? dateRange[1] : null,
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="filter-invoice-right apply-fliter-right">
                <button onClick={toggleFilterModal}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="13"
                    height="17"
                    viewBox="0 0 13 17"
                    fill="none"
                  >
                    <path
                      d="M11.7363 8.81108C11.4276 9.81451 10.4925 10.5444 9.38889 10.5444C8.28471 10.5444 7.35016 9.8141 7.04147 8.81108L0.722221 8.81108C0.323349 8.81108 -1.07845e-06 8.48773 -1.06101e-06 8.08886C-1.04357e-06 7.68998 0.323349 7.36663 0.722221 7.36663L7.04144 7.36663C7.35022 6.3632 8.28529 5.6333 9.38889 5.6333C10.4931 5.6333 11.4276 6.36362 11.7363 7.36663L12.2778 7.36663C12.6767 7.36663 13 7.68998 13 8.08886C13 8.48773 12.6766 8.81108 12.2778 8.81108L11.7363 8.81108ZM9.38889 7.07775C8.83162 7.07775 8.37778 7.53123 8.37778 8.08886C8.37778 8.64613 8.83127 9.09997 9.38889 9.09997C9.94616 9.09997 10.4 8.64648 10.4 8.08886C10.4 7.53158 9.94651 7.07775 9.38889 7.07775Z"
                      fill="#6479F8"
                    />
                    <path
                      d="M5.38078 3.17778C5.072 4.18121 4.13693 4.91111 3.03333 4.91111C1.92882 4.91111 0.994035 4.18035 0.68563 3.17687C0.303759 3.15781 -1.07791e-06 2.84216 -1.06101e-06 2.45556C-1.04411e-06 2.06896 0.303748 1.75331 0.685606 1.73425C0.994098 0.730343 1.9294 -4.83911e-07 3.03333 -4.35657e-07C4.13752 -3.87392e-07 5.07206 0.730315 5.38076 1.73333L12.2778 1.73333C12.6767 1.73333 13 2.05668 13 2.45556C13 2.85443 12.6766 3.17778 12.2778 3.17778L5.38078 3.17778ZM3.03333 1.44444C2.47606 1.44444 2.02222 1.89793 2.02222 2.45556C2.02222 3.01283 2.47571 3.46667 3.03333 3.46667C3.5906 3.46667 4.04444 3.01318 4.04444 2.45556C4.04444 1.89828 3.59096 1.44444 3.03333 1.44444Z"
                      fill="#6479F8"
                    />
                    <path
                      d="M7.403 14.4444C7.09422 15.4478 6.15916 16.1777 5.05556 16.1777C3.95137 16.1777 3.01682 15.4474 2.70813 14.4444L0.722221 14.4444C0.323349 14.4444 -1.07845e-06 14.121 -1.06101e-06 13.7222C-1.04357e-06 13.3233 0.323349 12.9999 0.722221 12.9999L2.70811 12.9999C3.01689 11.9965 3.95195 11.2666 5.05556 11.2666C6.15974 11.2666 7.09429 11.9969 7.40298 12.9999L12.2778 12.9999C12.6767 12.9999 13 13.3233 13 13.7222C13 14.121 12.6766 14.4444 12.2778 14.4444L7.403 14.4444ZM5.05556 12.711C4.49828 12.711 4.04444 13.1645 4.04444 13.7222C4.04444 14.2794 4.49793 14.7333 5.05556 14.7333C5.61283 14.7333 6.06667 14.2798 6.06667 13.7222C6.06667 13.1649 5.61318 12.711 5.05556 12.711Z"
                      fill="#6479F8"
                    />
                  </svg>
                  All Filters
                </button>
              </div>
              <Modal
                isOpen={showFilterModal}
                onRequestClose={checkAndShowConfirmationModal}
                contentLabel="Filter Modal"
                className="new-filter-modal"
                style={{
                  content: {
                    height: "50%",
                    width: "50%",
                    position: "fixed",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-25%, -25%)",
                    borderRadius: "10px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    backgroundColor: "#fff",
                    padding: "20px",
                  },
                  overlay: {
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                  },
                }}
              >
                <div className="new-nodal-invoice-outer-sec">
                  <div className="new-modal-top-sec">
                    <div className="new-modal-heading">All filters</div>
                    <a
                      className="close-image-clr1"
                      onClick={checkAndShowConfirmationModal}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                      >
                        <path d="M1 13L13 1" stroke="black" />
                        <path d="M1 0.999999L13 13" stroke="black" />
                      </svg>{" "}
                    </a>
                  </div>
                  <div className="invoice-filter-inner-section">
                    <div className="filter-inner invoice-id">
                      <label for="fname">Task Name</label>
                      <input type="text" id="taskname" name="taskname" placeholder="Task name..." value={taskName} onChange={handleTaskNameChange} />
                    </div>
                    {user &&
                      (user.userType === userTypeConsts.SUPER_ADMIN ||
                        user.userType === userTypeConsts.ADMIN ||
                        user.userType === userTypeConsts.PROJECT_MANAGER ||
                        user.userType === userTypeConsts.TEAM_MANAGER) && (
                        <div className="filter-inner invoice-id">
                          <label>Team</label>
                          <div
                            className="custom-dropdown1"
                            ref={teamModalDropdownRef}
                          >
                            <div className="drop-down-inner">
                              <div
                                className="selected-options1"
                                onClick={toggleTeamModalDropdown}
                              >
                                {selectedTeams.length === 0 ? (
                                  <div className="placeholder">Select</div>
                                ) : (
                                  <>
                                    <div
                                      className="selected-option1"
                                      key={selectedTeams[0]}
                                    >
                                      {selectedTeams.length === teams.filter((team) => userStatusFilter === "All" ? true : userStatusFilter === "Active" ? team.is_active === true : team.is_active === false).length ? (
                                        "All Teams"
                                      ) : (
                                        <>
                                          <TruncateFilterFieldView
                                            textContent={
                                              teams.find((item) => item._id === selectedTeams[0])?.first_name
                                                ? `${teams.find((item) => item._id === selectedTeams[0]).first_name
                                                } ${teams.find((item) => item._id === selectedTeams[0]).middle_name
                                                  ? teams.find((item) => item._id === selectedTeams[0]).middle_name
                                                  : ""
                                                } ${teams.find((item) => item._id === selectedTeams[0]).last_name ? teams.find((item) => item._id === selectedTeams[0]).last_name : ""}`
                                                : teams.find(
                                                  (item) => item._id === selectedTeams[0])?.company_first_name
                                                  ? `${teams.find(
                                                    (item) => item._id === selectedTeams[0]).company_first_name} 
                                                  ${teams.find((item) => item._id === selectedTeams[0]).company_last_name
                                                    ? teams.find((item) => item._id === selectedTeams[0]).company_last_name
                                                    : ""
                                                  }`
                                                  : `${teams.find((item) => item._id === selectedTeams[0])?.company_name
                                                  } (Company)`
                                            }
                                          />
                                          {selectedTeams.length > 1 &&
                                            selectedTeams.length !==
                                            teams.length && (
                                              <div className="selected-option-count">
                                                {selectedTeams.length - 1}+
                                              </div>
                                            )}
                                        </>
                                      )}
                                    </div>
                                  </>
                                )}
                              </div>
                              {isTeamModalDropdownOpen && (
                                <div className="dropdown-list1">
                                  {(user.userType === userTypeConsts.SUPER_ADMIN || user.userType === userTypeConsts.ADMIN) && (
                                    <>
                                      <div className="user-status-dropdown">
                                        <div htmlFor="userStatusFilter">Filter by Status</div>
                                        <div className="custom-dropdown-user" ref={userStatusModalDropdownRef}>
                                          <div className="showing-name" onClick={toggleUserStatusModalDropdown}>
                                            {userStatusFilter ? userStatusFilter : "Active"}
                                          </div>
                                        </div>
                                      </div>
                                      {isUserStatusModalDropdownOpen && (
                                        <ul className="status-list">
                                          <li
                                            onClick={() => handleUserStatusChange("All")}
                                            className={userStatusFilter === "All" ? "selected-item" : ""}
                                          >
                                            All
                                          </li>
                                          <li
                                            onClick={() => handleUserStatusChange("Active")}
                                            className={userStatusFilter === "Active" ? "selected-item" : ""}
                                          >
                                            Active
                                          </li>
                                          <li
                                            onClick={() => handleUserStatusChange("Inactive")}
                                            className={userStatusFilter === "Inactive" ? "selected-item" : ""}
                                          >
                                            Inactive
                                          </li>
                                        </ul>
                                      )}
                                    </>
                                  )}
                                  <input
                                    type="text"
                                    placeholder="Search teams"
                                    value={userSearchTerm}
                                    onChange={handleUserSearchChange}
                                    className="search-bar"
                                  />
                                  {filteredUsers.filter(team =>
                                    userStatusFilter === "All" ? true :
                                      userStatusFilter === "Active" ? team.is_active === true :
                                        team.is_active === false
                                  ).length > 0 ? (
                                    <>
                                      {userSearchTerm === "" && (
                                        <label className="checkbox-label1" key="selectAll">
                                          <input
                                            type="checkbox"
                                            checked={selectAllTeam}
                                            onChange={handleSelectAllTeamChange}
                                          />
                                          All Teams
                                        </label>
                                      )}
                                      {filteredUsers && filteredUsers
                                        // .filter((team) =>
                                        //   userStatusFilter === "Active"
                                        //     ? team.is_active === true
                                        //     : team.is_active === false
                                        // )
                                        .filter((team) =>
                                          userStatusFilter === "All" ? true : userStatusFilter === "Active" ? team.is_active === true : team.is_active === false
                                        )
                                        .map((team) => (
                                          <label
                                            className="checkbox-label1"
                                            key={team._id}
                                          >
                                            <input
                                              type="checkbox"
                                              checked={selectedTeams.includes(
                                                team._id
                                              )}
                                              onChange={() =>
                                                handleCheckboxTeamChange(team._id)
                                              }
                                            />
                                            {team.first_name
                                              ? `${team.first_name} ${team.middle_name
                                                ? team.middle_name
                                                : ""
                                              } ${team.last_name}`
                                              : team.company_first_name
                                                ? `${team.company_first_name} ${team.company_last_name
                                                  ? team.company_last_name
                                                  : ""
                                                }`
                                                : `${team.company_name} (Company)`}
                                          </label>
                                        ))}
                                    </>
                                  ) : (
                                    <div>No teams found</div>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    <div className="filter-inner user">
                      <label for="user">Client</label>
                      <div
                        className="custom-dropdown1"
                        ref={clientModalDropdownRef}
                      >
                        <div className="drop-down-inner">
                          <div
                            className="selected-options1"
                            onClick={toggleClientModalDropdown}
                          >
                            {selectedClients.length === 0 ? (
                              <div className="placeholder">Select</div>
                            ) : (
                              <>
                                <div
                                  className="selected-option1"
                                  key={selectedClients[0]}
                                >
                                  {selectedClients.length === clients.length ? (
                                    "All Clients"
                                  ) : (
                                    <>
                                      <TruncateFilterFieldView
                                        textContent={
                                          clients.find(
                                            (item) =>
                                              item._id === selectedClients[0]
                                          )?.client_name
                                        }
                                      />
                                      {selectedClients.length > 1 &&
                                        selectedClients.length !==
                                        clients.length && (
                                          <div className="selected-option-count">
                                            {selectedClients.length - 1}+
                                          </div>
                                        )}
                                    </>
                                  )}
                                </div>
                              </>
                            )}
                          </div>
                          {isClientModalDropdownOpen && (
                            <div className="dropdown-list1">
                              <input
                                type="text"
                                placeholder="Search clients"
                                value={clientSearchTerm}
                                onChange={handleClientSearchChange}
                                className="search-bar"
                              />
                              {filteredClients.length > 0 ? (
                                <>
                                  {clientSearchTerm === "" && (
                                    <label
                                      className="checkbox-label1"
                                      key="selectAll"
                                    >
                                      <input
                                        type="checkbox"
                                        checked={selectAllClient}
                                        onChange={handleSelectAllClientChange}
                                      />
                                      All Clients
                                    </label>
                                  )}
                                  {filteredClients
                                    .slice()
                                    .sort((a, b) =>
                                      a.client_name?.toLowerCase().localeCompare(b.client_name?.toLowerCase())
                                    )
                                    .map((client) => (
                                      <label
                                        className="checkbox-label1"
                                        key={client._id}
                                      >
                                        <input
                                          type="checkbox"
                                          checked={selectedClients.includes(
                                            client._id
                                          )}
                                          onChange={() =>
                                            handleCheckboxClientChange(client._id)
                                          }
                                        />
                                        {client.client_name &&
                                          `${client.client_name}`}
                                      </label>
                                    ))}
                                </>
                              ) : (
                                <div>No clients found</div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="filter-inner invoice-status">
                      <label>Project</label>
                      <div
                        className="custom-dropdown1"
                        ref={projectModalDropdownRef}
                      >
                        <div className="drop-down-inner">
                          <div
                            className="selected-options1"
                            onClick={toggleProjectModalDropdown}
                          >
                            {selectedProjects.length === 0 ? (
                              <div className="placeholder">Select</div>
                            ) : (
                              <>
                                <div
                                  className="selected-option1"
                                  key={selectedProjects[0]}
                                >
                                  {selectedProjects.length === projects.filter((project) => projectStatusFilter === "All" ? true : projectStatusFilter === "Active" ? project.is_active === true : project.is_active === false).length ? (
                                    "All Projects"
                                  ) : (
                                    <>
                                      <TruncateFilterFieldView
                                        textContent={
                                          projects.find(
                                            (item) =>
                                              item._id === selectedProjects[0]
                                          )?.project_name
                                        }
                                      />
                                      {selectedProjects.length > 1 &&
                                        selectedProjects.length !==
                                        projects.filter((project) => projectStatusFilter === "All" ? true : projectStatusFilter === "Active" ? project.is_active === true : project.is_active === false).length && (
                                          <div className="selected-option-count">
                                            {selectedProjects.length - 1}+
                                          </div>
                                        )}
                                    </>
                                  )}
                                </div>
                              </>
                            )}
                          </div>
                          {isProjectModalDropdownOpen && (
                            <div className="dropdown-list1">
                              {(user.userType === userTypeConsts.SUPER_ADMIN || user.userType === userTypeConsts.ADMIN) && (
                                <>
                                  <div className="user-status-dropdown">
                                    <div htmlFor="projectStatusFilter">Filter by Status</div>
                                    <div className="custom-dropdown-user" ref={projectStatusModalDropdownRef}>
                                      <div className="showing-name" onClick={toggleProjectStatusModalDropdown}>
                                        {projectStatusFilter ? projectStatusFilter : "Active"}
                                      </div>
                                    </div>
                                  </div>
                                  {isProjectStatusModalDropdownOpen && (
                                    <ul className="status-list">
                                      <li
                                        onClick={() => handleProjectStatusChange("All")}
                                        className={projectStatusFilter === "All" ? "selected-item" : ""}
                                      >
                                        All
                                      </li>
                                      <li
                                        onClick={() => handleProjectStatusChange("Active")}
                                        className={projectStatusFilter === "Active" ? "selected-item" : ""}
                                      >
                                        Active
                                      </li>
                                      <li
                                        onClick={() => handleProjectStatusChange("Inactive")}
                                        className={projectStatusFilter === "Inactive" ? "selected-item" : ""}
                                      >
                                        Inactive
                                      </li>
                                    </ul>
                                  )}
                                </>
                              )}
                              <input
                                type="text"
                                placeholder="Search projects"
                                value={projectSearchTerm}
                                onChange={handleProjectSearchChange}
                                className="search-bar"
                              />
                              {filteredProjects.filter(project =>
                                projectStatusFilter === "All" ? true :
                                  projectStatusFilter === "Active" ? project.is_active === true :
                                    project.is_active === false
                              ).length > 0 ? (
                                <>
                                  {projectSearchTerm === "" && (
                                    <label
                                      className="checkbox-label1"
                                      key="selectAll"
                                    >
                                      <input
                                        type="checkbox"
                                        checked={selectAllProject}
                                        onChange={handleSelectAllProjectChange}
                                      />
                                      All Projects
                                    </label>
                                  )}
                                  {filteredProjects
                                    .filter(project =>
                                      projectStatusFilter === "All" ? true :
                                        projectStatusFilter === "Active" ? project.is_active === true :
                                          project.is_active === false
                                    )
                                    .slice()
                                    .sort((a, b) =>
                                      a.project_name?.toLowerCase()
                                        .localeCompare(
                                          b.project_name?.toLowerCase()
                                        )
                                    )
                                    .map((project) => (
                                      <label
                                        className="checkbox-label1"
                                        key={project._id}
                                      >
                                        <input
                                          type="checkbox"
                                          checked={selectedProjects.includes(
                                            project._id
                                          )}
                                          onChange={() =>
                                            handleCheckboxProjectChange(
                                              project._id
                                            )
                                          }
                                        />
                                        {project.project_name &&
                                          `${project.project_name}`}
                                      </label>
                                    ))}
                                </>
                              ) : (
                                <div>No projects found</div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="filter-inner payment-status">
                      <label for="paymentStatus">Department</label>
                      <div
                        className="custom-dropdown1"
                        ref={deptModalDropdownRef}
                      >
                        <div className="drop-down-inner">
                          <div
                            className="selected-options1"
                            onClick={toggleDeptModalDropdown}
                          >
                            {selectedDepts.length === 0 ? (
                              <div className="placeholder">Select</div>
                            ) : (
                              <>
                                <div
                                  className="selected-option1"
                                  key={selectedDepts[0]}
                                >
                                  {selectedDepts.length === departments.length ? (
                                    "All Departments"
                                  ) : (
                                    <>
                                      <TruncateFilterFieldView
                                        textContent={
                                          departments.find(
                                            (item) =>
                                              item._id === selectedDepts[0]
                                          )?.user_department
                                        }
                                      />
                                      {selectedDepts.length > 1 &&
                                        selectedDepts.length !==
                                        departments.length && (
                                          <div className="selected-option-count">
                                            {selectedDepts.length - 1}+
                                          </div>
                                        )}
                                    </>
                                  )}
                                </div>
                              </>
                            )}
                          </div>
                          {isDeptModalDropdownOpen && (
                            <div className="dropdown-list1">
                              <input
                                type="text"
                                placeholder="Search departments"
                                value={deptSearchTerm}
                                onChange={handleDeptSearchChange}
                                className="search-bar"
                              />
                              {filteredDepts.length > 0 ? (
                                <>
                                  {deptSearchTerm === "" && (
                                    <label
                                      className="checkbox-label1"
                                      key="selectAll"
                                    >
                                      <input
                                        type="checkbox"
                                        checked={selectAllDept}
                                        onChange={handleSelectAllDeptChange}
                                      />
                                      All Departments
                                    </label>
                                  )}
                                  {filteredDepts
                                    .slice()
                                    .sort((a, b) =>
                                      a.user_department
                                        ?.toLowerCase()
                                        .localeCompare(
                                          b.user_department?.toLowerCase()
                                        )
                                    )
                                    .map((dept) => (
                                      <label
                                        className="checkbox-label1"
                                        key={dept._id}
                                      >
                                        <input
                                          type="checkbox"
                                          checked={selectedDepts.includes(
                                            dept._id
                                          )}
                                          onChange={() =>
                                            handleCheckboxDeptChange(dept._id)
                                          }
                                        />
                                        {dept.user_department &&
                                          `${dept.user_department}`}
                                      </label>
                                    ))}
                                </>
                              ) : (
                                <div>No depatments found</div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="filter-inner invoice-type">
                      <label for="invoice-type">Tag</label>
                      <div className="custom-dropdown1" ref={tagModalDropdownRef}>
                        <div className="drop-down-inner">
                          <div
                            className="selected-options1"
                            onClick={toggleTagModalDropdown}
                          >
                            {selectedTags.length === 0 ? (
                              <div className="placeholder">Select</div>
                            ) : (
                              <>
                                <div
                                  className="selected-option1"
                                  key={selectedTags[0]}
                                >
                                  {selectedTags.length === tags.length ? (
                                    "All Tags"
                                  ) : (
                                    <>
                                      <TruncateFilterFieldView
                                        textContent={
                                          tags.find(
                                            (item) => item._id === selectedTags[0]
                                          )?.tag_name
                                        }
                                      />
                                      {selectedTags.length > 1 &&
                                        selectedTags.length !== tags.length && (
                                          <div className="selected-option-count">
                                            {selectedTags.length - 1}+
                                          </div>
                                        )}
                                    </>
                                  )}
                                </div>
                              </>
                            )}
                          </div>
                          {isTagModalDropdownOpen && (
                            <div className="dropdown-list1">
                              <input
                                type="text"
                                placeholder="Search tags"
                                value={tagSearchTerm}
                                onChange={handleTagSearchChange}
                                className="search-bar"
                              />
                              {filteredTags.length > 0 ? (
                                <>
                                  {tagSearchTerm === "" && (
                                    <label
                                      className="checkbox-label1"
                                      key="selectAll"
                                    >
                                      <input
                                        type="checkbox"
                                        checked={selectAllTag}
                                        onChange={handleSelectAllTagChange}
                                      />
                                      All Tags
                                    </label>
                                  )}
                                  {filteredTags
                                    .slice()
                                    .sort((a, b) =>
                                      a.tag_name
                                        ?.toLowerCase()
                                        .localeCompare(b.tag_name?.toLowerCase())
                                    )
                                    .map((tag) => (
                                      <label
                                        className="checkbox-label1"
                                        key={tag._id}
                                      >
                                        <input
                                          type="checkbox"
                                          checked={selectedTags.includes(tag._id)}
                                          onChange={() =>
                                            handleCheckboxTagChange(tag._id)
                                          }
                                        />
                                        {tag.tag_name && `${tag.tag_name}`}
                                      </label>
                                    ))}
                                </>
                              ) : (
                                <div>No tags found</div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="filter-inner invoice-type">
                      <label for="invoice-type">Sprint</label>
                      <div className="custom-dropdown1" ref={sprintModalDropdownRef}>
                        <div className="drop-down-inner">
                          <div
                            className="selected-options1"
                            onClick={toggleSprintModalDropdown}
                          >
                            {selectedSprints.length === 0 ? (
                              <div className="placeholder">Select</div>
                            ) : (
                              <>
                                <div
                                  className="selected-option1"
                                  key={selectedSprints[0]}
                                >
                                  {selectedSprints.length === sprints.length ? (
                                    "All Sprints"
                                  ) : (
                                    <>
                                      <TruncateFilterFieldView
                                        textContent={
                                          sprints.find(
                                            (item) => item._id === selectedSprints[0]
                                          )?.sprint_name
                                        }
                                      />
                                      {selectedSprints.length > 1 &&
                                        selectedSprints.length !== sprints.length && (
                                          <div className="selected-option-count">
                                            {selectedSprints.length - 1}+
                                          </div>
                                        )}
                                    </>
                                  )}
                                </div>
                              </>
                            )}
                          </div>
                          {isSprintModalDropdownOpen && (
                            <div className="dropdown-list1">
                              <input
                                type="text"
                                placeholder="Search sprints"
                                value={sprintSearchTerm}
                                onChange={handleSprintSearchChange}
                                className="search-bar"
                              />
                              {filteredSprints.length > 0 ? (
                                <>
                                  {sprintSearchTerm === "" && (
                                    <label
                                      className="checkbox-label1"
                                      key="selectAll"
                                    >
                                      <input
                                        type="checkbox"
                                        checked={selectAllSprint}
                                        onChange={handleSelectAllSprintChange}
                                      />
                                      All Sprints
                                    </label>
                                  )}
                                  {filteredSprints
                                    .slice()
                                    .sort((a, b) =>
                                      a.sprint_name
                                        ?.toLowerCase()
                                        .localeCompare(b.sprint_name?.toLowerCase())
                                    )
                                    .map((sprint) => (
                                      <label
                                        className="checkbox-label1"
                                        key={sprint._id}
                                      >
                                        <input
                                          type="checkbox"
                                          checked={selectedSprints.includes(sprint._id)}
                                          onChange={() =>
                                            handleCheckboxSprintChange(sprint._id)
                                          }
                                        />
                                        {sprint.sprint_name && `${sprint.sprint_name}`}
                                      </label>
                                    ))}
                                </>
                              ) : (
                                <div>No sprints found</div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="filter-inner due-date">
                      <label htmlFor="dueDate" className="calendar-icon">
                        Date Range
                      </label>
                      <div
                        className="date-picker-outer date-picker-outer-all"
                        ref={calendarModalRef}
                      >
                        <div className="custom-picker-icon custom-picker-icon-all">
                          <svg
                            width="1em"
                            height="1em"
                            viewBox="0 0 14 14"
                            fill="currentColor"
                            aria-hidden="true"
                            focusable="false"
                            class="rs-picker-toggle-caret rs-icon"
                            aria-label="calendar"
                            data-category="legacy"
                          >
                            <path d="M1 4v8.5a.5.5 0 00.5.5H8c0-.128.049-.256.146-.354.555-.555.854-1.6.854-3.146a.5.5 0 01.621-.485l.119.03A2.623 2.623 0 0012.999 6.5V4h-12zm3-2h6V0h1v2h1.5A1.5 1.5 0 0114 3.5v3a3.623 3.623 0 01-4.015 3.603c-.064 1.245-.335 2.212-.831 2.898H12.5a.5.5 0 00.5-.5v-2a.5.5 0 011 0v2a1.5 1.5 0 01-1.5 1.5h-11a1.5 1.5 0 01-1.5-1.5v-9a1.5 1.5 0 011.5-1.5H3v-2h1v2zm5 4V5h1v1h2v1h-2v1H9V7H7v2h1v1H7v2H6v-2H4v2H3v-2H1V9h2V7H1V6h2V5h1v1h2V5h1v1h2zM6 9V7H4v2h2z"></path>
                          </svg>
                        </div>
                        <div className="custoom-icon-calender custoom-icon-calender-all">
                          <div className="custoom-icon-calender custoom-icon-calender-all custom-date-range">
                            <input
                              type="text"
                              // onChange={}
                              value={
                                dateRange
                                  ? `${new Date(dateRange[0]).toLocaleDateString(
                                    "en-GB",
                                    {
                                      day: "2-digit",
                                      month: "2-digit",
                                      year: "numeric",
                                    }
                                  )} to ${new Date(
                                    dateRange[1]
                                  ).toLocaleDateString("en-GB", {
                                    day: "2-digit",
                                    month: "2-digit",
                                    year: "numeric",
                                  })}`
                                  : null
                              }
                              id="invoiceId"
                              name="invoiceId"
                              placeholder="All Time"
                              onClick={toggleModalCalendar}
                              autocomplete="off"
                            />
                          </div>

                          {/* Date range selector */}
                        
                          <div className="date-range-new">
                            {showModalCalendar && (
                              <Calendar
                                onCancel={cancelModalCalendar}
                                onChange={(range) => {

                                  const sDate = new Date(range.startDate.getFullYear(), range.startDate.getMonth(), range.startDate.getDate());
                                  const eDate = new Date(range.endDate.getFullYear(), range.endDate.getMonth(), range.endDate.getDate());
                                  // Convert the selected date range to the desired timezone
                                  const adjustedStartDate = range.startDate
                                    ? new Date(range.startDate)
                                    : null;
                                 
                                  const adjustedEndDate = range.endDate
                                    ? new Date(range.endDate)
                                    : null;

                                  // Handle the adjusted date range
                                  onDateChange({
                                    startDate: adjustedStartDate,
                                    endDate: adjustedEndDate,
                                  });
                                }}
                                initialRange={{
                                  startDate: dateRange
                                    ? new Date(dateRange[0])
                                    : null,
                                  endDate: dateRange
                                    ? new Date(dateRange[1])
                                    : null,
                                }}
                              />
                            )}
                          </div>
                          {/* Date range selector */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Modal
                  className="delete-modal"
                  isOpen={showConfirmationModal}
                  onRequestClose={handleCancel}
                  contentLabel="Confirm Delete"
                  style={{
                    content: {
                      height: "41%",
                      width: "30%",
                      position: "fixed",
                      top: "36%",
                      left: "50%",
                      transform: "translate(-24.75%, 0%)",
                      parent: document.querySelector(".admin-outer.time.tracker"),
                    },
                  }}
                >
                  <div className="delete-modal-content">
                    <h2>Apply or Discard changes?</h2>
                    <p>Do you want to Apply or Discard filter changes?</p>

                    <div className="delete-modal-buttons">
                      <button className="delete" onClick={handleApplyConfirm}>
                        Apply Changes
                      </button>
                      <button onClick={clearFilters}>Discard Changes</button>
                    </div>
                  </div>
                </Modal>
                <div className="filter-buttons">
                  <button className="clear-all" onClick={clearFilters}>
                    Clear All
                  </button>
                  <button className="apply-filter" onClick={applyFilters}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="13"
                      height="17"
                      viewBox="0 0 13 17"
                      fill="none"
                    >
                      <path
                        d="M11.7363 8.81108C11.4276 9.81451 10.4925 10.5444 9.38889 10.5444C8.28471 10.5444 7.35016 9.8141 7.04147 8.81108L0.722221 8.81108C0.323349 8.81108 -1.07845e-06 8.48773 -1.06101e-06 8.08886C-1.04357e-06 7.68998 0.323349 7.36663 0.722221 7.36663L7.04144 7.36663C7.35022 6.3632 8.28529 5.6333 9.38889 5.6333C10.4931 5.6333 11.4276 6.36362 11.7363 7.36663L12.2778 7.36663C12.6767 7.36663 13 7.68998 13 8.08886C13 8.48773 12.6766 8.81108 12.2778 8.81108L11.7363 8.81108ZM9.38889 7.07775C8.83162 7.07775 8.37778 7.53123 8.37778 8.08886C8.37778 8.64613 8.83127 9.09997 9.38889 9.09997C9.94616 9.09997 10.4 8.64648 10.4 8.08886C10.4 7.53158 9.94651 7.07775 9.38889 7.07775Z"
                        fill="white"
                      />
                      <path
                        d="M5.38078 3.17778C5.072 4.18121 4.13693 4.91111 3.03333 4.91111C1.92882 4.91111 0.994035 4.18035 0.68563 3.17687C0.303759 3.15781 -1.07791e-06 2.84216 -1.06101e-06 2.45556C-1.04411e-06 2.06896 0.303748 1.75331 0.685606 1.73425C0.994098 0.730343 1.9294 -4.83911e-07 3.03333 -4.35657e-07C4.13752 -3.87392e-07 5.07206 0.730315 5.38076 1.73333L12.2778 1.73333C12.6767 1.73333 13 2.05668 13 2.45556C13 2.85443 12.6766 3.17778 12.2778 3.17778L5.38078 3.17778ZM3.03333 1.44444C2.47606 1.44444 2.02222 1.89793 2.02222 2.45556C2.02222 3.01283 2.47571 3.46667 3.03333 3.46667C3.5906 3.46667 4.04444 3.01318 4.04444 2.45556C4.04444 1.89828 3.59096 1.44444 3.03333 1.44444Z"
                        fill="white"
                      />
                      <path
                        d="M7.403 14.4444C7.09422 15.4478 6.15916 16.1777 5.05556 16.1777C3.95137 16.1777 3.01682 15.4474 2.70813 14.4444L0.722221 14.4444C0.323349 14.4444 -1.07845e-06 14.121 -1.06101e-06 13.7222C-1.04357e-06 13.3233 0.323349 12.9999 0.722221 12.9999L2.70811 12.9999C3.01689 11.9965 3.95195 11.2666 5.05556 11.2666C6.15974 11.2666 7.09429 11.9969 7.40298 12.9999L12.2778 12.9999C12.6767 12.9999 13 13.3233 13 13.7222C13 14.121 12.6766 14.4444 12.2778 14.4444L7.403 14.4444ZM5.05556 12.711C4.49828 12.711 4.04444 13.1645 4.04444 13.7222C4.04444 14.2794 4.49793 14.7333 5.05556 14.7333C5.61283 14.7333 6.06667 14.2798 6.06667 13.7222C6.06667 13.1649 5.61318 12.711 5.05556 12.711Z"
                        fill="white"
                      />
                    </svg>
                    Apply Filters
                  </button>
                </div>
              </Modal>
            </div>
          </div>
        </div>

        <div className="overview-outer-sec-time">
          <div className="total-sec-overview">
            <OverviewTotal TotalHours={timerDatas} isLoading={timerLoading} />
          </div>
          <div className="graph-piechart">
            <div className="overview-graph-time">
              <TimeTrackerOverviewGraph
                timerData={timerDatas}
                graphDate={dateRange[1]}
                team={tracker.team}
              />
            </div>
            <div className="piechart">
              <TrackerOverviewPieChart timerData={timerDatas} />
            </div>
          </div>
          <div className="time-table-section">
            <TimeTable
              timerData={timerDatas}
              perPage={perPage}
              graphDate={dateRange}
              user={user}
              isLoading={timerLoading}
              queryClient={queryClient}
            />
          </div>
          <div className="print-sec-invoice" style={{ display: "none" }}>
            {showPrintSection && (<TimeTrackerOverviewPrintTable
              timerData={timerDatas}
              graphDate={dateRange}
              componentRef={componentRef}
              user={user}
            />)
            }
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default TimeTrackerOverview;
