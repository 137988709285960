import Modal from "react-modal";
import { toast } from "react-toastify";
import "./TypeOfLeave.css";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from 'react-router-dom';
import { ThreeDots } from 'react-loader-spinner';
import { useSelector, useDispatch } from 'react-redux';
import Delete from "../../../images/icons/delete.svg";
import React, { useEffect, useState, useRef } from "react";
import { logout, reset } from "../../../features/auth/authSlice";
import addicon from "../../../images/team-member-tab/add-icon.svg";
import crssrImage from "../../../images/singleinvoice/cross-red.svg";
import editimgblue from "../../../images/team-member-tab/edit-btn-blue.svg";
import LeftArrow from "../../../images/icons/blue-left-arrow.svg";
import { setHeaderText } from "../../../features/headerText/headerTextSlice";
import { reset as invoiceReset } from "../../../features/invoice/invoiceSlice.js";
import { reset as headerReset } from "../../../features/headerText/headerTextSlice.js";
import { reset as appSettingsReset } from "../../../features/appSettings/appSettingsSlice.js";
import { useCreateLeaveType, useDeleteLeaveType, useFetchLeaveTypes, useUpdateLeaveType } from "../../TimeTracker/TimeTrackerTeam/TimeTrackerTeamTypeOfLeave/leaveTypeApi.jsx";

const TypeOfLeave = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [leaveTypeCreate, setLeaveTypeCreate] = useState("");
  const leaveTypeCreateRef = useRef(null);
  const [leaveTypeEdit, setLeaveTypeEdit] = useState("");
  const [updatedLeaveType, setUpdatedLeaveType] = useState([]);
  const optionDropdownRef = useRef(null);
  const [selectedOption, setSelectedOption] = useState("Categories");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [filter, setFilter] = useState("");
  const [selectedSortOption, setSelectedSortOption] = useState(null);
  const [checkedOption, setCheckedOption] = useState(null);
  const [sortDropdownOpen, setSortDropdownOpen] = useState(false);
  const sortDropdownRef = useRef(null);
  let token = null;
  const { user } = useSelector((state) => state.auth);
  if (user) {
    if (user && user.token) {
      token = user.token;
    }
  }
  const { data: leaveType = [], isLoading, isError } = useFetchLeaveTypes(token);

  const deleteLeaveTypeMutation = useDeleteLeaveType(token);
  const createLeaveTypeMutation = useCreateLeaveType(token);
  const updateLeaveTypeMutation = useUpdateLeaveType(token);
  useEffect(() => {
    dispatch(setHeaderText("Time Off Settings"));
    setUpdatedLeaveType(leaveType);
  }, [leaveType]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        document.activeElement.blur();
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);


  const selectSortOption = (option) => {
    setSelectedSortOption(option);
    setCheckedOption(option);
    setSortDropdownOpen(false);
  };

  const toggleSortDropdown = () => {
    setSortDropdownOpen(!sortDropdownOpen);
  };

  const sortData = (data, sortOption) => {
    switch (sortOption) {
      case 'By name: A - Z':
        return data.slice().sort((a, b) => a.leave_type.localeCompare(b.leave_type));
      case 'By name: Z - A':
        return data.slice().sort((a, b) => b.leave_type.localeCompare(a.leave_type));
      case 'Date: old to new':
        return data.slice().sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
      case 'Date: new to old':
        return data.slice().sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      default:
        return data;
    }
  };

  const sortedData = sortData(updatedLeaveType, selectedSortOption);

  const filteredData = sortedData.filter((item) => {
    const searchTerm = filter.trim()?.toLowerCase();
    const hasMatch =
      item.leave_type?.toLowerCase().includes(searchTerm);
    return hasMatch;
  });

  const handleOptionClick = (option, url) => {
    setSelectedOption(option);
    navigate(url);
    setDropdownOpen(false);
  };
  const handleOpenModal = (item) => {
    setIsModalOpen(true);
    setSelectedId(item._id);
    setLeaveTypeEdit(item.leave_type);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedId("");
    setLeaveTypeEdit("");
  };
  const handleDelete = () => {
    setIsDeleteModalOpen(true);
  };
  const handleConfirmDelete = async () => {
    try {
      setIsSubmitLoading(true);
      await deleteLeaveTypeMutation.mutateAsync(selectedId);
      setUpdatedLeaveType((prevState) => prevState.filter((leaveType) => leaveType._id !== selectedId));
      toast.success("Leave type removed successfully", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      global.config.activityLog(window.location.href, 'Leave Type', `Leave Type (${selectedId}) removed successfully`);
      handleCloseModal();

    } catch (error) {
      toast.error("Something went wrong", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      global.config.slackMessage(error.toString());
      console.log(error.response.data.message); // print server error message
      if (error.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        toast.error('This user is deleted!', {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
        return;
      }
    } finally {
      setIsSubmitLoading(false);
    }
    setIsDeleteModalOpen(false);
  };

  const handleCancelDelete = () => {
    setIsDeleteModalOpen(false);
  };
  const handleCreateSubmit = async (event) => {
    event.preventDefault();
    if (leaveTypeCreate === "") {
      toast.error("Leave type cannot be empty!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      leaveTypeCreateRef.current.focus();
      return;
    }
    const requestData = {
      leave_type: leaveTypeCreate,
    };
    try {
      setIsSubmitLoading(true);
      const data = await createLeaveTypeMutation.mutateAsync(requestData);
      if (data.status === "error") {
        toast.error("Something went wrong", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        global.config.activityLog(window.location.href, 'TaLeaveType', `Something went wrong while adding Leave Type ${leaveTypeCreate}`);
      } else if (data.status === "exists") {
        toast.error("This leave type already exists", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        global.config.activityLog(window.location.href, 'TaLeaveType', `Leave Type ${leaveTypeCreate} already exist`);
      } else {
        toast.success("Leave type added successfully", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        global.config.activityLog(window.location.href, 'TaLeaveType', `Leave Type ${leaveTypeCreate} added successfully`);
        setLeaveTypeCreate("");
      }
    } catch (error) {
      console.log(error);
      global.config.slackMessage(error.toString());
      if (error.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        toast.error('This user is deleted!', {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
        return;
      }
      toast.error("Something went wrong", {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      global.config.activityLog(window.location.href, 'TaLeaveType', `Something went wrong`);
    } finally {
      setIsSubmitLoading(false); // Set isLoading to false after the approval process is complete
    }
  };
  const handleUpdateSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsSubmitLoading(true);
      const response = await updateLeaveTypeMutation.mutateAsync({
        selectedId,
        leave_type: leaveTypeEdit,
      });
      const data = response.data;
      if (data.status === "error") {
        toast.error("Something went wrong", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (data.status === "exists") {
        toast.error("Leave type already exists", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        setUpdatedLeaveType((prevState) => {
          const updatedLeaveType = prevState.map((item) => {
            if (item._id === selectedId) {
              return { ...item, leave_type: leaveTypeEdit };
            }
            return item;
          });
          return updatedLeaveType;
        });
        toast.success("Leave type updated successfully", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        global.config.activityLog(window.location.href, 'Leave Type', `Leave Type ${leaveTypeEdit} updated successfully`);
        handleCloseModal();

      }
    } catch (error) {
      console.log(error.response.data.message); // print server error message
      global.config.slackMessage(error.toString());
      if (error.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        toast.error('This user is deleted!', {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
        return;
      }
      alert("Something went wrong");
    } finally {
      setIsSubmitLoading(false);
    }
  };

  const handleClickOutsideDropdown = (event) => {
    const isClickInsideSortDropdown = sortDropdownRef.current && sortDropdownRef.current.contains(event.target);

    if (!isClickInsideSortDropdown) {
      setSortDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutsideDropdown);
    return () => {
      document.removeEventListener('click', handleClickOutsideDropdown);
    };
  }, []);

  return (
    <div className='tab-outer-time'>
      <ul className="tabs">
        <li>
          <Link to={`/user/time-off/leave-type`} className={window.location.pathname.startsWith('/user/time-off/leave-type') ? 'active' : ''}>
            Categories
          </Link>
        </li>
      </ul>

      <div className="tabs-content">

        <div className='div-outter-for-department'>
          <div className="text-arrow-outer">
            <div className="text-arrow-inner">
              <Link to={`/user/time-off/overview`}>
                <div className="text-arrow">
                  <div className="left-arrow">
                    <img src={LeftArrow} alt="left-arrow" />
                  </div>
                  <div className="top-text">
                    Back to Time Off
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className='add-department-section top-filter-client-inner'>
            <div className="top-filter-client-inner-left">
              <input
                type='text'
                placeholder='Add new category'
                value={leaveTypeCreate}
                onChange={(e) => setLeaveTypeCreate(e.target.value)}
                ref={leaveTypeCreateRef}
              />
              <button className='add-btn-in-department-b1' onClick={handleCreateSubmit} disabled={isSubmitLoading}>
                {isSubmitLoading ? (
                  <div className="outter-load-table">
                    <ThreeDots
                      height="21"
                      width="25"
                      radius="9"
                      color="white"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  </div>
                ) : (
                  <img src={addicon} alt="add" />
                )}
              </button>
            </div>

            <div className="top-filter-client-sort">

              <div className="custom-dropdown1">
                <div
                  ref={sortDropdownRef}
                  className={`custom-dropdown-user-new-outer custom-dropdown-select ${sortDropdownOpen ? 'open' : ''}`}
                >
                  <div className="selected-option custom-dropdown-user-new" onClick={toggleSortDropdown}>
                  <div className="center-sort">
                    {selectedSortOption ? selectedSortOption : 'Sort By'}
                    </div>
                  </div>
                  {sortDropdownOpen && (
                    <div className="custom-dropdown-new">
                      <ul className="type-list">
                        <li
                          className={checkedOption === 'By name: A - Z' ? 'selected' : ''}
                          onClick={() => selectSortOption('By name: A - Z')}
                        >
                          <span className={`checkbox ${checkedOption === 'By name: A - Z' ? 'checked' : ''}`}></span>
                          By name: A - Z
                        </li>
                        <li
                          className={checkedOption === 'By name: Z - A' ? 'selected' : ''}
                          onClick={() => selectSortOption('By name: Z - A')}
                        >
                          <span className={`checkbox ${checkedOption === 'By name: Z - A' ? 'checked' : ''}`}></span>
                          By name: Z - A
                        </li>
                        <li
                          className={checkedOption === 'Date: old to new' ? 'selected' : ''}
                          onClick={() => selectSortOption('Date: old to new')}
                        >
                          <span className={`checkbox ${checkedOption === 'Date: old to new' ? 'checked' : ''}`}></span>
                          Date: old to new
                        </li>
                        <li
                          className={checkedOption === 'Date: new to old' ? 'selected' : ''}
                          onClick={() => selectSortOption('Date: new to old')}
                        >
                          <span className={`checkbox ${checkedOption === 'Date: new to old' ? 'checked' : ''}`}></span>
                          Date: new to old
                        </li>
                        {/* Add more sorting options as needed */}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
              <div className="top-filter-client-sort-search">
                <div className="top-filter-client-search">
                  <div className="search-sec">
                    <div className="search-container">
                      <input type="text" className="search-input" placeholder="Search by leave category name" onChange={(e) => setFilter(e.target.value)} value={filter} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='department-section-listing'>
            {isLoading == true ? (
              <div className="outter-load-table">
                <ThreeDots
                  height="80"
                  width="80"
                  radius="9"
                  color="#405BFF"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              </div>
            ) : (
              <>
                {filteredData.map((item, index) => (
                  <div className='grid-item' key={item._id}>
                    <input type='text' className='input-tag' value={item.leave_type} readOnly />
                    <button className='edit-button' onClick={() => handleOpenModal(item)}><img src={editimgblue} alt="edit" />Edit</button>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
        <div className="modal-invite-main-outter">
          <Modal
            className="client-modal leave"
            isOpen={isModalOpen}
            onRequestClose={handleCloseModal}
            contentLabel="New Client Account"
            style={{
              content: {
                height: "41%",
                width: "30%",
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                parent: document.querySelector(".admin-outer.time.tracker"),
              },
            }}
          >
            <div className="modal">
              <div className="modal-close-invite outter-modal-main">
                <a className="" onClick={handleCloseModal}>
                  <img src={crssrImage} alt="Edit Icon" />{" "}
                </a>
              </div>
              <div className="modal-client-head">
                <h2 className="">Edit Leave</h2>
              </div>
              <div className="form-field-client-outer">
                <form onSubmit={handleUpdateSubmit}>
                  <div className="form-field-client">
                    <label htmlFor="clientName" className="form-label">
                      Name of Leave
                    </label>
                    <input
                      type="text"
                      id="clientName"
                      name="clientName"
                      className="form-input"
                      value={leaveTypeEdit}
                      onChange={(e) => setLeaveTypeEdit(e.target.value)}
                      required
                    />
                  </div>
                  {isSubmitLoading ?
                    (
                      <div className="outter-load-table">
                        <ThreeDots
                          height="38"
                          width="40"
                          radius="9"
                          color="#6479f9"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClassName=""
                          visible={true}
                        />
                      </div>
                    ) : (
                      <div className="form-field-client-btns">
                        <button
                          type="button"
                          className="delete-client"
                          onClick={handleDelete}
                          disabled={isSubmitLoading}
                        >
                          <img src={Delete} alt="Delete" />  Delete
                        </button>
                        <Modal
                          className="delete-modal"
                          isOpen={isDeleteModalOpen}
                          onRequestClose={!isSubmitLoading ? handleCancelDelete : undefined}
                          contentLabel="Confirm Delete"
                          style={{
                            content: {
                              height: "41%",
                              width: "30%",
                              position: "fixed",
                              top: "36%",
                              left: "50%",
                              transform: "translate(-21.75%, -5%)",
                              parent: document.querySelector(".admin-outer.time.tracker"),
                            },
                          }}
                        >
                          <div className="delete-modal-content">
                            <h2>Confirm Delete</h2>
                            <p>
                              Are you sure you want to delete this type of leave?
                            </p>
                            {isSubmitLoading ? (
                              <div className="outter-load-table">
                                <ThreeDots
                                  height="38"
                                  width="40"
                                  radius="9"
                                  color="#6479f9"
                                  ariaLabel="three-dots-loading"
                                  wrapperStyle={{}}
                                  wrapperClassName=""
                                  visible={true}
                                />
                              </div>
                            ) : (
                              <div className="delete-modal-buttons">
                                <button className="delete" onClick={handleConfirmDelete}>
                                  Delete
                                </button>
                                <button onClick={handleCancelDelete}>
                                  Cancel
                                </button>
                              </div>
                            )}
                          </div>
                        </Modal>
                        <button
                          type="submit"
                          className="save-changes"
                          disabled={isSubmitLoading}
                        >
                          Save Changes
                        </button>
                      </div>
                    )}
                </form>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default TypeOfLeave;