import axios from "axios";
import moment from "moment";
import Modal from "react-modal";
import { DatePicker } from "rsuite";
import isEqual from "lodash/isEqual";
import "./TimeTrackerOverviewTable.css";
import "react-toastify/dist/ReactToastify.css";
import { useGetTeams } from "./useTimeTracker.jsx";
import { ThreeDots } from "react-loader-spinner";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import React, { useState, useEffect, useRef } from "react";
import userTypeConsts from "../../../constants/userTypeConsts.js";
import TopArrow from "../../../images/icons/top-small-arrow.svg";
import UpArrowImage from "../../../images/icons/up-down-arrow.svg";
import DownArrowImage from "../../../images/icons/up-down-arrow.svg";
import DownArrow from "../../../images/icons/bottom-small-arrow.svg";
import crssrImage from "../../../images/singleinvoice/cross-red.svg";
import TimestampConverter from "../Timer/tracker/helpers/TimestampConverter.jsx";
import { setNotification } from "../../../features/notification/notificationSlice.js";
import { useClientProjectDetails, useTagDetails } from "../Timer/tracker/TimerDataApi.jsx";
import editImage from "../../../images/icons/blue-edit.svg";
import Delete from "../../../images/icons/delete.svg";
import { setTimerData } from "../../../features/timerData/timerDataSlice.js";
import TruncateUserName from "../../helpers/truncateUserName.js";
import TruncateFieldView from "../../helpers/truncateFieldView.js";

const TimeTable = (props) => {
  const [data, setData] = useState([]);
  const [currentData, setCurrentData] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "" });
  const [currentPage, setCurrentPage] = useState(1);
  const [visiblePages, setVisiblePages] = useState([]);
  const tracker = useSelector((state) => state.tracker);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [selectedGroupId, setSelectedGroupId] = useState([]);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [isBulkEditModalOpen, setIsBulkEditModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [userSelectedId, setUserSelectedId] = useState("");
  const [userDropdownOpen, setUserDropdownOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [errorMessage, setErrorMessage] = useState({
    message: null,
    field: null,
  });
  const [uStartTimeError, setUstartTimeError] = useState(false);
  const [uEndTimeError, setUEndTimeError] = useState(false);
  const [uFormatedTime, setUFormatedTime] = useState("00:00:00");
  const [uFormatedTimeError, setUFormatedTimeError] = useState(false);
  const [projectEditDropdownOpen, setProjectEditDropdownOpen] = useState(false);
  const [tagEditDropdownOpen, setTagEditDropdownOpen] = useState(false);
  const [tagQuery, setTagQuery] = useState("");
  const [filter, setFilter] = useState("");
  const [tagEdit, setTagEdit] = useState(null);
  const [tagIdEdit, setTagIdEdit] = useState(null);
  const [clientEdit, setClientEdit] = useState(null);
  const [clientIdEdit, setClientIdEdit] = useState(null);
  const [taskNameEdit, setTaskNameEdit] = useState("");
  const [projectIdEdit, setProjectIdEdit] = useState("");
  const [projectNameEdit, setProjectNameEdit] = useState("");
  const [sprintNameEdit, setSprintNameEdit] = useState("");
  const [sprintIdEdit, setSprintIdEdit] = useState("");
  const [dateEdit, setDateEdit] = useState(new Date());
  const [startTimeEdit, setStartTimeEdit] = useState("07:30 pm");
  const [endTimeEdit, setEndTimeEdit] = useState("08:30 pm");
  const [billableEdit, setBillableEdit] = useState(false);
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);
  const [dateOldEdit, setDateOldEdit] = useState(null);
  const [uDuration, setUDuration] = useState(null);
  const [groupedData, setGroupedData] = useState([]);
  const [expandedGroups, setExpandedGroups] = useState([]);
  const datePickerRef = useRef();
  const [selectedClients, setSelectedClients] = useState(new Set());
  const [selectedProject, setSelectedProject] = useState(null);
  const [projectStatusFilter, setProjectStatusFilter] = useState("Active");
  const [isProjectStatusDropdownOpen, setIsProjectStatusDropdownOpen] = useState(false);
  const projectStatusDropdownRef = useRef(null);
  const perPage = props.perPage;
  const userType = props.user.userType;
  var filteredData = [];
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [lastSelectedRowIndex, setLastSelectedRowIndex] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteDone, setDeleteDone] = useState(false);
  const { appSettings } = useSelector((state) => state.appSettings);
  const [userCheck, setUserCheck] = useState(false);
  const [taskCheck, setTaskCheck] = useState(false);
  const [projectCheck, setProjectCheck] = useState(false);
  const [clientCheck, setClientCheck] = useState(false);
  const [tagCheck, setTagCheck] = useState(false);
  const [dateCheck, setDateCheck] = useState(false);
  const [timeCheck, setTimeCheck] = useState(false);
  const [durationCheck, setDurationCheck] = useState(false);

  const sortTable = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
    setExpandedGroups([]);
    setCurrentData((prevData) => {
      const sortedData = [...prevData].sort((a, b) => {

        if (key === "name" || key === "client" || key === "project" || key === "sprint" || key === "tag") {
          if (key === "name") {
            const aName =
              `${a.user.first_name} ${a.user.last_name}`.toLowerCase();
            const bName =
              `${b.user.first_name} ${b.user.last_name}`.toLowerCase();
            return direction === "asc"
              ? aName.localeCompare(bName)
              : bName.localeCompare(aName);
          } else if (key === "client") {
            // Handle sorting for Client column
            const aClient = a.project.client_name
              ? a.project.client_name.client_name.toLowerCase()
              : "";
            const bClient = b.project.client_name
              ? b.project.client_name.client_name.toLowerCase()
              : "";
            return direction === "asc"
              ? aClient.localeCompare(bClient)
              : bClient.localeCompare(aClient);
          } else if (key === "project") {
            // Handle sorting for Project column
            return direction === "asc"
              ? a.project.project_name.localeCompare(b.project.project_name)
              : b.project.project_name.localeCompare(a.project.project_name);
          } else if (key === "sprint") {
            const aSprint = a.sprint?.sprint_name ? a.sprint.sprint_name.toLowerCase() : "";
            const bSprint = b.sprint?.sprint_name ? b.sprint.sprint_name.toLowerCase() : "";
            return direction === "asc"
              ? aSprint.localeCompare(bSprint)
              : bSprint.localeCompare(aSprint);
          } else if (key === "tag") {
            const aTag = a.tag?.tag_name ? a.tag.tag_name.toLowerCase() : "";
            const bTag = b.tag?.tag_name ? b.tag.tag_name.toLowerCase() : "";
            return direction === "asc"
              ? aTag.localeCompare(bTag)
              : bTag.localeCompare(aTag);
          }
        } else if (key === "duration") {
          // Handle sorting for duration (convert to seconds for comparison)
          // return direction === "asc" ? a[key] - b[key] : b[key] - a[key];
          return direction === "asc"
            ? a.totalDuration - b.totalDuration
            : b.totalDuration - a.totalDuration;
        } else if (key === "date") {
          // Handle sorting for date
          return direction === "asc"
            ? new Date(a[key]) - new Date(b[key])
            : new Date(b[key]) - new Date(a[key]);
        } else if (key === "billable") {
          // Handle sorting for billable (true before false)
          return direction === "asc"
            ? a.project.is_billable - b.project.is_billable
            : b.project.is_billable - a.project.is_billable;
        }
        return 0;
      });

      return sortedData;
    });
  };
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  var currentItems = [];
  const projectDropdownRef = useRef(null);
  const tagDropdownRef = useRef(null);
  const userDropdownRef = useRef(null);
  const modalContentRef = useRef(null);
  const {
    data: userId = [],
    isLoading: teamLoading,
    isError: teamError,
  } = useGetTeams(token);
  const { data: clientData = [], isLoading: isLoadingClientProjectDetails } =
    useClientProjectDetails(token);
  const { data: tagOptions = [], isLoading: isLoadingTagDetails } =
    useTagDetails(token);

  // useEffect(() => {
  //   setCurrentPage(1);
  //   setShowConfirmation(false);
  // }, [props.perPage, props.timerData]);

  useEffect(() => {
    const resetState = async () => {
      // Non-blocking state updates
      setCurrentPage(1);
      setShowConfirmation(false);
    };

    resetState();
  }, [props.perPage, props.timerData]);

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     const isClickInsideProjectStatusDropdown = projectStatusDropdownRef.current && projectStatusDropdownRef.current.contains(event.target);
  //     if (
  //       modalContentRef.current &&
  //       !modalContentRef.current.contains(event.target) &&
  //       !projectDropdownRef.current?.contains(event.target) &&
  //       !tagDropdownRef.current?.contains(event.target) &&
  //       !userDropdownRef.current?.contains(event.target)
  //     ) {
  //       setProjectEditDropdownOpen(false);
  //       setTagEditDropdownOpen(false);
  //       setUserDropdownOpen(false);
  //     }
  //     if (!isClickInsideProjectStatusDropdown) {
  //       setIsProjectStatusDropdownOpen(false);
  //     }
  //   };

  //   // Attach the event listener
  //   document.addEventListener("click", handleClickOutside);

  //   // Clean up the event listener on component unmount
  //   return () => {
  //     document.removeEventListener("click", handleClickOutside);
  //   };
  // }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const isClickInsideProjectStatusDropdown = projectStatusDropdownRef.current?.contains(event.target);
      const isClickInsideModalContent = modalContentRef.current?.contains(event.target);
      const isClickInsideOtherDropdowns =
        projectDropdownRef.current?.contains(event.target) ||
        tagDropdownRef.current?.contains(event.target) ||
        userDropdownRef.current?.contains(event.target);

      if (!isClickInsideModalContent && !isClickInsideOtherDropdowns) {
        setProjectEditDropdownOpen(false);
        setTagEditDropdownOpen(false);
        setUserDropdownOpen(false);
      }

      if (!isClickInsideProjectStatusDropdown) {
        setIsProjectStatusDropdownOpen(false);
      }
    };

    // Attach the event listener
    document.addEventListener("click", handleClickOutside);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const { queryClient } = props;


  // useEffect(() => {
  //   const getTableData = async () => {
  //     setIsTableLoading(true);
  //     try {
  //       let filtered = props.timerData;
  //       setData(filtered);
  //     } catch (err) {
  //       global.config.slackMessage(err.toString());
  //       console.log(err);
  //     }
  //   };
  //   getTableData();
  //   updateVisiblePages(currentPage);

  //   props.timerData.sort((a, b) => {
  //     //  if (a.task !== b.task) return a.task.localeCompare(b.task);
  //     if (a?.project?._id !== b?.project?._id)
  //       return a.project._id.localeCompare(b.project._id);
  //     // if (a.tag && b.tag && a.tag?._id !== b.tag?._id) return a.tag?._id.localeCompare(b.tag?._id);
  //     return 0;
  //   });

  //   const updatedGroupedData = groupAndSumDurations(props.timerData);
  //   updatedGroupedData.sort((a, b) => {
  //     return new Date(b.date) - new Date(a.date);
  //   });

  //   setGroupedData(updatedGroupedData);
  //   const indexOfLastItem = currentPage * perPage;
  //   const indexOfFirstItem = indexOfLastItem - perPage;
  //   currentItems = updatedGroupedData.slice(indexOfFirstItem, indexOfLastItem);
  //   setCurrentData(currentItems);
  //   paginate(currentPage);
  //   setIsTableLoading(false);
  // }, [
  //   tracker,
  //   currentPage,
  //   perPage,
  //   tracker,
  //   props.timerData,
  //   props.perPage,
  //   props.team,
  //   props.graphDate,
  //   deleteDone,
  // ]);

  useEffect(() => {
    const getTableData = async () => {
      setIsTableLoading(true);

      try {
        // Filter data from props
        const filtered = props.timerData;
        setData(filtered);

        // Sort props.timerData for table display
        const sortedTimerData = [...props.timerData].sort((a, b) => {
          if (a?.project?._id !== b?.project?._id) {
            return a.project._id.localeCompare(b.project._id);
          }
          return 0;
        });

        // Group and sum durations, then sort by date
        const updatedGroupedData = groupAndSumDurations(sortedTimerData).sort((a, b) => {
          return new Date(b.date) - new Date(a.date);
        });

        // Paginate data
        const indexOfLastItem = currentPage * perPage;
        const indexOfFirstItem = indexOfLastItem - perPage;
        const currentItems = updatedGroupedData.slice(indexOfFirstItem, indexOfLastItem);

        // Update states
        setGroupedData(updatedGroupedData);
        setCurrentData(currentItems);

        // Update pagination
        paginate(currentPage);
      } catch (err) {
        global.config.slackMessage(err.toString());
        console.error(err);
      } finally {
        setIsTableLoading(false);
      }
    };

    // Fetch table data and update pagination
    getTableData();

    // Update visible pages
    updateVisiblePages(currentPage);
  }, [
    currentPage,
    perPage,
    props.timerData,
    props.perPage,
    props.team,
    props.graphDate,
    deleteDone,
  ]);

  const groupAndSumDurations = (data) => {
    const groupedData = [];
    let currentGroup = null;
    let totalDuration = 0;
    const formatDate = (dateString) => {
      if (!dateString) {
        return null;
      }
      const dateObject = new Date(dateString);
      const formattedDate = dateObject.toISOString().split("T")[0];
      return formattedDate;
    };

    data.forEach((row) => {
      if (
        currentGroup &&
        currentGroup.user._id === row.user._id &&
        currentGroup.task === row.task &&
        currentGroup.project._id === row.project._id &&
        formatDate(currentGroup.date) === formatDate(row.date)
      ) {
        // If the current row belongs to the same group, update the duration
        totalDuration += row.duration;
        currentGroup.entries.push(row);
      } else {
        // If the row starts a new group, push the previous group (if any) and start a new one
        if (currentGroup) {
          currentGroup.totalDuration = totalDuration;
          groupedData.push(currentGroup);
        }
        // Start a new group
        currentGroup = {
          _id: row._id,
          user: row.user,
          task: row.task,
          project: row.project,
          sprint: row.sprint,
          tag: row.tag,
          date: row.date,
          startTime: row.startTime,
          endTime: row.endTime,
          totalDuration: row.duration,
          entries: [row],
        };
        totalDuration = row.duration;
      }
    });

    // Push the last group (if any)
    if (currentGroup) {
      currentGroup.totalDuration = totalDuration;
      groupedData.push(currentGroup);
    }

    return groupedData;
  };

  const toggleGroup = (groupIndex) => {
    setExpandedGroups((prevGroups) => {
      if (prevGroups.includes(groupIndex)) {
        // Group is expanded, so collapse it
        return prevGroups.filter((index) => index !== groupIndex);
      } else {
        // Group is collapsed, so expand it
        return [...prevGroups, groupIndex];
      }
    });
  };

  const handleToggleDropdown = () => {
    setSearchTerm("");
    setUserDropdownOpen((prevOpen) => !prevOpen);
  };

  //This function handles the selection of a user
  //It sets the error message to null, sets the selected user id, sets the search term to an empty string, and closes the user dropdown
  const handleSelectUser = (selectedUserId) => {
    setErrorMessage({ message: null, field: null });
    setUserSelectedId(selectedUserId);
    setSearchTerm("");
    setUserDropdownOpen(false);
  };

  const handleSearchInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Filter the userId array to return only the users whose full name or company name includes the searchTerm
  const filteredUsers = userId.filter((user) => {
    // Create a variable to store the full name of the user
    const fullName = user.first_name
      ? `${user.first_name} ${user.middle_name || ""} ${user.last_name}`
      : `${user.company_first_name
        ? user.company_first_name +
        (user.company_last_name && user.company_last_name)
        : user.company_name
      }`;
    // Return true if the full name or company name includes the searchTerm
    return fullName.toLowerCase().includes(searchTerm.toLowerCase());
  });

  // This function is used to open the details modal and set the selected item's details
  const handleOpenDetailsModal = async (item, index) => {
    // Set the selected item's id
    setSelectedId(item._id);
    // If the item has a user, set the user's id
    if (item.user) {
      setUserSelectedId(item.user._id);
    }
    // If the item has a task, set the task's name
    if (item.task) {
      setTaskNameEdit(item.task);
    }
    // If the item has a project, set the project's id and name
    if (item.project) {
      setProjectIdEdit(item.project._id);
      setProjectNameEdit(item.project.project_name);
      // If the project is billable, set the billable status
      if (item.project.is_billable) {
        setBillableEdit(item.project.is_billable);
      }
      // If the item has a sprint, set the sprint's id and name
      if (item.sprint) {
        setSprintIdEdit(item.sprint._id);
        setSprintNameEdit(item.sprint.sprint_name);
      } else {
        // Otherwise, set the sprint's id and name to null
        setSprintIdEdit(null);
        setSprintNameEdit("");
      }
      // If the project has a client, set the client's id and name
      if (item.project.client_name) {
        setClientIdEdit(item.project.client_name._id);
        setClientEdit(item.project.client_name.client_name);
      } else {
        // Otherwise, set the client's id and name to null
        setClientIdEdit(null);
        setClientEdit(null);
      }
    }

    // If the item has a tag, set the tag's id and name
    if (item.tag) {
      setTagIdEdit(item.tag._id);
      setTagEdit(item.tag.tag_name);
    } else {
      // Otherwise, set the tag's id and name to null
      setTagIdEdit(null);
      setTagEdit(null);
    }
    // If the item has a duration, set the formatted time and duration
    if (item.duration) {
      setUFormatedTime(
        `${formatTime(Math.floor(item.duration / 3600))}:${formatTime(
          Math.floor(parseInt((item.duration % 3600) / 60))
        )}:${formatTime(Math.floor(item.duration % 60))}`
      );
      setUDuration(item.duration);
    }

    // If the item has a date, set the date
    if (item.date) {
      setDateEdit(item.date);
    }

    // If the item has a start time, set the start time
    if (item.startTime) {
      setStartTimeEdit(TimestampConverter(item.startTime));
    }

    // If the item has an end time, set the end time
    if (item.endTime) {
      setEndTimeEdit(TimestampConverter(item.endTime));
    }
    // Open the details modal
    setIsDetailsModalOpen(true);
  };

  const handleOpenSingleDataDetailsModal = async (item, index) => {
    setSelectedId(item._id);
    if (item.user) {
      setUserSelectedId(item.user._id);
    }
    if (item.task) {
      setTaskNameEdit(item.task);
    }
    if (item.project) {
      setProjectIdEdit(item.project._id);
      setProjectNameEdit(item.project.project_name);
      if (item.project.is_billable) {
        setBillableEdit(item.project.is_billable);
      }
      if (item.sprint) {
        setSprintIdEdit(item.sprint._id);
        setSprintNameEdit(item.sprint.sprint_name);
      } else {
        setSprintIdEdit(null);
        setSprintNameEdit("");
      }

      if (item.project.client_name) {
        setClientIdEdit(item.project.client_name._id);
        setClientEdit(item.project.client_name.client_name);
      } else {
        setClientIdEdit(null);
        setClientEdit(null);
      }
    }
    if (item.tag) {
      setTagIdEdit(item.tag._id);
      setTagEdit(item.tag.tag_name);
    } else {
      setTagIdEdit(null);
      setTagEdit(null);
    }
    if (item.totalDuration) {
      setUFormatedTime(
        `${formatTime(Math.floor(item.totalDuration / 3600))}:${formatTime(
          Math.floor(parseInt((item.totalDuration % 3600) / 60))
        )}:${formatTime(Math.floor(item.totalDuration % 60))}`
      );
      setUDuration(item.totalDuration);
    }

    if (item.date) {
      setDateEdit(item.date);
    }

    if (item.startTime) {
      setStartTimeEdit(TimestampConverter(item.startTime));
    }

    if (item.endTime) {
      setEndTimeEdit(TimestampConverter(item.endTime));
    }
    setIsDetailsModalOpen(true);
  };

  const handleOpenGroupedDataDetailsModal = async (item, index) => {
    // setSelectedId(item._id);

    setSelectedGroupId(item.entries);

    if (item.user) {
      setUserSelectedId(item.user._id);
    }
    if (item.task) {
      setTaskNameEdit(item.task);
    }
    if (item.project) {
      setProjectIdEdit(item.project._id);
      setProjectNameEdit(item.project.project_name);
      if (item.project.is_billable) {
        setBillableEdit(item.project.is_billable);
      }
      if (item.sprint) {
        setSprintIdEdit(item.sprint._id);
        setSprintNameEdit(item.sprint.sprint_name);
      } else {
        setSprintIdEdit(null);
        setSprintNameEdit("");
      }

      if (item.project.client_name) {
        setClientIdEdit(item.project.client_name._id);
        setClientEdit(item.project.client_name.client_name);
      } else {
        setClientIdEdit(null);
        setClientEdit(null);
      }
    }

    if (item.tag) {
      setTagIdEdit(item.tag._id);
      setTagEdit(item.tag.tag_name);
    } else {
      setTagIdEdit(null);
      setTagEdit(null);
    }
    setIsEditModalOpen(true);
  };

  const handleBulkModal = () => {
    setStartTimeEdit("09:00 am");
    setEndTimeEdit("09:00 am");
    setIsBulkEditModalOpen(true);
  };

  const handleBulkDelete = () => {
    setShowConfirmation(true);
  };

  const handleTagSearchInputChange = (e) => {
    setTagQuery(e.target.value);
  };

  const filteredTagItems = tagOptions.filter((item) =>
    item.tag_name.toLowerCase().includes(tagQuery.toLowerCase())
  );

  // const filteredClientData = clientData.filter(
  //   (client) =>
  //     client.client_name.toLowerCase().includes(filter.toLowerCase()) ||
  //     client.projects.some((project) =>
  //       project.project_name.toLowerCase().includes(filter.toLowerCase())
  //     )
  // ).sort((a, b) => a.client_name.localeCompare(b.client_name));

  const filteredClientData = clientData
    .filter((client) => {
      const clientMatches = client.client_name
        .toLowerCase()
        .includes(filter.toLowerCase());

      const filteredProjects = client.projects.filter(
        (project) =>
          project.project_name
            .toLowerCase()
            .includes(filter.toLowerCase()) &&
          (projectStatusFilter === "All"
            ? true
            : projectStatusFilter === "Active"
              ? project.is_active === true
              : project.is_active === false)
      );

      return filteredProjects.length > 0;
    })
    .sort((a, b) => a.client_name.localeCompare(b.client_name));

  const handleProjectStatusChange = (status) => {
    setProjectStatusFilter(status);
    setSelectedClients(new Set());
  };

  const toggleProjectStatusDropdown = () => {
    setIsProjectStatusDropdownOpen(!isProjectStatusDropdownOpen);
  };

  function formatTime(value) {
    return value < 10 ? `0${value}` : value;
  }

  const timeValidate = (timeString) => {
    const timePattern = /^(1[0-2]|0[1-9]):([0-5][0-9]) [APap][Mm]$/;
    const isValidFormat = timePattern.test(timeString);
    return isValidFormat;
  };

  const covertTimeSatmp = (dateEdit, sT) => {
    const date = new Date(dateEdit);
    const timeString = sT;
    const [hours, minutes] = timeString.split(":");
    if (timeString.includes("PM") || timeString.includes("pm")) {
      date.setHours(parseInt(hours) + 12);
    } else {
      date.setHours(parseInt(hours));
    }
    date.setMinutes(parseInt(minutes));
    // Format the date in the desired format
    return date.toISOString();
  };

  function validateTimeFormat(timeString) {
    const timeRegex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/;
    return timeRegex.test(timeString);
  }

  const timeShowEdit = (timeSec) => {
    if (validateTimeFormat(timeSec)) {
      const timeString = timeSec;
      const [hours, minutes, seconds] = timeString.split(":").map(Number);
      const totalSeconds =
        parseInt(hours) * 3600 + parseInt(minutes) * 60 + parseInt(seconds);
      const date = dateEdit;
      const formattedDates = covertTimeSatmp(
        date,
        startTimeEdit && startTimeEdit.trim()
      );
      const timestampInSeconds = Math.floor(
        new Date(formattedDates).getTime() / 1000
      );
      setUDuration(parseInt(totalSeconds));
      const upcomingTimeInSeconds =
        parseInt(timestampInSeconds) + parseInt(totalSeconds);
      const upcomingTime = new Date(parseInt(upcomingTimeInSeconds) * 1000);
      const formattedTime =
        upcomingTime &&
        upcomingTime.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        });
      setUFormatedTimeError(false);
      setEndTimeEdit(formattedTime);
      setUFormatedTimeError(false);
      setUstartTimeError(false);
      setUEndTimeError(false);
    } else {
      setUFormatedTimeError(true);
    }
    setUFormatedTime(timeSec);
  };

  const changeStartTime = (startTime) => {
    if (timeValidate(startTime)) {
      const timeString1 = startTime.trim();
      const timeString2 = endTimeEdit && endTimeEdit.trim();
      const format = "h:mm A";
      const time1 = moment(timeString1, format).tz(global.config.getTimeZone());
      const time2 = moment(timeString2, format).tz(global.config.getTimeZone());
      const timeDifferenceInMinutes = time2.diff(time1, "minutes");
      const timeDifferenceInSeconds = time2.diff(time1, "seconds");

      if (timeDifferenceInSeconds > 0) {
        setUDuration(parseInt(timeDifferenceInSeconds));
        setUFormatedTime(
          `${formatTime(
            Math.floor(parseInt(timeDifferenceInSeconds) / 3600)
          )}:${formatTime(
            Math.floor(parseInt((timeDifferenceInSeconds % 3600) / 60))
          )}:${formatTime(Math.floor(parseInt(timeDifferenceInSeconds) % 60))}`
        );
        setUstartTimeError(false);
        setUEndTimeError(false);
        setUFormatedTimeError(false);
      } else {
        setUstartTimeError(true);
      }
    } else {
      setUstartTimeError(true);
    }
    setStartTimeEdit(startTime);
  };

  const changeEndTime = (endTime) => {
    if (timeValidate(endTime)) {
      const timeString1 = startTimeEdit.trim();
      const timeString2 = endTime.trim();
      const format = "h:mm A";
      const time1 = moment(timeString1, format).tz(global.config.getTimeZone());
      const time2 = moment(timeString2, format).tz(global.config.getTimeZone());
      const timeDifferenceInMinutes = time2.diff(time1, "minutes");
      const timeDifferenceInSeconds = time2.diff(time1, "seconds");

      if (timeDifferenceInSeconds > 0) {
        setUDuration(parseInt(timeDifferenceInSeconds));
        setUFormatedTime(
          `${formatTime(
            Math.floor(parseInt(timeDifferenceInSeconds) / 3600)
          )}:${formatTime(
            Math.floor(parseInt((timeDifferenceInSeconds % 3600) / 60))
          )}:${formatTime(Math.floor(parseInt(timeDifferenceInSeconds) % 60))}`
        );
        setUstartTimeError(false);
        setUEndTimeError(false);
        setUFormatedTimeError(false);
      } else {
        setUEndTimeError(true);
      }
    } else {
      setUEndTimeError(true);
    }
    setEndTimeEdit(endTime);

  };

  const checkboxTemplate = (rowData, rowIndex, currentData) => {
    return (
      <input
        type="checkbox"
        onChange={(e) => handleCheckboxChange(e, rowData, rowIndex, currentData)}
        checked={isSelected(rowData)}
      />
    );
  };
  const isSelected = (rowData) => {
    return selectedRows.some((item) => isEqual(item, rowData));
  };

  const handleCheckboxChange = (e, rowData, rowIndex, currentData) => {
    const selected = [...selectedRows];
    const index = selected.findIndex((item) => isEqual(item, rowData));

    if (e.nativeEvent.shiftKey && lastSelectedRowIndex !== null) {
      const startIdx = Math.min(lastSelectedRowIndex, rowIndex);
      const endIdx = Math.max(lastSelectedRowIndex, rowIndex);

      for (let i = startIdx; i <= endIdx; i++) {
        const currentRowData = groupedData[i];
        const currentIndex = selected.findIndex((item) =>
          isEqual(item, currentRowData)
        );

        if (e.target.checked) {
          // Add to selection if not already selected
          if (currentIndex === -1) {
            selected.push(currentRowData);
          }
        } else {
          // Remove from selection if already selected
          if (currentIndex !== -1) {
            selected.splice(currentIndex, 1);
          }
        }
        setLastSelectedRowIndex(rowIndex);
      }
    } else {
      if (e.target.checked) {
        if (selected.length >= 200) {
          toast.error("Please select only a maximum of 200 Time tracker entries!", {
            position: "bottom-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          return;
        }
        selected.push(rowData);
      } else {
        if (index !== -1) {
          selected.splice(index, 1);
        }
      }
      setLastSelectedRowIndex(rowIndex);
    }

    // Check if all checkboxes on the current page are selected
    const sortedTimerData = [...props.timerData].sort((a, b) => {
      if (a?.project?._id !== b?.project?._id) {
        return a.project._id.localeCompare(b.project._id);
      }
      return 0;
    });

    const updatedGroupedData = groupAndSumDurations(sortedTimerData).sort((a, b) => {
      return new Date(b.date) - new Date(a.date);
    });

    const indexOfLastItem = currentData * perPage;
    const indexOfFirstItem = indexOfLastItem - perPage;
    const rowsOnPage = updatedGroupedData.slice(indexOfFirstItem, indexOfLastItem);
    const allSelectedOnPage = rowsOnPage.every((row) => selected.includes(row));

    // Update selectAll based on whether all checkboxes on the page are selected
    setSelectAll(allSelectedOnPage);
    setSelectedRows(selected);
    if (selected.length === currentData.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  };

  const handleSelectAllChange = () => {
    const sortedTimerData = [...props.timerData].sort((a, b) => {
      if (a?.project?._id !== b?.project?._id) {
        return a.project._id.localeCompare(b.project._id);
      }
      return 0;
    });

    const updatedGroupedData = groupAndSumDurations(sortedTimerData).sort((a, b) => {
      return new Date(b.date) - new Date(a.date);
    });

    const indexOfLastItem = currentPage * perPage;
    const indexOfFirstItem = indexOfLastItem - perPage;
    const rowsOnPage = updatedGroupedData.slice(indexOfFirstItem, indexOfLastItem);

    if (!selectAll && selectedRows.length + rowsOnPage.length > 200) {
      toast.error("Please select only a maximum of 200 Time tracker entries!", {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    if (!selectAll) {
      const updatedSelection = [...selectedRows];

      rowsOnPage.forEach((row) => {
        if (!updatedSelection.some((selectedRow) => selectedRow._id === row._id)) {
          updatedSelection.push(row);
        }
      });

      setSelectedRows(updatedSelection);
      setSelectAll(true);
    } else {
      const remainingSelection = selectedRows.filter(
        (selectedRow) => !rowsOnPage.some((row) => row._id === selectedRow._id)
      );

      setSelectedRows(remainingSelection);
      setSelectAll(false);
    }
  };

  const updateTimer = async (event) => {
    event.preventDefault();
    if (!taskNameEdit || taskNameEdit.trim() === "") {
      toast.error("Task cannot be empty", {
        position: "bottom-right",
        autoClose: 3500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    if (!projectIdEdit || projectIdEdit.trim() === "") {
      toast.error("Project cannot be empty", {
        position: "bottom-right",
        autoClose: 3500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    if (!dateEdit) {
      toast.error("Date cannot be empty", {
        position: "bottom-right",
        autoClose: 3500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    if (!startTimeEdit || startTimeEdit.trim() === "") {
      toast.error("Start time cannot be empty", {
        position: "bottom-right",
        autoClose: 3500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    if (!endTimeEdit || endTimeEdit.trim() === "") {
      toast.error("End time cannot be empty", {
        position: "bottom-right",
        autoClose: 3500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    if (!clientEdit || clientEdit.trim() === "" || !clientIdEdit || clientIdEdit.trim() === "") {
      toast.error("Client cannot be empty", {
        position: "bottom-right",
        autoClose: 3500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    if (uDuration === 0 || uDuration === null) {
      toast.error("Starting time and ending time shouldn't be the same.", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    const id = selectedId;

    const date = new Date(dateEdit);
    const startTimeFormated = covertTimeSatmp(
      date,
      startTimeEdit && startTimeEdit.trim()
    );
    const endTimeFormated = covertTimeSatmp(
      date,
      endTimeEdit && endTimeEdit.trim()
    );
    try {
      setIsUpdateLoading(true);
      const task = taskNameEdit;
      const client = clientEdit;
      const clientId = clientIdEdit;
      const postData = {
        task: task,
        project: projectIdEdit,
        date: new Date(dateEdit),
        oldDate: dateOldEdit,
        tag: tagIdEdit,
        billable: billableEdit,
        startTime: startTimeFormated,
        endTime: endTimeFormated,
        duration: uDuration,
        client: client,
        clientId: clientId,
        user: userSelectedId,
        id: selectedId && selectedId,
      };

      if (sprintIdEdit) {
        postData.sprint = sprintIdEdit;
      } else {
        postData.sprint = null;
      }

      const response = await axios.post(
        "/api/time-track/custome-time-update",
        postData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // global.config.activityLog(
      //   window.location.href,
      //   "Timer",
      //   `Time updated successfully`
      // );

      toast("Time updated successfully", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      setIsUpdateLoading(false);
      queryClient.refetchQueries(["timerData", tracker]);
      handleCloseDetailsModal();
    } catch (error) {
      console.error("Error occurred while updating time:", error);
      global.config.slackMessage(error.toString());
      toast.error("Error occurred while updating time.", {
        position: "bottom-right",
        autoClose: 3500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setIsUpdateLoading(false);
    }
  };

  const updateTimer2 = async (event) => {
    event.preventDefault();
    if (!taskNameEdit || taskNameEdit.trim() === "") {
      toast.error("Task cannot be empty", {
        position: "bottom-right",
        autoClose: 3500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    if (!projectIdEdit || projectIdEdit.trim() === "") {
      toast.error("Project cannot be empty", {
        position: "bottom-right",
        autoClose: 3500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    if (!clientEdit || clientEdit.trim() === "" || !clientIdEdit || clientIdEdit.trim() === "") {
      toast.error("Client cannot be empty", {
        position: "bottom-right",
        autoClose: 3500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    const entriesIds = [];
    // Iterate over the main array
    selectedGroupId.map((entry) => {
      entriesIds.push(entry._id);
    });
    try {
      setIsUpdateLoading(true);

      await Promise.all(
        entriesIds.map(async (item) => {
          const selectedGrpId = item;
          const task = taskNameEdit;
          const client = clientEdit;
          const clientId = clientIdEdit
          const postData = {
            task: task,
            project: projectIdEdit,
            tag: tagIdEdit,
            billable: billableEdit,
            client: client,
            clientId: clientId,
            user: userSelectedId,
            id: selectedGrpId,
          };

          if (sprintIdEdit) {
            postData.sprint = sprintIdEdit;
          } else {
            postData.sprint = null;
          }

          const response = await axios.post(
            "/api/time-track/custome-time-group-update",
            postData,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          // global.config.activityLog(
          //   window.location.href,
          //   "Timer",
          //   `Time updated successfully`
          // );
        })
      );

      toast("Time updated successfully", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      setIsUpdateLoading(false);
      queryClient.refetchQueries(["timerData", tracker]);
      handleCloseDetailsModal();
    } catch (error) {
      console.error("Error occurred while updating time:", error);
      global.config.slackMessage(error.toString());
      toast.error("Error occurred while updating time.", {
        position: "bottom-right",
        autoClose: 3500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setIsUpdateLoading(false);
    }
  };

  const updateTimerGroup = (event) => {
    event.preventDefault();


    if (userCheck && (!userSelectedId || userSelectedId.trim() === "")) {
      toast.error("User cannot be empty", {
        position: "bottom-right",
        autoClose: 3500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    if (taskCheck && (!taskNameEdit || taskNameEdit.trim() === "")) {
      toast.error("Task cannot be empty", {
        position: "bottom-right",
        autoClose: 3500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    if (projectCheck && (!projectIdEdit || projectIdEdit.trim() === "")) {
      toast.error("Project cannot be empty", {
        position: "bottom-right",
        autoClose: 3500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    if (dateCheck && !dateEdit) {
      toast.error("Date cannot be empty", {
        position: "bottom-right",
        autoClose: 3500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    if (timeCheck && (!startTimeEdit || startTimeEdit.trim() === "")) {
      toast.error("Start time cannot be empty", {
        position: "bottom-right",
        autoClose: 3500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    if (timeCheck && (!endTimeEdit || endTimeEdit.trim() === "")) {
      toast.error("End time cannot be empty", {
        position: "bottom-right",
        autoClose: 3500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    if (projectCheck && (!clientEdit || clientEdit.trim() === "" || !clientIdEdit || clientIdEdit.trim() === "")) {
      toast.error("Client cannot be empty", {
        position: "bottom-right",
        autoClose: 3500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    if (durationCheck && (uDuration === 0 || uDuration === null)) {
      toast.error("Starting time and ending time shouldn't be the same.", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    const entriesIds = [];
    // Iterate over the main array
    selectedRows.map((item) => {
      // Iterate over the entries array for each item
      item.entries.map((entry) => {
        // Push the _id value to the entriesIds array
        entriesIds.push(entry._id);
      });
    });

    // if (uFormatedTime !== "00:00:00") {

    entriesIds.map(async (item) => {
      const selectedId = item;
      const task = taskNameEdit;
      const date = new Date(dateEdit);
      const startTimeFormated = covertTimeSatmp(
        date,
        startTimeEdit && startTimeEdit.trim()
      );
      const endTimeFormated = covertTimeSatmp(
        date,
        endTimeEdit && endTimeEdit.trim()
      );
      const client = clientEdit;
      const clientId = clientIdEdit;


      const postData = {
        task: task,
        project: projectIdEdit,
        date: new Date(dateEdit),
        oldDate: dateOldEdit,
        tag: tagIdEdit,
        billable: billableEdit,
        startTime: startTimeFormated,
        endTime: endTimeFormated,
        duration: uDuration,
        client: client,
        clientId: clientId,
        user: userSelectedId,
        id: selectedId && selectedId,
      };

      if (!taskCheck) {
        postData.task = null;
      }

      if (!projectCheck) {
        postData.project = null;
        postData.client = null;
        postData.clientId = null;
      }

      if (!dateCheck) {
        postData.date = null;

      }

      if (!tagCheck) {
        postData.tag = null;
      }

      if (!durationCheck && !timeCheck) {
        postData.startTime = null;
        postData.endTime = null;

      }

      if (!durationCheck && !timeCheck) {
        postData.duration = null;
      }

      if (!userCheck) {
        postData.user = null;
      }


      if (sprintIdEdit) {
        postData.sprint = sprintIdEdit;
      } else {
        postData.sprint = null;
      }

      // console.log('oooi', uFormatedTime);
      // console.log('postData', postData);
      setIsUpdateLoading(true);
      await axios
        .post("/api/time-track/custome-time-update", postData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          // global.config.activityLog(
          //   window.location.href,
          //   "Timer",
          //   `Time updated successfully`
          // );
          toast("Time updated successfully", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setIsUpdateLoading(false);
          queryClient.refetchQueries(["timerData", tracker]);
          handleCloseDetailsModal();
        });
    });
    // }
  };

  const deleteTimerGroup = async (event) => {
    event.preventDefault();
    setIsDeleting(true);
    const entriesIds = [];

    selectedRows.map((item) => {
      // Iterate over the entries array for each item
      item.entries.map((entry) => {
        // Push the _id value to the entriesIds array
        entriesIds.push(entry._id);
      });
    });

    const deletePromises = entriesIds.map((item) => {
      const postData = {
        id: item,
      };

      return axios.post("/api/time-track/bulk-delete", postData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    });

    try {
      await Promise.all(deletePromises);
      // setShowConfirmation(false);
      dispatch(setTimerData(new Date()));
      setDeleteDone(true);
      setSelectAll(false);
      setSelectedRows([]);
      setIsDeleting(false);
      queryClient.refetchQueries(["timerData", tracker]);
      // Display toast message on successful deletion
      setShowConfirmation(false);
      toast.success("Selected tracked time deleted successfully", {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.error("Error deleting tracked time:", error);
      global.config.slackMessage(error.toString());
      setIsDeleting(false);
      toast.error("Error deleting tracked time.", {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handleCloseDetailsModal = () => {
    dispatch(setNotification(Date.now()));
    setProjectEditDropdownOpen(false);
    setTagEditDropdownOpen(false);
    setSelectedId("");
    setTaskNameEdit("");
    setUserSelectedId("");
    setProjectIdEdit(null);
    setProjectNameEdit("");
    setSprintIdEdit(null);
    setSprintNameEdit("");
    setBillableEdit(null);
    setClientIdEdit(null);
    setClientEdit(null);
    setTagIdEdit(null);
    setTagEdit(null);
    setUFormatedTime(0);
    setUDuration(null);
    setDateEdit(new Date());
    setSelectedRows([]);
    setStartTimeEdit(TimestampConverter(null));
    setEndTimeEdit(TimestampConverter(null));
    setUserCheck(false);
    setTaskCheck(false);
    setProjectCheck(false);
    setClientCheck(false);
    setTagCheck(false);
    setDateCheck(false);
    setTimeCheck(false);
    setDurationCheck(false);
    setIsDetailsModalOpen(false);
    setIsEditModalOpen(false);
    setIsBulkEditModalOpen(false);
    setSelectAll(false);
  };

  const updateVisiblePages = (pageNumber) => {
    const totalPages = Math.ceil(groupedData.length / perPage);
    const maxVisiblePages = 5; // Adjust as needed

    if (totalPages <= maxVisiblePages) {
      // If total pages are less than or equal to max visible pages, show all pages
      const pages = Array.from({ length: totalPages }, (_, i) => i + 1);
      setVisiblePages(pages);
    } else if (pageNumber <= Math.ceil(maxVisiblePages / 2)) {
      // If the current page is within the first half, show the first maxVisiblePages pages
      const pages = Array.from({ length: maxVisiblePages }, (_, i) => i + 1);
      setVisiblePages([...pages, "...", totalPages]);
    } else if (pageNumber >= totalPages - Math.floor(maxVisiblePages / 2)) {
      // If the current page is within the last half, show the last maxVisiblePages pages
      const pages = Array.from(
        { length: maxVisiblePages },
        (_, i) => totalPages - maxVisiblePages + i + 1
      );
      setVisiblePages([1, "...", ...pages]);
    } else {
      // If the current page is somewhere in the middle, show pages around the current page
      const pages = Array.from(
        { length: maxVisiblePages - 2 },
        (_, i) => pageNumber - Math.floor(maxVisiblePages / 2) + i + 1
      );
      setVisiblePages([1, "...", ...pages, "...", totalPages]);
    }
  };

  const paginate = (pageNumber) => {
    const totalPages = Math.ceil(groupedData.length / perPage);
    if (pageNumber < 1 || pageNumber > totalPages) {
      return; // Return early if the clicked page is out of range
    }

    if (typeof pageNumber === "number") {
      setCurrentPage(pageNumber);
    } else if (pageNumber === "...") {
      const currentPageIndex = visiblePages.indexOf(currentPage);
      if (currentPageIndex === 1 && visiblePages[0] !== 1) {
        // If the ellipsis near the "back" button is clicked, show the previous maxVisiblePages - 2 page numbers
        const prevPages = Array.from(
          { length: visiblePages.length - 2 },
          (_, i) => currentPage - i - 1
        ).reverse();
        setVisiblePages([1, "...", ...prevPages, "...", totalPages]);
      } else if (
        currentPageIndex === visiblePages.length - 2 &&
        visiblePages[visiblePages.length - 1] !== totalPages
      ) {
        // If the ellipsis near the "next" button is clicked, show the next maxVisiblePages - 2 page numbers
        const nextPages = Array.from(
          { length: visiblePages.length - 2 },
          (_, i) => currentPage + i + 1
        );
        setVisiblePages([1, "...", ...nextPages, "...", totalPages]);
      }
    }
  };

  const handlePageChange = (pageNumber) => {
    paginate(pageNumber);
    setExpandedGroups([]);

    setTimeout(() => {
      const sortedTimerData = [...props.timerData].sort((a, b) => {
        if (a?.project?._id !== b?.project?._id) {
          return a.project._id.localeCompare(b.project._id);
        }
        return 0;
      });

      const updatedGroupedData = groupAndSumDurations(sortedTimerData).sort((a, b) => {
        return new Date(b.date) - new Date(a.date);
      });

      const indexOfLastItem = pageNumber * perPage;
      const indexOfFirstItem = indexOfLastItem - perPage;
      const rowsOnPage = updatedGroupedData.slice(indexOfFirstItem, indexOfLastItem);

      const rowsOnSelectedRows = rowsOnPage.filter((row) =>
        selectedRows.some((selectedRow) => selectedRow._id === row._id)
      );

      const allSelected =
        rowsOnPage.length > 0 &&
        rowsOnPage.length === rowsOnSelectedRows.length;

      setSelectAll(allSelected);
    }, 0);
  };

  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage === Math.ceil(groupedData.length / perPage);
  const changeDurationFormat = (sec) => {
    let second = sec;
    const hours = Math.floor(second / 3600);
    const minutes = Math.floor((second % 3600) / 60);
    const seconds = second % 60;
    const formattedDuration = `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
    return formattedDuration;
  };

  // useEffect(() => {

  //   setSelectAll(false);
  //   setSelectedRows([]);

  // }, [props.timerData]);

  useEffect(() => {
    const resetSelection = () => {
      setSelectAll(false);
      setSelectedRows([]);
    };

    // Use `setTimeout` for non-blocking execution if necessary
    const timer = setTimeout(() => {
      resetSelection();
    }, 0);

    // Cleanup timer on component unmount
    return () => clearTimeout(timer);
  }, [props.timerData]);

  return (
    <div className="outer-time-table">
      {(userType === userTypeConsts.SUPER_ADMIN ||
        userType === userTypeConsts.ADMIN ||
        userType === userTypeConsts.PROJECT_MANAGER ||
        userType === userTypeConsts.TEAM_MANAGER) && (
          <div className="modal-invite-main-outter">
            <Modal
              className="client-modal project-modal tracker-overview-modal"
              isOpen={isDetailsModalOpen}
              onRequestClose={handleCloseDetailsModal}
              contentLabel="New Project"
              style={{
                content: {
                  height: "41%",
                  width: "30%",
                  position: "fixed",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  parent: document.querySelector(".admin-outer.time.tracker"),
                },
              }}
            >
              <div className="modal">
                <div className="modal-close-invite outter-modal-main">
                  <a className="" onClick={handleCloseDetailsModal}>
                    <img src={crssrImage} alt="Edit Icon" />{" "}
                  </a>
                </div>
                <div className="project-detail-modal">
                  <div className="modal-client-head ">
                    <h2 className="">Time Tracker</h2>
                  </div>
                  <div className="form-field-client-outer">
                    <form onSubmit={updateTimer}>
                      <div className="form-field-client">
                        <label htmlFor="projectName" className="form-label">
                          User
                        </label>
                        <div
                          ref={userDropdownRef}
                          className={`cursor ${userDropdownOpen ? "open" : ""}`}
                        >
                          <span
                            id="user-select"
                            onClick={handleToggleDropdown}
                            disabled={userType === userTypeConsts.TEAM_MANAGER}
                            className="custom-dropdown-user"
                          >
                            {userSelectedId ? (
                              userId.find((user) => user._id === userSelectedId)
                                .first_name ? (
                                <>
                                  {
                                    userId.find(
                                      (user) => user._id === userSelectedId
                                    ).first_name
                                  }{" "}
                                  {userId.find(
                                    (user) => user._id === userSelectedId
                                  ).middle_name &&
                                    userId.find(
                                      (user) => user._id === userSelectedId
                                    ).middle_name !== "null"
                                    ? userId.find(
                                      (user) => user._id === userSelectedId
                                    ).middle_name + " "
                                    : ""}
                                  {
                                    userId.find(
                                      (user) => user._id === userSelectedId
                                    ).last_name
                                  }{" "}
                                </>
                              ) : (
                                <>
                                  {userId.find(
                                    (user) => user._id === userSelectedId
                                  ).company_first_name
                                    ? `${userId.find(
                                      (user) => user._id === userSelectedId
                                    ).company_first_name
                                    } ${userId.find(
                                      (user) => user._id === userSelectedId
                                    ).company_last_name
                                      ? userId.find(
                                        (user) =>
                                          user._id === userSelectedId
                                      ).company_last_name
                                      : ""
                                    }`
                                    : `${userId.find(
                                      (user) => user._id === userSelectedId
                                    ).company_name || ""
                                    }(Company)`}
                                </>
                              )
                            ) : (
                              "Select User"
                            )}
                          </span>
                          {userType !== userTypeConsts.TEAM_MANAGER &&
                            userDropdownOpen && (
                              <div className="custom-dropdown">
                                <input
                                  type="text"
                                  className="search-bar"
                                  placeholder="Search..."
                                  value={searchTerm}
                                  onChange={handleSearchInputChange}
                                  disabled={
                                    userType === userTypeConsts.TEAM_MANAGER
                                  }
                                />
                                <ul className="client-list">
                                  {filteredUsers.length > 0 &&
                                    filteredUsers
                                      .slice()
                                      .sort((a, b) => {
                                        const nameA = a.first_name
                                          ? `${a.first_name} ${a.middle_name || ""
                                          } ${a.last_name}`
                                          : `${a.company_first_name
                                            ? a.company_first_name +
                                            (a.company_last_name &&
                                              a.company_last_name)
                                            : a.company_name
                                          }`;
                                        const nameB = b.first_name
                                          ? `${b.first_name} ${b.middle_name || ""
                                          } ${b.last_name}`
                                          : `${b.company_first_name
                                            ? b.company_first_name +
                                            (b.company_last_name &&
                                              b.company_last_name)
                                            : b.company_name
                                          }`;
                                        return nameA.localeCompare(nameB);
                                      })
                                      .map((item) => (
                                        <li
                                          key={item._id}
                                          onClick={() =>
                                            handleSelectUser(item._id)
                                          }
                                          className={
                                            userSelectedId === item._id
                                              ? "selected-item"
                                              : ""
                                          }
                                        >
                                          {item.first_name ? (
                                            <>
                                              {item.first_name}{" "}
                                              {item.middle_name &&
                                                item.middle_name !== "null"
                                                ? item.middle_name + " "
                                                : ""}
                                              {item.last_name}{" "}
                                            </>
                                          ) : (
                                            <>
                                              {item.company_first_name
                                                ? `${item.company_first_name} ${item.company_last_name
                                                  ? item.company_last_name
                                                  : ""
                                                }`
                                                : `${item.company_name || ""
                                                }(Company)`}
                                            </>
                                          )}
                                        </li>
                                      ))}
                                </ul>
                              </div>
                            )}
                        </div>
                      </div>
                      <div className="form-field-client">
                        <label htmlFor="projectName" className="form-label">
                          Task Name
                        </label>
                        <input
                          type="text"
                          id="taskName"
                          name="taskName"
                          style={{
                            maxWidth: "100%",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                          }}
                          value={taskNameEdit}
                          onChange={(e) => setTaskNameEdit(e.target.value)}
                          className="form-input"
                          disabled={userType === userTypeConsts.TEAM_MANAGER}
                        />
                      </div>
                      <div ref={modalContentRef}></div>
                      <div className="form-field-client tracker-detail-dropdown tracker-detail-dropdown-pro">
                        <label htmlFor="client" className="form-label">
                          Project Name
                        </label>

                        <div ref={projectDropdownRef}>
                          <div
                            className="cursor"
                            onClick={() => {
                              if (userType !== userTypeConsts.TEAM_MANAGER) {
                                setProjectEditDropdownOpen(
                                  !projectEditDropdownOpen
                                );
                                setFilter("");
                                setSelectedClient(null);
                                setSelectedProject(null);
                                setTagEditDropdownOpen(false);
                                setSelectedClients(new Set());
                              }
                            }}
                          >
                            <span>
                              {projectNameEdit ? projectNameEdit : ""}
                              {`${sprintNameEdit
                                ? ` - ${sprintNameEdit}`
                                : sprintNameEdit
                                }`}
                            </span>
                          </div>
                          {userType !== userTypeConsts.TEAM_MANAGER &&
                            projectEditDropdownOpen && (
                              <div className="custom-dropdown">
                                <input
                                  type="text"
                                  className="search-bar"
                                  placeholder="Find Project or Client"
                                  value={filter}
                                  onChange={(e) => {
                                    const filterValue = e.target.value;
                                    setFilter(filterValue);

                                    if (filterValue === "") {
                                      setSelectedClients(new Set());
                                    } else {
                                      // Check if the filtered project exists under any client
                                      const matchingClients =
                                        filteredClientData.filter((client) =>
                                          client.projects.some((project) =>
                                            project.project_name
                                              .toLowerCase()
                                              .includes(filterValue.toLowerCase()) &&
                                            (projectStatusFilter === "All"
                                              ? true
                                              : projectStatusFilter === "Active"
                                                ? project.is_active === true
                                                : project.is_active === false)
                                          )
                                        );

                                      setSelectedClients(new Set(matchingClients));
                                    }
                                  }}
                                  disabled={
                                    userType === userTypeConsts.TEAM_MANAGER
                                  }
                                />
                                <ul className="client-list client-list-filter">
                                  {(userType === userTypeConsts.SUPER_ADMIN || userType === userTypeConsts.ADMIN) && (
                                    <>
                                      <div className="user-status-dropdown">
                                        <div className="user-status-dropdown-label" htmlFor="projectStatusFilter">Filter by Status</div>
                                        <div className="custom-dropdown-user" ref={projectStatusDropdownRef}>
                                          <div className="showing-name" onClick={toggleProjectStatusDropdown}>
                                            {projectStatusFilter ? projectStatusFilter : "Active"}
                                          </div>
                                        </div>
                                      </div>
                                      {isProjectStatusDropdownOpen && (
                                        <ul className="status-list">
                                          <li
                                            onClick={() => handleProjectStatusChange("All")}
                                            className={projectStatusFilter === "All" ? "selected-item" : ""}
                                          >
                                            All
                                          </li>
                                          <li
                                            onClick={() => handleProjectStatusChange("Active")}
                                            className={projectStatusFilter === "Active" ? "selected-item" : ""}
                                          >
                                            Active
                                          </li>
                                          <li
                                            onClick={() => handleProjectStatusChange("Inactive")}
                                            className={projectStatusFilter === "Inactive" ? "selected-item" : ""}
                                          >
                                            Inactive
                                          </li>
                                        </ul>
                                      )}
                                    </>
                                  )}
                                  {filteredClientData.map((client) => (
                                    <div
                                      key={client._id}
                                      className="main-outer-item-dd"
                                    >
                                      {/* Render the client name */}
                                      <div
                                        className={`client-name1 ${selectedClients.has(client) ? "selected" : ""}`}
                                        onClick={() => {
                                          if (client.projects.filter(
                                            (project) =>
                                              project.project_name
                                                .toLowerCase()
                                                .includes(filter.toLowerCase()) &&
                                              (projectStatusFilter === "All"
                                                ? true
                                                : projectStatusFilter === "Active"
                                                  ? project.is_active === true
                                                  : project.is_active === false)
                                          ).length > 0) {
                                            const newSelectedClients = new Set(selectedClients);
                                            if (selectedClients.has(client)) {
                                              newSelectedClients.delete(client);
                                            } else {
                                              newSelectedClients.add(client);
                                            }
                                            setSelectedClients(
                                              newSelectedClients
                                            );
                                          }
                                        }}
                                      >
                                        <span className="main-project">
                                          {client.client_name}{" "}
                                        </span>
                                        {client.projects.filter(
                                          (project) =>
                                            project.project_name
                                              .toLowerCase()
                                              .includes(filter.toLowerCase()) &&
                                            (projectStatusFilter === "All"
                                              ? true
                                              : projectStatusFilter === "Active"
                                                ? project.is_active === true
                                                : project.is_active === false)
                                        ).length > 0 && (
                                            <span
                                              className={`arrow ${selectedClients.has(client)
                                                ? "open"
                                                : ""
                                                }`}
                                            >
                                              <span className="main-project-title">
                                                {client.projects.filter(
                                                  (project) =>
                                                    project.project_name
                                                      .toLowerCase()
                                                      .includes(filter.toLowerCase()) &&
                                                    (projectStatusFilter === "All"
                                                      ? true
                                                      : projectStatusFilter === "Active"
                                                        ? project.is_active === true
                                                        : project.is_active === false)
                                                ).length} Projects
                                              </span>
                                              {selectedClients.has(client) ? (
                                                <img src={TopArrow} alt="Open" />
                                              ) : (
                                                <img src={DownArrow} alt="Close" />
                                              )}
                                            </span>
                                          )}
                                      </div>
                                      {selectedClients.has(client) && (
                                        <div className="project-name1-outer">
                                          {/* Render projects under the selected client */}
                                          {client.projects.filter(
                                            (project) =>
                                              project.project_name
                                                .toLowerCase()
                                                .includes(filter.toLowerCase()) &&
                                              (projectStatusFilter === "All"
                                                ? true
                                                : projectStatusFilter === "Active"
                                                  ? project.is_active === true
                                                  : project.is_active === false)
                                          ).length === 0 ? (
                                            <div>
                                              No projects under this client
                                            </div>
                                          ) : (
                                            client.projects
                                              .filter((project) =>
                                                project.project_name.toLowerCase().includes(filter.toLowerCase()) &&
                                                (projectStatusFilter === "All"
                                                  ? true
                                                  : projectStatusFilter === "Active"
                                                    ? project.is_active === true
                                                    : project.is_active === false)
                                              )
                                              .sort((a, b) =>
                                                a.project_name.localeCompare(b.project_name)
                                              )
                                              .map((project) => (
                                                <div key={project._id}>
                                                  <div className={`project-name1 ${selectedProject === project ? "selected" : ""}`} >
                                                    <div
                                                      onClick={(e) => {
                                                        setSprintIdEdit("");
                                                        setSprintNameEdit("");
                                                        setProjectIdEdit(
                                                          project._id
                                                        );
                                                        setProjectNameEdit(
                                                          project.project_name
                                                        );
                                                        setClientEdit(
                                                          client.client_name
                                                        );
                                                        setClientIdEdit(
                                                          client._id
                                                        );
                                                        setBillableEdit(
                                                          project.is_billable
                                                        );
                                                        setProjectEditDropdownOpen(
                                                          false
                                                        );
                                                      }}
                                                    >
                                                      <span className="project-dd-title">
                                                        {project.project_name}{" "}
                                                      </span>
                                                    </div>
                                                    <div>
                                                      {project.sprints.length >
                                                        0 && (
                                                          <span
                                                            className={`arrow ${selectedProject ===
                                                              project
                                                              ? "open"
                                                              : ""
                                                              }`}
                                                            onClick={() =>
                                                              setSelectedProject(selectedProject === project ? null : project)
                                                            }
                                                          >
                                                            <span className="main-project-title">
                                                              {project.sprints.length}{" "} Sprints
                                                            </span>
                                                            {selectedProject === project ? (
                                                              <img src={TopArrow} alt="Open" />
                                                            ) : (
                                                              <img src={DownArrow} alt="Close" />
                                                            )}
                                                          </span>
                                                        )}
                                                    </div>
                                                  </div>
                                                  {selectedProject ===
                                                    project && (
                                                      <div>
                                                        {/* Render sprints under the selected project */}
                                                        {project.sprints.length ===
                                                          0 ? (
                                                          <div>
                                                            No sprints under this project
                                                          </div>
                                                        ) : (
                                                          <ul className="sprint-list">
                                                            {project.sprints
                                                              .sort((a, b) =>
                                                                a.sprint_name.localeCompare(b.sprint_name)
                                                              )
                                                              .map((sprint) => (
                                                                <li
                                                                  key={sprint._id}
                                                                  onClick={(e) => {
                                                                    setSprintIdEdit(sprint._id);
                                                                    setSprintNameEdit(sprint.sprint_name);
                                                                    setProjectIdEdit(project._id);
                                                                    setProjectNameEdit(project.project_name);
                                                                    setClientEdit(client.client_name);
                                                                    setClientIdEdit(client._id);
                                                                    setBillableEdit(project.is_billable);
                                                                    setProjectEditDropdownOpen(false);
                                                                  }}
                                                                >
                                                                  {sprint.sprint_name}
                                                                </li>
                                                              ))}
                                                          </ul>
                                                        )}
                                                      </div>
                                                    )}
                                                </div>
                                              ))
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  ))}
                                </ul>
                              </div>
                            )}
                        </div>
                      </div>
                      <div className="project-name-tracker-non-editable-outer">
                        <label htmlFor="client" className="form-label">
                          Client
                        </label>
                        <div class="project-name-tracker-non-editable form-input readonly">
                          {clientEdit}
                        </div>
                      </div>

                      <div className="form-field-client tracker-detail-dropdown">
                        <label htmlFor="description" className="form-label">
                          Tag
                        </label>
                        <div ref={tagDropdownRef}>
                          <div className="tag-section">
                            <p
                              className="cursor top-cursor"
                              onClick={() => {
                                if (userType !== userTypeConsts.TEAM_MANAGER) {
                                  setTagEditDropdownOpen(!tagEditDropdownOpen);
                                  setProjectEditDropdownOpen(false);
                                  setTagQuery("");
                                }
                              }}
                            >
                              {" "}
                              {tagEdit ? (
                                tagEdit
                              ) : (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path
                                    d="M13.9119 23C13.1542 23 12.4419 22.7048 11.9066 22.1695L1.83081 12.0945C1.17489 11.4386 0.881077 10.5022 1.04426 9.5892L1.99398 4.28519C2.20208 3.12282 3.12293 2.202 4.28535 1.99391L9.58953 1.04422C10.5067 0.881044 11.4395 1.1753 12.0949 1.83074L22.1712 11.9052C23.2763 13.0108 23.2763 14.8102 22.1712 15.9158L15.9172 22.1695C15.3819 22.7053 14.6696 23 13.9119 23ZM20.8749 13.2014L10.7992 3.12649C10.6094 2.93674 10.3532 2.83269 10.0896 2.83269C10.0309 2.83269 9.97181 2.83773 9.91314 2.84827L4.60895 3.79797C4.19826 3.87131 3.87282 4.19673 3.79902 4.60787L2.84929 9.91187C2.79154 10.2346 2.89559 10.5659 3.12706 10.7979L13.2028 20.8728C13.5814 21.2514 14.2415 21.2514 14.6201 20.8728L20.874 14.6191C21.265 14.2281 21.265 13.5919 20.874 13.201L20.8749 13.2014Z"
                                    fill="#96A8BA"
                                    stroke="white"
                                    strokeWidth="0.2"
                                  ></path>
                                  <path
                                    d="M7.5 9C6.67157 9 6 8.32843 6 7.5C6 6.67157 6.67157 6 7.5 6C8.32843 6 9 6.67157 9 7.5C9 8.32843 8.32843 9 7.5 9Z"
                                    fill="#96A8BA"
                                    stroke="white"
                                    strokeWidth="0.2"
                                  ></path>
                                </svg>
                              )}
                            </p>
                            {userType !== userTypeConsts.TEAM_MANAGER &&
                              tagEditDropdownOpen && (
                                <div className="custom-dropdown">
                                  <input
                                    type="text"
                                    className="search-bar"
                                    placeholder="Search..."
                                    value={tagQuery}
                                    onChange={handleTagSearchInputChange}
                                    disabled={
                                      userType === userTypeConsts.TEAM_MANAGER
                                    }
                                  />
                                  <ul className="client-list">
                                    {filteredTagItems.map((item) => (
                                      <li
                                        className={
                                          tagIdEdit === item._id
                                            ? "selected-item cursor"
                                            : "cursor"
                                        }
                                        key={item._id}
                                        onClick={(e) => {
                                          setTagEdit(item.tag_name);
                                          setTagIdEdit(item._id);
                                          setTagEditDropdownOpen(false);
                                        }}
                                      >
                                        {item.tag_name}
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>

                      <div className="form-field-client tracker-detail-dropdown">
                        <label htmlFor="time" className="form-label">
                          Date
                        </label>

                        <div className="date-picker-outer">
                          <div className="custom-picker-icon">
                            <svg
                              width="1em"
                              height="1em"
                              viewBox="0 0 14 14"
                              fill="currentColor"
                              aria-hidden="true"
                              focusable="false"
                              class="rs-picker-toggle-caret rs-icon"
                              aria-label="calendar"
                              data-category="legacy"
                            >
                              <path d="M1 4v8.5a.5.5 0 00.5.5H8c0-.128.049-.256.146-.354.555-.555.854-1.6.854-3.146a.5.5 0 01.621-.485l.119.03A2.623 2.623 0 0012.999 6.5V4h-12zm3-2h6V0h1v2h1.5A1.5 1.5 0 0114 3.5v3a3.623 3.623 0 01-4.015 3.603c-.064 1.245-.335 2.212-.831 2.898H12.5a.5.5 0 00.5-.5v-2a.5.5 0 011 0v2a1.5 1.5 0 01-1.5 1.5h-11a1.5 1.5 0 01-1.5-1.5v-9a1.5 1.5 0 011.5-1.5H3v-2h1v2zm5 4V5h1v1h2v1h-2v1H9V7H7v2h1v1H7v2H6v-2H4v2H3v-2H1V9h2V7H1V6h2V5h1v1h2V5h1v1h2zM6 9V7H4v2h2z"></path>
                            </svg>
                          </div>
                          <div className="custoom-icon-calender">
                            {userType !== userTypeConsts.TEAM_MANAGER ? (
                              <div className="date-overview-sec date-overview-sec-rem">
                                <DatePicker
                                  block
                                  name="date"
                                  appearance="subtle"
                                  className="tracker-date"
                                  value={new Date(dateEdit)}
                                  onChange={(value) => {
                                    setDateOldEdit(new Date(dateEdit));
                                    setDateEdit(value);
                                  }}
                                  ref={datePickerRef}
                                  placement="bottomStart"
                                  oneTap
                                  format="dd/MM/yyyy"
                                  disabled={
                                    userType === userTypeConsts.TEAM_MANAGER
                                  }
                                  isoWeek={
                                    appSettings.weekStartDay &&
                                    appSettings.weekStartDay === "Monday"
                                  }
                                  timeZone={global.config.getTimeZone()}
                                />
                              </div>
                            ) : (
                              <input
                                type="text"
                                id="trackerDate"
                                name="trackerDate"
                                value={new Date(dateEdit).toLocaleDateString(
                                  "en-GB"
                                )}
                                className="form-input"
                                disabled
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="form-field-client tracker-detail-dropdown">
                        <label htmlFor="time" className="form-label">
                          Time
                        </label>
                        <>
                          <div className="time-overview-sec">
                            <input
                              type="text"
                              name="start_time"
                              className="start-time-tracker-detail"
                              value={startTimeEdit}
                              style={{
                                backgroundColor: uStartTimeError,
                              }}
                              onChange={(e) => {
                                changeStartTime(e.target.value);
                              }}
                              onBlur={() => {
                                if (!startTimeEdit) setStartTimeEdit("7:46pm");
                              }}
                              disabled={userType === userTypeConsts.TEAM_MANAGER}
                            />
                            -
                            <input
                              type="text"
                              name="end_time"
                              value={endTimeEdit}
                              style={{ backgroundColor: uEndTimeError }}
                              onChange={(e) => {
                                changeEndTime(e.target.value);
                              }}
                              onBlur={() => {
                                if (!endTimeEdit) {
                                  setEndTimeEdit("8:50pm");
                                }
                              }}
                              className="edit-time-end"
                              disabled={userType === userTypeConsts.TEAM_MANAGER}
                            />
                          </div>
                        </>
                      </div>

                      <div className="form-field-client tracker-detail-dropdown">
                        <label htmlFor="time" className="form-label">
                          Duration
                        </label>
                        <div
                          className="timer-sec-input"
                          style={{
                            backgroundColor: uFormatedTimeError && "#fddada",
                          }}
                        >
                          <input
                            className="edit-time"
                            name="time"
                            value={uFormatedTime}
                            onChange={(e) => {
                              timeShowEdit(e.target.value);
                            }}
                            placeholder="00:00:00"
                            type="text"
                            disabled={userType === userTypeConsts.TEAM_MANAGER}
                          />
                        </div>
                      </div>
                      {userType !== userTypeConsts.TEAM_MANAGER && (
                        <div className="update-btn-trackter-detail">
                          <button
                            type="submit"
                            className="update-btn-trackter-detail-btn"
                            disabled={
                              (isUpdateLoading ||
                                uFormatedTimeError ||
                                uEndTimeError ||
                                uStartTimeError) &&
                              "disabled"
                            }
                          >
                            {isUpdateLoading ? (
                              <ThreeDots
                                height="18"
                                width="20"
                                radius="9"
                                color="white"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                              />
                            ) : (
                              "Update"
                            )}
                          </button>
                        </div>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
        )}

      {(userType === userTypeConsts.SUPER_ADMIN ||
        userType === userTypeConsts.ADMIN ||
        userType === userTypeConsts.PROJECT_MANAGER ||
        userType === userTypeConsts.TEAM_MANAGER) && (
          <div className="modal-invite-main-outter">
            <Modal
              className="client-modal project-modal bulk-edit-modal"
              isOpen={isBulkEditModalOpen}
              onRequestClose={handleCloseDetailsModal}
              contentLabel="New Project"
              style={{
                content: {
                  height: "41%",
                  width: "30%",
                  position: "fixed",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  parent: document.querySelector(".admin-outer.time.tracker"),
                },
              }}
            >
              <div className="modal">
                <div className="modal-close-invite outter-modal-main">
                  <a className="" onClick={handleCloseDetailsModal}>
                    <img src={crssrImage} alt="Edit Icon" />{" "}
                  </a>
                </div>
                <div className="project-detail-modal">
                  <div className="modal-client-head ">
                    <h2 className="">Time Tracker</h2>
                  </div>
                  <div className="form-field-client-outer form-field-client-outer-bulk-edit">
                    <form onSubmit={updateTimerGroup}>
                      <div className="form-field-client">
                        <div className="bulk-edit-label">
                          <input type="checkbox" checked={userCheck} onChange={() => setUserCheck(!userCheck)} />
                          <label htmlFor="projectName" className="form-label">
                            User
                          </label>
                        </div>
                        <div className="form-field-client-rt">
                          <div
                            style={{
                              pointerEvents: userCheck ? 'auto' : 'none',
                              cursor: userCheck ? 'pointer' : 'default'
                            }}
                            ref={userDropdownRef}
                            className={`cursor ${userDropdownOpen ? "open" : ""}`}

                          >
                            <span
                              id="user-select"
                              onClick={handleToggleDropdown}
                              disabled={userType === userTypeConsts.TEAM_MANAGER}
                              className="custom-dropdown-user"
                            >
                              {userSelectedId ? (
                                userId.find((user) => user._id === userSelectedId)
                                  .first_name ? (
                                  <>
                                    {
                                      userId.find(
                                        (user) => user._id === userSelectedId
                                      ).first_name
                                    }{" "}
                                    {userId.find(
                                      (user) => user._id === userSelectedId
                                    ).middle_name &&
                                      userId.find(
                                        (user) => user._id === userSelectedId
                                      ).middle_name !== "null"
                                      ? userId.find(
                                        (user) => user._id === userSelectedId
                                      ).middle_name + " "
                                      : ""}
                                    {
                                      userId.find(
                                        (user) => user._id === userSelectedId
                                      ).last_name
                                    }{" "}
                                  </>
                                ) : (
                                  <>
                                    {userId.find(
                                      (user) => user._id === userSelectedId
                                    ).company_first_name
                                      ? `${userId.find(
                                        (user) => user._id === userSelectedId
                                      ).company_first_name
                                      } ${userId.find(
                                        (user) => user._id === userSelectedId
                                      ).company_last_name
                                        ? userId.find(
                                          (user) =>
                                            user._id === userSelectedId
                                        ).company_last_name
                                        : ""
                                      }`
                                      : `${userId.find(
                                        (user) => user._id === userSelectedId
                                      ).company_name || ""
                                      }(Company)`}
                                  </>
                                )
                              ) : (
                                "Select User"
                              )}
                            </span>
                            {userType !== userTypeConsts.TEAM_MANAGER &&
                              userDropdownOpen && (
                                <div className="custom-dropdown">
                                  <input
                                    type="text"
                                    className="search-bar"
                                    placeholder="Search..."
                                    value={searchTerm}
                                    onChange={handleSearchInputChange}
                                    disabled={
                                      userType === userTypeConsts.TEAM_MANAGER
                                    }
                                  />
                                  <ul className="client-list">
                                    {filteredUsers.length > 0 &&
                                      filteredUsers
                                        .slice()
                                        .sort((a, b) => {
                                          const nameA = a.first_name
                                            ? `${a.first_name} ${a.middle_name || ""
                                            } ${a.last_name}`
                                            : `${a.company_first_name
                                              ? a.company_first_name +
                                              (a.company_last_name &&
                                                a.company_last_name)
                                              : a.company_name
                                            }`;
                                          const nameB = b.first_name
                                            ? `${b.first_name} ${b.middle_name || ""
                                            } ${b.last_name}`
                                            : `${b.company_first_name
                                              ? b.company_first_name +
                                              (b.company_last_name &&
                                                b.company_last_name)
                                              : b.company_name
                                            }`;
                                          return nameA.localeCompare(nameB);
                                        })
                                        .map((item) => (
                                          <li
                                            key={item._id}
                                            onClick={() =>
                                              handleSelectUser(item._id)
                                            }
                                            className={
                                              userSelectedId === item._id
                                                ? "selected-item"
                                                : ""
                                            }
                                          >
                                            {item.first_name ? (
                                              <>
                                                {item.first_name}{" "}
                                                {item.middle_name &&
                                                  item.middle_name !== "null"
                                                  ? item.middle_name + " "
                                                  : ""}
                                                {item.last_name}{" "}
                                              </>
                                            ) : (
                                              <>
                                                {item.company_first_name
                                                  ? `${item.company_first_name} ${item.company_last_name
                                                    ? item.company_last_name
                                                    : ""
                                                  }`
                                                  : `${item.company_name || ""
                                                  }(Company)`}
                                              </>
                                            )}
                                          </li>
                                        ))}
                                  </ul>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                      <div className="form-field-client">
                        <div className="bulk-edit-label">
                          <input type="checkbox" checked={taskCheck} onChange={() => setTaskCheck(!taskCheck)} />
                          <label htmlFor="projectName" className="form-label">
                            Task Name
                          </label>
                        </div>
                        <div className="form-field-client-rt">
                          <input

                            style={{
                              pointerEvents: taskCheck ? 'auto' : 'none',
                              cursor: taskCheck ? 'pointer' : 'default'
                            }}
                            type="text"
                            id="taskName"
                            name="taskName"
                            value={taskNameEdit}
                            onChange={(e) => setTaskNameEdit(e.target.value)}
                            className="form-input"
                            disabled={userType === userTypeConsts.TEAM_MANAGER}
                          />
                        </div>
                      </div>
                      <div ref={modalContentRef}></div>
                      <div className="form-field-client tracker-detail-dropdown tracker-detail-dropdown-pro">
                        <div className="bulk-edit-label">
                          <input type="checkbox" checked={projectCheck} onChange={() => setProjectCheck(!projectCheck)} />
                          <label htmlFor="client" className="form-label">
                            Project Name
                          </label>
                        </div>
                        <div className="form-field-client-rt">
                          <div ref={projectDropdownRef}>
                            <div

                              style={{
                                pointerEvents: projectCheck ? 'auto' : 'none',
                                cursor: projectCheck ? 'pointer' : 'default'
                              }}
                              className="cursor"
                              onClick={() => {
                                if (userType !== userTypeConsts.TEAM_MANAGER) {
                                  setProjectEditDropdownOpen(
                                    !projectEditDropdownOpen
                                  );
                                  setFilter("");
                                  setSelectedClient(null);
                                  setSelectedProject(null);
                                  setTagEditDropdownOpen(false);
                                  setSelectedClients(new Set());
                                }
                              }}
                            >
                              <span>
                                {projectNameEdit
                                  ? projectNameEdit
                                  : "Select a Project"}
                                {`${sprintNameEdit
                                  ? ` - ${sprintNameEdit}`
                                  : sprintNameEdit
                                  }`}
                              </span>
                            </div>
                            {userType !== userTypeConsts.TEAM_MANAGER &&
                              projectEditDropdownOpen && (
                                <div className="custom-dropdown">
                                  <input
                                    type="text"
                                    className="search-bar"
                                    placeholder="Find Project or Client"
                                    value={filter}
                                    onChange={(e) => {
                                      const filterValue = e.target.value;
                                      setFilter(filterValue);
                                      if (filterValue === "") {
                                        setSelectedClients(new Set());
                                      } else {
                                        // Check if the filtered project exists under any client
                                        const matchingClients =
                                          filteredClientData.filter((client) =>
                                            client.projects.some((project) =>
                                              project.project_name
                                                .toLowerCase()
                                                .includes(filterValue.toLowerCase()) &&
                                              (projectStatusFilter === "All"
                                                ? true
                                                : projectStatusFilter === "Active"
                                                  ? project.is_active === true
                                                  : project.is_active === false)
                                            )
                                          );
                                        setSelectedClients(
                                          new Set(matchingClients)
                                        );
                                      }
                                    }}
                                    disabled={
                                      userType === userTypeConsts.TEAM_MANAGER
                                    }
                                  />
                                  <ul className="client-list client-list-filter">
                                    {(userType === userTypeConsts.SUPER_ADMIN || userType === userTypeConsts.ADMIN) && (
                                      <>
                                        <div className="user-status-dropdown">
                                          <div className="user-status-dropdown-label" htmlFor="projectStatusFilter">Filter by Status</div>
                                          <div className="custom-dropdown-user" ref={projectStatusDropdownRef}>
                                            <div className="showing-name" onClick={toggleProjectStatusDropdown}>
                                              {projectStatusFilter ? projectStatusFilter : "Active"}
                                            </div>
                                          </div>
                                        </div>
                                        {isProjectStatusDropdownOpen && (
                                          <ul className="status-list">
                                            <li
                                              onClick={() => handleProjectStatusChange("All")}
                                              className={projectStatusFilter === "All" ? "selected-item" : ""}
                                            >
                                              All
                                            </li>
                                            <li
                                              onClick={() => handleProjectStatusChange("Active")}
                                              className={projectStatusFilter === "Active" ? "selected-item" : ""}
                                            >
                                              Active
                                            </li>
                                            <li
                                              onClick={() => handleProjectStatusChange("Inactive")}
                                              className={projectStatusFilter === "Inactive" ? "selected-item" : ""}
                                            >
                                              Inactive
                                            </li>
                                          </ul>
                                        )}
                                      </>
                                    )}
                                    {filteredClientData.map((client) => (
                                      <div key={client._id} className="main-outer-item-dd" >
                                        {/* Render the client name */}
                                        <div
                                          className={`client-name1 ${selectedClients.has(client) ? "selected" : ""}`}
                                          onClick={() => {
                                            if (client.projects.filter(
                                              (project) =>
                                                project.project_name
                                                  .toLowerCase()
                                                  .includes(filter.toLowerCase()) &&
                                                (projectStatusFilter === "All"
                                                  ? true
                                                  : projectStatusFilter === "Active"
                                                    ? project.is_active === true
                                                    : project.is_active === false)
                                            ).length > 0) {
                                              // Toggle the client in the Set
                                              const newSelectedClients = new Set(selectedClients);
                                              if (selectedClients.has(client)) {
                                                newSelectedClients.delete(client);
                                              } else {
                                                newSelectedClients.add(client);
                                              }
                                              setSelectedClients(
                                                newSelectedClients
                                              );
                                            }
                                          }}
                                        >
                                          <span className="main-project">
                                            {client.client_name}{" "}
                                          </span>
                                          {client.projects.filter(
                                            (project) =>
                                              project.project_name
                                                .toLowerCase()
                                                .includes(filter.toLowerCase()) &&
                                              (projectStatusFilter === "All"
                                                ? true
                                                : projectStatusFilter === "Active"
                                                  ? project.is_active === true
                                                  : project.is_active === false)
                                          ).length > 0 && (
                                              <span className={`arrow ${selectedClients.has(client) ? "open" : ""}`} >
                                                <span className="main-project-title">
                                                  {client.projects.filter(
                                                    (project) =>
                                                      project.project_name
                                                        .toLowerCase()
                                                        .includes(filter.toLowerCase()) &&
                                                      (projectStatusFilter === "All"
                                                        ? true
                                                        : projectStatusFilter === "Active"
                                                          ? project.is_active === true
                                                          : project.is_active === false)
                                                  ).length} Projects
                                                </span>
                                                {selectedClients.has(client) ? (
                                                  <img src={TopArrow} alt="Open" />
                                                ) : (
                                                  <img src={DownArrow} alt="Close" />
                                                )}
                                              </span>
                                            )}
                                        </div>
                                        {selectedClients.has(client) && (
                                          <div className="project-name1-outer">
                                            {/* Render projects under the selected client */}
                                            {client.projects.filter(
                                              (project) =>
                                                project.project_name
                                                  .toLowerCase()
                                                  .includes(filter.toLowerCase()) &&
                                                (projectStatusFilter === "All"
                                                  ? true
                                                  : projectStatusFilter === "Active"
                                                    ? project.is_active === true
                                                    : project.is_active === false)
                                            ).length === 0 ? (
                                              <div>
                                                No projects under this client
                                              </div>
                                            ) : (
                                              client.projects
                                                .filter((project) =>
                                                  project.project_name.toLowerCase().includes(filter.toLowerCase()) &&
                                                  (projectStatusFilter === "All" ? true : projectStatusFilter === "Active" ? project.is_active === true : project.is_active === false)
                                                )
                                                .sort((a, b) => a.project_name.localeCompare(b.project_name))
                                                .map((project) => (
                                                  <div key={project._id}>
                                                    <div className={`project-name1 ${selectedProject === project ? "selected" : ""}`} >
                                                      <div
                                                        onClick={(e) => {
                                                          setSprintIdEdit("");
                                                          setSprintNameEdit("");
                                                          setProjectIdEdit(project._id);
                                                          setProjectNameEdit(project.project_name);
                                                          setClientEdit(client.client_name);
                                                          setClientIdEdit(client._id);
                                                          setBillableEdit(project.is_billable);
                                                          setProjectEditDropdownOpen(false);
                                                        }}
                                                      >
                                                        <span className="project-dd-title">
                                                          {project.project_name}{" "}
                                                        </span>
                                                      </div>
                                                      <div>
                                                        {project.sprints.length > 0 && (
                                                          <span
                                                            className={`arrow ${selectedProject === project ? "open" : ""}`}
                                                            onClick={() => setSelectedProject(selectedProject === project ? null : project)}
                                                          >
                                                            <span className="main-project-title">
                                                              {project.sprints.length}{" "}
                                                              Sprints
                                                            </span>
                                                            {selectedProject === project ? (
                                                              <img src={TopArrow} alt="Open" />
                                                            ) : (
                                                              <img src={DownArrow} alt="Close" />
                                                            )}
                                                          </span>
                                                        )}
                                                      </div>
                                                    </div>
                                                    {selectedProject === project && (
                                                      <div>
                                                        {/* Render sprints under the selected project */}
                                                        {project.sprints.length === 0 ? (
                                                          <div>
                                                            No sprints under this project
                                                          </div>
                                                        ) : (
                                                          <ul className="sprint-list">
                                                            {project.sprints
                                                              .sort((a, b) =>
                                                                a.sprint_name.localeCompare(b.sprint_name)
                                                              )
                                                              .map((sprint) => (
                                                                <li
                                                                  key={sprint._id}
                                                                  onClick={(e) => {
                                                                    setSprintIdEdit(sprint._id);
                                                                    setSprintNameEdit(sprint.sprint_name);
                                                                    setProjectIdEdit(project._id);
                                                                    setProjectNameEdit(project.project_name);
                                                                    setClientEdit(client.client_name);
                                                                    setClientIdEdit(client._id);
                                                                    setBillableEdit(project.is_billable);
                                                                    setProjectEditDropdownOpen(false);
                                                                  }}
                                                                >
                                                                  {sprint.sprint_name}
                                                                </li>
                                                              ))}
                                                          </ul>
                                                        )}
                                                      </div>
                                                    )}
                                                  </div>
                                                ))
                                            )}
                                          </div>
                                        )}
                                      </div>
                                    ))}
                                  </ul>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                      <div className="form-field-client project-name-tracker-non-editable-outer">
                        <div className="bulk-edit-label">
                          <label htmlFor="client" className="form-label">
                            Client
                          </label>
                        </div>
                        <div className="form-field-client-rt">
                          <div class="project-name-tracker-non-editable form-input readonly">
                            {clientEdit ? clientEdit : "Client Name"}
                          </div>
                        </div>
                      </div>

                      <div className="form-field-client tracker-detail-dropdown">
                        <div className="bulk-edit-label">
                          <input type="checkbox" checked={tagCheck} onChange={() => setTagCheck(!tagCheck)} />
                          <label htmlFor="description" className="form-label">
                            Tag
                          </label>
                        </div>
                        <div className="form-field-client-rt">
                          <div ref={tagDropdownRef}>
                            <div
                              style={{
                                pointerEvents: tagCheck ? 'auto' : 'none',
                                cursor: tagCheck ? 'pointer' : 'default'
                              }}
                              className="tag-section">
                              <p
                                className="cursor top-cursor"
                                onClick={() => {
                                  if (userType !== userTypeConsts.TEAM_MANAGER) {
                                    setTagEditDropdownOpen(!tagEditDropdownOpen);
                                    setProjectEditDropdownOpen(false);
                                    setTagQuery("");
                                  }
                                }}
                              >
                                {" "} {tagEdit ? (tagEdit
                                ) : (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <path
                                      d="M13.9119 23C13.1542 23 12.4419 22.7048 11.9066 22.1695L1.83081 12.0945C1.17489 11.4386 0.881077 10.5022 1.04426 9.5892L1.99398 4.28519C2.20208 3.12282 3.12293 2.202 4.28535 1.99391L9.58953 1.04422C10.5067 0.881044 11.4395 1.1753 12.0949 1.83074L22.1712 11.9052C23.2763 13.0108 23.2763 14.8102 22.1712 15.9158L15.9172 22.1695C15.3819 22.7053 14.6696 23 13.9119 23ZM20.8749 13.2014L10.7992 3.12649C10.6094 2.93674 10.3532 2.83269 10.0896 2.83269C10.0309 2.83269 9.97181 2.83773 9.91314 2.84827L4.60895 3.79797C4.19826 3.87131 3.87282 4.19673 3.79902 4.60787L2.84929 9.91187C2.79154 10.2346 2.89559 10.5659 3.12706 10.7979L13.2028 20.8728C13.5814 21.2514 14.2415 21.2514 14.6201 20.8728L20.874 14.6191C21.265 14.2281 21.265 13.5919 20.874 13.201L20.8749 13.2014Z"
                                      fill="#96A8BA"
                                      stroke="white"
                                      strokeWidth="0.2"
                                    ></path>
                                    <path
                                      d="M7.5 9C6.67157 9 6 8.32843 6 7.5C6 6.67157 6.67157 6 7.5 6C8.32843 6 9 6.67157 9 7.5C9 8.32843 8.32843 9 7.5 9Z"
                                      fill="#96A8BA"
                                      stroke="white"
                                      strokeWidth="0.2"
                                    ></path>
                                  </svg>
                                )}
                              </p>
                              {userType !== userTypeConsts.TEAM_MANAGER &&
                                tagEditDropdownOpen && (
                                  <div className="custom-dropdown">
                                    <input
                                      type="text"
                                      className="search-bar"
                                      placeholder="Search..."
                                      value={tagQuery}
                                      onChange={handleTagSearchInputChange}
                                      disabled={
                                        userType === userTypeConsts.TEAM_MANAGER
                                      }
                                    />
                                    <ul className="client-list">
                                      {filteredTagItems.map((item) => (
                                        <li
                                          className={
                                            tagIdEdit === item._id
                                              ? "selected-item cursor"
                                              : "cursor"
                                          }
                                          key={item._id}
                                          onClick={(e) => {
                                            setTagEdit(item.tag_name);
                                            setTagIdEdit(item._id);
                                            setTagEditDropdownOpen(false);
                                          }}
                                        >
                                          {item.tag_name}
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="form-field-client tracker-detail-dropdown">
                        <div className="bulk-edit-label">
                          <input type="checkbox" checked={dateCheck} onChange={() => setDateCheck(!dateCheck)} />
                          <label htmlFor="time" className="form-label">
                            Date
                          </label>
                        </div>
                        <div className="form-field-client-rt">
                          <div className="date-picker-outer">
                            <div className="custom-picker-icon">
                              <svg
                                width="1em"
                                height="1em"
                                viewBox="0 0 14 14"
                                fill="currentColor"
                                aria-hidden="true"
                                focusable="false"
                                class="rs-picker-toggle-caret rs-icon"
                                aria-label="calendar"
                                data-category="legacy"
                              >
                                <path d="M1 4v8.5a.5.5 0 00.5.5H8c0-.128.049-.256.146-.354.555-.555.854-1.6.854-3.146a.5.5 0 01.621-.485l.119.03A2.623 2.623 0 0012.999 6.5V4h-12zm3-2h6V0h1v2h1.5A1.5 1.5 0 0114 3.5v3a3.623 3.623 0 01-4.015 3.603c-.064 1.245-.335 2.212-.831 2.898H12.5a.5.5 0 00.5-.5v-2a.5.5 0 011 0v2a1.5 1.5 0 01-1.5 1.5h-11a1.5 1.5 0 01-1.5-1.5v-9a1.5 1.5 0 011.5-1.5H3v-2h1v2zm5 4V5h1v1h2v1h-2v1H9V7H7v2h1v1H7v2H6v-2H4v2H3v-2H1V9h2V7H1V6h2V5h1v1h2V5h1v1h2zM6 9V7H4v2h2z"></path>
                              </svg>
                            </div>
                            <div className="custoom-icon-calender">
                              {userType !== userTypeConsts.TEAM_MANAGER ? (
                                <div

                                  style={{
                                    pointerEvents: dateCheck ? 'auto' : 'none',
                                    cursor: dateCheck ? 'pointer' : 'default'
                                  }}
                                  className="date-overview-sec">
                                  <DatePicker
                                    block
                                    name="date"
                                    appearance="subtle"
                                    className="tracker-date"
                                    value={new Date(dateEdit)}
                                    onChange={(value) => {
                                      setDateOldEdit(new Date());
                                      setDateEdit(value);
                                    }}
                                    ref={datePickerRef}
                                    placement="bottomStart"
                                    oneTap
                                    format="dd/MM/yyyy"
                                    disabled={
                                      userType === userTypeConsts.TEAM_MANAGER
                                    }
                                    isoWeek={
                                      appSettings.weekStartDay &&
                                      appSettings.weekStartDay === "Monday"
                                    }
                                    timeZone={global.config.getTimeZone()}
                                  />
                                </div>
                              ) : (
                                <input
                                  type="text"
                                  id="trackerDate"
                                  name="trackerDate"
                                  value={new Date().toLocaleDateString("en-GB")}
                                  className="form-input"
                                  disabled
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="form-field-client tracker-detail-dropdown">
                        <div className="bulk-edit-label">
                          <input type="checkbox" checked={timeCheck || durationCheck} onChange={() => setTimeCheck(!timeCheck)} />
                          <label htmlFor="time" className="form-label">
                            Time
                          </label>
                        </div>
                        <div className="form-field-client-rt">
                          <div
                            style={{
                              pointerEvents: timeCheck ? 'auto' : 'none',
                              cursor: timeCheck ? 'pointer' : 'default'
                            }}
                            className="time-overview-sec">
                            <input
                              type="text"
                              name="start_time"
                              className="start-time-tracker-detail"
                              value={startTimeEdit}
                              style={{ backgroundColor: uStartTimeError }}
                              onChange={(e) => {
                                changeStartTime(e.target.value);
                              }}
                              onBlur={() => {
                                if (!startTimeEdit) {
                                  setStartTimeEdit("07:46 pm");
                                }
                              }}
                              disabled={userType === userTypeConsts.TEAM_MANAGER}
                            />
                            -
                            <input
                              type="text"
                              name="end_time"
                              value={endTimeEdit}
                              style={{ backgroundColor: uEndTimeError }}
                              onChange={(e) => {
                                changeEndTime(e.target.value);
                              }}
                              onBlur={() => {
                                if (!endTimeEdit) {
                                  setEndTimeEdit("08:50 pm");
                                }
                              }}
                              className="edit-time-end"
                              disabled={userType === userTypeConsts.TEAM_MANAGER}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="form-field-client tracker-detail-dropdown tracker-detail-dropdown-last">
                        <div className="bulk-edit-label" >
                          <input type="checkbox" checked={durationCheck || timeCheck} onChange={() => setDurationCheck(!durationCheck)} />
                          <label htmlFor="time" className="form-label">
                            Duration
                          </label>
                        </div>
                        <div className="form-field-client-rt">
                          <div
                            className="timer-sec-input"
                            style={{
                              backgroundColor: uFormatedTimeError && "#fddada",
                              pointerEvents: durationCheck ? 'auto' : 'none',
                              cursor: durationCheck ? 'pointer' : 'default'
                            }}
                          >
                            <input
                              className="edit-time"
                              name="time"
                              value={uFormatedTime}
                              onChange={(e) => {
                                // handleTimeChange
                                timeShowEdit(e.target.value);
                              }}
                              placeholder="00:00:00"
                              type="text"
                              disabled={userType === userTypeConsts.TEAM_MANAGER}
                            />
                          </div>
                        </div>
                      </div>
                      {userType !== userTypeConsts.TEAM_MANAGER && (
                        <div className="update-btn-trackter-detail">
                          <button
                            type="submit"
                            className="update-btn-trackter-detail-btn"
                            disabled={
                              (isUpdateLoading ||
                                uFormatedTimeError ||
                                uEndTimeError ||
                                uStartTimeError) &&
                              "disabled"
                            }
                          >
                            {isUpdateLoading ? (
                              <ThreeDots
                                height="18"
                                width="20"
                                radius="9"
                                color="white"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                              />
                            ) : (
                              "Update"
                            )}
                          </button>
                        </div>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
        )}

      {(userType === userTypeConsts.SUPER_ADMIN ||
        userType === userTypeConsts.ADMIN ||
        userType === userTypeConsts.PROJECT_MANAGER ||
        userType === userTypeConsts.TEAM_MANAGER) && (
          <div className="modal-invite-main-outter">
            <Modal
              className="client-modal project-modal"
              isOpen={isEditModalOpen}
              onRequestClose={handleCloseDetailsModal}
              contentLabel="Time Tracker"
              style={{
                content: {
                  height: "41%",
                  width: "30%",
                  position: "fixed",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  parent: document.querySelector(".admin-outer.time.tracker"),
                },
              }}
            >
              <div className="modal">
                <div className="modal-close-invite outter-modal-main">
                  <a className="" onClick={handleCloseDetailsModal}>
                    <img src={crssrImage} alt="Edit Icon" />{" "}
                  </a>
                </div>
                <div className="project-detail-modal">
                  <div className="modal-client-head ">
                    <h2 className="">Time Tracker</h2>
                  </div>
                  <div className="form-field-client-outer">
                    <form onSubmit={updateTimer2}>
                      <div className="form-field-client">
                        <label htmlFor="projectName" className="form-label">
                          User
                        </label>
                        <div
                          ref={userDropdownRef}
                          className={`cursor ${userDropdownOpen ? "open" : ""}`}
                        >
                          <span
                            id="user-select"
                            onClick={handleToggleDropdown}
                            disabled={userType === userTypeConsts.TEAM_MANAGER}
                            className="custom-dropdown-user"
                          >
                            {userSelectedId ? (
                              userId.find((user) => user._id === userSelectedId)
                                .first_name ? (
                                <>
                                  {
                                    userId.find(
                                      (user) => user._id === userSelectedId
                                    ).first_name
                                  }{" "}
                                  {userId.find(
                                    (user) => user._id === userSelectedId
                                  ).middle_name &&
                                    userId.find(
                                      (user) => user._id === userSelectedId
                                    ).middle_name !== "null"
                                    ? userId.find(
                                      (user) => user._id === userSelectedId
                                    ).middle_name + " "
                                    : ""}
                                  {
                                    userId.find(
                                      (user) => user._id === userSelectedId
                                    ).last_name
                                  }{" "}
                                </>
                              ) : (
                                <>
                                  {userId.find(
                                    (user) => user._id === userSelectedId
                                  ).company_first_name
                                    ? `${userId.find(
                                      (user) => user._id === userSelectedId
                                    ).company_first_name
                                    } ${userId.find(
                                      (user) => user._id === userSelectedId
                                    ).company_last_name
                                      ? userId.find(
                                        (user) =>
                                          user._id === userSelectedId
                                      ).company_last_name
                                      : ""
                                    }`
                                    : `${userId.find(
                                      (user) => user._id === userSelectedId
                                    ).company_name || ""
                                    }(Company)`}
                                </>
                              )
                            ) : (
                              "Select User"
                            )}
                          </span>
                          {userType !== userTypeConsts.TEAM_MANAGER &&
                            userDropdownOpen && (
                              <div className="custom-dropdown">
                                <input
                                  type="text"
                                  className="search-bar"
                                  placeholder="Search..."
                                  value={searchTerm}
                                  onChange={handleSearchInputChange}
                                  disabled={
                                    userType === userTypeConsts.TEAM_MANAGER
                                  }
                                />
                                <ul className="client-list">
                                  {filteredUsers.length > 0 &&
                                    filteredUsers
                                      .slice()
                                      .sort((a, b) => {
                                        const nameA = a.first_name
                                          ? `${a.first_name} ${a.middle_name || ""
                                          } ${a.last_name}`
                                          : `${a.company_first_name
                                            ? a.company_first_name +
                                            (a.company_last_name &&
                                              a.company_last_name)
                                            : a.company_name
                                          }`;
                                        const nameB = b.first_name
                                          ? `${b.first_name} ${b.middle_name || ""
                                          } ${b.last_name}`
                                          : `${b.company_first_name
                                            ? b.company_first_name +
                                            (b.company_last_name &&
                                              b.company_last_name)
                                            : b.company_name
                                          }`;
                                        return nameA.localeCompare(nameB);
                                      })
                                      .map((item) => (
                                        <li
                                          key={item._id}
                                          onClick={() =>
                                            handleSelectUser(item._id)
                                          }
                                          className={
                                            userSelectedId === item._id
                                              ? "selected-item"
                                              : ""
                                          }
                                        >
                                          {item.first_name ? (
                                            <>
                                              {item.first_name}{" "}
                                              {item.middle_name &&
                                                item.middle_name !== "null"
                                                ? item.middle_name + " "
                                                : ""}
                                              {item.last_name}{" "}
                                            </>
                                          ) : (
                                            <>
                                              {item.company_first_name
                                                ? `${item.company_first_name} ${item.company_last_name
                                                  ? item.company_last_name
                                                  : ""
                                                }`
                                                : `${item.company_name || ""
                                                }(Company)`}
                                            </>
                                          )}
                                        </li>
                                      ))}
                                </ul>
                              </div>
                            )}
                        </div>
                      </div>
                      <div className="form-field-client">
                        <label htmlFor="projectName" className="form-label">
                          Task Name
                        </label>
                        <input
                          type="text"
                          id="taskName"
                          name="taskName"
                          value={taskNameEdit}
                          onChange={(e) => setTaskNameEdit(e.target.value)}
                          className="form-input"
                          disabled={userType === userTypeConsts.TEAM_MANAGER}
                        />
                      </div>
                      <div ref={modalContentRef}></div>
                      <div className="form-field-client tracker-detail-dropdown tracker-detail-dropdown-pro">
                        <label htmlFor="client" className="form-label">
                          Project Name
                        </label>

                        <div ref={projectDropdownRef}>
                          <div
                            className="cursor"
                            onClick={() => {
                              if (userType !== userTypeConsts.TEAM_MANAGER) {
                                setProjectEditDropdownOpen(
                                  !projectEditDropdownOpen
                                );
                                setFilter("");
                                setSelectedClient(null);
                                setSelectedProject(null);
                                setTagEditDropdownOpen(false);
                                setSelectedClients(new Set());
                              }
                            }}
                          >
                            <span>
                              {projectNameEdit ? projectNameEdit : ""}
                              {`${sprintNameEdit
                                ? ` - ${sprintNameEdit}`
                                : sprintNameEdit
                                }`}
                              <div class="project-name-tracker">{clientEdit}</div>
                            </span>
                          </div>
                          {userType !== userTypeConsts.TEAM_MANAGER &&
                            projectEditDropdownOpen && (
                              <div className="custom-dropdown">
                                <input
                                  type="text"
                                  className="search-bar"
                                  placeholder="Find Project or Client"
                                  value={filter}
                                  onChange={(e) => {
                                    const filterValue = e.target.value;
                                    setFilter(filterValue);
                                    if (filterValue === "") {
                                      setSelectedClients(new Set());
                                    } else {
                                      // Check if the filtered project exists under any client
                                      const matchingClients =
                                        filteredClientData.filter((client) =>
                                          client.projects.some((project) =>
                                            project.project_name.toLowerCase().includes(filterValue.toLowerCase()) &&
                                            (projectStatusFilter === "All" ? true : projectStatusFilter === "Active" ? project.is_active === true : project.is_active === false)
                                          )
                                        );
                                      setSelectedClients(
                                        new Set(matchingClients)
                                      );
                                    }
                                  }}
                                  disabled={
                                    userType === userTypeConsts.TEAM_MANAGER
                                  }
                                />
                                <ul className="client-list client-list-filter">
                                  {(userType === userTypeConsts.SUPER_ADMIN || userType === userTypeConsts.ADMIN) && (
                                    <>
                                      <div className="user-status-dropdown">
                                        <div className="user-status-dropdown-label" htmlFor="projectStatusFilter">Filter by Status</div>
                                        <div className="custom-dropdown-user" ref={projectStatusDropdownRef}>
                                          <div className="showing-name" onClick={toggleProjectStatusDropdown}>
                                            {projectStatusFilter ? projectStatusFilter : "Active"}
                                          </div>
                                        </div>
                                      </div>
                                      {isProjectStatusDropdownOpen && (
                                        <ul className="status-list">
                                          <li
                                            onClick={() => handleProjectStatusChange("All")}
                                            className={projectStatusFilter === "All" ? "selected-item" : ""}
                                          >
                                            All
                                          </li>
                                          <li
                                            onClick={() => handleProjectStatusChange("Active")}
                                            className={projectStatusFilter === "Active" ? "selected-item" : ""}
                                          >
                                            Active
                                          </li>
                                          <li
                                            onClick={() => handleProjectStatusChange("Inactive")}
                                            className={projectStatusFilter === "Inactive" ? "selected-item" : ""}
                                          >
                                            Inactive
                                          </li>
                                        </ul>
                                      )}
                                    </>
                                  )}
                                  {filteredClientData.map((client) => (
                                    <div key={client._id} className="main-outer-item-dd" >
                                      {/* Render the client name */}
                                      <div
                                        className={`client-name1 ${selectedClients.has(client) ? "selected" : ""}`}
                                        onClick={() => {
                                          if (client.projects.filter(
                                            (project) =>
                                              project.project_name
                                                .toLowerCase()
                                                .includes(filter.toLowerCase()) &&
                                              (projectStatusFilter === "All"
                                                ? true
                                                : projectStatusFilter === "Active"
                                                  ? project.is_active === true
                                                  : project.is_active === false)
                                          ).length > 0) {
                                            // Toggle the client in the Set
                                            const newSelectedClients = new Set(selectedClients);
                                            if (selectedClients.has(client)) {
                                              newSelectedClients.delete(client);
                                            } else {
                                              newSelectedClients.add(client);
                                            }
                                            setSelectedClients(
                                              newSelectedClients
                                            );
                                          }
                                        }}
                                      >
                                        <span className="main-project">
                                          {client.client_name}{" "}
                                        </span>
                                        {client.projects.filter(
                                          (project) =>
                                            project.project_name
                                              .toLowerCase()
                                              .includes(filter.toLowerCase()) &&
                                            (projectStatusFilter === "All"
                                              ? true
                                              : projectStatusFilter === "Active"
                                                ? project.is_active === true
                                                : project.is_active === false)
                                        ).length > 0 && (
                                            <span className={`arrow ${selectedClients.has(client) ? "open" : ""}`} >
                                              <span className="main-project-title">
                                                {client.projects.filter(
                                                  (project) =>
                                                    project.project_name
                                                      .toLowerCase()
                                                      .includes(filter.toLowerCase()) &&
                                                    (projectStatusFilter === "All"
                                                      ? true
                                                      : projectStatusFilter === "Active"
                                                        ? project.is_active === true
                                                        : project.is_active === false)
                                                ).length} Projects
                                              </span>
                                              {selectedClients.has(client) ? (
                                                <img src={TopArrow} alt="Open" />
                                              ) : (
                                                <img src={DownArrow} alt="Close" />
                                              )}
                                            </span>
                                          )}
                                      </div>
                                      {selectedClients.has(client) && (
                                        <div className="project-name1-outer">
                                          {/* Render projects under the selected client */}
                                          {client.projects.filter(
                                            (project) =>
                                              project.project_name
                                                .toLowerCase()
                                                .includes(filter.toLowerCase()) &&
                                              (projectStatusFilter === "All"
                                                ? true
                                                : projectStatusFilter === "Active"
                                                  ? project.is_active === true
                                                  : project.is_active === false)
                                          ).length === 0 ? (
                                            <div>
                                              No projects under this client
                                            </div>
                                          ) : (
                                            client.projects
                                              .filter((project) => project.project_name.toLowerCase().includes(filter.toLowerCase()) &&
                                                (projectStatusFilter === "All" ? true : projectStatusFilter === "Active" ? project.is_active === true : project.is_active === false))
                                              .sort((a, b) =>
                                                a.project_name.localeCompare(b.project_name)
                                              )
                                              .map((project) => (
                                                <div key={project._id}>
                                                  <div className={`project-name1 ${selectedProject === project ? "selected" : ""}`} >
                                                    <div
                                                      onClick={(e) => {
                                                        setSprintIdEdit("");
                                                        setSprintNameEdit("");
                                                        setProjectIdEdit(project._id);
                                                        setProjectNameEdit(project.project_name);
                                                        setClientEdit(client.client_name);
                                                        setClientIdEdit(client._id);
                                                        setBillableEdit(project.is_billable);
                                                        setProjectEditDropdownOpen(false);
                                                      }}
                                                    >
                                                      <span className="project-dd-title">
                                                        {project.project_name}{" "}
                                                      </span>
                                                    </div>
                                                    <div>
                                                      {project.sprints.length > 0 && (
                                                        <span
                                                          className={`arrow ${selectedProject === project ? "open" : ""}`}
                                                          onClick={() => setSelectedProject(selectedProject === project ? null : project)}
                                                        >
                                                          <span className="main-project-title">
                                                            {project.sprints.length}{" "}
                                                            Sprints
                                                          </span>
                                                          {selectedProject ===
                                                            project ? (
                                                            <img src={TopArrow} alt="Open" />
                                                          ) : (
                                                            <img src={DownArrow} alt="Close" />
                                                          )}
                                                        </span>
                                                      )}
                                                    </div>
                                                  </div>
                                                  {selectedProject ===
                                                    project && (
                                                      <div>
                                                        {/* Render sprints under the selected project */}
                                                        {project.sprints.length ===
                                                          0 ? (
                                                          <div>
                                                            No sprints under this project
                                                          </div>
                                                        ) : (
                                                          <ul className="sprint-list">
                                                            {project.sprints
                                                              .sort((a, b) =>
                                                                a.sprint_name.localeCompare(b.sprint_name)
                                                              )
                                                              .map((sprint) => (
                                                                <li
                                                                  key={sprint._id}
                                                                  onClick={(e) => {
                                                                    setSprintIdEdit(sprint._id);
                                                                    setSprintNameEdit(sprint.sprint_name);
                                                                    setProjectIdEdit(project._id);
                                                                    setProjectNameEdit(project.project_name);
                                                                    setClientEdit(client.client_name);
                                                                    setClientIdEdit(client._id);
                                                                    setBillableEdit(project.is_billable);
                                                                    setProjectEditDropdownOpen(false);
                                                                  }}
                                                                >
                                                                  {sprint.sprint_name}
                                                                </li>
                                                              ))}
                                                          </ul>
                                                        )}
                                                      </div>
                                                    )}
                                                </div>
                                              ))
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  ))}
                                </ul>
                              </div>
                            )}
                        </div>
                      </div>
                      <div className="project-name-tracker-non-editable-outer">
                        <label htmlFor="client" className="form-label">
                          Client
                        </label>
                        <div class="project-name-tracker-non-editable form-input readonly">
                          {clientEdit}
                        </div>
                      </div>

                      <div className="form-field-client tracker-detail-dropdown">
                        <label htmlFor="description" className="form-label">
                          Tag
                        </label>
                        <div ref={tagDropdownRef}>
                          <div className="tag-section">
                            <p
                              className="cursor top-cursor"
                              onClick={() => {
                                if (userType !== userTypeConsts.TEAM_MANAGER) {
                                  setTagEditDropdownOpen(!tagEditDropdownOpen);
                                  setProjectEditDropdownOpen(false);
                                  setTagQuery("");
                                }
                              }}
                            >
                              {" "}
                              {tagEdit ? (
                                tagEdit
                              ) : (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path
                                    d="M13.9119 23C13.1542 23 12.4419 22.7048 11.9066 22.1695L1.83081 12.0945C1.17489 11.4386 0.881077 10.5022 1.04426 9.5892L1.99398 4.28519C2.20208 3.12282 3.12293 2.202 4.28535 1.99391L9.58953 1.04422C10.5067 0.881044 11.4395 1.1753 12.0949 1.83074L22.1712 11.9052C23.2763 13.0108 23.2763 14.8102 22.1712 15.9158L15.9172 22.1695C15.3819 22.7053 14.6696 23 13.9119 23ZM20.8749 13.2014L10.7992 3.12649C10.6094 2.93674 10.3532 2.83269 10.0896 2.83269C10.0309 2.83269 9.97181 2.83773 9.91314 2.84827L4.60895 3.79797C4.19826 3.87131 3.87282 4.19673 3.79902 4.60787L2.84929 9.91187C2.79154 10.2346 2.89559 10.5659 3.12706 10.7979L13.2028 20.8728C13.5814 21.2514 14.2415 21.2514 14.6201 20.8728L20.874 14.6191C21.265 14.2281 21.265 13.5919 20.874 13.201L20.8749 13.2014Z"
                                    fill="#96A8BA"
                                    stroke="white"
                                    strokeWidth="0.2"
                                  ></path>
                                  <path
                                    d="M7.5 9C6.67157 9 6 8.32843 6 7.5C6 6.67157 6.67157 6 7.5 6C8.32843 6 9 6.67157 9 7.5C9 8.32843 8.32843 9 7.5 9Z"
                                    fill="#96A8BA"
                                    stroke="white"
                                    strokeWidth="0.2"
                                  ></path>
                                </svg>
                              )}
                            </p>
                            {userType !== userTypeConsts.TEAM_MANAGER &&
                              tagEditDropdownOpen && (
                                <div className="custom-dropdown">
                                  <input
                                    type="text"
                                    className="search-bar"
                                    placeholder="Search..."
                                    value={tagQuery}
                                    onChange={handleTagSearchInputChange}
                                    disabled={
                                      userType === userTypeConsts.TEAM_MANAGER
                                    }
                                  />
                                  <ul className="client-list">
                                    {filteredTagItems.map((item) => (
                                      <li
                                        className={
                                          tagIdEdit === item._id
                                            ? "selected-item cursor"
                                            : "cursor"
                                        }
                                        key={item._id}
                                        onClick={(e) => {
                                          setTagEdit(item.tag_name);
                                          setTagIdEdit(item._id);
                                          setTagEditDropdownOpen(false);
                                        }}
                                      >
                                        {item.tag_name}
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                      {userType !== userTypeConsts.TEAM_MANAGER && (
                        <div className="update-btn-trackter-detail">
                          <button
                            type="submit"
                            className="update-btn-trackter-detail-btn"
                            disabled={
                              (isUpdateLoading ||
                                uFormatedTimeError ||
                                uEndTimeError ||
                                uStartTimeError) &&
                              "disabled"
                            }
                          >
                            {isUpdateLoading ? (
                              <ThreeDots
                                height="18"
                                width="20"
                                radius="9"
                                color="white"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                              />
                            ) : (
                              "Update"
                            )}
                          </button>
                        </div>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
        )}

      <Modal
        className="delete-modal"
        isOpen={showConfirmation}
        onRequestClose={() => setShowConfirmation(false)}
        contentLabel="Confirmation Delete"
        style={{
          content: {
            height: "41%",
            width: "30%",
            position: "fixed",
            top: "36%",
            left: "50%",
            transform: "translate(-50%, -50%)", // Center align horizontally
          },
        }}
      >
        <div className="delete-modal-content">
          <h2>Confirm Delete</h2>
          <p>Are you sure you want to delete?</p>
          <div className="delete-modal-buttons">
            <button
              className="buttons-rejected"
              onClick={deleteTimerGroup}
              disabled={isDeleting}
            >
              {isDeleting ? (
                <div className="outter-load-table">
                  <ThreeDots
                    height="18"
                    width="20"
                    radius="9"
                    color="white"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                </div>
              ) : (
                "Delete"
              )}
            </button>
            <button
              onClick={() => setShowConfirmation(false)}
              disabled={isSubmitLoading}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>

      <div className="time-table" ref={props.componentRef}>
        <table>
          <thead>
            <tr>
              {/* {(userType === userTypeConsts.SUPER_ADMIN ||
                userType === userTypeConsts.ADMIN ||
                userType === userTypeConsts.PROJECT_MANAGER ||
                userType === userTypeConsts.TEAM_MANAGER) && ( */}
              <>
                {(userType === userTypeConsts.SUPER_ADMIN ||
                  userType === userTypeConsts.ADMIN ||
                  userType === userTypeConsts.PROJECT_MANAGER ||
                  userType === userTypeConsts.TEAM_MANAGER) && (
                    <th className="time-tracker-top-checkbox">
                      {/* {selectedRows.length > 0 && (
                        <input
                          type="checkbox"
                          onChange={handleSelectAllChange}
                          checked={selectAll}
                        />
                      )} */}
                      <input
                        type="checkbox"
                        onChange={() => handleSelectAllChange(currentData)}
                        checked={selectAll}
                      />
                    </th>
                  )}
                <th onClick={() => {
                  if (
                    userType === userTypeConsts.SUPER_ADMIN ||
                    userType === userTypeConsts.ADMIN ||
                    userType === userTypeConsts.PROJECT_MANAGER ||
                    userType === userTypeConsts.TEAM_MANAGER
                  ) {
                    sortTable("name");
                  }
                }}>
                  User{" "}
                  {(userType === userTypeConsts.SUPER_ADMIN ||
                    userType === userTypeConsts.ADMIN ||
                    userType === userTypeConsts.PROJECT_MANAGER ||
                    userType === userTypeConsts.TEAM_MANAGER) && (
                      sortConfig.key === "name" &&
                        sortConfig.direction === "asc" ? (
                        <img src={UpArrowImage} alt="Up Arrow" />
                      ) : (
                        <img src={DownArrowImage} alt="Down Arrow" />
                      )
                    )}
                </th>
                {(userType === userTypeConsts.SUPER_ADMIN ||
                  userType === userTypeConsts.ADMIN ||
                  userType === userTypeConsts.PROJECT_MANAGER ||
                  userType === userTypeConsts.TEAM_MANAGER) && (
                    <th></th>
                  )}
              </>
              {/* )} */}
              <th>
                Tasks{" "}
              </th>
              <th onClick={() => sortTable("client")}>
                Client{" "}
                {sortConfig.key === "client" &&
                  sortConfig.direction === "asc" ? (
                  <img src={UpArrowImage} alt="Up Arrow" />
                ) : (
                  <img src={DownArrowImage} alt="Down Arrow" />
                )}
              </th>
              <th onClick={() => sortTable("project")}>
                Project{" "}
                {sortConfig.key === "project" &&
                  sortConfig.direction === "asc" ? (
                  <img src={UpArrowImage} alt="Up Arrow" />
                ) : (
                  <img src={DownArrowImage} alt="Down Arrow" />
                )}
              </th>
              <th onClick={() => sortTable("sprint")}>
                Sprint{" "}
                {sortConfig.key === "sprint" && sortConfig.direction === "asc" ? (
                  <img src={UpArrowImage} alt="Up Arrow" />
                ) : (
                  <img src={DownArrowImage} alt="Down Arrow" />
                )}
              </th>
              <th onClick={() => sortTable("tag")}>
                Tag{" "}
                {sortConfig.key === "tag" &&
                  sortConfig.direction === "asc" ? (
                  <img src={UpArrowImage} alt="Up Arrow" />
                ) : (
                  <img src={DownArrowImage} alt="Down Arrow" />
                )}
              </th>
              <th onClick={() => sortTable("duration")}>
                Duration{" "}
                {sortConfig.key === "duration" &&
                  sortConfig.direction === "asc" ? (
                  <img src={UpArrowImage} alt="Up Arrow" />
                ) : (
                  <img src={DownArrowImage} alt="Down Arrow" />
                )}
              </th>
              <th onClick={() => sortTable("date")}>
                Date{" "}
                {sortConfig.key === "date" && sortConfig.direction === "asc" ? (
                  <img src={UpArrowImage} alt="Up Arrow" />
                ) : (
                  <img src={DownArrowImage} alt="Down Arrow" />
                )}
              </th>
              <th onClick={() => sortTable("billable")}>
                Billable{" "}
                {sortConfig.key === "billable" &&
                  sortConfig.direction === "asc" ? (
                  <img src={UpArrowImage} alt="Up Arrow" />
                ) : (
                  <img src={DownArrowImage} alt="Down Arrow" />
                )}
              </th>
            </tr>
          </thead>

          <tbody>
            {(userType === userTypeConsts.SUPER_ADMIN ||
              userType === userTypeConsts.ADMIN ||
              userType === userTypeConsts.PROJECT_MANAGER ||
              userType === userTypeConsts.TEAM_MANAGER) && (
                selectedRows && selectedRows.length > 0 && (
                  <tr className="bulk-edit-outer">
                    <div className="bulk-edit">
                      <button
                        onClick={() => {
                          handleBulkModal();
                        }}
                      >
                        <img src={editImage} alt="Edit Icon" /> Bulk Edit
                      </button>
                      <button
                        onClick={() => {
                          handleBulkDelete();
                        }}
                        type="button"
                        className="delete-tracker-details"
                      >
                        <img src={Delete} alt="Delete" /> Delete
                      </button>
                    </div>
                  </tr>
                )
              )}
            {props.isLoading ? (
              <tr>
                <td colSpan="6">
                  <div className="outter-load-table">
                    <ThreeDots
                      height="80"
                      width="80"
                      radius="9"
                      color="#6479f9"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  </div>
                </td>
              </tr>
            ) : (
              <>
                {currentData.length > 0 ? (
                  currentData.map((group, groupIndex) => (
                    <React.Fragment key={groupIndex}>
                      <tr key={groupIndex}>
                        {/* {(userType === userTypeConsts.SUPER_ADMIN ||
                          userType === userTypeConsts.ADMIN ||
                          userType === userTypeConsts.PROJECT_MANAGER ||
                          userType === userTypeConsts.TEAM_MANAGER) && ( */}
                        <>
                          {(userType === userTypeConsts.SUPER_ADMIN ||
                            userType === userTypeConsts.ADMIN ||
                            userType === userTypeConsts.PROJECT_MANAGER ||
                            userType === userTypeConsts.TEAM_MANAGER) && (
                              <td>
                                {checkboxTemplate(
                                  group,
                                  groupedData.indexOf(group),
                                  currentData
                                )}
                              </td>
                            )}
                          <td>
                            <div className="extra-detail">
                              {group.entries.length > 1 && (
                                <div
                                  onClick={() => toggleGroup(groupIndex)}
                                  style={{ cursor: "pointer" }}
                                  className="extra-drop"
                                >
                                  <span>
                                    {group.entries.length}{" "}
                                    {expandedGroups.includes(groupIndex) ? (
                                      <img src={TopArrow} alt="Minus" />
                                    ) : (
                                      <img src={DownArrow} alt="Plus" />
                                    )}
                                  </span>
                                </div>
                              )}
                              <div className="member-name">
                                <img
                                  src={`/uploads/lowProfileImage/${group.user && group.user.profile_image
                                    }`}
                                  alt={
                                    group.user &&
                                    (group.user.first_name ||
                                      group.user.company_name)
                                  }
                                  className="member-image"
                                  onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src =
                                      "/uploads/lowProfileImage/user_default.png";
                                  }}
                                />
                                <span>
                                  <TruncateUserName
                                    textContent={
                                      group.user.first_name ? (
                                        <>
                                          {group.user.first_name}{" "}
                                          {group.user.middle_name &&
                                            group.user.middle_name !== "null"
                                            ? group.user.middle_name + " "
                                            : ""}
                                          {group.user.last_name}{" "}
                                        </>
                                      ) : (
                                        <>
                                          {group.user.company_first_name
                                            ? `${group.user.company_first_name
                                            } ${group.user.company_last_name
                                              ? group.user
                                                .company_last_name
                                              : ""
                                            }`
                                            : `${group.user.company_name || ""
                                            }(Company)`}
                                        </>
                                      )
                                    }
                                  />
                                </span>
                              </div>
                            </div>
                          </td>
                          {(userType === userTypeConsts.SUPER_ADMIN ||
                            userType === userTypeConsts.ADMIN ||
                            userType === userTypeConsts.PROJECT_MANAGER ||
                            userType === userTypeConsts.TEAM_MANAGER) && (
                              <>
                                {group.user.is_delete ? (
                                  <td>
                                    <span className="deleted-user-message payment-status overdue">Deleted User</span>
                                  </td>
                                ) : (
                                  <>
                                    {group.entries.length === 1 && (
                                      <td>
                                        <a
                                          class="cta-link"
                                          onClick={() =>
                                            handleOpenSingleDataDetailsModal(
                                              group,
                                              groupIndex
                                            )
                                          }
                                        >
                                          Details
                                        </a>
                                      </td>
                                    )}

                                    {group.entries.length > 1 && (
                                      <td>
                                        <a
                                          class="cta-link"
                                          onClick={() =>
                                            handleOpenGroupedDataDetailsModal(
                                              group,
                                              groupIndex
                                            )
                                          }
                                        >
                                          Details
                                        </a>
                                      </td>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                        </>
                        {/* )} */}
                        {group.entries.length === 1 ? (
                          <td style={{ minWidth: "230px" }}>
                            {group.task !== null && (
                              <TruncateFieldView textContent={group.task} />
                            )}
                          </td>
                        ) : (
                          <td style={{ minWidth: "230px" }}>
                            {group.task !== null && (
                              <TruncateFieldView textContent={group.task} />
                            )}
                          </td>
                        )}
                        <td style={{ minWidth: "230px" }}>
                          <div className="extra-detail">
                            {/* {(userType === userTypeConsts.USER ||
                              userType === userTypeConsts.COLLABERATOR) &&
                              group.entries.length > 1 && (
                                <div
                                  onClick={() => toggleGroup(groupIndex)}
                                  style={{ cursor: "pointer" }}
                                  className="extra-drop"
                                >
                                  <span>
                                    {group.entries.length}{" "}
                                    {expandedGroups.includes(groupIndex) ? (
                                      <img src={TopArrow} alt="Minus" />
                                    ) : (
                                      <img src={DownArrow} alt="Plus" />
                                    )}
                                  </span>
                                </div>
                              )} */}
                            <TruncateFieldView
                              textContent={
                                group?.project?.client_name !== null &&
                                group?.project?.client_name?.client_name
                              }
                            />
                          </div>
                        </td>
                        <td style={{ minWidth: "230px" }}>
                          {group.project.project_name !== null && (
                            <TruncateFieldView
                              textContent={group.project.project_name}
                            />
                          )}
                        </td>
                        <td style={{ minWidth: "230px" }}>
                          {group?.sprint?.sprint_name && group?.sprint?.sprint_name !== null ? (
                            <TruncateFieldView
                              textContent={group.sprint.sprint_name}
                            />
                          ) : (
                            "--"
                          )}
                        </td>
                        <td style={{ minWidth: "230px" }}>
                          {group?.tag?.tag_name && group?.tag?.tag_name !== null ? (
                            <TruncateFieldView
                              textContent={group.tag.tag_name}
                            />
                          ) : (
                            "--"
                          )}
                        </td>
                        <td>{changeDurationFormat(group.totalDuration)}</td>
                        <td>
                          {new Date(group.date).toLocaleDateString("en-US", {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                          })}
                        </td>
                        <td>
                          <span
                            className={
                              group?.project?.is_billable === true ? "yes" : "no"
                            }
                          >
                            {group?.project?.is_billable ? "Yes" : "No"}
                          </span>
                        </td>
                      </tr>
                      {expandedGroups.includes(groupIndex) &&
                        group.entries.length > 1 &&
                        group.entries.map((row, index) => (
                          <tr key={`${groupIndex}-${index}`} className="member-extra" >
                            {/* {(userType === userTypeConsts.SUPER_ADMIN ||
                              userType === userTypeConsts.ADMIN ||
                              userType === userTypeConsts.PROJECT_MANAGER ||
                              userType === userTypeConsts.TEAM_MANAGER) && ( */}
                            <>
                              {(userType === userTypeConsts.SUPER_ADMIN ||
                                userType === userTypeConsts.ADMIN ||
                                userType === userTypeConsts.PROJECT_MANAGER ||
                                userType === userTypeConsts.TEAM_MANAGER) && (
                                  <td>
                                  </td>
                                )}
                              <td>
                                <div className="extra-detail">

                                  <div className="extra-drop extra-drop-hide">
                                    <span>

                                    </span>
                                  </div>

                                  <div className="member-name member-name-extra">
                                    <img src={`/uploads/lowProfileImage/${group.user && group.user.profile_image}`}
                                      alt={group.user && (group.user.first_name || group.user.company_name)}
                                      className="member-image"
                                      onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src =
                                          "/uploads/lowProfileImage/user_default.png";
                                      }}
                                    />
                                    <span>
                                      <TruncateUserName
                                        textContent={
                                          group.user.first_name ? (
                                            <>
                                              {group.user.first_name}{" "}
                                              {group.user.middle_name &&
                                                group.user.middle_name !== "null"
                                                ? group.user.middle_name + " "
                                                : ""}
                                              {group.user.last_name}{" "}
                                            </>
                                          ) : (
                                            <>
                                              {group.user.company_first_name
                                                ? `${group.user.company_first_name
                                                } ${group.user.company_last_name
                                                  ? group.user
                                                    .company_last_name
                                                  : ""
                                                }`
                                                : `${group.user.company_name || ""
                                                }(Company)`}
                                            </>
                                          )
                                        }
                                      />
                                    </span>
                                  </div>
                                </div>
                              </td>
                              {(userType === userTypeConsts.SUPER_ADMIN ||
                                userType === userTypeConsts.ADMIN ||
                                userType === userTypeConsts.PROJECT_MANAGER ||
                                userType === userTypeConsts.TEAM_MANAGER) && (
                                  <td>
                                    {row.user.is_delete ? (
                                      <span className="deleted-user-message payment-status overdue">Deleted User</span>
                                    ) : (
                                      <a
                                        class="cta-link"
                                        onClick={() =>
                                          handleOpenDetailsModal(row, index)
                                        }
                                      >
                                        Details
                                      </a>
                                    )}
                                  </td>
                                )}
                            </>
                            {/* )} */}
                            <td style={{ minWidth: "230px" }}>
                              {row.task !== null && (
                                <TruncateFieldView textContent={row.task} />
                              )}
                            </td>
                            <td>
                              {row.project.client_name !== null && (
                                <TruncateFieldView
                                  textContent={
                                    row.project.client_name !== null &&
                                    row.project.client_name.client_name
                                  }
                                />
                              )}
                            </td>
                            <td>{row.project.project_name !== null && (
                              <TruncateFieldView
                                textContent={row.project.project_name}
                              />
                            )}</td>
                            <td>
                              {row?.sprint?.sprint_name && row?.sprint?.sprint_name !== null ? (
                                <TruncateFieldView
                                  textContent={row.sprint.sprint_name}
                                />
                              ) : (
                                "--"
                              )}
                            </td>
                            <td>
                              {row?.tag?.tag_name && row?.tag?.tag_name !== null ? (
                                <TruncateFieldView
                                  textContent={row.tag.tag_name}
                                />
                              ) : (
                                "--"
                              )}
                            </td>
                            <td>{changeDurationFormat(row.duration)}</td>
                            <td>
                              {new Date(row.date).toLocaleDateString("en-US", {
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                              })}
                            </td>
                            <td>
                              <span
                                className={
                                  row.project.is_billable === true
                                    ? "yes"
                                    : "no"
                                }
                              >
                                {row.project.is_billable ? "Yes" : "No"}
                              </span>
                            </td>
                          </tr>
                        ))}
                    </React.Fragment>
                  ))
                ) : isTableLoading === true ? (
                  <tr>
                    <td colSpan="9">
                      <div className="outter-load-table">
                        <ThreeDots
                          height="80"
                          width="80"
                          radius="9"
                          color="#405BFF"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClassName=""
                          visible={true}
                        />
                      </div>
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td colSpan="9">
                      <div className="outter-load-table">No results found</div>
                    </td>
                  </tr>
                )}
              </>
            )}
          </tbody>
        </table>
      </div>
      <div className="pagination">
        {groupedData.length > perPage && (
          <ul>
            {!isFirstPage && (
              <li
                // onClick={() => {
                //   paginate(currentPage - 1);
                //   setExpandedGroups([]);
                // }}
                onClick={() => handlePageChange(currentPage - 1)}
                className="pagination-item back"
              >
                Back
              </li>
            )}
            {visiblePages.map((pageNumber, index) => (
              <li
                key={index}
                // onClick={() => {
                //   paginate(pageNumber);
                //   setExpandedGroups([]);
                // }}
                onClick={() => handlePageChange(pageNumber)}
                className={`pagination-item ${currentPage === pageNumber ? "active" : ""
                  } ${pageNumber === "..." ? "ellipsis" : ""}`}
              >
                {pageNumber === "..." ? <span>{pageNumber}</span> : pageNumber}
              </li>
            ))}
            {!isLastPage && (
              <li
                // onClick={() => {
                //   paginate(currentPage + 1);
                //   setExpandedGroups([]);
                // }}
                onClick={() => handlePageChange(currentPage + 1)}
                className="pagination-item next"
              >
                Next
              </li>
            )}
          </ul>
        )}
      </div>
    </div>
  );
};

export default TimeTable;
