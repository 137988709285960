import axios from "axios";
import moment from "moment";
import Modal from "react-modal";
import Moment from "react-moment";
import { DatePicker } from "rsuite";
import Pusher from "pusher-js";
import Col from "react-bootstrap/Col";
import ReactPaginate from "react-paginate";
import "react-toastify/dist/ReactToastify.css";
import { ThreeDots } from "react-loader-spinner";
import Spinner from "../../../Animations/Spinner.jsx";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import WeekTotalDisplay from "./helpers/WeekTotalDisplay.jsx";
import ThisWeekTotalDisplay from "./helpers/ThisWeekTotalDisplay.jsx";
import React, { useState, useRef, useEffect } from "react";
import Plus from "../../../../images/icons/circle-plus.svg";
import PlusCircle from "../../../../images/icons/blue-plus-circle.svg";
import TopArrow from "../../../../images/icons/top-small-arrow.svg";
import DownArrow from "../../../../images/icons/bottom-small-arrow.svg";
import DownloadImage from "../../../../images/icons/download-icon.svg";
import crssrImage from "../../../../images/singleinvoice/cross-red.svg";
import { motion } from "framer-motion";
import userCategory from "../../../../constants/userCategory.js";
import userTypeConsts from "../../../../constants/userTypeConsts.js";
import {
  useClientProjectDetails,
  useGroupedTimerData,
  useProjectDetails,
  useTagDetails,
  useTimerData,
  useTimeZone,
} from "./TimerDataApi.jsx";
import { checkTimer } from "./services.js";
import { setTimerData } from "../../../../features/timerData/timerDataSlice.js";
import TimerCard from "./trackerModules/Card.jsx";
import {
  setClientData,
  setClientIdData,
  setIsRunningData,
  setIsbillableData,
  setProjectData,
  setProjectIdData,
  setRunningTaskData,
  setSprintIdData,
  setSprintNameData,
  setTagData,
  setTagIdData,
  setTaskData,
  setTimeData,
} from "../../../../features/timeTrackerData/timeTrackerLogSlice.js";
import {
  formatChange,
  convertTo24HourFormat,
  convertTo12HourFormat,
  convertTo24HourFormatback,
  isEndTimeGreater,
  validateInput,
  convertTo24Hour,
  validateNoAlphabets,
} from "./services/timeFormat.js";
import TimeZoneModal from "../../../Modal/SetDefaultModal.jsx";
import { useFetchUsersTimer } from "../../TimeTrackerTeam/TimeTrackerTeamMembers/teamMemberApi.jsx";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "50%",
    transform: "translate(-50%, -50%)",
    width: "500px",
    height: "500px",
  },
};

const Tracker = (props) => {
  const [taskModalIsOpen, setTaskModalIsOpen] = useState(false);
  const [projectDropdownOpen, setProjectDropdownOpen] = useState(false);
  const [tagDropdownOpen, setTagDropdownOpen] = useState(false);
  const [projectEditDropdownOpen, setProjectEditDropdownOpen] = useState(false);
  const [tagEditDropdownOpen, setTagEditDropdownOpen] = useState(false);
  const [loadingModalIsOpen, setLoadingModalIsOpen] = useState(false);
  const [isCustomeTimeLoading, setIsCustomeTimeLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [taskName, setTaskName] = useState("");
  const [taskNameEdit, setTaskNameEdit] = useState("");
  const [taskError, setTaskError] = useState(null);
  const [projectName, setProjectName] = useState("");
  const [projectId, setProjectId] = useState("");
  const [sprintName, setSprintName] = useState("");
  const [sprintId, setSprintId] = useState("");
  const [projectIdEdit, setProjectIdEdit] = useState("");
  const [projectNameEdit, setProjectNameEdit] = useState("");
  const [sprintNameEdit, setSprintNameEdit] = useState("");
  const [sprintIdEdit, setSprintIdEdit] = useState("");
  const [selectedPage, setSelectedPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [hr, setHr] = useState(0);
  const [hrEdit, setHrEdit] = useState(0);
  const [min, setMin] = useState(0);
  const [minEdit, setMinEdit] = useState(0);
  const [sec, setSec] = useState(0);
  const [secEdit, setSecEdit] = useState(0);
  const [date, setDate] = useState(new Date());
  const [dateEdit, setDateEdit] = useState(new Date());
  const [filter, setFilter] = useState("");
  const [selectedClients, setSelectedClients] = useState(new Set());
  const [tag, setTag] = useState(null);
  const [tagId, setTagId] = useState(null);
  const [tagEdit, setTagEdit] = useState(null);
  const [tagIdEdit, setTagIdEdit] = useState(null);
  const [tagQuery, setTagQuery] = useState("");
  const [startTime, setStartTime] = useState(null);
  const [runningTask, setRunningTask] = useState(null);
  const [stopRunning, setStopRunning] = useState(null);
  const [timerUpdate, setTimerUpdate] = useState(null);
  const [customeTime, setCustomeTime] = useState(false);
  const [customeStartTime, setCustomeStartTime] = useState(new Date());
  const [customeEndTime, setCustomeEndTime] = useState(new Date());
  const [isRunning, setIsRunning] = useState(false);
  const [editedId, setEditedId] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [projectValidation, setProjectValidation] = useState(false);
  const [projectStatusFilter, setProjectStatusFilter] = useState("Active");
  const [isProjectStatusDropdownOpen, setIsProjectStatusDropdownOpen] = useState(false);
  const [stopLoading, setStopLoading] = useState(false);
  // edit time section
  const [uStartTimeError, setUStartTimeError] = useState(false);
  const [uManualStartTimeError, setUManualStartTimeError] = useState(false);
  const [uEndTimeError, setUEndTimeError] = useState(false);
  const [uManualEndTimeError, setUManualEndTimeError] = useState(false);
  const [uDuration, setUDuration] = useState(null);
  const [uFormatedTime, setUFormatedTime] = useState(0);
  const [uManualFormatedTimeError, setUManualFormatedTimeError] =
    useState(false);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  // endit time section

  const [showTimeZoneModal, setShowTimeZoneModal] = useState(false);
  var zoneChangeConfirm = false;
  zoneChangeConfirm = localStorage.getItem("zoneChangeConfirm");

  const [client, setClient] = useState(null);
  const [clientId, setClientId] = useState(null);
  const [isBillable, setIsBillable] = useState(null);
  const [startTimeEdit, setStartTimeEdit] = useState(""); // Initialize with appropriate initial value
  const [endTimeEdit, setEndTimeEdit] = useState(""); // Initialize with appropriate initial value
  const datePickerRef = useRef();
  const [dateOldEdit, setDateOldEdit] = useState(null);
  const dispatch = useDispatch();
  const [filteredData, setFilteredData] = useState([]);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [mergeVisible, setMergeVisible] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [resumeLoading, setResumeLoading] = useState(false);

  const trackerOuterRef1 = useRef(null);
  const trackerOuterRef = useRef(null);
  const autoFillDropdownRef = useRef(null);
  const [showingDropdownOpen, setShowingDropdownOpen] = useState(false);
  const showingDropdownRef = useRef(null);
  const projectDropdownRef = useRef(null);
  const tagDropdownRef = useRef(null);
  const trackerOuterRef2 = useRef(null);
  const startInputRef = useRef(null);
  const endInputRef = useRef(null);
  const start12HrTimeInputRef = useRef(null);
  const end12HrTimeInputRef = useRef(null);
  const projectStatusDropdownRef = useRef(null);
  const [letterCount, setLetterCount] = useState(0);
  const { appSettings } = useSelector((state) => state.appSettings);

  const timerRedux = useSelector((state) => state.timeTrackerLog);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [checkTimerStatus, setCheckTimerStatus] = useState(false);
  const userDropdownRef = useRef(null);

  const [userStatusFilter, setUserStatusFilter] = useState("Active");
  const [isUserStatusDropdownOpen, setIsUserStatusDropdownOpen] = useState(false);
  const userStatusDropdownRef = useRef(null);
  const [userSearchTerm, setUserSearchTerm] = useState('');

  const token = useSelector((state) => state.auth.token);
  const user = useSelector((state) => state.auth.user);
  const timerData = useSelector((state) => state.timerData.timerDataState);
  const timeTrackerLog = useSelector((state) => state.timeTrackerLog);

  const [selectedUser, setSelectedUser] = useState(user._id);
  const [isUserDropdownOpen, setIsUserDropdownOpen] = useState(false);

  const [bulkImportFile, setBulkImportFile] = useState(null);
  const [isBulkImportLoading, setIsBulkImportLoading] = useState(false);
  const [bulkImportFileName, setBulkImportFileName] = useState("");
  const [isBulkImportModalOpen, setIsBulkImportModalOpen] = useState(false);
  const [trigger, setTrigger] = useState(false);

  // useEffect(() => {
  //   const handleResize = () => {
  //     setWindowWidth(window.innerWidth);
  //   };
  //   window.addEventListener("resize", handleResize);
  //   setEndTimeManual24("09:30");

  //   // Cleanup function to remove the event listener when component unmounts
  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Use a debounced function to optimize resize event handling
    const debouncedResize = debounce(handleResize, 300);

    window.addEventListener("resize", debouncedResize);

    // Perform initial state setup outside the resize logic
    setEndTimeManual24("09:30");

    // Cleanup function to remove the event listener when component unmounts
    return () => {
      window.removeEventListener("resize", debouncedResize);
    };
  }, []);

  // Debounce utility function to reduce frequent calls
  function debounce(func, delay) {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), delay);
    };
  }

  // Use useEffect to check the height and update the state accordingly
  // useEffect(() => {
  //   const trackerOuterElement = trackerOuterRef.current;
  //   if (trackerOuterElement) {
  //     const height = trackerOuterElement.offsetHeight;
  //     // Update the state based on the height condition
  //     setIsActive(height > 21);
  //   }
  // });


  useEffect(() => {
    const updateHeightStatus = () => {
      const trackerOuterElement = trackerOuterRef.current;
      if (trackerOuterElement) {
        const height = trackerOuterElement.offsetHeight;
        // Update the state based on the height condition
        setIsActive(height > 21);
      }
    };

    // Execute the function initially
    updateHeightStatus();

    // Optionally, you could add an observer if height changes dynamically
    const resizeObserver = new ResizeObserver(() => {
      updateHeightStatus();
    });

    if (trackerOuterRef.current) {
      resizeObserver.observe(trackerOuterRef.current);
    }

    // Cleanup observer on unmount
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  // Use useEffect to check the height and update the state accordingly
  // useEffect(() => {
  //   const trackerOuterElement = trackerOuterRef1.current;
  //   if (trackerOuterElement) {
  //     const height = trackerOuterElement.offsetHeight;
  //     // Update the state based on the height condition
  //     setIsActive(height > 21);
  //   }
  // });

  useEffect(() => {
    const updateHeightStatus = () => {
      const trackerOuterElement = trackerOuterRef1.current;
      if (trackerOuterElement) {
        const height = trackerOuterElement.offsetHeight;
        // Update the state based on the height condition
        setIsActive(height > 21);
      }
    };

    // Execute the function initially
    updateHeightStatus();

    // Optionally, you could add an observer if height changes dynamically
    const resizeObserver = new ResizeObserver(() => {
      updateHeightStatus();
    });

    if (trackerOuterRef1.current) {
      resizeObserver.observe(trackerOuterRef1.current);
    }

    // Cleanup observer on unmount
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const handleDateIconClick = () => {
    datePickerRef.current.open(); // Open the date picker programmatically
  };

  const { data: tagOptions = [], isLoading: isLoadingTagDetails } =
    useTagDetails(token);
  const { data: projectOptions = [], isLoading: isLoadingProjectDetails } =
    useProjectDetails(token);
  const { data: clientData = [], isLoading: isLoadingClientProjectDetails } =
    useClientProjectDetails(token);
  const {
    data: groupedTimerData = [],
    isLoading: isGroupedTimerDataLoading,
    refetch: refetchGroupedTimerData,
  } = useGroupedTimerData(token, selectedUser);
  const {
    data: timer = [],
    isLoading: isTimerDataLoading,
    refetch: refetchTimerData,
  } = useTimerData(token, selectedUser);

  const {
    data: timeZoneData = [],
    isLoading: isTimeZoneDataLoading,
    refetch: refetchTimeZoneDatas,
    isError: timeZoneDataError,
  } = useTimeZone(user && user._id, token);
  const { data: userProfiles = [], isLoading: isUserLoading } = useFetchUsersTimer(token);

  const toggleUserStatusDropdown = () => {
    setIsUserStatusDropdownOpen(!isUserStatusDropdownOpen);
    setUserSearchTerm("");
  };

  const handleUserStatusChange = (status) => {
    setUserStatusFilter(status);
    // setIsUserStatusDropdownOpen(false);
  };

  const filteredUsers = userProfiles.filter((user) => {
    const fullName = user.first_name
      ? `${user.first_name} ${user.middle_name || ''} ${user.last_name}`
      : `${user.company_first_name ? user.company_first_name + (user.company_last_name && user.company_last_name) : user.company_name}`;
    return fullName.toLowerCase().includes(userSearchTerm.toLowerCase());
  });

  const handleUserSearchChange = (e) => {
    setUserSearchTerm(e.target.value);
  };

  // useEffect(() => {
  //   // Calculate word count when the component mounts or when content changes
  //   const content = trackerOuterRef2?.current?.innerText;
  //   const count = content && content.replace(/\s/g, "").length; // Remove whitespaces before counting
  //   setLetterCount(count ? count : 0);
  // });


  useEffect(() => {
    const updateLetterCount = () => {
      const content = trackerOuterRef2?.current?.innerText;
      const count = content && content.replace(/\s/g, "").length; // Remove whitespaces before counting
      setLetterCount(count ? count : 0);
    };

    // Call the function initially
    updateLetterCount();

    // Using setTimeout to delay the state update for non-blocking behavior
    const timeoutId = setTimeout(() => {
      updateLetterCount();
    }, 0); // Delay with 0ms to allow other operations to run

    // Cleanup function to clear timeout on unmount
    return () => {
      clearTimeout(timeoutId);
    };
  }, []); // Empty dependency array to only run once on mount



  // useEffect(() => {
  //   refetchTimerData();
  //   refetchGroupedTimerData();
  // }, [selectedUser]);

  useEffect(() => {
    const fetchData = async () => {
      // Wrap both API calls in a promise to make them non-blocking
      try {
        await Promise.all([
          refetchTimerData(),
          refetchGroupedTimerData()
        ]);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [selectedUser]);

  // useEffect(() => {
  //   if (user && !isTimeZoneDataLoading) {
  //     if (timeZoneData === null) {
  //       setShowTimeZoneModal(true);
  //     }
  //     if (
  //       timeZoneData &&
  //       (timeZoneData.currency === null || timeZoneData.timeZone === null)
  //     ) {
  //       setShowTimeZoneModal(true);
  //     }
  //   }

  //   refetchTimeZoneDatas();
  // }, [user, token, timerData]);

  // useEffect(() => {
  //   const checkAndFetchTimeZoneData = async () => {
  //     // Check if the user is available and time zone data is not loading
  //     if (user && !isTimeZoneDataLoading) {
  //       // Show the modal if time zone data is not set
  //       if (timeZoneData === null || timeZoneData.currency === null || timeZoneData.timeZone === null) {
  //         setShowTimeZoneModal(true);
  //       }
  //     }

  //     // Non-blocking call to refetch time zone data
  //     try {
  //       await refetchTimeZoneDatas();
  //     } catch (error) {
  //       console.error('Error refetching time zone data:', error);
  //     }
  //   };

  //   // Call the asynchronous function
  //   checkAndFetchTimeZoneData();
  // }, [user, token, timerData]); 

  useEffect(() => {
    const checkAndFetchTimeZoneData = async () => {
      // Check if the user is available and time zone data is not loading
      if (user && !isTimeZoneDataLoading) {
        // Show the modal if time zone data is not set
        if (timeZoneData === null || timeZoneData.currency === null || timeZoneData.timeZone === null) {
          setShowTimeZoneModal(true);
        }
      }

      // Non-blocking call to refetch time zone data
      try {
        await refetchTimeZoneDatas();
      } catch (error) {
        console.error('Error refetching time zone data:', error);
      }
    };

    // Use setTimeout to ensure the refetch happens asynchronously
    setTimeout(() => {
      checkAndFetchTimeZoneData();
    }, 0); // Deferred execution to allow the main thread to continue

  }, [user, token, timerData]);  // Dependencies to trigger the effect when user, token, or timerData change

  const filteredTagItems = tagOptions
    .filter((item) =>
      item.tag_name.toLowerCase().includes(tagQuery.toLowerCase())
    )
    .sort((a, b) => a.tag_name.localeCompare(b.tag_name));

  // const filteredClientData = clientData
  //   .filter(
  //     (client) =>
  //       client.client_name.toLowerCase().includes(filter.toLowerCase()) ||
  //       client.projects.some((project) =>
  //         project.project_name.toLowerCase().includes(filter.toLowerCase()) &&
  //         (projectStatusFilter === "All"
  //           ? true
  //           : projectStatusFilter === "Active"
  //             ? project.is_active === true
  //             : project.is_active === false)
  //       )
  //   )
  //   .sort((a, b) => a.client_name.localeCompare(b.client_name));

  const filteredClientData = clientData
    .filter((client) => {
      const clientMatches = client.client_name
        .toLowerCase()
        .includes(filter.toLowerCase());

      const filteredProjects = client.projects.filter(
        (project) =>
          project.project_name
            .toLowerCase()
            .includes(filter.toLowerCase()) &&
          (projectStatusFilter === "All"
            ? true
            : projectStatusFilter === "Active"
              ? project.is_active === true
              : project.is_active === false)
      );

      return filteredProjects.length > 0;
    })
    .sort((a, b) => a.client_name.localeCompare(b.client_name));

  const handleProjectStatusChange = (status) => {
    setProjectStatusFilter(status);
    setSelectedClients(new Set());
  };

  const toggleProjectStatusDropdown = () => {
    setIsProjectStatusDropdownOpen(!isProjectStatusDropdownOpen);
  };

  const handleTagInputChange = (event) => {
    setTagQuery(event.target.value);
    setTag(event.target.value);
    setTagIdEdit(event.target.value);
  };

  const handleInputClick = () => {
    setDropdownVisible(!dropdownVisible);
  };

  // useEffect(() => {
  //   // global.config.activityLog(
  //   //   window.location.href,
  //   //   "Timer",
  //   //   `User entered into Timer`
  //   // );
  //   var gettime =
  //     timeTrackerLog && timeTrackerLog.time
  //       ? timeTrackerLog.time
  //       : localStorage.getItem("time") !== "undefined"
  //         ? JSON.parse(localStorage.getItem("time"))
  //         : null;
  //   var Running =
  //     localStorage.getItem("isRunning") !== "undefined"
  //       ? JSON.parse(localStorage.getItem("isRunning"))
  //       : null;
  //   var getTask =
  //     timeTrackerLog && timeTrackerLog.task
  //       ? timeTrackerLog.task
  //       : localStorage.getItem("task") !== "undefined"
  //         ? JSON.parse(localStorage.getItem("task"))
  //         : null;
  //   var getTag =
  //     timeTrackerLog && timeTrackerLog.tag
  //       ? timeTrackerLog.tag
  //       : localStorage.getItem("tag") !== "undefined"
  //         ? JSON.parse(localStorage.getItem("tag"))
  //         : null;
  //   var getTagId =
  //     timeTrackerLog && timeTrackerLog.tagId
  //       ? timeTrackerLog.tagId
  //       : localStorage.getItem("tagId") !== "undefined"
  //         ? JSON.parse(localStorage.getItem("tagId"))
  //         : null;
  //   var getProject =
  //     timeTrackerLog && timeTrackerLog.project
  //       ? timeTrackerLog.project
  //       : localStorage.getItem("project") !== "undefined"
  //         ? JSON.parse(localStorage.getItem("project"))
  //         : null;
  //   var getProjectId =
  //     timeTrackerLog && timeTrackerLog.projectId
  //       ? timeTrackerLog.projectId
  //       : localStorage.getItem("projectId") !== "undefined"
  //         ? JSON.parse(localStorage.getItem("projectId"))
  //         : null;
  //   var getSprintName =
  //     timeTrackerLog && timeTrackerLog.sprintName
  //       ? timeTrackerLog.sprintName
  //       : localStorage.getItem("sprintName") !== "undefined"
  //         ? JSON.parse(localStorage.getItem("sprintName"))
  //         : null;
  //   var getSprintId =
  //     timeTrackerLog && timeTrackerLog.sprintId
  //       ? timeTrackerLog.sprintId
  //       : localStorage.getItem("sprintId") !== "undefined"
  //         ? JSON.parse(localStorage.getItem("sprintId"))
  //         : null;
  //   var isBillable =
  //     timeTrackerLog && timeTrackerLog.isbillable
  //       ? timeTrackerLog.isbillable
  //       : localStorage.getItem("isbillable") !== "undefined"
  //         ? JSON.parse(localStorage.getItem("isbillable"))
  //         : null;
  //   var client =
  //     timeTrackerLog && timeTrackerLog.client
  //       ? timeTrackerLog.client
  //       : localStorage.getItem("client") !== "undefined"
  //         ? JSON.parse(localStorage.getItem("client"))
  //         : null;
  //   var clientId =
  //     timeTrackerLog && timeTrackerLog.clientId
  //       ? timeTrackerLog.clientId
  //       : localStorage.getItem("clientId") !== "undefined"
  //         ? JSON.parse(localStorage.getItem("clientId"))
  //         : null;
  //   var getRunningTask =
  //     timeTrackerLog && timeTrackerLog.runningTask
  //       ? timeTrackerLog.runningTask
  //       : localStorage.getItem("runningTask") !== "undefined"
  //         ? JSON.parse(localStorage.getItem("runningTask"))
  //         : null;
  //   setEditedId(null);
  //   setTaskNameEdit("");
  //   setProjectIdEdit(null);
  //   setProjectNameEdit(null);
  //   setSprintIdEdit("");
  //   setSprintNameEdit("");
  //   setHrEdit(null);
  //   setMinEdit(0);
  //   setSecEdit(0);
  //   setDateEdit(new Date());
  //   setTag(null);
  //   setTagId(null);
  //   setTagIdEdit(null);
  //   setTagEdit(null);
  //   setTaskName("");
  //   if (gettime) {
  //     setStartTime(gettime);
  //   }
  //   if (Running) {
  //     setIsRunning(Running);
  //   }
  //   if (getTask) {
  //     setTaskName(getTask);
  //   } else {
  //     setTaskName(null);
  //   }
  //   if (getProject) {
  //     setProjectName(getProject);
  //   } else {
  //     setProjectName(null);
  //   }
  //   if (getRunningTask) {
  //     setRunningTask(getRunningTask);
  //   }
  //   if (getTag) {
  //     setTag(getTag);
  //   }
  //   if (getTagId) {
  //     setTagId(getTagId);
  //   }
  //   if (client) {
  //     setClient(client);
  //   }
  //   if (clientId) {
  //     setClientId(clientId);
  //   }
  //   if (getProjectId) {
  //     setProjectId(getProjectId);
  //   }
  //   if (getSprintName) {
  //     setSprintName(getSprintName);
  //   } else {
  //     setSprintName("");
  //   }
  //   if (getSprintId) {
  //     setSprintId(getSprintId);
  //   } else {
  //     setSprintId("");
  //   }
  //   if (isBillable) {
  //     setIsBillable(isBillable);
  //   }
  //   refetchGroupedTimerData();
  //   Modal.setAppElement("body");
  //   refetchTimerData();
  //   const handleKeyDown = (event) => {
  //     if (event.key === "Escape") {
  //       document.activeElement.blur();
  //       setEditedId(null);
  //       setProjectDropdownOpen(false);
  //       setTagDropdownOpen(false);
  //       setProjectEditDropdownOpen(false);
  //       setTagEditDropdownOpen(false);
  //       setCustomeTime(false);
  //       setUEndTimeError(false);
  //       setUStartTimeError(false);
  //       setUFormatedTime(false);
  //       setDropdownVisible(false);
  //     }
  //   };
  //   document.addEventListener("keydown", handleKeyDown);
  //   return () => {
  //     document.removeEventListener("keydown", handleKeyDown);
  //   };
  // }, [stopRunning, timerUpdate, timerData, timeTrackerLog]);


  useEffect(() => {
    // Initialize non-blocking fetches
    const initializeData = async () => {
      // Fetch or initialize values from localStorage or timeTrackerLog
      var gettime = timeTrackerLog?.time || (localStorage.getItem("time") !== "undefined" ? JSON.parse(localStorage.getItem("time")) : null);
      var Running = localStorage.getItem("isRunning") !== "undefined" ? JSON.parse(localStorage.getItem("isRunning")) : null;
      var getTask = timeTrackerLog?.task || (localStorage.getItem("task") !== "undefined" ? JSON.parse(localStorage.getItem("task")) : null);
      var getTag = timeTrackerLog?.tag || (localStorage.getItem("tag") !== "undefined" ? JSON.parse(localStorage.getItem("tag")) : null);
      var getTagId = timeTrackerLog?.tagId || (localStorage.getItem("tagId") !== "undefined" ? JSON.parse(localStorage.getItem("tagId")) : null);
      var getProject = timeTrackerLog?.project || (localStorage.getItem("project") !== "undefined" ? JSON.parse(localStorage.getItem("project")) : null);
      var getProjectId = timeTrackerLog?.projectId || (localStorage.getItem("projectId") !== "undefined" ? JSON.parse(localStorage.getItem("projectId")) : null);
      var getSprintName = timeTrackerLog?.sprintName || (localStorage.getItem("sprintName") !== "undefined" ? JSON.parse(localStorage.getItem("sprintName")) : null);
      var getSprintId = timeTrackerLog?.sprintId || (localStorage.getItem("sprintId") !== "undefined" ? JSON.parse(localStorage.getItem("sprintId")) : null);
      var isBillable = timeTrackerLog?.isbillable || (localStorage.getItem("isbillable") !== "undefined" ? JSON.parse(localStorage.getItem("isbillable")) : null);
      var client = timeTrackerLog?.client || (localStorage.getItem("client") !== "undefined" ? JSON.parse(localStorage.getItem("client")) : null);
      var clientId = timeTrackerLog?.clientId || (localStorage.getItem("clientId") !== "undefined" ? JSON.parse(localStorage.getItem("clientId")) : null);
      var getRunningTask = timeTrackerLog?.runningTask || (localStorage.getItem("runningTask") !== "undefined" ? JSON.parse(localStorage.getItem("runningTask")) : null);

      // Update state based on fetched values
      setEditedId(null);
      setTaskNameEdit("");
      setProjectIdEdit(null);
      setProjectNameEdit(null);
      setSprintIdEdit("");
      setSprintNameEdit("");
      setHrEdit(null);
      setMinEdit(0);
      setSecEdit(0);
      setDateEdit(new Date());
      setTag(null);
      setTagId(null);
      setTagIdEdit(null);
      setTagEdit(null);
      setTaskName("");
      if (gettime) setStartTime(gettime);
      if (Running) setIsRunning(Running);
      if (getTask) setTaskName(getTask);
      else setTaskName(null);
      if (getProject) setProjectName(getProject);
      else setProjectName(null);
      if (getRunningTask) setRunningTask(getRunningTask);
      if (getTag) setTag(getTag);
      if (getTagId) setTagId(getTagId);
      if (client) setClient(client);
      if (clientId) setClientId(clientId);
      if (getProjectId) setProjectId(getProjectId);
      if (getSprintName) setSprintName(getSprintName);
      else setSprintName("");
      if (getSprintId) setSprintId(getSprintId);
      else setSprintId("");
      if (isBillable) setIsBillable(isBillable);

      // Refetch grouped timer data asynchronously
      try {
        refetchGroupedTimerData();
      } catch (error) {
        console.error('Error refetching grouped timer data:', error);
      }

      // Refetch timer data asynchronously
      try {
        refetchTimerData();
      } catch (error) {
        console.error('Error refetching timer data:', error);
      }
    };

    // Delay the initialization to allow for other synchronous code to run first
    setTimeout(() => {
      initializeData();
    }, 0);

    // Handle keydown event non-blockingly
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        document.activeElement.blur();
        setEditedId(null);
        setProjectDropdownOpen(false);
        setTagDropdownOpen(false);
        setProjectEditDropdownOpen(false);
        setTagEditDropdownOpen(false);
        setCustomeTime(false);
        setUEndTimeError(false);
        setUStartTimeError(false);
        setUFormatedTime(false);
        setDropdownVisible(false);
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [stopRunning, timerUpdate, timerData, timeTrackerLog]);

  const handleStartClick = () => {
    setStopLoading(false);
    setTrigger(true);
    var time = moment()
      .tz(global.config.getTimeZone())
      .format("YYYY-MM-DD HH:mm:ss");

    const now = moment
      .tz(new Date(), "YYYY-MM-DD HH:mm:ss", global.config.getTimeZone())
      .utc()
      .format();
    if (taskName && projectName && projectId && client) {
      setTaskError(false);
      setProjectValidation("");
      setIsRunning(true);
      setStartTime(time);
      localStorage.setItem("startTime", JSON.stringify(time));
      dispatch(setTimeData(time));
      localStorage.setItem("isRunning", JSON.stringify(true));
      dispatch(setIsRunningData(true));
      localStorage.setItem("task", JSON.stringify(taskName));
      dispatch(setTaskData(taskName));
      localStorage.setItem("project", JSON.stringify(projectName));
      dispatch(setProjectData(projectName));
      localStorage.setItem("projectId", JSON.stringify(projectId));
      dispatch(setProjectIdData(projectId));
      localStorage.setItem("tag", JSON.stringify(tag));
      dispatch(setTagData(tag));
      localStorage.setItem("tagId", JSON.stringify(tagId));
      dispatch(setTagIdData(tagId));
      localStorage.setItem("client", JSON.stringify(client));
      dispatch(setClientData(client));
      localStorage.setItem("clientId", JSON.stringify(clientId));
      dispatch(setClientIdData(clientId));
      localStorage.setItem("isBillable", JSON.stringify(isBillable));
      dispatch(setIsbillableData(isBillable));
      if (sprintId) {
        localStorage.setItem("sprintName", JSON.stringify(sprintName));
        dispatch(setSprintNameData(sprintName));
        localStorage.setItem("sprintId", JSON.stringify(sprintId));
        dispatch(setSprintIdData(sprintId));
      }

      const postData = {
        task: taskName,
        projectName: projectName,
        project: projectId,
        tagName: tag,
        tag: tagId,
        startTime: time,
        user: selectedUser,
        client: client,
        clientId: clientId,
        billable: isBillable,
        tz: global.config.getTimeZone(),
        date: time,
      };
      if (sprintId) {
        postData.sprintName = sprintName;
        postData.sprint = sprintId;
      }
      axios
        .post("/api/time-track/start-timer", postData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setRunningTask(response.data.id);
          localStorage.setItem("runningTask", JSON.stringify(response.data.id));
          dispatch(setRunningTaskData(response.data.id));
        });
      // global.config.activityLog(
      //   window.location.href,
      //   "Timer",
      //   `Time started for (${projectId}) project`
      // );
      dispatch(setTimerData(Date.now()));
      toast.success("Timer started", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setTaskError(true);
      setProjectValidation("red");
      var taskErr = "";
      var projectErr = "";
      var tagErr = "";
      if (!taskName) {
        taskErr = " 'Task' ";
      }
      if (!projectName) {
        projectErr = " 'Project' ";
      }

      toast(`Please select ${taskErr} ${projectErr} ${tagErr}`, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    setTrigger(false);
  };

  const handleEndClick = () => {

    try {
      var localTime = moment()
        .tz(global.config.getTimeZone())
        .format("YYYY-MM-DD HH:mm:ss");

      // const runTask = timerRedux.runningTask ? timerRedux.runningTask :
      //   localStorage.getItem("runningTask") !== "undefined"
      //     ? JSON.parse(localStorage.getItem("runningTask"))
      //     : null;
      const runTask = timerRedux.runningTask ? timerRedux.runningTask : "";

      setStopLoading(true);
      const postData = {
        id: runTask,
        endTime: localTime,
        user: user._id,
        task: taskName,
        projectName: projectName,
        project: projectId,
        tagName: tag,
        tag: tagId,
        client: client,
        clientId: clientId,
        billable: isBillable,
        tz: global.config.getTimeZone(),
        date: localTime,
      };

      if (sprintId) {
        postData.sprintName = sprintName;
        postData.sprint = sprintId;
      }

      axios
        .post("/api/time-track/stop-timer", postData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setIsRunning(false);
          setStopLoading(false);
          setTaskName("");
          setTag(null);
          setTagId(null);
          setProjectName(null);
          setProjectId(null);
          setRunningTask(null);
          setSprintName("");
          setSprintId("");
          localStorage.removeItem("startTime");
          dispatch(setTimeData(null));
          localStorage.removeItem("project");
          dispatch(setProjectData(null));
          localStorage.removeItem("task");
          dispatch(setTaskData(null));
          localStorage.removeItem("isRunning");
          dispatch(setIsRunningData(false));
          localStorage.removeItem("runningTask");
          dispatch(setRunningTaskData(null));
          localStorage.removeItem("tag");
          dispatch(setTagData(null));
          localStorage.removeItem("tagId");
          dispatch(setTagIdData(null));
          localStorage.removeItem("projectId");
          dispatch(setProjectIdData(null));
          localStorage.removeItem("sprintName");
          dispatch(setSprintNameData(null));
          localStorage.removeItem("sprintId");
          dispatch(setSprintIdData(null));
          localStorage.removeItem("client");
          dispatch(setClientData(null));
          localStorage.removeItem("clientId");
          dispatch(setClientIdData(null));
          localStorage.removeItem("isBillable");
          dispatch(setIsbillableData(false));
          dispatch(setTimerData(Date.now()));
          setStopRunning((prevState) => !prevState);
          refetchTimerData();
        })
        .catch((error) => {
          console.log(error);
          global.config.slackMessage(error.toString());
        });


    } catch (err) {
      console.log(err);
    }


  };

  const resumeTimeTracking = (
    task,
    project_id,
    projectName,
    tag_name,
    tag_id,
    client,
    clientId,
    date,
    startTime,
    dupendTime,
    duration,
    sprint_name,
    sprint_id,
    _id,
    billable
  ) => {

    try {
      setTrigger(true);
      if (isRunning) {
        setResumeLoading(true);
        var localTime = moment()
          .tz(global.config.getTimeZone())
          .format("YYYY-MM-DD HH:mm:ss");
        // const runTask =
        //   localStorage.getItem("runningTask") !== "undefined"
        //     ? JSON.parse(localStorage.getItem("runningTask"))
        //     : null;

        const runTask = timerRedux.runningTask ? timerRedux.runningTask :
          localStorage.getItem("runningTask") !== "undefined"
            ? JSON.parse(localStorage.getItem("runningTask"))
            : null;
        setStopLoading(true);
        const postData = {
          id: runTask,
          endTime: localTime,
          user: selectedUser,
          task: taskName,
          projectName: projectName,
          project: projectId,
          tagName: tag_name,
          tag: tagId,
          client: client,
          clientId: clientId,
          sprintName: sprint_name,
          billable: isBillable,
          tz: global.config.getTimeZone(),
          date: localTime,
        };

        if (sprint_id) {
          postData.sprintName = sprint_name;
          postData.sprint = sprint_id;
        }
        axios
          .post("/api/time-track/stop-timer", postData, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            setIsRunning(false);
            setStopLoading(false);
            setTaskName("");
            setTag(null);
            setTagId(null);
            setProjectName(null);
            setProjectId(null);
            setRunningTask(null);
            setSprintName("");
            setSprintId("");
            localStorage.removeItem("startTime");
            dispatch(setTimeData(null));
            localStorage.removeItem("project");
            dispatch(setProjectData(null));
            localStorage.removeItem("task");
            dispatch(setTaskData(null));
            localStorage.removeItem("isRunning");
            dispatch(setIsRunningData(false));
            localStorage.removeItem("runningTask");
            dispatch(setRunningTaskData(null));
            localStorage.removeItem("tag");
            dispatch(setTagData(null));
            localStorage.removeItem("tagId");
            dispatch(setTagIdData(null));
            localStorage.removeItem("projectId");
            dispatch(setProjectIdData(null));
            localStorage.removeItem("sprintName");
            dispatch(setSprintNameData(null));
            localStorage.removeItem("sprintId");
            dispatch(setSprintIdData(null));
            localStorage.removeItem("client");
            dispatch(setClientData(null));
            localStorage.removeItem("clientId");
            dispatch(setClientIdData(null));
            localStorage.removeItem("isBillable");
            dispatch(setIsbillableData(false));
            dispatch(setTimerData(Date.now()));
            refetchTimerData();

            // start new
            var time = moment().tz(global.config.getTimeZone()).utc().format();
            // time = time.format();
            setTaskName(task);
            setProjectName(projectName);
            setProjectId(project_id);
            setTag(tag_name);
            setTagId(tag_id);
            setSprintName(sprint_name);
            setSprintId(sprint_id);
            setClient(client);
            setClientId(clientId);
            setIsBillable(billable);
            setResumeLoading(true);

            if (task && projectName && project_id && client && clientId) {
              setTaskError(false);
              setProjectValidation("");
              setIsRunning(true);
              setStartTime(time);
              localStorage.setItem("startTime", JSON.stringify(time));
              dispatch(setTimeData(time));
              localStorage.setItem("isRunning", JSON.stringify(true));
              dispatch(setIsRunningData(true));
              localStorage.setItem("task", JSON.stringify(task));
              dispatch(setTaskData(task));
              localStorage.setItem("project", JSON.stringify(projectName));
              dispatch(setProjectData(projectName));
              localStorage.setItem("projectId", JSON.stringify(project_id));
              dispatch(setProjectIdData(project_id));
              localStorage.setItem("tag", JSON.stringify(tag_name));
              dispatch(setTagData(tag_name));
              localStorage.setItem("tagId", JSON.stringify(tag_id));
              dispatch(setTagIdData(tag_id));
              localStorage.setItem("client", JSON.stringify(client));
              dispatch(setClientData(client));
              localStorage.setItem("clientId", JSON.stringify(clientId));
              dispatch(setClientIdData(clientId));
              localStorage.setItem("isbillable", JSON.stringify(billable));
              dispatch(setIsbillableData(billable));
              localStorage.setItem("billable", JSON.stringify(billable));
              if (sprint_id) {
                localStorage.setItem("sprintName", JSON.stringify(sprint_name));
                dispatch(setSprintNameData(sprint_name));
                localStorage.setItem("sprintId", JSON.stringify(sprintId));
                dispatch(setSprintIdData(sprintId));
              }

              const postData = {
                task: task,
                projectName: projectName,
                project: project_id,
                tagName: tag_name,
                tag: tag_id,
                startTime: time,
                user: selectedUser,
                client: client,
                clientId: clientId,
                billable: billable,
                sprint: null,
              };
              if (sprint_id) {
                postData.sprintName = sprintName;
                postData.sprint = sprint_id;
              }

              axios
                .post("/api/time-track/start-timer", postData, {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                })
                .then((response) => {
                  dispatch(setTimerData(Date.now()));
                  setRunningTask(response.data.id);
                  localStorage.setItem(
                    "runningTask",
                    JSON.stringify(response.data.id)
                  );
                  dispatch(setRunningTaskData(response.data.id));
                })
                .catch((error) => {
                  // Handle error
                  global.config.slackMessage(error.toString());
                })
                .finally(() => {
                  setResumeLoading(false); // Set loading to false after the request is completed
                });
            } else {
              setResumeLoading(false);
              setTaskError(true);
              setProjectValidation("red");
              var taskErr = "";
              var projectErr = "";
              var tagErr = "";
              if (!taskName) {
                taskErr = " 'Task' ";
              }
              if (!projectName) {
                projectErr = " 'Project' ";
              }
              if (!tag) {
                tagErr = " 'Tag' ";
              }
              toast(`Please select ${taskErr} ${projectErr} ${tagErr}`, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          })
          .catch((error) => {
            console.log(error);
            global.config.slackMessage(error.toString());
          });
      } else {

        setTrigger(true);
        var time = moment().tz(global.config.getTimeZone()).utc().format();

        setTaskName(task);
        setProjectName(projectName);
        setProjectId(project_id);
        setTag(tag_name);
        setTagId(tag_id);
        setSprintName(sprint_name);
        setSprintId(sprint_id);
        setClient(client);
        setClient(clientId);
        setIsBillable(billable);
        setResumeLoading(true);

        if (task && projectName && project_id && client && clientId) {
          setTaskError(false);
          setProjectValidation("");
          setIsRunning(true);
          setStartTime(time);
          localStorage.setItem("startTime", JSON.stringify(time));
          dispatch(setTimeData(time));
          setIsRunning(true);
          localStorage.setItem("isRunning", JSON.stringify(true));
          dispatch(setIsRunningData(true));
          setTaskName(task);
          localStorage.setItem("task", JSON.stringify(task));
          dispatch(setTaskData(task));
          setProjectName(projectName);
          localStorage.setItem("project", JSON.stringify(projectName));
          dispatch(setProjectData(projectName));
          setProjectId(project_id);
          localStorage.setItem("projectId", JSON.stringify(project_id));
          dispatch(setProjectIdData(project_id));
          setTag(tag_name);
          localStorage.setItem("tag", JSON.stringify(tag_name));
          dispatch(setTagData(tag_name));
          setTagId(tag_id);
          localStorage.setItem("tagId", JSON.stringify(tag_id));
          dispatch(setTagIdData(tag_id));
          setClient(client);
          localStorage.setItem("client", JSON.stringify(client));
          dispatch(setClientData(client));
          setClientId(clientId);
          localStorage.setItem("clientId", JSON.stringify(clientId));
          dispatch(setClientIdData(clientId));
          setIsBillable(billable);
          localStorage.setItem("isbillable", JSON.stringify(billable));
          dispatch(setIsbillableData(billable));
          localStorage.setItem("billable", JSON.stringify(billable));
          if (sprint_id) {
            setSprintName(sprint_name);
            localStorage.setItem("sprintName", JSON.stringify(sprint_name));
            dispatch(setSprintNameData(sprint_name));
            setSprintId(sprint_id);
            localStorage.setItem("sprintId", JSON.stringify(sprint_id));
            dispatch(setSprintIdData(sprint_id));
          }

          const postData = {
            task: task,
            projectName: projectName,
            project: project_id,
            tagName: tag_name,
            tag: tag_id,
            startTime: time,
            user: selectedUser,
            client: client,
            clientId: clientId,
            billable: billable,
            sprintName: sprint_name,
            sprint: null,
          };
          if (sprint_id) {
            postData.sprint = sprint_id;
          }

          axios
            .post("/api/time-track/start-timer", postData, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
            .then((response) => {
              dispatch(setTimerData(Date.now()));
              setRunningTask(response.data.id);
              localStorage.setItem(
                "runningTask",
                JSON.stringify(response.data.id)
              );
              console.log('response.data.id', response.data.id);
              dispatch(setRunningTaskData(response.data.id));
            })
            .catch((error) => {
              // Handle error
              global.config.slackMessage(error.toString());
            })
            .finally(() => {
              setResumeLoading(false); // Set loading to false after the request is completed
            });
        } else {
          setResumeLoading(false);
          setTaskError(true);
          setProjectValidation("red");
          var taskErr = "";
          var projectErr = "";
          var tagErr = "";
          if (!taskName) {
            taskErr = " 'Task' ";
          }
          if (!projectName) {
            projectErr = " 'Project' ";
          }
          if (!tag) {
            tagErr = " 'Tag' ";
          }
          toast(`Please select ${taskErr} ${projectErr} ${tagErr}`, {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }
      setTrigger(false);
    } catch (error) {

      console.log(error);
      setTrigger(false);

    }


  };

  function handleSetCustomeTime() {

    try {

      if (taskName && projectName && customeStartTime && customeEndTime) {
        if (customeStartTime && customeEndTime) {
          if (
            moment(customeEndTime)
              .tz(global.config.getTimeZone())
              .diff(moment(customeStartTime).tz(global.config.getTimeZone())) >= 0
          ) {
            const postData = {
              task: taskName,
              project: projectName,
              startTime: moment(customeStartTime).tz(global.config.getTimeZone()),
              endTime: moment(customeEndTime).tz(global.config.getTimeZone()),
              user: selectedUser,
              client: client,
              clientId: clientId,
              billable: isBillable,
              tz: global.config.getTimeZone(),
            };

            if (sprintId) {
              postData.sprint = sprintId;
            }

            axios
              .post("/api/time-track/custome-time", postData, {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              })
              .then(async (response) => {
                await dispatch(setTimerData(Date.now()));
                setIsRunning(false);
                setCustomeTime(false);
                setStopRunning((prevState) => !prevState);
                setSprintName("");
                setSprintId("");
                toast("Time added Successfully", {
                  position: "bottom-right",
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              });
          } else {
            toast("End time must be greater that start time", {
              position: "bottom-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        }
      } else {
        setTaskError(true);
        setTaskName(null);
        toast("Please select Task , Project , Start Time and End Time", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }

    } catch (error) {

      console.log(error);
    }

  }

  function handleSetCustomeTimes() {

    try {

      if (taskName && projectName && projectId && date && client && clientId) {
        const date1 = moment
          .tz(date, global.config.getTimeZone())
          .utc()
          .format("YYYY-MM-DD HH:mm:ss");
        if (uDuration === 0 || startTimeManual.trim() === endTimeManual.trim()) {
          setTaskError(true);
          toast.error("Starting time and ending time shouldn't be the same.", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          return;
        }

        const postData = {
          task: taskName,
          project: projectId,
          date: date1,
          tag: tagId,
          hr: parseInt(Math.floor(uDuration / 3600)),
          min: parseInt(Math.floor(uDuration % 3600) / 60),
          sec: parseInt(Math.floor(uDuration % 60)),
          duration: uDuration,
          startTime: covertTimeSatmp(
            date1,
            startTimeManual && startTimeManual.trim()
          ),
          endTime: covertTimeSatmp(date1, endTimeManual && endTimeManual.trim()),
          user: selectedUser,
          client: client,
          clientId: clientId,
          billable: isBillable,
          tz: global.config.getTimeZone(),
        };

        if (sprintId) {
          postData.sprint = sprintId;
        }
        
        setIsLoading(true);
        setIsCustomeTimeLoading(true);
        axios
          .post("/api/time-track/custome-time-set", postData, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then(async (response) => {
            await dispatch(setTimerData(Date.now()));
            setIsLoading(false);
            setIsRunning(false);
            setCustomeTime(true);
            setTag(null);
            setTagId(null);
            setDate(new Date());
            setProjectName(null);
            setProjectId(null);
            setHr(0);
            setMin(0);
            setSec(0);
            setTaskName("");
            setStopRunning((prevState) => !prevState);
            setTimerUpdate((prevState) => !prevState);
            setSprintName("");
            setSprintId("");
            setStartTimeManual("09:00 AM");
            setEndTimeManual("09:30 AM");
            setUFormatedTimeManual("00:30:00");
            setDate(new Date());
            setIsCustomeTimeLoading(false);
            toast("Time added Successfully", {
              position: "bottom-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          })
          .catch((error) => {
            console.log(error);
            global.config.slackMessage(error.toString());
          });
        setTaskError(false);
        setProjectValidation("");
      } else {
        setTaskError(true);
        setProjectValidation("red");
        var taskErr = "";
        var projectErr = "";
        var tagErr = "";
        if (!taskName) {
          taskErr = " 'Task' ";
        }
        if (!projectName) {
          projectErr = " 'Project' ";
        }

        toast(`Please select ${taskErr} ${projectErr} ${tagErr}`, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }

    } catch (error) {

      console.log(error);

    }

  }



  var start =
    localStorage.getItem("startTime") !== "undefined"
      ? JSON.parse(localStorage.getItem("startTime"))
      : null;
  let customeTimeInput;
  if (customeTime) {
    customeTimeInput = (
      <>
        <p className="time-range">
          Start:{" "}
          <DatePicker
            className="date-pick-width"
            selected={customeStartTime}
            onChange={(date) => setCustomeStartTime(date)}
            showTimeSelect
            timeFormat="hh:mm"
            timeIntervals={15}
            dateFormat="MMMM d, yyyy h:mm:ss aa"
            timeCaption="Time"
          />
        </p>
        <p className="time-range">
          End:{" "}
          <DatePicker
            className="date-pick-width"
            selected={customeEndTime}
            onChange={(date) => setCustomeEndTime(date)}
            showTimeSelect
            timeFormat="hh:mm"
            timeIntervals={15}
            dateFormat="MMMM d, yyyy h:mm:ss aa"
            timeCaption="Time"
          />
        </p>
      </>
    );
  } else {
    customeTimeInput = <></>;
  }

  const handleManualClick = () => {
    setCustomeTime(true);
    setStartTimeManual("09:00 AM");
    setEndTimeManual("09:30 AM");
    setUFormatedTimeManual("00:00:00");
    setDate(new Date());
    if (
      uManualStartTimeError ||
      uManualEndTimeError ||
      uManualFormatedTimeError
    ) {
      setUManualStartTimeError(false);
      setUManualEndTimeError(false);
      setUManualFormatedTimeError(false);
    }
    if (projectDropdownOpen) {
      setProjectDropdownOpen(false);
    }
    if (tagDropdownOpen) {
      setTagDropdownOpen(false);
    }
  };

  const handleTimerClick = () => {
    setCustomeTime(false);
    if (projectDropdownOpen) {
      setProjectDropdownOpen(false);
    }
    if (tagDropdownOpen) {
      setTagDropdownOpen(false);
    }
  };

  function formatTime(value) {
    return value < 10 ? `0${value}` : value;
  }

  const timeValidate = (timeString) => {
    const timePattern = /^(1[0-2]|0[1-9]):([0-5][0-9]) [APap][Mm]$/;
    const isValidFormat = timePattern.test(timeString);
    return isValidFormat;
  };

  const covertTimeSatmp = (dateEdit, sT,tz) => {
    const date = new Date(dateEdit);
    const timeString = sT;
    const datePart = date.toLocaleDateString("en-US");
    const timePart = timeString;

    // Combine the date and time strings
    const combinedDateTimeString = `${datePart} ${timePart}`;

    const formattedTime = moment(
      combinedDateTimeString,
      "MM/DD/YYYY hh:mm A",
    ).format("YYYY-MM-DD hh:mm A");
    const dateObject = new Date(combinedDateTimeString);
    // Set the locale to English
    const formattedDate = dateObject.toLocaleString("en-US");
    return formattedDate;
  };

  function validateTimeFormat(timeString) {
    const timeRegex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/;
    return timeRegex.test(timeString);
  }

  const autoLoad = (
    task,
    project_id,
    projectName,
    tag_name,
    tag_id,
    client,
    clientId,
    sprint_name,
    _id,
    billable
  ) => {
    var time = moment().tz(global.config.getTimeZone());
    time = time.format();
    setTaskName(task);
    setProjectName(projectName);
    setProjectId(project_id);
    setTag(tag_name);
    setTagId(tag_id);
    setClient(client);
    setClientId(clientId);
    setIsBillable(billable);
    setDropdownVisible(false);
  };

  const [weekdata, setData] = useState(null);

  useEffect(() => {
    const getWeeklyHour = async () => {
      await axios
        .post(
          "/api/time-track/weekly-hours",
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) => {
          setData(
            `${formatTime(
              Math.floor(parseInt(result.data) / 3600)
            )}:${formatTime(
              Math.floor(parseInt((result.data % 3600) / 60))
            )}:${formatTime(Math.floor(parseInt(result.data) % 60))}`
          );
        });
    };
    getWeeklyHour();
    setFilteredData(groupedTimerData);
  }, [timerUpdate, stopRunning, timerData]);

  useEffect(() => {
    setFilteredData(groupedTimerData);
  }, [groupedTimerData]);

  const handleClickOutsideDropdown = (event) => {
    const isClickInsideShowingDropdown = showingDropdownRef.current && showingDropdownRef.current.contains(event.target);
    const isClickInsideAutoFillDropdown = autoFillDropdownRef.current && autoFillDropdownRef.current.contains(event.target);
    const isClickInsideProjectStatusDropdown = projectStatusDropdownRef.current && projectStatusDropdownRef.current.contains(event.target);
    const isClickInsideProjectDropdown = projectDropdownRef.current && projectDropdownRef.current.contains(event.target);
    const isClickInsideTagDropdown = tagDropdownRef.current && tagDropdownRef.current.contains(event.target);
    const isClickInsideUserDropdown = userDropdownRef.current && userDropdownRef.current.contains(event.target);
    const isClickInsideUserStatusDropdown = userStatusDropdownRef.current && userStatusDropdownRef.current.contains(event.target);

    if (!isClickInsideShowingDropdown) {
      setShowingDropdownOpen(false);
    }
    if (!isClickInsideAutoFillDropdown) {
      setDropdownVisible(false);
    }
    if (!isClickInsideProjectStatusDropdown) {
      setIsProjectStatusDropdownOpen(false);
    }
    if (!isClickInsideProjectDropdown) {
      setProjectDropdownOpen(false);
    }
    if (!isClickInsideTagDropdown) {
      setTagDropdownOpen(false);
    }
    if (!isClickInsideUserDropdown) {
      setIsUserDropdownOpen(false);
    }
    if (!isClickInsideUserStatusDropdown) {
      setIsUserStatusDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutsideDropdown);
    return () => {
      document.removeEventListener("click", handleClickOutsideDropdown);
    };
  }, []);

  const [startTimeManual, setStartTimeManual] = useState("09:00 AM");
  const [startTimeTemp, setStartTimeTemp] = useState("09:00 AM");
  const [startTimeManual24, setStartTimeManual24] = useState("09:00");
  const [endTimeManual, setEndTimeManual] = useState("09:30 AM");
  const [endTimeTemp, setEndTimeTemp] = useState("09:30 AM");
  const [endTimeManual24, setEndTimeManual24] = useState("09:30");
  const [uFormatedTimeManual, setUFormatedTimeManual] = useState("00:00:00");

  function isValidTimeFormat(timeString) {
    const timeRegex = /^(0?[1-9]|1[0-2]):[0-5][0-9] [APMapm]{2}$/;
    return timeRegex.test(timeString);
  }

  const parseTimeInput = (input) => {
    input = input.trim().toLowerCase();
    // const timeRegex = /^(\d{1,2}):?(\d{1,2})?\s*([ap]m?)?$/;
    let timeRegex = /^(\d{1,2}):?(\d{2})?\s*([ap]m?)?$/;
    let match = input.match(timeRegex);

    if (!match) {
      timeRegex = /^(\d{1,2}):?(\d{1,2})?\s*([ap]m?)?$/;
      match = input.match(timeRegex);
      if (!match) return false;
    }

    let [_, hour, minutes, period] = match;
    hour = hour.padStart(2, "0");
    minutes = minutes ? minutes.padStart(2, "0") : "00";
    period = period
      ? period?.toUpperCase()
      : parseInt(hour, 10) < 12
        ? "AM"
        : "PM";
    if (parseInt(hour, 10) > 12) return false;
    return `${hour}:${minutes} ${period}`;
  };

  const timeFormatChange = (time) => {
    const input = parseTimeInput(time);
    if (input) {
      const momentTime = moment(input, "hh:mm A");
      return momentTime.format("hh:mm A");
    }
    return false;
  };

  function convertToStandardTime(timeString) {
    const isPM = timeString.toLowerCase().includes("p");
    const hours = parseInt(timeString, 10);
    if (isNaN(hours)) {
      return "Invalid format";
    }
    const standardTime = moment({ hour: hours % 12, minute: 0 }).format(
      "hh:mm A"
    );
    return isPM ? standardTime.replace("AM", "PM") : standardTime;
  }

  function isValidCustomTimeFormat(timeString) {
    const customTimeRegex = /^(0?[1-9]|1[0-2])[apAP]$/;
    return customTimeRegex.test(timeString);
  }

  function isAlphanumericCombination(inputString) {
    const alphanumericRegex = /^[a-zA-Z]\d$/;
    return alphanumericRegex.test(inputString);
  }

  const addOneHour = (startTime) => {
    // Parse the time string
    const timeString = startTime;
    const time = moment(timeString, "hh:mm A");
    // Add one hour to the time
    const newTime = time.add(1, "hour");
    // Format the new time
    const formattedTime = newTime.format("hh:mm A");
    return formattedTime;
  };
  const subOneHour = (startTime) => {
    // Parse the time string
    const timeString = startTime;
    const time = moment(timeString, "hh:mm A");
    // Add one hour to the time
    const newTime = time.subtract(1, "hour");
    // Format the new time
    const formattedTime = newTime.format("hh:mm A");
    return formattedTime;
  };

  const formatTime3Digits = (startTime) => {
    // Parse the time string
    const timeString = startTime;
    const time = moment(timeString, "hmmA");
    // Format the time into 'hh:mm A' format
    const formattedTime = time.format("hh:mm A");
  };

  const patternCheck = (startTime) => {
    const timePattern = /^(0?[1-9]|1[0-2])[0-5][0-9][apAP][mM]$/;
    // Time string to validate
    const timeString = startTime;
    // Check if the time string matches the pattern
    const isValid = timePattern.test(timeString);
  };

  const timeFomatChange2 = (time) => {
    const input = time.trim().toLowerCase();
    let converted = "";
    // Check if the input matches the supported time format
    if (/^\d{1,2}:\d{2}[ap]$/i.test(input)) {
      const [hour, minutes] = input.match(/\d+/g);
      const isPM = input.includes("p");
      let formattedHour = parseInt(hour, 10);
      if (isPM && formattedHour !== 12) {
        formattedHour += 12;
      }
      if (!isPM && formattedHour === 12) {
        formattedHour = 0;
      }
      formattedHour = formattedHour % 12 || 12; // Convert 0 to 12
      converted = `${formattedHour.toString().padStart(2, "0")}:${minutes} ${isPM ? "PM" : "AM"
        }`;
    } else {
      // Invalid time format
      converted = false;
    }
    return converted;
  };
  const manualChangeStartTime = (startTime) => {
    let formattedTime = timeFormatChange(startTime);

    if (formattedTime === false) {
      setUManualStartTimeError(true);
      return;
    }

    setStartTimeManual(formattedTime);
    setStartTimeTemp(formattedTime);
    setUManualStartTimeError(false);
    setUManualEndTimeError(false);

    const timeString1 = formattedTime.trim();
    const timeString2 = endTimeManual.trim();
    const format = "h:mm A";
    const time1 = moment(timeString1, format).tz(global.config.getTimeZone());
    const time2 = moment(timeString2, format).tz(global.config.getTimeZone());
    const timeDifferenceInSeconds = time2.diff(time1, "seconds");

    if (timeDifferenceInSeconds > 0) {
      setUDuration(parseInt(timeDifferenceInSeconds));
      setUFormatedTimeManual(
        `${formatTime(
          Math.floor(parseInt(timeDifferenceInSeconds) / 3600)
        )}:${formatTime(
          Math.floor(parseInt((timeDifferenceInSeconds % 3600) / 60))
        )}:${formatTime(Math.floor(parseInt(timeDifferenceInSeconds) % 60))}`
      );
      setUManualStartTimeError(false);
      setUManualEndTimeError(false);
      setUManualFormatedTimeError(false);
    } else {
      setUManualStartTimeError(true);
    }
  };
  //end of 12 hour format

  // 24 hour format
  const manualChangeStartTime24 = (startTime) => {
    setStartTimeManual24(startTime);
    if (formatChange(startTime)) {
      var convertedTim = convertTo24HourFormat(startTime);

      setStartTimeManual24(convertedTim);
      setUManualStartTimeError(false);
    } else {
      setUManualStartTimeError(true);
    }
    startTime = convertTo12HourFormat(convertedTim);
    if (timeFomatChange2(startTime) !== false) {
      startTime = timeFomatChange2(startTime);
    }
    var oldTime = null;
    if (isValidCustomTimeFormat(startTime)) {
      var oldTime = startTime;
      startTime = convertToStandardTime(startTime);
      setUManualStartTimeError(false);
      setUManualEndTimeError(false);
    } else {
      const timePart = startTime.split(" ")[0];
      var hours = parseInt(timePart.split(":")[0], 10);
      if (hours > 12 && hours < 24) {
        const twelveHourTime = moment(timePart, "HH:mm").format("hh:mm A");
        if (twelveHourTime !== "Invalid date") {
          startTime = twelveHourTime;
        }
        setUManualStartTimeError(false);
        setUManualEndTimeError(false);
      }
    }
    if (timeValidate(startTime)) {
      const timeString1 = startTime.trim();
      var timeString2 = endTimeManual && endTimeManual.trim();
      const format = "h:mm A";
      const time1 = moment(timeString1, format).tz(global.config.getTimeZone());
      const time2 = moment(timeString2, format).tz(global.config.getTimeZone());
      const timeDifferenceInSeconds = time2.diff(time1, "seconds");

      if (timeDifferenceInSeconds > 0) {
        setUDuration(parseInt(timeDifferenceInSeconds));
        setUFormatedTimeManual(
          `${formatTime(
            Math.floor(parseInt(timeDifferenceInSeconds) / 3600)
          )}:${formatTime(
            Math.floor(parseInt((timeDifferenceInSeconds % 3600) / 60))
          )}:${formatTime(Math.floor(parseInt(timeDifferenceInSeconds) % 60))}`
        );
        setUManualStartTimeError(false);
        setUManualEndTimeError(false);
        setUManualFormatedTimeError(false);
      } else {
        setUManualStartTimeError(true);
      }
    } else {
      setUManualStartTimeError(true);
    }
    setStartTimeManual(startTime);
  };

  const manualChangeEndTime = (endTime) => {
    let formattedTime = timeFormatChange(endTime);

    if (formattedTime === false) {
      setUManualEndTimeError(true);
      return;
    }

    setEndTimeManual(formattedTime);
    setEndTimeTemp(formattedTime);

    const timeString1 = startTimeManual.trim();
    const timeString2 = formattedTime.trim();
    const format = "h:mm A";
    const time1 = moment(timeString1, format).tz(global.config.getTimeZone());
    const time2 = moment(timeString2, format).tz(global.config.getTimeZone());
    const timeDifferenceInSeconds = time2.diff(time1, "seconds");

    if (timeDifferenceInSeconds > 0) {
      setUDuration(parseInt(timeDifferenceInSeconds));
      setUFormatedTimeManual(
        `${formatTime(
          Math.floor(parseInt(timeDifferenceInSeconds) / 3600)
        )}:${formatTime(
          Math.floor(parseInt((timeDifferenceInSeconds % 3600) / 60))
        )}:${formatTime(Math.floor(parseInt(timeDifferenceInSeconds) % 60))}`
      );
      setUManualStartTimeError(false);
      setUManualEndTimeError(false);
      setUManualFormatedTimeError(false);
    } else {
      setUManualEndTimeError(true);
    }
  };

  const manualChangeEndTime24 = (endTime) => {
    setEndTimeManual24(endTime);
    if (formatChange(endTime)) {
      var convertedTim = convertTo24HourFormat(endTime);
      // setTimeout(() => {
      setEndTimeManual24(convertedTim);
      setUManualEndTimeError(false);
      // }, 4000);
    } else {
      setUManualEndTimeError(true);
    }
    // setStartTimeManual24(startTime);
    endTime = convertTo12HourFormat(convertedTim);
    // return true;
    if (timeFomatChange2(endTime) !== false) {
      endTime = timeFomatChange2(endTime);
    }
    var oldTime = null;
    if (isValidCustomTimeFormat(endTime)) {
      oldTime = endTime;
      endTime = convertToStandardTime(endTime);
    } else {
      const timePart = endTime.split(" ")[0];
      const hours = parseInt(timePart.split(":")[0], 10);
      if (hours > 12 && hours < 24) {
        const twelveHourTime = moment(timePart, "HH:mm").format("hh:mm A");
        if (twelveHourTime !== "Invalid date") {
          endTime = twelveHourTime;
        }
      }
    }
    if (timeValidate(endTime)) {
      var timeString1 = startTimeManual.trim();
      const timeString2 = endTime.trim();
      const format = "h:mm A";
      if (isValidCustomTimeFormat(oldTime)) {
        timeString1 = subOneHour(endTime).trim();
        setStartTimeManual(timeString1);
      }
      const time1 = moment(timeString1, format).tz(global.config.getTimeZone());
      const time2 = moment(timeString2, format).tz(global.config.getTimeZone());
      const timeDifferenceInSeconds = time2.diff(time1, "seconds");
      if (timeDifferenceInSeconds > 0) {
        setUDuration(parseInt(timeDifferenceInSeconds));
        setUFormatedTimeManual(
          `${formatTime(
            Math.floor(parseInt(timeDifferenceInSeconds) / 3600)
          )}:${formatTime(
            Math.floor(parseInt((timeDifferenceInSeconds % 3600) / 60))
          )}:${formatTime(Math.floor(parseInt(timeDifferenceInSeconds) % 60))}`
        );
        setUManualStartTimeError(false);
        setUManualEndTimeError(false);
        setUManualFormatedTimeError(false);
      } else {
        setUManualEndTimeError(true);
      }
    } else {
      setUManualEndTimeError(true);
    }
    setEndTimeManual(endTime);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        startInputRef.current &&
        !startInputRef.current.contains(event.target)
      ) {
        // Click occurred outside the input field, trigger changeStartTime24
        if (
          formatChange(startInputRef.current.value) &&
          validateInput(startInputRef.current.value)
        ) {
          manualChangeStartTime24(startInputRef.current.value);
        } else {
          manualChangeStartTime24(convertTo24Hour(startTimeManual));
        }
      }
      if (endInputRef.current && !endInputRef.current.contains(event.target)) {
        if (formatChange(endInputRef.current.value)) {
          manualChangeEndTime24(endInputRef.current.value);
        } else {
          manualChangeEndTime24(convertTo24Hour(endTimeManual));
        }
      }
      if (
        start12HrTimeInputRef.current &&
        !start12HrTimeInputRef.current.contains(event.target)
      ) {
        let formattedTime = timeFormatChange(
          start12HrTimeInputRef.current.value
        );
        if (formattedTime === false) {
          manualChangeStartTime(startTimeTemp);
        } else {
          manualChangeStartTime(start12HrTimeInputRef.current.value);
        }
      }
      if (
        end12HrTimeInputRef.current &&
        !end12HrTimeInputRef.current.contains(event.target)
      ) {
        let formattedTime = timeFormatChange(end12HrTimeInputRef.current.value);
        if (formattedTime === false) {
          manualChangeEndTime(endTimeTemp);
        } else {
          manualChangeEndTime(end12HrTimeInputRef.current.value);
        }
      }
    };

    document.body.addEventListener("click", handleClickOutside);
    return () => {
      document.body.removeEventListener("click", handleClickOutside);
    };
  }, [
    manualChangeStartTime24,
    manualChangeEndTime24,
    manualChangeStartTime,
    manualChangeEndTime,
  ]);

  const manualTimeShowEdit = (timeSec) => {
    if (validateTimeFormat(timeSec)) {
      const timeString = timeSec;
      const [hours, minutes, seconds] = timeString.split(":").map(Number);
      const totalSeconds =
        parseInt(hours) * 3600 + parseInt(minutes) * 60 + parseInt(seconds);
      const formattedDates = covertTimeSatmp(
        date,
        startTimeManual && startTimeManual.trim()
      );

      const timestampInSeconds = Math.floor(
        new Date(formattedDates).getTime() / 1000
      );

      setUDuration(parseInt(totalSeconds));
      const upcomingTimeInSeconds =
        parseInt(timestampInSeconds) + parseInt(totalSeconds);
      const upcomingTime = new Date(parseInt(upcomingTimeInSeconds) * 1000);
      const formattedDate1 = date.toLocaleString("en-US", { upcomingTime });
      const updatedDateTime = moment()
        .utc(startTimeManual)
        .add(totalSeconds, "seconds")
        .format("YYYY-MM-DD HH:mm:ss");
      const formattedTime =
        upcomingTime &&
        upcomingTime.toLocaleTimeString(["en-US"], {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        });
      setUManualFormatedTimeError(false);
      setEndTimeManual(formattedTime);
      setUManualStartTimeError(false);
      setUManualEndTimeError(false);
      setUManualFormatedTimeError(false);
    } else {
      setUManualFormatedTimeError(true);
    }
    setUFormatedTimeManual(timeSec);
  };

  const manualTimeShowEdit24 = (timeSec) => {
    if (validateTimeFormat(timeSec)) {
      const timeString = timeSec;
      const [hours, minutes, seconds] = timeString.split(":").map(Number);
      const totalSeconds =
        parseInt(hours) * 3600 + parseInt(minutes) * 60 + parseInt(seconds);
      const formattedDates = covertTimeSatmp(
        date,
        startTimeManual && startTimeManual.trim()
      );

      const timestampInSeconds = Math.floor(
        new Date(formattedDates).getTime() / 1000
      );

      setUDuration(parseInt(totalSeconds));
      const upcomingTimeInSeconds =
        parseInt(timestampInSeconds) + parseInt(totalSeconds);
      const upcomingTime = new Date(parseInt(upcomingTimeInSeconds) * 1000);
      const formattedDate1 = date.toLocaleString("en-US", { upcomingTime });
      const updatedDateTime = moment()
        .utc(startTimeManual)
        .add(totalSeconds, "seconds")
        .format("YYYY-MM-DD HH:mm:ss");
      const formattedTime =
        upcomingTime &&
        upcomingTime.toLocaleTimeString(["en-US"], {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        });
      setUManualFormatedTimeError(false);
      setEndTimeManual24(convertTo24HourFormatback(formattedTime));
      setUManualStartTimeError(false);
      setUManualEndTimeError(false);
      setUManualFormatedTimeError(false);
    } else {
      setUManualFormatedTimeError(true);
    }
    setUFormatedTimeManual(timeSec);
  };

  const getWeekStartDate = (date) => {
    // Clone the input date
    const dateObject = moment(date);
    const formattedDate = dateObject.format("YYYY-MM-DD");
    const startDate = new Date(formattedDate);
    // Check if the start of the week is set to Monday in appSettings
    if (appSettings.weekStartDay && appSettings.weekStartDay === "Monday") {
      // Get the day of the week (0 for Sunday, 1 for Monday, ..., 6 for Saturday)
      const dayOfWeek = startDate.getDay();
      // Calculate the difference between the current day of the week and Monday (1)
      const diff = dayOfWeek === 0 ? -6 : 1; // Adjust for Sunday as start of the week
      // Set the date to the start of the week (Monday)
      startDate.setDate(startDate.getDate() + diff);
    } else {
      // By default, set the day of the week to Sunday (0)
      startDate.setDate(startDate.getDate() - startDate.getDay());
    }
    return startDate;
  };

  const getWeekEndDate = (date) => {
    // Clone the input date
    const endDate = new Date(date);
    // Add 7 days to the input date to get the 7th day (Sunday)
    endDate.setDate(date.getDate() + 6);
    return endDate;
  };

  function filterAndFindExtremes(dates) {
    // Get the current date
    //moment().tz(global.config.getTimeZone());
    const dateObject = moment();
    const formattedDate = dateObject.format("YYYY-MM-DD");
    const currentDate = new Date(formattedDate);

    // Calculate the start and end dates of the current week
    const currentWeekStartDate = new Date(currentDate);
    const currentWeekEndDate = new Date(currentWeekStartDate);
    if (appSettings.weekStartDay && appSettings.weekStartDay === "Monday") {
      // monday to sunday

      currentWeekStartDate.setDate(
        currentDate.getDate() - ((currentDate.getDay() + 6) % 7)
      ); // Set to the first day (Monday) of the last week
      currentWeekStartDate.setHours(0, 0, 0, 0);

      const currentWeekEndDate = new Date(currentWeekStartDate);
      const getDateNew = moment(currentWeekEndDate);
      // Add 6 days to the current date
      const sixDaysAfter = getDateNew.add(6, "days");
      currentWeekEndDate.setDate(sixDaysAfter.format("YYYY-MM-DD")); // Set to the last day (Sunday) of the current week
      currentWeekEndDate.setHours(23, 59, 59, 999);
      // Monday to sunday
    } else {
      // sunday to saterday
      currentWeekStartDate.setDate(
        currentDate.getDate() - currentDate.getDay()
      ); // Set to the first day (Sunday) of the current week
      currentWeekStartDate.setHours(0, 0, 0, 0);
      const currentWeekEndDate = new Date(currentWeekStartDate);
      const getDateNew = moment(currentWeekEndDate);
      // Add 6 days to the current date
      const sixDaysAfter = getDateNew.add(6, "days");
      currentWeekEndDate.setDate(sixDaysAfter.format("YYYY-MM-DD")); // Set to the last day (Sunday) of the current week
      currentWeekEndDate.setHours(23, 59, 59, 999);
      // sunday to saterday
    }
    // Filter dates that are part of the current week
    const datesInCurrentWeek = dates.filter((date) => {
      return date >= currentWeekStartDate && date <= currentWeekEndDate;
    });

    // Find the highest and least date in the filtered array
    const highestDate = new Date(Math.max(...datesInCurrentWeek));
    const leastDate = new Date(Math.min(...datesInCurrentWeek));

    return { highestDate, leastDate };
  }

  function findExtremesOfLastWeek(dates) {
    const currentDate = new Date();
    const dateObject = moment();
    const formattedDate = dateObject.format("YYYY-MM-DD");
    const lastWeekStartDate = new Date(formattedDate);

    if (appSettings.weekStartDay && appSettings.weekStartDay === "Monday") {
      // Monday to sunday
      lastWeekStartDate.setDate(
        currentDate.getDate() - ((currentDate.getDay() + 6) % 7) - 7
      ); // Set to the first day (Monday) of the last week
      // Monday to sunday
    } else {
      // sunday to staerday
      lastWeekStartDate.setDate(
        currentDate.getDate() - currentDate.getDay() - 7
      ); // Set to the first day (Sunday) of the last week
      // sunday to saterday
    }

    lastWeekStartDate.setHours(0, 0, 0, 0);
    const lastWeekEndDate = new Date(lastWeekStartDate);
    lastWeekEndDate.setDate(lastWeekStartDate.getDate() + 6); // Set to the last day (Saturday) of the last week
    lastWeekEndDate.setHours(23, 59, 59, 999);

    // monday to sunday
    // Filter dates that are part of the last week
    const datesInLastWeek = dates.filter((date) => {
      return date >= lastWeekStartDate && date <= lastWeekEndDate;
    });

    // Find the highest and least date in the filtered array
    const highestLastWeekDate = new Date(Math.max(...datesInLastWeek));
    const leastLastWeekDate = new Date(Math.min(...datesInLastWeek));
    highestLastWeekDate.setHours(0, 0, 0, 0);
    leastLastWeekDate.setHours(0, 0, 0, 0);
    return { highestLastWeekDate, leastLastWeekDate };
  }

  function findExtremesByWeek(dates) {
    // Create an object to store dates grouped by week
    const datesByWeek = {};
    // Iterate through the dates and group them by week
    for (const date of dates) {
      // const weekStartDate = new Date(weekStartDate);
      const weekStartDatefil = moment(date);
      const weekStartDatef = weekStartDatefil.format("YYYY-MM-DD");
      const weekStartDate = new Date(weekStartDatef);
      if (appSettings.weekStartDay && appSettings.weekStartDay === "Monday") {
        // start with monday
        weekStartDate.setDate(date.getDate() - ((date.getDay() + 6) % 7)); // Set to the first day (Monday) of the week
        weekStartDate.setHours(0, 0, 0, 0);
        // start with monday
      } else {
        // start with sunday
        weekStartDate.setDate(date.getDate() - date.getDay()); // Set to the first day (Sunday) of the week
        weekStartDate.setHours(0, 0, 0, 0);
        // start with sunday
      }
      // Convert the week start date to a string for use as a key in the object
      const weekStartDateString = weekStartDate.toISOString();
      // Add the date to the corresponding week in the object
      if (!datesByWeek[weekStartDateString]) {
        datesByWeek[weekStartDateString] = [];
      }
      datesByWeek[weekStartDateString].push(date);
    }
    // Initialize an array to store the highest and least dates for each week
    const extremesByWeek = [];
    // Iterate through the grouped dates and find the highest and least date for each week
    for (const weekDates of Object.values(datesByWeek)) {
      const highestWeekDate = new Date(Math.max(...weekDates));
      const leastDate = new Date(Math.min(...weekDates));
      extremesByWeek.push({ highestWeekDate, leastDate });
    }
    return extremesByWeek;
  }

  const handlePageChange = (selected) => {
    setSelectedPage(selected.selected);
  };

  const mergeOpen = () => {
    setMergeVisible(!mergeVisible);
  };

  const totalResults = timer.length;
  const pageCount = Math.ceil(timer.length / perPage); // Total number of pages
  const offset = perPage * selectedPage; // Offset of the current page
  const currentPageData = timer.slice(offset, offset + perPage);
  const { highestDate, leastDate } = filterAndFindExtremes(
    currentPageData.map((item) => new Date(item[0]))
  );
  const { highestLastWeekDate, leastLastWeekDate } = findExtremesOfLastWeek(
    currentPageData.map((item) => new Date(item[0]))
  );
  const extremesByWeek = findExtremesByWeek(
    currentPageData.map((item) => new Date(item[0]))
  );

  const taskTitleUpdate = async (task) => {
    await dispatch(setTaskData(task));
    setTaskError(false);
    const filterText = task.toLowerCase();
    const filteredItems = groupedTimerData.filter((item) =>
      task.toLowerCase().includes(filterText)
    );
    setFilteredData(filteredItems);
  };

  useEffect(() => {
    const updateOnlineStatus = () => {
      setIsOnline(navigator.onLine);
    };

    window.addEventListener("online", updateOnlineStatus);
    window.addEventListener("offline", updateOnlineStatus);

    return () => {
      window.removeEventListener("online", updateOnlineStatus);
      window.removeEventListener("offline", updateOnlineStatus);
    };
  }, []);

  function getMomentDate(date, format) {
    const dateObject = moment(date, format);
    return dateObject.format("YYYY-MM-DD");
  }

  const findLeastDate1 = (highestDate, data) => {
    let leastDate = null;

    data.forEach((item) => {
      const itemDate = new Date(item.highestWeekDate);
      const inputDate = new Date(highestDate);
      if (
        itemDate <= inputDate &&
        (!leastDate || itemDate > new Date(leastDate))
      ) {
        leastDate = item.leastDate;
      }
    });
    return leastDate;
  };

  const findLeastDate = (inputDate, data) => {
    let leastDate = null;
    data.forEach((item) => {
      const itemDate = new Date(item.highestWeekDate);
      const startDate = new Date(item.leastDate);
      const endDate = new Date(item.highestWeekDate);
      if (inputDate >= startDate && inputDate <= endDate) {
        leastDate = item.leastDate;
      }
    });
    return leastDate;
  };

  const endFinalWeek = (date) => {
    // Define the date
    const currentDate = new Date(date);
    // Add six days
    currentDate.setDate(currentDate.getDate() + 6);
    // Output the result
    return currentDate.toDateString();
  };

  const findExtremeDate = (inputDate, data) => {
    let leastDate = null;
    let highestDate = null;

    data.forEach((item) => {
      const itemDate = new Date(item.highestWeekDate);
      const startDate = new Date(item.leastDate);
      const endDate = new Date(item.highestWeekDate);
      if (inputDate >= startDate && inputDate <= endDate) {
        leastDate = item.leastDate;
        highestDate = item.highestWeekDate;
      }
    });
    return highestDate;
  };

  function getMomentDate1(dateString, formatString) {
    // Parse the input date string using the provided format
    const date = moment(dateString, formatString);
    date.add(1, "day");
    const formattedDate = date.format(formatString);
    return formattedDate;
  }

  useEffect(() => {
    // console.log(isEndTimeGreater(startTimeManual24, endTimeManual24));
    if (
      !isValidTimeFormat(startTimeManual24) ||
      !isValidTimeFormat(endTimeManual24)
    ) {
      setUManualFormatedTimeError(true);
    }
    if (isEndTimeGreater(startTimeManual24, endTimeManual24)) {
      setUManualFormatedTimeError(false);
      setUManualEndTimeError(false);
    } else {
      setUManualFormatedTimeError(true);
      setUManualEndTimeError(true);
    }
  }, [endTimeManual24, startTimeManual24]);

  useEffect(() => {
    // Initialize Pusher with your app key and cluster

    // const pusher = new Pusher("77dbc32bce6ebdabdbc8", {
    //   cluster: "ap1",
    //   encrypted: true,
    // });

    // // Subscribe to the channel and event you used in the backend
    // const channel = pusher.subscribe(user && user._id);
    // channel.bind("timerStatus", (data) => {
    //   if (data.message === "off") {
    //     handleEndClick();
    //   }

    //   if (data.message === "on") {
    //     setCheckTimerStatus(!checkTimerStatus);
    //     setStopLoading(false);
    //   }
    // });

    // // Cleanup on unmount
    // return () => {
    //   channel.unbind_all();
    //   channel.unsubscribe();
    // };


  }, []);

  // useEffect(() => {
  //   const getOld = async (req, res) => {
  //     const activeData = await checkTimer(user._id, token);

  //     if (activeData) {
  //       setTaskError(false);
  //       setProjectValidation("");
  //       setIsRunning(true);
  //       setIsOnline(true);
  //       setStartTime(activeData.startTime);
  //       localStorage.setItem("startTime", JSON.stringify(activeData.startTime));
  //       dispatch(setTimeData(activeData.startTime));
  //       localStorage.setItem("isRunning", JSON.stringify(true));
  //       dispatch(setIsRunningData(true));
  //       localStorage.setItem("task", JSON.stringify(activeData.task));
  //       dispatch(setTaskData(activeData.task));
  //       localStorage.setItem("project", JSON.stringify(activeData.projectName));
  //       dispatch(setProjectData(activeData.projectName));
  //       localStorage.setItem("projectId", JSON.stringify(activeData.project));
  //       dispatch(setProjectIdData(activeData.project));
  //       localStorage.setItem("tag", JSON.stringify(activeData.tagName));
  //       dispatch(setTagData(activeData.tagName));
  //       localStorage.setItem("tagId", JSON.stringify(activeData.tag));
  //       dispatch(setTagIdData(activeData.tag));
  //       localStorage.setItem("client", JSON.stringify(activeData.client));
  //       dispatch(setClientData(activeData.client));
  //       localStorage.setItem("clientId", JSON.stringify(activeData.clientId));
  //       dispatch(setClientIdData(activeData.clientId));
  //       localStorage.setItem("isBillable", JSON.stringify(activeData.billable));
  //       dispatch(setIsbillableData(activeData.billable));
  //       if (activeData.sprint) {
  //         localStorage.setItem(
  //           "sprintName",
  //           JSON.stringify(activeData.sprintName)
  //         );
  //         dispatch(setSprintNameData(activeData.sprintName));
  //         localStorage.setItem("sprintId", JSON.stringify(activeData.sprint));
  //         dispatch(setSprintIdData(activeData.sprint));
  //       }

  //       setRunningTask(activeData._id);
  //       localStorage.setItem("runningTask", JSON.stringify(activeData._id));
  //       dispatch(setRunningTaskData(activeData._id));

  //       dispatch(setTimerData(Date.now()));
  //       setStopLoading(false);
  //     } else {
  //       handleEndClick();
  //       setTaskError(false);
  //       setProjectValidation("");
  //       setIsRunning(false);

  //       localStorage.removeItem("startTime");

  //       localStorage.removeItem("isRunning");

  //       localStorage.removeItem("task");

  //       localStorage.removeItem("project");

  //       localStorage.removeItem("projectId");

  //       localStorage.removeItem("tag");

  //       localStorage.removeItem("tagId");

  //       localStorage.removeItem("client");

  //       localStorage.removeItem("clientId");

  //       localStorage.removeItem("isBillable");

  //       localStorage.removeItem("sprintName");

  //       localStorage.removeItem("sprintId");

  //       setRunningTask(null);
  //       localStorage.removeItem("runningTask");
  //     }
  //   };

  //   getOld();
  // }, [checkTimerStatus]);

  const componentRef = useRef(null);
  const fadeIn = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  const dummyUsers = [
    { _id: "1", user_name: "User 1" },
    { _id: "2", user_name: "User 2" },
    { _id: "3", user_name: "User 3" },
  ];

  // State management for user dropdown

  // Handlers for user dropdown
  const toggleUserDropdown = () => {
    setIsUserDropdownOpen(!isUserDropdownOpen);
  };

  const handleUserSelect = (userId) => {
    setSelectedUser(userId);
    setIsUserDropdownOpen(false);
  };

  const handleUpload = async (event) => {
    event.preventDefault();
    setIsBulkImportLoading(true);
    if (!bulkImportFile) {
      toast.error("Please select a file.", {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setIsBulkImportLoading(true);
      return;
    }

    const fileExtension = bulkImportFile.name.split(".").pop();
    if (fileExtension !== "xlsx") {
      toast.error("Only xlsx files are supported.", {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setIsBulkImportLoading(false);
      return;
    }

    const formData = new FormData();
    formData.append("file", bulkImportFile);
    formData.append("tz", global.config.getTimeZone());

    try {
      const response = await axios.post(
        "/api/time-track/importdata/grouped/import/timer",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.status === "requiredValidationError") {
        toast.error(response.data.message, {
          position: "bottom-right",
          autoClose: 5000, // Adjust as needed
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        if (response.data.skippedRows && response.data.skippedRows.length > 0) {
          response.data.skippedRows.forEach((skippedRow) => {
            toast.warning(`Row ${skippedRow.index}: ${skippedRow.reason}`, {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          });
        }

        if (
          response.data.trackedEntries &&
          response.data.trackedEntries.length > 0
        ) {
          toast.success(
            `Inserted ${response.data.trackedEntries.length} records`,
            {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
          refetchTimerData();
          refetchGroupedTimerData();
          props.viewIsImport();
          handleBulkImportCloseModal();
        } else {
          toast.success(
            "File uploaded successfully, but no data was inserted.",
            {
              position: "bottom-right",
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
        }
      }
    } catch (error) {
      console.log(error);
      global.config.slackMessage(error.toString());
      toast.error("Error uploading file. Please try again.", {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } finally {
      setIsBulkImportLoading(false); // Reset loading state after upload process completes
    }
  };

  const handleBulkImportOpenModal = () => {
    setIsBulkImportModalOpen(true);
  };

  const handleBulkImportCloseModal = () => {
    setBulkImportFile(null);
    setBulkImportFileName("");
    setIsBulkImportModalOpen(false);
  };

  const handleBulkImportUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setBulkImportFile(e.target.files[0]);
      setBulkImportFileName(file.name);
    } else {
      setBulkImportFile(null);
      setBulkImportFileName("");
    }
  };

  return (
    <motion.div
      ref={componentRef}
      initial="hidden"
      animate="visible"
      variants={fadeIn}
      transition={{ duration: 0.3 }}
    >
      <div className="time-tracker-outer">
        <div className="top-section-tracker">
          <div className="manual-timer-change">
            <div className="anual-timer-change-outer">
              <div className="anual-timer-change-inner">
                <button
                  className={customeTime ? "active" : ""}
                  onClick={handleManualClick}
                  disabled={isRunning}
                >
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="23"
                      height="14"
                      viewBox="0 0 23 14"
                      fill="none"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M4.51758 0.828167C4.51758 0.370783 4.83595 0 5.22868 0H22.0365C22.4292 0 22.7476 0.370783 22.7476 0.828167C22.7476 1.28555 22.4292 1.65633 22.0365 1.65633H5.22868C4.83595 1.65633 4.51758 1.28555 4.51758 0.828167Z"
                        fill="#96A8BA"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0 0.828167C0 0.370783 0.370783 0 0.828167 0L2.33393 0C2.79131 0 3.16209 0.370783 3.16209 0.828167C3.16209 1.28555 2.79131 1.65633 2.33393 1.65633L0.828167 1.65633C0.370783 1.65633 0 1.28555 0 0.828167Z"
                        fill="#96A8BA"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0 6.85118C0 6.39379 0.370783 6.02301 0.828167 6.02301L2.33393 6.02301C2.79131 6.02301 3.16209 6.39379 3.16209 6.85118C3.16209 7.30856 2.79131 7.67934 2.33393 7.67934L0.828167 7.67934C0.370783 7.67934 0 7.30856 0 6.85118Z"
                        fill="#96A8BA"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0 12.8743C0 12.4169 0.370783 12.0461 0.828167 12.0461H2.33393C2.79131 12.0461 3.16209 12.4169 3.16209 12.8743C3.16209 13.3317 2.79131 13.7025 2.33393 13.7025H0.828167C0.370783 13.7025 0 13.3317 0 12.8743Z"
                        fill="#96A8BA"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M4.51758 6.85118C4.51758 6.39379 4.83595 6.02301 5.22868 6.02301H22.0365C22.4292 6.02301 22.7476 6.39379 22.7476 6.85118C22.7476 7.30856 22.4292 7.67934 22.0365 7.67934H5.22868C4.83595 7.67934 4.51758 7.30856 4.51758 6.85118Z"
                        fill="#96A8BA"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M4.51758 12.8743C4.51758 12.4169 4.83595 12.0461 5.22868 12.0461H22.0365C22.4292 12.0461 22.7476 12.4169 22.7476 12.8743C22.7476 13.3317 22.4292 13.7025 22.0365 13.7025H5.22868C4.83595 13.7025 4.51758 13.3317 4.51758 12.8743Z"
                        fill="#96A8BA"
                      />
                    </svg>
                  </span>{" "}
                  Manual
                </button>
                <button
                  className={!customeTime ? "active" : ""}
                  onClick={handleTimerClick}
                >
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="21"
                      height="23"
                      viewBox="0 0 21 23"
                      fill="#96A8BA"
                    >
                      <path d="M7.88008 1.59379e-05C7.50837 0.00134006 7.20755 0.294417 7.20621 0.656569C7.20553 0.83136 7.27598 0.999304 7.40237 1.12333C7.52877 1.24736 7.70069 1.31754 7.88009 1.3182H9.82242V2.58363C4.34897 2.92702 0 7.35611 0 12.7718C0 18.4111 4.71168 23 10.502 23C16.2924 23 21 18.4111 21 12.7718C21 7.35419 16.6525 2.92487 11.1776 2.58363V1.3182H13.1199C13.3002 1.31886 13.4733 1.24934 13.6008 1.12509C13.7283 1.00085 13.7997 0.832236 13.799 0.656564C13.7983 0.481773 13.7263 0.31427 13.599 0.191123C13.4717 0.0679763 13.2993 -0.000656286 13.1199 4.73086e-06L7.88008 1.59379e-05ZM10.5015 3.86855C15.5609 3.86855 19.6466 7.84544 19.6466 12.7719C19.6466 17.6984 15.561 21.6821 10.5015 21.6821C5.44196 21.6821 1.35289 17.6988 1.35289 12.7719C1.35289 7.84544 5.44231 3.86855 10.5015 3.86855ZM10.5015 6.37386C6.88114 6.37386 3.93041 9.24594 3.93041 12.7722C3.93041 16.2984 6.88097 19.1716 10.5015 19.1716C14.122 19.1716 17.0697 16.2982 17.0697 12.7722C17.0697 9.24594 14.1216 6.37386 10.5015 6.37386ZM10.5015 7.6934C13.3909 7.6934 15.7153 9.95882 15.7153 12.7722C15.7153 15.5856 13.3909 17.8521 10.5015 17.8521C7.61212 17.8521 5.2836 15.5856 5.2836 12.7722C5.2836 9.95882 7.61212 7.6934 10.5015 7.6934ZM10.5015 9.23052C10.3221 9.22985 10.1497 9.29849 10.0224 9.42163C9.89491 9.54478 9.8231 9.71228 9.82242 9.88707V13.4106C9.82174 13.5863 9.8931 13.7549 10.0206 13.8792C10.1479 14.0034 10.3212 14.0729 10.5015 14.0723H14.118C14.2983 14.0729 14.4713 14.0034 14.5989 13.8792C14.7264 13.7549 14.7977 13.5863 14.7971 13.4106C14.7968 13.2356 14.725 13.0679 14.5975 12.9443C14.4702 12.8209 14.2976 12.7521 14.118 12.7528H11.1767V9.88705C11.1751 9.52445 10.8737 9.23119 10.5015 9.23052Z" />
                    </svg>
                  </span>{" "}
                  Timer
                </button>
              </div>
              {user &&
              (user.userType === userTypeConsts.SUPER_ADMIN ||
                user.userType === userTypeConsts.ADMIN) &&
              userProfiles.length > 0 && (
              <div className="bulk-import-time-tracker">
                <a
                  className="btn-forpop-initem bulk-import"
                  onClick={handleBulkImportOpenModal}
                >
                  <span className="icon-invoice">
                    <img src={DownloadImage} alt="Export" />
                  </span>
                  Bulk Import 
                </a>
              </div>)}
            </div>

            {/* Timer user filter */}
            {user &&
              (user.userType === userTypeConsts.SUPER_ADMIN ||
                user.userType === userTypeConsts.ADMIN) &&
              userProfiles.length > 0 && (
                <div className="tracker-filter-user-bulk-import-sec">
                  <div className="tracker-filter-user users">
                    <label htmlFor="user">Tracking time for:</label>
                    <div className="custom-dropdown1" ref={userDropdownRef}>
                      <div className="drop-down-inner">
                        <div
                          className="selected-options1"
                          onClick={toggleUserDropdown}
                        >
                          {selectedUser ? (
                            <div className="selected-option1">
                              {userProfiles.find(
                                (user) => user._id === selectedUser
                              )?.first_name ||
                                userProfiles.find((user) => user._id === selectedUser)?.company_first_name}{" "}
                              {userProfiles.find((user) => user._id === selectedUser)?.last_name ||
                                userProfiles.find((user) => user._id === selectedUser)?.company_last_name}{" "}
                              {userProfiles.find((user) => user._id === selectedUser)?._id === user._id && "(me)"}
                            </div>
                          ) : (
                            <div className="placeholder">Select</div>
                          )}
                        </div>
                        {isUserDropdownOpen && (
                          <div className="dropdown-list1">
                            {(user.userType === userTypeConsts.SUPER_ADMIN || user.userType === userTypeConsts.ADMIN) && (
                              <>
                                <div className="user-status-dropdown">
                                  <div className="user-status-dropdown-label" htmlFor="userStatusFilter">Filter by Status</div>
                                  <div className="custom-dropdown-user" ref={userStatusDropdownRef}>
                                    <div className={`showing-name ${isUserStatusDropdownOpen ? 'open' : 'close'}`} onClick={toggleUserStatusDropdown}>
                                      {userStatusFilter ? userStatusFilter : "Active"}
                                    </div>
                                  </div>
                                </div>
                                {isUserStatusDropdownOpen && (
                                    <ul className="status-list">
                                      <li
                                        onClick={() => handleUserStatusChange("All")}
                                        className={userStatusFilter === "All" ? "selected-item" : ""}
                                      >
                                        All
                                      </li>
                                      <li
                                        onClick={() => handleUserStatusChange("Active")}
                                        className={userStatusFilter === "Active" ? "selected-item" : ""}
                                      >
                                        Active
                                      </li>
                                      <li
                                        onClick={() => handleUserStatusChange("Inactive")}
                                        className={userStatusFilter === "Inactive" ? "selected-item" : ""}
                                      >
                                        Inactive
                                      </li>
                                    </ul>
                                  )}
                              </>
                            )}

                            <div className="top-search-tracker-for">
                            <input
                                type="text"
                                placeholder="Search teams"
                                value={userSearchTerm}
                                onChange={handleUserSearchChange}
                                className="search-bar"
                              />
                            </div>
                            <div className="tracker-who-list">
                             
                              {filteredUsers
                                // .filter((user) => {
                                //   // Check if the user's `userType` matches any of the allowed values
                                //   return (
                                //     user.role_id.user_type === "Super Admin" ||
                                //     user.role_id.user_type === "Admin" ||
                                //     user.role_id.user_type === "User" ||
                                //     user.role_id.user_type === "Business" ||
                                //     user.role_id.user_type === "Project Manager" ||
                                //     user.role_id.user_type === "Team Manager"
                                //   );
                                // })
                                .filter((user) => {
                                  const isAllowedUserCategory = user.user_category !== 3 && user.user_category !== 4;
                                  const matchesStatusFilter = userStatusFilter === "All"
                                    ? true
                                    : userStatusFilter === "Active"
                                      ? user.is_active === true
                                      : user.is_active === false;
                                  return isAllowedUserCategory && matchesStatusFilter;
                                })
                                //  .filter((user) => user.first_name && user.last_name) // Filter profiles with both first and last names
                                .sort((a, b) => {
                                  // Sort by first name, then last name if first names are equal
                                  const firstNameComparison = (
                                    a.first_name || a.company_first_name
                                  ).localeCompare(
                                    b.first_name || b.company_first_name
                                  );
                                  if (firstNameComparison !== 0) {
                                    return firstNameComparison;
                                  }
                                  return a.last_name.localeCompare(b.last_name);
                                })
                                .map((userF) => (
                                  <div
                                    className="dropdown-item"
                                    key={userF._id}
                                    onClick={() => handleUserSelect(userF._id)}
                                  >
                                    {`${userF.first_name || userF.company_first_name
                                      } ${userF.last_name || userF.company_last_name
                                      }`}{" "}
                                    {userF._id === user._id && "(me)"}
                                  </div>
                                ))}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <Modal
                    isOpen={isBulkImportModalOpen}
                    onRequestClose={
                      !isBulkImportLoading
                        ? handleBulkImportCloseModal
                        : undefined
                    }
                    contentLabel="Invite a User"
                    className="bulk-import-popup"
                    style={{
                      content: {
                        height: "50%",
                        width: "50%",
                        position: "fixed",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-17.5%, -46%)",
                      },
                    }}
                  >
                    <div className="modal">
                      <div className="outter-modal-main">
                        <a
                          className="close-image-clr1"
                          onClick={handleBulkImportCloseModal}
                          disabled={isBulkImportLoading}
                        >
                          <img src={crssrImage} alt="Close Bulk Import" />{" "}
                        </a>
                      </div>

                      <div className="outter-modal-main">
                        <p>Bulk Import</p>
                        <form encType="multipart/form-data">
                          <div className="relative w-full mb-3">
                            <label className="gray-label">
                              Select timesheet
                            </label>
                            <div className="file-input-wrapper">
                              <input
                                type="text"
                                className="form-input form-input-file"
                                value={bulkImportFileName}
                                readOnly
                              />
                              <input
                                type="file"
                                id="expFileAttchEdit"
                                name="expFileAttchEdit"
                                className="form-input hidden-input"
                                onChange={handleBulkImportUpload}
                              />
                              <label
                                htmlFor="expFileAttch"
                                className="file-input-label"
                              >
                                <img src={Plus} alt="Browse" /> Browse
                              </label>
                            </div>
                            <div className="text-sm bulk-text">
                              Demo File: {"    "}
                              <a
                                href="https://docs.google.com/spreadsheets/d/1YE7znnsUb2qqRzThXgtzYHWAoDrr3-hfd4_jh984CRE/edit?usp=sharing"
                                className="form-textarea"
                                target="_blank"
                              >
                                timesheet_demo.xlsx
                              </a>
                            </div>
                          </div>

                          <div className="bulk-btn-section sprint-btn-outer">
                            <button
                              className="submit-client cancel-sprint"
                              type="button"
                              onClick={handleBulkImportCloseModal}
                              disabled={isBulkImportLoading}
                            >
                              Close
                            </button>
                            <button
                              className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded-full shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                              type="button"
                              onClick={handleUpload}
                              disabled={isBulkImportLoading}
                            >
                              {isBulkImportLoading ? (
                                <div className="outter-load-table">
                                  <ThreeDots
                                    height="18"
                                    width="20"
                                    radius="9"
                                    color="white"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                  />
                                </div>
                              ) : (
                                "Send"
                              )}
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </Modal>
                </div>
              )}

            {/* Timer User Filter end */}
          </div>

          {(isLoading || isGroupedTimerDataLoading) && (
            <Modal
              isOpen={loadingModalIsOpen}
              onRequestClose={() => setLoadingModalIsOpen(false)}
              style={{
                content: {
                  height: "10%",
                  width: "10%",
                  position: "fixed",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                },
              }}
            >
              <Spinner />
            </Modal>
          )}
          {/* timer */}
          {customeTime === false &&
            (appSettings.timeFormat === 12 ? (
              <div
                className="timetracker-overview-outer"
                style={{
                  borderColor: taskError ? "red" : "",
                  backgroundColor: taskError ? "#fff9f9" : "",
                }}
              >
                <div className="timetracker-inner">
                  <div className="timer-left ">
                    <div className="project-name">
                      <Col className="cursor" ref={autoFillDropdownRef}>
                        <input
                          type="text"
                          value={taskName ? taskName : ""}
                          onChange={(e) => {
                            setTaskName(e.target.value);
                            setTaskError(false);
                            const filterText = e.target.value.toLowerCase();
                            const filteredItems = groupedTimerData.filter(
                              (item) =>
                                item.task.toLowerCase().includes(filterText)
                            );
                            setFilteredData(filteredItems);
                          }}
                          onClick={handleInputClick}
                          placeholder="You’re working on..."
                          className={"timer-input-sec"}
                          style={{ textOverflow: "ellipsis" }}
                        />
                        {filteredData.length > 0 && dropdownVisible && (
                          <div className="dropdown">
                            <ul>
                              {filteredData.slice(0, 8).map((item) => (
                                <li
                                  key={item._id}
                                  onClick={() => {
                                    autoLoad(
                                      item.task,
                                      item.project?._id,
                                      item.project?.project_name,
                                      item.tag ? item.tag.tag_name : null,
                                      item.tag ? item.tag._id : null,
                                      item.client,
                                      item.project?.client_name,
                                      item.sprint
                                        ? item.sprint.sprint_name
                                        : null,
                                      item._id,
                                      item.billable
                                    );
                                  }}
                                >
                                  <div className="project-des">{item.task}</div>
                                  <div className="project-sug-name">
                                    {item.project?.project_name}
                                    <span className="project-tag-sug">
                                      {item.client}
                                    </span>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </Col>
                    </div>
                  </div>
                  <div className="timer-right">
                    <div className="project-sprint" ref={projectDropdownRef}>
                      <Col
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setProjectDropdownOpen(!projectDropdownOpen);
                          setProjectStatusFilter("Active");
                          setFilter("");
                          setSelectedClients(new Set());
                          setSelectedProject(null);
                          setEditedId(null);
                          setTaskError(false);
                          setTagDropdownOpen(false);
                          setProjectEditDropdownOpen(false);
                          setTagEditDropdownOpen(false);
                        }}
                        className="project-sprint-outer"
                      >
                        {projectName && (
                          <span className={`tooltip-tracker ${letterCount < 34 ? "small" : "large"}`} >
                            {projectName} {sprintName ? ` - ${sprintName}` : ""}{" "}
                            {projectName && (
                              <div className="project-name-tracker">
                                {client}
                              </div>
                            )}
                          </span>
                        )}
                        <div
                          className={`tracker-top-outer-project ${letterCount} ${letterCount < 34 ? "small" : "large"}`}
                          ref={trackerOuterRef2}
                          style={{
                            display: "inline-block",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            height: "22px",
                            maxWidth: "294.91px",
                          }}
                        >
                          {!projectName && (
                            <>
                              <img src={Plus} alt="plus" />{" "}
                            </>
                          )}
                          {projectName ? projectName : "Sprint/Project"}
                          {sprintName ? ` - ${sprintName}` : ""}
                          {projectName && (
                            <div className="project-name-tracker">{client}</div>
                          )}
                        </div>
                      </Col>

                      {projectDropdownOpen && (
                        <div className="custom-dropdown">
                          <input
                            type="text"
                            className="search-bar"
                            placeholder="Find Project or Client"
                            value={filter}
                            onChange={(e) => {
                              const filterValue = e.target.value;
                              setFilter(filterValue);
                              if (filterValue === "") {
                                setSelectedClients(new Set());
                              } else {
                                // Check if the filtered project exists under any client
                                // const matchingClients =
                                //   filteredClientData.filter((client) =>
                                //     client.projects.some((project) =>
                                //       project.project_name
                                //         .toLowerCase()
                                //         .includes(filterValue.toLowerCase())
                                //     )
                                //   );
                                const matchingClients = filteredClientData.filter((client) =>
                                  client.projects.some(
                                    (project) =>
                                      project.project_name
                                        .toLowerCase()
                                        .includes(filterValue.toLowerCase()) &&
                                      (projectStatusFilter === "All"
                                        ? true
                                        : projectStatusFilter === "Active"
                                          ? project.is_active === true
                                          : project.is_active === false)
                                  )
                                );
                                setSelectedClients(new Set(matchingClients));
                              }
                            }}
                            autocomplete="off"
                          />
                          <ul className={`client-list client-list-filter ${(user.userType !== userTypeConsts.SUPER_ADMIN && user.userType !== userTypeConsts.ADMIN) ? 'filter-active-no' : ''}`}>
                            {(user.userType === userTypeConsts.SUPER_ADMIN || user.userType === userTypeConsts.ADMIN) && (
                              <>
                                <div className="user-status-dropdown">
                                  <div className="user-status-dropdown-label" htmlFor="projectStatusFilter">Filter by Status</div>
                                  <div className="custom-dropdown-user" ref={projectStatusDropdownRef}>
                                    <div className="showing-name" onClick={toggleProjectStatusDropdown}>
                                      {projectStatusFilter ? projectStatusFilter : "Active"}
                                    </div>
                                  </div>
                                </div>
                                {isProjectStatusDropdownOpen && (
                                  <ul className="status-list">
                                    <li
                                      onClick={() => handleProjectStatusChange("All")}
                                      className={projectStatusFilter === "All" ? "selected-item" : ""}
                                    >
                                      All
                                    </li>
                                    <li
                                      onClick={() => handleProjectStatusChange("Active")}
                                      className={projectStatusFilter === "Active" ? "selected-item" : ""}
                                    >
                                      Active
                                    </li>
                                    <li
                                      onClick={() => handleProjectStatusChange("Inactive")}
                                      className={projectStatusFilter === "Inactive" ? "selected-item" : ""}
                                    >
                                      Inactive
                                    </li>
                                  </ul>
                                )}
                              </>
                            )}
                            {filteredClientData.map((client) => (
                              <div key={client._id} class="main-outer-item-dd">
                                {/* Render the client name */}
                                <div
                                  className={`client-name1 ${selectedClients.has(client) ? "selected" : ""}`}
                                  onClick={() => {
                                    if (client.projects.filter(
                                      (project) =>
                                        project.project_name
                                          .toLowerCase()
                                          .includes(filter.toLowerCase()) &&
                                        (projectStatusFilter === "All"
                                          ? true
                                          : projectStatusFilter === "Active"
                                            ? project.is_active === true
                                            : project.is_active === false)
                                    ).length > 0) {
                                      // Toggle the client in the Set
                                      const newSelectedClients = new Set(
                                        selectedClients
                                      );
                                      if (selectedClients.has(client)) {
                                        newSelectedClients.delete(client);
                                      } else {
                                        newSelectedClients.add(client);
                                      }
                                      setSelectedClients(newSelectedClients);
                                    }
                                  }}
                                >
                                  <span className="main-project">
                                    {client.client_name}{" "}
                                  </span>
                                  {client.projects.filter(
                                    (project) =>
                                      project.project_name
                                        .toLowerCase()
                                        .includes(filter.toLowerCase()) &&
                                      (projectStatusFilter === "All"
                                        ? true
                                        : projectStatusFilter === "Active"
                                          ? project.is_active === true
                                          : project.is_active === false)
                                  ).length > 0 && (
                                      <span
                                        className={`arrow ${selectedClients.has(client) ? "open" : ""}`}
                                      >
                                        <span className="main-project-title">
                                          {client.projects.filter(
                                            (project) =>
                                              project.project_name
                                                .toLowerCase()
                                                .includes(filter.toLowerCase()) &&
                                              (projectStatusFilter === "All"
                                                ? true
                                                : projectStatusFilter === "Active"
                                                  ? project.is_active === true
                                                  : project.is_active === false)
                                          ).length} Projects
                                        </span>
                                        {selectedClients.has(client) ? (
                                          <img src={TopArrow} alt="Open" />
                                        ) : (
                                          <img src={DownArrow} alt="Close" />
                                        )}
                                      </span>
                                    )}
                                </div>
                                {selectedClients.has(client) && (
                                  <div className="project-name1-outer">
                                    {/* Render projects under the selected client */}
                                    {client.projects.filter(
                                      (project) =>
                                        project.project_name
                                          .toLowerCase()
                                          .includes(filter.toLowerCase()) &&
                                        (projectStatusFilter === "All"
                                          ? true
                                          : projectStatusFilter === "Active"
                                            ? project.is_active === true
                                            : project.is_active === false)
                                    ).length === 0 ? (
                                      <div>No projects under this client</div>
                                    ) : (
                                      client.projects
                                        .filter((project) =>
                                          project.project_name
                                            .toLowerCase()
                                            .includes(filter.toLowerCase()) &&
                                          (projectStatusFilter === "All"
                                            ? true
                                            : projectStatusFilter === "Active"
                                              ? project.is_active === true
                                              : project.is_active === false)
                                        )
                                        .sort((a, b) =>
                                          a.project_name.localeCompare(b.project_name)
                                        )
                                        .map((project) => (
                                          <div key={project._id}>
                                            <div className={`project-name1 ${selectedProject === project ? "selected" : ""}`} >
                                              <div
                                                onClick={(e) => {
                                                  setSprintName("");
                                                  setSprintId("");
                                                  setProjectName(project.project_name);
                                                  setProjectId(project._id);
                                                  setClient(client.client_name && client.client_name);
                                                  setClientId(client._id && client._id);
                                                  setIsBillable(project.is_billable);
                                                  setProjectDropdownOpen(false);
                                                }}
                                                className="project-dd-title"
                                              >
                                                {project.project_name}{" "}
                                              </div>
                                              <div>
                                                {project.sprints.length > 0 && (
                                                  <span
                                                    className={`arrow ${selectedProject === project ? "open" : ""}`}
                                                    onClick={() =>
                                                      setSelectedProject(selectedProject === project ? null : project)
                                                    }
                                                  >
                                                    <span className="main-project-title">
                                                      {project.sprints.length}{" "}
                                                      Sprints
                                                    </span>
                                                    {selectedProject === project ? (
                                                      <img src={TopArrow} alt="Open" />
                                                    ) : (
                                                      <img src={DownArrow} alt="Close" />
                                                    )}
                                                  </span>
                                                )}
                                              </div>
                                            </div>
                                            {selectedProject === project && (
                                              <div>
                                                {/* Render sprints under the selected project */}
                                                {project.sprints.length === 0 ? (
                                                  <div> {" "} No sprints under this project{" "}
                                                  </div>
                                                ) : (
                                                  <ul className="sprint-list">
                                                    {project.sprints
                                                      .sort((a, b) =>
                                                        a.sprint_name.localeCompare(
                                                          b.sprint_name
                                                        )
                                                      )
                                                      .map((sprint) => (
                                                        <li
                                                          key={sprint._id}
                                                          onClick={(e) => {
                                                            setProjectName(project.project_name);
                                                            setProjectId(project._id);
                                                            setClient(client?.client_name && client.client_name);
                                                            setClientId(client._id && client._id);
                                                            setIsBillable(project.is_billable);
                                                            setSprintId(sprint._id);
                                                            setSprintName(sprint.sprint_name);
                                                            setProjectDropdownOpen(false);
                                                          }}
                                                        >
                                                          {sprint.sprint_name}
                                                        </li>
                                                      ))}
                                                  </ul>
                                                )}
                                              </div>
                                            )}
                                          </div>
                                        ))
                                    )}
                                  </div>
                                )}
                              </div>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                    <div className="tag-billing">
                      <div className="tag-section-outer" ref={tagDropdownRef}>
                        <div
                          style={{ cursor: "pointer" }}
                          className="tag-section"
                          onClick={() => {
                            setTagDropdownOpen(!tagDropdownOpen);
                            setEditedId(null);
                            setProjectDropdownOpen(false);
                            setProjectEditDropdownOpen(false);
                            setTagEditDropdownOpen(false);
                          }}
                        >
                          {!tag && (
                            <>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  d="M13.9119 23C13.1542 23 12.4419 22.7048 11.9066 22.1695L1.83081 12.0945C1.17489 11.4386 0.881077 10.5022 1.04426 9.5892L1.99398 4.28519C2.20208 3.12282 3.12293 2.202 4.28535 1.99391L9.58953 1.04422C10.5067 0.881044 11.4395 1.1753 12.0949 1.83074L22.1712 11.9052C23.2763 13.0108 23.2763 14.8102 22.1712 15.9158L15.9172 22.1695C15.3819 22.7053 14.6696 23 13.9119 23ZM20.8749 13.2014L10.7992 3.12649C10.6094 2.93674 10.3532 2.83269 10.0896 2.83269C10.0309 2.83269 9.97181 2.83773 9.91314 2.84827L4.60895 3.79797C4.19826 3.87131 3.87282 4.19673 3.79902 4.60787L2.84929 9.91187C2.79154 10.2346 2.89559 10.5659 3.12706 10.7979L13.2028 20.8728C13.5814 21.2514 14.2415 21.2514 14.6201 20.8728L20.874 14.6191C21.265 14.2281 21.265 13.5919 20.874 13.201L20.8749 13.2014Z"
                                  fill="#96A8BA"
                                  stroke="white"
                                  strokeWidth="0.2"
                                />
                                <path
                                  d="M7.5 9C6.67157 9 6 8.32843 6 7.5C6 6.67157 6.67157 6 7.5 6C8.32843 6 9 6.67157 9 7.5C9 8.32843 8.32843 9 7.5 9Z"
                                  fill="#96A8BA"
                                  stroke="white"
                                  strokeWidth="0.2"
                                />
                              </svg>{" "}
                            </>
                          )}
                          {tag && (
                            <span className="tooltip-tracker">{tag}</span>
                          )}
                          {tag && (
                            <p
                              style={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                maxWidth: "150px",
                              }}
                            >
                              {tag}
                            </p>
                          )}
                        </div>

                        {tagDropdownOpen && (
                          <div className="custom-dropdown">
                            <input
                              type="text"
                              className="search-bar"
                              placeholder="Find Tag"
                              value={tag}
                              onChange={handleTagInputChange}
                              autocomplete="off"
                            />
                            <ul className="client-list">
                              {filteredTagItems.map((item) => (
                                <li
                                  className="cursor"
                                  key={item._id}
                                  onClick={(e) => {
                                    setTag(item.tag_name);
                                    setTagId(item._id);
                                    setTagDropdownOpen(false);
                                  }}
                                >
                                  {item.tag_name}
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </div>

                      <div className="billable-section">
                        {isBillable ? (
                          <div className="billable-timing-sec billable-timing">
                            <svg
                              width="13"
                              height="20"
                              viewBox="0 0 13 20"
                              fill="#405BFF"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M5.544 19.32V17.4C3.264 17.184 1.464 16.2 0.024 14.688L1.872 12.792C3.288 14.232 4.848 14.952 6.648 14.904C8.304 14.88 9.408 14.232 9.408 13.176C9.408 11.808 8.064 11.664 5.616 11.04C3.336 10.464 0.384 9.72 0.384 6.792C0.384 4.32 2.736 2.568 5.592 2.376V0.503999H7.248V2.424C9.024 2.64 10.776 3.408 12.192 4.848L10.368 6.744C8.952 5.448 7.704 4.8 6.072 4.8C4.32 4.8 3.168 5.424 3.168 6.48C3.168 7.656 4.464 8.064 6.936 8.592C9.24 9.12 12.384 9.768 12.384 12.888C12.384 15.6 10.08 17.184 7.2 17.4V19.32H5.544Z"
                                fill="#96A8BA"
                              />
                            </svg>
                          </div>
                        ) : (
                          <div className="billable-timing-sec non-billable-timing">
                            <svg
                              width="13"
                              height="20"
                              viewBox="0 0 13 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M5.544 19.32V17.4C3.264 17.184 1.464 16.2 0.024 14.688L1.872 12.792C3.288 14.232 4.848 14.952 6.648 14.904C8.304 14.88 9.408 14.232 9.408 13.176C9.408 11.808 8.064 11.664 5.616 11.04C3.336 10.464 0.384 9.72 0.384 6.792C0.384 4.32 2.736 2.568 5.592 2.376V0.503999H7.248V2.424C9.024 2.64 10.776 3.408 12.192 4.848L10.368 6.744C8.952 5.448 7.704 4.8 6.072 4.8C4.32 4.8 3.168 5.424 3.168 6.48C3.168 7.656 4.464 8.064 6.936 8.592C9.24 9.12 12.384 9.768 12.384 12.888C12.384 15.6 10.08 17.184 7.2 17.4V19.32H5.544Z"
                                fill="#96A8BA"
                              />
                            </svg>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="start-timer-outer">
                      <Col className="start-timer-inner">
                        <Moment
                          interval={1000}
                          date={moment.tz(start, global.config.getTimeZone())}
                          format="hh:mm:ss"
                          durationFromNow
                          tz={global.config.getTimeZone()}
                        />
                        {isRunning === true &&
                          customeTime === false &&
                          isOnline === true && (
                            <button
                              onClick={handleEndClick}
                              className="button-pad-stop"
                              type="button"
                              disabled={
                                stopLoading || user._id !== selectedUser || trigger
                              }
                            >
                              {stopLoading ? (
                                <ThreeDots
                                  height="18"
                                  width="20"
                                  radius="9"
                                  color="white"
                                  ariaLabel="three-dots-loading"
                                  wrapperStyle={{}}
                                  wrapperClassName=""
                                  visible={true}
                                />
                              ) : (
                                "Stop"
                              )}
                            </button>
                          )}
                        {isRunning === false &&
                          customeTime === false &&
                          isOnline === true && (
                            <button
                              onClick={handleStartClick}
                              className="button-pad-start"
                              type="button"
                              disabled={user._id !== selectedUser}
                            >
                              Start
                            </button>
                          )}
                        {customeTime === true && (
                          <button
                            onClick={handleSetCustomeTime}
                            className="button-pad"
                            type="button"
                          >
                            Set
                          </button>
                        )}
                      </Col>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="timetracker-overview-outer"
                style={{
                  borderColor: taskError ? "red" : "",
                  backgroundColor: taskError ? "#fff9f9" : "",
                }}
              >
                <div className="timetracker-inner">
                  <div className="timer-left ">
                    <div className="project-name">
                      <Col className="cursor" ref={autoFillDropdownRef}>
                        <input
                          type="text"
                          value={taskName ? taskName : ""}
                          onChange={(e) => {
                            setTaskName(e.target.value);
                            setTaskError(false);
                            const filterText = e.target.value.toLowerCase();
                            const filteredItems = groupedTimerData.filter(
                              (item) =>
                                item.task.toLowerCase().includes(filterText)
                            );
                            setFilteredData(filteredItems);
                          }}
                          onClick={handleInputClick}
                          placeholder="You’re working on..."
                          className={"timer-input-sec"}
                          style={{ textOverflow: "ellipsis" }}
                          autocomplete="off"
                        />
                        {filteredData.length > 0 && dropdownVisible && (
                          <div className="dropdown">
                            <ul>
                              {filteredData.slice(0, 8).map((item) => (
                                <li
                                  key={item._id}
                                  onClick={() => {
                                    autoLoad(
                                      item.task,
                                      item.project?._id,
                                      item.project?.project_name,
                                      item.tag ? item.tag.tag_name : null,
                                      item.tag ? item.tag._id : null,
                                      item.client,
                                      item.project?.client_name,
                                      item.sprint
                                        ? item.sprint.sprint_name
                                        : null,
                                      item._id,
                                      item.billable
                                    );
                                  }}
                                >
                                  <div className="project-des">{item.task}</div>
                                  <div className="project-sug-name">
                                    {item.project?.project_name}
                                    <span className="project-tag-sug">
                                      {item.client}
                                    </span>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </Col>
                    </div>
                  </div>
                  <div className="timer-right">
                    <div className="project-sprint" ref={projectDropdownRef}>
                      <Col
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setProjectDropdownOpen(!projectDropdownOpen);
                          setProjectStatusFilter("Active");
                          setFilter("");
                          setSelectedClients(new Set());
                          setSelectedProject(null);
                          setEditedId(null);
                          setTaskError(false);
                          setTagDropdownOpen(false);
                          setProjectEditDropdownOpen(false);
                          setTagEditDropdownOpen(false);
                        }}
                        className="project-sprint-outer"
                      >
                        {projectName && (
                          <span
                            className={`tooltip-tracker ${letterCount < 34 ? "small" : "large"
                              }`}
                          >
                            {projectName} {sprintName ? ` - ${sprintName}` : ""}
                            {projectName && (
                              <div className="project-name-tracker">
                                {client}
                              </div>
                            )}
                          </span>
                        )}
                        <div
                          className={`tracker-top-outer-project ${letterCount} ${letterCount < 34 ? "small" : "large"
                            }`}
                          ref={trackerOuterRef2}
                          style={{
                            display: "inline-block",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            height: "22px",
                            maxWidth: "294.91px",
                          }}
                        >
                          {!projectName && (
                            <>
                              {" "}
                              <img src={Plus} alt="plus" />{" "}
                            </>
                          )}
                          {projectName ? projectName : "Sprint/Project"}
                          {sprintName ? ` - ${sprintName}` : ""}
                          {projectName && (
                            <div className="project-name-tracker">{client}</div>
                          )}
                        </div>
                      </Col>

                      {projectDropdownOpen && (
                        <div className="custom-dropdown">
                          <input
                            type="text"
                            className="search-bar"
                            placeholder="Find Project or Client"
                            value={filter}
                            onChange={(e) => {
                              const filterValue = e.target.value;
                              setFilter(filterValue);
                              if (filterValue === "") {
                                setSelectedClients(new Set());
                              } else {
                                // Check if the filtered project exists under any client
                                const matchingClients =
                                  filteredClientData.filter((client) =>
                                    client.projects.some((project) =>
                                      project.project_name
                                        .toLowerCase()
                                        .includes(filterValue.toLowerCase()) &&
                                      (projectStatusFilter === "All"
                                        ? true
                                        : projectStatusFilter === "Active"
                                          ? project.is_active === true
                                          : project.is_active === false)
                                    )
                                  );
                                setSelectedClients(new Set(matchingClients));
                              }
                            }}
                            autocomplete="off"
                          />
                          {(user.userType === userTypeConsts.SUPER_ADMIN || user.userType === userTypeConsts.ADMIN) && (
                              <>
                                <div className="user-status-dropdown">
                                  <div className="user-status-dropdown-label" htmlFor="projectStatusFilter">Filter by Status</div>
                                  <div className="custom-dropdown-user" ref={projectStatusDropdownRef}>
                                    <div className="showing-name" onClick={toggleProjectStatusDropdown}>
                                      {projectStatusFilter ? projectStatusFilter : "Active"}
                                    </div>
                                  </div>
                                </div>
                                {isProjectStatusDropdownOpen && (
                                  <ul className="status-list">
                                    <li
                                      onClick={() => handleProjectStatusChange("All")}
                                      className={projectStatusFilter === "All" ? "selected-item" : ""}
                                    >
                                      All
                                    </li>
                                    <li
                                      onClick={() => handleProjectStatusChange("Active")}
                                      className={projectStatusFilter === "Active" ? "selected-item" : ""}
                                    >
                                      Active
                                    </li>
                                    <li
                                      onClick={() => handleProjectStatusChange("Inactive")}
                                      className={projectStatusFilter === "Inactive" ? "selected-item" : ""}
                                    >
                                      Inactive
                                    </li>
                                  </ul>
                                )}
                              </>
                            )}
                          <ul className={`client-list client-list-filter ${(user.userType !== userTypeConsts.SUPER_ADMIN && user.userType !== userTypeConsts.ADMIN) ? 'filter-active-no' : ''}`}>
                            
                            {filteredClientData.map((client) => (
                              <div key={client._id} class="main-outer-item-dd">
                                {/* Render the client name */}
                                <div
                                  className={`client-name1 ${selectedClients.has(client) ? "selected" : ""}`}
                                  onClick={() => {
                                    if (client.projects.filter(
                                      (project) =>
                                        project.project_name
                                          .toLowerCase()
                                          .includes(filter.toLowerCase()) &&
                                        (projectStatusFilter === "All"
                                          ? true
                                          : projectStatusFilter === "Active"
                                            ? project.is_active === true
                                            : project.is_active === false)
                                    ).length > 0) {
                                      // Toggle the client in the Set
                                      const newSelectedClients = new Set(
                                        selectedClients
                                      );
                                      if (selectedClients.has(client)) {
                                        newSelectedClients.delete(client);
                                      } else {
                                        newSelectedClients.add(client);
                                      }
                                      setSelectedClients(newSelectedClients);
                                    }
                                  }}
                                >
                                  <span className="main-project">
                                    {client.client_name}{" "}
                                  </span>
                                  {client.projects.filter(
                                    (project) =>
                                      project.project_name
                                        .toLowerCase()
                                        .includes(filter.toLowerCase()) &&
                                      (projectStatusFilter === "All"
                                        ? true
                                        : projectStatusFilter === "Active"
                                          ? project.is_active === true
                                          : project.is_active === false)
                                  ).length > 0 && (
                                      <span className={`arrow ${selectedClients.has(client) ? "open" : ""}`} >
                                        <span className="main-project-title">
                                          {client.projects.filter(
                                            (project) =>
                                              project.project_name
                                                .toLowerCase()
                                                .includes(filter.toLowerCase()) &&
                                              (projectStatusFilter === "All"
                                                ? true
                                                : projectStatusFilter === "Active"
                                                  ? project.is_active === true
                                                  : project.is_active === false)
                                          ).length} Projects
                                        </span>
                                        {selectedClients.has(client) ? (
                                          <img src={TopArrow} alt="Open" />
                                        ) : (
                                          <img src={DownArrow} alt="Close" />
                                        )}
                                      </span>
                                    )}
                                </div>
                                {selectedClients.has(client) && (
                                  <div className="project-name1-outer">
                                    {/* Render projects under the selected client */}
                                    {client.projects.filter(
                                      (project) =>
                                        project.project_name
                                          .toLowerCase()
                                          .includes(filter.toLowerCase()) &&
                                        (projectStatusFilter === "All"
                                          ? true
                                          : projectStatusFilter === "Active"
                                            ? project.is_active === true
                                            : project.is_active === false)
                                    ).length === 0 ? (
                                      <div>No projects under this client</div>
                                    ) : (
                                      client.projects
                                        .filter((project) =>
                                          project.project_name
                                            .toLowerCase()
                                            .includes(filter.toLowerCase()) &&
                                          (projectStatusFilter === "All"
                                            ? true
                                            : projectStatusFilter === "Active"
                                              ? project.is_active === true
                                              : project.is_active === false)
                                        )
                                        .sort((a, b) =>
                                          a.project_name.localeCompare(
                                            b.project_name
                                          )
                                        )
                                        .map((project) => (
                                          <div key={project._id}>
                                            <div className={`project-name1 ${selectedProject === project ? "selected" : ""}`} >
                                              <div
                                                onClick={(e) => {
                                                  setSprintName("");
                                                  setSprintId("");
                                                  setProjectName(project.project_name);
                                                  setProjectId(project._id);
                                                  setClient(client.client_name && client.client_name);
                                                  setClientId(client._id && client._id);
                                                  setIsBillable(project.is_billable);
                                                  setProjectDropdownOpen(false);
                                                }}
                                                className="project-dd-title"
                                              >
                                                {project.project_name}{" "}
                                              </div>
                                              <div>
                                                {project.sprints.length > 0 && (
                                                  <span
                                                    className={`arrow ${selectedProject ===
                                                      project
                                                      ? "open"
                                                      : ""
                                                      }`}
                                                    onClick={() =>
                                                      setSelectedProject(
                                                        selectedProject ===
                                                          project
                                                          ? null
                                                          : project
                                                      )
                                                    }
                                                  >
                                                    <span className="main-project-title">
                                                      {project.sprints.length}{" "}
                                                      Sprints
                                                    </span>
                                                    {selectedProject ===
                                                      project ? (
                                                      <img
                                                        src={TopArrow}
                                                        alt="Open"
                                                      />
                                                    ) : (
                                                      <img
                                                        src={DownArrow}
                                                        alt="Close"
                                                      />
                                                    )}
                                                  </span>
                                                )}
                                              </div>
                                            </div>
                                            {selectedProject === project && (
                                              <div>
                                                {/* Render sprints under the selected project */}
                                                {project.sprints.length ===
                                                  0 ? (
                                                  <div>
                                                    No sprints under this
                                                    project
                                                  </div>
                                                ) : (
                                                  <ul className="sprint-list">
                                                    {project.sprints
                                                      .sort((a, b) =>
                                                        a.sprint_name.localeCompare(
                                                          b.sprint_name
                                                        )
                                                      )
                                                      .map((sprint) => (
                                                        <li
                                                          key={sprint._id}
                                                          onClick={(e) => {
                                                            setProjectName(project.project_name);
                                                            setProjectId(project._id);
                                                            setClient(client.client_name && client.client_name);
                                                            setClientId(client._id && client._id);
                                                            setIsBillable(project.is_billable);
                                                            setSprintId(sprint._id);
                                                            setSprintName(sprint.sprint_name);
                                                            setProjectDropdownOpen(false);
                                                          }}
                                                        >
                                                          {sprint.sprint_name}
                                                        </li>
                                                      ))}
                                                  </ul>
                                                )}
                                              </div>
                                            )}
                                          </div>
                                        ))
                                    )}
                                  </div>
                                )}
                              </div>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                    <div className="tag-billing">
                      <div className="tag-section-outer" ref={tagDropdownRef}>
                        <div
                          style={{ cursor: "pointer" }}
                          className="tag-section"
                          onClick={() => {
                            setTagDropdownOpen(!tagDropdownOpen);
                            setEditedId(null);
                            setProjectDropdownOpen(false);
                            setProjectEditDropdownOpen(false);
                            setTagEditDropdownOpen(false);
                          }}
                        >
                          {!tag && (
                            <>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  d="M13.9119 23C13.1542 23 12.4419 22.7048 11.9066 22.1695L1.83081 12.0945C1.17489 11.4386 0.881077 10.5022 1.04426 9.5892L1.99398 4.28519C2.20208 3.12282 3.12293 2.202 4.28535 1.99391L9.58953 1.04422C10.5067 0.881044 11.4395 1.1753 12.0949 1.83074L22.1712 11.9052C23.2763 13.0108 23.2763 14.8102 22.1712 15.9158L15.9172 22.1695C15.3819 22.7053 14.6696 23 13.9119 23ZM20.8749 13.2014L10.7992 3.12649C10.6094 2.93674 10.3532 2.83269 10.0896 2.83269C10.0309 2.83269 9.97181 2.83773 9.91314 2.84827L4.60895 3.79797C4.19826 3.87131 3.87282 4.19673 3.79902 4.60787L2.84929 9.91187C2.79154 10.2346 2.89559 10.5659 3.12706 10.7979L13.2028 20.8728C13.5814 21.2514 14.2415 21.2514 14.6201 20.8728L20.874 14.6191C21.265 14.2281 21.265 13.5919 20.874 13.201L20.8749 13.2014Z"
                                  fill="#96A8BA"
                                  stroke="white"
                                  strokeWidth="0.2"
                                />
                                <path
                                  d="M7.5 9C6.67157 9 6 8.32843 6 7.5C6 6.67157 6.67157 6 7.5 6C8.32843 6 9 6.67157 9 7.5C9 8.32843 8.32843 9 7.5 9Z"
                                  fill="#96A8BA"
                                  stroke="white"
                                  strokeWidth="0.2"
                                />
                              </svg>{" "}
                            </>
                          )}
                          {tag && (
                            <span className="tooltip-tracker">{tag}</span>
                          )}
                          {tag && (
                            <p
                              style={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                maxWidth: "150px",
                              }}
                            >
                              {tag}
                            </p>
                          )}
                        </div>

                        {tagDropdownOpen && (
                          <div className="custom-dropdown">
                            <input
                              type="text"
                              className="search-bar"
                              placeholder="Find Tag"
                              value={tag}
                              onChange={handleTagInputChange}
                              autocomplete="off"
                            />
                            <ul className="client-list">
                              {filteredTagItems.map((item) => (
                                <li
                                  className="cursor"
                                  key={item._id}
                                  onClick={(e) => {
                                    setTag(item.tag_name);
                                    // dispatch(setTagData(item.tag_name));
                                    setTagId(item._id);
                                    // dispatch(setTagIdData(item._id));
                                    setTagDropdownOpen(false);
                                  }}
                                >
                                  {item.tag_name}
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </div>

                      <div className="billable-section">
                        {isBillable ? (
                          <div className="billable-timing-sec billable-timing">
                            <svg
                              width="13"
                              height="20"
                              viewBox="0 0 13 20"
                              fill="#405BFF"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M5.544 19.32V17.4C3.264 17.184 1.464 16.2 0.024 14.688L1.872 12.792C3.288 14.232 4.848 14.952 6.648 14.904C8.304 14.88 9.408 14.232 9.408 13.176C9.408 11.808 8.064 11.664 5.616 11.04C3.336 10.464 0.384 9.72 0.384 6.792C0.384 4.32 2.736 2.568 5.592 2.376V0.503999H7.248V2.424C9.024 2.64 10.776 3.408 12.192 4.848L10.368 6.744C8.952 5.448 7.704 4.8 6.072 4.8C4.32 4.8 3.168 5.424 3.168 6.48C3.168 7.656 4.464 8.064 6.936 8.592C9.24 9.12 12.384 9.768 12.384 12.888C12.384 15.6 10.08 17.184 7.2 17.4V19.32H5.544Z"
                                fill="#96A8BA"
                              />
                            </svg>
                          </div>
                        ) : (
                          <div className="billable-timing-sec non-billable-timing">
                            <svg
                              width="13"
                              height="20"
                              viewBox="0 0 13 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M5.544 19.32V17.4C3.264 17.184 1.464 16.2 0.024 14.688L1.872 12.792C3.288 14.232 4.848 14.952 6.648 14.904C8.304 14.88 9.408 14.232 9.408 13.176C9.408 11.808 8.064 11.664 5.616 11.04C3.336 10.464 0.384 9.72 0.384 6.792C0.384 4.32 2.736 2.568 5.592 2.376V0.503999H7.248V2.424C9.024 2.64 10.776 3.408 12.192 4.848L10.368 6.744C8.952 5.448 7.704 4.8 6.072 4.8C4.32 4.8 3.168 5.424 3.168 6.48C3.168 7.656 4.464 8.064 6.936 8.592C9.24 9.12 12.384 9.768 12.384 12.888C12.384 15.6 10.08 17.184 7.2 17.4V19.32H5.544Z"
                                fill="#96A8BA"
                              />
                            </svg>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="start-timer-outer">
                      <Col className="start-timer-inner">
                        <Moment
                          interval={1000}
                          date={moment.tz(start, global.config.getTimeZone())}
                          format="hh:mm:ss"
                          durationFromNow
                          tz={global.config.getTimeZone()}
                        />
                        {isRunning === true &&
                          customeTime === false &&
                          isOnline === true && (
                            <button
                              onClick={handleEndClick}
                              className="button-pad-stop"
                              type="button"
                              disabled={
                                stopLoading || user._id !== selectedUser || trigger
                              }
                            >
                              {stopLoading ? (
                                <ThreeDots
                                  height="18"
                                  width="20"
                                  radius="9"
                                  color="white"
                                  ariaLabel="three-dots-loading"
                                  wrapperStyle={{}}
                                  wrapperClassName=""
                                  visible={true}
                                />
                              ) : (
                                "Stop"
                              )}
                            </button>
                          )}
                        {isRunning === false &&
                          customeTime === false &&
                          isOnline === true && (
                            <button
                              onClick={handleStartClick}
                              className="button-pad-start"
                              type="button"
                              disabled={user._id !== selectedUser}
                            >
                              Start
                            </button>
                          )}
                        {customeTime === true && (
                          <button
                            onClick={handleSetCustomeTime}
                            className="button-pad"
                            type="button"
                          >
                            Set
                          </button>
                        )}
                      </Col>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          {/* timer end */}
          {customeTime === true && (
            <div
              className="timetracker-overview-outer timetracker-outer-custom"
              style={{
                borderColor: taskError ? "red" : "",
                backgroundColor: taskError ? "#fff9f9" : "",
              }}
            >
              <div className="timetracker-inner small-device-desktop">
                <div className="timer-left">
                  <div className="project-name">
                    <Col className="cursor" ref={autoFillDropdownRef}>
                      <input
                        type="text"
                        value={taskName ? taskName : ""}
                        onClick={handleInputClick}
                        onChange={(e) => {
                          setTaskName(e.target.value);
                          setTaskError(false);
                          const filterText = e.target.value.toLowerCase();
                          const filteredItems = groupedTimerData.filter(
                            (item) =>
                              item.task.toLowerCase().includes(filterText)
                          );
                          setFilteredData(filteredItems);
                        }}
                        placeholder="You’re working on..."
                        className={
                          taskError
                            ? "text-field-with-error px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full"
                            : " px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full"
                        }
                        style={{
                          textOverflow: "ellipsis",
                        }}
                        autocomplete="off"
                      />
                      {filteredData.length > 0 && dropdownVisible && (
                        <div className="dropdown">
                          <ul>
                            {filteredData.slice(0, 8).map((item) => (
                              <li
                                key={item._id}
                                onClick={() => {
                                  autoLoad(
                                    item.task,
                                    item.project?._id,
                                    item.project?.project_name,
                                    item.tag ? item.tag.tag_name : null,
                                    item.tag ? item.tag._id : null,
                                    item.client,
                                    item.project?.client_name,
                                    item.sprint
                                      ? item.sprint.sprint_name
                                      : null,
                                    item._id,
                                    item.billable
                                  );
                                }}
                              >
                                <div className="project-des">{item.task}</div>
                                <div className="project-sug-name">
                                  {item.project?.project_name}
                                  <span className="project-tag-sug">
                                    {item.client}
                                  </span>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </Col>
                  </div>

                  {windowWidth >= 200 && windowWidth <= 1440 && (
                    <div
                      className="project-sprint hide-on-large-desktop"
                      ref={projectDropdownRef}
                    >
                      <Col
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setProjectDropdownOpen(!projectDropdownOpen);
                          setProjectStatusFilter("Active");
                          setFilter("");
                          setSelectedClients(new Set());
                          setSelectedProject(null);
                          setEditedId(null);
                          setTagDropdownOpen(false);
                          setProjectEditDropdownOpen(false);
                          setTagEditDropdownOpen(false);
                        }}
                        className="project-sprint-outer"
                      >
                        {!projectName && (
                          <>
                            <img src={Plus} alt="plus" />{" "}
                          </>
                        )}
                        {projectName && (
                          <span
                            className={`tooltip-tracker ${letterCount < 34 ? "small" : "large"
                              }`}
                          >
                            {projectName ? projectName : "Sprint/Project"}
                            {sprintName ? ` - ${sprintName}` : ""}
                            {projectName && (
                              <div className="project-name-tracker">
                                . {client}
                              </div>
                            )}
                          </span>
                        )}
                        <span
                          className={`tracker-project-tag-project`}
                          ref={trackerOuterRef2}
                          style={{
                            display: "inline-block",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            height: "22px",
                            maxWidth: "150px",
                          }}
                        >
                          {projectName ? projectName : "Sprint/Project"}
                          {sprintName ? ` - ${sprintName}` : ""}
                          {projectName && (
                            <div className="project-name-tracker">
                              . {client}
                            </div>
                          )}
                        </span>
                      </Col>
                      {projectDropdownOpen && (
                        <div className="custom-dropdown">
                          <input
                            type="text"
                            className="search-bar"
                            placeholder="Find Project or Client"
                            value={filter}
                            onChange={(e) => {
                              const filterValue = e.target.value;
                              setFilter(filterValue);
                              if (filterValue === "") {
                                setSelectedClients(new Set());
                              } else {
                                // Check if the filtered project exists under any client
                                const matchingClients =
                                  filteredClientData.filter((client) =>
                                    client.projects.some((project) =>
                                      project.project_name
                                        .toLowerCase()
                                        .includes(filterValue.toLowerCase())
                                    )
                                  );
                                setSelectedClients(new Set(matchingClients));
                              }
                            }}
                            autocomplete="off"
                          />
                          {(user.userType === userTypeConsts.SUPER_ADMIN || user.userType === userTypeConsts.ADMIN) && (
                              <>
                                <div className="user-status-dropdown">
                                  <div className="user-status-dropdown-label" htmlFor="projectStatusFilter">Filter by Status</div>
                                  <div className="custom-dropdown-user" ref={projectStatusDropdownRef}>
                                    <div className="showing-name" onClick={toggleProjectStatusDropdown}>
                                      {projectStatusFilter ? projectStatusFilter : "Active"}
                                    </div>
                                  </div>
                                </div>
                                {isProjectStatusDropdownOpen && (
                                  <ul className="status-list">
                                    <li
                                      onClick={() => handleProjectStatusChange("All")}
                                      className={projectStatusFilter === "All" ? "selected-item" : ""}
                                    >
                                      All
                                    </li>
                                    <li
                                      onClick={() => handleProjectStatusChange("Active")}
                                      className={projectStatusFilter === "Active" ? "selected-item" : ""}
                                    >
                                      Active
                                    </li>
                                    <li
                                      onClick={() => handleProjectStatusChange("Inactive")}
                                      className={projectStatusFilter === "Inactive" ? "selected-item" : ""}
                                    >
                                      Inactive
                                    </li>
                                  </ul>
                                )}
                              </>
                            )}
                          <ul className="client-list project-list">
                            
                            {filteredClientData.map((client) => (
                              <div
                                key={client._id}
                                className="main-outer-item-dd"
                              >
                                {/* Render the client name */}
                                <div
                                  className={`client-name1 ${selectedClients.has(client)
                                    ? "selected"
                                    : ""
                                    }`}
                                  onClick={() => {
                                    if (client.projects.filter(
                                      (project) =>
                                        project.project_name
                                          .toLowerCase()
                                          .includes(filter.toLowerCase()) &&
                                        (projectStatusFilter === "All"
                                          ? true
                                          : projectStatusFilter === "Active"
                                            ? project.is_active === true
                                            : project.is_active === false)
                                    ).length > 0) {
                                      // Toggle the client in the Set
                                      const newSelectedClients = new Set(
                                        selectedClients
                                      );
                                      if (selectedClients.has(client)) {
                                        newSelectedClients.delete(client);
                                      } else {
                                        newSelectedClients.add(client);
                                      }
                                      setSelectedClients(newSelectedClients);
                                    }
                                  }}
                                >
                                  <span className="main-project">
                                    {client.client_name}{" "}
                                  </span>
                                  {client.projects.filter(
                                    (project) =>
                                      project.project_name
                                        .toLowerCase()
                                        .includes(filter.toLowerCase()) &&
                                      (projectStatusFilter === "All"
                                        ? true
                                        : projectStatusFilter === "Active"
                                          ? project.is_active === true
                                          : project.is_active === false)
                                  ).length > 0 && (
                                      <span
                                        className={`arrow ${selectedClients.has(client)
                                          ? "open"
                                          : ""
                                          }`}
                                      >
                                        <span className="main-project-title">
                                          {client.projects.filter(
                                            (project) =>
                                              project.project_name
                                                .toLowerCase()
                                                .includes(filter.toLowerCase()) &&
                                              (projectStatusFilter === "All"
                                                ? true
                                                : projectStatusFilter === "Active"
                                                  ? project.is_active === true
                                                  : project.is_active === false)
                                          ).length} Projects
                                        </span>
                                        {selectedClients.has(client) ? (
                                          <img src={TopArrow} alt="Open" />
                                        ) : (
                                          <img src={DownArrow} alt="Close" />
                                        )}
                                      </span>
                                    )}
                                </div>
                                {selectedClients.has(client) && (
                                  <div className="project-name1-outer">
                                    {/* Render projects under the selected client */}
                                    {client.projects.filter(
                                      (project) =>
                                        project.project_name
                                          .toLowerCase()
                                          .includes(filter.toLowerCase()) &&
                                        (projectStatusFilter === "All"
                                          ? true
                                          : projectStatusFilter === "Active"
                                            ? project.is_active === true
                                            : project.is_active === false)
                                    ).length === 0 ? (
                                      <div>No projects under this client</div>
                                    ) : (
                                      client.projects
                                        .filter((project) =>
                                          project.project_name
                                            .toLowerCase()
                                            .includes(filter.toLowerCase()) &&
                                          (projectStatusFilter === "All"
                                            ? true
                                            : projectStatusFilter === "Active"
                                              ? project.is_active === true
                                              : project.is_active === false)
                                        )
                                        .sort((a, b) =>
                                          a.project_name.localeCompare(
                                            b.project_name
                                          )
                                        )
                                        .map((project) => (
                                          <div key={project._id}>
                                            <div
                                              className={`project-name1 ${selectedProject === project
                                                ? "selected"
                                                : ""
                                                }`}
                                            >
                                              <div
                                                onClick={(e) => {
                                                  setSprintName("");
                                                  setSprintId("");
                                                  setProjectName(project.project_name);
                                                  setProjectId(project._id);
                                                  setProjectIdEdit(project._id);
                                                  setProjectNameEdit(project.project_name);
                                                  setProjectDropdownOpen(false);
                                                  setClient(client.client_name && client.client_name);
                                                  setClientId(client._id && client._id);
                                                  setIsBillable(project.is_billable);
                                                }}
                                              >
                                                <span className="project-dd-title">
                                                  {project.project_name}{" "}
                                                </span>
                                              </div>
                                              <div>
                                                {project.sprints.length > 0 && (
                                                  <span
                                                    className={`arrow ${selectedProject === project ? "open" : ""}`}
                                                    onClick={() =>
                                                      setSelectedProject(selectedProject === project ? null : project)
                                                    }
                                                  >
                                                    <span className="main-project-title">
                                                      {project.sprints.length}{" "}
                                                      Sprints
                                                    </span>
                                                    {selectedProject ===
                                                      project ? (
                                                      <img src={TopArrow} alt="Open" />
                                                    ) : (
                                                      <img src={DownArrow} alt="Close" />
                                                    )}
                                                  </span>
                                                )}
                                              </div>
                                            </div>
                                            {selectedProject === project && (
                                              <div>
                                                {/* Render sprints under the selected project */}
                                                {project.sprints.length === 0 ? (
                                                  <div>
                                                    No sprints under this project
                                                  </div>
                                                ) : (
                                                  <ul className="sprint-list">
                                                    {project.sprints
                                                      .sort((a, b) =>
                                                        a.sprint_name.localeCompare(b.sprint_name)
                                                      )
                                                      .map((sprint) => (
                                                        <li
                                                          key={sprint._id}
                                                          onClick={(e) => {
                                                            setProjectName(project.project_name);
                                                            setProjectId(project._id);
                                                            setProjectIdEdit(project._id);
                                                            setProjectNameEdit(project.project_name);
                                                            setProjectDropdownOpen(false);
                                                            setClient(client.client_name && client.client_name);
                                                            setClientId(client._id && client._id);
                                                            setIsBillable(project.is_billable);
                                                            setSprintId(sprint._id);
                                                            setSprintName(sprint.sprint_name);
                                                          }}
                                                        >
                                                          {sprint.sprint_name}
                                                        </li>
                                                      ))}
                                                  </ul>
                                                )}
                                              </div>
                                            )}
                                          </div>
                                        ))
                                    )}
                                  </div>
                                )}
                              </div>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div className="timer-right">
                  {windowWidth >= 1441 && (
                    <div
                      className="project-sprint hide-on-small-desktop"
                      ref={projectDropdownRef}
                    >
                      <Col
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setProjectDropdownOpen(!projectDropdownOpen);
                          setProjectStatusFilter("Active");
                          setFilter("");
                          // if (!projectName) {
                          setSelectedClients(new Set());
                          setSelectedProject(null);
                          // }
                          setEditedId(null);
                          setTagDropdownOpen(false);
                          setProjectEditDropdownOpen(false);
                          setTagEditDropdownOpen(false);
                        }}
                        className="project-sprint-outer"
                      >
                        {!projectName && (
                          <>
                            <img src={Plus} alt="plus" />{" "}
                          </>
                        )}
                        {projectName && (
                          <span
                            className={`tooltip-tracker ${letterCount < 34 ? "small" : "large"
                              }`}
                          >
                            {projectName ? projectName : "Sprint/Project"}
                            {sprintName ? ` - ${sprintName}` : ""}
                            {projectName && (
                              <div className="project-name-tracker">
                                . {client}
                              </div>
                            )}
                          </span>
                        )}
                        <span
                          className={`tracker-project-tag-project`}
                          ref={trackerOuterRef2}
                          style={{
                            display: "inline-block",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            height: "22px",
                            maxWidth: "150px",
                          }}
                        >
                          {projectName ? projectName : "Sprint/Project"}
                          {sprintName ? ` - ${sprintName}` : ""}
                          {projectName && (
                            <div className="project-name-tracker">
                              . {client}
                            </div>
                          )}
                        </span>
                      </Col>
                      {projectDropdownOpen && (
                        <div className="custom-dropdown">
                          <input
                            type="text"
                            className="search-bar"
                            placeholder="Find Project or Client"
                            value={filter}
                            onChange={(e) => {
                              const filterValue = e.target.value;
                              setFilter(filterValue);
                              if (filterValue === "") {
                                setSelectedClients(new Set());
                              } else {
                                // Check if the filtered project exists under any client
                                const matchingClients =
                                  filteredClientData.filter((client) =>
                                    client.projects.some((project) =>
                                      project.project_name
                                        .toLowerCase()
                                        .includes(filterValue.toLowerCase()) &&
                                      (projectStatusFilter === "All"
                                        ? true
                                        : projectStatusFilter === "Active"
                                          ? project.is_active === true
                                          : project.is_active === false)
                                    )
                                  );
                                setSelectedClients(new Set(matchingClients));
                              }
                            }}
                            autocomplete="off"
                          />
                          <ul className={`client-list client-list-filter ${(user.userType !== userTypeConsts.SUPER_ADMIN && user.userType !== userTypeConsts.ADMIN) ? 'filter-active-no' : ''}`}>
                            {(user.userType === userTypeConsts.SUPER_ADMIN || user.userType === userTypeConsts.ADMIN) && (
                              <>
                                <div className="user-status-dropdown">
                                  <div className="user-status-dropdown-label" htmlFor="projectStatusFilter">Filter by Status</div>
                                  <div className="custom-dropdown-user" ref={projectStatusDropdownRef}>
                                    <div className="showing-name" onClick={toggleProjectStatusDropdown}>
                                      {projectStatusFilter ? projectStatusFilter : "Active"}
                                    </div>
                                  </div>
                                </div>
                                {isProjectStatusDropdownOpen && (
                                  <ul className="status-list">
                                    <li
                                      onClick={() => handleProjectStatusChange("All")}
                                      className={projectStatusFilter === "All" ? "selected-item" : ""}
                                    >
                                      All
                                    </li>
                                    <li
                                      onClick={() => handleProjectStatusChange("Active")}
                                      className={projectStatusFilter === "Active" ? "selected-item" : ""}
                                    >
                                      Active
                                    </li>
                                    <li
                                      onClick={() => handleProjectStatusChange("Inactive")}
                                      className={projectStatusFilter === "Inactive" ? "selected-item" : ""}
                                    >
                                      Inactive
                                    </li>
                                  </ul>
                                )}
                              </>
                            )}
                            {filteredClientData.map((client) => (
                              <div
                                key={client._id}
                                className="main-outer-item-dd"
                              >
                                {/* Render the client name */}
                                <div
                                  className={`client-name1 ${selectedClients.has(client)
                                    ? "selected"
                                    : ""
                                    }`}
                                  onClick={() => {
                                    if (client.projects.filter(
                                      (project) =>
                                        project.project_name
                                          .toLowerCase()
                                          .includes(filter.toLowerCase()) &&
                                        (projectStatusFilter === "All"
                                          ? true
                                          : projectStatusFilter === "Active"
                                            ? project.is_active === true
                                            : project.is_active === false)
                                    ).length > 0) {
                                      // Toggle the client in the Set
                                      const newSelectedClients = new Set(
                                        selectedClients
                                      );
                                      if (selectedClients.has(client)) {
                                        newSelectedClients.delete(client);
                                      } else {
                                        newSelectedClients.add(client);
                                      }
                                      setSelectedClients(newSelectedClients);
                                    }
                                  }}
                                >
                                  <span className="main-project">
                                    {client.client_name}{" "}
                                  </span>
                                  {client.projects.filter(
                                    (project) =>
                                      project.project_name
                                        .toLowerCase()
                                        .includes(filter.toLowerCase()) &&
                                      (projectStatusFilter === "All"
                                        ? true
                                        : projectStatusFilter === "Active"
                                          ? project.is_active === true
                                          : project.is_active === false)
                                  ).length > 0 && (
                                      <span
                                        className={`arrow ${selectedClients.has(client)
                                          ? "open"
                                          : ""
                                          }`}
                                      >
                                        <span className="main-project-title">
                                          {client.projects.filter(
                                            (project) =>
                                              project.project_name
                                                .toLowerCase()
                                                .includes(filter.toLowerCase()) &&
                                              (projectStatusFilter === "All"
                                                ? true
                                                : projectStatusFilter === "Active"
                                                  ? project.is_active === true
                                                  : project.is_active === false)
                                          ).length} Projects
                                        </span>
                                        {selectedClients.has(client) ? (
                                          <img src={TopArrow} alt="Open" />
                                        ) : (
                                          <img src={DownArrow} alt="Close" />
                                        )}
                                      </span>
                                    )}
                                </div>
                                {selectedClients.has(client) && (
                                  <div className="project-name1-outer">
                                    {/* Render projects under the selected client */}
                                    {client.projects.filter(
                                      (project) =>
                                        project.project_name
                                          .toLowerCase()
                                          .includes(filter.toLowerCase()) &&
                                        (projectStatusFilter === "All"
                                          ? true
                                          : projectStatusFilter === "Active"
                                            ? project.is_active === true
                                            : project.is_active === false)
                                    ).length === 0 ? (
                                      <div>No projects under this client</div>
                                    ) : (
                                      client.projects
                                        .filter((project) =>
                                          project.project_name
                                            .toLowerCase()
                                            .includes(filter.toLowerCase()) &&
                                          (projectStatusFilter === "All"
                                            ? true
                                            : projectStatusFilter === "Active"
                                              ? project.is_active === true
                                              : project.is_active === false)
                                        )
                                        .sort((a, b) =>
                                          a.project_name.localeCompare(
                                            b.project_name
                                          )
                                        )
                                        .map((project) => (
                                          <div key={project._id}>
                                            <div
                                              className={`project-name1 ${selectedProject === project
                                                ? "selected"
                                                : ""
                                                }`}
                                            >
                                              <div
                                                onClick={(e) => {
                                                  setSprintName("");
                                                  setSprintId("");
                                                  setProjectName(project.project_name);
                                                  setProjectId(project._id);
                                                  setProjectIdEdit(project._id);
                                                  setProjectNameEdit(project.project_name);
                                                  setProjectDropdownOpen(false);
                                                  setClient(client.client_name && client.client_name);
                                                  setClientId(client._id && client._id);
                                                  setIsBillable(project.is_billable);
                                                }}
                                              >
                                                <span className="project-dd-title">
                                                  {project.project_name}{" "}
                                                </span>
                                              </div>
                                              <div>
                                                {project.sprints.length > 0 && (
                                                  <span
                                                    className={`arrow ${selectedProject === project ? "open" : ""}`}
                                                    onClick={() =>
                                                      setSelectedProject(selectedProject === project ? null : project)
                                                    }
                                                  >
                                                    <span className="main-project-title">
                                                      {project.sprints.length}{" "}
                                                      Sprints
                                                    </span>
                                                    {selectedProject === project ? (
                                                      <img src={TopArrow} alt="Open" />
                                                    ) : (
                                                      <img src={DownArrow} alt="Close" />
                                                    )}
                                                  </span>
                                                )}
                                              </div>
                                            </div>
                                            {selectedProject === project && (
                                              <div>
                                                {/* Render sprints under the selected project */}
                                                {project.sprints.length === 0 ? (
                                                  <div>
                                                    No sprints under this project
                                                  </div>
                                                ) : (
                                                  <ul className="sprint-list">
                                                    {project.sprints
                                                      .sort((a, b) =>
                                                        a.sprint_name.localeCompare(b.sprint_name)
                                                      )
                                                      .map((sprint) => (
                                                        <li
                                                          key={sprint._id}
                                                          onClick={(e) => {
                                                            setProjectName(project.project_name);
                                                            setProjectId(project._id);
                                                            setProjectIdEdit(project._id);
                                                            setProjectNameEdit(project.project_name);
                                                            setProjectDropdownOpen(false);
                                                            setClient(client.client_name && client.client_name);
                                                            setClientId(client._id && client._id);
                                                            setIsBillable(project.is_billable);
                                                            setSprintId(sprint._id);
                                                            setSprintName(sprint.sprint_name);
                                                          }}
                                                        >
                                                          {sprint.sprint_name}
                                                        </li>
                                                      ))}
                                                  </ul>
                                                )}
                                              </div>
                                            )}
                                          </div>
                                        ))
                                    )}
                                  </div>
                                )}
                              </div>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  )}
                  <div className="outer-small-device-timer">
                    <div className="inner-left-samll-device">
                      {windowWidth >= 1200 && windowWidth <= 1440 && (
                        <div className="tag-section-outer" ref={tagDropdownRef}>
                          <div
                            style={{ cursor: "pointer" }}
                            className="tag-section"
                            onClick={() => {
                              setTagDropdownOpen(!tagDropdownOpen);
                              setEditedId(null);
                              setProjectDropdownOpen(false);
                              setProjectEditDropdownOpen(false);
                              setTagEditDropdownOpen(false);
                            }}
                          >
                            {!tag && (
                              <>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path
                                    d="M13.9119 23C13.1542 23 12.4419 22.7048 11.9066 22.1695L1.83081 12.0945C1.17489 11.4386 0.881077 10.5022 1.04426 9.5892L1.99398 4.28519C2.20208 3.12282 3.12293 2.202 4.28535 1.99391L9.58953 1.04422C10.5067 0.881044 11.4395 1.1753 12.0949 1.83074L22.1712 11.9052C23.2763 13.0108 23.2763 14.8102 22.1712 15.9158L15.9172 22.1695C15.3819 22.7053 14.6696 23 13.9119 23ZM20.8749 13.2014L10.7992 3.12649C10.6094 2.93674 10.3532 2.83269 10.0896 2.83269C10.0309 2.83269 9.97181 2.83773 9.91314 2.84827L4.60895 3.79797C4.19826 3.87131 3.87282 4.19673 3.79902 4.60787L2.84929 9.91187C2.79154 10.2346 2.89559 10.5659 3.12706 10.7979L13.2028 20.8728C13.5814 21.2514 14.2415 21.2514 14.6201 20.8728L20.874 14.6191C21.265 14.2281 21.265 13.5919 20.874 13.201L20.8749 13.2014Z"
                                    fill="#96A8BA"
                                    stroke="white"
                                    strokeWidth="0.2"
                                  />
                                  <path
                                    d="M7.5 9C6.67157 9 6 8.32843 6 7.5C6 6.67157 6.67157 6 7.5 6C8.32843 6 9 6.67157 9 7.5C9 8.32843 8.32843 9 7.5 9Z"
                                    fill="#96A8BA"
                                    stroke="white"
                                    strokeWidth="0.2"
                                  />
                                </svg>{" "}
                              </>
                            )}
                            {tag && (
                              <span className="tooltip-tracker">{tag}</span>
                            )}
                            {tag && (
                              <p
                                style={{
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  maxWidth: "150px",
                                }}
                              >
                                {tag}
                              </p>
                            )}
                          </div>
                          {tagDropdownOpen && (
                            <div className="custom-dropdown">
                              <input
                                type="text"
                                className="search-bar"
                                placeholder="Search..."
                                value={tag}
                                onChange={handleTagInputChange}
                                autocomplete="off"
                              />
                              <ul className="client-list">
                                {filteredTagItems.map((item) => (
                                  <li
                                    className="cursor"
                                    key={item._id}
                                    onClick={(e) => {
                                      setTag(item.tag_name);
                                      setTagId(item._id);
                                      setTagIdEdit(item._id);
                                      setTagDropdownOpen(false);
                                    }}
                                  >
                                    {item.tag_name}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    <div className="inner-right-small-device">
                      <div className="tag-billing">
                        {(windowWidth >= 1441 || windowWidth < 1200) && (
                          <div
                            className="tag-section-outer"
                            ref={tagDropdownRef}
                          >
                            <div
                              style={{ cursor: "pointer" }}
                              className="tag-section"
                              onClick={() => {
                                setTagDropdownOpen(!tagDropdownOpen);
                                setEditedId(null);
                                setProjectDropdownOpen(false);
                                setProjectEditDropdownOpen(false);
                                setTagEditDropdownOpen(false);
                              }}
                            >
                              {!tag && (
                                <>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <path
                                      d="M13.9119 23C13.1542 23 12.4419 22.7048 11.9066 22.1695L1.83081 12.0945C1.17489 11.4386 0.881077 10.5022 1.04426 9.5892L1.99398 4.28519C2.20208 3.12282 3.12293 2.202 4.28535 1.99391L9.58953 1.04422C10.5067 0.881044 11.4395 1.1753 12.0949 1.83074L22.1712 11.9052C23.2763 13.0108 23.2763 14.8102 22.1712 15.9158L15.9172 22.1695C15.3819 22.7053 14.6696 23 13.9119 23ZM20.8749 13.2014L10.7992 3.12649C10.6094 2.93674 10.3532 2.83269 10.0896 2.83269C10.0309 2.83269 9.97181 2.83773 9.91314 2.84827L4.60895 3.79797C4.19826 3.87131 3.87282 4.19673 3.79902 4.60787L2.84929 9.91187C2.79154 10.2346 2.89559 10.5659 3.12706 10.7979L13.2028 20.8728C13.5814 21.2514 14.2415 21.2514 14.6201 20.8728L20.874 14.6191C21.265 14.2281 21.265 13.5919 20.874 13.201L20.8749 13.2014Z"
                                      fill="#96A8BA"
                                      stroke="white"
                                      strokeWidth="0.2"
                                    />
                                    <path
                                      d="M7.5 9C6.67157 9 6 8.32843 6 7.5C6 6.67157 6.67157 6 7.5 6C8.32843 6 9 6.67157 9 7.5C9 8.32843 8.32843 9 7.5 9Z"
                                      fill="#96A8BA"
                                      stroke="white"
                                      strokeWidth="0.2"
                                    />
                                  </svg>{" "}
                                </>
                              )}
                              {tag && (
                                <span className="tooltip-tracker">{tag}</span>
                              )}
                              {tag && (
                                <p
                                  style={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    maxWidth: "150px",
                                  }}
                                >
                                  {tag}
                                </p>
                              )}
                            </div>
                            {tagDropdownOpen && (
                              <div className="custom-dropdown">
                                <input
                                  type="text"
                                  className="search-bar"
                                  placeholder="Search..."
                                  value={tag}
                                  onChange={handleTagInputChange}
                                  autocomplete="off"
                                />
                                <ul className="client-list">
                                  {filteredTagItems.map((item) => (
                                    <li
                                      className="cursor"
                                      key={item._id}
                                      onClick={(e) => {
                                        setTag(item.tag_name);
                                        setTagId(item._id);
                                        setTagIdEdit(item._id);
                                        setTagDropdownOpen(false);
                                      }}
                                    >
                                      {item.tag_name}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            )}
                          </div>
                        )}
                        <div className="billable-section">
                          {isBillable ? (
                            <div className="billable-timing-sec billable-timing">
                              <svg
                                width="13"
                                height="20"
                                viewBox="0 0 13 20"
                                fill="#405BFF"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M5.544 19.32V17.4C3.264 17.184 1.464 16.2 0.024 14.688L1.872 12.792C3.288 14.232 4.848 14.952 6.648 14.904C8.304 14.88 9.408 14.232 9.408 13.176C9.408 11.808 8.064 11.664 5.616 11.04C3.336 10.464 0.384 9.72 0.384 6.792C0.384 4.32 2.736 2.568 5.592 2.376V0.503999H7.248V2.424C9.024 2.64 10.776 3.408 12.192 4.848L10.368 6.744C8.952 5.448 7.704 4.8 6.072 4.8C4.32 4.8 3.168 5.424 3.168 6.48C3.168 7.656 4.464 8.064 6.936 8.592C9.24 9.12 12.384 9.768 12.384 12.888C12.384 15.6 10.08 17.184 7.2 17.4V19.32H5.544Z"
                                  fill="#96A8BA"
                                />
                              </svg>
                            </div>
                          ) : (
                            <div className="billable-timing-sec non-billable-timing">
                              <svg
                                width="13"
                                height="20"
                                viewBox="0 0 13 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M5.544 19.32V17.4C3.264 17.184 1.464 16.2 0.024 14.688L1.872 12.792C3.288 14.232 4.848 14.952 6.648 14.904C8.304 14.88 9.408 14.232 9.408 13.176C9.408 11.808 8.064 11.664 5.616 11.04C3.336 10.464 0.384 9.72 0.384 6.792C0.384 4.32 2.736 2.568 5.592 2.376V0.503999H7.248V2.424C9.024 2.64 10.776 3.408 12.192 4.848L10.368 6.744C8.952 5.448 7.704 4.8 6.072 4.8C4.32 4.8 3.168 5.424 3.168 6.48C3.168 7.656 4.464 8.064 6.936 8.592C9.24 9.12 12.384 9.768 12.384 12.888C12.384 15.6 10.08 17.184 7.2 17.4V19.32H5.544Z"
                                  fill="#96A8BA"
                                />
                              </svg>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="manual-time-sec">
                        <div className="time-overview-sec">
                          {appSettings.timeFormat === 24 ? (
                            <>
                              <input
                                ref={startInputRef}
                                style={{
                                  backgroundColor:
                                    uManualStartTimeError && "#fddada",
                                }}
                                type="text"
                                name="start_time"
                                autoComplete="off"
                                value={startTimeManual24}
                                onChange={(e) => {
                                  if (validateNoAlphabets(e.target.value)) {
                                    setStartTimeManual24(e.target.value);
                                  }
                                }}
                                onBlur={() => {
                                  if (!startTimeEdit)
                                    setStartTimeEdit("7:46pm");
                                }}
                                className="edit-time edit-time-manual edit-time-start"
                              />
                              -
                              <input
                                ref={endInputRef}
                                style={{
                                  backgroundColor:
                                    uManualEndTimeError && "#fddada",
                                }}
                                type="text"
                                name="end_time"
                                value={endTimeManual24}
                                onChange={(e) => {
                                  if (validateNoAlphabets(e.target.value)) {
                                    setEndTimeManual24(e.target.value);
                                  }
                                }}
                                onBlur={() => {
                                  if (!endTimeEdit) setEndTimeEdit("8:50pm");
                                }}
                                className="edit-time edit-time-manual edit-time-end"
                                autocomplete="off"
                              />
                            </>
                          ) : (
                            <>
                              <input
                                ref={start12HrTimeInputRef}
                                style={{
                                  backgroundColor:
                                    uManualStartTimeError && "#fddada",
                                }}
                                type="text"
                                name="start_time"
                                value={startTimeManual}
                                onChange={(e) => {
                                  setStartTimeManual(e.target.value);
                                }}
                                onBlur={() => {
                                  if (!startTimeEdit)
                                    setStartTimeEdit("7:46pm");
                                }}
                                className="edit-time edit-time-manual edit-time-start"
                                autocomplete="off"
                              />
                              -
                              <input
                                ref={end12HrTimeInputRef}
                                style={{
                                  backgroundColor:
                                    uManualEndTimeError && "#fddada",
                                }}
                                type="text"
                                name="end_time"
                                value={endTimeManual}
                                onChange={(e) => {
                                  setEndTimeManual(e.target.value);
                                }}
                                onBlur={() => {
                                  if (!endTimeEdit) setEndTimeEdit("8:50pm");
                                }}
                                className="edit-time edit-time-manual edit-time-end"
                                autocomplete="off"
                              />
                            </>
                          )}
                        </div>
                        <div className="date-overview-sec manual-tracker-outer-sec">
                          <div
                            onClick={handleDateIconClick}
                            className="custom-date-icon"
                          >
                            {/* Your custom date picker icon SVG or image here */}
                            <svg
                              width="1em"
                              height="1em"
                              viewBox="0 0 14 14"
                              fill="currentColor"
                              aria-hidden="true"
                              focusable="false"
                              className="rs-picker-toggle-caret rs-icon"
                              aria-label="calendar"
                              data-category="legacy"
                            >
                              <path d="M1 4v8.5a.5.5 0 00.5.5H8c0-.128.049-.256.146-.354.555-.555.854-1.6.854-3.146a.5.5 0 01.621-.485l.119.03A2.623 2.623 0 0012.999 6.5V4h-12zm3-2h6V0h1v2h1.5A1.5 1.5 0 0114 3.5v3a3.623 3.623 0 01-4.015 3.603c-.064 1.245-.335 2.212-.831 2.898H12.5a.5.5 0 00.5-.5v-2a.5.5 0 011 0v2a1.5 1.5 0 01-1.5 1.5h-11a1.5 1.5 0 01-1.5-1.5v-9a1.5 1.5 0 011.5-1.5H3v-2h1v2zm5 4V5h1v1h2v1h-2v1H9V7H7v2h1v1H7v2H6v-2H4v2H3v-2H1V9h2V7H1V6h2V5h1v1h2V5h1v1h2zM6 9V7H4v2h2z"></path>
                            </svg>
                          </div>
                          <DatePicker
                            value={date}
                            onChange={(value) => {
                              setDateOldEdit(date);
                              setDate(value);
                            }}
                            format="dd/MM/yyyy"
                            className="timer-date time-date-manual"
                            container={document.querySelector(
                              ".manual-time-sec .date-overview-sec"
                            )}
                            placement="bottomEnd"
                            oneTap
                            timeZone={global.config.getTimeZone()}
                            isoWeek={
                              appSettings.weekStartDay &&
                              appSettings.weekStartDay === "Monday"
                            }
                          />
                        </div>
                      </div>

                      <div className="start-timer-outer">
                        <Col className="start-timer-inner">
                          {appSettings.timeFormat === 24 ? (
                            <input
                              className="SmallInput px-2 py-2 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full"
                              style={{
                                backgroundColor:
                                  uManualFormatedTimeError && "#fddada",
                              }}
                              value={uFormatedTimeManual}
                              onChange={(e) => {
                                // handleTimeChange
                                manualTimeShowEdit24(e.target.value);
                              }}
                              placeholder="00:00:00"
                              type="text"
                              autocomplete="off"
                            />
                          ) : (
                            <>
                              <input
                                className="SmallInput px-2 py-2 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full"
                                style={{
                                  backgroundColor:
                                    uManualFormatedTimeError && "#fddada",
                                }}
                                value={uFormatedTimeManual}
                                onChange={(e) => {
                                  // handleTimeChange
                                  manualTimeShowEdit(e.target.value);
                                }}
                                placeholder="00:00:00"
                                type="text"
                                autocomplete="off"
                              />
                            </>
                          )}

                          {isRunning === true && customeTime === false && (
                            <button
                              onClick={handleEndClick}
                              className="button-pad bg-pink-500 text-white active:bg-pink-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                              type="button"
                            >
                              Stop
                            </button>
                          )}

                          {customeTime === true && (
                            <button
                              onClick={handleSetCustomeTimes}
                              className="button-pad-add"
                              disabled={
                                (isCustomeTimeLoading ||
                                  uManualFormatedTimeError ||
                                  uManualEndTimeError ||
                                  uManualStartTimeError) &&
                                "disabled"
                              }
                              type="button"
                            >
                              {isCustomeTimeLoading ? (
                                <ThreeDots
                                  height="18"
                                  width="20"
                                  radius="9"
                                  color="#6479f9"
                                  ariaLabel="three-dots-loading"
                                  wrapperStyle={{}}
                                  wrapperClassName=""
                                  visible={true}
                                />
                              ) : (
                                <img src={PlusCircle} alt="plus-icon" />
                              )}
                            </button>
                          )}
                        </Col>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <ToastContainer />

        {timer.length > 0 ? (
          currentPageData.map((item, index) => (
            <div>
              {new Date(item[0]).toDateString() ===
                highestDate.toDateString() && (
                  <div>
                    {timer.length > 0 ? (
                      <div className="total-time-tracked">
                        <div className="total-time-date-left">This Week </div>
                        <div className="total-time-date-right">
                          Week Total:
                          <ThisWeekTotalDisplay
                            startDate={leastDate.toDateString()}
                            endDate={highestDate.toDateString()}
                            selectedUser={selectedUser}
                          />
                        </div>
                      </div>
                    ) : isTimerDataLoading === true ? (
                      <></>
                    ) : (
                      <></>
                    )}
                  </div>
                )}

              {new Date(item[0]).toDateString() ===
                highestLastWeekDate.toDateString() && (
                  <div>
                    {timer.length > 0 ? (
                      <div className="total-time-tracked">
                        <div className="total-time-date-left">Last Week</div>
                        <div className="total-time-date-right">
                          Week Total:
                          <WeekTotalDisplay
                            startDate={getMomentDate(
                              leastLastWeekDate,
                              "ddd MMM DD YYYY"
                            )}
                            endDate={getMomentDate(
                              highestLastWeekDate,
                              "ddd MMM DD YYYY"
                            )}
                            selectedUser={selectedUser}
                          />
                        </div>
                      </div>
                    ) : isTimerDataLoading === true ? (
                      <></>
                    ) : (
                      <></>
                    )}
                  </div>
                )}

              {extremesByWeek.some(
                (extreme) =>
                  extreme.highestWeekDate.toDateString() ===
                  new Date(item[0]).toDateString()
              ) &&
                new Date(item[0]).toDateString() !==
                highestDate.toDateString() &&
                new Date(item[0]).toDateString() !==
                highestLastWeekDate.toDateString() && (
                  <div className="total-time-tracked">
                    <div className="total-time-date-left">
                      {`${moment(
                        getMomentDate(
                          findLeastDate(new Date(item[0]), extremesByWeek),
                          "ddd MMM DD YYYY"
                        )
                      )
                        .tz(global.config.getTimeZone())
                        .format("MMM D")} - ${moment(
                          getMomentDate(
                            findExtremeDate(
                              new Date(item[0]),
                              extremesByWeek
                            ).toDateString(),
                            "ddd MMM DD YYYY"
                          )
                        )
                          .tz(global.config.getTimeZone())
                          .format("MMM D")}`}
                      {new Date(item[0]).getFullYear() !==
                        new Date().getFullYear() && (
                          <span>
                            <span>,</span> {new Date(item[0]).getFullYear()}
                          </span>
                        )}
                    </div>

                    <div className="total-time-date-right">
                      Week Total:
                      <WeekTotalDisplay
                        selectedUser={selectedUser}
                        startDate={findLeastDate(
                          new Date(item[0]),
                          extremesByWeek
                        ).toDateString()}
                        endDate={moment(
                          getMomentDate(
                            findExtremeDate(
                              new Date(item[0]),
                              extremesByWeek
                            ).toDateString(),
                            "ddd MMM DD YYYY"
                          )
                        )
                          .tz(global.config.getTimeZone())
                          .format("MMM DD YYYY")}
                      />
                    </div>
                  </div>
                )}
              <TimerCard
                index={index}
                item={item}
                resumeTimeTracking={resumeTimeTracking}
                timeFormat={appSettings.timeFormat}
                resumeLoading={resumeLoading}
                selectedUser={selectedUser}
              />
            </div>
          ))
        ) : isTimerDataLoading === true ? (
          <div className="outter-load-table">
            <ThreeDots
              height="80"
              width="80"
              radius="9"
              color="#405BFF"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        ) : (
          <>No Results</>
        )}
        {/* </>
      )} */}

        <Modal
          isOpen={taskModalIsOpen}
          onRequestClose={() => setTaskModalIsOpen(false)}
        >
          <h5>Task</h5>
          <input
            type="text"
            placeholder="What are you working on?"
            value={taskName}
            onChange={(e) => setTaskName(e.target.value)}
            autocomplete="off"
          />
          <button onClick={() => setTaskModalIsOpen(false)}>Close Modal</button>
        </Modal>
        <div className="pagination-outer">
          <div class="showing-count">
            {isTimerDataLoading ? (
              <></>
            ) : (
              <>
                {timer.length > 0 ? (
                  <div class="filter-inner showing-result">
                    <label>Showing</label>
                    <div
                      ref={showingDropdownRef}
                      className="select-modal-mainM custom-dropdown-user-new-outer"
                    >
                      <div
                        className="selected-option custom-dropdown-user-new"
                        onClick={() =>
                          setShowingDropdownOpen(!showingDropdownOpen)
                        }
                      >
                        {totalResults < 10
                          ? totalResults.toString()
                          : perPage.toString()}
                      </div>
                      {showingDropdownOpen && (
                        <div className="custom-dropdown-new">
                          <ul className="showing-options-list">
                            {totalResults >= 10 && (
                              <li
                                className={
                                  perPage === 10 ? "selected-item" : ""
                                }
                                onClick={() => {
                                  setPerPage(10);
                                  setSelectedPage(0);
                                  setShowingDropdownOpen(false);
                                }}
                              >
                                10
                              </li>
                            )}
                            {totalResults >= 20 && (
                              <li
                                className={
                                  perPage === 20 ? "selected-item" : ""
                                }
                                onClick={() => {
                                  setPerPage(20);
                                  setSelectedPage(0);
                                  setShowingDropdownOpen(false);
                                }}
                              >
                                20
                              </li>
                            )}
                            {totalResults >= 30 && (
                              <li
                                className={
                                  perPage === 30 ? "selected-item" : ""
                                }
                                onClick={() => {
                                  setPerPage(30);
                                  setSelectedPage(0);
                                  setShowingDropdownOpen(false);
                                }}
                              >
                                30
                              </li>
                            )}
                            {totalResults >= 40 && (
                              <li
                                className={
                                  perPage === 40 ? "selected-item" : ""
                                }
                                onClick={() => {
                                  setPerPage(40);
                                  setSelectedPage(0);
                                  setShowingDropdownOpen(false);
                                }}
                              >
                                40
                              </li>
                            )}
                            {totalResults >= 10 && (
                              <li
                                className={
                                  totalResults >= 10 && perPage === totalResults
                                    ? "selected-item"
                                    : ""
                                }
                                onClick={() => {
                                  setPerPage(totalResults);
                                  setSelectedPage(0);
                                  setShowingDropdownOpen(false);
                                }}
                              >
                                ALL
                              </li>
                            )}
                            {/* Add more conditions as needed */}
                            {totalResults < 10 ? (
                              <li
                                className={
                                  totalResults < 10 ? "selected-item" : ""
                                }
                                onClick={() => {
                                  setPerPage(totalResults);
                                  setSelectedPage(0);
                                  setShowingDropdownOpen(false);
                                }}
                              >
                                {totalResults}
                              </li>
                            ) : null}
                          </ul>
                        </div>
                      )}
                    </div>
                    <label>of {totalResults} Results</label>
                  </div>
                ) : isTimerDataLoading === true ? (
                  <ThreeDots
                    height="80"
                    width="80"
                    radius="9"
                    color="#405BFF"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                ) : (
                  <></>
                )}
              </>
            )}
          </div>
          <div className="pagination">
            {isTimerDataLoading ? (
              <></>
            ) : (
              <>
                {pageCount > 1 && (
                  <ReactPaginate
                    previousLabel={selectedPage > 0 ? "< Back" : null}
                    nextLabel={selectedPage < pageCount - 1 ? "Next >" : null}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={pageCount}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={1}
                    onPageChange={handlePageChange}
                    containerClassName={"pagination maininteampag"}
                    activeClassName={"active"}
                    forcePage={selectedPage}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {showTimeZoneModal === true && <TimeZoneModal />}
    </motion.div>
  );
};

export default Tracker;
