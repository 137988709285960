import React, { useState, createRef, useEffect } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import "./Demo.css";
import axios from "axios";
import Calendar from "./components/Calendar";
import GradualNumberLoader from "./GradualNumberLoader";
import SmoothLoadMap from "./SmoothLoadMap";


const items = Array.from({ length: 100 }, (_, i) => `Item ${i + 1}`); // Sample data





const Test = () => {

   
   
    const onChange = (ranges) => {

        try {

            console.log(ranges);
            throw new Error('This is a custom error message');

        } catch (err) {
            console.log(err);
            global.config.slackMessage(err.toString());
        }

    };



    return (
        <div>
            {/* <Calendar onChange={onChange} /> */}sdssd

            {/* <GradualNumberLoader targetNumber={1000} duration={3000} /> */}
            <SmoothLoadMap items={items} />
        </div>
    );
};

export default Test;