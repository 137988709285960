import "./TimeTrackerOverviewTotal.css";
import { ThreeDots } from 'react-loader-spinner';
import React, { useEffect, useState } from "react";
import Clock from "../../../images/icons/clock.svg";
import Billable from "../../../images/icons/billable.svg";
import NonBillable from "../../../images/icons/non-billable.svg";

const TimeTrackerOverviewTotal = (props) => {
  const [formattedDuration, setFormattedDuration] = useState('00:00:00');
  const [billableHours, setBillableHours] = useState("00:00:00");
  const [totalDuration, setTotalDuration] = useState(0);
  const [totalBillableDuration, setTotalBillableDuration] = useState(0);
  const [nonBillableHours, setNonBillableHours] = useState("00:00:00");
  const hour = props.TotalHours;

  // useEffect(() => {

  //   const setTotalHours = () => {
  //     let sum = 0;
  //     for (let i = 0; i < hour.length; i++) {
  //       sum += hour[i].duration;
  //     }

  //     setTotalDuration(sum);
  //     const durationInSeconds = sum;
  //     const hours = Math.floor(durationInSeconds / 3600);
  //     const minutes = Math.floor((durationInSeconds % 3600) / 60);
  //     const seconds = durationInSeconds % 60;
  //     const formattedDuration = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  //     setFormattedDuration(formattedDuration);
  //   }
  //   setTotalHours()
  //   const setTotalBillable = () => {

  //     let sum = 0;
  //     for (let i = 0; i < hour.length; i++) {
  //       if (hour[i]?.project?.is_billable === true) {
  //         sum += hour[i].duration;
  //       }
  //     }

  //     let second = sum;
  //     setTotalBillableDuration(second);
  //     const hours = Math.floor(second / 3600);
  //     const minutes = Math.floor((second % 3600) / 60);
  //     const seconds = second % 60;
  //     const formattedDuration = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  //     setBillableHours(formattedDuration);
  //   }

  //   setTotalBillable()
  //   const setTotalNonBillable = () => {

  //     let sum = 0;
  //     for (let i = 0; i < hour.length; i++) {
  //       if (hour[i]?.project?.is_billable === false) {
  //         sum += hour[i].duration;
  //       }
  //     }

  //     let second = sum;
  //     const hours = Math.floor(second / 3600);
  //     const minutes = Math.floor((second % 3600) / 60);
  //     const seconds = second % 60;
  //     const formattedDuration = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  //     setNonBillableHours(formattedDuration);
  //   }
  //   setTotalNonBillable();

  // }, [hour, totalBillableDuration, totalDuration, props.TotalHours]);



  useEffect(() => {
    const setTotalHours = async () => {
      try {
        let sum = 0;
        for (let i = 0; i < hour.length; i++) {
          sum += hour[i].duration;
        }
  
        // Use setState asynchronously to avoid blocking
        setTotalDuration(sum);
        const durationInSeconds = sum;
        const hours = Math.floor(durationInSeconds / 3600);
        const minutes = Math.floor((durationInSeconds % 3600) / 60);
        const seconds = durationInSeconds % 60;
        const formattedDuration = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
        setFormattedDuration(formattedDuration);
      } catch (error) {
        console.error("Error calculating total hours:", error);
      }
    };
  
    const setTotalBillable = async () => {
      try {
        let sum = 0;
        for (let i = 0; i < hour.length; i++) {
          if (hour[i]?.project?.is_billable === true) {
            sum += hour[i].duration;
          }
        }
  
        const second = sum;
        setTotalBillableDuration(second);
        const hours = Math.floor(second / 3600);
        const minutes = Math.floor((second % 3600) / 60);
        const seconds = second % 60;
        const formattedDuration = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
        setBillableHours(formattedDuration);
      } catch (error) {
        console.error("Error calculating total billable hours:", error);
      }
    };
  
    const setTotalNonBillable = async () => {
      try {
        let sum = 0;
        for (let i = 0; i < hour.length; i++) {
          if (hour[i]?.project?.is_billable === false) {
            sum += hour[i].duration;
          }
        }
  
        const second = sum;
        const hours = Math.floor(second / 3600);
        const minutes = Math.floor((second % 3600) / 60);
        const seconds = second % 60;
        const formattedDuration = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
        setNonBillableHours(formattedDuration);
      } catch (error) {
        console.error("Error calculating total non-billable hours:", error);
      }
    };
  
    // Use asynchronous logic for state updates
    const updateHours = async () => {
      await Promise.all([
        setTotalHours(),
        setTotalBillable(),
        setTotalNonBillable()
      ]);
    };
  
    updateHours();
  
  }, [hour, totalBillableDuration, totalDuration, props.TotalHours]);

  

  return (
    <div className="total-overview-outer">
      <div className="inner-overview-hours">
        <div className="overview-item">
          <div className="overview-title-outer">
            <div className="overview-title">Total Hours</div>
            <div className="overview-icon">
              <img src={Clock} alt="cLock" />
            </div>
          </div>
          <div className="overview-rice">
            <time datetime="PT1800H">{props.isLoading === true ?
              (<ThreeDots
                height="40"
                width="80"
                radius="9"
                color="#405BFF"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />)
              :
              formattedDuration
            }</time>
          </div>
        </div>
        <div className="overview-item">
          <div className="overview-title-outer">
            <div className="overview-title">Total Billable</div>
            <div className="overview-icon">
              <img src={Billable} alt="Billable" />
            </div>
          </div>
          <div className="overview-rice">
            <time datetime="PT1640H">{props.isLoading === true ?
              (<ThreeDots
                height="40"
                width="80"
                radius="9"
                color="#405BFF"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />)
              :
              billableHours
            }</time>
          </div>
        </div>
        <div className="overview-item">
          <div className="overview-title-outer">
            <div className="overview-title">Total Non-Billable</div>
            <div className="overview-icon">
              <img src={NonBillable} alt="cLock" />
            </div>
          </div>
          <div className="overview-rice">
            <time datetime="PT160H">{props.isLoading === true ?
              (<ThreeDots
                height="40"
                width="80"
                radius="9"
                color="#405BFF"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />)
              :
              nonBillableHours
            }</time>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimeTrackerOverviewTotal;