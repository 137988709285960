import { AES, enc } from 'crypto-js';
import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ThreeDots } from "react-loader-spinner";
import Modal from "react-modal";
import { toast } from "react-toastify";
import userTypeConsts from '../../../constants/userTypeConsts';
import { Link, useNavigate, Outlet, useParams } from 'react-router-dom';
import { setHeaderText } from "../../../features/headerText/headerTextSlice";
import LeftArrow from "../../../images/icons/blue-left-arrow.svg";
import editimgblue from "../../../images/team-member-tab/edit-btn-blue.svg";
import crssrImage from "../../../images/singleinvoice/cross-red.svg";
import Delete from "../../../images/icons/delete.svg";
import { logout, reset } from "../../../features/auth/authSlice";
import { reset as invoiceReset } from "../../../features/invoice/invoiceSlice.js";
import { reset as headerReset } from "../../../features/headerText/headerTextSlice.js";
import { reset as appSettingsReset } from "../../../features/appSettings/appSettingsSlice.js";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useDeleteExpCategory, useFetchExpCategory, useUpdateExpCategory } from '../../../components/AppSettings/expenseCategoryApi';
import NewExpCatModal from './element/NewExpCatModal.jsx';


function ExpCategoryLayout() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectAllByPage, setSelectAllByPage] = useState({});
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [expCategoryEdit, setExpCategoryEdit] = useState("");
  const [expCatBudgetEdit, setExpCatBudgetEdit] = useState("");
  const [expCatCurrencyEdit, setExpCatCurrencyEdit] = useState("");
  const [sortedExpCategory, setSortedExpCategory] = useState([]);
  const [showingDropdownOpen, setShowingDropdownOpen] = useState(false);
  const targetRef = useRef(null);
  const showingDropdownRef = useRef(null);
  const { appSettings } = useSelector((state) => state.appSettings);
  const token = useSelector(
    (state) => state.auth.token
  )

  const optionDropdownRef = useRef(null);
  const [selectedOption, setSelectedOption] = useState("Categories");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const handleOptionClick = (option, url) => {
    setSelectedOption(option);
    navigate(url);
    setDropdownOpen(false); // Close the dropdown after selecting an option
  };
  const { data: expCategory = [], isLoading, isError } = useFetchExpCategory(token);
  const deleteExpCategoryMutation = useDeleteExpCategory(token);
  const updateExpCategoryMutation = useUpdateExpCategory(token);


  const getOptionText = (path) => {
    switch (path) {
      case `/user/expense/expense-category`:
        return "Categories";
      default:
        return "";
    }
  };

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      // Your resize observer logic here
    });

    if (targetRef.current) {
      resizeObserver.observe(targetRef.current);
    }
    // Clean up the observer on component unmount
    return () => {
      if (targetRef.current) {
        resizeObserver.unobserve(targetRef.current);
      }
    };
  }, []);

  useEffect(() => {
    // Set the initial value of the select to match the current URL path
    const currentPath = window.location.pathname;
    // console.log(getOptionText(currentPath));
    setSelectedOption(getOptionText(currentPath));
    const selectElement = document.querySelector('.mobile-tab-select');
    if (selectElement) {
      selectElement.value = currentPath;
    }
  }, []);

  useEffect(() => {
    dispatch(setHeaderText("Expenses"));
    setSortedExpCategory(expCategory);
  }, [expCategory]);

  const handleClickOutsideDropdown = (event) => {
    const isClickInsideShowingDropdown = showingDropdownRef.current && showingDropdownRef.current.contains(event.target);
    if (!isClickInsideShowingDropdown) {
      setShowingDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutsideDropdown);
    return () => {
      document.removeEventListener('click', handleClickOutsideDropdown);
    };
  }, []);

  const renderEditExpCat = (rowData) => {
    return (
      <button className="main-edit-btn-parent" onClick={() => handleOpenModal(rowData)}><img src={editimgblue} alt="edit" />Edit</button>
    );
  };

  const renderExpCatBudget = (rowData) => {
    var totalBudget = rowData?.exp_cat_budget && rowData?.exp_cat_budget.toLocaleString("en-US", {
      style: "currency",
      currency: rowData ? rowData?.exp_cat_currency : "USD",
    });

    return <span> {totalBudget}</span>;
  };

  const handleRowsPerPageChange = (e) => {
    const newRowsPerPage = parseInt(e.target.value);
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(0); // Set the current page to 0 when changing rows per page
  };

  const onPageChange = (e) => {
    const newPage = e.page;
    // Store the current "Select All" state for the current page
    setSelectAllByPage((prevSelectAllByPage) => ({
      ...prevSelectAllByPage,
    }));
    setCurrentPage(e.page);
  };

  const totalResults = sortedExpCategory.length;
  const originalUrl = window.location.pathname;
  const lastIndex = originalUrl.lastIndexOf('/');
  const newUrl = originalUrl.substring(0, lastIndex);

  const handleOpenModal = (item) => {
    setIsEditModalOpen(true);
    setSelectedId(item._id);
    setExpCategoryEdit(item.exp_category_name);
    setExpCatCurrencyEdit(item.exp_cat_currency);
    setExpCatBudgetEdit(item.exp_cat_budget || 0);
  };

  const handleCloseModal = () => {
    setIsEditModalOpen(false);
    setSelectedId("");
    setExpCategoryEdit("");
    setExpCatBudgetEdit("");
  };

  const handleDelete = () => {
    setIsDeleteModalOpen(true);
  };

  const handleConfirmDelete = async () => {
    try {
      setIsSubmitLoading(true);
      await deleteExpCategoryMutation.mutateAsync(selectedId);
      setSortedExpCategory((prevState) => prevState.filter((expCategory) => expCategory._id !== selectedId));
      toast.success("Expense category removed successfully", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      global.config.activityLog(window.location.href, 'Expense Category', `Expense Category (${selectedId}) removed successfully`);
      handleCloseModal();
    } catch (error) {
      if (error.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        toast.error('This user is deleted!', {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
        return;
      }
      toast.error("Something went wrong", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      global.config.slackMessage(error.toString());
      console.log(error.response.data.message); // print server error message
    } finally {
      setIsSubmitLoading(false);
    }
    setIsDeleteModalOpen(false);
  };

  const handleCancelDelete = () => {
    setIsDeleteModalOpen(false);
  };

  const handleUpdateSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsSubmitLoading(true);
      const response = await updateExpCategoryMutation.mutateAsync({
        selectedId,
        exp_category_name: expCategoryEdit,
        exp_cat_budget: expCatBudgetEdit,
        exp_cat_currency: appSettings.currency || "USD",
      });
      const data = response.data;
      if (data.status === "error") {
        toast.error("Something went wrong", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (data.status === "exists") {
        toast.error("Expense category with the same name already exists", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        setSortedExpCategory((prevState) => {
          const updatedExpCategory = prevState.map((item) => {
            if (item._id === selectedId) {
              return { ...item, exp_category_name: expCategoryEdit, exp_cat_budget: expCatBudgetEdit, exp_cat_currency: appSettings.currency || "USD", };
            }
            return item;
          });
          return updatedExpCategory;
        });
        toast.success("Expense category updated successfully", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        global.config.activityLog(window.location.href, 'Expense Category', `Expense Category ${expCategoryEdit} updated successfully`);
        handleCloseModal();

      }
    } catch (error) {
      console.log(error.response.data.message); // print server error message
      if (error.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        toast.error('This user is deleted!', {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
        return;
      }
      global.config.slackMessage(error.toString());
      alert("Something went wrong");
    } finally {
      setIsSubmitLoading(false);
    }
  };

  const numberInputOnWheelPreventChange = (e) => {
    // Prevent the input value change
    e.target.blur()

    // Prevent the page/container scrolling
    e.stopPropagation()

    setTimeout(() => {
      e.target.focus()
    }, 0)
  }
  

  return (
    <div className='tab-outer-time'>

      {/* <div className='tab-mobile-select 33'>
        <h4>Select an option</h4>
        <div className="custom-dropdown-user-new-outer">
          <div className="selected-option custom-dropdown-user-new" onClick={() => setDropdownOpen(!dropdownOpen)} ref={optionDropdownRef} >
            {selectedOption}
          </div>
          {dropdownOpen && (
            <div className="custom-dropdown-new">
              <ul className="showing-options-list">
                <li onClick={() => handleOptionClick("Categories", "/user/expense/expense-category")}>Categories</li>
              </ul>
            </div>
          )}
        </div>

      </div> */}

      <ul className="tabs">
        <li>
          <Link to={`/user/expense/expense-category`} className={window.location.pathname.startsWith('/user/expense/expense-category') ? 'active' : ''}>
            Categories
          </Link>
        </li>
      </ul>

      <div className="tabs-content">
        <div className='tab-inner-time'>
          <div className="outer-project-client-section expense-cat">


            <div className="user-outer-timeoff"  >
              <div className="invoice-top">
                <div className="text-arrow-outer">
                  <div className="text-arrow-inner">
                    <Link to={`/user/expense/overview`}>
                      <div className="text-arrow">
                        <div className="left-arrow">
                          <img src={LeftArrow} alt="left-arrow" />
                        </div>
                        <div className="top-text">
                          Back to Expenses
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>

                <NewExpCatModal />
              </div>
              <div className="overview-top-right" ref={targetRef} >

                <div className="showing-count showing-count-timeoff">
                  <div className="filter-inner showing-result show-result-team-fill">
                    <label>Showing</label>

                    <div ref={showingDropdownRef} className="select-modal-mainM custom-dropdown-user-new-outer">
                      <div className="selected-option custom-dropdown-user-new" onClick={() => setShowingDropdownOpen(!showingDropdownOpen)}>
                        {totalResults < 10 ? totalResults.toString() : rowsPerPage.toString()}
                      </div>
                      {showingDropdownOpen && (
                        <div className="custom-dropdown-new">
                          <ul className="showing-options-list">
                            {totalResults >= 10 && <li className={rowsPerPage === 10 ? 'selected-item' : ''} onClick={() => { handleRowsPerPageChange({ target: { value: 10 } }); setShowingDropdownOpen(false); }}>10</li>}
                            {totalResults >= 20 && <li className={rowsPerPage === 20 ? 'selected-item' : ''} onClick={() => { handleRowsPerPageChange({ target: { value: 20 } }); setShowingDropdownOpen(false); }}>20</li>}
                            {totalResults >= 30 && <li className={rowsPerPage === 30 ? 'selected-item' : ''} onClick={() => { handleRowsPerPageChange({ target: { value: 30 } }); setShowingDropdownOpen(false); }}>30</li>}
                            {totalResults >= 40 && <li className={rowsPerPage === 40 ? 'selected-item' : ''} onClick={() => { handleRowsPerPageChange({ target: { value: 40 } }); setShowingDropdownOpen(false); }}>40</li>}
                            {totalResults >= 10 && <li className={totalResults >= 10 && rowsPerPage === totalResults ? 'selected-item' : ''} onClick={() => { handleRowsPerPageChange({ target: { value: totalResults }, }); setShowingDropdownOpen(false); }}>ALL</li>}
                            {/* Add more conditions as needed */}
                            {totalResults < 10 ? (
                              <li className={totalResults < 10 ? 'selected-item' : ''} onClick={() => { handleRowsPerPageChange({ target: { value: totalResults }, }); setShowingDropdownOpen(false); }}>{totalResults}</li>
                            ) : null}
                          </ul>
                        </div>
                      )}
                    </div>
                    <label>of {totalResults} Results</label>
                  </div>
                </div>
              </div>
              <div className="datatable-team-maintb">


                {isLoading ? (

                  <div className="outter-load-table">
                    <ThreeDots
                      height="80"
                      width="80"
                      radius="9"
                      color="#6479f9"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  </div>

                ) : (
                  <DataTable
                    value={sortedExpCategory}
                    removableSort
                    tableStyle={{
                      minWidth: "100%",
                      width: "100%",
                      textAlign: "left",
                    }}
                    paginator={sortedExpCategory.length > 10}
                    paginatorClassName="recent-invoices-pagination"
                    rows={rowsPerPage} // Set the number of rows per page
                    first={currentPage * rowsPerPage} // Set the current page using currentPage
                    onPage={onPageChange} // Update currentPage when changing pages
                  >
                    <Column
                      field="exp_category_name"
                      header="Category"
                      className=""
                      sortable
                      style={{ paddingRight: "44px", width: "80%" }}
                    ></Column>
                    <Column
                      field="exp_cat_budget"
                      header="Total Budget"
                      body={renderExpCatBudget}
                      className=""
                      sortable
                      style={{ paddingRight: "44px", width: "20%" }}
                    ></Column>
                    <Column
                      field="_id"
                      body={(rowData) => renderEditExpCat(rowData)}
                      className=""
                      style={{
                        paddingRight: "45px",
                        Width: "10%",
                      }}
                    ></Column>
                  </DataTable>
                )}
              </div>

              <Modal
                className="client-modal leave"
                isOpen={isEditModalOpen}
                onRequestClose={handleCloseModal}
                contentLabel="Edit Expense Category"
                style={{
                  content: {
                    height: "41%",
                    width: "30%",
                    position: "fixed",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    parent: document.querySelector(".admin-outer.time.tracker"),
                  },
                }}
              >
                <div className="modal">
                  <div className="modal-close-invite outter-modal-main">
                    <a className="" onClick={handleCloseModal}>
                      <img src={crssrImage} alt="Edit Icon" />{" "}
                    </a>
                  </div>
                  <div className="modal-client-head">
                    <h2 className="">Edit Category</h2>
                  </div>
                  <div className="form-field-client-outer">
                    <form onSubmit={handleUpdateSubmit}>
                      <div className="form-field-client">
                        <label htmlFor="clientName" className="form-label">
                          Category Name
                        </label>
                        <input
                          type="text"
                          id="clientName"
                          name="clientName"
                          className="form-input"
                          value={expCategoryEdit}
                          onChange={(e) => setExpCategoryEdit(e.target.value)}
                          required
                        />
                      </div>
                      <div className="form-field-client">
                        <label htmlFor="expCatBudget" className="form-label">
                          Budget ({expCatCurrencyEdit})
                        </label>
                        <input
                          type="number"
                          id="expCatBudget"
                          name="expCatBudget"
                          className="form-input"
                          value={expCatBudgetEdit}
                          onChange={(e) => setExpCatBudgetEdit(e.target.value)}
                          required
                          onWheel={numberInputOnWheelPreventChange}
                        />
                      </div>
                      {isSubmitLoading ?
                        (
                          <div className="outter-load-table">
                            <ThreeDots
                              height="38"
                              width="40"
                              radius="9"
                              color="#6479f9"
                              ariaLabel="three-dots-loading"
                              wrapperStyle={{}}
                              wrapperClassName=""
                              visible={true}
                            />
                          </div>
                        ) : (
                          <div className="form-field-client-btns">
                            <button
                              type="button"
                              className="delete-client"
                              onClick={handleDelete}
                              disabled={isSubmitLoading}
                            >
                              <img src={Delete} alt="Delete" />  Delete
                            </button>
                            <Modal
                              className="delete-modal"
                              isOpen={isDeleteModalOpen}
                              onRequestClose={handleCancelDelete}
                              contentLabel="Confirm Delete"
                              style={{
                                content: {
                                  height: "41%",
                                  width: "30%",
                                  position: "fixed",
                                  top: "36%",
                                  left: "50%",
                                  transform: "translate(-24.75%, 0%)",
                                  parent: document.querySelector(".admin-outer.time.tracker"),
                                },
                              }}
                            >
                              <div className="delete-modal-content">
                                <h2>Confirm Delete</h2>
                                <p>
                                  Are you sure you want to delete this Expense Category?
                                </p>
                                {isSubmitLoading ? (
                                  <div className="outter-load-table">
                                    <ThreeDots
                                      height="38"
                                      width="40"
                                      radius="9"
                                      color="#6479f9"
                                      ariaLabel="three-dots-loading"
                                      wrapperStyle={{}}
                                      wrapperClassName=""
                                      visible={true}
                                    />
                                  </div>
                                ) : (
                                  <div className="delete-modal-buttons">
                                    <button className="delete" onClick={handleConfirmDelete} disabled={isSubmitLoading}>
                                      Delete
                                    </button>
                                    <button onClick={handleCancelDelete}>
                                      Cancel
                                    </button>
                                  </div>
                                )}
                              </div>
                            </Modal>
                            <button
                              type="submit"
                              className="save-changes"
                              disabled={isSubmitLoading}
                            >
                              Save Changes
                            </button>
                          </div>
                        )}
                    </form>
                  </div>
                </div>
              </Modal>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExpCategoryLayout;
