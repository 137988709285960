// External
import axios from "axios";
import moment from "moment";
import { saveAs } from "file-saver";
import { toast } from "react-toastify";
import { AES, enc } from 'crypto-js';
import Modal from "react-modal";
import "react-toastify/dist/ReactToastify.css";
import { useSelector, useDispatch } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
// External

// Internal
import css from "./ClientInvoicesingle.css";
import ClientInvoiceActions from "./ClientInvoiceActions";
import { ThreeDots } from "react-loader-spinner";
import ClientInvoiceAddress from "./elements/ClientInvoiceAddress";
import ClientItemDescription from "./elements/ClientItemDescription";
import userTypeConsts from "../../../../constants/userTypeConsts";
import { CLIENT_INVOICE_STATUS } from "../../../../constants/cliInvStatus.js";
import { logout, reset } from "../../../../features/auth/authSlice";
import { setHeaderText } from "../../../../features/headerText/headerTextSlice";
import { reset as invoiceReset } from "../../../../features/invoice/invoiceSlice.js";
// import PaymentStatusWiseSingle from "../../Invoices/elements/paymentStatusWiseSingle";
import { reset as headerReset } from "../../../../features/headerText/headerTextSlice.js";
import { reset as appSettingsReset } from "../../../../features/appSettings/appSettingsSlice.js";
import { setNotification } from "../../../../features/notification/notificationSlice.js";
import tikgImage from "../../../../images/singleinvoice/tick-green.svg";
import SendIcon from "../../../../images/icons/send-icon.svg";
import crssrImage from "../../../../images/singleinvoice/cross-red.svg";
import crssrImageBlack from "../../../../images/icons/close-black.svg";
import { useClientView } from "../../../TimeTracker/TimeTrackerClient/useClientDetails.jsx";
import { setSideBarText } from "../../../../features/sideBarText/sideBarTextSlice.js";
import { useWxCompany } from "../useClientInvoice.jsx";
import userCategory from "../../../../constants/userCategory.js";
// Internal

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

function getFileExtension(filename) {
  const lastIndex = filename.lastIndexOf(".");
  if (lastIndex !== -1) {
    return filename.slice(lastIndex + 1);
  }
  return "";
}

const ClientInvoiceSingle = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { inv, encryptedId } = useParams();
  const decodedId = decodeURIComponent(inv.replace(/%2F/g, '/'));
  const decryptedBytes = AES.decrypt(decodedId, 'encryptionKey');
  const id = decryptedBytes.toString(enc.Utf8);
  const decodedCliId = decodeURIComponent(encryptedId.replace(/%2F/g, '/'));
  const decryptedClientBytes = AES.decrypt(decodedCliId, 'encryptionKey');
  const cliId = decryptedClientBytes.toString(enc.Utf8);
  const [invoiceView, setInvoiceView] = useState([]);
  const [invoiceData, setInvoiceData] = useState(null);
  const [invStatus, setInvStatus] = useState("");
  const [approveModalIsOpen, setApproveModalIsOpen] = useState(false);
  const [invSendModalIsOpen, setInvSendModalIsOpen] = useState(false);
  const [invManualSendModalIsOpen, setInvManualSendModalIsOpen] = useState(false);
  const [isLoadingInvSend, setIsLoadingInvSend] = useState(false);
  const [isLoadingInvManualSend, setIsLoadingInvManualSend] = useState(false);
  const [isLoadingApprove, setIsLoadingApprove] = useState(false);
  const [isLoadingReject, setIsLoadingReject] = useState(false);
  const [isLoadingManualPayment, setIsLoadingManualPayment] = useState(false);
  const [modalRejectIsOpen, setRejectIsOpen] = useState(false);
  const [wxCompany, setWxCompany] = useState(null);
  const [wxCompanyDropdownOpen, setWxCompanyDropdownOpen] = useState(false);
  const [manualModalIsOpen, setManualModalIsOpen] = useState(false);
  const url = new URL(window.location.href);
  const subdomain = url.hostname.split('.')[0];
  const capitalizedSubdomain = subdomain.charAt(0).toUpperCase() + subdomain.slice(1);
  const user = useSelector((state) => state.auth.user);
  let token = null;
  if (user) {
    if (user && user.token) {
      token = user.token;
    }
  }
  const componentRef = useRef();

  const { data: client = [], isLoading, refetch: refetchClientProfiles, isError: clientViewError } = useClientView(cliId, token);
  const { data: wxCompanyProfile = [], isLoading: isWxCompanyLoading } = useWxCompany(token);

  // useEffect(() => {

  //   async function fetchInvoice() {
  //     try {
  //       const response = await axios.get(`/api/client/invoice/${id}`, {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       });
  //       const data = response.data;
  //       setWxCompany(data?.cli_inv_vendor?._id);
  //       const invoiceDetails = data.cli_inv_description;
  //       var costs = data.cli_inv_cost;
  //       const vats = data.cli_inv_tax;
  //       const prices = data.cli_inv_price;
  //       const invoiceViewData = invoiceDetails.map((item, index) => {
  //         return {
  //           Description: item.trim(),
  //           Cost: costs[index],
  //           VAT: vats[index],
  //           Price: prices[index],
  //         };
  //       });

  //       setInvoiceView([{ ...data, Invoice_Details: invoiceViewData }]);

  //       setInvoiceData(data);
  //       global.config.activityLog(window.location.href, 'Invoice', `Viewed invoice number:${data.cli_inv_number}`);
  //     } catch (error) {
  //       console.log(error);
  //       global.config.slackMessage(error.toString());
  //       if (error.response?.data?.status === "deleted") {
  //         localStorage.removeItem("user");
  //         dispatch(logout());
  //         dispatch(reset());
  //         dispatch(invoiceReset());
  //         dispatch(headerReset());
  //         dispatch(appSettingsReset());
  //         toast.error("This user is deleted!", {
  //           position: "bottom-right",
  //           autoClose: 1500,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //           theme: "light",
  //         });
  //         navigate("/");
  //         return;
  //       }
  //       alert("Something went wrong123");
  //     }
  //   }


  //   fetchInvoice();
  //   refetchClientProfiles();
  //   if (!isLoading) {
  //     if (user && (user?.userCategory === userCategory.Client)) {
  //       dispatch(setHeaderText("Invoices"));
  //     } else {
  //       dispatch(setHeaderText(client?.client_name || client?.legal_entity_name || "Client Overview"));
  //       dispatch(setSideBarText("Client Overview"));
  //     }
  //   }
  // }, [id, dispatch, token, invStatus]);


  useEffect(() => {
    const fetchInvoice = async () => {
      try {
        const response = await axios.get(`/api/client/invoice/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        const data = response.data;
  
        // Log activity asynchronously
        setTimeout(() => {
          global.config.activityLog(
            window.location.href,
            "Invoice",
            `Viewed invoice number:${data.cli_inv_number}`
          );
        }, 0);
  
        // Set WxCompany asynchronously
        setTimeout(() => setWxCompany(data?.cli_inv_vendor?._id), 0);
  
        // Prepare invoice details
        const invoiceDetails = data.cli_inv_description;
        const costs = data.cli_inv_cost;
        const vats = data.cli_inv_tax;
        const prices = data.cli_inv_price;
  
        const invoiceViewData = invoiceDetails.map((item, index) => ({
          Description: item.trim(),
          Cost: costs[index],
          VAT: vats[index],
          Price: prices[index],
        }));
  
        // Update invoice data in chunks
        setTimeout(() => {
          setInvoiceView([{ ...data, Invoice_Details: invoiceViewData }]);
          setInvoiceData(data);
        }, 0);
      } catch (error) {
        console.error(error);
  
        // Send Slack message asynchronously
        setTimeout(() => global.config.slackMessage(error.toString()), 0);
  
        if (error.response?.data?.status === "deleted") {
          localStorage.removeItem("user");
          dispatch(logout());
          dispatch(reset());
          dispatch(invoiceReset());
          dispatch(headerReset());
          dispatch(appSettingsReset());
          toast.error("This user is deleted!", {
            position: "bottom-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          navigate("/");
          return;
        }
  
        // Show error alert asynchronously
        setTimeout(() => alert("Something went wrong"), 0);
      }
    };
  
    // Fetch invoices asynchronously
    setTimeout(() => fetchInvoice(), 0);
  
    // Refetch client profiles asynchronously
    setTimeout(() => refetchClientProfiles(), 0);
  
    // Update header and sidebar text asynchronously
    if (!isLoading) {
      setTimeout(() => {
        if (user?.userCategory === userCategory.Client) {
          dispatch(setHeaderText("Invoices"));
        } else {
          dispatch(
            setHeaderText(
              client?.client_name || client?.legal_entity_name || "Client Overview"
            )
          );
          dispatch(setSideBarText("Client Overview"));
        }
      }, 0);
    }
  }, [id, dispatch, token, invStatus]);

  function openApproveModal() {
    setApproveModalIsOpen(true);
  }

  function openInvSendModal() {
    setInvSendModalIsOpen(true);
  }

  function closeInvSendModal() {
    setInvSendModalIsOpen(false);
  }

  function openInvManualSendModal() {
    setInvManualSendModalIsOpen(true);
  }

  function closeInvManualSendModal() {
    setInvManualSendModalIsOpen(false);
  }

  const handleToggleDropdown = () => {
    setWxCompanyDropdownOpen(!wxCompanyDropdownOpen);
  };

  const handleSelectCompany = (company) => {
    setWxCompany(company);
    setWxCompanyDropdownOpen(false);
  };

  function openRejectModal() {
    setRejectIsOpen(true);
  }

  function closeRejectModal() {
    setRejectIsOpen(false);
  }

  function closeModal() {
    setApproveModalIsOpen(false);
  }

  const handleManualPayment = async () => {
    setManualModalIsOpen(true);
  };

  const handleManualModalCancel = () => {
    setManualModalIsOpen(false);
  };

  const invoiceStatusApprove = async () => {
    try {
      if (!wxCompany || wxCompany.trim() === "") {
        toast.error(`${capitalizedSubdomain} company can't be null`, {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return;
      }
      setIsLoadingApprove(true);
      await axios
        .get(`/api/client/invoice/approve/${id}?company=${wxCompany}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },

        })
        .then(() => {
          dispatch(setNotification(Date.now()));
        });

      global.config.activityLog(window.location.href, 'Client Invoice', `Client Invoice Approved Successfully.`);
      setInvoiceView((prevState) => {
        const updatedInvoiceView = prevState.map((item) => {
          if (item._id === id) {
            return { ...item, cli_inv_status: "1" };
          }
          return item;
        });
        return updatedInvoiceView;
      });
      setIsLoadingApprove(false);
      closeModal();
      setInvStatus("Approved");
      toast.success("Approved successsfully!", {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      // if (error.response.data.status === "mailfailed") {
      //   setInvoiceView((prevState) => {
      //     const updatedInvoiceView = prevState.map((item) => {
      //       if (item._id === id) {
      //         return { ...item, cli_inv_status: "1" };
      //       }
      //       return item;
      //     });
      //     return updatedInvoiceView;
      //   });
      //   setIsLoadingApprove(false);
      //   closeModal();
      //   setInvStatus("Approved");
      //   toast.error("Client invoice approved successsfully but something went wrong in sending mail!!", {
      //     position: "bottom-right",
      //     autoClose: 3500,
      //     hideProgressBar: false,
      //     closeOnClick: true,
      //     pauseOnHover: true,
      //     draggable: true,
      //     progress: undefined,
      //   });
      //   console.log(error.response.data.message);
      // } else {
      global.config.slackMessage(error.toString());
      toast.error("Something went wrong in approval!", {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.log(error);
      // }
    }
  };

  const invoiceStatusInvSend = async () => {
    try {
      setIsLoadingInvSend(true);
      await axios
        .get(`/api/client/invoice/send/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          dispatch(setNotification(Date.now()));
        });
      global.config.activityLog(window.location.href, 'Client Invoice', `Client invoice sent successfully.`);
      setInvoiceView((prevState) => {
        const updatedInvoiceView = prevState.map((item) => {
          if (item._id === id) {
            return { ...item, cli_inv_status: "1" };
          }
          return item;
        });
        return updatedInvoiceView;
      });
      setInvStatus("Send");
      setIsLoadingInvSend(false);
      closeInvSendModal();
      toast.success("Client invoice sent successfully!", {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      global.config.slackMessage(error.toString());
      if (error.response.data.status === "mailfailed") {
        setInvoiceView((prevState) => {
          const updatedInvoiceView = prevState.map((item) => {
            if (item._id === id) {
              return { ...item, cli_inv_status: "1" };
            }
            return item;
          });
          return updatedInvoiceView;
        });
        setInvStatus("Send");
        setIsLoadingInvSend(false);
        closeInvSendModal();
        toast.error("Invoice sent successfully but something went wrong in sending mail!!", {
          position: "bottom-right",
          autoClose: 3500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        console.log(error.response.data.message);
      } else {
        toast.error("Something went wrong in sending the invoice!", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setIsLoadingInvSend(false);
        console.log(error);
      }
    } finally {
      setIsLoadingInvSend(false);
    }
  };

  const invoiceStatusManualInvSend = async () => {
    try {
      setIsLoadingInvManualSend(true);
      await axios
        .get(`/api/client/invoice/send/manually/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          dispatch(setNotification(Date.now()));
        });
      global.config.activityLog(window.location.href, 'Client Invoice', `Client invoice marked as manully sent successfully.`);
      setInvoiceView((prevState) => {
        const updatedInvoiceView = prevState.map((item) => {
          if (item._id === id) {
            return { ...item, cli_inv_status: "1" };
          }
          return item;
        });
        return updatedInvoiceView;
      });
      setInvStatus("Send");
      setIsLoadingInvManualSend(false);
      closeInvManualSendModal();
      toast.success("Invoice marked as manully send successsfully!", {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      global.config.slackMessage(error.toString());
      toast.error("Something went wrong in marking invoice as manully send!", {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.log(error);
    } finally {
      setIsLoadingInvManualSend(false);
    }
  };

  const invoiceStatusReject = async () => {
    try {
      setIsLoadingReject(true);
      await axios
        .get(`/api/client/invoice/reject/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          dispatch(setNotification(Date.now()));
        });
      global.config.activityLog(window.location.href, 'Client Invoice', `Client Invoice cacelled Successfully.`);
      setInvoiceView((prevState) => {
        const updatedInvoiceView = prevState.map((item) => {
          if (item._id === id) {
            return { ...item, cli_inv_status: "2" };
          }
          return item;
        });
        return updatedInvoiceView;
      });
      setInvStatus("Rejected");
      setIsLoadingReject(false);
      closeRejectModal();
      toast.success("Rejected successsfully!", {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      global.config.slackMessage(error.toString());
      if (error.response.data.status === "mailfailed") {
        setInvoiceView((prevState) => {
          const updatedInvoiceView = prevState.map((item) => {
            if (item._id === id) {
              return { ...item, cli_inv_status: "2" };
            }
            return item;
          });
          return updatedInvoiceView;
        });
        setInvStatus("Rejected");
        setIsLoadingReject(false);
        closeRejectModal();
        toast.error("Invoice Cancelled successsfully but something went wrong in sending mail!!", {
          position: "bottom-right",
          autoClose: 3500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        console.log(error.response.data.message);
      } else {
        toast.error("Something went wrong in rejection!");
        console.log(error);
      }
    }
  };

  const handleManualModalConfirm = async (invoiceId) => {
    setIsLoadingManualPayment(true);
    const paymentData = {
      invoice_id: invoiceId,
    };
    try {
      await axios
        .post("/api/client/invoice/payment/processed", paymentData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          const data = response.data;
          if (data.status === "Error") {

            toast.error("Something went wrong", {
              position: "bottom-right",
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          } else {
            global.config.activityLog(window.location.href, 'Client Invoice', `Client invoice "${invoiceId}" paid manually.`);

            setInvStatus("Payment Processed");
            setManualModalIsOpen(false);
            toast.success("Client invoice payment status updated successfully", {
              position: "bottom-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
          setIsLoadingManualPayment(false);
        })
        .catch((AxiosError) => {
          global.config.slackMessage(AxiosError.message.toString());
          toast.error(`Sorry!.${AxiosError.message}`, {
            position: "bottom-right",
            autoClose: 3500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        });
    } catch (error) {
      console.log(error);
      global.config.slackMessage(error.toString());
      if (error.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        toast.error("This user is deleted!", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
        return;
      }
      toast.error("Something went wrong", {
        position: "bottom-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } finally {
      setIsLoadingManualPayment(false);
      setManualModalIsOpen(false);
    }
  };

  const downloadCliInvDoc = async (invFile) => {
    try {
      const response = await axios.get(`/api/client/invoice/download/${invFile}`, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const pdfBlob = new Blob([response.data], { type: "application/pdf" });
      saveAs(pdfBlob, `${invFile}`);
    } catch (error) {
      console.error(error);
      global.config.slackMessage(error.toString());
      if (error.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        toast.error("This user is deleted!", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
        return;
      }
      // Handle error
    }
  };



  // Add logic to fetch and display the details of a single invoice
  return (
    <div className="single-ls-info single-ls-info-client">
      {(user && (user.userCategory === userCategory.Client)) ? (
        <div className="back-btn-ins">
          {invoiceData && invoiceData.cli_inv_for ? (
            <Link to={`/client/invoice`} className="btnf1-si-s">
              Back to All Invoices
            </Link>
          ) : (
            <ThreeDots
              height="18"
              width="20"
              radius="9"
              color="#6479f9"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          )}
        </div>
      ) : (
        <div className="back-btn-ins">
          {invoiceData && invoiceData.cli_inv_for ? (
            <Link to={`/client/invoices/${encodeURIComponent(AES.encrypt(invoiceData && invoiceData.cli_inv_for._id, 'encryptionKey').toString().replace(/\//g, '%2F'))}`} className="btnf1-si-s">
              Back to All Invoices
            </Link>
          ) : (
            <ThreeDots
              height="18"
              width="20"
              radius="9"
              color="#6479f9"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          )}
        </div>
      )}
      <div className="man-padd-for-siginvo">
        <div>
          <ClientInvoiceActions
            invoiceData={invoiceData}
            invoiceView={invoiceView}
            componentRef={componentRef}
          />
        </div>
        {/* <div className="invoice-main-body invoice-main-body-client"> */}
        <div className="invoice-main-body">
          <div className="invoice-detail-bs1" ref={componentRef}>
            <div className="main-head-bs11">
              <div className="invoce-id-num">
                <div className="invo-id">Invoice ID </div>
                <div className="invo-num-1">
                  {invoiceData ? (
                    invoiceData.cli_inv_number
                  ) : (
                    <ThreeDots
                      height="18"
                      width="20"
                      radius="9"
                      color="#6479f9"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  )}
                </div>
              </div>
              {/* <div className="invoce-id-type">
                <div className="invo-id">Type</div>
                <div className="invo-num-1">
                  {invoiceData ? (
                    invoiceData.inv_type
                  ) : (
                    <ThreeDots
                      height="18"
                      width="20"
                      radius="9"
                      color="#6479f9"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  )}
                </div>
              </div> */}
              <div className="invoce-id-exdate">
                <div className="invo-id">Due Date</div>
                <div className="invo-num-1">
                  {invoiceData ? (
                    moment(invoiceData.cli_inv_due_date).tz(global.config.getTimeZone()).format("MM/DD/YYYY")
                  ) : (
                    <ThreeDots
                      height="18"
                      width="20"
                      radius="9"
                      color="#6479f9"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  )}
                </div>
              </div>
              <div className="invoce-id-isdate">
                <div className="invo-id">Issue Date</div>
                <div className="invo-num-1">
                  {invoiceData ? (
                    moment(invoiceData.cli_inv_issue_date).tz(global.config.getTimeZone()).format("MM/DD/YYYY")
                  ) : (
                    <ThreeDots
                      height="18"
                      width="20"
                      radius="9"
                      color="#6479f9"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  )}
                </div>
              </div>
            </div>

            <div className="head-con-date">
              {invoiceData ? (
                invoiceData.cli_inv_title
              ) : (
                <ThreeDots
                  height="18"
                  width="20"
                  radius="9"
                  color="#6479f9"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              )}
            </div>
            {/* invoice from to */}

            <ClientInvoiceAddress
              invoiceData={invoiceData ? invoiceData : null}
            />
            <div className="table-outtter">
              <ClientItemDescription invoiceData={invoiceView ? invoiceView : null} />
              <div className="other-comment">
                {invoiceData && invoiceData.cli_inv_other_comments && (
                  <>
                    <div className="other-head1">Other comments</div>
                    <div className="other-subhead1">
                      {" "}
                      {invoiceData.cli_inv_other_comments}
                    </div>
                  </>
                )}
              </div>
              {invoiceData && invoiceData.cli_inv_file.length > 0 && (
                <div className="attachment-single-invi">
                  <div className="attachment-head">Attachments</div>

                  {Array.isArray(invoiceData.cli_inv_file) ? (
                    invoiceData.cli_inv_file.map((file, index) => (
                      <div
                        className="the-src-pdf-file"
                        onClick={() => downloadCliInvDoc(file)}
                        style={{ cursor: "pointer" }}
                      >
                        <div className="bottom-download-outer">
                          <div className="bottom-download-inner">
                            <div key={index} className="bottom-download-item">
                              <div
                                className="attachment-file-sib-pname"

                              >
                                {file.length > 15 ? (
                                  <span className="shortened-name" title={file}>
                                    {file.slice(0, 15)}...
                                  </span>
                                ) : (
                                  file
                                )}
                              </div>
                              <div className="attachment-file-sib-psize">
                                {file && getFileExtension(file)?.toUpperCase()}{" "}
                                {invoiceData.cli_inv_file_size && invoiceData.cli_inv_file_size[index] && (
                                  <span>{invoiceData.cli_inv_file_size[index]}</span>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="bottom-download-inner-right" onClick={() => downloadCliInvDoc(invoiceData.cli_inv_file)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="15" viewBox="0 0 12 15" fill="none">
                              <path d="M5.67409 13.8727C5.69551 13.8928 5.71891 13.9108 5.74395 13.9266L5.77888 13.9439L5.82304 13.9665L5.86918 13.9804L5.90971 13.9909C5.97034 14.003 6.03328 14.003 6.09425 13.9909L6.13478 13.9804L6.18091 13.9665L6.22507 13.9439L6.26 13.9266C6.28537 13.9108 6.30877 13.8928 6.33019 13.8727L10.8939 9.57954C11.0458 9.40745 11.0333 9.1549 10.8649 8.99674C10.6965 8.83858 10.4276 8.82682 10.2444 8.9695L6.46134 12.526V1.43331C6.46134 1.19406 6.25473 1 6 1C5.74527 1 5.53865 1.19406 5.53865 1.43331V12.526L1.75563 8.9695C1.57241 8.82682 1.30351 8.83858 1.13512 8.99674C0.966727 9.1549 0.954205 9.40745 1.10612 9.57954L5.67343 13.8727H5.67409Z" fill="#282828" stroke="#282828" strokeWidth="0.5" />
                            </svg>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div
                      className="the-src-pdf-file"
                      style={{ cursor: "pointer" }}
                    >
                      <div className="bottom-download-inner">
                        <div className="bottom-download-item">
                          <div
                            className="attachment-file-sib-pname"
                            onClick={() => downloadCliInvDoc(invoiceData.cli_inv_file)}
                          >
                            {invoiceData.cli_inv_file.length > 15 ? (
                              <span className="shortened-name" title={invoiceData.cli_inv_file}>
                                {invoiceData.cli_inv_file.slice(0, 15)}...
                              </span>
                            ) : (
                              invoiceData.cli_inv_file
                            )}
                          </div>
                          <div className="attachment-file-sib-psize">
                            {invoiceData.cli_inv_file && getFileExtension(invoiceData.cli_inv_file)?.toUpperCase()}{" "}
                            {invoiceData.cli_inv_file_size && (
                              <span>{invoiceData.cli_inv_file_size}</span>
                            )}
                          </div>
                          <div className="bottom-download-inner-right" onClick={() => downloadCliInvDoc(invoiceData.cli_inv_file)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="15" viewBox="0 0 12 15" fill="none">
                              <path d="M5.67409 13.8727C5.69551 13.8928 5.71891 13.9108 5.74395 13.9266L5.77888 13.9439L5.82304 13.9665L5.86918 13.9804L5.90971 13.9909C5.97034 14.003 6.03328 14.003 6.09425 13.9909L6.13478 13.9804L6.18091 13.9665L6.22507 13.9439L6.26 13.9266C6.28537 13.9108 6.30877 13.8928 6.33019 13.8727L10.8939 9.57954C11.0458 9.40745 11.0333 9.1549 10.8649 8.99674C10.6965 8.83858 10.4276 8.82682 10.2444 8.9695L6.46134 12.526V1.43331C6.46134 1.19406 6.25473 1 6 1C5.74527 1 5.53865 1.19406 5.53865 1.43331V12.526L1.75563 8.9695C1.57241 8.82682 1.30351 8.83858 1.13512 8.99674C0.966727 9.1549 0.954205 9.40745 1.10612 9.57954L5.67343 13.8727H5.67409Z" fill="#282828" stroke="#282828" strokeWidth="0.5" />
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="manage-detail-outer">
            <div className="manage-detail-bs2">
              <div className="invoice-manage-singlepg-head">Manage invoice</div>
              <div className="single-invo-sattus">
               
                {user && (user.userType === userTypeConsts.SUPER_ADMIN || user.userType === userTypeConsts.ADMIN) ? (
                  invoiceData ? (
                    <>
                      {invoiceData && invoiceData.cli_inv_status === CLIENT_INVOICE_STATUS.INVOICE_PENDING ? (
                        <>
                        <div className="invo-main-status-head">Invoice Status</div>
                        <div className="invoice-cancel-new">
                          <Link className="cancel-singlebtn" onClick={openRejectModal}>
                            {" "} Cancel <img src={crssrImageBlack} alt="close Icon" />{" "}
                          </Link>
                          </div>
                        <div className="invo-main-status-head">Send Invoices</div>
                        <div className="invo-sig-st-btns">
                          <Link className="Approve-singlebtn" onClick={openInvSendModal}>
                            {" "} Send <img src={SendIcon} alt="Send Icon" />{" "}
                          </Link>
                          <Link className="Approve-singlebtn" onClick={openInvManualSendModal}>
                            {" "} Send Manually <img src={SendIcon} alt="Send Icon" />{" "}
                          </Link>
                          
                        </div>
                        </>
                      ) : invoiceData && (invStatus === "Send" || invoiceData.cli_inv_status === CLIENT_INVOICE_STATUS.INVOICE_SEND) ? (
                        <>
                        <div className="invo-main-status-head">Invoice Status</div>
                          <div className="invoice-cancel-new">
                            <Link className="cancel-singlebtn" onClick={openRejectModal}>
                              {" "} Cancel <img src={crssrImageBlack} alt="close Icon" />{" "}
                            </Link>
                          </div>
                          <div className="invo-main-status-head">Send Invoices</div>
                          <div className="invo-sig-st-btns">
                            <Link className="Approve-singlebtn approved">
                              {" "} Successfully Sent <img src={tikgImage} alt="Edit Icon" />{" "}
                            </Link>
                          </div>
                          <div className="payment-btn-sin-in">
                            <div className="payment-btn-sec-head">Payment </div>
                            <div className="btn-pay-cta12">
                              <Link className="prma-singlebtn" onClick={handleManualPayment}>
                                {" "} Process Payment
                              </Link>
                            </div>
                          </div>
                        </>
                      ) : invoiceData && (invStatus === "Rejected" || invoiceData.cli_inv_status === CLIENT_INVOICE_STATUS.INVOICE_REJECT) ? (
                        <>
                        <div className="invo-main-status-head">Invoice Status</div>
                        <div className="invo-sig-st-btns">
                          <Link className="Reject-singlebtn rejected">
                            {" "} Cancelled <img src={crssrImage} alt="Edit Icon" />{" "}
                          </Link>
                        </div>
                        </>
                      ) : invoiceData && (invStatus === "Payment Processed" || invoiceData.cli_inv_status === CLIENT_INVOICE_STATUS.INVOICE_PAYMENT_PROCESSED) ? (
                       <>
                       <div className="invo-main-status-head">Invoice Status</div>
                        <div className="invo-sig-st-btns">
                          <Link className="Approve-singlebtn">
                            {" "} Paid <img src={tikgImage} alt="Edit Icon" />{" "}
                          </Link>
                        </div>
                        </>
                      ) : (
                        <div className="invo-sig-st-btns">
                          <ThreeDots
                            height="18"
                            width="20"
                            radius="9"
                            color="#6479f9"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                          />
                        </div>
                      )}
                    </>
                  ) : (
                    <ThreeDots
                      height="18"
                      width="20"
                      radius="9"
                      color="#6479f9"
                      ariaLabel="loading"
                      visible={true}
                    />
                  )
                ) : (
                  invoiceData && (
                    <>
                      {invoiceData.cli_inv_status === CLIENT_INVOICE_STATUS.INVOICE_PENDING ? (
                        <Link className="NotReviewed-singlebtn Notreviewed">Not Reviewed</Link>
                      ) : invoiceData.cli_inv_status === CLIENT_INVOICE_STATUS.INVOICE_SEND ? (
                        <Link className="Approve-singlebtn approved">Sent</Link>
                      ) : invoiceData.cli_inv_status === CLIENT_INVOICE_STATUS.INVOICE_REJECT ? (
                        <Link className="Reject-singlebtn rejected">Cancelled</Link>
                      ) : invoiceData.cli_inv_status === CLIENT_INVOICE_STATUS.INVOICE_PAYMENT_PROCESSED ? (
                        <Link className="Approve-singlebtn">Paid</Link>
                      ) : (
                        <ThreeDots
                          height="18"
                          width="20"
                          radius="9"
                          color="#6479f9"
                          ariaLabel="loading"
                          visible={true}
                        />
                      )}
                    </>
                  )
                )}
              </div>
              <div className="payment-comp-detail">
                <div className="company-detail">
                  <Link className="head-payapp">Vendor</Link>
                  <Link className="companynae-sig">
                    {wxCompany && wxCompany === "" && invoiceData ? setWxCompany(invoiceData && invoiceData.cli_inv_vendor.wx_company) : wxCompany ? wxCompanyProfile?.find((selWxCompany) => selWxCompany._id === wxCompany)?.wx_company : 'Company Name'}
                  </Link>
                </div>
              </div>

              {/* modal approve */}
              <div>
                <Modal
                  className="delete-modal"
                  isOpen={approveModalIsOpen}
                  onRequestClose={closeModal}
                  contentLabel="Approve Modal"
                  style={{
                    content: {
                      height: "41%",
                      width: "30%",
                      position: "fixed",
                      top: "40%",
                      left: "50%",
                      transform: "translate(-17.5%, 0%)",
                      parent: document.querySelector(".admin-outer.time.tracker"),
                    },
                  }}
                >
                  <div className="delete-modal-content">
                    <div className="outter-modal-main">
                      <a className="close-image-clr1" onClick={closeModal}>
                        <img src={crssrImage} alt="Close Icon" />{" "}
                      </a>
                    </div>
                    <h2>Approve Invoice</h2>
                    <p>Are you sure you want to Approve this Invoice</p>

                    <div className="form-field-payment">
                      <label htmlFor="client" className="form-label">
                        Select Company
                      </label>
                      {isWxCompanyLoading ? (
                        <div className="outter-load-table">
                          <ThreeDots
                            height="25"
                            width="25"
                            radius="9"
                            color="#6479f9"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                          />
                        </div>
                      ) : (
                        <div
                          className={`select-modal-mainM custom-dropdown-user-new-outer ${wxCompanyDropdownOpen ? 'open' : ''}`}
                        >
                          <div
                            className="selected-option custom-dropdown-user-new"
                            onClick={handleToggleDropdown}
                          >
                            {wxCompany ? wxCompanyProfile?.find((selWxCompany) => selWxCompany._id === wxCompany)?.wx_company : 'Select Company'}
                          </div>
                          {wxCompanyDropdownOpen && (
                            <div className="custom-dropdown-new">
                              <ul className="company-list">
                                {/* <li className={wxCompany === 'WX' ? 'selected-item' : ''} onClick={() => handleSelectCompany('WX')}>WX EUROPE</li>
                              <li className={wxCompany === 'Xpertos' ? 'selected-item' : ''} onClick={() => handleSelectCompany('Xpertos')}>XPERTOS</li> */}
                                {wxCompanyProfile.length > 0 ? (
                                  wxCompanyProfile
                                    .slice()
                                    .sort((a, b) => {
                                      const nameA = a.wx_company;
                                      const nameB = b.wx_company;
                                      return nameA.localeCompare(nameB);
                                    })
                                    .map((item) => (
                                      <li key={item._id} className={wxCompany === item._id ? 'selected-item' : ''} onClick={() => handleSelectCompany(item._id)}>
                                        {item.wx_company}
                                      </li>
                                    ))
                                ) : (
                                  <li>No ventors registered yet.</li>
                                )}
                              </ul>
                            </div>
                          )}
                        </div>
                      )}
                    </div><br></br>



                    {isLoadingApprove ? (
                      <div className="outter-load-table">
                        <ThreeDots
                          height="38"
                          width="40"
                          radius="9"
                          color="#6479f9"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClassName=""
                          visible={true}
                        />
                      </div>
                    ) : (
                      <div className="delete-modal-buttons">
                        <button
                          className="buttons-approved"
                          onClick={() => invoiceStatusApprove(true)}
                          disabled={isLoadingApprove}
                        >
                          Approve
                        </button>

                        <button onClick={closeModal}>Cancel</button>
                      </div>
                    )}
                  </div>
                </Modal>
              </div>
              {/* modal end */}

              {/* modal reject */}
              <div>
                <Modal
                  className="delete-modal"
                  isOpen={modalRejectIsOpen}
                  onRequestClose={closeRejectModal}
                  contentLabel="Reject Modal"
                  style={{
                    content: {
                      height: "41%",
                      width: "30%",
                      position: "fixed",
                      top: "40%",
                      left: "50%",
                      transform: "translate(-17.5%, 0%)",
                      parent: document.querySelector(".admin-outer.time.tracker"),
                    },
                  }}
                >
                  <div className="delete-modal-content">
                    <div className="outter-modal-main">
                      <a className="close-image-clr1" onClick={closeRejectModal}>
                        <img src={crssrImage} alt="Close Icon" />{" "}
                      </a>
                    </div>
                    <h2>Cancel Invoice</h2>
                    <p>Are you sure you want to Cancel this Invoice</p>
                    {isLoadingReject ? (
                      <div className="outter-load-table">
                        <ThreeDots
                          height="38"
                          width="40"
                          radius="9"
                          color="#6479f9"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClassName=""
                          visible={true}
                        />
                      </div>
                    ) : (
                      <div className="delete-modal-buttons-reject">
                        <button
                          className="buttons-rejected"
                          onClick={() => invoiceStatusReject()}
                          disabled={isLoadingReject}
                        >
                          Yes
                        </button>
                        <button onClick={closeRejectModal}>Cancel</button>
                      </div>
                    )}
                  </div>
                </Modal>
              </div>
              {/* modal end */}

              {/* modal invoice send */}
              <div>
                <Modal
                  className="delete-modal"
                  isOpen={invSendModalIsOpen}
                  onRequestClose={closeInvSendModal}
                  contentLabel="Invoice Send Modal"
                  style={{
                    content: {
                      height: "41%",
                      width: "30%",
                      position: "fixed",
                      top: "40%",
                      left: "50%",
                      transform: "translate(-17.5%, 0%)",
                      parent: document.querySelector(".admin-outer.time.tracker"),
                    },
                  }}
                >
                  <div className="delete-modal-content">
                    <div className="outter-modal-main">
                      <a className="close-image-clr1" onClick={closeInvSendModal}>
                        <img src={crssrImage} alt="Close Icon" />{" "}
                      </a>
                    </div>
                    <h2>Send Invoice</h2>
                    <p>Are you sure you want to send this invoice to client</p>
                    {isLoadingInvSend ? (
                      <div className="outter-load-table">
                        <ThreeDots
                          height="38"
                          width="40"
                          radius="9"
                          color="#6479f9"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClassName=""
                          visible={true}
                        />
                      </div>
                    ) : (
                      <div className="delete-modal-buttons">
                        <button
                          className="buttons-approved"
                          onClick={() => invoiceStatusInvSend()}
                          disabled={isLoadingInvSend}
                        >
                          Send
                        </button>
                        <button onClick={closeInvSendModal}>Cancel</button>
                      </div>
                    )}
                  </div>
                </Modal>
              </div>
              {/* modal end */}

              {/* modal invoice send manually */}
              <div>
                <Modal
                  className="delete-modal"
                  isOpen={invManualSendModalIsOpen}
                  onRequestClose={closeInvManualSendModal}
                  contentLabel="Invoice Manual Send Modal"
                  style={{
                    content: {
                      height: "41%",
                      width: "30%",
                      position: "fixed",
                      top: "40%",
                      left: "50%",
                      transform: "translate(-17.5%, 0%)",
                      parent: document.querySelector(".admin-outer.time.tracker"),
                    },
                  }}
                >
                  <div className="delete-modal-content">
                    <div className="outter-modal-main">
                      <a className="close-image-clr1" onClick={closeInvManualSendModal}>
                        <img src={crssrImage} alt="Close Icon" />{" "}
                      </a>
                    </div>
                    <h2>Send Invoice Manually</h2>
                    <p>Are you sure this Invoice is send manually to the client</p>
                    {isLoadingInvManualSend ? (
                      <div className="outter-load-table">
                        <ThreeDots
                          height="38"
                          width="40"
                          radius="9"
                          color="#6479f9"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClassName=""
                          visible={true}
                        />
                      </div>
                    ) : (
                      <div className="delete-modal-buttons">
                        <button
                          className="buttons-approved"
                          onClick={() => invoiceStatusManualInvSend()}
                          disabled={isLoadingInvManualSend}
                        >
                          Send
                        </button>
                        <button onClick={closeInvManualSendModal}>Cancel</button>
                      </div>
                    )}
                  </div>
                </Modal>
              </div>
              {/* modal end */}

              {/* modal manual payment */}
              <div>
                <Modal
                  className="delete-modal"
                  isOpen={manualModalIsOpen}
                  onRequestClose={handleManualModalCancel}
                  contentLabel="Manual Payment"
                  style={{
                    content: {
                      height: "41%",
                      width: "30%",
                      position: "fixed",
                      top: "40%",
                      left: "50%",
                      transform: "translate(-17.5%, 0%)",
                      parent: document.querySelector(".admin-outer.time.tracker"),
                    },
                  }}
                >
                  <div className="delete-modal-content">
                    <div className="outter-modal-main">
                      <a
                        className="close-image-clr1"
                        onClick={handleManualModalCancel}
                      >
                        <img src={crssrImage} alt="Close Icon" />{" "}
                      </a>
                    </div>
                    <h2>Payment</h2>
                    <p>Are you sure you want mark this Invoice as paid?</p>
                    {isLoadingManualPayment ? (
                      <div className="outter-load-table">
                        <ThreeDots
                          height="38"
                          width="40"
                          radius="9"
                          color="#6479f9"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClassName=""
                          visible={true}
                        />
                      </div>
                    ) : (
                      <div className="delete-modal-buttons">
                        {invoiceData && (
                          <button
                            className="buttons-pay"
                            onClick={() => {
                              handleManualModalConfirm(invoiceData._id);
                            }}
                            disabled={isLoadingManualPayment}
                          >
                            Paid
                          </button>
                        )}
                        <button onClick={handleManualModalCancel}>Cancel</button>
                      </div>
                    )}
                  </div>
                </Modal>
              </div>
              {/* modal end */}
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default ClientInvoiceSingle;